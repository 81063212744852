import React, { useState, useEffect } from "react";
import { EditorState, ContentState, convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

const SoalWYSIWYG = (props) => {
    const maxLength = 10000;
    // const blocksFromHtml = htmlToDraft(props.value);
    // const { contentBlocks, entityMap } = blocksFromHtml;
    let editorStateInitial;
    let contentBlock;
    if(props.value) {
        const html = props.value;
        contentBlock = htmlToDraft(html);
    }

    if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
        editorStateInitial = EditorState.createWithContent(contentState);
    } else {
        editorStateInitial = EditorState.createEmpty();
    }

    const [editorState, setEditorState] = useState(editorStateInitial);

    const onEditorStateChange = editorState => {
        setEditorState(editorState);
        return props.onChange(
            draftToHtml(convertToRaw(editorState.getCurrentContent()))
        );
    };

    return (
        <React.Fragment>
            <Editor
                editorState={editorState}
                toolbarClassName="toolbarClassName"
                wrapperClassName="wrapperClassName"
                editorClassName="shadow border border-gray-300 dark:border-gray-700 pl-3 py-1 shadow-sm rounded text-sm focus:outline-none focus:border-indigo-700 bg-transparent resize-none text-gray-800 dark:text-gray-100 w-100 resize"
                onEditorStateChange={onEditorStateChange}
                placeholder="Tuliskan soal disini"
                editorStyle={{ fontFamily: 'Quicksand', minHeight: '200px' }}
                toolbar={{
                    options: ['inline', 'list'],
                    inline: {
                        options: ['bold', 'italic', 'underline', 'superscript', 'subscript'],
                    },
                    list: {
                        options: ['unordered', 'ordered'],
                    },
                }}
                handleBeforeInput={val => {
                    const textLength = editorState.getCurrentContent().getPlainText().length;
                    if (val && textLength >= maxLength) {
                        return 'handled';
                    }
                    return 'not-handled';
                }}
                handlePastedText={val => {
                    const textLength = editorState.getCurrentContent().getPlainText().length;
                    return ((val.length + textLength) >= maxLength);
                }}
            />
        </React.Fragment>
    );
};

export default SoalWYSIWYG;