import { connect, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";

function OnlyCheckRole (WrappedComponent, allowedRoles) {
  function AuthHOC (props) {
    console.log(JSON.stringify(props))
    const { isLoggedIn, role } = props;
    if (isLoggedIn && allowedRoles === role ) {
      return (
        <WrappedComponent {...props} />
      )
    } else {
      return (
        <Redirect to='/403'/>
      )
    }
  }

  return connect(mapStateToProps)(AuthHOC);
}

function mapStateToProps(state) {
  const { isLoggedIn, isProfileComplete, role } = state.auth;
  return {
      isLoggedIn,
      isProfileComplete,
      role
  };
}

export default OnlyCheckRole;



// export default 