import React from 'react';
import styles from '../../index.module.css';
import { HeroCarousel } from './index';

export default function Hero({props}) {
  return (
    <React.Fragment>
      <section className="container-fluid" id="hero-section-container" style={{backgroundImage: 'url(/assets/landing/papyrus-dark.png)', backgroundSize: 'contain'}}>
        <div className="svg-container-upper">
          <svg className="wave-hero-upper" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 200" fill="none">
            <path fillRule="evenodd" clipRule="evenodd" d="M1063 0L1003.59 5.31091C945.073 10.6218 826.26 21.2437 708.333 63.7311C590.407 106.218 471.593 180.571 353.667 196.504C235.74 212.437 116.927 169.949 58.4067 148.706L-1 127.462V0H58.4067C116.927 0 235.74 0 353.667 0C471.593 0 590.407 0 708.333 0C826.26 0 945.073 0 1003.59 0H1063Z" fill="url(#paint0_linear)"/>
            <defs>
              <linearGradient id="paint0_linear" x1="531" y1="150" x2="531" y2="0" gradientUnits="userSpaceOnUse">
                <stop stopColor="#F8CA2B"/>
                <stop offset="1" stopColor="#FCA61F"/>
              </linearGradient>
            </defs>
          </svg>
        </div>
        <div className="container hero-section">
          <div className="row align-items-center g-2 py-5">
            <div className="col-sm-12 col-lg-6">
              <h1 className="display-5 1h-1 mb-3 text-start jd-home" style={{fontWeight: '400'}}>Bimbingan Belajar Kedokteran dan Tenaga Medis</h1>
              <div className="d-grid gap-2 d-md-flex justify-content-md-start mb-3">
                <button type="button" className="btn-home btn-hero">Terbaik di Indonesia</button>
              </div>
              <div className="d-grid gap-2 d-md-flex justify-content-md-start mb-3">
                <h4 className="fs-5 1h-1 mb-3 text-start jm-peserta"> &gt; 32.000 Peserta Se-Indonesia </h4>
              </div>
              <div className="d-grid gap-2 d-md-flex justify-content-md-start ct">
                <p className={styles.ingeniolead}>Pilihan Belajar dengan Tutor, Sistem dan Pola Pikir <b>TERBAIK</b><br/> untuk persiapan ujianmu bersama <span className={styles.textYellow}>INGENIO INDONESIA.</span><br/><b>Daftar Sekarang</b>!</p>
              </div>
            </div>
            <div className="col-sm-12 col-lg-6" style={{position: 'relative', zIndex:1100}}>
              <HeroCarousel />
            </div>
          </div>
          
        </div>
        <div className="svg-container-lower" style={{backgroundImage: 'url(/assets/landing/hero-foot.png)', backgroundSize:'contain', backgroundRepeat:'no-repeat'}}>
          <svg className="wave-hero-lower" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1068 200" fill="none">
            <path fillRule="evenodd" clipRule="evenodd" d="M0 200L59.4067 194.689C117.927 189.378 236.74 178.756 354.667 136.269C472.593 93.7816 591.407 19.4287 709.333 3.49589C827.26 -12.4369 946.073 30.0505 1004.59 51.2942L1064 72.5379V200H1004.59C946.073 200 827.26 200 709.333 200C591.407 200 472.593 200 354.667 200C236.74 200 117.927 200 59.4067 200H0Z" fill="url(#paint0_linear)"/>
            <defs>
              <linearGradient id="paint0_linear" x1="492.077" y1="-15" x2="492.397" y2="200.044" gradientUnits="userSpaceOnUse">
              <stop stopColor="#F8CA2B"/>
              <stop offset="1" stopColor="#FCA826"/>
              </linearGradient>
            </defs>
          </svg>
          {/* <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1068 27" fill="none">
            <rect width="1068" height="27" fill="#F8CA2B"/>
          </svg> */}
        </div>
      </section>
    </React.Fragment>
  )
}