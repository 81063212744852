import React from 'react'
import styles from '../../index.module.css';
import { ProgramCard } from './index';

export default function Program(props) {
  let baseURL = window.location.origin;

  let dataPrograms = [{
      id: 1,
      color: 'linear-gradient(180deg, #F8CA2B 0%, #FCA826 100%)',
      colorName: 'orange',
      text: 'Bimbingan UKMPPD CBT-OSCE',
      href: '/Program-Ukmppd',
    },
    {
      id: 2,
      color: 'linear-gradient(180deg, #BC72EB 0%, #A328F2 100%)',
      colorName: 'purple',
      text: 'Bimbingan UKMP2DG CBT-OSCE',
      href: '/Program-Ukmp2dg',
    },
    {
      id: 3,
      color: 'linear-gradient(180deg, #35D5A1 0%, #1CB181 100%)',
      colorName: 'green',
      text: 'Bimbingan UKOM Kebidanan',
      href: '/Program-Ukom-Kebidanan',
    },
    {
      id: 4,
      color: 'linear-gradient(180deg, #F07676 0%, #D31C1C 100%)',
      colorName: 'red',
      text: 'Rocket Class Plus FK-FKG',
      href: '/program-rocket-plus',
    },
    {
      id: 5,
      color: 'linear-gradient(180deg, #F8CA2B 0%, #FCA826 100%)',
      colorName: 'orange',
      text: 'Bimbingan Preklinik FK-FKG Kebidanan',
      href: '/Program-Preklinik',
    },
    {
      id: 6,
      color: 'linear-gradient(180deg, #BC72EB 0%, #A328F2 100%)',
      colorName: 'purple',
      text: `Bimbingan Uji Tahap Bersama 
      Tahap 1 dan Tahap 2`,
      href: '/Program-Utb',
    },
    {
      id: 7,
      color: 'linear-gradient(180deg, #35D5A1 0%, #1CB181 100%)',
      colorName: 'green',
      text: 'Bimbingan Pre-Coass FK-FKG',
      href: '/Program-Bimbingan-Pre-Coass',
    },
    {
      id: 8,
      color: 'linear-gradient(180deg, #F07676 0%, #D31C1C 100%)',
      colorName: 'red',
      text: 'Bimbingan Dokter Muda FK-FKG',
      href: '/Program-Dokter-Muda',
    }
  ]

  return (
    <React.Fragment>
      <section className="container-fluid" style={{backgroundImage: 'url(/assets/landing/memphis-mini-dark.png)', backgroundSize: 'contain'}}>
        <div className="container px-4 pt-5">
          <div className="row align-items-center g-2 py-1">
              <h1 className="text-center jd-home2">Program Bimbingan</h1>
          </div>
        </div>
        <div className="container px-4 pb-5">
          <div className="row align-items-center gy-5 py-1 my-4">
            {dataPrograms.map(program => <div className="col-sm-6 col-lg-3"><ProgramCard key={program.id} data={program} /></div>)}
          </div>
        </div>
      </section>
    </React.Fragment>
  )
}