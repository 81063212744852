import React, { useState } from 'react';

export default function Values() {

    return (
        <React.Fragment>
            <section className="container-fluid">
                <div className="site-header site-header--menu-left landing-11-menu site-header--absolute" style={{ backgroundImage: 'url(/assets/landing/Genio2.png)', backgroundPositionY: 'bottom', backgroundPositionX: 'right', backgroundRepeat: 'no-repeat', paddingTop: '6rem', backgroundColor: '#ffcc00' }}>
                    <div className="py-5" style={{ paddingBottom: '2rem !important' }}>
                        <h5 className="tentang_title">VALUES </h5>
                        <h5 className="sub text-center">Visi  &amp; Misi </h5>
                    </div>
                </div>
            </section>
            <section className="container-fluid bg-desktop" style={{ backgroundImage: 'url(/assets/landing/bg-value.png)' }}>
                <div className="container px-4" style={{ paddingTop: '12rem', width: '85%' }}>
                    <div className="row g-2" style={{ textAlign: 'justify', paddingTop: '10rem' }}>
                        {/* Visi */}
                        <h2 className="txt32px">Visi Ingenio Indonesia</h2>
                        <h5 className="txt24px text-center">“Menjadi bimbingan belajar bagi mahasiswa dan tenaga kesehatan yang Profesional, Inklusif dan Unggul untuk mewujudkan Indonesia sehat“</h5>
                        {/* Misi */}
                        <h2 className="txt32px">Misi Ingenio Indonesia</h2>
                        <div className="row">
                            <div className="col-2 iconz">
                                <img src="/assets/icons/eclips.png" />
                            </div>
                            <div className="col-10">
                                <p className="ct pb-4" style={{ color: '#23272A', fontWeight: 'normal' }}>Memberikan layanan pendidikan kesehatan yang relevan, berkualitas tinggi, dan berkelanjutan didukung oleh infrastruktur dan teknologi.</p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-2 iconz">
                                <img src="/assets/icons/eclips.png" />
                            </div>
                            <div className="col-10">
                                <p className="ct pb-4" style={{ color: '#23272A', fontWeight: 'normal' }}>Mengajar dan mendidik berdasarkan keilmuan kesehatan terkini melalui pola pikir dan landasan teori yang kuat.</p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-2 iconz">
                                <img src="/assets/icons/eclips.png" />
                            </div>
                            <div className="col-10">
                                <p className="ct pb-4" style={{ color: '#23272A', fontWeight: 'normal' }}>Memperluas jangkauan dan menyediakan layanan pendukung pendidikan formal untuk mahasiswa dan tenaga kesehatan Indonesia.</p>
                            </div>
                        </div>

                        {/* <h5 className="txt24px text-center" style={{ paddingTop: '5rem' }}>“ Mengajar dan mendidik melalui pola pikir dan landasan teori yang kuat “</h5>
                        <p className="ct pb-4" style={{ color: '#23272A', fontWeight: 'normal' }}>Seperti sudah dijelaskan sebelumnya, Ingenio sangat berfokus pada pola pikir dan landasan teori. Kami tidak hanya menarget lulus ujian, target kami adalah ‘pola pikir yang benar’. Bagi kami, lulus ujian hanyalah efek kausalitas dari pola pikir yang benar. Sekali lagi, seorang yang sudah layak menjadi seorang tenaga medis pasti akan lulus ujian.</p>

                        <h5 className="txt24px text-center" style={{ paddingTop: '5rem' }}>“ Tidak pernah berkompromi tentang kualitas pendidikan “</h5>
                        <p className="ct pb-4" style={{ color: '#23272A', fontWeight: 'normal' }}>Untuk mencapai visi tenaga medis yang profesional, jelas kualitas pendidikan sangatlah esensial. Ingenio menjamin bahwa setiap kalimat yang tertulis dalam buku intensif kami adalah kalimat yang bertanggung jawab. Setiap tahun, buku tersebut juga direvisi agar tetap updated, karena ilmu kedokteran selalu berkembang. Setiap tutor juga selalu mengevaluasi tren soal ujian yang muncul, dan menyesuaikan materi yang diberikan sesuai dengan kebutuhan. Kedua, Ingenio tidak pernah dengan sembarangan menjawab soal latihan ujian baik yang dikeluarkan oleh Ingenio sendiri maupun dari institusi lain. Setiap jawaban yang kami cantumkan berasal dari sumber yang terpercaya baik tingkat nasional maupun internasional. Ingenio memiliki guideline khusus mengenai sumber literatur yang boleh dipakai untuk menjawab soal, dimana guideline ini sesuai dengan sumber yang digunakan oleh pihak penyelenggara ujian. Setiap jawaban soal yang kami berikan adalah jawaban yang bertanggung jawab. Dan kami berkompromi untuk hal ini. Tidak sekalipun.</p>

                        <h5 className="txt24px text-center" style={{ paddingTop: '5rem' }}>“ Tidak pernah berkompromi tentang mentalitas tenaga medis “</h5>
                        <p className="ct pb-4" style={{ color: '#23272A', fontWeight: 'normal' }}>Bagian kedua dari visi Ingenio adalah mendidik tenaga medis yang berdasarkan hati nurani. Untuk inilah, misi ketiga ini dibentuk. Setiap tenaga medis perlu memiliki mentalitas yang kuat dan lurus. Profesi sebagai tenaga medis sangatlah krusial dalam masyarakat karena terkait dengan nyawa manusia, yang ketika dilakukan dengan mentalitas yang buruk akan sangat berbahaya. Mentalitas yang buruk inilah yang selama ini menjadikan citra tenaga medis di Indonesia menjadi tercoreng. Sekali lagi Ingenio berlari melawan arus untuk mengoreksi hal ini dalam peserta didik kami. Dalam setiap pertemuan, tutor akan mengajar sepenuh hati untuk membuat peserta didik mengerti makna sebenarnya menjadi seorang tenaga medis. Sekali lagi, tanpa kompromi.</p> */}
                    </div>
                </div>
            </section>
        </React.Fragment >
    )
}

