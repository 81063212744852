import React from 'react';
import styles from '../../index.module.css';

export default function Legalitas () {
  return (
    <React.Fragment>
      <section className="container-fluid bg-white py-4" id="legalitas-section-container">
        <div className="row align-items-center g-2 py-4">
          <h1 className="jd-home2 my-4" style={{color: '#000',fontWeight: 'bold'}}>Legalitas <span className={styles.textYellow}>INGENIO</span></h1>
        </div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-6 col-sm-12">
              <img src="/assets/landing/legalitas-sk.png" alt="SK Kemenkumham" style={{width: '100%'}}></img>
            </div>
            <div className="col-md-6 col-sm-12">
              <img src="/assets/landing/legalitas-HKI.png" alt="HKI" style={{width: '100%'}}></img>
            </div>
          </div>
          <div className="row my-4 py-5">
            <div className="col-md-6 ml-auto col-sm-12">
              <img src="/assets/landing/legalitas-penghargaan.png" alt="Penghargaan" style={{width: '100%', paddingLeft: '20px'}}></img>
            </div>
            <div className="col-md-6 ml-auto col-sm-12">
              <img src="/assets/landing/new-penghargaan-2022.png" alt="Penghargaan" style={{width: '100%', paddingLeft: '20px'}}></img>
            </div>
            {/* <div className="col-md-6 mr-auto col-sm-12 legalitas-text" style={{paddingTop: '12.5%'}}>
              <h3 className="mb-4" style={{fontWeight: 700, lineHeight: '4rem', fontSize: '1.6rem'}}>Anugerah Karya Pendidikan Indonesia 2018</h3>
              <h3 className="mb-4" style={{fontWeight: 700, lineHeight: '4rem' , fontSize: '1.6rem'}}>Kategori</h3>
              <h3 className="mb-4" style={{fontWeight: 700, lineHeight: '4rem' , fontSize: '1.6rem'}}>Lembaga Pendidikan Terpecaya Dalam Mutu dan Kualitas Pendidikan Terbaik</h3>
              <p style={{color:'black'}}>
                Anugerah Karya Pendidikan Indonesia 2018 <br/>
                Kategori<br/>
                Lembaga Pendidikan Terpercaya<br/>
                Dalam Mutu dan Kualitas Pendidikan Terbaik
              </p>
            </div> */}
          </div>
        </div> 
      </section>
    </React.Fragment>
  )
}