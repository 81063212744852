import React from 'react';
import { useState, useEffect } from 'react';
import AdminSideBar from '../../../../components/layout/dashboard/admin/AdminSideBar';
import Button from '@material-tailwind/react/Button';
import { FormGroup, Modal } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import { Link, useParams } from 'react-router-dom';
import { FaRegUser } from 'react-icons/fa';
import { HiOutlineDownload } from 'react-icons/hi';
import { BsFileBarGraph } from 'react-icons/bs';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import TryoutService from '../../../../services/tryout'
import AuthService from '../../../../services/auth'
import CustomDatatable from '../../../../components/common/CustomDatatable';
import { addCommas } from '../../../../helpers/utils';
import moment from 'moment-timezone';
import Swal from 'sweetalert2';

export default function InformasiTryOutDetail () {
  document.title = "INGENIO Indonesia";
  const [showSidebar, setShowSidebar] = useState('-left-64');
  var baseUrl = window.location.origin;

  const [downloading, setDownloading] = useState(false);

  // Filtering data
  const [isLoading, setIsLoading] = useState(true);
  const [hasNext, setHasNext] = useState(false)
  const [hasPrev, setHasPrev] = useState(false)
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [keyword, setKeyword] = useState('');
  const [kelasData, setKelasData] = useState([]);

  const { tryoutId, subtryoutId } = useParams();

  const columns = [
    {
        name: 'NO.',
        field: 'no',
        index: true,
        selector: row => row.no,
        sortable: true,
        minWidth: '1px'
    },
    {
        name: 'NAMA PESERTA',
        render: true,
        component: (row) => {
            return (
                <td className='main-table__bodyRow'>{row.full_name}</td>
            )
        },
        selector: row => row.tanggal,
        sortable: true,
        // width: '15vw',
    },
    {
        name: 'TOTAL NILAI',
        render: true,
        component: (row) => {
            return (
                <td className='main-table__bodyRow'>{parseFloat(row.total_nilai).toFixed(2)}</td>
            )
        },
        selector: row => row.tanggal,
        sortable: true,
        // width: '15vw',
    },
    {
        name: 'RANKIING',
        render: true,
        component: (row) => {
            return (
                <td className='main-table__bodyRow'>{row.ranking}</td>
            )
        },
        selector: row => row.tanggal,
        sortable: true,
        // width: '15vw',
    },
    {
      name: 'Aksi',
      render: true,
      component: (row) => {
          return (
              <td className='main-table__bodyRow'>
                  <div style={{display: 'flex', flexDirection: 'row'}}>
                      <OverlayTrigger placement="top" overlay={<Tooltip id="button-tooltip-2">Detail Profile</Tooltip>}>
                        <Link className="btnCircleBlack" to={`/dashboard/admin/informasi-tryout/detail/profile/${row.id_user}`}><FaRegUser size="22"/></Link>
                      </OverlayTrigger>
                      <OverlayTrigger placement="top" overlay={<Tooltip id="button-tooltip-2">Detail Nilai</Tooltip>}>
                        <Link className="btnCircleBlack ml-3" 
                          to={{
                            pathname: `/dashboard/admin/informasi-tryout/detail/nilai/${row.id_subtryout}`,
                            state: {
                              user: {
                                id_user: row.id_user,
                                id_tryout: row.id_tryout,
                                id_subtryout: row.id_subtryout
                              },
                            }
                          }}><BsFileBarGraph size="22"/></Link>
                      </OverlayTrigger>
                  </div>
              </td>
          )
      },
      selector: row => row.tanggal,
      sortable: true,
      // width: '15vw',
  },
];

  const data = [
    {
        no: 1,
        kode: '11223344',
        nama: 'lorem',
        nilai: '70',
        rank: '1',
    },
  ];

  const customStyles = {
      table: {
        style: {
          borderRadius: '15px',
          border: '1px solid #BDC1C9',
        },
      },
      head: {
        style: {
          fontSize: '1.25rem',
          textTransform: "uppercase",
          color: '#6B7280',
          backgroundColor: '#f3f4f6',
          border: '1px solid #BDC1C9',
          borderTopLeftRadius: '15px',
          borderTopRightRadius: '15px',
        },
      },
      pagination: {
        style: {
          color: '#6B7280',
          fontSize: '1.1rem',
          minHeight: '56px',
          backgroundColor: '#f3f4f6',
          border: '1.25px solid #BDC1C9',
          borderBottomLeftRadius: '15px',
          borderBottomRightRadius: '15px',
        },
      },
  };

  const downloadHasilTryout = () => {
    setDownloading(true)
    TryoutService.downloadHasilTryout(subtryoutId)
      .then(resp => {
        const url = resp.data.data.url;
        window.location.replace(url)
        setDownloading(false)
      }).catch(err => {
        console.error(err)
        Swal.fire({  
          title: 'Gagal!',  
          text: 'Data tidak ditemukan',  
          icon: 'warning',  
          showCancelButton: true,  
          cancelButtonText: 'Kembali',           
          showConfirmButton: false,  
      });  
        setDownloading(false)
      })
  }

  const getListKelas = (params = '') => {
    setIsLoading(true)
    TryoutService.getListUserRankingAdmin(subtryoutId, params).then(resp => {
        const response = resp.data;
        setKelasData(response.data);

        setPage(response.meta.page)
        setPerPage(response.meta.perPage)
        setCount(response.meta.count)

        setHasNext(response.meta.hasNext)
        setHasPrev(response.meta.hasPrev);
        
        setIsLoading(false)
    }).catch(err => {
        let msg = 'Internal Server Error'
        setIsLoading(false)
        console.error(err)
        if(err.response) {
            if(err.response.data.message) {
                msg = err.response.data.message
            }
        }

        Fail(msg)
    })
  }

  const Fail = (message) => {
    Swal.fire({  
        title: 'Gagal!',  
        text: message,  
        icon: 'warning',  
        showCancelButton: true,  
        cancelButtonText: 'Kembali',           
        showConfirmButton: false,  
    });   
  }

  useEffect(() => {
    getListKelas()
  }, [])

  return(
    <React.Fragment>
      <AdminSideBar />
      <div className="md:ml-64 styleHead" style={{ backgroundColor: '#F3F4F6' }}>
        <div className="bg-orange md:px-8 pb-5" style={{height: '20vh', position: 'relative', padding: '0 30px 30px 30px'}}>
            <h1 className="tracking-wider title-24-600">Detail Peserta TryOut</h1>
            {/* <h2 className="tracking-wider title-20-700 mt-5">Selamat Datang Tutor INGENIO !</h2> */}
            <img src={baseUrl + "/assets/logo/evaluasikelas.png"} className="img-opacity"></img>
        </div>

        <div className="px-3 md:px-8 pb-5">
            <div className="container mx-auto max-w-full">
                <div className="row mt-5">
                  <div className="col-12 my-2">
                    <FormGroup>
                      <div className="justify-start">
                        <div className="SearchTable">
                            <input type="text" name="" className="inputSearchTable" placeholder="&#x1F50E;&#xFE0E; Search..." id="" />
                            <Button className="btnSearchTable">Cari</Button>
                        </div>
                        <OverlayTrigger placement="top" overlay={<Tooltip id="button-tooltip-2">Download Detail Peserta</Tooltip>}>
                          <Button onClick={(e) => {
                                e.preventDefault();
                                if(downloading)
                                  return;
                                downloadHasilTryout();
                              }} className="btnOrange2">
                              <div className="titleBtnOrange">
                                  <HiOutlineDownload size="23px" className="mr-2"/>{downloading ? 'Processing...' : 'Download'}
                              </div> 
                          </Button>
                        </OverlayTrigger>
                      </div>
                    </FormGroup>
                  </div>
                  <div className="col-12 mt-3">
                      {/* <DataTable columns={columns} customStyles={customStyles} data={data} pagination /> */}
                      <CustomDatatable 
                          filteredItems={kelasData} 
                          columns={columns}
                          isLoading={isLoading}
                          page={page}
                          perPage={perPage}
                          hasPrev={hasPrev}
                          hasNext={hasNext}
                          count={count}
                          onTableChange={(e, data) => {
                              switch (e) {
                              case 'rowPerPageChange':
                                  setPerPage(data.perPage)
                                  getListKelas(`page=${page}&perPage=${data.perPage}&keyword=${keyword}`)
                                  break;
                              case 'rowPageChange':
                                  setPage(data.page)
                                  getListKelas(`page=${data.page}&perPage=${perPage}&keyword=${keyword}`)
                                  break;
                              
                              default:
                                  break;
                              }
                          }}
                      />
                  </div>
                </div>
            </div>
        </div>

      </div>
    </React.Fragment>
    
  )
}