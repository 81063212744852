import React, { useState, useEffect } from 'react';
import { Form, FormGroup } from 'react-bootstrap';
import Button from '@restart/ui/esm/Button';
import DataTable from 'react-data-table-component';
import { Link, useHistory } from 'react-router-dom';
import { SideBar } from '../../../components/layout/dashboard';
import ElearningService from '../../../services/elearning';
import { contactLink } from '../../../helpers/utils'
import CustomDatatable from '../../../components/common/CustomDatatable';
import moment from 'moment-timezone';
import Swal from 'sweetalert2';
import '../akademik/tryout/Styles/style.css'

export default function KelasSaya() {
    document.title = "INGENIO Indonesia";
    document.body.style.backgroundColor = "#f3f4f6";

    const [program, setProgram] = useState('')

    // Filtering data
    const [isLoading, setIsLoading] = useState(true);
    const [hasNext, setHasNext] = useState(false)
    const [hasPrev, setHasPrev] = useState(false)
    const [count, setCount] = useState(0);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [keyword, setKeyword] = useState('');
    const [userId, setUserId] = useState('');
    const [kelasSaya, setKelasSaya] = useState([])
    const [elearningPrograms, setElearningPrograms] = useState([])
    const [idElearningProgram, setIdElearningProgram] = useState('');

    const history = useHistory();
    const dropdown = {
        borderRadius: "10px",
        height: 50,
        padding: "25px",
    };
    const columns = [
        {
            name: 'NO.',
            field: 'no',
            index: true,
            selector: row => row.no,
            sortable: true,
        },
        {
            name: 'NAMA KELAS',
            render: true,
            component: (row) => {
                return (
                    <td className='main-table__bodyRow'>{row.kelas.nama_kelas}</td>
                )
            },
            selector: row => row.namakelas,
            sortable: true,
            // width: '15vw',
        },
        {
            name: 'PROGRAM BIMBINGAN',
            render: true,
            component: (row) => {
                return (
                    <td className='main-table__bodyRow'>{row.kelas.elearning_program.nama_program}</td>
                )
            },
            selector: row => row.programbimbingan,
            sortable: true,
            // width: '15vw',
        },
        {
            name: 'REGIO',
            render: true,
            component: (row) => {
                return (
                    <td className='main-table__bodyRow'>{row.kelas.nama_regio}</td>
                )
            },
            selector: row => row.regio,
            sortable: true,
            // width: '15vw',
        },
        {
            name: 'MASA BERLAKU',
            selector: row => row.masaberlaku,
            render: true,
            component: (row) => {
                return (
                    <td className='main-table__bodyRow'>{`${moment(row.kelas.elearning_program.tanggal_mulai).format('DD-MM-YYYY')} s/d ${moment(row.kelas.elearning_program.tanggal_akhir).format('DD-MM-YYYY')}`}</td>
                )
            },
            sortable: true,
            // width: '15vw',
        },
        {
            name: 'STATUS KELAS',
            render: true,
            component: (row) => {
                return (
                    <td className='main-table__bodyRow'>{row.kelas.is_active ? 'Aktif' : 'Tidak Aktif'}</td>
                )
            },
            selector: row => row.statuskelas,
            sortable: true,
        },
        {
            render: true,
            component: (row) => {
                return (
                    <td className='main-table__bodyRow'>
                        {
                            row.kelas.is_active ?
                            <Link className="pilih-tryout p-2 text-center m-1"
                                to={{
                                    pathname: `/dashboard/e-learning/kelas-saya/details/${row.kelas.id}`,
                                    state: {
                                        nama_program: row.kelas.elearning_program.nama_program,
                                        nama_regio: row.kelas.nama_regio,
                                        nama_kelas: row.kelas.nama_kelas
                                    }
                                }}>
                                Akses Kelas
                            </Link>
                            :
                            <Button onClick={() => {
                                Swal.fire({
                                    title: 'Kelas tidak aktif',  
                                    text: 'Kelas tidak aktif, silahkan menghubungi administrator',  
                                    icon: 'warning',  
                                    showCancelButton: true,  
                                    cancelButtonText: 'Kembali',           
                                    showConfirmButton: false,  
                                  }); 
                            }} className="pilih-tryout-disable">Akses Kelas</Button>
                            
                        }
                        
                    </td>
                )
            },
            minWidth: '190px',
            cell: () => <Link className="pilih-tryout p-2 text-center m-1" to="/dashboard/e-learning/kelas-saya/details">Akses Kelas</Link>,
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
    ];
    const data = [
        {
            no: 1,
            namakelas: 'Lorem ipsum dolor.',
            programbimbingan: 'Lorem ipsum dolor.',
            regio: 'Progam 1',
            masaberlaku: '100.000',
            statuskelas: 'AKTIF',
        },
        {
            no: 2,
            namakelas: 'Lorem ipsum dolor.',
            programbimbingan: 'Lorem ipsum dolor.',
            regio: 'Progam 1',
            masaberlaku: '100.000',
            statuskelas: 'AKTIF',
        },
        {
            no: 3,
            namakelas: 'Lorem ipsum dolor.',
            programbimbingan: 'Lorem ipsum dolor.',
            regio: 'Progam 4',
            masaberlaku: '100.000',
            statuskelas: 'TIDAK AKTIF',
        },
        {
            no: 4,
            namakelas: 'Lorem ipsum dolor.',
            programbimbingan: 'Lorem ipsum dolor.',
            regio: 'Progam 7',
            masaberlaku: '100.000',
            statuskelas: 'AKTIF',
        },
        {
            no: 5,
            namakelas: 'Lorem ipsum dolor.',
            programbimbingan: 'Lorem ipsum dolor.',
            regio: 'Progam 2',
            masaberlaku: '100.000',
            statuskelas: 'TIDAK AKTIF',
        },
        {
            no: 6,
            namakelas: 'Lorem ipsum dolor.',
            programbimbingan: 'Lorem ipsum dolor.',
            regio: 'Progam 3',
            masaberlaku: '100.000',
            statuskelas: 'AKTIF',
        },
    ];

    const getListKelasSaya = (params = '') => {
        setIsLoading(true)
        ElearningService.getListKelasSayaParticipant(params).then(resp => {
            if(resp.data.success === true) {
                const response = resp.data;
                setKelasSaya(response.data);

                setPage(response.meta.page)
                setPerPage(response.meta.perPage)
                setCount(response.meta.count)

                setHasNext(response.meta.hasNext)
                setHasPrev(response.meta.hasPrev);

                if(response.meta.userId) {
                    setUserId(response.meta.userId)
                }
            }
            setIsLoading(false)

            if(resp.data.code === 401){
                localStorage.clear()
                history.push('/signin');
                return;
            }
        }).catch(err => {
            setIsLoading(false)
            console.error(err)
        })
    }

    const getListElearningProgramDropdown = () => {
        ElearningService.getListElearningProgramAkademik().then(resp => {
            const response = resp.data
            setElearningPrograms(response.data)
        }).catch(err => {
            console.error(err)
        })
    }

    useEffect(() => {
        getListKelasSaya();
        getListElearningProgramDropdown();
    }, [])

    return (
        <React.Fragment>
            <SideBar />
            <div className="md:ml-64" style={{ backgroundColor: '#F3F4F6' }}>
                {/* <div className="bg-orange px-3 md:px-8 py-1 h-40">
                    <div className="container md:pr-8 md:pl-10">
                        <h3 className="text-left tracking-wider title-dashboard">Selamat datang di Kelas Bimbingan Intensif Ingenio Indonesia</h3>
                        <br />
                        <h3 className="text-left tracking-wider">Anda dapat menghubungi Admin terlebih dahulu untuk memastikan kelas dan program yang Anda daftarkan. <br /> <br />Kontak Admin <a href={contactLink()} target={'_blank'}> <i class="fa fa-whatsapp" aria-hidden="true"></i></a></h3>
                    </div>
                </div> */}

                <div className="container md:pr-8 md:pl-10"  style={{position: 'absolute', top: '80px'}}>
                    <h3 className="text-left tracking-wider title-dashboard">Selamat datang di Kelas Bimbingan Intensif Ingenio Indonesia</h3>
                    <br />
                    <h3 className="text-left tracking-wider">Anda dapat menghubungi Admin terlebih dahulu untuk memastikan kelas dan program yang Anda daftarkan. <br /> <br />Kontak Admin <a href={contactLink()} target={'_blank'}> <i class="fa fa-whatsapp" aria-hidden="true"></i></a></h3>
                </div> 

                <div className="px-3 md:px-8 mt-4">
                    <div className="container mx-auto max-w-full">
                        <div className="flex-2 mb-4">
                            <form onSubmit={(e) => {
                                e.preventDefault()
                                getListKelasSaya(`page=${page}&perPage=${perPage}&keyword=${keyword}&program=${program}`)
                            }}>
                            <div className="flex flex-row">
                                <div className="bg-white px-4 py-3 mr-3" style={{ borderRadius: '20px' }}>
                                <input
                                    type="text"
                                    name=""
                                    value={keyword}
                                    onChange={(e) => setKeyword(e.target.value)}
                                    style={{ backgroundColor: '#F3F4F6', width: '25rem', borderRadius: '12px', padding: '0.75rem', border: 'none !important', paddingLeft: '10px', fontFamily: 'Quicksand' }}
                                    className="mr-3"
                                    placeholder="Nama kelas"
                                    id=""
                                />
                                <select
                                    as="select"
                                    aria-label="Program"
                                    aria-describedby="basic-addon1"
                                    className="my-tryout px-3 py-2 mx-3"
                                    style={{ fontFamily: 'Quicksand' }}
                                    onChange={(e) => setProgram(e.target.value)}
                                >
                                    <option value="">Pilih Program</option>
                                    {elearningPrograms.map(item =>
                                        <option key={item.key} value={item.nama_program}>{item.nama_program}</option>
                                    )};
                                </select>
                                <Button type="submit" className="pilih-tryout mx-3">Cari</Button>
                                </div>
                            </div>
                            </form>
                        </div>
                        <div className="col-md-12 col-lg-12 col-xs-12 mt-3">
                            {/* <DataTable columns={columns} data={data} pagination /> */}
                            <CustomDatatable 
                                filteredItems={kelasSaya} 
                                columns={columns}
                                isLoading={isLoading}
                                page={page}
                                perPage={perPage}
                                hasPrev={hasPrev}
                                hasNext={hasNext}
                                count={count}
                                onTableChange={(e, data) => {
                                    switch (e) {
                                    case 'rowPerPageChange':
                                        setPerPage(data.perPage)
                                        getListKelasSaya(`page=${page}&perPage=${data.perPage}&keyword=${keyword}&program=${program}`)
                                        break;
                                    case 'rowPageChange':
                                        setPage(data.page)
                                        getListKelasSaya(`page=${data.page}&perPage=${perPage}&keyword=${keyword}&program=${program}`)
                                        break;
                                    
                                    default:
                                        break;
                                    }
                                }}
                            />
                        </div>
                    </div>
                </div>

            </div>
        </React.Fragment >

    )
}