import React from 'react';
import './canvas.css'
import './tes.css'

export default function IngenioFooterPurple(props) {

    const bgFooter = {
        backgroundColor: "#000",
        backgroundImage: "url('assets/landing/footer.png')",
        backgroundSize: "cover"
    };

    const ptContainer = {
        paddingTop: "15rem"
    };

    const logoFooter = {
        width: "60%",
        height: "50%",
        marginTop: "-40%"
    };

    const logoLeft = {
        textAlign: "left"
    };
    const textb800 = {
        color: "#000",
        fontSize: "1.125rem",
        fontWeight: 700,
        textDecoration: "none",
        paddingBottom: "20px",
    };
    const textb800mobile = {
        color: "#000",
        fontSize: "0.9rem",
        fontWeight: 700,
        textDecoration: "none",
        paddingBottom: "20px",
    };
    const textb801mobile = {
        color: "#000",
        fontSize: "0.7rem",
        fontWeight: 600,
        textDecoration: "none",
        paddingBottom: "20px",
    };
    const texth5800 = {
        color: "#000",
        textAlign: "left",
        fontSize: "1.125rem",
        fontWeight: 700,
        marginTop: "20%"
    };
    const texth5800mobile = {
        color: "#000",
        textAlign: "left",
        fontSize: "1rem",
        fontWeight: 700,
        marginTop: "-2rem"
    };
    const noUnderline = {
        fontSize: "0.85rem",
        fontWeight: 500,
        textDecoration: "none"
    };
    const w25p = {
        width: "25%"
    };
    const w50p = {
        width: "50%",
    };
    const pt5r = {
        paddingTop: "5rem"
    };
    const pr1r = {
        paddingRight: "0.5rem",
    };
    const pr1rmobile = {
        paddingRight: "0.5rem",
        width: "32px",
    };
    const slice = {
        marginTop: "1rem",
        height: "150px",
        border: "1px #000 solid"
    }

    return (
        <React.Fragment>
            <div className="container-fluid p-0" style={{ backgroundColor: props.bgColor }}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 281" fill="none">
                    <path fillRule="evenodd" clipRule="evenodd" d="M34.8 63.2172L0 54.1862V281H1440V0L1405.2 27.0931C1371.6 54.1862 1303.2 108.372 1234.8 144.497C1165.2 180.621 1096.8 198.683 1028.4 207.714C1005.6 210.724 982.8 212.731 960 214.738C914.4 218.752 868.8 222.766 823.2 234.807C808.182 238.773 793.106 243.609 777.997 248.456C724.294 265.685 670.182 283.044 616.8 261.9C579.969 247.311 543.139 214.394 506.308 181.476C474.738 153.26 443.169 125.045 411.6 108.372C360.356 81.3087 309.111 84.6586 257.362 88.0416C240.038 89.1741 222.657 90.3103 205.2 90.3103C136.8 90.3103 68.4 72.2483 34.8 63.2172Z" fill="#B954A0" />
                </svg>
                <div className="row">

                </div>
            </div>
            <footer className="footer-l-11 bg-linear-footer-purple text-center text-md-start">
                {/* {Footer Desktop} */}
                <div className="container-fluid d-none d-lg-block px-5">
                    <div className="row g-5 px-5">
                        <div className="col-md-4">
                            <div className="footer-logo-l-11">
                                <a href="/"><img src="assets/logo/ingenio rakun 2.png" alt="logo" style={logoFooter} /></a>
                                <div className="text-left" style={texth5800}>
                                    <p style={{ fontWeight: 700, color: 'black' }}>KANTOR PUSAT <br />PT. INGENIO MEDIKA NUSANTARA <br /><span style={{ fontWeight: 600 }}>Ruko Ditas</span></p>
                                    <p style={{ fontWeight: 600, color: 'black' }}>
                                        Jl. MT. Haryono No.14, Dinoyo, Kec. Lowokwaru, Kota Malang, Jawa Timur 65145, Indonesia <br />
                                        081358889123
                                    </p>
                                </div>

                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="footer-widget-l-11">
                                <h5 style={textb800}>PROGRAM </h5>
                                <ul className="list-unstyled">
                                    <li><a href="/Program-Ukmppd" style={noUnderline}>UKMPPD</a></li>
                                    <li><a href="/Program-Ukmp2dg" style={noUnderline}>UKMP2DG</a></li>
                                    <li><a href="/Program-Ukom-Kebidanan" style={noUnderline}>UKOM KEBIDANAN</a></li>
                                    <li><a href="/Program-Preklinik" style={noUnderline}>PREKLINIK FK/FKG/KEBIDANAN</a></li>
                                    <li><a href="/Program-Utb" style={noUnderline}>UTB TAHAP I DAN II</a></li>
                                    <li><a href="/Program-Bimbingan-Pre-Coass" style={noUnderline}>PRE-COASS</a></li>
                                    <li><a href="/Program-Dokter-Muda" style={noUnderline}>DOKTER MUDA/DOKTER GIGI MUDA</a></li>
                                    <li><a href="/program-rocket-plus" style={noUnderline}>ROCKET PLUS</a></li>
                                    <li><a href="https://tryout.ingenioindonesia.id/" style={noUnderline}>TRYOUT</a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="footer-widget-l-11">
                                <h5 style={textb800}>TENTANG INGENIO</h5>
                                <div className="row">
                                    <div className="col-md-4">
                                        <ul className="list-unstyled">
                                            <li><a href="/opening" style={noUnderline}>OPENING</a></li>
                                            <li><a href="/history" style={noUnderline}>HISTORY</a></li>
                                            <li><a href="/values" style={noUnderline}>VALUES</a></li>
                                            <li><a href="/fasilitas" style={noUnderline}>FASILITAS</a></li>
                                            <li><a href="/gallery" style={noUnderline}>GALERI</a></li>
                                            <li><a href="/artikel" style={noUnderline}>ARTIKEL</a></li>
                                        </ul>
                                    </div>
                                    <div class="col-md-1 d-none d-xl-block">
                                        <div></div>
                                    </div>
                                    <div className="col-md-5">
                                        <ul className="list-unstyled">
                                            <li><a href="/testimoni_peserta" style={noUnderline}>TESTIMONI</a></li>
                                            <li><a href="#" style={noUnderline}>PESERTA</a></li>
                                            <li><a href="/tutor" style={noUnderline}>TUTOR INGENIO</a></li>
                                            <li><a href="/disclaimer" style={noUnderline}>DISCLAIMER</a></li>
                                            <li><a href="/privacy-policy" style={noUnderline}>KEBIJAKAN PRIVASI</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-2">
                            <div className="footer-widget-l-11">
                                <h5 style={textb800}>BANTUAN</h5>
                                <ul className="list-unstyled" sty>
                                    <li><a href="/hubungi-kami" style={noUnderline}>HUBUNGI KAMI</a></li>
                                    <li><a href="https://api.whatsapp.com/send?phone=6281358889133&text=Hallo,%20Tim%20HRD%20Ingenio%0A%0ASaya%20(nama%20dengan%20gelar)%0ADomisili%20kota%20xxx%0Amau%20tahu%20tentang%20informasi%20pendaftaran%20dan%20seleksi%20tutor%20regio%20(Kota%20Regio).%0A%0ATerima%20kasih" style={noUnderline}>KARIR</a></li>
                                    <li><a href="/faq" style={noUnderline}>FAQ</a></li>
                                    <li className="mt-4 flex flex-wrap">
                                        <a href="https://www.instagram.com/ingenioindonesia/"><img src="assets/icons/ig.png" style={pr1r} className /></a>
                                        <a href="https://www.youtube.com/channel/UCE-3-BkaJHarx4eZmiD50Ww"><img src="assets/icons/yt.png" style={pr1r} /></a>
                                        <a href="https://www.facebook.com/ingeniolearningcenter/"><img src="assets/icons/fb.png" style={pr1r} /></a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                {/* {Footer Mobile} */}
                <div className="container-fluid d-lg-none d-lg-none px-5">
                    <div className="row mb-3" style={pt5r}>
                        <div className="col-sm-4 col-md-6">
                            <div className="footer-logo-l-11" style={logoLeft}>
                                <a href="#"><img src="assets/logo/logo-ingenio-nav.png" alt="logo" style={logoFooter} /></a>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="text-left" style={texth5800mobile}>
                                <p style={{ fontWeight: 700, color: 'black' }}>KANTOR PUSAT <br />PT. INGENIO MEDIKA NUSANTARA <br /><span style={{ fontWeight: 600 }}>Ruko Ditas</span></p>
                                <p style={{ fontWeight: 600, color: 'black' }}>
                                    Jl. MT. Haryono No.14, Dinoyo, Kec. Lowokwaru, Kota Malang, Jawa Timur 65145, Indonesia <br />
                                    081358889123
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="row mb-3 g-4" style={pt5r}>
                        <div className="col-sm-9 col-md-6" style={w50p}>
                            <div className="footer-widget-l-11">
                                <h5 style={textb800mobile}>PROGRAM </h5>
                                <ul className="list-unstyled">
                                    <li><a href="/Program-Ukmppd" style={noUnderline}>UKMPPD</a></li>
                                    <li><a href="/Program-Ukmp2dg" style={noUnderline}>UKMP2DG</a></li>
                                    <li><a href="/Program-Ukom-Kebidanan" style={noUnderline}>UKOM KEBIDANAN</a></li>
                                    <li><a href="/Program-Preklinik" style={noUnderline}>PREKLINIK FK/FKG/KEBIDANAN</a></li>
                                    <li><a href="/Program-Utb" style={noUnderline}>UTB TAHAP I DAN II</a></li>
                                    <li><a href="/Program-Bimbingan-Pre-Coass" style={noUnderline}>PRE-COASS</a></li>
                                    <li><a href="/Program-Dokter-Muda" style={noUnderline}>DOKTER MUDA/DOKTER GIGI MUDA</a></li>
                                    <li><a href="/program-rocket-plus" style={noUnderline}>ROCKET PLUS</a></li>
                                    <li><a href="https://tryout.ingenioindonesia.id/" style={noUnderline}>TRYOUT</a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-sm-3 col-md-2" style={w50p}>
                            <div className="footer-widget-l-11">
                                <h5 style={textb800mobile}>BANTUAN </h5>
                                <ul className="list-unstyled">
                                    <li><a href="/hubungi-kami" style={textb801mobile}>HUBUNGI KAMI</a></li>
                                    <li><a href="#" style={textb801mobile}>KARIR</a></li>
                                    <li><a href="/faq" style={textb801mobile}>FAQ</a></li>
                                    <li className="mt-4 flex flex-wrap">
                                        <a href="https://www.instagram.com/ingenioindonesia/"><img src="assets/icons/ig.png" style={pr1rmobile} /></a>
                                        <a href="https://www.youtube.com/channel/UCE-3-BkaJHarx4eZmiD50Ww"><img src="assets/icons/yt.png" style={pr1rmobile} /></a>
                                        <a href="https://www.facebook.com/ingeniolearningcenter/"><img src="assets/icons/fb.png" style={pr1rmobile} /></a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-4" style={w50p}>
                            <div className="row" style={logoLeft}>
                                <h5 style={textb800mobile}>TENTANG INGENIO</h5>
                                <div className="col-md-6" style={w50p}>
                                    <ul className="list-unstyled">
                                        <li><a href="/opening" style={noUnderline}>OPENING</a></li>
                                        <li><a href="/history" style={noUnderline}>HISTORY</a></li>
                                        <li><a href="/values" style={noUnderline}>VALUES</a></li>
                                        <li><a href="/fasilitas" style={noUnderline}>FASILITAS</a></li>
                                        <li><a href="/gallery" style={noUnderline}>GALERI</a></li>
                                        <li><a href="/artikel" style={noUnderline}>ARTIKEL</a></li>
                                    </ul>
                                </div>
                                <div className="col-md-6" style={w50p}>
                                    <ul className="list-unstyled">
                                        <li><a href="/testimoni_peserta" style={textb801mobile}>TESTIMONI</a></li>
                                        <li><a href="#" style={textb801mobile}>PESERTA</a></li>
                                        <li><a href="/tutor" style={textb801mobile}>TUTOR INGENIO</a></li>
                                        <li><a href="/disclaimer" style={textb801mobile}>DISCLAIMER</a></li>
                                        <li><a href="/privacy-policy" style={textb801mobile}>KEBIJAKAN PRIVASI</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <div className="copyright-area-l-11">
                </div>
            </footer>
        </React.Fragment>
    )
}