import React, { useState, useEffect } from 'react';
import { Col, Form, FormGroup, Row } from 'react-bootstrap';
import { BsArrowLeftShort, BsShieldFillCheck, BsFillCircleFill } from 'react-icons/bs';
import { Link, useParams } from 'react-router-dom';
import { SideBar } from '../../../components/layout/dashboard';
import { contactLink } from '../../../helpers/utils';
import ElearningService from '../../../services/elearning'
import moment from 'moment';
import Swal from 'sweetalert2';

export default function CekTracking() {
    document.title = "INGENIO Indonesia";
    var baseUrl = window.location.origin;
    
    const [isLoading, setIsLoading] = useState(false);
    const [kelasData, setKelasData] = useState([]);

    const { resi } = useParams();

    const dropdown = {
        borderRadius: "10px",
        height: 50,
        padding: "25px",
    };

    const getListKelas = () => {
        setIsLoading(true)
        ElearningService.trackingShipmentParticipant(resi).then(resp => {
            const response = resp.data;
            setKelasData(response.data.history);
            
            setIsLoading(false)
        }).catch(err => {
            setIsLoading(false)
            let msg = 'Internal Server Error'
            console.error(err)
            if(err.response) {
              if(err.response.data.message) {
                msg = err.response.data.message
              }
            }

            Fail(msg)
        })
    }

    const Fail = (message) => {
        Swal.fire({  
            title: 'Gagal!',  
            text: message,  
            icon: 'warning',  
            showCancelButton: true,  
            cancelButtonText: 'Kembali',           
            showConfirmButton: false,  
        }); 
    }

    useEffect(() => {
        getListKelas()
    }, [])


    return (
        <React.Fragment>
            <SideBar />
            <div className="md:ml-64 tutorDataKelas" style={{ backgroundColor: '#F3F4F6' }}>
                {/* <div className="bg-orange md:px-8 pb-5" style={{ position: 'relative'}}>
                    <h1 className="tracking-wider title-24-600">Status Resi</h1>
                    <h2 className="tracking-wider title-20-700 my-4">Tracking</h2>
                    <h3 className="text-left tracking-wider">Anda dapat menghubungi Admin terlebih dahulu untuk memastikan kelas dan program yang Anda daftarkan. <br /> <br />Kontak Admin <a href={contactLink()} target={'_blank'}> <i class="fa fa-whatsapp" aria-hidden="true"></i></a></h3>
                    <img src={baseUrl + "/assets/logo/evaluasikelas.png"} className="img-opacity"></img>
                </div> */}

                <div className="container md:pr-8 md:pl-10"  style={{position: 'absolute', top: '60px'}}>
                    <h1 className="tracking-wider title-24-600">Status Resi</h1>
                    <h2 className="tracking-wider title-20-700 my-4">Tracking</h2>
                    <h3 className="text-left tracking-wider">Anda dapat menghubungi Admin terlebih dahulu untuk memastikan kelas dan program yang Anda daftarkan. <br /> <br />Kontak Admin <a href={contactLink()} target={'_blank'}> <i class="fa fa-whatsapp" aria-hidden="true"></i></a></h3>
                </div> 

                <div className="px-3 md:px-8 mt-2 pb-5">
                    <div className="container mx-auto max-w-full">
                        <div className="justify-start my-4">
                            <Link to="/dashboard/e-learning/resi">
                                <div className="navBack">
                                    <BsArrowLeftShort size="20px"/>Kembali
                                </div> 
                            </Link>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <div className="cards pd-InJurnal">
                                    <div className="row">
                                        <div className="col-12 mb-4">
                                            <div className="title-dashboard">Status Tracking</div>
                                            <div className="borderBottomGrey"></div>
                                        </div>
                                        {
                                            kelasData.map((item, i) => {
                                                return (<div className="col-12 mb-3">
                                                <h3 className="text-lg rounded-lg font-bold flex items-center focus:outline-none mb-2"
                                                    style={{ color: '#F8CA2B'}}>
                                                    <span className="" style={{ color: '#F8CA2B', paddingRight: '1rem' }}>
                                                        <BsFillCircleFill style={{ stroke: "#F8CA2B", strokeWidth: "0.5" }} />
                                                    </span> {moment(item.datetime).format('LLLL')}
                                                </h3>
                                                <h3 className="text-sm rounded-lg font-bold flex items-center focus:outline-none"
                                                    style={{ paddingLeft: '2rem', marginBottom: '6px', color: '#999' }}>
                                                    {item.status_code === 'POD' ? 'Barang Anda sudah sampai.' : 'Barang Anda sudah dikirim'}
                                                </h3>
                                                <h3 className="text-sm rounded-lg font-bold flex items-center focus:outline-none"
                                                    style={{ paddingLeft: '2rem', marginBottom: '6px', color: '#999' }}>
                                                    {item.remarks}
                                                </h3>
                                                <div className="borderBottomTracking"></div>
                                            </div>)
                                            })
                                        }
                                        

                                    </div>
                                </div>
                            </div>
                        </div>
                            {/* <div className="col-12 mb-4">
                                <FormGroup>
                                    <Row className="cards">
                                        <Col sm={12}>  <h3 className="text-left tracking-wider title-dashboard">Status Tracking</h3><br />
                                        </Col>
                                        <Col sm={4}>
                                            <Row>
                                                <Col className="mb-3" sm={12}><h3 className="text-md rounded-lg font-bold flex items-center focus:outline-none"
                                                    style={{ color: '#F8CA2B', fontFamily: 'Quicksand' }}>
                                                    <span className="" style={{ color: '#F8CA2B', paddingRight: '1rem' }}>
                                                        <BsShieldFillCheck style={{ stroke: "#F8CA2B", strokeWidth: "0.5" }} />
                                                    </span> Selasa, 2 Nov 2021</h3>
                                                    <h3 className="text-sm rounded-lg font-bold flex items-center focus:outline-none text-muted"
                                                        style={{ fontFamily: 'Quicksand', paddingLeft: '2rem' }}>Barang telah di kirim.</h3>
                                                    <h3 className="text-sm rounded-lg font-bold flex items-center focus:outline-none text-muted"
                                                        style={{ fontFamily: 'Quicksand', paddingLeft: '2rem' }}>Barang Anda dalam proses pengiriman oleh kurir.</h3>
                                                    <hr />
                                                </Col>
                                                <Col sm={12}><h3 className="text-md rounded-lg font-bold flex items-center focus:outline-none"
                                                    style={{ color: '#000', fontFamily: 'Quicksand' }}>
                                                    <span className="" style={{ color: '#F8CA2B', paddingRight: '1rem' }}>
                                                        <BsShieldFillCheck style={{ stroke: "#F8CA2B", strokeWidth: "0.5" }} />
                                                    </span> Senin, 1 Nov 2021</h3>
                                                    <h3 className="text-sm rounded-lg font-bold flex items-center focus:outline-none text-muted"
                                                        style={{ fontFamily: 'Quicksand', paddingLeft: '2rem' }}>Pembayaran sudah di Verifikasi.</h3>
                                                    <h3 className="text-sm rounded-lg font-bold flex items-center focus:outline-none text-muted"
                                                        style={{ fontFamily: 'Quicksand', paddingLeft: '2rem' }}>Pembayaran telah diterima INGENIO</h3>
                                                    <hr />
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </FormGroup>
                            </div> */}
                    </div>
                </div>

            </div>
        </React.Fragment >

    )
}