import React, { useState } from 'react';
import { Col, Form, FormGroup, Row, Button } from 'react-bootstrap';
import { BsArrowLeftShort, BsFillGeoAltFill, BsPlusCircle } from 'react-icons/bs';
import { Link, NavLink, useParams, useHistory } from 'react-router-dom';
import { SideBar } from '../../../components/layout/dashboard';
import { useLocation } from 'react-router';
import { contactLink } from '../../../helpers/utils'
import ElearningService from '../../../services/elearning';
import moment from 'moment-timezone';
import Swal from 'sweetalert2';

export default function IsiEvaluasi() {
    var baseUrl = window.location.origin;

    document.title = "INGENIO Indonesia";
    var baseUrl = window.location.origin;
    const [program, setProgram] = useState('');
    const dropdown = {
        borderRadius: "10px",
        height: 50,
        padding: "25px",
    };

    const [isLoading, setIsLoading] = useState(false)
    const [globalScore, setGlobalScore] = useState(null);
    const [materialScore, setMaterialScore] = useState(null);
    const [facilityScore, setFacilityScore] = useState(null);
    const [learningSystemScore, setLearningSystemScore] = useState(null);
    const [disciplineScore, setDisciplineScore] = useState(null);
    const [otherMessage, setOtherMessage] = useState(null);

    const history = useHistory();
    
    const location = useLocation();
    const { id_kelas_jadwal, nama_kelas, nama_program, nama_regio, nama_tutor, nama_materi } = location.state

    const Succeed = () => {
        Swal.fire({  
            title: 'Data Berhasil Tersimpan !',  
            text: '',  
            icon: 'success',  
            showCancelButton: false,              
            confirmButtonText: 'OK'  
        }).then((result) => {
          if (result.isConfirmed) {
            history.goBack();
          }
        })
    }

    const addEvaluasiKelas = (body) => {
        Swal.fire({  
            title: 'Sudah Yakin?',  
            text: 'Apakah Anda Yakin Ingin Menambah Data Ini?',  
            icon: 'warning',  
            showCancelButton: true,  
            cancelButtonText: 'Tidak',  
            confirmButtonText: 'Ya'  
        }).then((result) => {
          if (result.isConfirmed) {
              setIsLoading(true)
              ElearningService.addEvaluasiKelasParticipant(body).then(resp => {
                  Succeed()
                  setIsLoading(false)
              }).catch(err => {
                let msg = 'Internal Server Error'
                setIsLoading(false)
                console.error(err)
                if(err.response) {
                  if(err.response.data.message) {
                    msg = err.response.data.message
                  }
                }
    
                Fail(msg)
              })
          }
        });  
    }
        const Fail = (message) => {
            Swal.fire({  
                title: 'Gagal!',  
                text: message,  
                icon: 'warning',  
                showCancelButton: true,  
                cancelButtonText: 'Kembali',           
                showConfirmButton: false,  
            }); 
          }

    return (
        <React.Fragment>
            <SideBar />
            <div className="md:ml-64 tutorDataKelas" style={{ backgroundColor: '#F3F4F6' }}>
                {/* <div className="bg-orange md:px-8" style={{height: '220px', position: 'relative', padding: '0 30px'}}>
                    <h1 className="tracking-wider title-24-600" style={{marginTop: '-20px'}}>Evaluasi Kelas</h1>
                    <h2 className="tracking-wider title-20-700 my-3">Isi Evaluasi</h2>
                    <h3 className="text-left tracking-wider">isi data evaluasi se objektif mungkin karena identitas akan terjaga</h3>
                    <img src={baseUrl + "/assets/logo/evaluasikelas.png"} className="img-opacity"></img>
                </div> */}

                <div className="container md:pr-8 md:pl-10"  style={{position: 'absolute', top: '80px'}}>
                    <h1 className="tracking-wider title-24-600" style={{marginTop: '-20px'}}>Evaluasi Kelas</h1>
                    <h2 className="tracking-wider title-20-700 my-3">Isi Evaluasi</h2>
                    <h3 className="text-left tracking-wider">isi data evaluasi se objektif mungkin karena identitas akan terjaga</h3>
                </div> 

                <div className="px-3 md:px-8 pb-5">
                    <div className="container mx-auto max-w-full">
                        <div className="justify-start mt-4 mb-3">
                            <Link to="/dashboard/e-learning/info-kelas">
                                <div className="navBack">
                                    <BsArrowLeftShort size="19px"/>Kembali
                                </div> 
                            </Link>
                        </div>
                        <div className="row mt-4">
                            <div className="col-lg-5 mb-4">
                                <FormGroup>
                                    <div className="cards pd-4">
                                        <div className="flex lh-35">
                                            <div className="w-40">Nama Kelas</div>
                                            <div className="w-60 fw-600">{`: ${nama_kelas}`}</div>
                                        </div>
                                        <div className="flex lh-35">
                                            <div className="w-40">Program</div>
                                            <div className="w-60 fw-600">{`: ${nama_program}`}</div>
                                        </div>
                                        <div className="flex lh-35">
                                            <div className="w-40">Regio</div>
                                            <div className="w-60 fw-600">{`: ${nama_regio}`}</div>
                                        </div>
                                        <div className="flex lh-35">
                                            <div className="w-40">Nama Tutor</div>
                                            <div className="w-60 fw-600">{`: ${nama_tutor}`}</div>
                                        </div>
                                        <div className="flex lh-35">
                                            <div className="w-40">Nama Materi</div>
                                            <div className="w-60 fw-600">{`: ${nama_materi}`}</div>
                                        </div>
                                    </div>
                                </FormGroup>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 mb-4">
                                <FormGroup>
                                    <div className="cards pd-4">
                                        <h3 className="title-20-700">GLOBAL PERFORMANCE SCORE</h3>
                                        <h3 className="title-14-500 mt-2">Skala Global Performance isilah angka 1 sampai 5.</h3>
                                        <div className="flex gap-4 my-4">
                                            <div className="title-14-500"><strong>1</strong> = Sangat Perlu Dievaluasi</div>
                                            <div className="title-14-500"><strong>2</strong> = Kurang Baik</div>
                                            <div className="title-14-500"><strong>3</strong> = Cukup Baik</div>
                                            <div className="title-14-500"><strong>4</strong> = Baik</div>
                                            <div className="title-14-500"><strong>5</strong> = Sangat Baik</div>
                                        </div>
                                        <h3 className="title-14-500"> Berikanlah rentang nilai tutor/staf penunjang di bawah ini sesuai daftar kode tutor/staf, nama tutor/staf dan materinya dari Admin/Akademik kelas bimbingan yang telah Anda ikuti.</h3>
                                    </div>
                                </FormGroup>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 mb-4">
                                <div className="cards pd-4">
                                    <div className="row">
                                        <div className="col-md-6 mb-4">
                                            <Form.Select onChange={(e) => setGlobalScore(e.target.value)}>
                                                <option selected disabled>Global Score</option>
                                                <option value="1">1</option>
                                                <option value="2">2</option>
                                                <option value="3">3</option>
                                                <option value="4">4</option>
                                                <option value="5">5</option>
                                            </Form.Select>
                                        </div>
                                        <div className="col-md-6 mb-4">
                                            <Form.Select onChange={(e) => setMaterialScore(e.target.value)}>
                                                <option selected disabled>Materi/Slide</option>
                                                <option value="1">1</option>
                                                <option value="2">2</option>
                                                <option value="3">3</option>
                                                <option value="4">4</option>
                                                <option value="5">5</option>
                                            </Form.Select>
                                        </div>
                                        <div className="col-md-4 mb-4">
                                            <Form.Select onChange={(e) => setFacilityScore(e.target.value)}>
                                                <option selected disabled>Layanan/Fasilitas</option>
                                                <option value="1">1</option>
                                                <option value="2">2</option>
                                                <option value="3">3</option>
                                                <option value="4">4</option>
                                                <option value="5">5</option>
                                            </Form.Select>
                                        </div>
                                        <div className="col-md-4 mb-4">
                                            <Form.Select onChange={(e) => setLearningSystemScore(e.target.value)}>
                                                <option selected disabled>Sistem Belajar</option>
                                                <option value="1">1</option>
                                                <option value="2">2</option>
                                                <option value="3">3</option>
                                                <option value="4">4</option>
                                                <option value="5">5</option>
                                            </Form.Select>
                                        </div>
                                        <div className="col-md-4 mb-4">
                                            <Form.Select onChange={(e) => setDisciplineScore(e.target.value)}>
                                                <option selected disabled>Kedisiplinan</option>
                                                <option value="1">1</option>
                                                <option value="2">2</option>
                                                <option value="3">3</option>
                                                <option value="4">4</option>
                                                <option value="5">5</option>
                                            </Form.Select>
                                        </div>
                                        <div className="col-12">
                                            <textarea onChange={(e) => setOtherMessage(e.target.value)} className="formInputJurnal" placeholder="Masukkan Pesan..." name="" id="" style={{height: '220px', padding: '14px 18px'}}></textarea>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 justify-end mt-2">
                                <button type="submit" onClick={() => {
                                    addEvaluasiKelas({
                                        id_kelas_jadwal,
                                        global_score: parseInt(globalScore),
                                        material_score: parseInt(materialScore),
                                        facility_score: parseInt(facilityScore),
                                        learning_system_score: parseInt(learningSystemScore),
                                        discipline_score: parseInt(disciplineScore),
                                        other_messages: otherMessage 
                                    })
                                }} className="btnOrange3">Submit Evaluasi</button>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </React.Fragment >

    )
}