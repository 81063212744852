import React, { useState, useEffect } from 'react';
import { Form, FormGroup } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import { Link, useLocation } from 'react-router-dom';
import { SideBar } from '../../../components/layout/dashboard';
import { Badge } from 'react-bootstrap';
import { addCommas } from '../../../helpers/utils';
import CustomDatatable from '../../../components/common/CustomDatatable';

import axios from 'axios';
import moment from 'moment';
import Swal from 'sweetalert2';

export default function Payment() {
    document.title = "INGENIO Indonesia";
    document.body.style.backgroundColor = "#F3F4F6";
    const [jenisPembayaran, setJenisPembayaran] = useState('');

    const [isLoading, setIsLoading] = useState(true);
    const [hasNext, setHasNext] = useState(false)
    const [hasPrev, setHasPrev] = useState(false)
    const [count, setCount] = useState(0);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [keyword, setKeyword] = useState('');
    const [transaction, setTransaction] = useState([])


    const dropdown = {
        borderRadius: "10px",
        height: 50,
        padding: "25px",
    };
    const jenisTransactionTypeRender = (transactionItems) => {
      if(transactionItems.tryout !== null) {
        return 'Tryout'
      }
  
      if(transactionItems.elearning !== null) {
        return 'E-Elarning'
      }
    }
    const linkToDetailTransaction = (id, status, transactionType) => {
      if(transactionType === 'tryout') {
        if(status === 'success') {
          return <Link className="pilih-tryout p-2" to={`/dashboard/payment-process/sukses?transaction_id=${id}`}>Detail</Link>
        }
        return <Link className="pilih-tryout p-2" to={`/dashboard/payment-process?transaction_id=${id}`}>Detail</Link>
      }
  
      if(transactionType === 'e-learning') {
        if(status === 'success') {
          return <Link className="pilih-tryout p-2" to={`/dashboard/elearning-payment-process/sukses?transaction_id=${id}`}>Detail</Link>
        }
        return <Link className="pilih-tryout p-2" to={`/dashboard/elearning-payment-process?transaction_id=${id}`}>Detail</Link>
      }

      if(transactionType === 'e-course') {
        if(status === 'success') {
          return <Link className="pilih-tryout p-2" to={`/dashboard/course-tersedia/beli/pembayaran/sukses?transaction_id=${id}`}>Detail</Link>
        }
        return <Link className="pilih-tryout p-2" to={`/dashboard/course-tersedia/beli/pembayaran/proses?transaction_id=${id}`}>Detail</Link>
      }
    }
    
    const columns = [
      {
          name: 'NO.',
          field: 'no',
          index: true,
          selector: row => row.no,
          sortable: true,
      },
      {
          name: 'JENIS TRANSAKSI',
          render: true,
          component: (row) => {
              return (
                  <td className='main-table__bodyRow'>{row.transaction_type}</td>
              )
          },
          selector: row => row.namakelas,
          sortable: true,
          // width: '15vw',
      },
      {
          name: 'KODE TRANSAKSI',
          render: true,
          component: (row) => {
              return (
                  <td className='main-table__bodyRow'>{row.id}</td>
              )
          },
          selector: row => row.programbimbingan,
          sortable: true,
          // width: '15vw',
      },
      {
          name: 'TANGGAL TRANSAKSI',
          render: true,
          component: (row) => {
              return (
                  <td className='main-table__bodyRow'>{moment(row.transaction_at).format('LL')}</td>
              )
          },
          selector: row => row.regio,
          sortable: true,
          // width: '15vw',
      },
      {
          name: 'HARGA',
          selector: row => row.masaberlaku,
          render: true,
          component: (row) => {
              return (
                  <td className='main-table__bodyRow'>{`Rp ${addCommas(row.subtotal)}`}</td>
              )
          },
          sortable: true,
          // width: '15vw',
      },
      {
          name: 'STATUS',
          render: true,
          component: (row) => {
              return (
                  <td className='main-table__bodyRow'>
                    {
                      row.transaction_status === 'success' ? 
                        <Badge bg="success" text="light">SUKSES</Badge> : 
                          row.transaction_status === 'failed' ? 
                          <Badge bg="danger" text="light">GAGAL</Badge> : 
                        <Badge bg="warning" text="light">PENDING</Badge>
                    }
                  </td>
              )
          },
          selector: row => row.statuskelas,
          sortable: true,
      },
      {
          render: true,
          component: (row) => {
              return (
                  <td className='main-table__bodyRow'>
                      {
                        (row.transaction_items.length > 0) ? linkToDetailTransaction(row.id, row.transaction_status, row.transaction_type) : ''
                      }
                  </td>
              )
          },
          minWidth: '190px',
          cell: () => <Link className="pilih-tryout p-2 text-center m-1" to="/dashboard/e-learning/kelas-saya/details">Akses Kelas</Link>,
          ignoreRowClick: true,
          allowOverflow: true,
          button: true,
      },
  ];

    function useQuery() {
        const { search } = useLocation();
    
        return React.useMemo(() => new URLSearchParams(search), [search]);
      }
    
      const query = useQuery()

      const customStyles = {
        table: {
          style: {
            borderRadius: '15px',
            border: '1px solid #BDC1C9',
          },
        },
        head: {
          style: {
            fontSize: '1.25rem',
            textTransform: "uppercase",
            color: '#6B7280',
            backgroundColor: '#f3f4f6',
            border: '1px solid #BDC1C9',
            borderTopLeftRadius: '15px',
            borderTopRightRadius: '15px',
          },
        },
        pagination: {
          style: {
            color: '#6B7280',
            fontSize: '1.1rem',
            minHeight: '56px',
            backgroundColor: '#f3f4f6',
            border: '1.25px solid #BDC1C9',
            borderBottomLeftRadius: '15px',
            borderBottomRightRadius: '15px',
          },
        },
      };

      const getListKelas = (params = '') => {
        setIsLoading(true)
        axios.get(`${process.env.REACT_APP_TRYOUTURL}transaction/participant/get?${params}`, {
          headers: {
            Authorization: `Bearer ${JSON.parse(localStorage.getItem('token'))}`
          }
        }).then(resp => {
          const response = resp.data;
          setTransaction(response.data);
  
          setPage(response.meta.page)
          setPerPage(response.meta.perPage)
          setCount(response.meta.count)
  
          setHasNext(response.meta.hasNext)
          setHasPrev(response.meta.hasPrev);
          
          setIsLoading(false)
        }).catch(err => {
          setIsLoading(false)
          let msg = 'Internal Server Error'
          console.error(err)
          if(err.response) {
              if(err.response.data.message) {
                  msg = err.response.data.message
              }
          }
  
          Fail(msg)
        })
      }
    
      const Fail = (message) => {
        Swal.fire({  
            title: 'Gagal!',  
            text: message,  
            icon: 'warning',  
            showCancelButton: true,  
            cancelButtonText: 'Kembali',           
            showConfirmButton: false,  
        });   
      }
    
      useEffect(() => {
        getListKelas();
    }, [])

    return (
        <React.Fragment>
            <SideBar judul={'Daftar Transaksi'}/>
            <div className="md:ml-64">
                {/* <div className="bg-orange px-3 md:px-8 py-1" style={{height: '2.5rem'}}>
                    <div className="container md:pr-8 md:pl-10">
                    </div>
                </div> */}

                <div className="px-3 md:px-8 mt-4">
                    <div className="container mx-auto max-w-full">
                        <div className="row">
                            <div className="col-md-4 col-lg-4 col-xs-12 mb-4">
                                <FormGroup>
                                    <div className="row" style={{ backgroundColor: '#FFF', padding: '1rem', borderRadius: '1rem' }}>
                                        <div className="col-md-12">
                                            <Form.Select
                                                as="select"
                                                value={keyword}
                                                aria-label="Program"
                                                aria-describedby="basic-addon1"
                                                className="my-tryout"
                                                onChange={(e) => {
                                                  setKeyword(e.target.value)
                                                  getListKelas(`page=${page}&perPage=${perPage}&keyword=${e.target.value}`)
                                                }}
                                            >
                                                <option value="">Semua Pembayaran</option>
                                                <option value="tryout">Transaksi Tryout</option>
                                                <option value="e-learning">Transaksi E-Learning</option>
                                                <option value="e-course">Transaksi E-Course</option>
                                            </Form.Select>
                                        </div>
                                    </div>

                                </FormGroup>
                            </div>
                        </div>
                        <div className="col-md-12 col-lg-12 col-xs-12 mt-3">
                            {/* <DataTable columns={columns} data={transaction} customStyles={customStyles} pagination /> */}
                            <CustomDatatable 
                                filteredItems={transaction} 
                                columns={columns}
                                isLoading={isLoading}
                                page={page}
                                perPage={perPage}
                                hasPrev={hasPrev}
                                hasNext={hasNext}
                                count={count}
                                onTableChange={(e, data) => {
                                    switch (e) {
                                    case 'rowPerPageChange':
                                        setPerPage(data.perPage)
                                        getListKelas(`page=${page}&perPage=${data.perPage}&keyword=${keyword}`)
                                        break;
                                    case 'rowPageChange':
                                        setPage(data.page)
                                        getListKelas(`page=${data.page}&perPage=${perPage}&keyword=${keyword}`)
                                        break;
                                    
                                    default:
                                        break;
                                    }
                                }}
                            />
                        </div>
                    </div>
                </div>

            </div>
        </React.Fragment >

    )
}