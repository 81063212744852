import axios from "axios";

const API_URL = `${process.env.REACT_APP_ELEARNINGURL}`
const USER_URL = `${process.env.REACT_APP_USERURL}`;
const AUTH_URL = `${process.env.REACT_APP_AUTHURL}`;


axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('token');
class ElearningService {
    getStudentInfo() {
        return axios.get(AUTH_URL + `me/student`, {
            headers: {
                "Authorization": "Bearer " + JSON.parse(localStorage.getItem("token"))
            }
        })
    }
    trackingShipmentParticipant(resi) {
        return axios.get(API_URL + `shipment/participant/tracking/get?resi=${resi}`, {
            headers: {
                "Authorization": "Bearer " + JSON.parse(localStorage.getItem("token"))
            }
        })
    }
    inputResi(id, body) {
        return axios.put(API_URL + `shipment/logistik/update/${id}/resi`, body, {
            headers: {
                "Authorization": "Bearer " + JSON.parse(localStorage.getItem("token"))
            }
        })
    }
    getListShipmentByKelasIdLogistik(id, params = '') {
        return axios.get(API_URL + `shipment/logistik/get-by-kelas/${id}?${params}`, {
            headers: {
                "Authorization": "Bearer " + JSON.parse(localStorage.getItem("token"))
            }
        })
    }
    getListKelasLogistik(params = '') {
        return axios.get(API_URL + `kelas/logistik/get?${params}`, {
            headers: {
                "Authorization": "Bearer " + JSON.parse(localStorage.getItem("token"))
            }
        })
    }

    getListShipmentAdmin(params = '') {
        return axios.get(API_URL + `shipment/admin/get?${params}`, {
            headers: {
                "Authorization": "Bearer " + JSON.parse(localStorage.getItem("token"))
            }
        })
    }

    async getListKelasAdmin(params = '') {
        return axios.get(API_URL + `kelas/admin/get?${params}`, {
            headers: {
                "Authorization": "Bearer " + JSON.parse(localStorage.getItem("token"))
            }
        })
    }

    async downloadDetailEvaluasiTutor(id) {
        return axios.get(API_URL + `evaluasi/admin/get/${id}/download`, {
            headers: {
                "Authorization": "Bearer " + JSON.parse(localStorage.getItem("token")),
            }
        })
    }

    // Participant Area
    getListShipmentParticipant(params = '') {
        return axios.get(API_URL + `shipment/participant/get?${params}`, {
            headers: {
                "Authorization": "Bearer " + JSON.parse(localStorage.getItem("token"))
            }
        })
    }


    addEvaluasiKelasParticipant(data) {
        return axios.post(API_URL + `evaluasi/create`, data, {
            headers: {
                "Authorization": "Bearer " + JSON.parse(localStorage.getItem("token"))
            }
        })
    }

    updateElearningParticipantProfile(data) {
        return axios.put(API_URL + `kelas/participant/update`, data, {
            headers: {
                "Authorization": "Bearer " + JSON.parse(localStorage.getItem("token"))
            }
        })
    }

    getListElearningTransactionParticipant() {
        return axios.get(`${process.env.REACT_APP_TRYOUTURL}transaction/participant/get`, {
            headers: {
                "Authorization": "Bearer " + JSON.parse(localStorage.getItem("token"))
            }
        })
    }
    orderKelasParticipant(body) {
        return axios.post(API_URL + `kelas/participant/kelas-tersedia/order`, body, {
            headers: {
                "Authorization": "Bearer " + JSON.parse(localStorage.getItem("token"))
            }
        })
    }
    getSingleKelasTersediaParticipant(id) {
        return axios.get(API_URL + `kelas/participant/kelas-tersedia/detail/${id}`, {
            headers: {
                "Authorization": "Bearer " + JSON.parse(localStorage.getItem("token"))
            }
        })
    }

    getListJadwalKelasForEvaluasiParticipant(id, params = '') {
        return axios.get(API_URL + `kelas/participant/evaluasi/jadwal-kelas/${id}?${params}`, {
            headers: {
                "Authorization": "Bearer " + JSON.parse(localStorage.getItem("token"))
            }
        })
    }

    getListJadwalKelasParticipant(id, params = '') {
        return axios.get(API_URL + `kelas/participant/jadwal-kelas/${id}?${params}`, {
            headers: {
                "Authorization": "Bearer " + JSON.parse(localStorage.getItem("token"))
            }
        })
    }

    getListKelasSayaParticipant(params = '') {
        return axios.get(API_URL + `kelas/participant/kelas-saya?${params}`, {
            headers: {
                "Authorization": "Bearer " + JSON.parse(localStorage.getItem("token"))
            }
        })
    }
    getListKelasTersediaParticipant(params = '') {
        return axios.get(API_URL + `kelas/participant/kelas-tersedia?${params}`, {
            headers: {
                "Authorization": "Bearer " + JSON.parse(localStorage.getItem("token"))
            }
        })
    }

    getListKelasHistoryParticipant(params = '') {
        return axios.get(API_URL + `kelas/participant/kelas-saya/history?${params}`, {
            headers: {
                "Authorization": "Bearer " + JSON.parse(localStorage.getItem("token"))
            }
        })
    }

    // END Participant Area

    // Akademik Area

    uploadMateriContentJadwalKelasAkademik(bodyFormData, materiId, jadwalKelasId, onUploadProgress) {
        return axios.post(API_URL + 'materi/akademik/kelas-jadwal/'+jadwalKelasId+'/upload-content-materi/' + materiId, bodyFormData, {
            headers: {
                "Authorization": "Bearer " + JSON.parse(localStorage.getItem("token")),
                'Content-Type': 'multipart/form-data'
            },
            onUploadProgress: onUploadProgress
        })
    }
    deleteMateriJadwalKelasAkademik(materiId, jadwalKelasId) {
        return axios.delete(API_URL + 'materi/akademik/kelas-jadwal/'+jadwalKelasId+'/delete-materi/'+materiId, {
            headers: {
                "Authorization": "Bearer " + JSON.parse(localStorage.getItem("token"))
            }
        })
    }
    addMateriJadwalKelasAkademik(body, jadwalKelasId) {
        return axios.post(API_URL + 'materi/akademik/kelas-jadwal/'+jadwalKelasId+'/add-materi', body, {
            headers: {
                "Authorization": "Bearer " + JSON.parse(localStorage.getItem("token"))
            }
        })
    }

    updateStatusMateriJadwalKelasAkademik(body, kelasJadwalId, jadwalKelasMateriId) {
        return axios.put(API_URL + `materi/akademik/kelas-jadwal/${kelasJadwalId}/update-materi-status/${jadwalKelasMateriId}`, body, {
            headers: {
                "Authorization": "Bearer " + JSON.parse(localStorage.getItem("token"))
            }
        })
    }

    getListTutorAkademik() {
        return axios.get(USER_URL + 'tutor/get', {
            headers: {
                "Authorization": "Bearer " + JSON.parse(localStorage.getItem("token"))
            }
        })
    }

    getListMateriJadwalKelasAkademik(params = '', jadwalKelasId) {
        return axios.get(API_URL + 'materi/akademik/kelas-jadwal/'+jadwalKelasId+'/list-materi?' + params, {
            headers: {
                "Authorization": "Bearer " + JSON.parse(localStorage.getItem("token"))
            }
        })
    }


    getListJadwalKelasAkademik(params = '', kelasId) {
        return axios.get(API_URL + 'kelas/akademik/jadwal-kelas/get/' + kelasId + '?' + params, {
            headers: {
                "Authorization": "Bearer " + JSON.parse(localStorage.getItem("token"))
            }
        })
    }

    addJadwalKelasAkademik(body, kelasId) {
        return axios.post(API_URL + 'kelas/akademik/jadwal-kelas/create/' + kelasId, body, {
            headers: {
                "Authorization": "Bearer " + JSON.parse(localStorage.getItem("token"))
            }
        })
    }

    updateJadwalKelasAkademik(body, kelasId, jadwalKelasId) {
        return axios.put(API_URL + 'kelas/akademik/jadwal-kelas/update/'+kelasId+'/' + jadwalKelasId, body, {
            headers: {
                "Authorization": "Bearer " + JSON.parse(localStorage.getItem("token"))
            }
        })
    }

    deleteJadwalKelasAkademik(kelasId, jadwalKelasId) {
        return axios.delete(API_URL + 'kelas/akademik/jadwal-kelas/delete/'+kelasId+'/' + jadwalKelasId, {
            headers: {
                "Authorization": "Bearer " + JSON.parse(localStorage.getItem("token"))
            }
        })
    }

    getListProfileKelasAllAkademik(params = '') {
        return axios.get(API_URL + 'kelas/akademik/profile/get?' + params, {
            headers: {
                "Authorization": "Bearer " + JSON.parse(localStorage.getItem("token"))
            }
        })
    }

    getListKelasAkademik(params = '') {
        return axios.get(API_URL + 'kelas/akademik/get?' + params, {
            headers: {
                "Authorization": "Bearer " + JSON.parse(localStorage.getItem("token"))
            }
        })
    }

    getListKelasAktivasiAkademik(params = '') {
        return axios.get(API_URL + 'kelas/akademik/activation/get?' + params, {
            headers: {
                "Authorization": "Bearer " + JSON.parse(localStorage.getItem("token"))
            }
        })
    }

    getListKelasAktivasiDetailAkademik(kelasId, params = '') {
        return axios.get(API_URL + `kelas/akademik/activation/kelas/${kelasId}/participants?` + params, {
            headers: {
                "Authorization": "Bearer " + JSON.parse(localStorage.getItem("token"))
            }
        })
    }

    addKelasAkademik(body) {
        return axios.post(API_URL + 'kelas/akademik/create', body, {
            headers: {
                "Authorization": "Bearer " + JSON.parse(localStorage.getItem("token"))
            }
        })
    }

    updateKelasAkademik(body, id) {
        return axios.put(API_URL + 'kelas/akademik/update/' + id, body, {
            headers: {
                "Authorization": "Bearer " + JSON.parse(localStorage.getItem("token"))
            }
        })
    }

    deleteKelasAkademik(id) {
        return axios.delete(API_URL + 'kelas/akademik/delete/' + id, {
            headers: {
                "Authorization": "Bearer " + JSON.parse(localStorage.getItem("token"))
            }
        })
    }

    getListElearningProgramAkademik(params = '') {
        return axios.get(API_URL + 'elearning-program/akademik/get?' + params, {
            headers: {
              "Authorization": "Bearer " + JSON.parse(localStorage.getItem("token")),
            }
        });
    }

    addElearningProgramAkademik(body) {
        return axios.post(API_URL + 'elearning-program/akademik/create', body, {
            headers: {
              "Authorization": "Bearer " + JSON.parse(localStorage.getItem("token")),
            }
        })
    }

    updateElearningProgramAkademik(body, id) {
        return axios.put(API_URL + 'elearning-program/akademik/update/' + id, body, {
            headers: {
              "Authorization": "Bearer " + JSON.parse(localStorage.getItem("token")),
            }
        })
    }

    deleteElarningProgramAkademik(id) {
        return axios.delete(API_URL + 'elearning-program/akademik/delete/' + id, {
            headers: {
                "Authorization": "Bearer " + JSON.parse(localStorage.getItem("token"))
            }
        })
    }

    // END Akademik Area

    // Tutor Area

    getListKelasParticipantTutor(kelasId, params = '') {
        return axios.get(API_URL + `kelas/tutor/kelas/${kelasId}/participants?` + params, {
            headers: {
                "Authorization": "Bearer " + JSON.parse(localStorage.getItem("token"))
            }
        })
    }

    getListKelasTutor(params = '') {
        return axios.get(API_URL + 'kelas/tutor/get?' + params, {
            headers: {
                "Authorization": "Bearer " + JSON.parse(localStorage.getItem("token"))
            }
        })
    }

    getListJadwalKelasTutor(params = '', kelasId) {
        return axios.get(API_URL + 'kelas/akademik/jadwal-kelas/get/' + kelasId + '?' + params, {
            headers: {
                "Authorization": "Bearer " + JSON.parse(localStorage.getItem("token"))
            }
        })
    }

    getListMateriJadwalKelasTutor(params = '', jadwalKelasId) {
        return axios.get(API_URL + 'materi/tutor/kelas-jadwal/'+jadwalKelasId+'/list-materi?' + params, {
            headers: {
                "Authorization": "Bearer " + JSON.parse(localStorage.getItem("token"))
            }
        })
    }

    uploadMateriContentJadwalKelasTutor(bodyFormData, materiId, jadwalKelasId, onUploadProgress) {
        return axios.post(API_URL + 'materi/tutor/kelas-jadwal/'+jadwalKelasId+'/upload-content-materi/' + materiId, bodyFormData, {
            headers: {
                "Authorization": "Bearer " + JSON.parse(localStorage.getItem("token")),
                'Content-Type': 'multipart/form-data'
            },
            onUploadProgress: onUploadProgress
        })
    }

    getListAbsensiKelasTutor(kelasId, params = '') {
        return axios.get(API_URL + `absensi-kelas/${kelasId}?${params}`, {
            headers: {
                "Authorization": "Bearer " + JSON.parse(localStorage.getItem("token"))
            }
        })
    }

    downloadRekapAbsensiTutor(kelasId) {
        return axios.get(API_URL + `absensi-kelas/${kelasId}/download`, {
            headers: {
                "Authorization": "Bearer " + JSON.parse(localStorage.getItem("token"))
            }
        })
    }

    submitAbsen(kelasId, body) {
        return axios.post(API_URL + `/absensi-kelas/${kelasId}`, body, {
            headers: {
                "Authorization": "Bearer " + JSON.parse(localStorage.getItem("token"))
            }
        })
    }

    getListJurnalTutor() {
        return axios.get(API_URL + `jurnal-mengajar/get`, {
            headers: {
                "Authorization": "Bearer " + JSON.parse(localStorage.getItem("token"))
            }
        })
    }

    getDetailJurnalTutor(jurnalId) {
        return axios.get(API_URL + `jurnal-mengajar/get/${jurnalId}`, {
            headers: {
                "Authorization": "Bearer " + JSON.parse(localStorage.getItem("token"))
            }
        })
    }

    uploadFileJurnalTutor(jurnalId, body) {
        return axios.put(API_URL + `jurnal-mengajar/update/${jurnalId}/upload`, body, {
            headers: {
                "Authorization": "Bearer " + JSON.parse(localStorage.getItem("token")),
                'Content-Type': 'multipart/form-data'
            }
        })
    }

    updateJurnalTutor(jurnalId, body) {
        return axios.put(API_URL + `jurnal-mengajar/update/${jurnalId}`, body, {
            headers: {
                "Authorization": "Bearer " + JSON.parse(localStorage.getItem("token"))
            }
        })
    }

    deleteJurnalTutor(jurnalId, body) {
        return axios.put(API_URL + `jurnal-mengajar/${jurnalId}/status`, body, {
            headers: {
                "Authorization": "Bearer " + JSON.parse(localStorage.getItem("token"))
            }
        })
    }

    // END Tutor Area
    downloadResiAdmin() {
        return axios.get(API_URL + `shipment/admin/download`, {
            headers: {
                "Authorization": "Bearer " + JSON.parse(localStorage.getItem("token"))
            }
        })
    }

    getListTutorForJurnalMengajarAkademik(id, params = '') {
        return axios.get(API_URL + `jurnal-mengajar/kelas/akademik/get/${id}?${params}`, {
            headers: {
                "Authorization": "Bearer " + JSON.parse(localStorage.getItem("token"))
            }
        })
    }

    getListTopicForJurnalMengajarAkademik(kelasid, id, params = '') {
        return axios.get(API_URL + `jurnal-mengajar/kelas/akademik/get/${id}/topic/${kelasid}?${params}`, {
            headers: {
                "Authorization": "Bearer " + JSON.parse(localStorage.getItem("token"))
            }
        })
    }

    getDetailJurnalMengajarAkademik(id) {
        return axios.get(API_URL + `jurnal-mengajar/akademik/detail/${id}`, {
            headers: {
                "Authorization": "Bearer " + JSON.parse(localStorage.getItem("token"))
            }
        })
    }

    async downloadListParticipant(id) {
        return axios.get(API_URL + `kelas/admin/elearning-participant/get/${id}/download`, {
            headers: {
                "Authorization": "Bearer " + JSON.parse(localStorage.getItem("token"))
            }
        })
    }

    previewMateriContentJadwalKelasAkademik(materiid, kelasjadwalid) {
        return axios.get(API_URL + `/materi/akademik/kelas-jadwal/${kelasjadwalid}/preview/${materiid}`, {
            headers: {
                "Authorization": "Bearer " + JSON.parse(localStorage.getItem("token"))
            }
        })
    }

    getListDistrictSuggest(keyword) {
        return axios.get(API_URL + `/shipment/participant/district-suggest/get?keyword=${keyword}`, {
            headers: {
                "Authorization": "Bearer " + JSON.parse(localStorage.getItem("token"))
            }
        })
    }
}

export default new ElearningService();