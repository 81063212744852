import React from 'react';

export default function RocketCard(props) {
  return (
    <React.Fragment>
      <div>
        <img src={props.img_url} alt="Testimoni" className="img-fluid mx-auto" style={{width: '100%'}}></img>
      </div>
    </React.Fragment>
  )
}