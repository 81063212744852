import axios from "axios";

const API_URL = `${process.env.REACT_APP_ECOURSEURL}`


axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('token');
class EcourseService {
    orderEcourseParticipant(body) {
        return axios.post(API_URL + `ecourse-participant/register`, body, {
            headers: {
                "Authorization": "Bearer " + JSON.parse(localStorage.getItem("token")),
                
            }
        })
    }
    getListEcourseParticipant() {
        return axios.get(`${process.env.REACT_APP_TRYOUTURL}transaction/participant/get`, {
            headers: {
                "Authorization": "Bearer " + JSON.parse(localStorage.getItem("token"))
            }
        })
    }
    getListContentEcourseParticipant(id, params = '') {
        return axios.get(API_URL + `content/participant/get-by-ecourse/${id}?${params}`, {
            headers: {
                "Authorization": "Bearer " + JSON.parse(localStorage.getItem("token")),
                
            }
        })
    }
    getListMyEcourseParticipant(params = '') {
        return axios.get(API_URL + `ecourse-participant/participant/get?${params}`, {
            headers: {
                "Authorization": "Bearer " + JSON.parse(localStorage.getItem("token")),
                
            }
        })
    }
    getListEcourseAvailableParticipant(params = '') {
        return axios.get(API_URL + `e-course/participant/get?${params}`, {
            headers: {
                "Authorization": "Bearer " + JSON.parse(localStorage.getItem("token")),
                
            }
        })
    }
    getListEcourseContentAkademik(id, params = '') {
        return axios.get(API_URL + `content/akademik/get/${id}/ecourse?${params}`, {
            headers: {
                "Authorization": "Bearer " + JSON.parse(localStorage.getItem("token")),
                
            }
        })
    }
    getListEcourseAkademik(params = '') {
        return axios.get(API_URL + `e-course/akademik/get?${params}`, {
            headers: {
                "Authorization": "Bearer " + JSON.parse(localStorage.getItem("token")),
                
            }
        })
    }
    uploadFile(body, onUploadProgress) {
        return axios.post(API_URL + `e-course/akademik/upload`, body, {
            headers: {
                "Authorization": "Bearer " + JSON.parse(localStorage.getItem("token")),
                'Content-Type': 'multipart/form-data'
            },
            onUploadProgress: onUploadProgress
        })
    }
    addEcourseAkamdeik(body) {
        return axios.post(API_URL + `e-course/akademik/create`, body, {
            headers: {
                "Authorization": "Bearer " + JSON.parse(localStorage.getItem("token"))
            }
        })
    }
    updateEcourseAkamdeik(id, body) {
        return axios.put(API_URL + `e-course/akademik/update/${id}`, body, {
            headers: {
                "Authorization": "Bearer " + JSON.parse(localStorage.getItem("token"))
            }
        })
    }
    updateContentEcourseAkademik(ecourseId, contentId, body) {
        return axios.put(API_URL + `content/akademik/update/${ecourseId}/ecourse/${contentId}/content`, body, {
            headers: {
                "Authorization": "Bearer " + JSON.parse(localStorage.getItem("token"))
            }
        })
    }
    addContentEcourseAkademik(id, body) {
        return axios.post(API_URL + `content/akademik/create/${id}/ecourse`, body, {
            headers: {
                "Authorization": "Bearer " + JSON.parse(localStorage.getItem("token"))
            }
        })
    }
    deleteContentEcourseAkademik(id) {
        return axios.delete(API_URL + `content/akademik/delete/${id}/content`, {
            headers: {
                "Authorization": "Bearer " + JSON.parse(localStorage.getItem("token"))
            }
        })
    }

    async downloadListParticipant(id) {
        return axios.get(process.env.REACT_APP_ELEARNINGURL + `kelas/admin/ecourse-participant/get/${id}/download`, {
            headers: {
                "Authorization": "Bearer " + JSON.parse(localStorage.getItem("token"))
            }
        })
    }

    async inputRatingEcourse(body) {
        return axios.post(API_URL + `e-course/rating/participant/create`, body, {
            headers: {
                "Authorization": "Bearer " + JSON.parse(localStorage.getItem("token"))
            }
        })
    }
}

export default new EcourseService();