import React from 'react';
import {
    ProgramPreklinik,
} from '../components/programpreklinik';
import {
    IngenioFooterBlue,
    IngenioNavBlue
} from '../components/layout'

export default function ProgramPreklinikPage() {
    return (
        <React.Fragment>
            <IngenioNavBlue />
            <div style={{ backgroundColor: 'white' }}>
                <ProgramPreklinik />
            </div>
            <IngenioFooterBlue />
        </React.Fragment>

    )
}