import React, { useState } from 'react';

export default function ProgramBimbinganCoas() {

    return (
        <React.Fragment>
            <section className="container-fluid">
                <div className="site-header site-header--menu-left landing-11-menu site-header--absolute" style={{ backgroundPositionY: 'bottom', backgroundPositionX: 'right', backgroundRepeat: 'no-repeat', paddingTop: '6rem', backgroundColor: '#E54081' }}>
                    <div className="py-5" style={{ paddingBottom: '2rem !important' }}>
                        <h5 className="sub-title text-center">Kelas Bimbingan</h5>
                        <h5 className="tentang_title g-title">Pre-Coass</h5>

                    </div>
                </div>
            </section>
            <section className="container-fluid bg-desktop">
                <div className="container px-4" style={{ paddingTop: '10rem', width: '85%', paddingBottom: '5rem' }}>
                    <div className="row g-2" style={{ textAlign: 'left', paddingTop: '12.5rem' }}>
                        <div className="col-lg-6 col-md-12">
                            <img src="/assets/landing/precoas-1.png" />
                        </div>
                        <div className="col-lg-6 col-md-12 mt5r">
                            {/* <h5 className="txt32px" style={{ textDecoration: 'none' }}>Bimbingan CBT UKOM Kebidanan (Online)</h5> */}
                            <h5 className="txt20px prl5r" style={{ marginBottom: '1rem', lineHeight: '30px' }}>Merupakan program bimbingan di Ingenio yang mempersiapkan mahasiswa tahap preklinik yang akan memasuki tahap klinik (coass)  Kedokteran untuk menghadapi memahami materi stase yang dihadapi secara daring (online) secara menyenangkan dan komprehensif
                            </h5>
                        </div>
                    </div>
                    <h5 className="txt24px mt1r" style={{ marginBottom: '0.2rem', lineHeight: '30px' }}>Dengan berbagai fasilitas penunjang yaitu:</h5>
                    <h5 className="txt20px mt1r" style={{ marginBottom: '1rem', lineHeight: '30px' }}>a.	Metode pembelajaran dengan penanaman pola pikir logis dan sederhana ala Ingenio<br />
                        b.	Kelas sistematis,  interaktif via Zoom, dan dapat diikuti oleh peserta FK di seluruh Indonesia<br />
                        c.	Materi review bimbingan stase<br />
                        d.	Jadwal bimbingan yang dapat disepakati bersama dengan tim akademik<br />
                        e.	Diampu oleh tutor terbaik yang telah mengikuti standarisasi serta penjaminan kualitas Ingenio Indonesia<br />
                        f.	Konsultasi tutor 7x 24 jam selama masa bimbingan



                    </h5>
                    <a href="#" className="txt20px" style={{ color: '#FCA61F', textDecoration: 'underline' }}>Download Brosur Bimbingan Pre-coass
                    </a>
                </div>
            </section>
        </React.Fragment >
    )
}

