import React, { useState, useEffect } from 'react';
import {
  SideBar
} from '../../../components/layout/dashboard';
import { useParams, useHistory } from "react-router-dom";
import { Link, useLocation } from 'react-router-dom';
import { Form, FormGroup, Modal } from 'react-bootstrap';
import Icon from '@material-tailwind/react/Icon';
import ElearningService from '../../../services/elearning';
import moment from 'moment-timezone';
import Swal from 'sweetalert2';
import axios from 'axios';
import { addCommas, contactLink } from '../../../helpers/utils';

export default function DetailKelasTersedia (props) {
    document.title = "INGENIO Indonesia";

    const [programs, setPrograms] = useState({});
    const [dataTryout, setDataTryout] = useState({})
    const [dataSubTryout, setDataSubTryout] = useState([]);
    const [total, setTotal] = useState(0); 
    const [isLoading, setIsLoading] = useState(true);

    // Forms
    const [kelasId, setKelasId] = useState('');
    const [namaKelas, setNamaKelas] = useState('')
    const [namaProgram, setNamaProgram] = useState('')
    const [namaRegio, setNamaRegio] = useState('')
    const [kapasitasKelas, setKapasitasKelas] = useState(0)
    const [sisaSeat, setSisaSeat] = useState(0)
    const [biayaKelas, setBiayaKelas] = useState('')
    const [biayaPengiriman, setBiayaPengiriman] = useState(0)
    const [includeTryout, setIncludeTryout] = useState(null)

    const [listPackages, setListPackages] = useState([])
    const [selectedPackage, setSelectedPackage] = useState('');
    const [isShipment, setIsShipment] = useState(false)

    const { dispatch } = props;
    let { id } = useParams();

    const history = useHistory();

    const getSingleKelasTersedia = () => {
        setIsLoading(true)
        ElearningService.getSingleKelasTersediaParticipant(id).then(resp => {
            if(resp.data.success === true) {
                const response = resp.data.data;

                setKelasId(response.id);
                setNamaKelas(response.nama_kelas);
                setNamaProgram(response.elearning_program.nama_program)
                setNamaRegio(response.nama_regio)
                setKapasitasKelas(response.kapasitas)
                setSisaSeat(response.available_kelas)
                setBiayaKelas(response.harga_kelas)

                if(parseInt(response.elearning_program.weight_package) > 0) {
                    setIsShipment(true);
                    getAlamat(response.harga_kelas, parseInt(response.elearning_program.weight_package));
                }

                if(response.elearning_program_tryouts && response.elearning_program_tryouts !== null) {
                    if(response.elearning_program_tryouts.length > 0){
                        setIncludeTryout(response.elearning_program_tryouts[0].paket_tryout)
                    }
                }

            }
            setIsLoading(false)

            if(resp.data.code === 401){
                history.push('/signin');
                return;
            }
        }).catch(err => {
            setIsLoading(false)
            console.error(err)
        })
    }

    useEffect(() => {
        getSingleKelasTersedia()
    }, [])

    const getTariff = (hargaKelas, berat) => {
        axios.get(`${process.env.REACT_APP_ELEARNINGURL}shipment/participant/tariff/get?harga_kelas=${hargaKelas}&weight=${berat}`, {
            headers: {
              Authorization: `Bearer ${JSON.parse(localStorage.getItem('token'))}`
            }
          }).then(resp => {
            setListPackages(resp.data.data)


          }).catch(err => {
            let msg = 'Internal Server Error'
            console.error(err)
            if(err.response) {
                if(err.response.data.message) {
                    msg = err.response.data.message
                }
            }

            Fail(msg)
          })
    }

    const getAlamat = (hargaKelas, berat) => {
        axios.get(`${process.env.REACT_APP_AUTHURL}me/student`, {
            headers: {
              Authorization: `Bearer ${JSON.parse(localStorage.getItem('token'))}`
            }
          }).then(resp => {
            const profile = resp.data.data.profile
            const district = profile.district;
            const city = profile.city;

            if(district === null || city === null) {
                Swal.fire({  
                    title: 'Gagal!',  
                    text: 'Data alamat kamu belum lengkap, silahkan lengkapi di halaman profile',  
                    icon: 'warning',           
                    showCancelButton: false,              
                    confirmButtonText: 'OK'  
                }).then((result) => {
                    if (result.isConfirmed) {
                        history.push('/dashboard/profil-saya');
                    }
                })
                return;
            }

            getTariff(hargaKelas, berat)


          }).catch(err => {
            let msg = 'Internal Server Error'
            console.error(err)
            if(err.response) {
                if(err.response.data.message) {
                    msg = err.response.data.message
                }
            }

            Fail(msg)
          })
    }

    const Fail = (message) => {
        Swal.fire({  
            title: 'Gagal!',  
            text: message,  
            icon: 'warning',  
            showCancelButton: true,  
            cancelButtonText: 'Kembali',           
            showConfirmButton: false,  
        });
      }

    return(
        <React.Fragment>
        <SideBar />
        <div className="md:ml-64">
            {/* <div className="bg-orange px-3 md:px-8 py-1 h-40">
                <div className="container md:pr-8 md:pl-10">
                    <h3 className="text-left tracking-wider title-dashboard">Selamat datang di Kelas Bimbingan Intensif Ingenio Indonesia</h3>
                </div>           
            </div>

            <div className="px-3 md:px-8 -mt-24">
                <div className="container mx-auto max-w-full">
                    <div className="grid grid-cols-2 xl:grid-cols-5">
                        <div className="xl:col-start-1 xl:col-end-4 px-4 mb-14">
                            <p>Anda dapat menghubungi Admin terlebih dahulu untuk memastikan kelas dan program yang Anda daftarkan.</p><br />
                            <p><u>Kontak Admin</u></p>
                        </div>
                    </div>
                </div>
            </div> */}

            <div className="container md:pr-8 md:pl-10"  style={{position: 'absolute', top: '80px'}}>
                <h3 className="text-left tracking-wider title-dashboard">Selamat datang di Kelas Bimbingan Intensif Ingenio Indonesia</h3>
                <br />
                <h3 className="text-left tracking-wider">Anda dapat menghubungi Admin terlebih dahulu untuk memastikan kelas dan program yang Anda daftarkan. <br /> <br />Kontak Admin <a href={contactLink()} target={'_blank'}> <i class="fa fa-whatsapp" aria-hidden="true"></i></a></h3>
            </div> 

            <div className="px-3 md:px-8">
                <div className="container mx-auto max-w-full">
                    <p className="breadcrumbss py-4">Kelas Tersedia / <span style={{fontWeight: 'bold'}}>Detail Kelas</span></p>
                    <div className="card-gede">
                        <div className="card-head px-4 py-4" >
                            Detail Kelas - <b>{namaKelas}</b>
                        </div>
                        <div className="row">
                            <div className="col-lg-6 col-md-6 col-xs-12 py-4 px-5">
                                <p style={{fontSize:"18px !important", lineHeight: "22px", letterSpacing: "0.06em", color: "#000000", fontWeight: 'bold'}}>Detail E-Learning</p> <br />
                                <table style={{marginBottom: '15px'}}>
                                    <tr>
                                        <td style={{padding: '0px 10px 0px 0px'}}>Nama Kelas E-Learning</td>
                                        <td style={{padding: '0px 10px 0px 0px'}}>:</td>
                                        <td style={{padding: '0px'}}><b>{namaKelas}</b></td>
                                    </tr>
                                    <tr>
                                        <td style={{padding: '0px'}}>Program Bimbingan</td>
                                        <td style={{padding: '0px'}}>:</td>
                                        <td style={{padding: '0px'}}><b>{namaProgram}</b></td>
                                    </tr>
                                    <tr>
                                        <td style={{padding: '0px'}}>Regio</td>
                                        <td style={{padding: '0px'}}>:</td>
                                        <td style={{padding: '0px'}}><b>{namaRegio}</b></td>
                                    </tr>
                                    <tr>
                                        <td style={{padding: '0px'}}>Kapasitas Kelas</td>
                                        <td style={{padding: '0px'}}>:</td>
                                        <td style={{padding: '0px'}}><b>{kapasitasKelas}</b></td>
                                    </tr>
                                    <tr>
                                        <td style={{padding: '0px'}}>Sisa Seat Tersedia</td>
                                        <td style={{padding: '0px'}}>:</td>
                                        <td style={{padding: '0px'}}><b>{sisaSeat}</b></td>
                                    </tr>
                                    {
                                        isShipment ?
                                        <>
                                            <tr>
                                                <td style={{padding: '0px'}}>Pilih paket pengiriman</td>
                                                <td style={{padding: '0px'}}>:</td>
                                                <td style={{padding: '0px'}}>
                                                    <Form.Select
                                                        as="select"
                                                        aria-label="Paket Pengiriman"
                                                        aria-describedby="basic-addon1"
                                                        className="formInputJurnal"
                                                        onChange={(e) => {
                                                            setSelectedPackage(e.target.value.split(',')[0]);
                                                            setBiayaPengiriman(e.target.value.split(',')[1]);
                                                        }}
                                                    >
                                                        <option selected disabled >Pilih paket</option>
                                                        {
                                                            listPackages.map((item, i) => {
                                                                return <option value={`${item.service_type} - ${item.product},${item.total_normal_tariff}`}>{`${item.service_type} - ${item.product}`}</option>
                                                            })
                                                        }
                                                    </Form.Select>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{padding: '0px'}}>Biaya Pengiriman Modul</td>
                                                <td style={{padding: '0px'}}>:</td>
                                                <td style={{padding: '0px'}}><b>{'Rp'+addCommas(biayaPengiriman)}</b></td>
                                            </tr>
                                        </>
                                        :
                                        null
                                    }
                                    

                                    <tr>
                                        <td style={{padding: '0px'}}>Biaya Kelas</td>
                                        <td style={{padding: '0px'}}>:</td>
                                        <td style={{padding: '0px'}}><b>{'Rp'+addCommas(biayaKelas)}</b></td>
                                    </tr>
                                </table>
                                {/* <p>Nama Kelas E-Learning : <b>{namaKelas}</b></p> <br />
                                <p>Program Bimbingan : <b>{namaProgram}</b></p> <br />
                                <p>Regio : <b>{namaRegio}</b></p> <br />
                                <p>Kapasitas Kelas : <b>{kapasitasKelas}</b></p> <br />
                                <p>Sisa Seat Tersedia : <b>{sisaSeat}</b></p> <br />
                                <p>Biaya Kelas : <b>{`Rp${biayaKelas}`}</b></p> <br /> */}
                                 Saya telah yakin dan setuju dengan ketentuan kelas dan melakukan pembayaran sebelum kelas dimulai. <br /> <br />
                                {
                                    isShipment ?
                                        selectedPackage === null || selectedPackage === '' ?
                                        null
                                        :
                                        <Link className="pilih-tryout p-2"
                                            to={{
                                                pathname: "/dashboard/e-learning/kelas-tersedia/bayar",
                                                state: {
                                                    kelas_id: kelasId,
                                                    nama_program: namaProgram,
                                                    nama_regio: namaRegio,
                                                    nama_kelas: namaKelas,
                                                    biaya: biayaKelas,
                                                    is_package_include: isShipment,
                                                    biaya_pengiriman: biayaPengiriman,
                                                    service_pengiriman: selectedPackage
                                                }
                                            }}>
                                            <Icon color="black" size="base" name="shopping_cart" />
                                            <span className="px-2">Bayar Akses Kelas</span>
                                        </Link>
                                    :
                                    <Link className="pilih-tryout p-2"
                                        to={{
                                            pathname: "/dashboard/e-learning/kelas-tersedia/bayar",
                                            state: {
                                                kelas_id: kelasId,
                                                nama_program: namaProgram,
                                                nama_regio: namaRegio,
                                                nama_kelas: namaKelas,
                                                biaya: biayaKelas,
                                                is_package_include: isShipment,
                                                biaya_pengiriman: biayaPengiriman,
                                                service_pengiriman: selectedPackage
                                            }
                                        }}>
                                        <Icon color="black" size="base" name="shopping_cart" />
                                        <span className="px-2">Bayar Akses Kelas</span>
                                    </Link>
                                }
                                
                            </div>
                            <div className="col-lg-6 col-md-6 col-xs-12 py-4 px-5">
                                {
                                    includeTryout !== null ?
                                    <p style={{fontSize:"18px !important", lineHeight: "22px", letterSpacing: "0.06em", color: "#000000", fontWeight: 'bold'}}>* Termasuk Paket Tryout</p>
                                    :
                                    null
                                }
                                
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>      

        </div>
        </React.Fragment>
        
    )
}