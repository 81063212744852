import React from 'react';
import { Card, Image } from 'react-bootstrap';
import TutorCards from './TutorCards'
// import { TutorSlider } from './index';
// import { TutorSliderMobile } from './index';

export default function Tutor() {
    let baseURL = window.location.origin;
    const Column = {
        borderRadius: "10px",
        width: 250,
        height: 50,
        padding: "25px",
    };
    return (
        <React.Fragment>
            {/* desktop ver */}
            <section className="container-fluid d-none d-lg-block" style={{ backgroundImage: 'url(/assets/landing/bg-tutor.png)', backgroundSize: '100%', backgroundRepeat: 'no-repeat' }}>
                <div className="container px-4" style={{ paddingTop: '8.5rem' }}>
                    <div className="row align-items-center g-2 py-1">
                        <h1 className="text-center jd-home2" style={{ color: 'black', fontSize: '48px', paddingBottom: '10vh' }}>Tutor Ingenio Indonesia</h1>
                    </div>
                    <div className="row col-xl-10 col-md-12" style={{ marginLeft: 'auto', marginRight: 'auto' }}>
                        <TutorCards />
                        {/* <div className="col-xl-3 col-md-6 mb-4" style={{ paddingBottom: '10vh' }}>
                            <Card className="testimoni-slider mx-auto" style={{ border: 'none', backgroundColor: 'transparent', paddingTop: '7vh' }}>
                                <Card.Body style={{ padding: '0', background: 'linear-gradient(180deg, #7AF1CA 0%, #678D98 100%)', borderTopLeftRadius: '20px', borderTopRightRadius: '20px' }}>
                                    <Card.Title>
                                        <div className="col-12 py-4">
                                            <img src="/assets/landing/tutor-2.png" style={{ marginTop: '-45%', marginBottom: '-20%', marginRight: 'auto', marginLeft: 'auto' }}></img>
                                        </div>
                                    </Card.Title>
                                </Card.Body>
                                <Card.Body style={{ backgroundColor: '#FFFF', borderBottomLeftRadius: '20px', borderBottomRightRadius: '20px', boxShadow: '0 0 8px -3px black' }}>
                                    <Card.Text>
                                        <div className="row">
                                            <div className="col-12 px-5" style={{ textAlign: 'center' }}>
                                                <p style={{ color: '#000', fontSize: '16pt', fontFamily: 'Quicksand', fontWeight: 700 }}>
                                                    Nama Tutor
                                                </p>
                                                <p style={{ color: '#000', fontSize: '14pt', fontFamily: 'Quicksand', fontWeight: 400 }}>
                                                    Alumni FK
                                                </p>
                                                <p style={{ color: '#000', fontSize: '14pt', fontFamily: 'Quicksand', fontWeight: 400 }}>
                                                    Materi Bidang
                                                </p>
                                                <p style={{ color: '#000', fontSize: '14pt', fontFamily: 'Quicksand', fontWeight: 400 }}>
                                                    Mengajar Sejak Tahun
                                                </p>
                                                <p style={{ color: '#000', fontSize: '14pt', fontFamily: 'Quicksand', fontWeight: 400 }}>
                                                    Pekerjaan Saat Ini
                                                </p>
                                            </div>
                                        </div>

                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </div> */}
                        {/* <div className="col-xl-3 col-md-6 mb-4" style={{ paddingBottom: '10vh' }}>
                            <Card className="testimoni-slider mx-auto" style={{ border: 'none', backgroundColor: 'transparent', paddingTop: '7vh' }}>
                                <Card.Body style={{ padding: '0', background: 'linear-gradient(180deg, #7AF1CA 0%, #678D98 100%)', borderTopLeftRadius: '20px', borderTopRightRadius: '20px' }}>
                                    <Card.Title>
                                        <div className="col-12 py-4">
                                            <img src="/assets/landing/tutor-2.png" style={{ marginTop: '-45%', marginBottom: '-20%', marginRight: 'auto', marginLeft: 'auto' }}></img>
                                        </div>
                                    </Card.Title>
                                </Card.Body>
                                <Card.Body style={{ backgroundColor: '#FFFF', borderBottomLeftRadius: '20px', borderBottomRightRadius: '20px', boxShadow: '0 0 8px -3px black' }}>
                                    <Card.Text>
                                        <div className="row">
                                            <div className="col-12 px-5" style={{ textAlign: 'center' }}>
                                                <p style={{ color: '#000', fontSize: '16pt', fontFamily: 'Quicksand', fontWeight: 700 }}>
                                                    Nama Tutor
                                                </p>
                                                <p style={{ color: '#000', fontSize: '14pt', fontFamily: 'Quicksand', fontWeight: 400 }}>
                                                    Alumni FK
                                                </p>
                                                <p style={{ color: '#000', fontSize: '14pt', fontFamily: 'Quicksand', fontWeight: 400 }}>
                                                    Materi Bidang
                                                </p>
                                                <p style={{ color: '#000', fontSize: '14pt', fontFamily: 'Quicksand', fontWeight: 400 }}>
                                                    Mengajar Sejak Tahun
                                                </p>
                                                <p style={{ color: '#000', fontSize: '14pt', fontFamily: 'Quicksand', fontWeight: 400 }}>
                                                    Pekerjaan Saat Ini
                                                </p>
                                            </div>
                                        </div>

                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </div>
                        <div className="col-xl-3 col-md-6 mb-4" style={{ paddingBottom: '10vh' }}>
                            <Card className="testimoni-slider mx-auto" style={{ border: 'none', backgroundColor: 'transparent', paddingTop: '7vh' }}>
                                <Card.Body style={{ padding: '0', background: 'linear-gradient(180deg, #F8CA2B 0%, #FCA826 100%)', borderTopLeftRadius: '20px', borderTopRightRadius: '20px' }}>
                                    <Card.Title>
                                        <div className="col-12 py-4">
                                            <img src="/assets/landing/tutor-3.png" style={{ marginTop: '-37%', marginBottom: '-20%', marginRight: 'auto', marginLeft: 'auto' }}></img>
                                        </div>
                                    </Card.Title>
                                </Card.Body>
                                <Card.Body style={{ backgroundColor: '#FFFF', borderBottomLeftRadius: '20px', borderBottomRightRadius: '20px', boxShadow: '0 0 8px -3px black' }}>
                                    <Card.Text>
                                        <div className="row">
                                            <div className="col-12 px-5" style={{ textAlign: 'center' }}>
                                                <p style={{ color: '#000', fontSize: '16pt', fontFamily: 'Quicksand', fontWeight: 700 }}>
                                                    Nama Tutor
                                                </p>
                                                <p style={{ color: '#000', fontSize: '14pt', fontFamily: 'Quicksand', fontWeight: 400 }}>
                                                    Alumni FK
                                                </p>
                                                <p style={{ color: '#000', fontSize: '14pt', fontFamily: 'Quicksand', fontWeight: 400 }}>
                                                    Materi Bidang
                                                </p>
                                                <p style={{ color: '#000', fontSize: '14pt', fontFamily: 'Quicksand', fontWeight: 400 }}>
                                                    Mengajar Sejak Tahun
                                                </p>
                                                <p style={{ color: '#000', fontSize: '14pt', fontFamily: 'Quicksand', fontWeight: 400 }}>
                                                    Pekerjaan Saat Ini
                                                </p>
                                            </div>
                                        </div>

                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </div> */}
                    </div>

                </div>
            </section>
            {/* mobile ver */}
            <section className="container-fluid d-lg-none d-lg-none" style={{ backgroundImage: 'url(/assets/landing/bg-tutor.png)', backgroundSize: '100%', backgroundRepeat: 'no-repeat' }}>
                <div className="container px-4" style={{ paddingTop: '6.5rem' }}>
                    <div className="row align-items-center g-2 py-1">
                        <h1 className="text-center jd-home2" style={{ color: 'black', fontSize: '32px', paddingBottom: '5vh' }}>Tutor Ingenio Indonesia</h1>
                    </div>
                    <div className="row col-xl-10 col-md-12" style={{ marginLeft: 'auto', marginRight: 'auto' }}>
                        <div className="col-6 mb-4" style={{ paddingBottom: '1vh' }}>
                            <Card className="testimoni-slider mx-auto" style={{ border: 'none', backgroundColor: 'transparent', paddingTop: '7vh' }}>
                                <Card.Body style={{ padding: '0', background: 'linear-gradient(180deg, #F8CA2B 0%, #FCA826 100%)', borderTopLeftRadius: '20px', borderTopRightRadius: '20px' }}>
                                    <Card.Title>
                                        <div className="col-12 py-4">
                                            <img src="/assets/landing/tutor-1.png" style={{ marginTop: '-50%', marginBottom: '-25%', marginRight: 'auto', marginLeft: 'auto' }}></img>
                                        </div>
                                    </Card.Title>
                                </Card.Body>
                                <Card.Body style={{ backgroundColor: '#FFFF', borderBottomLeftRadius: '20px', borderBottomRightRadius: '20px', boxShadow: '0 0 8px -3px black' }}>
                                    <Card.Text>
                                        <div className="row">
                                            <div className="col-12" style={{ textAlign: 'center' }}>
                                                <p style={{ color: '#000', fontSize: '8px', fontFamily: 'Quicksand', fontWeight: 700 }}>
                                                    Nama Tutor
                                                </p>
                                                <p style={{ color: '#000', fontSize: '8px', fontFamily: 'Quicksand', fontWeight: 400 }}>
                                                    Alumni FK
                                                </p>
                                                <p style={{ color: '#000', fontSize: '8px', fontFamily: 'Quicksand', fontWeight: 400 }}>
                                                    Materi Bidang
                                                </p>
                                                <p style={{ color: '#000', fontSize: '8px', fontFamily: 'Quicksand', fontWeight: 400 }}>
                                                    Mengajar Sejak Tahun
                                                </p>
                                                <p style={{ color: '#000', fontSize: '8px', fontFamily: 'Quicksand', fontWeight: 400 }}>
                                                    Pekerjaan Saat Ini
                                                </p>
                                            </div>
                                        </div>

                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </div>
                        <div className="col-6 mb-4" style={{ paddingBottom: '1vh' }}>
                            <Card className="testimoni-slider mx-auto" style={{ border: 'none', backgroundColor: 'transparent', paddingTop: '7vh' }}>
                                <Card.Body style={{ padding: '0', background: 'linear-gradient(180deg, #7AF1CA 0%, #678D98 100%)', borderTopLeftRadius: '20px', borderTopRightRadius: '20px' }}>
                                    <Card.Title>
                                        <div className="col-12 py-4">
                                            <img src="/assets/landing/tutor-1.png" style={{ marginTop: '-50%', marginBottom: '-25%', marginRight: 'auto', marginLeft: 'auto' }}></img>
                                        </div>
                                    </Card.Title>
                                </Card.Body>
                                <Card.Body style={{ backgroundColor: '#FFFF', borderBottomLeftRadius: '20px', borderBottomRightRadius: '20px', boxShadow: '0 0 8px -3px black' }}>
                                    <Card.Text>
                                        <div className="row">
                                            <div className="col-12" style={{ textAlign: 'center' }}>
                                                <p style={{ color: '#000', fontSize: '8px', fontFamily: 'Quicksand', fontWeight: 700 }}>
                                                    Nama Tutor
                                                </p>
                                                <p style={{ color: '#000', fontSize: '8px', fontFamily: 'Quicksand', fontWeight: 400 }}>
                                                    Alumni FK
                                                </p>
                                                <p style={{ color: '#000', fontSize: '8px', fontFamily: 'Quicksand', fontWeight: 400 }}>
                                                    Materi Bidang
                                                </p>
                                                <p style={{ color: '#000', fontSize: '8px', fontFamily: 'Quicksand', fontWeight: 400 }}>
                                                    Mengajar Sejak Tahun
                                                </p>
                                                <p style={{ color: '#000', fontSize: '8px', fontFamily: 'Quicksand', fontWeight: 400 }}>
                                                    Pekerjaan Saat Ini
                                                </p>
                                            </div>
                                        </div>

                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </div>
                        <div className="col-6 mb-4" style={{ paddingBottom: '1vh' }}>
                            <Card className="testimoni-slider mx-auto" style={{ border: 'none', backgroundColor: 'transparent', paddingTop: '7vh' }}>
                                <Card.Body style={{ padding: '0', background: 'linear-gradient(180deg, #7AF1CA 0%, #678D98 100%)', borderTopLeftRadius: '20px', borderTopRightRadius: '20px' }}>
                                    <Card.Title>
                                        <div className="col-12 py-4">
                                            <img src="/assets/landing/tutor-1.png" style={{ marginTop: '-50%', marginBottom: '-25%', marginRight: 'auto', marginLeft: 'auto' }}></img>
                                        </div>
                                    </Card.Title>
                                </Card.Body>
                                <Card.Body style={{ backgroundColor: '#FFFF', borderBottomLeftRadius: '20px', borderBottomRightRadius: '20px', boxShadow: '0 0 8px -3px black' }}>
                                    <Card.Text>
                                        <div className="row">
                                            <div className="col-12" style={{ textAlign: 'center' }}>
                                                <p style={{ color: '#000', fontSize: '8px', fontFamily: 'Quicksand', fontWeight: 700 }}>
                                                    Nama Tutor
                                                </p>
                                                <p style={{ color: '#000', fontSize: '8px', fontFamily: 'Quicksand', fontWeight: 400 }}>
                                                    Alumni FK
                                                </p>
                                                <p style={{ color: '#000', fontSize: '8px', fontFamily: 'Quicksand', fontWeight: 400 }}>
                                                    Materi Bidang
                                                </p>
                                                <p style={{ color: '#000', fontSize: '8px', fontFamily: 'Quicksand', fontWeight: 400 }}>
                                                    Mengajar Sejak Tahun
                                                </p>
                                                <p style={{ color: '#000', fontSize: '8px', fontFamily: 'Quicksand', fontWeight: 400 }}>
                                                    Pekerjaan Saat Ini
                                                </p>
                                            </div>
                                        </div>

                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </div>
                        <div className="col-6 mb-4" style={{ paddingBottom: '1vh' }}>
                            <Card className="testimoni-slider mx-auto" style={{ border: 'none', backgroundColor: 'transparent', paddingTop: '7vh' }}>
                                <Card.Body style={{ padding: '0', background: 'linear-gradient(180deg, #F8CA2B 0%, #FCA826 100%)', borderTopLeftRadius: '20px', borderTopRightRadius: '20px' }}>
                                    <Card.Title>
                                        <div className="col-12 py-4">
                                            <img src="/assets/landing/tutor-1.png" style={{ marginTop: '-50%', marginBottom: '-25%', marginRight: 'auto', marginLeft: 'auto' }}></img>
                                        </div>
                                    </Card.Title>
                                </Card.Body>
                                <Card.Body style={{ backgroundColor: '#FFFF', borderBottomLeftRadius: '20px', borderBottomRightRadius: '20px', boxShadow: '0 0 8px -3px black' }}>
                                    <Card.Text>
                                        <div className="row">
                                            <div className="col-12" style={{ textAlign: 'center' }}>
                                                <p style={{ color: '#000', fontSize: '8px', fontFamily: 'Quicksand', fontWeight: 700 }}>
                                                    Nama Tutor
                                                </p>
                                                <p style={{ color: '#000', fontSize: '8px', fontFamily: 'Quicksand', fontWeight: 400 }}>
                                                    Alumni FK
                                                </p>
                                                <p style={{ color: '#000', fontSize: '8px', fontFamily: 'Quicksand', fontWeight: 400 }}>
                                                    Materi Bidang
                                                </p>
                                                <p style={{ color: '#000', fontSize: '8px', fontFamily: 'Quicksand', fontWeight: 400 }}>
                                                    Mengajar Sejak Tahun
                                                </p>
                                                <p style={{ color: '#000', fontSize: '8px', fontFamily: 'Quicksand', fontWeight: 400 }}>
                                                    Pekerjaan Saat Ini
                                                </p>
                                            </div>
                                        </div>

                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </div>
                    </div>
                </div>
            </section>

            {/* Tutor 2 Desktop */}
            {/* <section className="container-fluid d-none d-lg-block" style={{ backgroundColor: '#F3F4F6', backgroundSize: '100%', backgroundRepeat: 'no-repeat' }}>
                <div className="container px-4" style={{ paddingTop: '3.5rem', paddingBottom: '2rem' }}>
                    <div className="row col-xl-10 col-md-12" style={{ marginLeft: 'auto', marginRight: 'auto' }}>
                        <div className="col-6 mb-4">
                            <Card className="testimoni-slider mx-auto">
                                <Card.Body style={{ boxShadow: '0 0 8px -3px black' }}>
                                    <div className="row">
                                        <div className="col-4">
                                            <img src="/assets/landing/dr1.png" style={{ marginRight: 'auto', marginLeft: 'auto', }}></img>
                                        </div>
                                        <div className="col-6" style={{ textAlign: 'left', marginTop: 'auto', marginBottom: 'auto' }}>
                                            <p style={{ color: '#000', fontSize: '14pt', fontFamily: 'Quicksand', fontWeight: 700 }}>
                                                Nama Tutor
                                            </p>
                                            <p style={{ color: '#000', fontSize: '14pt', fontFamily: 'Quicksand', fontWeight: 400 }}>
                                                Alumni FK
                                            </p>
                                            <p style={{ color: '#000', fontSize: '14pt', fontFamily: 'Quicksand', fontWeight: 400 }}>
                                                Materi Bidang
                                            </p>
                                            <p style={{ color: '#000', fontSize: '14pt', fontFamily: 'Quicksand', fontWeight: 400 }}>
                                                Mengajar Sejak Tahun
                                            </p>
                                            <p style={{ color: '#000', fontSize: '14pt', fontFamily: 'Quicksand', fontWeight: 400 }}>
                                                Pekerjaan Saat Ini
                                            </p>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                        </div>
                        <div className="col-6 mb-4">
                            <Card className="testimoni-slider mx-auto">
                                <Card.Body style={{ boxShadow: '0 0 8px -3px black' }}>
                                    <div className="row">
                                        <div className="col-4">
                                            <img src="/assets/landing/dr2.png" style={{ marginRight: 'auto', marginLeft: 'auto', }}></img>
                                        </div>
                                        <div className="col-6" style={{ textAlign: 'left', marginTop: 'auto', marginBottom: 'auto' }}>
                                            <p style={{ color: '#000', fontSize: '14pt', fontFamily: 'Quicksand', fontWeight: 700 }}>
                                                Nama Tutor
                                            </p>
                                            <p style={{ color: '#000', fontSize: '14pt', fontFamily: 'Quicksand', fontWeight: 400 }}>
                                                Alumni FK
                                            </p>
                                            <p style={{ color: '#000', fontSize: '14pt', fontFamily: 'Quicksand', fontWeight: 400 }}>
                                                Materi Bidang
                                            </p>
                                            <p style={{ color: '#000', fontSize: '14pt', fontFamily: 'Quicksand', fontWeight: 400 }}>
                                                Mengajar Sejak Tahun
                                            </p>
                                            <p style={{ color: '#000', fontSize: '14pt', fontFamily: 'Quicksand', fontWeight: 400 }}>
                                                Pekerjaan Saat Ini
                                            </p>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                        </div>
                        <div className="col-6 mb-4">
                            <Card className="testimoni-slider mx-auto">
                                <Card.Body style={{ boxShadow: '0 0 8px -3px black' }}>
                                    <div className="row">
                                        <div className="col-4">
                                            <img src="/assets/landing/dr3.png" style={{ marginRight: 'auto', marginLeft: 'auto', }}></img>
                                        </div>
                                        <div className="col-6" style={{ textAlign: 'left', marginTop: 'auto', marginBottom: 'auto' }}>
                                            <p style={{ color: '#000', fontSize: '14pt', fontFamily: 'Quicksand', fontWeight: 700 }}>
                                                Nama Tutor
                                            </p>
                                            <p style={{ color: '#000', fontSize: '14pt', fontFamily: 'Quicksand', fontWeight: 400 }}>
                                                Alumni FK
                                            </p>
                                            <p style={{ color: '#000', fontSize: '14pt', fontFamily: 'Quicksand', fontWeight: 400 }}>
                                                Materi Bidang
                                            </p>
                                            <p style={{ color: '#000', fontSize: '14pt', fontFamily: 'Quicksand', fontWeight: 400 }}>
                                                Mengajar Sejak Tahun
                                            </p>
                                            <p style={{ color: '#000', fontSize: '14pt', fontFamily: 'Quicksand', fontWeight: 400 }}>
                                                Pekerjaan Saat Ini
                                            </p>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                        </div>
                        <div className="col-6 mb-4">
                            <Card className="testimoni-slider mx-auto">
                                <Card.Body style={{ boxShadow: '0 0 8px -3px black' }}>
                                    <div className="row">
                                        <div className="col-4">
                                            <img src="/assets/landing/dr4.png" style={{ marginRight: 'auto', marginLeft: 'auto', }}></img>
                                        </div>
                                        <div className="col-6" style={{ textAlign: 'left', marginTop: 'auto', marginBottom: 'auto' }}>
                                            <p style={{ color: '#000', fontSize: '14pt', fontFamily: 'Quicksand', fontWeight: 700 }}>
                                                Nama Tutor
                                            </p>
                                            <p style={{ color: '#000', fontSize: '14pt', fontFamily: 'Quicksand', fontWeight: 400 }}>
                                                Alumni FK
                                            </p>
                                            <p style={{ color: '#000', fontSize: '14pt', fontFamily: 'Quicksand', fontWeight: 400 }}>
                                                Materi Bidang
                                            </p>
                                            <p style={{ color: '#000', fontSize: '14pt', fontFamily: 'Quicksand', fontWeight: 400 }}>
                                                Mengajar Sejak Tahun
                                            </p>
                                            <p style={{ color: '#000', fontSize: '14pt', fontFamily: 'Quicksand', fontWeight: 400 }}>
                                                Pekerjaan Saat Ini
                                            </p>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                        </div>

                    </div>

                </div>
            </section> */}

            {/* Tutor 2 Mobile */}
            {/* <section className="container-fluid d-lg-none" style={{ backgroundColor: '#F3F4F6', backgroundSize: '100%', backgroundRepeat: 'no-repeat' }}>
                <div className="container px-4" style={{ paddingTop: '5.5rem' }}>
                    <div className="row col-xl-10 col-md-12" style={{ marginLeft: 'auto', marginRight: 'auto' }}>
                        <div className="col-12 mb-4">
                            <Card className="testimoni-slider mx-auto">
                                <Card.Body style={{ boxShadow: '0 0 8px -3px black' }}>
                                    <div className="row">
                                        <div className="col-5">
                                            <img src="/assets/landing/dr1.png" style={{ marginRight: 'auto', marginLeft: 'auto', }}></img>
                                        </div>
                                        <div className="col-6" style={{ textAlign: 'left', marginTop: 'auto', marginBottom: 'auto' }}>
                                            <p style={{ color: '#000', fontSize: '8px', fontFamily: 'Quicksand', fontWeight: 700 }}>
                                                Nama Tutor
                                            </p>
                                            <p style={{ color: '#000', fontSize: '8px', fontFamily: 'Quicksand', fontWeight: 400 }}>
                                                Alumni FK
                                            </p>
                                            <p style={{ color: '#000', fontSize: '8px', fontFamily: 'Quicksand', fontWeight: 400 }}>
                                                Materi Bidang
                                            </p>
                                            <p style={{ color: '#000', fontSize: '8px', fontFamily: 'Quicksand', fontWeight: 400 }}>
                                                Mengajar Sejak Tahun
                                            </p>
                                            <p style={{ color: '#000', fontSize: '8px', fontFamily: 'Quicksand', fontWeight: 400 }}>
                                                Pekerjaan Saat Ini
                                            </p>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                        </div>
                        <div className="col-12 mb-4">
                            <Card className="testimoni-slider mx-auto">
                                <Card.Body style={{ boxShadow: '0 0 8px -3px black' }}>
                                    <div className="row">
                                        <div className="col-5">
                                            <img src="/assets/landing/dr2.png" style={{ marginRight: 'auto', marginLeft: 'auto', }}></img>
                                        </div>
                                        <div className="col-6" style={{ textAlign: 'left', marginTop: 'auto', marginBottom: 'auto' }}>
                                            <p style={{ color: '#000', fontSize: '8px', fontFamily: 'Quicksand', fontWeight: 700 }}>
                                                Nama Tutor
                                            </p>
                                            <p style={{ color: '#000', fontSize: '8px', fontFamily: 'Quicksand', fontWeight: 400 }}>
                                                Alumni FK
                                            </p>
                                            <p style={{ color: '#000', fontSize: '8px', fontFamily: 'Quicksand', fontWeight: 400 }}>
                                                Materi Bidang
                                            </p>
                                            <p style={{ color: '#000', fontSize: '8px', fontFamily: 'Quicksand', fontWeight: 400 }}>
                                                Mengajar Sejak Tahun
                                            </p>
                                            <p style={{ color: '#000', fontSize: '8px', fontFamily: 'Quicksand', fontWeight: 400 }}>
                                                Pekerjaan Saat Ini
                                            </p>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                        </div>
                        <div className="col-12 mb-4">
                            <Card className="testimoni-slider mx-auto">
                                <Card.Body style={{ boxShadow: '0 0 8px -3px black' }}>
                                    <div className="row">
                                        <div className="col-5">
                                            <img src="/assets/landing/dr3.png" style={{ marginRight: 'auto', marginLeft: 'auto', }}></img>
                                        </div>
                                        <div className="col-6" style={{ textAlign: 'left', marginTop: 'auto', marginBottom: 'auto' }}>
                                            <p style={{ color: '#000', fontSize: '8px', fontFamily: 'Quicksand', fontWeight: 700 }}>
                                                Nama Tutor
                                            </p>
                                            <p style={{ color: '#000', fontSize: '8px', fontFamily: 'Quicksand', fontWeight: 400 }}>
                                                Alumni FK
                                            </p>
                                            <p style={{ color: '#000', fontSize: '8px', fontFamily: 'Quicksand', fontWeight: 400 }}>
                                                Materi Bidang
                                            </p>
                                            <p style={{ color: '#000', fontSize: '8px', fontFamily: 'Quicksand', fontWeight: 400 }}>
                                                Mengajar Sejak Tahun
                                            </p>
                                            <p style={{ color: '#000', fontSize: '8px', fontFamily: 'Quicksand', fontWeight: 400 }}>
                                                Pekerjaan Saat Ini
                                            </p>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                        </div>
                        <div className="col-12 mb-4">
                            <Card className="testimoni-slider mx-auto">
                                <Card.Body style={{ boxShadow: '0 0 8px -3px black' }}>
                                    <div className="row">
                                        <div className="col-5">
                                            <img src="/assets/landing/dr4.png" style={{ marginRight: 'auto', marginLeft: 'auto', }}></img>
                                        </div>
                                        <div className="col-6" style={{ textAlign: 'left', marginTop: 'auto', marginBottom: 'auto' }}>
                                            <p style={{ color: '#000', fontSize: '8px', fontFamily: 'Quicksand', fontWeight: 700 }}>
                                                Nama Tutor
                                            </p>
                                            <p style={{ color: '#000', fontSize: '8px', fontFamily: 'Quicksand', fontWeight: 400 }}>
                                                Alumni FK
                                            </p>
                                            <p style={{ color: '#000', fontSize: '8px', fontFamily: 'Quicksand', fontWeight: 400 }}>
                                                Materi Bidang
                                            </p>
                                            <p style={{ color: '#000', fontSize: '8px', fontFamily: 'Quicksand', fontWeight: 400 }}>
                                                Mengajar Sejak Tahun
                                            </p>
                                            <p style={{ color: '#000', fontSize: '8px', fontFamily: 'Quicksand', fontWeight: 400 }}>
                                                Pekerjaan Saat Ini
                                            </p>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                        </div>
                    </div>

                </div>
            </section> */}

            {/* Tutor 3 Desktop */}
            {/* <section className="container-fluid d-none d-lg-block" style={{ backgroundColor: '#FFFF', backgroundSize: '100%', backgroundRepeat: 'no-repeat' }}>
                <div className="container px-4" style={{ paddingTop: '3.5rem', paddingBottom: '2rem' }}>
                    <div className="row col-xl-10 col-md-12" style={{ marginLeft: 'auto', marginRight: 'auto' }}>
                        <div className="col-6 mb-4">
                            <Card className="testimoni-slider mx-auto">
                                <Card.Body style={{ boxShadow: '0 0 8px -3px black' }}>
                                    <div className="row">
                                        <div className="col-4">
                                            <img src="/assets/landing/dr1.png" style={{ marginRight: 'auto', marginLeft: 'auto', }}></img>
                                        </div>
                                        <div className="col-6" style={{ textAlign: 'left', marginTop: 'auto', marginBottom: 'auto' }}>
                                            <p style={{ color: '#000', fontSize: '14pt', fontFamily: 'Quicksand', fontWeight: 700 }}>
                                                Nama Tutor
                                            </p>
                                            <p style={{ color: '#000', fontSize: '14pt', fontFamily: 'Quicksand', fontWeight: 400 }}>
                                                Alumni FK
                                            </p>
                                            <p style={{ color: '#000', fontSize: '14pt', fontFamily: 'Quicksand', fontWeight: 400 }}>
                                                Materi Bidang
                                            </p>
                                            <p style={{ color: '#000', fontSize: '14pt', fontFamily: 'Quicksand', fontWeight: 400 }}>
                                                Mengajar Sejak Tahun
                                            </p>
                                            <p style={{ color: '#000', fontSize: '14pt', fontFamily: 'Quicksand', fontWeight: 400 }}>
                                                Pekerjaan Saat Ini
                                            </p>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                        </div>
                        <div className="col-6 mb-4">
                            <Card className="testimoni-slider mx-auto">
                                <Card.Body style={{ boxShadow: '0 0 8px -3px black' }}>
                                    <div className="row">
                                        <div className="col-4">
                                            <img src="/assets/landing/dr2.png" style={{ marginRight: 'auto', marginLeft: 'auto', }}></img>
                                        </div>
                                        <div className="col-6" style={{ textAlign: 'left', marginTop: 'auto', marginBottom: 'auto' }}>
                                            <p style={{ color: '#000', fontSize: '14pt', fontFamily: 'Quicksand', fontWeight: 700 }}>
                                                Nama Tutor
                                            </p>
                                            <p style={{ color: '#000', fontSize: '14pt', fontFamily: 'Quicksand', fontWeight: 400 }}>
                                                Alumni FK
                                            </p>
                                            <p style={{ color: '#000', fontSize: '14pt', fontFamily: 'Quicksand', fontWeight: 400 }}>
                                                Materi Bidang
                                            </p>
                                            <p style={{ color: '#000', fontSize: '14pt', fontFamily: 'Quicksand', fontWeight: 400 }}>
                                                Mengajar Sejak Tahun
                                            </p>
                                            <p style={{ color: '#000', fontSize: '14pt', fontFamily: 'Quicksand', fontWeight: 400 }}>
                                                Pekerjaan Saat Ini
                                            </p>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                        </div>
                        <div className="col-6 mb-4">
                            <Card className="testimoni-slider mx-auto">
                                <Card.Body style={{ boxShadow: '0 0 8px -3px black' }}>
                                    <div className="row">
                                        <div className="col-4">
                                            <img src="/assets/landing/dr3.png" style={{ marginRight: 'auto', marginLeft: 'auto', }}></img>
                                        </div>
                                        <div className="col-6" style={{ textAlign: 'left', marginTop: 'auto', marginBottom: 'auto' }}>
                                            <p style={{ color: '#000', fontSize: '14pt', fontFamily: 'Quicksand', fontWeight: 700 }}>
                                                Nama Tutor
                                            </p>
                                            <p style={{ color: '#000', fontSize: '14pt', fontFamily: 'Quicksand', fontWeight: 400 }}>
                                                Alumni FK
                                            </p>
                                            <p style={{ color: '#000', fontSize: '14pt', fontFamily: 'Quicksand', fontWeight: 400 }}>
                                                Materi Bidang
                                            </p>
                                            <p style={{ color: '#000', fontSize: '14pt', fontFamily: 'Quicksand', fontWeight: 400 }}>
                                                Mengajar Sejak Tahun
                                            </p>
                                            <p style={{ color: '#000', fontSize: '14pt', fontFamily: 'Quicksand', fontWeight: 400 }}>
                                                Pekerjaan Saat Ini
                                            </p>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                        </div>
                        <div className="col-6 mb-4">
                            <Card className="testimoni-slider mx-auto">
                                <Card.Body style={{ boxShadow: '0 0 8px -3px black' }}>
                                    <div className="row">
                                        <div className="col-4">
                                            <img src="/assets/landing/dr4.png" style={{ marginRight: 'auto', marginLeft: 'auto', }}></img>
                                        </div>
                                        <div className="col-6" style={{ textAlign: 'left', marginTop: 'auto', marginBottom: 'auto' }}>
                                            <p style={{ color: '#000', fontSize: '14pt', fontFamily: 'Quicksand', fontWeight: 700 }}>
                                                Nama Tutor
                                            </p>
                                            <p style={{ color: '#000', fontSize: '14pt', fontFamily: 'Quicksand', fontWeight: 400 }}>
                                                Alumni FK
                                            </p>
                                            <p style={{ color: '#000', fontSize: '14pt', fontFamily: 'Quicksand', fontWeight: 400 }}>
                                                Materi Bidang
                                            </p>
                                            <p style={{ color: '#000', fontSize: '14pt', fontFamily: 'Quicksand', fontWeight: 400 }}>
                                                Mengajar Sejak Tahun
                                            </p>
                                            <p style={{ color: '#000', fontSize: '14pt', fontFamily: 'Quicksand', fontWeight: 400 }}>
                                                Pekerjaan Saat Ini
                                            </p>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                        </div>

                    </div>

                </div>
            </section> */}
        </React.Fragment>
    )
}

