import React, { useState, useEffect } from 'react';
import {
  SideBar
} from '../../../components/layout/dashboard';
import { Table } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import { Badge } from 'react-bootstrap';

import { connect } from 'react-redux';
import { getMyTryoutDetail, getParticipantCompletion } from '../../../store/actions/tryout';

function MyTryOutDetail(props) {
  document.title = "INGENIO Indonesia";
  const [myTryout, setMyTryout] = useState({});
  const [dataTryout, setDataTryout] = useState({});
  const [dataSubTryout, setDataSubTryout] = useState([]);
  const [program, setProgram] = useState('');
  const [completion, setCompletion] = useState([]);

  const { dispatch } = props;

  const { id } = useParams();

  useEffect(() => {
    dispatch(getMyTryoutDetail(id))
      .then(async (response) => {
        let datasub = response.data.tryout.subtryout;
        await setMyTryout(response.data);
        await setDataTryout(response.data.tryout);
        await setDataSubTryout(datasub);
      })
      .then(() => {
        console.log(dataSubTryout);
      })
      .catch((err) => {
        console.log(err);
      })
  }, [])

  useEffect(() => {
    dispatch(getParticipantCompletion(id))
      .then(async (response) => {
        console.log(response, 'completion')
        await setCompletion(response.data)
      })
      .then(() => {
        console.log(completion)
      })
      .catch((err) => {
        console.log(err);
      })
  }, [])

  return (
    <React.Fragment>
      <SideBar judul={'Detail TryOut Saya'} />
      <div className="md:ml-64">
        {/* <div className="bg-orange px-3 md:px-8 py-1" style={{ height: '2.5rem' }}>
          <div className="container md:pr-8 md:pl-10">
          </div>
        </div> */}

        <div className="px-3 md:px-8">
          <div className="container mx-auto max-w-full">
            <p className="breadcrumbss py-4">Tryout Saya / <span style={{ fontWeight: 'bold' }}>Detail Tryout</span></p>
            <div className="card-gede">
              <div className="card-head px-4 py-4" style={{ 'fontFamily': 'Quicksand' }}>
                Detail Tryout - {dataTryout.nama_tryout}
              </div>
              <div className="row">
                <div className="col-lg-12 col-md-12 col-xs-12 py-4 px-5">
                  <p style={{ fontSize: "18px !important", lineHeight: "22px", letterSpacing: "0.06em", color: "#000000", fontWeight: 'bold' }}>Daftar List Sub Tryout</p> <br />
                  <Table bordered style={{ textAlign: 'center' }}>
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>NAMA SUB TRYOUT</th>
                        <th>JUMLAH SOAL</th>
                        <th>STATUS</th>
                        <th>MAKS. RETAKE</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        completion.map((i, idx) => (
                          <tr key={i.id}>
                            <td>{idx + 1}</td>
                            <td>{i.nama_subtryout}</td>
                            <td>{i.jumlah_soal}</td>
                            <td>
                              {
                                i.completion ? <Badge bg="success" text="light">Sudah di Kerjakan</Badge> : <Badge bg="warning" text="light">Belum di Mulai</Badge>
                              }
                            </td>
                            <td>{`${i.total_attempt}/${dataTryout.max_attempt}`}</td>
                            <td>
                              {
                                i.completion ? 
                                  i.can_retake ?
                                  <Link className="pilih-tryout p-2" to={"/dashboard/kerjakan?tryout=" + dataTryout.id + "&subtryout=" + i.id}>Retake</Link>
                                  :
                                  <Link className="pilih-tryout p-2" to={"/dashboard/hasil-pengerjaan?sub=" + i.id}>Review</Link> 
                                    : 
                                    <Link className="pilih-tryout p-2" to={"/dashboard/kerjakan?tryout=" + dataTryout.id + "&subtryout=" + i.id}>Kerjakan</Link>
                              }
                            </td>
                          </tr>
                        ))
                      }
                    </tbody>
                  </Table>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </React.Fragment>

  )
}

export default connect()(MyTryOutDetail)