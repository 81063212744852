import { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { BsGrid, BsUpload, BsInfoLg } from "react-icons/bs";
import { LogistikNav } from '..';

export default function LogistikSidebar({judul}) {
    const logoFooter = {
        width: "100%",
        height: "auto",
    };
    const [showSidebar, setShowSidebar] = useState('-left-64');
    var baseUrl = window.location.origin;
    return (
        <>
            <LogistikNav
                judul={judul}
                showSidebar={showSidebar}
                setShowSidebar={setShowSidebar}
            />
            <div
                className={`h-screen fixed top-0 md:left-0 ${showSidebar} overflow-y-auto flex-row flex-nowrap overflow-hidden shadow-xl bg-white w-64 z-10 py-4 px-6 transition-all duration-300`}
            >
                <div className="flex-col items-stretch min-h-full flex-nowrap px-0 relative">
                    <NavLink
                        to="/dashboard/logistik"
                        exact
                        className="mt-2 text-center w-full inline-block"
                    >
                        <img src={baseUrl + "/assets/logo/ingenio rakun 2.png"} alt="logo" style={logoFooter} />
                    </NavLink>
                    <div className="flex flex-col">
                        <hr className="my-4 min-w-full" />

                        <ul className="flex-col min-w-full flex list-none">
                            <li className="rounded-lg mb-2">
                                <NavLink
                                    to="/dashboard/logistik"
                                    exact
                                    className="flex items-center gap-4 text-sm font-bold px-2 py-2 rounded-lg"
                                    activeClassName="SidebarActive"
                                >
                                    <BsGrid size="25px" />
                                    <span className="SidebarName">Dashboard</span>
                                </NavLink>
                            </li>
                            <li className="rounded-lg mb-2">
                                <NavLink
                                    to="/dashboard/logistik/informasi-kelas"
                                    exact
                                    className="flex items-center gap-4 text-sm font-bold px-2 py-2 rounded-lg"
                                    activeClassName="SidebarActive"
                                >
                                    <BsInfoLg size="25px" />
                                    <span className="SidebarName">Informasi Kelas</span>
                                </NavLink>
                            </li>
                            <li className="rounded-lg mb-2">
                                <NavLink
                                    to="/dashboard/logistik/upload-data"
                                    exact
                                    className="flex items-center gap-4 text-sm text-gray-700 font-light px-2 py-2 rounded-lg"
                                    activeClassName="SidebarActive"
                                >
                                    <BsUpload size="25px" />
                                    <span className="SidebarName">Upload Data</span>
                                </NavLink>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    );
}
