import Button from '@material-tailwind/react/Button';
import React, { useState, useEffect } from 'react';
import { FormGroup, FormControl, Form } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import { Link } from 'react-router-dom';
import { BsPencilSquare, BsFillTrashFill } from 'react-icons/bs';
import TutorSidebar from '../../../components/layout/dashboard/tutor/TutorSideBar';
import Swal from 'sweetalert2';
import CustomDatatable from '../../../components/common/CustomDatatable';
import ElearningService from '../../../services/elearning';
import moment from 'moment-timezone';

export default function JurnalMengajar() {
    var baseUrl = window.location.origin;

    document.title = "INGENIO Indonesia";
    const [program, setProgram] = useState('');

    // Filtering data
    const [isLoading, setIsLoading] = useState(true);
    const [hasNext, setHasNext] = useState(false)
    const [hasPrev, setHasPrev] = useState(false)
    const [count, setCount] = useState(0);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [keyword, setKeyword] = useState('');
    const [kelasData, setKelasData] = useState([]);


    const dropdown = {
        borderRadius: "10px",
        height: 50,
        padding: "25px",
    };
    const columns = [
        {
            name: 'NO.',
            field: 'no',
            index: true,
            selector: row => row.no,
            sortable: true,
            minWidth: '1px'
            
        },
        {
            name: 'NAMA KELAS',
            render: true,
            component: (row) => {
                return (
                    <td className='main-table__bodyRow'>{row.kelas.nama_kelas}</td>
                )
            },
            selector: row => row.kelas,
            sortable: true,
            
        },
        {
            name: 'NAMA REGIO',
            render: true,
            component: (row) => {
                return (
                    <td className='main-table__bodyRow'>{row.kelas.nama_regio}</td>
                )
            },
            selector: row => row.regio,
            sortable: true,
             
        },
        {
            name: 'NAMA PROGRAM',
            render: true,
            component: (row) => {
                return (
                    <td className='main-table__bodyRow'>{row.kelas.elearning_program.nama_program}</td>
                )
            },
            selector: row => row.program,
            minWidth: '170px',
            sortable: true,
            
        },
        {
            name: 'Materi',
            render: true,
            component: (row) => {
                return (
                    <td className='main-table__bodyRow'>{row.topik_pembelajaran}</td>
                )
            },
            selector: row => row.kapasitas,
            sortable: true,
            
        },
        {
            name: 'Tanggal Mengajar',
            render: true,
            component: (row) => {
                return (
                    <td className='main-table__bodyRow'>{moment(row.tanggal_mengajar).format('DD-MMM-YYYY')}</td>
                )
            },
            selector: row => row.kapasitas,
            sortable: true,
            
        },
        {
            name: 'Jam',
            render: true,
            component: (row) => {
                return (
                    <td className='main-table__bodyRow'>{`${moment(row.jam_mulai_mengajar).format('HH:mm')} - ${moment(row.jam_berakhir_mengajar).format('HH:mm')}`}</td>
                )
            },
            selector: row => row.kapasitas,
            sortable: true,
            
        },
        {
            name: 'Status',
            render: true,
            component: (row) => {
                return (
                    <td className='main-table__bodyRow'>{`Selesai`}</td>
                )
            },
            selector: row => row.kapasitas,
            sortable: true,
            
        },
        {
            name: 'AKSI',
            render: true,
            component: (row) => {
              return (
                <td className='main-table__bodyRow'>
                    <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-around'}}>
                        {
                            row.done_filled ? 
                            <Link className="btnTable p-2" to={`#`} style={{backgroundColor: 'grey'}}>Isi Jurnal mengajar</Link>
                            :
                            <Link className="pilih-tryout p-2" to={`/dashboard/tutor/jurnal-mengajar/input-jurnal/${row.id}`}>Isi Jurnal mengajar</Link>
                        }
                        
                        {/* <Button className="btnCircleWhite" onClick={() => AreYouSure(row.id)}><spam><BsFillTrashFill size="15"/></spam></Button> */}
                    </div>
                </td>
              )
            },
            selector: row => row.jumlah,
            sortable: true,
            minWidth: '290px',
            wrap: 'yes'
        },
        // {
        //     cell: () => <Link className="btnCircleWhite" onClick={AreYouSure}><BsFillTrashFill size="15"/></Link>,
        //     ignoreRowClick: true,
        //     allowOverflow: true,
        //     button: true,
        //     width: '4vw',
        // },
    ];
    const data = [
        {
            no: 1,
            kelas: 'Kelas A',
            regio: 'Lorem ipsum dolor sit amet, consectetur.',
            program: 'Lorem Ipsum.',
            materi: 'Lorem Ipsum.',
            hari: 'Lorem.',
            jam: '10:00 - 12:00',
            status: 'Lorem.',
        },
        {
            no: 2,
            kelas: 'Kelas B',
            regio: 'Lorem ipsum dolor sit amet, consectetur.',
            program: 'Lorem Ipsum.',
            materi: 'Lorem Ipsum.',
            hari: 'Lorem.',
            jam: '10:00 - 12:00',
            status: 'Lorem.',
        },
        {
            no: 3,
            kelas: 'Kelas C',
            regio: 'Lorem ipsum dolor sit amet, consectetur.',
            program: 'Lorem Ipsum.',
            materi: 'Lorem Ipsum.',
            hari: 'Lorem.',
            jam: '10:00 - 12:00',
            status: 'Lorem.',
        },
        {
            no: 4,
            kelas: 'Kelas D',
            regio: 'Lorem ipsum dolor sit amet, consectetur.',
            program: 'Lorem Ipsum.',
            materi: 'Lorem Ipsum.',
            hari: 'Lorem.',
            jam: '10:00 - 12:00',
            status: 'Lorem.',
        },
        {
            no: 5,
            kelas: 'Kelas E',
            regio: 'Lorem ipsum dolor sit amet, consectetur.',
            program: 'Lorem Ipsum.',
            materi: 'Lorem Ipsum.',
            hari: 'Lorem.',
            jam: '10:00 - 12:00',
            status: 'Lorem.',
        },
        {
            no: 6,
            kelas: 'Kelas F',
            regio: 'Lorem ipsum dolor sit amet, consectetur.',
            program: 'Lorem Ipsum.',
            materi: 'Lorem Ipsum.',
            hari: 'Lorem.',
            jam: '10:00 - 12:00',
            status: 'Lorem.',
        },
    ];

    const customStyles = {
        table: {
          style: {
            borderRadius: '15px',
            border: '1px solid #BDC1C9',
          },
        },
        head: {
          style: {
            fontSize: '1.25rem',
            textTransform: "uppercase",
            color: '#6B7280',
            backgroundColor: '#f3f4f6',
            border: '1px solid #BDC1C9',
            borderTopLeftRadius: '15px',
            borderTopRightRadius: '15px',
          },
        },
        pagination: {
          style: {
            color: '#6B7280',
            fontSize: '1.1rem',
            minHeight: '56px',
            backgroundColor: '#f3f4f6',
            border: '1.25px solid #BDC1C9',
            borderBottomLeftRadius: '15px',
            borderBottomRightRadius: '15px',
          },
        },
    };

    const AreYouSure = (id) => {
        Swal.fire({  
            title: 'Sudah Yakin?',  
            text: 'Pastikan semua data yang Anda isikan sudah sesuai',  
            icon: 'warning',  
            showCancelButton: true,  
            cancelButtonText: 'Tidak',  
            confirmButtonText: 'Ya'  
          })
          .then((result) => {
            if (result.isConfirmed) {
                ElearningService.deleteJurnalTutor(id, {status: false}).then(resp => {
                    Swal.fire({  
                        title: 'Data Berhasil Terupdate !',  
                        text: '',  
                        icon: 'success',  
                        showCancelButton: false,              
                        confirmButtonText: 'OK'  
                    }).then((result) => {
                      if (result.isConfirmed) {
                        getListKelas()
                      }
                    })
                }).catch(err => {
                    console.error(err)
                    Swal.fire({  
                        title: 'Server Error',  
                        text: err.message,  
                        icon: 'error',  
                        showCancelButton: true,  
                        cancelButtonText: 'Kembali',           
                        showConfirmButton: false,  
                    }); 
                })
            }
          });   
    }

    const getListKelas = (params = '') => {
        setIsLoading(true)
        ElearningService.getListJurnalTutor(params).then(resp => {
            const response = resp.data;
            setKelasData(response.data);

            setPage(response.meta.page)
            setPerPage(response.meta.perPage)
            setCount(response.meta.count)

            setHasNext(response.meta.hasNext)
            setHasPrev(response.meta.hasPrev);
            
            setIsLoading(false)
        }).catch(err => {
            setIsLoading(false)
            console.error(err)
        })
    }

    useEffect(() => {
        getListKelas()
    }, [])

    return (
        <React.Fragment>
            <TutorSidebar />
            <div className="md:ml-64 tutorDataKelas" style={{ backgroundColor: '#F3F4F6' }}>
                <div className="bg-orange md:px-8 pb-5" style={{height: '24vh', position: 'relative', padding: '0 30px 30px 30px'}}>
                    <h1 className="tracking-wider title-24-600">Jurnal Mengajar</h1>
                    <h2 className="tracking-wider title-20-700 mt-5">Selamat Datang Tutor INGENIO !</h2>
                    <img src={baseUrl + "/assets/logo/evaluasikelas.png"} className="img-opacity"></img>
                </div>

                <div className="px-3 md:px-8 mt-4">
                    <div className="container mx-auto max-w-full">
                        <div className="row">
                        <div className="col-md-8 col-lg-4 col-xs-12 mb-4">
                                <FormGroup>
                                    <div className="row" style={{ backgroundColor: '#FFF', padding: '1rem', borderRadius: '1rem' }}>
                                        <div className="col-8">
                                            <input value={keyword} onChange={(e) => setKeyword(e.target.value)} type="text" name="" style={{ backgroundColor: '#F3F4F6', width: '100%', borderRadius: '8px', border: 'none !important', height: '45px', paddingLeft: '10px' }} placeholder="&#x1F50E;&#xFE0E; Nama Kelas." id="" />
                                        </div>
                                        <div className="col-3">
                                            <Button onClick={() => {
                                                getListKelas(`page=${page}&perPage=${perPage}&keyword=${keyword}`)
                                            }} className="pilih-tryout">Cari</Button>
                                        </div>
                                    </div>
                                </FormGroup>
                            </div>
                            <div className="col-8" style={{ padding: '1rem' }}>
                                
                            </div>
                        </div>
                        <div className="col-md-12 col-lg-12 col-xs-12 mt-3 mb-5">
                            {/* <DataTable columns={columns} customStyles={customStyles} data={data} pagination /> */}
                            <CustomDatatable 
                                    filteredItems={kelasData} 
                                    columns={columns}
                                    isLoading={isLoading}
                                    page={page}
                                    perPage={perPage}
                                    hasPrev={hasPrev}
                                    hasNext={hasNext}
                                    count={count}
                                    onTableChange={(e, data) => {
                                        switch (e) {
                                        case 'rowPerPageChange':
                                            setPerPage(data.perPage)
                                            getListKelas(`page=${page}&perPage=${data.perPage}&keyword=${keyword}`)
                                            break;
                                        case 'rowPageChange':
                                            setPage(data.page)
                                            getListKelas(`page=${data.page}&perPage=${perPage}&keyword=${keyword}`)
                                            break;
                                        
                                        default:
                                            break;
                                        }
                                    }}
                                />
                        </div>
                    </div>
                </div>

            </div>
        </React.Fragment >

    )
}