import React from 'react';
import { useState, useEffect } from 'react';
import AdminSideBar from '../../../../components/layout/dashboard/admin/AdminSideBar';
import Button from '@material-tailwind/react/Button';
import { FormGroup, Modal } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import { Link } from 'react-router-dom';
import { BsFillFileEarmarkArrowUpFill, BsPencilSquare } from 'react-icons/bs';
import { MdOutlineNoteAdd, MdOutlineAddCircleOutline } from 'react-icons/md';
import { HiOutlinePencilAlt, HiOutlineTrash } from 'react-icons/hi';
import AuthService from '../../../../services/auth'
import CustomDatatable from '../../../../components/common/CustomDatatable';
import moment from 'moment-timezone';
import Swal from 'sweetalert2';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

export default function OlahKategoriLibraryAdmin (props) {
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);

  // Filtering data
  const [isLoading, setIsLoading] = useState(true);
  const [hasNext, setHasNext] = useState(false)
  const [hasPrev, setHasPrev] = useState(false)
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [keyword, setKeyword] = useState('');
  const [kelasData, setKelasData] = useState([]);

  // Add Data
  const [inIdKategori, setInIdKategori] = useState('');
  const [inNamaKategori, setInNamaKategori] = useState('');

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  document.title = "INGENIO Indonesia";
  const [showSidebar, setShowSidebar] = useState('-left-64');
  var baseUrl = window.location.origin;

  const columns = [
    {
        name: 'NO.',
        field: 'no',
        index: true,
        selector: row => row.no,
        sortable: true,
        minWidth: '1px'
    },
    {
        name: 'KATEGORI',
        render: true,
        component: (row) => {
            return (
                <td className='main-table__bodyRow'>{row.name}</td>
            )
        },
        selector: row => row.tanggal,
        sortable: true,
        // width: '15vw',
    },
    {
      name: 'Aksi',
      render: true,
      component: (row) => {
          return (
              <td className='main-table__bodyRow'>
                  <div style={{display: 'flex', flexDirection: 'row'}}>
                      <OverlayTrigger placement="top" overlay={<Tooltip id="button-tooltip-2">Edit</Tooltip>}>
                        <Link to='#' className="btnCircleBlack mr-3" onClick={() => {
                          setInIdKategori(row.id);
                          setInNamaKategori(row.name);
                          handleShow2();
                        }}><HiOutlinePencilAlt size="22"/></Link>
                      </OverlayTrigger>
                      <OverlayTrigger placement="top" overlay={<Tooltip id="button-tooltip-2">Delete</Tooltip>}>
                        <Link to='#' className="btnCircleBlack mr-3" onClick={() => {
                          DeleteElibCategory(row.id)
                        }}><HiOutlineTrash size="22"/></Link>
                      </OverlayTrigger>
                  </div>
              </td>
          )
      },
      selector: row => row.tanggal,
      sortable: true,
      // width: '15vw',
  },
];
  const data = [
      {
          no: 1,
          kategori: 'lorem ipsum.',
      },
      {
          no: 2,
          kategori: 'lorem ipsum.',
      },
  ];

  const customStyles = {
      table: {
        style: {
          borderRadius: '15px',
          border: '1px solid #BDC1C9',
        },
      },
      head: {
        style: {
          fontSize: '1.25rem',
          textTransform: "uppercase",
          color: '#6B7280',
          backgroundColor: '#f3f4f6',
          border: '1px solid #BDC1C9',
          borderTopLeftRadius: '15px',
          borderTopRightRadius: '15px',
        },
      },
      pagination: {
        style: {
          color: '#6B7280',
          fontSize: '1.1rem',
          minHeight: '56px',
          backgroundColor: '#f3f4f6',
          border: '1.25px solid #BDC1C9',
          borderBottomLeftRadius: '15px',
          borderBottomRightRadius: '15px',
        },
      },
  };

  const AreYouSure = () => {
      Swal.fire({  
          title: 'Sudah Yakin?',  
          text: 'Apakah Anda Yakin Ingin Menghapus Data Ini?',  
          icon: 'warning',  
          showCancelButton: true,  
          cancelButtonText: 'Tidak',  
          confirmButtonText: 'Ya'  
      })
  }

  const Succeed = () => {
      Swal.fire({  
          title: 'Data Berhasil Tersimpan !',  
          text: '',  
          icon: 'success',  
          showCancelButton: false,              
          confirmButtonText: 'OK'  
      }).then((result) => {
        if (result.isConfirmed) {
          window.location.reload();
        }
      })
  }

  const EditSucceed = () => {
      Swal.fire({  
          title: 'Data Berhasil Tersimpan !',  
          text: '',  
          icon: 'success',  
          showCancelButton: false,              
          confirmButtonText: 'OK'  
      }).then((result) => {
        if (result.isConfirmed) {
          window.location.reload();
        }
      })
  }

  const AddElibCategory = (body) => {
    Swal.fire({  
        title: 'Sudah Yakin?',  
        text: 'Apakah Anda Yakin Ingin Menambah Data Ini?',  
        icon: 'warning',  
        showCancelButton: true,  
        cancelButtonText: 'Tidak',  
        confirmButtonText: 'Ya'  
    }).then((result) => {
      if (result.isConfirmed) {
          setIsLoading(true)
          AuthService.addElibCategoryAdmin(body).then(resp => {
              Succeed()
              setIsLoading(false)
          }).catch(err => {
              setIsLoading(false)
              console.error(err)
          })
      }
    });  
  }

  const UpdateElibCategory = (id, body) => {
    Swal.fire({  
        title: 'Sudah Yakin?',  
        text: 'Apakah Anda Yakin Ingin Update Data Ini?',  
        icon: 'warning',  
        showCancelButton: true,  
        cancelButtonText: 'Tidak',  
        confirmButtonText: 'Ya'  
    }).then((result) => {
      if (result.isConfirmed) {
          setIsLoading(true)
          AuthService.updateElibCategoryAdmin(id, body).then(resp => {
              Succeed()
              setIsLoading(false)
          }).catch(err => {
            let msg = 'Internal Server Error'
            setIsLoading(false)
            console.error(err)
            if(err.response) {
              if(err.response.data.message) {
                msg = err.response.data.message
              }
            }

            Fail(msg)
          })
      }
    });  
  }

  const DeleteElibCategory = (id) => {
    Swal.fire({  
        title: 'Sudah Yakin?',  
        text: 'Apakah Anda Yakin Ingin Hapus Data Ini?',  
        icon: 'warning',  
        showCancelButton: true,  
        cancelButtonText: 'Tidak',  
        confirmButtonText: 'Ya'  
    }).then((result) => {
      if (result.isConfirmed) {
          setIsLoading(true)
          AuthService.deleteElibCategoryAdmin(id).then(resp => {
              Succeed()
              setIsLoading(false)
          }).catch(err => {
              let msg = 'Internal Server Error'
              setIsLoading(false)
              console.error(err)
              if(err.response) {
                if(err.response.data.message) {
                  msg = err.response.data.message
                }
              }

              Fail(msg)
          })
      }
    });  
  }

  const Fail = (message) => {
    Swal.fire({  
        title: 'Gagal!',  
        text: message,  
        icon: 'warning',  
        showCancelButton: true,  
        cancelButtonText: 'Kembali',           
        showConfirmButton: false,  
    });   
  }

  const getListKelas = (params = '') => {
    setIsLoading(true)
    AuthService.getListElibCategoryAdmin(params).then(resp => {
        const response = resp.data;
        setKelasData(response.data);

        setPage(response.meta.page)
        setPerPage(response.meta.perPage)
        setCount(response.meta.count)

        setHasNext(response.meta.hasNext)
        setHasPrev(response.meta.hasPrev);
        
        setIsLoading(false)
    }).catch(err => {
        setIsLoading(false)
        console.error(err)
    })
  }

  useEffect(() => {
    getListKelas()
  }, [])

  return(
    <React.Fragment>
      <AdminSideBar />
      <div className="md:ml-64 styleHead" style={{ backgroundColor: '#F3F4F6' }}>
        <div className="bg-orange md:px-8 pb-5" style={{height: '20vh', position: 'relative', padding: '0 30px 30px 30px'}}>
            <h1 className="tracking-wider title-24-600">Olah Kategori E-Library</h1>
            {/* <h2 className="tracking-wider title-20-700 mt-5">Selamat Datang Tutor INGENIO !</h2> */}
            <img src={baseUrl + "/assets/logo/evaluasikelas.png"} className="img-opacity"></img>
        </div>

        <div className="px-3 md:px-8 pb-5">
            <div className="container mx-auto max-w-full">
                <div className="row mt-5">
                  <div className="col-12 my-2">
                    <FormGroup>
                      <div className="justify-start">
                        <div className="SearchTable">
                            <input  onChange={(e) => {
                              setKeyword(e.target.value)
                            }}type="text" name="" className="inputSearchTable" placeholder="&#x1F50E;&#xFE0E; Search..." id="" />
                            <Button onClick={() => {
                                getListKelas(`page=${page}&perPage=${perPage}&keyword=${keyword}`)
                            }} className="btnSearchTable">Cari</Button>
                        </div>
                        <Button className="btnOrange2" onClick={() => {
                          setInIdKategori('');
                          setInNamaKategori('');
                          handleShow();
                        }}>
                            <div className="titleBtnOrange">
                                <MdOutlineAddCircleOutline size="23px" className="mr-2"/>Tambah Kategori
                            </div> 
                        </Button>
                      </div>
                    </FormGroup>
                  </div>
                  <div className="col-12 mt-3">
                      {/* <DataTable columns={columns} customStyles={customStyles} data={data} pagination /> */}
                      <CustomDatatable 
                          filteredItems={kelasData} 
                          columns={columns}
                          isLoading={isLoading}
                          page={page}
                          perPage={perPage}
                          hasPrev={hasPrev}
                          hasNext={hasNext}
                          count={count}
                          onTableChange={(e, data) => {
                              switch (e) {
                              case 'rowPerPageChange':
                                  setPerPage(data.perPage)
                                  getListKelas(`page=${page}&perPage=${data.perPage}&keyword=${keyword}`)
                                  break;
                              case 'rowPageChange':
                                  setPage(data.page)
                                  getListKelas(`page=${data.page}&perPage=${perPage}&keyword=${keyword}`)
                                  break;
                              
                              default:
                                  break;
                              }
                          }}
                      />
                  </div>
                </div>
                <Modal show={show} onHide={handleClose}
                      {...props}
                      size="md"
                      aria-labelledby="contained-modal-title-vcenter"
                      centered>
                  <Modal.Header closeButton>
                      <Modal.Title id="contained-modal-title-vcenter">
                          <div className="title-20-700">Tambah Kategori</div>
                      </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                      <div className="row p-4">
                          <div className="col-12">
                              <div className="title-18-500 mb-1">Kategori</div>
                              <input onChange={(e) => {
                                setInNamaKategori(e.target.value)
                              }} type="text" class="formInputJurnal" placeholder="masukkan kategori..." />
                          </div>
                          <div className="col-12 justify-end mt-4">
                              <Button type="submit" onClick={() => {
                                AddElibCategory({name: inNamaKategori})
                              }} className="btnOrange3">
                                  <span className="txt-btn">Submit</span>
                              </Button>
                          </div>
                      </div>
                  </Modal.Body>
                </Modal>
                <Modal show={show2} onHide={handleClose2}
                      {...props}
                      size="md"
                      aria-labelledby="contained-modal-title-vcenter"
                      centered>
                  <Modal.Header closeButton>
                      <Modal.Title id="contained-modal-title-vcenter">
                          <div className="title-20-700">Edit Kategori</div>
                      </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                      <div className="row p-4">
                          <div className="col-12">
                              <div className="title-18-500 mb-1">Kategori</div>
                              <input onChange={(e) => {
                                setInNamaKategori(e.target.value)
                              }} value={inNamaKategori} type="text" class="formInputJurnal" placeholder="masukkan kategori..." />
                          </div>
                          <div className="col-12 justify-end mt-4">
                              <Button type="submit" onClick={() => {
                                UpdateElibCategory(inIdKategori, {
                                  name: inNamaKategori
                                })
                              }} className="btnOrange3">
                                  <span className="txt-btn">Submit</span>
                              </Button>
                          </div>
                      </div>
                  </Modal.Body>
                </Modal>
            </div>
        </div>

      </div>
    </React.Fragment>
    
  )
}