import React, { useState, useEffect } from 'react';
import AkademikSidebar from '../../../../components/layout/dashboard/akademik/AkademikSideBar';
import { FormGroup, FormControl, Form } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import { Link, useParams, useLocation } from 'react-router-dom';
import Button from '@restart/ui/esm/Button';
import InputIcon from '@material-tailwind/react/InputIcon';
import { Badge } from 'react-bootstrap';
import { BsPersonCircle, BsFileBarGraph, BsTrashFill } from 'react-icons/bs'
import { getUserRankingTryout, getUserTryout } from '../../../../store/actions/tryout';
import CustomDatatable from '../../../../components/common/CustomDatatable';
import tryoutService from '../../../../services/tryout'

import { connect } from 'react-redux';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import Swal from 'sweetalert2';

function DetailPeserta(props) {
  document.body.style.backgroundColor = "#f3f4f6";
  const [dataPeserta, setDataPeserta] = useState([]);
  const [downloading, setDownloading] = useState(false);
  const [processing, setProcessing] = useState(false);
  const dropdown = {
    borderRadius: "10px",
    height: 50,
    padding: "25px",
  };

  const [isLoading, setIsLoading] = useState(true);
  const [hasNext, setHasNext] = useState(false)
  const [hasPrev, setHasPrev] = useState(false)
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [keyword, setKeyword] = useState('');
  const [kelasData, setKelasData] = useState([]);

  // FILTERING DATA
  const [selectedProgram, setSelectedProgram] = React.useState(null)
  const [filterText, setFilterText] = React.useState('');
  const [filterObj, setFilterObj] = React.useState({
    text: '',
    selectedProgram: ''
  })
  const onFilter = (data) => {
    setFilterText(data)
  }
  const onFilterSubmit = (e) => {
    e.preventDefault();
    let obj = {
      text: filterText,
      program: selectedProgram
    }
    setFilterObj(obj)
  }

  // const filteredItems = dataPeserta.filter(
  //   item => (item.user.full_name && item.user.full_name.toLowerCase().includes(filterObj.text.toLowerCase()))
  // );
  // END FILTERING DATA

  let { id } = useParams();

  const { dispatch } = props;

  const location = useLocation();
    const { max_attempt } = location.state

  const columns_bak = [
    {
      name: '#',
      selector: (row, idx) => idx + 1,
      width: '2.7rem',
      sortable: false,
    },
    {
      name: 'Kode Registrasi',
      selector: row => `TO${row.id_user.split('-')[0].toUpperCase()}`,
      sortable: true,
      width: '15rem'
    },
    {
      name: 'Nama Peserta',
      selector: row => row.full_name,
      sortable: true,
      width: '20rem'
    },
    {
      name: 'Total Nilai Tryout',
      selector: row => Math.ceil(row.total_nilai),
      sortable: true,
      width: '10rem'
    },
    {
      name: 'Retake',
      selector: row => `${row.total_attempt}/${max_attempt}`,
      sortable: true,
      width: '10rem'
    },
    {
      name: 'Ranking',
      selector: row => row.ranking,
      sortable: true,
      width: '10rem'
    },
    {
      cell: (row) =>
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip id="button-tooltip-2">Detail Peserta</Tooltip>}
        >
          {({ ...triggerHandler }) => (
            <Link
              className="p-2 mr-3"
              {...triggerHandler}
              style={{
                backgroundColor: '#F8CA2B',
                fontFamily: 'Quicksand',
                fontStyle: 'normal',
                fontWeight: 'bold',
                fontSize: '1rem',
                lineHeight: '1.5rem',
                letterSpacing: '0.06em',
                color: 'black',
                borderRadius: '50%',
              }}
              to={{
                pathname: "/dashboard/akademik/data-peserta/detail-peserta/" + row.id_tryout,
                state: {
                  user: {
                    id_user: row.id_user,
                    id_tryout: row.id_tryout
                  },
                }
              }}>
              <BsPersonCircle size={28} ref={React.ref} />
            </Link>
          )}
        </OverlayTrigger>,
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
    {
      cell: (row) =>
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip id="button-tooltip-2">Detail Nilai</Tooltip>}
        >
          {({ ...triggerHandler }) => (
            <Link
              className="p-2 mr-3"
              {...triggerHandler}
              style={{
                backgroundColor: '#F8CA2B',
                fontFamily: 'Quicksand',
                fontStyle: 'normal',
                fontWeight: 'bold',
                fontSize: '1rem',
                lineHeight: '1.5rem',
                letterSpacing: '0.06em',
                color: 'black',
                borderRadius: '50%',
              }}
              to={{
                pathname: "/dashboard/akademik/data-peserta/detail-hasil-peserta/" + row.id_subtryout,
                state: {
                  user: {
                    id_user: row.id_user,
                    id_tryout: row.id_tryout,
                    id_subtryout: row.id_subtryout
                  },
                }
              }}
            >
              <BsFileBarGraph size={28} ref={React.ref} />
            </Link>
          )}
        </OverlayTrigger>,
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  const customStyles = {
    table: {
      style: {
        borderRadius: '15px',
        border: '1px solid #BDC1C9',
      },
    },
    head: {
      style: {
        fontSize: '1.25rem',
        textTransform: "uppercase",
        color: '#6B7280',
        backgroundColor: '#f3f4f6',
        border: '1px solid #BDC1C9',
        borderTopLeftRadius: '15px',
        borderTopRightRadius: '15px',
      },
    },
    pagination: {
      style: {
        color: '#6B7280',
        fontSize: '1.1rem',
        minHeight: '56px',
        backgroundColor: '#f3f4f6',
        border: '1.25px solid #BDC1C9',
        borderBottomLeftRadius: '15px',
        borderBottomRightRadius: '15px',
      },
    },
  };

  useEffect(() => {
    // dispatch(getUserTryout(id))
    //   .then((response) => {
    //     console.log(response);
        
    //   })
    //   .then(() => {
    //     console.log(dataPeserta, 'ini dari state')
    //   })
  }, [])

  useEffect(() => {
    // tryoutService.getUserRankingAkademikSubtryout(id)
    //   .then(resp => {
    //     setDataPeserta(resp.data.data)
    //   }).catch(err => {
    //     console.error(err)
    //   })
    // dispatch(getUserRankingTryout(id))
    //   .then((response) => {
    //     setDataPeserta(response.data);
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   })
  }, [])

  useEffect(() => {
    getListKelas()
  }, [])

  const resetResultTryout = () => {
    Swal.fire({  
      title: 'Sudah Yakin?',  
      text: 'Apakah anda yakin akan me-reset hasil TO peserta ini?',  
      icon: 'warning',  
      showCancelButton: true,  
      cancelButtonText: 'Tidak',  
      confirmButtonText: 'Ya'  
    })
    .then((result) => {
      if (result.isConfirmed) {
          forceRefreshData()
      }
    });   
  }

  const forceRefreshData = () => {
    setProcessing(true)
    tryoutService.updateRankingSubtryout(id)
      .then(resp => {
        getListKelas()
        setProcessing(false)
      }).catch(err => {
        console.error(err)
        Swal.fire({  
          title: 'Gagal!',  
          text: 'Gagal memproses data, silahkan coba lagi',  
          icon: 'warning',  
          showCancelButton: true,  
          cancelButtonText: 'Kembali',           
          showConfirmButton: false,  
      });  
        setProcessing(false)
      })
  }

  const downloadHasilTryout = () => {
    setDownloading(true)
    tryoutService.downloadHasilTryout(id)
      .then(resp => {
        const url = resp.data.data.url;
        window.location.replace(url)
        setDownloading(false)
      }).catch(err => {
        console.error(err)
        Swal.fire({  
          title: 'Gagal!',  
          text: 'Data tidak ditemukan',  
          icon: 'warning',  
          showCancelButton: true,  
          cancelButtonText: 'Kembali',           
          showConfirmButton: false,  
      });  
        setDownloading(false)
      })
  }

  const getListKelas = (params = '') => {
    setIsLoading(true)
    tryoutService.getUserRankingAkademikSubtryoutList(id, params).then(resp => {
        const response = resp.data;
        setKelasData(response.data);
  
        setPage(response.meta.page)
        setPerPage(response.meta.perPage)
        setCount(response.meta.count)
  
        setHasNext(response.meta.hasNext)
        setHasPrev(response.meta.hasPrev);
        
        setIsLoading(false)
    }).catch(err => {
        let msg = 'Internal Server Error'
        setIsLoading(false)
        console.error(err)
        if(err.response) {
            if(err.response.data.message) {
                msg = err.response.data.message
            }
        }
  
        Fail(msg)
    })
  }

  const Fail = (message) => {
    Swal.fire({  
        title: 'Gagal!',  
        text: message,  
        icon: 'warning',  
        showCancelButton: true,  
        cancelButtonText: 'Kembali',           
        showConfirmButton: false,  
    });   
  }

  const columns = [
    {
        name: 'NO.',
        field: 'no',
        index: true,
        selector: row => row.no,
        sortable: true,
        minWidth: '1px'
    },
    {
      name: 'KODE REGISTRASI',
      render: true,
      component: (row) => {
          return (
              <td className='main-table__bodyRow'>{`TO${row.id_user.split('-')[0].toUpperCase()}`}</td>
          )
      },
      selector: row => row.tanggal,
      sortable: true,
      // width: '15vw',
    },
    {
      name: 'NAMA PESERTA',
      render: true,
      component: (row) => {
          return (
              <td className='main-table__bodyRow'>{row.full_name}</td>
          )
      },
      selector: row => row.tanggal,
      sortable: true,
      // width: '15vw',
    },
    {
      name: 'TOTAL NILAI TRYOUT',
      render: true,
      component: (row) => {
          return (
              <td className='main-table__bodyRow'>{Math.ceil(row.total_nilai)}</td>
          )
      },
      selector: row => row.tanggal,
      sortable: true,
      // width: '15vw',
    },
    {
      name: 'RETAKE',
      render: true,
      component: (row) => {
          return (
              <td className='main-table__bodyRow'>{`${row.total_attempt}/${max_attempt}`}</td>
          )
      },
      selector: row => row.tanggal,
      sortable: true,
      // width: '15vw',
    },
    {
      name: 'RANKING',
      render: true,
      component: (row) => {
          return (
              <td className='main-table__bodyRow'>{`${row.ranking}`}</td>
          )
      },
      selector: row => row.tanggal,
      sortable: true,
      // width: '15vw',
    },
  {
      name: 'Aksi',
      render: true,
      component: (row) => {
          return (
              <td className='main-table__bodyRow'>
                  <div style={{display: 'flex', flexDirection: 'row'}}>
                  <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip id="button-tooltip-2">Detail Peserta</Tooltip>}
                  >
                    {({ ...triggerHandler }) => (
                      <Link
                        className="p-2 mr-3"
                        {...triggerHandler}
                        style={{
                          backgroundColor: '#F8CA2B',
                          fontFamily: 'Quicksand',
                          fontStyle: 'normal',
                          fontWeight: 'bold',
                          fontSize: '1rem',
                          lineHeight: '1.5rem',
                          letterSpacing: '0.06em',
                          color: 'black',
                          borderRadius: '50%',
                        }}
                        to={{
                          pathname: "/dashboard/akademik/data-peserta/detail-peserta/" + row.id_tryout,
                          state: {
                            user: {
                              id_user: row.id_user,
                              id_tryout: row.id_tryout
                            },
                          }
                        }}>
                        <BsPersonCircle size={28} ref={React.ref} />
                      </Link>
                    )}
                  </OverlayTrigger>
                  <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip id="button-tooltip-2">Detail Nilai</Tooltip>}
                  >
                    {({ ...triggerHandler }) => (
                      <Link
                        className="p-2 mr-3"
                        {...triggerHandler}
                        style={{
                          backgroundColor: '#F8CA2B',
                          fontFamily: 'Quicksand',
                          fontStyle: 'normal',
                          fontWeight: 'bold',
                          fontSize: '1rem',
                          lineHeight: '1.5rem',
                          letterSpacing: '0.06em',
                          color: 'black',
                          borderRadius: '50%',
                        }}
                        to={{
                          pathname: "/dashboard/akademik/data-peserta/detail-hasil-peserta/" + row.id_subtryout,
                          state: {
                            user: {
                              id_user: row.id_user,
                              id_tryout: row.id_tryout,
                              id_subtryout: row.id_subtryout
                            },
                          }
                        }}
                      >
                        <BsFileBarGraph size={28} ref={React.ref} />
                      </Link>
                    )}
                  </OverlayTrigger>
                  <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip id="button-tooltip-2">Reset Hasil</Tooltip>}
                  >
                    {({ ...triggerHandler }) => (
                      <Button onClick={(e) => {
                        e.preventDefault();
                        resetResultTryout()
                      }} className="pilih-tryout mr-3" style={{
                        backgroundColor: '#F8CA2B',
                        fontFamily: 'Quicksand',
                        fontStyle: 'normal',
                        fontWeight: 'bold',
                        fontSize: '1rem',
                        lineHeight: '1.5rem',
                        letterSpacing: '0.06em',
                        color: 'black',
                        borderRadius: '50%',
                      }} {...triggerHandler}>
                        <BsTrashFill size={28} ref={React.ref} />
                      </Button>
                    )}
                  </OverlayTrigger>
                  </div>
              </td>
          )
      },
      selector: row => row.tanggal,
      sortable: true,
      // width: '15vw',
  },
];

  return (
    <React.Fragment>
      <AkademikSidebar judul={'Detail Peserta TryOut'} />
      <div className="md:ml-64">
        <div className="bg-orange px-3 md:px-8 py-1" style={{ height: '2.5rem' }}>
          <div className="container md:pr-8 md:pl-10">
          </div>
        </div>

        <div className="px-3 md:px-8 mt-5">
          <div className="container mx-auto max-w-full">
            <div className="flex-2 flex-col">
              <div className="flex-2 mb-4">
                <div>
                  <div className="flex flex-row">
                    <div className="bg-white px-4 py-3 mr-3" style={{ borderRadius: '20px' }}>
                      <input
                        type="text"
                        name=""
                        style={{ backgroundColor: '#F3F4F6', width: '25rem', borderRadius: '8px', padding: '0.75rem', border: 'none !important', paddingLeft: '10px', fontFamily: 'Quicksand' }}
                        className="mr-3"
                        placeholder="Nama Peserta atau keyword"
                        id=""
                        value={keyword}
                        onChange={(e) => setKeyword(e.target.value)}
                      />
                      <Button className="pilih-tryout mx-3" onClick={(e) => getListKelas(`page=${page}&perPage=${perPage}&keyword=${keyword}`)}>Cari</Button>
                    </div>
                    <div className="px-4 py-3 mr-3" style={{ borderRadius: '10px' }}>
                      <Button onClick={(e) => {
                        e.preventDefault();
                        if(downloading)
                          return;
                        downloadHasilTryout();
                      }} className="pilih-tryout mr-3">{downloading ? 'Processing...' : 'Download Hasil Tryout'}</Button>
                    </div>
                    <div className="px-4 py-3" style={{ borderRadius: '10px' }}>
                      <Button onClick={(e) => {
                        e.preventDefault();
                        if(processing)
                          return;
                        forceRefreshData();
                      }} className="pilih-tryout mr-3">{processing ? 'Processing...' : 'Refresh Data Ranking'}</Button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex-2 mb-4">
                {/* <DataTable
                  customStyles={customStyles}
                  columns={columns}
                  data={dataPeserta}
                  pagination
                /> */}
                <CustomDatatable 
                          filteredItems={kelasData} 
                          columns={columns}
                          isLoading={isLoading}
                          page={page}
                          perPage={perPage}
                          hasPrev={hasPrev}
                          hasNext={hasNext}
                          count={count}
                          onTableChange={(e, data) => {
                              switch (e) {
                              case 'rowPerPageChange':
                                  setPerPage(data.perPage)
                                  getListKelas(`page=${page}&perPage=${data.perPage}&keyword=${keyword}`)
                                  break;
                              case 'rowPageChange':
                                  setPage(data.page)
                                  getListKelas(`page=${data.page}&perPage=${perPage}&keyword=${keyword}`)
                                  break;
                              
                              default:
                                  break;
                              }
                          }}
                      />
              </div>
            </div>
          </div>
        </div>

      </div>
    </React.Fragment>

  )
}

export default connect()(DetailPeserta)