import Button from '@material-tailwind/react/Button';
import React, { useEffect, useState } from 'react';
import { FormGroup, FormControl, Form, Modal } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import { Link } from 'react-router-dom';
import { BsPencilSquare, BsFillTrashFill, BsFillPlusCircleFill } from 'react-icons/bs';
import AkademikSidebar from '../../../../components/layout/dashboard/akademik/AkademikSideBar';
import CustomDatatable from '../../../../components/common/CustomDatatable';
import ElearningService from '../../../../services/elearning';
import TryoutService from '../../../../services/tryout'
import moment from 'moment-timezone';
import Swal from 'sweetalert2';

export default function OlahProgram() {
    document.title = "INGENIO Indonesia";
    const [show, setShow] = useState(false);
    const [showUpdate, setShowUpdate] = useState(false);

    // Filtering data
    const [isLoading, setIsLoading] = useState(true);
    const [hasNext, setHasNext] = useState(false)
    const [hasPrev, setHasPrev] = useState(false)
    const [count, setCount] = useState(0);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [keyword, setKeyword] = useState('');
    const [elearningData, setElearningData] = useState([]);

    const [tryoutData, setTryoutData] = useState([]);
    const [tryoutIdSelected, setTryoutIdSelected] = useState('');

    // Form Add Elearning Program
    const [idElearningProgram, setIdElearningProgram] = useState(null);
    const [namaProgram, setNamaProgram] = useState('');
    const [durasi, setDurasi] = useState(0);
    const [tanggalMulai, setTanggalMulai] = useState('');
    const [tanggalAkhir, setTanggalAkhir] = useState('');

    // For package included
    const [isPackageInclude, setIsPackageInclude] = useState(false);
    const [weightPackage, setWeightPackage] = useState(0);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleUpdateClose = () => setShowUpdate(false);
    const handleUpdateShow = () => setShowUpdate(true);
    const dropdown = {
        borderRadius: "10px",
        height: 50,
        padding: "25px",
    };
    const columns = [
        {
            name: 'NO.',
            field: 'no',
            selector: row => row.no,
            index: true,
            sortable: true,
            minWidth: '1px',
            
        },
        {
            name: 'NAMA PROGRAM',
            field: 'nama_program',
            selector: row => row.program,
            sortable: true,
            
        },
        {
            name: 'DURASI',
            field: 'durasi',
            render: true,
            component: (row) => {
                return (
                    <td className='main-table__bodyRow'>{`${row.durasi} jam`}</td>
                )
            },
            selector: row => row.durasi,
            sortable: true,
            
        },
        {
            name: 'WAKTU PELAKSANAAN',
            field: 'waktu_pelaksanaan',
            render: true,
            component: (row) => {
                return (
                    <td className='main-table__bodyRow'>{`${moment(row.tanggal_mulai).format("DD MMM YYYY HH:mm")} - ${moment(row.tanggal_akhir).format("DD MMM YYYY HH:mm")}`}</td>
                )
            },
            selector: row => row.pelaksanaan,
            sortable: true,
            
        },
        {
            name: 'Berat Modul',
            render: true,
            component: (row) => {
                if(row.weight_package !== null && row.weight_package > 0 ) {
                    return (
                        <td className='main-table__bodyRow'>{row.weight_package+' Kg'}</td>
                    )
                }
                return (
                    <td className='main-table__bodyRow'>{`-`}</td>
                )
            },
            selector: row => row.pelaksanaan,
            sortable: true,
            
        },
        {
            name: '',
            render: true,
            component: (row) => {
              return (
                <td className='main-table__bodyRow'>
                    <Button
                        className="flex content-center items-center"
                        style={{width: '35px', borderRadius: '50%', padding: '10px', color: 'black', background: '#F8CA2B' }}
                        onClick={() => { 
                            setIdElearningProgram(row.id)
                            setNamaProgram(row.nama_program)
                            setDurasi(row.durasi)
                            setTanggalMulai(moment(row.tanggal_mulai).format('yyyy-MM-DD'))
                            setTanggalAkhir(moment(row.tanggal_akhir).format('yyyy-MM-DD'))
                            setTryoutIdSelected('')
                            if(row.elearning_program_tryouts.length > 0) {
                                setTryoutIdSelected(row.elearning_program_tryouts[0].id_tryout)
                            }
                            handleUpdateShow()
                        }}
                    >
                        <BsPencilSquare color='black' />
                    </Button>
                </td>
              )
            },
            selector: row => row.jumlah,
            sortable: true,
            minWidth: '20px',
            wrap: 'yes'
          },
          {
            name: '',
            render: true,
            component: (row) => {
              return (
                <td className='main-table__bodyRow'>
                  <Button
                    className="flex content-center items-center"
                    style={{width: '35px', borderRadius: '50%', padding: '10px', color: 'black', background: '#F8CA2B' }}
                    onClick={() => { 
                        deleteElearningProgram(row.id)
                    }}
                    >
                    <BsFillTrashFill color='black'  />
                  </Button>
                </td>
              )
            },
            selector: row => row.jumlah,
            sortable: true,
            minWidth: '20px',
            wrap: 'yes'
          }

    ];

    const deleteElearningProgram = (id) => {
        Swal.fire({  
            title: 'Sudah Yakin?',  
            text: 'Anda yakin ingin menghapus data?',  
            icon: 'warning',  
            showCancelButton: true,  
            cancelButtonText: 'Tidak',  
            confirmButtonText: 'Ya'  
          })
          .then((result) => {
            if (result.isConfirmed) {
                ElearningService.deleteElarningProgramAkademik(id).then(resp => {
                    Succeed('Data berhasil di hapus', () => {
                        handleClose()
                        getListElearningProgram()
                    })
                }).catch(err => {
                    let msg = 'Something went wrong'
                    if(err.response) {
                        if(err.response.data.message) {
                            msg = err.response.data.message
                            if(err.response.data.message.includes('is still referenced from table')) {
                                msg = 'Data tidak bisa dihapus, karena sedang di gunakan'
                            }
                            
                        }
                    }
                    Fail(msg)
                })
            }
          });
    }

    const updateElearningProgramSubmit = () => {
        let isIncludePackage = false;
        if(namaProgram === null || namaProgram === '') {
            Fail('Nama program harus diisi')
            return;
        }
        if(durasi === null || durasi === '') {
            Fail('Durasi harus diisi')
            return;
        }
        if(parseInt(durasi) < 1) {
            Fail('Durasi harus lebih dari 0')
            return;
        }
        if(tanggalMulai === null || tanggalMulai === '') {
            Fail('Tanggal mulai harus diisi')
            return;
        }
        if(tanggalAkhir === null || tanggalAkhir === '') {
            Fail('Tanggal akhir harus diisi')
            return;
        }
        if(moment(tanggalAkhir) < moment(tanggalMulai)) {
            Fail('Tanggal akhir harus lebih besar dari tanggal mulai')
            return;
        }

        if(weightPackage !== '' && weightPackage !== null && parseInt(weightPackage) > 0) {
            isIncludePackage = true;
        }

        const body = {
            nama_program: namaProgram,
            durasi: durasi,
            tanggal_mulai: moment(tanggalMulai).utc().toDate(),
            tanggal_akhir: moment(tanggalAkhir).utc().toDate(),
            is_active: true,
            is_package_include: isIncludePackage,
            weight_package: weightPackage
        }
        if(tryoutIdSelected && tryoutIdSelected !== null) {
            body.tryout = {
                id_tryout: tryoutIdSelected
            }
        }
        Swal.fire({  
            title: 'Sudah Yakin?',  
            text: 'Pastikan semua data yang Anda isikan sudah sesuai',  
            icon: 'warning',  
            showCancelButton: true,  
            cancelButtonText: 'Tidak',  
            confirmButtonText: 'Ya'  
          })
          .then((result) => {
            if (result.isConfirmed) {
                ElearningService.updateElearningProgramAkademik(body, idElearningProgram).then(resp => {
                    Succeed('Data berhasil diupdate', () => {
                        handleUpdateClose()
                        getListElearningProgram()
                    })
                }).catch(err => {
                    Fail('Gagal menambahkan data')
                })
            }
          });
    }

    const addElarningProgramSubmit = () => {
        let isIncludePackage = false;
        if(namaProgram === null || namaProgram === '') {
            Fail('Nama program harus diisi')
            return;
        }
        if(durasi === null || durasi === '') {
            Fail('Durasi harus diisi')
            return;
        }
        if(parseInt(durasi) < 1) {
            Fail('Durasi harus lebih dari 0')
            return;
        }
        if(tanggalMulai === null || tanggalMulai === '') {
            Fail('Tanggal mulai harus diisi')
            return;
        }
        if(tanggalAkhir === null || tanggalAkhir === '') {
            Fail('Tanggal akhir harus diisi')
            return;
        }
        if(moment(tanggalAkhir) < moment(tanggalMulai)) {
            Fail('Tanggal akhir harus lebih besar dari tanggal mulai')
            return;
        }
        if(weightPackage && weightPackage !== '' && weightPackage !== null && parseInt(weightPackage) > 0) {
            isIncludePackage = true;
        }

        const body = {
            nama_program: namaProgram,
            durasi: durasi,
            tanggal_mulai: moment(tanggalMulai).utc().toDate(),
            tanggal_akhir: moment(tanggalAkhir).utc().toDate(),
            is_active: true,
            is_package_include: isIncludePackage,
            weight_package: parseInt(weightPackage)
            
        }

        if(tryoutIdSelected && tryoutIdSelected !== null) {
            body.tryout = {
                id_tryout: tryoutIdSelected
            }
        }

        Swal.fire({  
            title: 'Sudah Yakin?',  
            text: 'Pastikan semua data yang Anda isikan sudah sesuai',  
            icon: 'warning',  
            showCancelButton: true,  
            cancelButtonText: 'Tidak',  
            confirmButtonText: 'Ya'  
          })
          .then((result) => {
            if (result.isConfirmed) {
                ElearningService.addElearningProgramAkademik(body).then(resp => {
                    Succeed('Data berhasil ditambahkan', () => {
                        handleClose()
                        getListElearningProgram()
                    })
                }).catch(err => {
                    Fail('Gagal menambahkan data')
                })
            }
          });
    }

    const Succeed = (message, onConfirm) => {
        Swal.fire({  
            title: 'Berhasil!',  
            text: message,  
            icon: 'success',  
            showCancelButton: false,              
            confirmButtonText: 'OK'  
        }).then((result) => {
          if (result.isConfirmed) {
            onConfirm()
          }
        })
      }
    
    const Fail = (message) => {
        Swal.fire({  
            title: 'Gagal!',  
            text: message,  
            icon: 'warning',  
            showCancelButton: true,  
            cancelButtonText: 'Kembali',           
            showConfirmButton: false,  
        });   
    }

    const getListElearningProgram = (params = '') => {
        setIsLoading(true);
        ElearningService.getListElearningProgramAkademik(params)
            .then(resp => {
                const response = resp.data
                setElearningData(response.data)

                setPage(response.meta.page)
                setPerPage(response.meta.perPage)
                setCount(response.meta.count)

                setHasNext(response.meta.hasNext)
                setHasPrev(response.meta.hasPrev);
                
                setIsLoading(false)
            }).catch(err => {

                setIsLoading(false)
            })
    }

    const getListTryout = () => {
        TryoutService.getAllTryout('perPage=1000').then(resp => {
            const response = resp.data;
            setTryoutData(response.data);
        }).catch(err => {
            console.log(err)
        })
    }

    

    useEffect(() => {
        getListElearningProgram();
        getListTryout();
    }, [])

    return (
        <React.Fragment>
            <AkademikSidebar judul={'Olah Program'} />
            <div className="md:ml-64" style={{ backgroundColor: '#F3F4F6' }}>
                <div className="bg-orange px-3 md:px-8 py-1" style={{ height: '5vh' }}>
                    <div className="container md:pr-8 md:pl-10">
                        {/* <h3 className="text-left tracking-wider title-dashboard">Selamat Datang Staff Akademik INGENIO!</h3> */}
                    </div>
                </div>
                <div className="px-3 md:px-8 mt-5">
                    <div className="container mx-auto max-w-full">
                        <div className="row">
                            <div className="col-md-8 col-lg-4 col-xs-12 mb-4">
                                <FormGroup>
                                    <div className="row" style={{ backgroundColor: '#FFF', padding: '1rem', borderRadius: '1rem' }}>
                                        <div className="col-8">
                                            <input value={keyword} onChange={(e) => setKeyword(e.target.value)} type="text" name="" style={{ backgroundColor: '#F3F4F6', width: '100%', borderRadius: '8px', border: 'none !important', height: '45px', paddingLeft: '10px' }} placeholder="&#x1F50E;&#xFE0E; Nama Program." id="" />
                                        </div>
                                        <div className="col-3">
                                            <Button onClick={() => {
                                                getListElearningProgram(`page=${page}&perPage=${perPage}&keyword=${keyword}`)
                                            }} className="pilih-tryout">Cari</Button>
                                        </div>
                                    </div>
                                </FormGroup>
                            </div>
                            <div className="col-8" style={{ padding: '1rem' }}>
                                <Button className="pilih-tryout" onClick={() => {
                                    setNamaProgram('')
                                    setDurasi('')
                                    setTanggalMulai('')
                                    setTanggalAkhir('')
                                    setTryoutIdSelected('')
                                    handleShow()
                                }}><BsFillPlusCircleFill /> Tambah Program</Button>

                                <Modal size="lg" show={show} onHide={handleClose}>
                                    <Modal.Header closeButton>
                                        <Modal.Title><strong>Tambah Program</strong></Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <div className="form-group p-5">
                                            <div className="row">
                                                <div className="col-12 lg:px-4">
                                                    <label htmlFor="" className="label-tryout">Nama Program</label> <br />
                                                    <Form.Control value={namaProgram} onChange={(e) => {
                                                        setNamaProgram(e.target.value)
                                                    }} type="text" name="" className="form-tryout mb-3" id="" />
                                                </div>
                                                <div className="col-12 lg:px-4">
                                                    <label htmlFor="" className="label-tryout">Durasi <span>(Dalam Jam)</span></label> <br />
                                                    <Form.Control value={durasi} onChange={(e) => {
                                                        setDurasi(e.target.value)
                                                    }} type="text" name="" className="form-tryout mb-3" id="" />
                                                </div>
                                                <div className="row lg:px-4" style={{ marginLeft: 'auto', marginRight: 'auto' }}>
                                                    <div className="col-6">
                                                        <label htmlFor="" className="label-tryout">Tanggal Mulai</label> <br />
                                                        <Form.Control value={tanggalMulai} onChange={(e) => {
                                                            const tanggalMulai = moment(e.target.value).format('yyyy-MM-DD')
                                                            setTanggalMulai(tanggalMulai)
                                                        }} type="date" name="" className="mb-3" placeholder="" />
                                                    </div>
                                                    <div className="col-6">
                                                        <label htmlFor="" className="label-tryout">Tanggal Selesai</label> <br />
                                                        <Form.Control value={tanggalAkhir} onChange={(e) => {
                                                            const tanggalAkhir = moment(e.target.value).format('yyyy-MM-DD')
                                                            setTanggalAkhir(tanggalAkhir)
                                                        }} type="date" name="" className="mb-3" placeholder="" />
                                                    </div>
                                                </div>
                                                <div className="col-12 lg:px-4 pb-3">
                                                    <label htmlFor="" className="label-tryout">Include Paket Tryout (Opsional)</label> <br />
                                                    <Form.Select
                                                        as="select"
                                                        value={tryoutIdSelected}
                                                        aria-label="Regio"
                                                        aria-describedby="basic-addon1"
                                                        className="my-tryout"
                                                        onChange={(e) => {
                                                            setTryoutIdSelected(e.target.value)
                                                        }}
                                                    >
                                                        <option value="">--Pilih Paket Tryout--</option>
                                                        {
                                                            tryoutData.map((item, i) => {
                                                                return <option value={item.id}>{item.nama_tryout}</option>
                                                            })
                                                        }
                                                        
                                                    </Form.Select>
                                                </div>
                                                <div className="col-12 lg:px-4">
                                                    <label htmlFor="" className="label-tryout">Berat Modul (Kg) (Optional)</label> <br />
                                                    <Form.Control value={weightPackage} onChange={(e) => {
                                                        setWeightPackage(e.target.value)
                                                    }} type="number" name="" className="form-tryout mb-3" id="" />
                                                </div>
                                            </div>
                                        </div>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <Button className="btn btn-dark" style={{ backgroundColor: '#00' }} onClick={handleClose}>
                                            Batal
                                        </Button>
                                        <Button className="btn btn-success" onClick={() => {
                                            addElarningProgramSubmit();
                                        }}>
                                            Submit
                                        </Button>
                                    </Modal.Footer>
                                </Modal>

                                <Modal size="lg" show={showUpdate} onHide={handleUpdateClose}>
                                    <Modal.Header closeButton>
                                        <Modal.Title><strong>Update Program</strong></Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <div className="form-group p-5">
                                            <div className="row">
                                                <div className="col-12 lg:px-4">
                                                    <label htmlFor="" className="label-tryout">Nama Program</label> <br />
                                                    <Form.Control value={namaProgram} onChange={(e) => {
                                                        setNamaProgram(e.target.value)
                                                    }} type="text" name="" className="form-tryout mb-3" id="" />
                                                </div>
                                                <div className="col-12 lg:px-4">
                                                    <label htmlFor="" className="label-tryout">Durasi <span>(Dalam Jam)</span></label> <br />
                                                    <Form.Control value={durasi} onChange={(e) => {
                                                        setDurasi(e.target.value)
                                                    }} type="text" name="" className="form-tryout mb-3" id="" />
                                                </div>
                                                <div className="row lg:px-4" style={{ marginLeft: 'auto', marginRight: 'auto' }}>
                                                    <div className="col-6">
                                                        <label htmlFor="" className="label-tryout">Tanggal Mulai</label> <br />
                                                        <Form.Control value={tanggalMulai} onChange={(e) => {
                                                            const tanggalMulai = moment(e.target.value).format('yyyy-MM-DD')
                                                            setTanggalMulai(tanggalMulai)
                                                        }} type="date" name="" className="mb-3" placeholder="" />
                                                    </div>
                                                    <div className="col-6">
                                                        <label htmlFor="" className="label-tryout">Tanggal Selesai</label> <br />
                                                        <Form.Control value={tanggalAkhir} onChange={(e) => {
                                                            const tanggalAkhir = moment(e.target.value).format('yyyy-MM-DD')
                                                            setTanggalAkhir(tanggalAkhir)
                                                        }} type="date" name="" className="mb-3" placeholder="" />
                                                    </div>
                                                </div>
                                                <div className="col-12 lg:px-4 pb-3">
                                                    <label htmlFor="" className="label-tryout">Include Paket Tryout (Opsional)</label> <br />
                                                    <Form.Select
                                                        as="select"
                                                        value={tryoutIdSelected}
                                                        aria-label="Regio"
                                                        aria-describedby="basic-addon1"
                                                        className="my-tryout"
                                                        onChange={(e) => {
                                                            setTryoutIdSelected(e.target.value)
                                                        }}
                                                    >
                                                        <option value="">--Pilih Paket Tryout--</option>
                                                        {
                                                            tryoutData.map((item, i) => {
                                                                return <option selected={tryoutIdSelected === item.id} value={item.id}>{item.nama_tryout}</option>
                                                            })
                                                        }
                                                        
                                                    </Form.Select>
                                                </div>
                                                <div className="col-12 lg:px-4">
                                                    <label htmlFor="" className="label-tryout">Berat Modul (Kg) (Optional)</label> <br />
                                                    <Form.Control value={weightPackage} onChange={(e) => {
                                                        setWeightPackage(e.target.value)
                                                    }} type="number" name="" className="form-tryout mb-3" id="" />
                                                </div>
                                            </div>
                                        </div>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <Button className="btn btn-dark" style={{ backgroundColor: '#00' }} onClick={handleUpdateClose}>
                                            Batal
                                        </Button>
                                        <Button className="btn btn-success" onClick={() => {
                                            updateElearningProgramSubmit();
                                        }}>
                                            Submit
                                        </Button>
                                    </Modal.Footer>
                                </Modal>
                            </div>
                            <div className="col-md-12 col-lg-12 col-xs-12">
                                {/* <DataTable columns={columns} data={data} pagination /> */}
                                <CustomDatatable 
                                    filteredItems={elearningData} 
                                    columns={columns}
                                    isLoading={isLoading}
                                    page={page}
                                    perPage={perPage}
                                    hasPrev={hasPrev}
                                    hasNext={hasNext}
                                    count={count}
                                    onTableChange={(e, data) => {
                                        switch (e) {
                                        case 'rowPerPageChange':
                                            setPerPage(data.perPage)
                                            getListElearningProgram(`page=${page}&perPage=${data.perPage}&keyword=${keyword}`)
                                            break;
                                        case 'rowPageChange':
                                            setPage(data.page)
                                            getListElearningProgram(`page=${data.page}&perPage=${perPage}&keyword=${keyword}`)
                                            break;
                                        
                                        default:
                                            break;
                                        }
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </React.Fragment >

    )
}