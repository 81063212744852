import Button from '@material-tailwind/react/Button';
import React, { useEffect, useRef, useState } from 'react';
import { FormGroup, FormControl, Form } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import { Link, useParams, useHistory } from 'react-router-dom';
import { BsPencilSquare, BsFillFileEarmarkArrowUpFill } from 'react-icons/bs';
import { TiWarning } from 'react-icons/ti';
import TutorSidebar from '../../../components/layout/dashboard/tutor/TutorSideBar';
import ElearningService from '../../../services/elearning';
import moment from 'moment-timezone';
import Swal from 'sweetalert2';

import * as Yup from 'yup';
import { useForm, FormProvider, useFieldArray, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';

import { fileValidation } from '../../../helpers/utils'

import { InputJawaban, SoalWYSIWYG, UploadGambar } from '../../../components/dashboard';

export default function InputJurnalMengajar(props) {
    var baseUrl = window.location.origin;

    const [files, setFiles] = useState(null);
    const [elearnginProgram, setElearningProgram] = useState('');
    const [elearningregio, setElearningRegio] = useState('');

    const didMountRef = useRef(false);
    const validationSchema = Yup.object().shape({
        jumlah_siswa: Yup.number().required('Jumlah siswa is required'),
        jumlah_first_taker: Yup.number().required('Jumlah first taker is required'),
        jumlah_retaker: Yup.number().required('Jumlah re taker is required'),

        sangat_tertarik: Yup.number().required('Sangat tertarik is required'),
        cukup_tertarik: Yup.number().required('Cukup tertarik is required'),
        tidak_tertarik: Yup.number().required('Tidak tertarik is required'),

        hiperaktif: Yup.number().required('Hiperaktif is required'),
        cukup_aktif: Yup.number().required('Cukup aktif is required'),
        pasif: Yup.number().required('Pasif is required'),

        advance: Yup.number().required('Advance is required'),
        medium: Yup.number().required('Medium is required'),
        basic: Yup.number().required('Basic is required'),

        level_istimewa: Yup.number().required('Istimewa is required'),
        level_cukup: Yup.number().required('Cukup is required'),
        level_lemah: Yup.number().required('Lemah is required'),

        materi_istimewa: Yup.number().required('Materi Istimewa is required'),
        materi_cukup: Yup.number().required('Materi cukup is required'),
        materi_lemah: Yup.number().required('Materi lemah is required'),

        ontime_commit_student: Yup.string().required('Kommit ontime peserta is required'),

        optimize_teaching: Yup.string().required('Keadaan optimal atau tidak is required'),

        distract_teaching: Yup.string().required('Ada distract atensi siswa atau tidak is required'),

        special_student: Yup.string().required('Ada siswa istimewa atau tidak is required'),

        special_needed_student: Yup.string().required('Ada siswa istimewa atau tidak is required'),
    });

    const {
        register,
        handleSubmit,
        watch,
        reset,
        setValue,
        formState: { errors, isSubmitSuccessful, submitCount, isSubmitted }
    } = useForm({ resolver: yupResolver(validationSchema) });

    useEffect(() => {
        if (didMountRef.current){
            if(!isSubmitSuccessful && submitCount > 0) {
                
            }
        } else {
            didMountRef.current = true;
        }
      }, [submitCount]);

    document.title = "INGENIO Indonesia";
    const [program, setProgram] = useState('');
    const [isLoading, setIsLoading] = useState(false)

    const history = useHistory();
    const { id } = useParams();

    const dropdown = {
        borderRadius: "10px",
        height: 50,
        padding: "25px",
    };

    const onSubmit = async (data) => {
        console.log(data);
        setIsLoading(true)
        let formData = new FormData();

        // Image validation
        if(data.gambar && data.gambar.length > 0){
            const file = data.gambar[0]
            const size = file.size;
            const name = file.name;
            if(!fileValidation(name.split('.')[name.split('.').length -1])){
                Swal.fire({  
                    title: 'Gagal!',  
                    text: 'File not allowed (jpg, png)',  
                    icon: 'warning',  
                    showCancelButton: true,  
                    cancelButtonText: 'Kembali',           
                    showConfirmButton: false,  
                });  
                setIsLoading(false)
                return;
            }

            let formData = new FormData();
            formData.append('file', data.gambar[0]);
            try {
                const fileResp = await ElearningService.uploadFileJurnalTutor(id, formData);
                data.teaching_photo_url = fileResp.data.data.url;
            }catch(err) {
                let msg = 'Internal Server Error'
                setIsLoading(false)
                console.error(err)
                if(err.response) {
                    if(err.response.data.message) {
                        msg = err.response.data.message
                    }
                }

                Fail(msg)
            }
            

        }

        // Upload image first
        data.gambar = undefined;
        // TODO: Upload Image

        // set validate time
        const tanggalDate = moment(data.tanggal_mengajar).format('yyyy-MM-DD');
        const jamMulaiDatetime = `${moment(`${moment(data.tanggal_mengajar).format('yyyy-MM-DD')} ${data.jam_mulai_mengajar}`).utc().format('yyyy-MM-DD HH:mm')}`
        const jamAkhirDatetime = `${moment(`${moment(data.tanggal_mengajar).format('yyyy-MM-DD')} ${data.jam_berakhir_mengajar}`).utc().format('yyyy-MM-DD HH:mm')}`

        const result = {...data, jam_mulai_mengajar: jamMulaiDatetime, jam_berakhir_mengajar: jamAkhirDatetime}

        ElearningService.updateJurnalTutor(id, result).then(resp => {
            Succeed();
        }).catch(err => {
            Swal.fire({  
                title: 'Gagal!',  
                text: 'Server Error',  
                icon: 'warning',  
                showCancelButton: true,  
                cancelButtonText: 'Kembali',           
                showConfirmButton: false,  
            }); 
        })

    };

    const Succeed = () => {
        Swal.fire({  
            title: 'Data Berhasil Tersimpan !',  
            text: '',  
            icon: 'success',  
            showCancelButton: false,              
            confirmButtonText: 'OK'  
        }).then((result) => {
          if (result.isConfirmed) {
            history.goBack();
          }
        })
    }

    const getDetailJurnalMengajar = () => {
        ElearningService.getDetailJurnalTutor(id).then(resp => {
            const response = resp.data.data;
            setElearningProgram(response.kelas.elearning_program.nama_program);
            setElearningRegio(response.kelas.nama_regio);
            const inputDatas = [
                { name: 'topik_pembelajaran', value: response.topik_pembelajaran },
                { name: 'tanggal_mengajar', value: moment(response.tanggal_mengajar).format('YYYY-MM-DD') },
                { name: 'jam_mulai_mengajar', value: moment(response.jam_mulai_mengajar).format('HH:mm') },
                { name: 'jam_berakhir_mengajar', value: moment(response.jam_berakhir_mengajar).format('HH:mm') },

                { name: 'jumlah_siswa', value: response.jumlah_siswa },
                { name: 'jumlah_first_taker', value: response.jumlah_first_taker },
                { name: 'jumlah_retaker', value: response.jumlah_retaker },

                { name: 'sangat_tertarik', value: response.sangat_tertarik },
                { name: 'cukup_tertarik', value: response.cukup_tertarik },
                { name: 'tidak_tertarik', value: response.tidak_tertarik },

                { name: 'hiperaktif', value: response.hiperaktif },
                { name: 'cukup_aktif', value: response.cukup_aktif },
                { name: 'pasif', value: response.pasif },

                { name: 'advance', value: response.advance },
                { name: 'medium', value: response.medium },
                { name: 'basic', value: response.basic },

                { name: 'level_istimewa', value: response.level_istimewa },
                { name: 'level_cukup', value: response.level_cukup },
                { name: 'level_lemah', value: response.level_lemah },

                { name: 'materi_istimewa', value: response.materi_istimewa },
                { name: 'materi_cukup', value: response.materi_cukup },
                { name: 'materi_lemah', value: response.materi_lemah },

                { name: 'ontime_commit_student', value: response.ontime_commit_student },

                { name: 'optimize_teaching', value: response.optimize_teaching },
                { name: 'optimize_teaching_reason', value: response.optimize_teaching_reason },

                { name: 'distract_teaching', value: response.distract_teaching },
                { name: 'distract_teaching_reason', value: response.distract_teaching_reason },

                { name: 'special_student', value: response.special_student },
                { name: 'special_student_reason', value: response.special_student_reason },
                { name: 'special_student_appraisal', value: response.special_student_appraisal },

                { name: 'special_needed_student', value: response.special_needed_student },
                { name: 'special_needed_student_reason', value: response.special_needed_student_reason },
                { name: 'special_needed_student_help', value: response.special_needed_student_help },
                { name: 'special_needed_student_advice', value: response.special_needed_student_advice },

                { name: 'special_needed_student_advice_for_ak', value: response.special_needed_student_advice_for_ak },

                { name: 'others', value: response.others },

                { name: 'teaching_photo_url', value: response.teaching_photo_url },
            ]
            for (let o = 0; o < inputDatas.length; o++) {
                const inputData = inputDatas[o];
                setValue(inputData.name, inputData.value)
            }

            // setKelasData(response.data);

            // setPage(response.meta.page)
            // setPerPage(response.meta.perPage)
            // setCount(response.meta.count)

            // setHasNext(response.meta.hasNext)
            // setHasPrev(response.meta.hasPrev);
            
            // setIsLoading(false)
        }).catch(err => {
            // setIsLoading(false)
            let msg = 'Internal Server Error'
            setIsLoading(false)
            console.error(err)
            if(err.response) {
                if(err.response.data.message) {
                    msg = err.response.data.message
                }
            }

            Fail(msg)
        })
    }

    useEffect(() => {
        getDetailJurnalMengajar()
    }, [])

    const Fail = (message) => {
        Swal.fire({  
            title: 'Gagal!',  
            text: message,  
            icon: 'warning',  
            showCancelButton: true,  
            cancelButtonText: 'Kembali',           
            showConfirmButton: false,  
        });   
    }

    return (
        <React.Fragment>
            <TutorSidebar />
            <div className="md:ml-64 tutorDataKelas" style={{ backgroundColor: '#F3F4F6' }}>
                <div className="bg-orange md:px-8 pb-5" style={{height: '24vh', position: 'relative', padding: '0 30px 30px 30px'}}>
                    <h1 className="tracking-wider title-24-600">Jurnal Mengajar</h1>
                    <h2 className="tracking-wider title-20-700 mt-5">Selamat Datang Tutor INGENIO !</h2>
                    <img src={baseUrl + "/assets/logo/evaluasikelas.png"} className="img-opacity"></img>
                </div>

                <div className="px-3 md:px-8 mt-5 pb-5">
                    <div className="container mx-auto max-w-full">
                        <div className="row">
                            <div className="col-md-9 ml-auto mr-auto">
                                 <form onSubmit={handleSubmit(onSubmit)}>
                                 <div className="cards pd-InJurnal">
                                    <div className="row">
                                        <div className="col-12 mb-4">
                                            <div className="title-20-700">Input Jurnal Mengajar</div>
                                            <div className="borderBottomGrey"></div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6 mb-4">
                                            <div className="titleForm">Program</div>
                                            <input value={elearnginProgram} type="text" class="formInputJurnal" placeholder="Program" disabled />
                                        </div>
                                        <div className="col-md-6 mb-4">
                                            <div className="titleForm">Regio</div>
                                            <input value={elearningregio} type="text" class="formInputJurnal" placeholder="Program" disabled />
                                        </div>
                                        <div className="col-12">
                                            <div className="titleForm">Topik Yang Diajar <span className="notice">*max 500 character</span> </div>
                                            <textarea disabled {...register("topik_pembelajaran")} className="formInputJurnal" maxlength="500" name="" id="" style={{height: '160px'}}></textarea>
                                        </div>
                                        <div className="col-12">
                                            <div className="titleForm">Tanggal Mengajar</div>
                                            <input disabled {...register("tanggal_mengajar")} type="date" className="formInputJurnal" placeholder="DD/MM/YY" />
                                        </div>
                                        <div className="col-md-6">
                                            <div className="titleForm">Jam Mulai</div>
                                            <input {...register("jam_mulai_mengajar")} type="text" class="formInputJurnal" placeholder="Program" disabled />
                                        </div>
                                        <div className="col-md-6">
                                            <div className="titleForm">Jam Berakhir</div>
                                            <input {...register("jam_berakhir_mengajar")} type="text" class="formInputJurnal" placeholder="Program" disabled />
                                        </div>
                                        <div className="col-lg-4">
                                            <div className="titleForm">Jumlah Siswa</div> {errors && errors.jumlah_siswa ? <span className='notice'>Data tidak valid atau kosong</span> : null}
                                            <input {...register("jumlah_siswa")} type="number" name="jumlah_siswa" class="formInputJurnal" placeholder="jumlah siswa" />
                                        </div>
                                        <div className="col-lg-4">
                                            <div className="titleForm">Jumlah First Taker</div> {errors && errors.jumlah_first_taker ? <span className='notice'>Data tidak valid atau kosong</span> : null}
                                            <input {...register("jumlah_first_taker")} type="number" name="jumlah_first_taker" class="formInputJurnal" placeholder="jumlah first taker" />
                                        </div>
                                        <div className="col-lg-4">
                                            <div className="titleForm">Jumlah Retaker</div> {errors && errors.jumlah_retaker ? <span className='notice'>Data tidak valid atau kosong</span> : null}
                                            <input {...register("jumlah_retaker")} type="number" name="jumlah_retaker" class="formInputJurnal" placeholder="jumlah retaker" />
                                        </div>
                                        <div className="col-12">
                                            <div className="title-18-500">Komitmen on-time Peserta</div> {errors && errors.ontime_commit_student ? <span className='notice'>Data tidak valid atau kosong</span> : null}
                                            <div className="flex gap-5 mt-2">
                                                <div className="form-check">
                                                    <input {...register("ontime_commit_student")} className="form-check-input" type="radio" value="Ya" name="ontime_commit_student" id="y" />
                                                    <label className="form-check-label" for="Ya">Ya</label>
                                                </div>
                                                <div className="form-check">
                                                    <input {...register("ontime_commit_student")} className="form-check-input" type="radio" value="Tidak" name="ontime_commit_student" id="t" />
                                                    <label className="form-check-label" for="Tidak">Tidak</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="title-18-600 mt-4">Afek Ketertarikan Siswa Terhadap Materi</div> 
                                        <div className="col-lg-4">
                                            <div className="titleForm">Sangat Tertarik</div> {errors && errors.sangat_tertarik ? <span className='notice'>Data tidak valid atau kosong</span> : null}
                                            <input {...register("sangat_tertarik")} type="number" name="sangat_tertarik" class="formInputJurnal" placeholder="Diisi dalam %" />
                                        </div>
                                        <div className="col-lg-4">
                                            <div className="titleForm">Cukup Tertarik</div> {errors && errors.cukup_tertarik ? <span className='notice'>Data tidak valid atau kosong</span> : null}
                                            <input {...register("cukup_tertarik")} type="number" name="cukup_tertarik" class="formInputJurnal" placeholder="Diisi dalam %" />
                                        </div>
                                        <div className="col-lg-4">
                                            <div className="titleForm">Tidak Tertarik</div> {errors && errors.tidak_tertarik ? <span className='notice'>Data tidak valid atau kosong</span> : null}
                                            <input {...register("tidak_tertarik")} type="number" name="tidak_tertarik" class="formInputJurnal" placeholder="Diisi dalam %" />
                                        </div>
                                        {/* <div className="justify-start">
                                            <div className="notifError"><TiWarning className="iconWarning" />Perhitungan Tidak Sesuai</div>
                                        </div> */}

                                        <div className="title-18-600 mt-3">Keaktifan Siswa Memberi Feedback</div>
                                        <div className="col-lg-4">
                                            <div className="titleForm">Hiperaktif</div> {errors && errors.hiperaktif ? <span className='notice'>Data tidak valid atau kosong</span> : null}
                                            <input {...register("hiperaktif")} type="number" name="hiperaktif" class="formInputJurnal" placeholder="Diisi dalam %" />
                                        </div>
                                        <div className="col-lg-4">
                                            <div className="titleForm">Cukup Aktif</div> {errors && errors.cukup_aktif ? <span className='notice'>Data tidak valid atau kosong</span> : null}
                                            <input {...register("cukup_aktif")} type="number" name="cukup_aktif" class="formInputJurnal" placeholder="Diisi dalam %" />
                                        </div>
                                        <div className="col-lg-4">
                                            <div className="titleForm">Pasif</div> {errors && errors.pasif ? <span className='notice'>Data tidak valid atau kosong</span> : null}
                                            <input {...register("pasif")} type="number" name="pasif" class="formInputJurnal" placeholder="Diisi dalam %" />
                                        </div>
                                        {/* <div className="justify-start">
                                            <div className="notifError"><TiWarning className="iconWarning" />Perhitungan Tidak Sesuai</div>
                                        </div> */}

                                        <div className="title-18-600 mt-3">Level Pertanyaan</div>
                                        <div className="col-lg-4">
                                            <div className="titleForm">Advance</div> {errors && errors.advance ? <span className='notice'>Data tidak valid atau kosong</span> : null}
                                            <input {...register("advance")} type="number" name="advance" class="formInputJurnal" placeholder="Diisi dalam %" />
                                        </div>
                                        <div className="col-lg-4">
                                            <div className="titleForm">Medium</div> {errors && errors.medium ? <span className='notice'>Data tidak valid atau kosong</span> : null}
                                            <input {...register("medium")} type="number" name="medium" class="formInputJurnal" placeholder="Diisi dalam %" />
                                        </div>
                                        <div className="col-lg-4">
                                            <div className="titleForm">Basic</div> {errors && errors.basic ? <span className='notice'>Data tidak valid atau kosong</span> : null}
                                            <input {...register("basic")} type="number" name="basic" class="formInputJurnal" placeholder="Diisi dalam %" />
                                        </div>
                                        {/* <div className="justify-start">
                                            <div className="notifError"><TiWarning className="iconWarning" />Perhitungan Tidak Sesuai</div>
                                        </div> */}

                                        <div className="title-18-600 mt-3">Level Keilmuan Dasar</div>
                                        <div className="col-lg-4">
                                            <div className="titleForm">Istimewa</div> {errors && errors.level_istimewa ? <span className='notice'>Data tidak valid atau kosong</span> : null}
                                            <input {...register("level_istimewa")} type="number" name="level_istimewa" class="formInputJurnal" placeholder="Diisi dalam %" />
                                        </div>
                                        <div className="col-lg-4">
                                            <div className="titleForm">Cukup</div> {errors && errors.level_cukup ? <span className='notice'>Data tidak valid atau kosong</span> : null}
                                            <input {...register("level_cukup")} type="number" name="level_cukup" class="formInputJurnal" placeholder="Diisi dalam %" />
                                        </div>
                                        <div className="col-lg-4">
                                            <div className="titleForm">Lemah</div> {errors && errors.level_lemah ? <span className='notice'>Data tidak valid atau kosong</span> : null}
                                            <input {...register("level_lemah")} type="number" name="level_lemah" class="formInputJurnal" placeholder="Diisi dalam %" />
                                        </div>
                                        {/* <div className="justify-start">
                                            <div className="notifError"><TiWarning className="iconWarning" />Perhitungan Tidak Sesuai</div>
                                        </div> */}

                                        <div className="title-18-600 mt-3">Memori Immediate Terhadap Materi Fasin</div>
                                        <div className="col-lg-4">
                                            <div className="titleForm">Istimewa</div> {errors && errors.materi_istimewa ? <span className='notice'>Data tidak valid atau kosong</span> : null}
                                            <input {...register("materi_istimewa")} type="number" name="materi_istimewa" class="formInputJurnal" placeholder="Diisi dalam %" />
                                        </div>
                                        <div className="col-lg-4">
                                            <div className="titleForm">Cukup</div> {errors && errors.materi_cukup ? <span className='notice'>Data tidak valid atau kosong</span> : null}
                                            <input {...register("materi_cukup")} type="number" name="materi_cukup" class="formInputJurnal" placeholder="Diisi dalam %" />
                                        </div>
                                        <div className="col-lg-4">
                                            <div className="titleForm">Lemah</div> {errors && errors.materi_lemah ? <span className='notice'>Data tidak valid atau kosong</span> : null}
                                            <input {...register("materi_lemah")} type="number" name="materi_lemah" class="formInputJurnal" placeholder="Diisi dalam %" />
                                        </div>
                                        {/* <div className="justify-start">
                                            <div className="notifError"><TiWarning className="iconWarning" />Perhitungan Tidak Sesuai</div>
                                        </div> */}
                                    </div>
                           
                                    <div className="row mt-3">
                                        <div className="title-20-700">Penjabaran Deskriptif</div>
                                        <div className="col-12 mt-3">
                                            <div className="title-18-500">Apakah Anda dalam keadaan optimal saat mengajar kelas ini?</div> {errors && errors.optimize_teaching ? <span className='notice'>Data tidak valid atau kosong</span> : null}
                                            <div className="flex gap-4 mt-2">
                                                <div className="form-check">
                                                    <input className="form-check-input" {...register("optimize_teaching")} value="Ya" type="radio" name="optimize_teaching" id="ya1" />
                                                    <label className="form-check-label" for="ya1">Ya</label>
                                                </div>
                                                <div className="form-check">
                                                    <input className="form-check-input" {...register("optimize_teaching")} value="Tidak" type="radio" name="optimize_teaching" id="tidak1" />
                                                    <label className="form-check-label" for="tidak1">Tidak</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 mt-4">
                                            <div className="title-18-500">Jika tidak, ceritakan apa yang Anda rasa membuat Anda secara personal kurang optimal dalam mengajar kelas ini. <span className="notice">*max 500 character</span> </div>
                                            <div className="col-12 mt-2">
                                                <textarea {...register("optimize_teaching_reason")} className="formInputJurnal" maxlength="500" name="optimize_teaching_reason" id="" style={{height: '120px'}}></textarea>
                                            </div>
                                        </div>
                                        <div className="col-12 mt-3">
                                            <div className="title-18-500">Adakah distraktor atensi siswa di kelas ini saat anda mengajar?</div> {errors && errors.distract_teaching ? <span className='notice'>Data tidak valid atau kosong</span> : null}
                                            <div className="flex gap-4 mt-2">
                                                <div className="form-check">
                                                    <input className="form-check-input" {...register("distract_teaching")} value="Ya" type="radio" name="distract_teaching" id="ya2" />
                                                    <label className="form-check-label" for="ya2">Ya</label>
                                                </div>
                                                <div className="form-check">
                                                    <input className="form-check-input" {...register("distract_teaching")} value="Tidak" type="radio" name="distract_teaching" id="tidak2" />
                                                    <label className="form-check-label" for="tidak2">Tidak</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 mt-4">
                                            <div className="title-18-500">Jika Ya, apa distraktor komunal yang menggangu atensi siswa saat Anda mengajar? <span className="notice">*max 500 character</span></div>
                                            <div className="col-12 mt-2">
                                                <textarea {...register("distract_teaching_reason")} className="formInputJurnal" maxLength="500" name="distract_teaching_reason" id="" style={{height: '120px'}}></textarea>
                                            </div>
                                        </div>
                                        <div className="col-12 mt-3">
                                            <div className="title-18-500">Apakah ada siswa di kelas ini yang Anda rasa istimewa?</div> {errors && errors.special_student ? <span className='notice'>Data tidak valid atau kosong</span> : null}
                                            <div className="flex gap-4 mt-2">
                                                <div className="form-check">
                                                    <input className="form-check-input" {...register("special_student")} value="Ya" type="radio" name="special_student" id="ya3" />
                                                    <label className="form-check-label" for="ya3">Ya</label>
                                                </div>
                                                <div className="form-check">
                                                    <input className="form-check-input" {...register("special_student")} value="Tidak" type="radio" name="special_student" id="tidak3" />
                                                    <label className="form-check-label" for="tidak3">Tidak</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 mt-4">
                                            <div className="title-18-500">Jika Ya, sebutkan nama dan jelaskan keistimewaannya! <span className="notice">*max 500 character</span></div>
                                            <div className="col-12 mt-2">
                                                <textarea {...register("special_student_reason")} className="formInputJurnal" maxlength="500" name="special_student_reason" id="" style={{height: '80px'}}></textarea>
                                            </div>
                                        </div>
                                        <div className="col-12 mt-4">
                                            <div className="title-18-500">Apa yang sudah anda lakukan untuk mengapresiasi keistimewaannya? <span className="notice">*max 500 character</span></div>
                                            <div className="col-12 mt-2">
                                                <textarea {...register("special_student_appraisal")} className="formInputJurnal" maxlength="500" name="special_student_appraisal" id="" style={{height: '80px'}}></textarea>
                                            </div>
                                        </div>
                                        <div className="col-12 mt-3">
                                            <div className="title-18-500">Apakah ada siswa dikelas ini yang Anda rasa memiliki kebutuhan khusus?</div> {errors && errors.special_needed_student ? <span className='notice'>Data tidak valid atau kosong</span> : null}
                                            <div className="flex gap-4 mt-2">
                                                <div className="form-check">
                                                    <input className="form-check-input" {...register("special_needed_student")} value="Ya" type="radio" name="special_needed_student" id="ya4" />
                                                    <label className="form-check-label" for="ya4">Ya</label>
                                                </div>
                                                <div className="form-check">
                                                    <input className="form-check-input" {...register("special_needed_student")} value="Tidak" type="radio" name="special_needed_student" id="tidak4" />
                                                    <label className="form-check-label" for="tidak4">Tidak</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 mt-4">
                                            <div className="title-18-500">Jika Ya, sebutkan nama dan jelaskan alasan/bentuk seperti apa kebutuhan khususnya! <span className="notice">*max 500 character</span></div>
                                            <div className="col-12 mt-2">
                                                <textarea {...register("special_needed_student_reason")} className="formInputJurnal" maxlength="500" name="special_needed_student_reason" id="" style={{height: '80px'}}></textarea>
                                            </div>
                                        </div>
                                        <div className="col-12 mt-4">
                                            <div className="title-18-500">Apa yang sudah anda lakukan untuk membantu siswa tersebut? <span className="notice">*max 500 character</span></div>
                                            <div className="col-12 mt-2">
                                                <textarea {...register("special_needed_student_help")} className="formInputJurnal" maxlength="500" name="special_needed_student_help" id="" style={{height: '80px'}}></textarea>
                                            </div>
                                        </div>
                                        <div className="col-12 mt-4">
                                            <div className="title-18-500">Saran untuk tutor lain untuk menghadapi siswa ini <span className="notice">*max 500 character</span></div>
                                            <div className="col-12 mt-2">
                                                <textarea {...register("special_needed_student_advice")} className="formInputJurnal" maxlength="500" name="special_needed_student_advice" id="" style={{height: '80px'}}></textarea>
                                            </div>
                                        </div>
                                        <div className="col-12 mt-4">
                                            <div className="title-18-500">Saran untuk tim akademik untuk menghadapi siswa ini <span className="notice">*max 500 character</span></div>
                                            <div className="col-12 mt-2">
                                                <textarea {...register("special_needed_student_advice_for_ak")} className="formInputJurnal" maxlength="500" name="special_needed_student_advice_for_ak" id="" style={{height: '80px'}}></textarea>
                                            </div>
                                        </div>
                                        <div className="col-12 mt-4">
                                            <div className="title-18-500">Hal deskriptif lain yang ingin Anda sampaikan diluar pertanyaan di atas <span className="notice">*max 500 character</span></div>
                                            <div className="col-12 mt-2">
                                                <textarea {...register("others")} className="formInputJurnal" maxlength="500" name="others" id="" style={{height: '230px'}}></textarea>
                                            </div>
                                        </div>
                                        <div className="col-12 mt-3 mb-4">
                                            <div className="title-18-500">Upload foto bukti saat mengajar</div>
                                            <div className="relative mt-2">
                                                {/* <Controller
                                                    name="gambar"
                                                    control={methods.control}
                                                    render={({
                                                        field: { onChange, onBlur, value, name, ref } }) => {
                                                        return <UploadGambar value={value} onChange={(e) => {
                                                            isDeleteGambarSoal = false;
                                                            onChange(e.target.files[0]);
                                                        }} />
                                                    }}
                                                /> */}
                                                <input {...register("gambar")} type="file" id="file" className="custom-file-input" style={{ position: 'absolute', left: '2rem' }} />
                                                <label htmlFor="file" className="labelFile">Upload Bukti <BsFillFileEarmarkArrowUpFill size="18px"/></label>
                                            </div>
                                        </div>
                                        <div className="col-12 justify-end" style={{ marginTop: '4rem' }}>
                                            <Button type="submit" className="btnOrange3">
                                                <span className="txt-btn">Submit Jurnal Mengajar</span>
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                                        
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </React.Fragment >

    )
}