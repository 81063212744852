import React from 'react';

export default function History() {

    return (
        <React.Fragment>
            <section className="container-fluid">
                <div className="site-header site-header--menu-left landing-11-menu site-header--absolute" style={{ backgroundImage: 'url(/assets/landing/Genio2.png)', backgroundPositionY: 'bottom', backgroundPositionX: 'right', backgroundRepeat: 'no-repeat', paddingTop: '6rem', backgroundColor: '#ffcc00' }}>
                    <h5 className="text-center tentang_title py-5" style={{ paddingBottom: '2rem !important' }}>HISTORY</h5>
                </div>
            </section>
            <section className="container-fluid bg-desktop" style={{ backgroundImage: 'url(/assets/landing/bg-value.png)' }}>
                <div className="container px-4" style={{ paddingTop: '12rem', width: '85%' }}>
                    <div className="row g-2" style={{ textAlign: 'justify', paddingTop: '5rem' }}>
                        <p className="ct pt-5 pb-4" style={{ color: '#000000' }}>
                        Ingenio Indonesia, yang sebelumnya bernama Ingenio Learning Center berdiri pada tahun 2013. Dengan
mengamati fenomena-fenomena terkait UKDI di masa itu (dan nampaknya masih relevan hingga saat ini),
mulai dari banyaknya peserta yang gagal hingga keberadaan bimbingan persiapan UKDI yang hanya
berfokus pada soal, maka didirikanlah bimbingan UKDI di kota Malang.
                        </p>

                        <p className="ct pb-4" style={{ color: '#000000' }}>
                        Ingenio bertujuan untuk menjadi bimbingan kedokteran dan tenaga medis lainnya yang berfokus pada pola pikir yang benar, bukan hanya sekedar soal, karena dengan pola pikir yang benar maka semua soal dapat dijawab dengan baik. Lebih dari itu, dengan pola pikir yang benar maka peserta bimbingan kami memiliki modal yang akan terus terbawa saat menjalankan peran dan tugasnya secara profesional nantinya. 
                        </p>

                        <p className="ct pb-4" style={{ color: '#000000' }}>
                        Pada awal berdirinya, Ingenio membimbing 17 calon sejawat dalam persiapan UKDI yang berasal dari Universitas Brawijaya Malang dan Universitas Hang Tuah Surabaya. Bimbingan diadakan di tempat tinggal salah seorang tutor. Debut Ingenio dengan 17 peserta tersebut mencapai angka kelulusan yang sangat memuaskan menjadikan nama Ingenio dikenal oleh sejawat lainnya. Sejak saat itu, Ingenio berkembang dengan sangat pesat dan dipercayakan lebih banyak sejawat. Peserta yang mulanya hanya berasalh dari kota Surabaya dan Malang, kemudian berkembang dengan bertambahnya peserta dari kota Jember, Yogyakarta, Purwokerto, Solo, Semarang, Bandung, Cirebon, Jakarta, Sumatera, Bali, Kalimantan hingga Nusa Tenggara, Sulawesi dan Papua. Dalam kurun waktu 10 tahun, Ingenio telah membantu 32.000 sejawat untuk bimbingan preklinik hingga lulus ujian kompetensi dan menjadi dokter yang lebih baik. Sejawat tersebut berasal dari berbagai universitas di Indonesia dari Sumatera hingga Papua.
                        </p>

                        {/* <div className="row pbr mt1r">
                            <div className="col-2 iconz">
                                <img src="/assets/icons/square-blue.png" />
                            </div>
                            <div className="col-10">
                                <p className="ct" style={{ color: '#000000', fontWeight: 'bold' }}>Universitas Airlangga (Surabaya)</p>
                            </div>
                        </div>
                        <div className="row pbr mt1r">
                            <div className="col-2 iconz">
                                <img src="/assets/icons/square-green.png" />
                            </div>
                            <div className="col-10">
                                <p className="ct" style={{ color: '#000000', fontWeight: 'bold' }}>Universitas Wijaya Kusuma Surabaya (Surabaya)</p>
                            </div>
                        </div>
                        <div className="row pbr mt1r">
                            <div className="col-2 iconz">
                                <img src="/assets/icons/square-red.png" />
                            </div>
                            <div className="col-10">
                                <p className="ct" style={{ color: '#000000', fontWeight: 'bold' }}>Universitas Hang Tuah (Surabaya)</p>
                            </div>
                        </div>
                        <div className="row pbr mt1r">
                            <div className="col-2 iconz">
                                <img src="/assets/icons/square-purple.png" />
                            </div>
                            <div className="col-10">
                                <p className="ct" style={{ color: '#000000', fontWeight: 'bold' }}>Universitas Brawijaya (Malang)</p>
                            </div>
                        </div>
                        <div className="row pbr mt1r">
                            <div className="col-2 iconz">
                                <img src="/assets/icons/square-blue1.png" />
                            </div>
                            <div className="col-10">
                                <p className="ct" style={{ color: '#000000', fontWeight: 'bold' }}>Universitas Muhammadiyah Malang</p>
                            </div>
                        </div>
                        <div className="row pbr mt1r">
                            <div className="col-2 iconz">
                                <img src="/assets/icons/square-blue2.png" />
                            </div>
                            <div className="col-10">
                                <p className="ct" style={{ color: '#000000', fontWeight: 'bold' }}>Universitas Islam Malang</p>
                            </div>
                        </div>
                        <div className="row pbr mt1r">
                            <div className="col-2 iconz">
                                <img src="/assets/icons/square-orange.png" />
                            </div>
                            <div className="col-10">
                                <p className="ct" style={{ color: '#000000', fontWeight: 'bold' }}>Universitas Muhammadiyah Yogyakarta</p>
                            </div>
                        </div>
                        <div className="row pbr mt1r">
                            <div className="col-2 iconz">
                                <img src="/assets/icons/square-blue.png" />
                            </div>
                            <div className="col-10">
                                <p className="ct" style={{ color: '#000000', fontWeight: 'bold' }}>Universitas Udayana (Denpasar)</p>
                            </div>
                        </div>
                        <div className="row pbr mt1r">
                            <div className="col-2 iconz">
                                <img src="/assets/icons/square-purple2.png" />
                            </div>
                            <div className="col-10">
                                <p className="ct" style={{ color: '#000000', fontWeight: 'bold' }}>Universitas Hasanuddin (Makassar)</p>
                            </div>
                        </div>
                        <div className="row pbr mt1r">
                            <div className="col-2 iconz">
                                <img src="/assets/icons/square-purple3.png" />
                            </div>
                            <div className="col-10">
                                <p className="ct" style={{ color: '#000000', fontWeight: 'bold' }}>Universitas Muslim Indonesia (Makassar)</p>
                            </div>
                        </div>
                        <div className="row pbr mt1r">
                            <div className="col-2 iconz">
                                <img src="/assets/icons/square-blue3.png" />
                            </div>
                            <div className="col-10">
                                <p className="ct" style={{ color: '#000000', fontWeight: 'bold' }}>Universitas Syiah Kuala (Banda Aceh)</p>
                            </div>
                        </div>
                        <div className="row pbr mt1r">
                            <div className="col-2 iconz">
                                <img src="/assets/icons/square-green2.png" />
                            </div>
                            <div className="col-10">
                                <p className="ct" style={{ color: '#000000', fontWeight: 'bold' }}>Universitas Lambung Mangkurat (Banjarmasin)</p>
                            </div>
                        </div>
                        <div className="row pbr mt1r">
                            <div className="col-2 iconz">
                                <img src="/assets/icons/square-purple4.png" />
                            </div>
                            <div className="col-10">
                                <p className="ct" style={{ color: '#000000', fontWeight: 'bold' }}>Universitas Mulawarman (Samarinda)</p>
                            </div>
                        </div>
                        <div className="row pbr mt1r">
                            <div className="col-2 iconz">
                                <img src="/assets/icons/square-pink.png" />
                            </div>
                            <div className="col-10">
                                <p className="ct" style={{ color: '#000000', fontWeight: 'bold' }}>Universitas Sam Ratulangi (Manado)</p>
                            </div>
                        </div> */}

                        <p className="ct pt-4 pb-4" style={{ color: '#000000' }}>
                        Ingenio Indonesia berkomitmen menjaga nadi perusahaan untuk terus berdenyut dalam sinergi pendidikan kedokteran dan ilmu kesehatan di Indonesia dalam rangka peningkatan pelayanan kesehatan dengan memperhatikan etika dan keilmuan di bidang kesehatan. Pada tahun 2021 Ingenio Indonesia telah memiliki 4 kantor cabang utama (Malang, Surabaya, Denpasar, dan Jakarta) dengan banyak kota sub regio seperti Semarang, Yogyakarta, Purwokerto, Bandung, Cirebon, Jember, Solo, Mataram, Samarinda, Banjarmasin, Makassar, Sumatera, hingga Papua.
                        </p>
                        <p className="ct pb-4" style={{ color: '#000000' }}>
                        Kini dalam program studi pendidikan dokter, Ingenio tidak hanya mempersiapkan sejawat untuk menghadapi UKMPPD, namun juga pendidikan mahasiswa kedokteran, bimbingan persiapan Uji Tahap Bersama, bimbingan skripsi dan kebidanan preklinik, persiapan mahasiswa FK menuju pendidikan klinik dan bimbingan dokter muda. Selain itu, Ingenio saat ini juga memiliki program persiapan UKMP2DG, UKOM Kebidanan, dan preklinik gigi untuk sejawat calon dokter gigi di Indonesia. Semua hal ini kami lakukan untuk memberikan warna positif dalam dunia kesehatan Indonesia dan membuktikan bahwa lulus ujian kompetensi tidaklah sulit. Itulah yang terangkum menjadi moto kami “It’s Not That Hard, Seriously!”
                        </p>
                        <p className="ct pb-4" style={{ color: '#000000' }}>
                        Di masa pandemi Covid 19 ini, Ingenio Indonesia cepat beradaptasi dengan mempersiapkan semua lini tutor, kurikulum dan operasional penunjangnya untuk dapat tetap maksimal memberikan bimbingan kepada para peserta secara daring (online). Pengajaran disusun sedemikian rupa agar peserta bimbingan tidak hanya belajar satu arah, tapi juga lebih mantap serta turut aktif dalam diskusi dan evaluasi diri secara kontinyu untuk mempersiapkan hasil yang terbaik.
                        </p>
                        <p className="ct pb-4" style={{ color: '#000000' }}>
                        Ingenio Indonesia hadir bukanlah hanya sekedar sebagai sebuah bisnis pendidikan. Namun Ingenio berkomitmen hadir dengan cita-cita luhur bahwa kebaikan harus terus diduplikasi. Kebaikan untuk dapat menghantar dokter, dokter gigi dan tenaga medis lainnya lulus dengan menjalankan perannya dengan keterampilan dan keilmuan profesional yang berdasarkan hati nurani. Kami yakin dengan seluruh tujuan luhur, kesatuan visi misi, sumber daya yang kami miliki, pengembangan sistem dan pengendalian mutu yang terus kami upayakan, Ingenio Indonesia akan menjadi lembaga suplementasi pengajaran dan pendidikan tenaga medis yang terbesar di Indonesia untuk dapat merangkul dan menjadi rumah bagi inkubasi kebaikan nurani semua yang mau masuk di dalamnya.
                        </p>
                    </div>
                </div>
            </section>
        </React.Fragment >
    )
}

