import { useState } from 'react';
import { NavLink } from 'react-router-dom';
import AdminNavbar from './AdminNavbar';
import { BsFillGridFill, BsTv, BsBookHalf, BsBoxSeam, BsPen, BsChat, BsUpload } from "react-icons/bs";
import { BiDollar } from "react-icons/bi";
import { RiBookMarkFill } from "react-icons/ri";
import Dropdown from "@material-tailwind/react/Dropdown"
import DropdownLink from "@material-tailwind/react/DropdownLink"
import { Link } from 'react-router-dom'

import AccessControl from '../../../helpers/AccessControl';

export default function Sidebar({ judul }) {
    const logoFooter = {
        width: "100%",
        height: "auto",
    };
    const [showSidebar, setShowSidebar] = useState('-left-64');
    var baseUrl = window.location.origin;
    return (
        <>
            <AdminNavbar
                judul={judul}
                showSidebar={showSidebar}
                setShowSidebar={setShowSidebar}
            />
            <div
                className={`h-screen fixed top-0 md:left-0 ${showSidebar} overflow-y-auto flex-row flex-nowrap overflow-hidden shadow-xl bg-white w-64 z-10 py-4 px-6 transition-all duration-300`}
            >
                <div className="flex-col items-stretch min-h-full flex-nowrap px-0 relative">
                    <NavLink
                        to="/dashboard"
                        className="mt-2 text-center w-full inline-block"
                    >
                        <img src={baseUrl + "/assets/logo/ingenio rakun 2.png"} alt="logo" style={logoFooter}/>
                    </NavLink>
                    <div className="flex flex-col">
                        <hr className="my-4 min-w-full" />

                        <ul className="flex-col min-w-full flex list-none" style={{fontFamily: "Quicksand"}}>
                            <li className="rounded-lg mb-2">
                                <NavLink
                                    to="/dashboard"
                                    exact
                                    className="flex items-center gap-4 text-sm text-gray-700 px-2 py-2 rounded-lg"
                                    activeClassName="SidebarActive"
                                    style={{fontWeight: 500}}
                                >
                                    <BsFillGridFill size="25px"/>
                                    <span className="SidebarName">Dashboard</span>
                                </NavLink>
                            </li>
                            <AccessControl 
                                role={"STUDENT"} 
                                isProfileComplete={true}>
                                <li className="rounded-lg mb-2">
                                    <Dropdown className="flex items-center gap-4 text-sm text-gray-700 font-light px-2 py-2 rounded-lg"
                                            activeClassName="SidebarActive"
                                            placement="bottom-start"
                                            buttonText={
                                                <div style={{display: 'inline-flex', fontWeight: 500}}>
                                                    <BsTv style={{marginRight: '25px'}} size="25px" />
                                                    Course
                                                </div>
                                            }
                                            buttonType="link"
                                            size="regular"
                                            rounded={false}
                                            block={false}
                                            ripple="dark"
                                            style={{fontWeight: 'normal'}}>
                                        <DropdownLink href="/dashboard/course-tersedia">
                                            <div style={{display: 'inline-flex', fontWeight: 500}}>
                                                <BsTv style={{marginRight: '15px'}} size="20px" />
                                                Course Tersedia
                                            </div>                                        
                                        </DropdownLink>
                                        <DropdownLink href="/dashboard/course-saya">
                                            <div style={{display: 'inline-flex', fontWeight: 500}}>
                                                <BsTv style={{marginRight: '15px'}} size="20px" />
                                                Course Saya
                                            </div>
                                        </DropdownLink>
                                    </Dropdown>
                                </li>
                            </AccessControl>
                            <AccessControl 
                                role={"STUDENT"} 
                                isProfileComplete={true}>
                                <li className="rounded-lg mb-2">
                                    <Dropdown className="flex items-center gap-4 text-sm text-gray-700 font-light px-2 py-2 rounded-lg"
                                            activeClassName="SidebarActive"
                                            placement="bottom-start"
                                            buttonText={
                                                <div style={{display: 'inline-flex', fontWeight: 500}}>
                                                    <BsBookHalf style={{marginRight: '20px'}} size="25px"/>
                                                    <span className="SidebarName">E-Learning</span>
                                                </div>
                                            }
                                            buttonType="link"
                                            size="regular"
                                            rounded={false}
                                            block={false}
                                            ripple="dark"
                                            style={{fontWeight: 'normal'}}>
                                        <DropdownLink>
                                            <Link to="/dashboard/e-learning/kelas-tersedia">
                                                <div style={{display: 'inline-flex'}}>
                                                    <RiBookMarkFill style={{marginRight: '15px', fontWeight: 500}} size="20px" />
                                                    Kelas Tersedia
                                                </div> 
                                            </Link>                                     
                                        </DropdownLink>
                                        <DropdownLink>
                                            <Link to="/dashboard/e-learning/kelas-saya">
                                                <div style={{display: 'inline-flex'}}>
                                                    <RiBookMarkFill style={{marginRight: '15px', fontWeight: 500}} size="20px" />
                                                    Kelas Saya
                                                </div>
                                            </Link>
                                        </DropdownLink>
                                        <DropdownLink>
                                            <Link to="/dashboard/e-learning/resi">
                                                <div style={{display: 'inline-flex'}}>
                                                    <RiBookMarkFill style={{marginRight: '15px', fontWeight: 500}} size="20px" />
                                                    Resi
                                                </div>
                                            </Link>
                                        </DropdownLink>
                                        <DropdownLink>
                                            <Link to="/dashboard/e-learning/evaluasi-kelas">
                                                <div style={{display: 'inline-flex'}}>
                                                    <RiBookMarkFill style={{marginRight: '15px', fontWeight: 500}} size="20px" />
                                                    Evaluasi Kelas
                                                </div>
                                            </Link>
                                        </DropdownLink>
                                        <DropdownLink>
                                            <Link to="/dashboard/e-learning/history-kelas">
                                                <div style={{display: 'inline-flex'}}>
                                                    <RiBookMarkFill style={{marginRight: '15px', fontWeight: 500}} size="20px" />
                                                    History Kelas
                                                </div>
                                            </Link>
                                        </DropdownLink>
                                        <DropdownLink>
                                            <Link to="/dashboard/e-learning/e-library">
                                                <div style={{display: 'inline-flex'}}>
                                                    <RiBookMarkFill style={{marginRight: '15px', fontWeight: 500}} size="20px" />
                                                    E-Library
                                                </div>
                                            </Link>
                                           
                                        </DropdownLink>
                                    </Dropdown>
                                </li>
                            </AccessControl>
                            {/* <AccessControl 
                                role={"STUDENT"} 
                                isProfileComplete={true}>
                                <li className="rounded-lg mb-2">
                                    <NavLink
                                        to="/dashboard/elibrary"
                                        className="flex items-center gap-4 text-sm text-gray-700 font-light px-2 py-2 rounded-lg"
                                        activeClassName="SidebarActive"
                                        style={{fontWeight: 500}}
                                    >
                                    <BsFolder size="25px"/>
                                        E-Library
                                    </NavLink>
                                </li>
                            </AccessControl> */}
                            <AccessControl 
                                role={"STUDENT"} 
                                isProfileComplete={true}>
                                <li className="rounded-lg mb-2">
                                    {/* <NavLink
                                        to="/dashboard/user/tryout"
                                        className="flex items-center gap-4 text-sm text-gray-700 font-light px-2 py-2 rounded-lg"
                                        activeClassName="SidebarActive"
                                    >
                                    <BsPen size="25px" />
                                    TryOut
                                    </NavLink> */}
                                    <Dropdown className="flex items-center gap-4 text-sm text-gray-700 px-2 py-2 rounded-lg"
                                            activeClassName="SidebarActive"
                                            placement="bottom-start"
                                            buttonText={
                                                <div style={{display: 'inline-flex', fontWeight: 500}}>
                                                    <BsPen style={{marginRight: '25px'}} size="25px" />
                                                    <span className="SidebarName">TryOut</span>
                                                </div>
                                            }
                                            buttonType="link"
                                            size="regular"
                                            rounded={false}
                                            block={false}
                                            ripple="dark"
                                            style={{fontWeight: 'normal'}}>
                                        <DropdownLink>
                                                <Link to="/dashboard/tryout">
                                                    <div style={{display: 'inline-flex', fontWeight: 500}}>
                                                        <BsPen style={{marginRight: '15px'}} size="20px" />
                                                        Tryout Tersedia
                                                    </div> 
                                                </Link>
                                                                                       
                                        </DropdownLink>
                                        <DropdownLink>
                                            <Link to="/dashboard/tryout-saya">
                                                <div style={{display: 'inline-flex', fontWeight: 500}}>
                                                    <BsPen style={{marginRight: '15px'}} size="20px" />
                                                    Tryout Saya
                                                </div>
                                            </Link>
                                        </DropdownLink>
                                        <DropdownLink>
                                            <Link to="/dashboard/hasil-tryout">
                                                <div style={{display: 'inline-flex', fontWeight: 500}}>
                                                    <BsPen style={{marginRight: '15px', fontWeight: 500}} size="20px" />
                                                    Hasil Tryout
                                                </div>
                                            </Link>
                                        </DropdownLink>
                                        <DropdownLink>
                                            <Link to="/dashboard/tata-cara">
                                                <div style={{display: 'inline-flex', fontWeight: 500}}>
                                                    <BsPen style={{marginRight: '15px'}} size="20px" />
                                                    Tata Cara Tryout
                                                </div>   
                                            </Link>                                     
                                        </DropdownLink>
                                    </Dropdown>
                                </li>
                            </AccessControl>
                            <AccessControl 
                                role={"STUDENT"} 
                                isProfileComplete={true}>
                                <li className="rounded-lg mb-2">
                                    <NavLink
                                        to="/dashboard/transaction"
                                        className="flex items-center gap-4 text-sm text-gray-700 font-light px-2 py-2 rounded-lg"
                                        activeClassName="SidebarActive"
                                        style={{fontWeight: 500}}
                                    >
                                    <BiDollar size="25px" />
                                        <span className="SidebarName">Transaction</span>
                                    </NavLink>
                                </li>
                            </AccessControl>
                            <AccessControl 
                                role={"STUDENT"} 
                                isProfileComplete={true}>
                                <li className="rounded-lg mb-2">
                                    <NavLink
                                        to="/dashboard/testimoni"
                                        className="flex items-center gap-4 text-sm text-gray-700 font-light px-2 py-2 rounded-lg"
                                        activeClassName="SidebarActive"
                                        style={{fontWeight: 500}}
                                    >
                                    <BsChat size="25px" />
                                        <span className="SidebarName">Testimoni</span>
                                    </NavLink>
                                </li>
                            </AccessControl>
                            {/* <li className="rounded-lg mb-2">
                                <NavLink
                                    to="/dashboard/user/resi"
                                    className="flex items-center gap-4 text-sm text-gray-700 font-light px-2 py-2 rounded-lg"
                                    activeClassName="bg-gradient-to-tr from-light-blue-500 to-light-blue-700 text-white shadow-md"
                                >
                                  <BsFileEarmarkText size="25px" />
                                    Resi
                                </NavLink>
                            </li> */}
                        </ul>
                    </div>
                </div>
            </div>
        </>
    );
}
