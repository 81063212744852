import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FormGroup, FormControl, Form } from 'react-bootstrap';
import { SideBar } from '../../../components/layout/dashboard';
import Carousel from 'react-bootstrap/Carousel';
import { useLocation } from 'react-router';
import { contactLink } from '../../../helpers/utils'

export default function AksesMateri() {
    document.title = "INGENIO Indonesia";




    const location = useLocation();
    const { nama_program, nama_regio, nama_kelas, materi_contents, kelas_jadwal_materies } = location.state

    const dropdown = {
        borderRadius: "10px",
        height: 50,
        padding: "25px",
    };
    const [index, setIndex] = useState(0);
    const [materiKe, setMateriKe] = useState(0);

    const handleSelect = (selectedIndex, e) => {
        setIndex(selectedIndex);
    };

    return (
        <React.Fragment>
            <SideBar />
            <div className="md:ml-64" style={{ backgroundColor: '#F3F4F6' }}>
                {/* <div className="bg-orange px-3 md:px-8 py-1 h-40">
                    <div className="container md:pr-8 md:pl-10">
                        <h3 className="text-left tracking-wider title-dashboard">Selamat datang di Kelas Bimbingan Intensif Ingenio Indonesia</h3>
                        <br />
                        <h3 className="text-left tracking-wider">Anda dapat menghubungi Admin terlebih dahulu untuk memastikan kelas dan program yang Anda daftarkan. <br /> <br />Kontak Admin <a href={contactLink()} target={'_blank'}> <i class="fa fa-whatsapp" aria-hidden="true"></i></a></h3>
                    </div>
                </div> */}

                <div className="container md:pr-8 md:pl-10"  style={{position: 'absolute', top: '80px'}}>
                    <h3 className="text-left tracking-wider title-dashboard">Selamat datang di Kelas Bimbingan Intensif Ingenio Indonesia</h3>
                    <br />
                    <h3 className="text-left tracking-wider">Anda dapat menghubungi Admin terlebih dahulu untuk memastikan kelas dan program yang Anda daftarkan. <br /> <br />Kontak Admin <a href={contactLink()} target={'_blank'}> <i class="fa fa-whatsapp" aria-hidden="true"></i></a></h3>
                </div> 

                <div className="px-3 md:px-8 mt-4">
                    <div className="container mx-auto max-w-full pb-4">
                        <div className="row">
                            <div className="col-md-6 col-lg-6 col-xs-12 mb-4">
                                <h2 className="text-left tracking-wider text-muted">Nama Kelas  /  Daftar Materi Kelas 1 / <strong>Akses Materi Kelas (Teori)</strong></h2>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-4 col-lg-4 col-xs-12 mb-4">
                                <table style={{marginBottom: '15px'}}>
                                    <tr>
                                        <td style={{padding: '0px 10px 0px 0px'}}>Regio</td>
                                        <td style={{padding: '0px 10px 0px 0px'}}>:</td>
                                        <td style={{padding: '0px'}}><b>{nama_regio}</b></td>
                                    </tr>
                                    <tr>
                                        <td style={{padding: '0px'}}>Program</td>
                                        <td style={{padding: '0px'}}>:</td>
                                        <td style={{padding: '0px'}}><b>{nama_program}</b></td>
                                    </tr>
                                    <tr>
                                        <td style={{padding: '0px'}}>Kelas</td>
                                        <td style={{padding: '0px'}}>:</td>
                                        <td style={{padding: '0px'}}><b>{nama_kelas}</b></td>
                                    </tr>
                                </table>
                                {/* <h2 className="text-left tracking-wider">Regio : <strong>{nama_regio}</strong></h2><br />
                                <h2 className="text-left tracking-wider">Program : <strong>{nama_program}</strong></h2><br />
                                <h2 className="text-left tracking-wider">Kelas : <strong>{nama_kelas}</strong></h2> */}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12 col-lg-12 col-xs-12 mt-3">
                                <div className="col-sm-12 col-md-4 ml-0 mb-3">
                                    <FormGroup>
                                        <Form.Label htmlFor="universitas" className="form-label"><b>Pilih Materi</b></Form.Label><br/>
                                        <Form.Select
                                            as="select"
                                            aria-label="Universitas"
                                            aria-describedby="basic-addon1"
                                            onChange={(e) => {
                                                if(e.target.value === "") return;
                                                setMateriKe(e.target.value)
                                                setIndex(0)
                                            }}
                                            value={materiKe}
                                            style={dropdown}
                                        >
                                            <option value="">-- Pilih Materi --</option>
                                            {
                                                kelas_jadwal_materies && kelas_jadwal_materies !== null ?
                                                kelas_jadwal_materies.map((item, o) => {
                                                    if(item.materi.materi_contents.filter(x => x.content_type === 'docs').length < 1) {
                                                        return null
                                                    }
                                                    return (
                                                        <option value={o}>{item.materi.nama_materi}</option>
                                                    )
                                                })
                                                :
                                                null
                                            }

                                        </Form.Select>
                                    </FormGroup>
                                </div>
                                <Carousel activeIndex={index} onSelect={handleSelect}>
                                    {
                                        kelas_jadwal_materies && kelas_jadwal_materies !== null ?
                                        kelas_jadwal_materies[materiKe].materi.materi_contents.filter((content) => {
                                            return content.content_type === 'docs'
                                        }).map((item, i) => {
                                            return (
                                                <Carousel.Item key={i}>
                                                    <img
                                                        className="d-block w-100"
                                                        src={item.content_url}
                                                        alt=""
                                                    />
                                                    {/* <Carousel.Caption>
                                                        <h3>First slide label</h3>
                                                        <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
                                                    </Carousel.Caption> */}
                                                </Carousel.Item>
                                            )
                                        })
                                        :
                                        null
                                    }
                                    
                                </Carousel>
                            </div>
                            {/* <div className="col-md-4 col-lg-4 col-xs-12 mt-3">
                                <div className="card-gede">
                                    <div className="px-4 py-4" style={{borderBottom: "1px solid #BDC1C9"}}>
                                        <p style={{fontSize: "16px", fontWeight: "bold"}}>Materi</p>
                                    </div>
                                    <div className="px-4 py-4">
                                        <Link><u>- Judul Materi 1 Sub Materi A</u></Link> <br />
                                        <Link><u>- Judul Materi 1 Sub Materi B</u></Link> <br />
                                        <Link><u>- Judul Materi 2 Sub Materi Lorem Ipsum</u></Link>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>

            </div>
        </React.Fragment >

    )
}