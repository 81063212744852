import { NavLink, useLocation, Link } from 'react-router-dom';
import React, { useEffect } from 'react';
import { BsChevronLeft } from "react-icons/bs";

export default function SoalEditorNav(props) {

  var baseUrl = window.location.origin;
  return (
    <nav className="bg-orange py-2 px-3">
      <div className="container max-w-full mx-auto flex items-center justify-between md:pr-8 md:pl-10">
        <div className="flex justify-between items-center w-full">
          <NavLink
            to={"/dashboard/akademik/tryout/" + props.tryoutId}
            className="flex items-center gap-4 text-md text-black font-bold px-2 py-2 rounded-lg"
            style={{ fontFamily: 'Quicksand' }}
          >
            <BsChevronLeft style={{ stroke: "black", strokeWidth: "1" }} />
            Kembali
          </NavLink>


          <div className="flex">
            <Link
              class="false flex items-center justify-center gap-1 font-bold outline-none tracking-wider focus:outline-none focus:shadow-none transition-all duration-300 rounded-lg py-1.5 px-3 text-sm leading-normal bg-white border border-solid shadow-none text-black border-black hover:bg-white hover:border-black hover:text-black hover:bg-white active:bg-white undefined mr-2"
              style={{ position: 'relative', overflow: 'hidden', fontFamily: 'Quicksand' }}
              to={"/dashboard/akademik/tryout/" + props.tryoutId + "/soal-editor/" + props.subtryoutId + "/review"}
            >
              Review
            </Link>
            {/* <Link
              class="false flex items-center justify-center gap-1 font-bold outline-none tracking-wider focus:outline-none focus:shadow-none transition-all duration-300 rounded-lg py-1.5 px-3 text-sm leading-normal bg-white border border-solid shadow-none text-black border-black hover:bg-white hover:border-black hover:text-black hover:bg-white active:bg-white undefined mr-2"
              style={{ position: 'relative', overflow: 'hidden', fontFamily: 'Quicksand' }}
              to={"/dashboard/akademik/tryout-list"}
            >
              Publish
            </Link> */}
          </div>
        </div>
      </div>
    </nav>
  );
}
