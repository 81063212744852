import React, { useState } from 'react';

export default function ProgramDokterMuda() {

    return (
        <React.Fragment>
            <section className="container-fluid">
                <div className="site-header site-header--menu-left landing-11-menu site-header--absolute" style={{ backgroundPositionY: 'bottom', backgroundPositionX: 'right', backgroundRepeat: 'no-repeat', paddingTop: '6rem', backgroundColor: '#1E7EC0' }}>
                    <div className="py-5" style={{ paddingBottom: '2rem !important' }}>
                        <h5 className="sub-title text-center">Kelas Bimbingan</h5>
                        <h5 className="tentang_title g-title">Dokter Muda dan Dokter Gigi Muda</h5>

                    </div>
                </div>
            </section>
            <section className="container-fluid bg-desktop">
                <div className="container px-4" style={{ paddingTop: '10rem', width: '85%', paddingBottom: '5rem' }}>
                    <div className="row g-2" style={{ textAlign: 'left', paddingTop: '12.5rem' }}>
                        <div className="col-lg-6 col-md-12">
                            <img src="/assets/landing/doktermuda-1.png" />
                        </div>
                        <div className="col-lg-6 col-md-12 mt5r">
                            <h5 className="tdt36px balooc2 prl5r" style={{ textDecoration: 'none', lineHeight: '50px' }}>Bimbingan Dokter Muda</h5>
                            <h5 className="txt20px mt1r prl5r" style={{ marginBottom: '1rem', lineHeight: '30px' }}>Merupakan program bimbingan di Ingenio yang mempersiapkan mahasiswa tahap klinik/coass/dokter muda Kedokteran untuk menghadapi ujian stase dan memahami materi stase yang dihadapi secara daring (online) secara menyenangkan dan komprehensif
                            </h5>
                        </div>
                    </div>
                    <h5 className="txt24px mt1r" style={{ marginBottom: '0.2rem', lineHeight: '30px' }}>Dengan berbagai fasilitas penunjang yaitu:</h5>
                    <h5 className="txt20px mt1r" style={{ marginBottom: '1rem', lineHeight: '30px' }}>a.	Materi disesuaikan dengan logbook dan pencapaian indikator kepaniteraan masing-masing kampus<br />
                        b.	Metode pembelajaran dengan penanaman pola pikir logis dan sederhana, serta materi untuk dokter muda<br />
                        c.	Kelas sistematis,  interaktif via Zoom, dan dapat diikuti oleh peserta FK di seluruh Indonesia<br />
                        d.	Pilihan bimbingan per stase<br />
                        e.	Proses belajar intensif hingga akhir stase<br />
                        f.	Jadwal bimbingan yang dapat disepakati bersama dengan tim akademik<br />
                        g.	Diampu oleh tutor terbaik yang telah mengikuti standarisasi serta penjaminan kualitas Ingenio Indonesia<br />
                        h.	Konsultasi tutor 7x 24 jam selama masa bimbingan<br />
                        i.	Latihan soal intensif serta simulasi ujian stase
                    </h5>
                    <a href="https://drive.google.com/file/d/1EBJQGWskHQPaEV-UqSB0BiquEN7k0cQS/view?usp=sharing" className="txt20px" style={{ color: '#FCA61F', textDecoration: 'underline' }}>Download Brosur Bimbingan Dokter Muda
                    </a>
                </div>
            </section>
        </React.Fragment >
    )
}

