import React from 'react';
import { HalPrestasiSlider } from './index';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";

export default function HalPrestasi() {
    let baseURL = window.location.origin;
    const Column = {
        borderRadius: "10px",
        width: 250,
        height: 50,
        padding: "25px",
    };
    return (
        <React.Fragment>
            <section className="container-fluid" style={{ backgroundImage: 'url(/assets/landing/bg-login.png)', backgroundSize: '100%', backgroundRepeat: 'no-repeat' }}>
                <div className="container px-4 py-5">
                    <div className="row align-items-center g-2 py-5">
                        <h5 className="jd-home2 title-big" style={{ fontSize: '36pt' }}> Penghargaan Peserta</h5>
                    </div>
                    <div className="row align-items-center" style={{ marginTop: '-2rem' }}>
                        <HalPrestasiSlider
                            interval={5000}
                            images={[
                                './assets/landing/prestasi1.png',
                                './assets/landing/prestasi2.png',
                                './assets/landing/prestasi3.png',
                                './assets/landing/prestasi4.png',
                            ]}
                        />
                    </div>

                    {/* <div className="row align-items-center">
                        <div className="col-12 col-md-8 mx-auto pt-3">
                            <button className="btn-login">Kembali &nbsp; <FontAwesomeIcon icon={faChevronRight} /><FontAwesomeIcon icon={faChevronRight} /></button>
                        </div>
                    </div> */}
                </div>
            </section>
        </React.Fragment>
    )
}

