import React, { useState, useRef } from 'react';
// import styles from '../../index.module.css';
import SwiperCore, { Navigation, Thumbs, Pagination, Scrollbar, A11y } from 'swiper';
import { FaChevronRight, FaChevronLeft } from 'react-icons/fa'

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import 'swiper/components/scrollbar/scrollbar.scss';

import { UKMPPDCard } from './index';

SwiperCore.use([Navigation, Thumbs, Pagination, Scrollbar, A11y ]);

export default function UKMPPDSlider(props) {
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const navigationPrevRef = React.useRef(null)
  const navigationNextRef = React.useRef(null)

  return (
    <React.Fragment>
      <Swiper
        modules={[Thumbs]}
        thumbs={{ swiper: thumbsSwiper }}
        id="main"
        spaceBetween={200}
        slidesPerView={1}
        navigation={{
          prevEl: '.ukmppdslider_button-prev',
          nextEl: '.ukmppdslider_button-next',
        }}
        beforeInit={(swiper) => {
          swiper.params.navigation.prevEl= navigationPrevRef.current
          swiper.params.navigation.nextEl= navigationNextRef.current
          console.log(navigationNextRef)
        }}
      >
        <SwiperSlide>
          <UKMPPDCard />
        </SwiperSlide>
        <SwiperSlide>
          <UKMPPDCard />
        </SwiperSlide>
        <SwiperSlide>
          <UKMPPDCard />
        </SwiperSlide>
        <SwiperSlide>
          <UKMPPDCard />
        </SwiperSlide>
        <SwiperSlide>
          <UKMPPDCard />
        </SwiperSlide>
        <button className="btn ukmppdslider_button-prev" ref={navigationPrevRef}><FaChevronLeft size="20px" color="black"/></button>
        <button className="btn ukmppdslider_button-next" ref={navigationNextRef}><FaChevronRight size="20px" color="black"/></button>
      </Swiper>
      <div className="col-12 col-md-8 col-lg-6 mx-auto my-4 justify-content-center ukmppdthumbs">
        <Swiper onSwiper={setThumbsSwiper} spaceBetween={10} slidesPerView={5} freeMode={true} watchSlidesProgress={true} className="ukmppdthumbs-container">
          <SwiperSlide>
            <img src="/assets/UKMPPD/image 25.png" className="img-fluid" alt="testimoni peserta"/>
          </SwiperSlide>
          <SwiperSlide>
            <img src="/assets/UKMPPD/image 26.png" className="img-fluid" alt="testimoni peserta"/>
          </SwiperSlide>
          <SwiperSlide>
            <img src="/assets/UKMPPD/image 27.png" className="img-fluid" alt="testimoni peserta"/>
          </SwiperSlide>
          <SwiperSlide>
            <img src="/assets/UKMPPD/image 28.png" className="img-fluid" alt="testimoni peserta"/>
          </SwiperSlide>
          <SwiperSlide>
            <img src="/assets/UKMPPD/image 25.png" className="img-fluid" alt="testimoni peserta"/>
          </SwiperSlide>
        </Swiper>
      </div>
    </React.Fragment>
  )
}