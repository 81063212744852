import Button from '@material-tailwind/react/Button';
import React, { useState, useEffect } from 'react';
import { FormGroup, FormControl, Form } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import { Link } from 'react-router-dom';
import { BsPencilSquare, BsFillTrashFill } from 'react-icons/bs';
import { HiOutlineTrash, HiOutlinePencilAlt  } from 'react-icons/hi';
import { addCommas } from '../../../../helpers/utils'
import AkademikSidebar from '../../../../components/layout/dashboard/akademik/AkademikSideBar';
import EcourseService from '../../../../services/ecourse';
import CustomDatatable from '../../../../components/common/CustomDatatable';
import moment from 'moment-timezone';
import Swal from 'sweetalert2';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';


export default function SemuaEcourse() {
    var baseUrl = window.location.origin;

    document.title = "INGENIO Indonesia";
    const [program, setProgram] = useState('');

    // Filtering data
    const [isLoading, setIsLoading] = useState(true);
    const [hasNext, setHasNext] = useState(false)
    const [hasPrev, setHasPrev] = useState(false)
    const [count, setCount] = useState(0);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [keyword, setKeyword] = useState('');
    const [kelasData, setKelasData] = useState([]);

    const columns = [
        {
            name: 'NO.',
            field: 'no',
            index: true,
            selector: row => row.no,
            sortable: true,
            minWidth: '1px'
            
        },
        {
            name: 'KODE E-COURSE',
            field: 'kode_ecourse',
            selector: row => row.kelas,
            sortable: true,
            
        },
        {
            name: 'NAMA E-COURSE',
            field: 'name',
            selector: row => row.regio,
            sortable: true,
             
        },
        {
            name: 'HARGA',
            field: 'kapasitas',
            render: true,
            component: (row) => {
                return (
                    <td className='main-table__bodyRow'>{`Rp ${addCommas(row.price)}`}</td>
                )
            },
            selector: row => row.kapasitas,
            sortable: true,
            
        },
        {
            name: 'Aksi',
            render: true,
            component: (row) => {
                return (
                    <td className='main-table__bodyRow'>
                        <div style={{display: 'flex', flexDirection: 'row'}}>
                            <OverlayTrigger placement="top" overlay={<Tooltip id="button-tooltip-2">Edit</Tooltip>}>
                              <Link to={{
                                    pathname: `/dashboard/akademik/e-course/olah`,
                                    state: {
                                        id_ecourse: row.id,
                                        isUpdate: true, 
                                        kode_ecourse: row.kode_ecourse, 
                                        name: row.name, 
                                        price: row.price, 
                                        desc: row.desc, 
                                        url_thumbnail: row.url_thumbnail
                                    }
                                }} className="btnCircleBlack"><HiOutlinePencilAlt size="22"/></Link>
                            </OverlayTrigger>
                            <Button onClick={() => {
                                EditStatusEcourse(row.id, {
                                    kode_ecourse: row.kode_ecourse,
                                    name: row.name,
                                    price: row.price,
                                    desc: row.desc,
                                    is_active: row.is_active ? false : true
                                })
                            }} className="justify-center mx-3" style={{padding: '6px', background: '#F8CA2B', width: '110px' }}>
                                <span style={{color: 'black', fontSize: '14px'}}>{row.is_active ? 'Inactivate' : 'Activate'}</span> 
                            </Button>
                        </div>
                    </td>
                )
            },
            selector: row => row.tanggal,
            sortable: true,
            // width: '15vw',
        }
    ];

    const dropdown = {
        borderRadius: "10px",
        height: 50,
        padding: "25px",
    };
    // const columns = [
    //     {
    //         name: 'NO.',
    //         selector: row => row.no,
    //         sortable: true,
    //         width: '6.2vw',
    //     },
    //     {
    //         name: 'Kode',
    //         selector: row => row.kode,
    //         sortable: true,
    //         width: '16vw',
    //     },
    //     {
    //         name: 'NAMA E-Course',
    //         selector: row => row.ecourse,
    //         sortable: true,
    //         width: '25vw',
    //     },
    //     {
    //         name: 'Harga',
    //         selector: row => row.harga,
    //         sortable: true,
    //         width: '15vw',
    //     },
    //     {
    //         name: 'Aksi',
    //         cell: () => <OverlayTrigger placement="top" overlay={<Tooltip id="button-tooltip-2">Edit</Tooltip>}><Link className="btnCircleBlack" to="/dashboard/akademik/e-course/olah"><HiOutlinePencilAlt size="22"/></Link></OverlayTrigger>,
    //         ignoreRowClick: true,
    //         allowOverflow: true,
    //         button: true,
    //         width: '3vw',
    //     },
    //     {
    //         cell: () => <OverlayTrigger placement="top" overlay={<Tooltip id="button-tooltip-2">Delete</Tooltip>}><Link className="btnCircleBlack" onClick={AreYouSure}><HiOutlineTrash size="22" /></Link></OverlayTrigger>,
    //         ignoreRowClick: true,
    //         allowOverflow: true,
    //         button: true,
    //         width: '5vw',
    //     },
    // ];
    const data = [
        {
            no: 1,
            kode: 'EC123456.',
            ecourse: 'Lorem ipsum.',
            harga: 'Rp. 100.000'
        },
        {
            no: 2,
            kode: 'EC123456.',
            ecourse: 'Lorem ipsum.',
            harga: 'Rp. 120.000'
        },
        {
            no: 3,
            kode: 'EC123456.',
            ecourse: 'Lorem ipsum.',
            harga: 'Rp. 80.000'
        },
    ];

    const customStyles = {
        table: {
          style: {
            borderRadius: '15px',
            border: '1px solid #BDC1C9',
          },
        },
        head: {
          style: {
            fontSize: '1.25rem',
            textTransform: "uppercase",
            color: '#6B7280',
            backgroundColor: '#f3f4f6',
            border: '1px solid #BDC1C9',
            borderTopLeftRadius: '15px',
            borderTopRightRadius: '15px',
          },
        },
        pagination: {
          style: {
            color: '#6B7280',
            fontSize: '1.1rem',
            minHeight: '56px',
            backgroundColor: '#f3f4f6',
            border: '1.25px solid #BDC1C9',
            borderBottomLeftRadius: '15px',
            borderBottomRightRadius: '15px',
          },
        },
    };

    const AreYouSure = () => {
        Swal.fire({  
            title: 'Hapus Data ?',
            icon: 'warning',  
            showCancelButton: true,  
            cancelButtonText: 'Tidak',  
            confirmButtonText: 'Ya'  
        }).then((result) => {
            if (result.isConfirmed) {
              window.location.reload();
            }
        })
    }

    const EditStatusEcourse = (id, body) => {
        setIsLoading(true)
        EcourseService.updateEcourseAkamdeik(id, body).then(resp => {
            getListKelas()
            setIsLoading(false)
        }).catch(err => {
            setIsLoading(false)
            let msg = 'Internal Server Error'
            console.error(err)
            if(err.response) {
                if(err.response.data.message) {
                msg = err.response.data.message
                }
            }

            Fail(msg)
        }) 
      }  

    const getListKelas = (params = '') => {
        setIsLoading(true)
        EcourseService.getListEcourseAkademik(params).then(resp => {
            const response = resp.data;
            setKelasData(response.data);

            setPage(response.meta.page)
            setPerPage(response.meta.perPage)
            setCount(response.meta.count)

            setHasNext(response.meta.hasNext)
            setHasPrev(response.meta.hasPrev);
            
            setIsLoading(false)
        }).catch(err => {
            let msg = 'Internal Server Error'
              setIsLoading(false)
              console.error(err)
              if(err.response) {
                if(err.response.data.message) {
                  msg = err.response.data.message
                }
              }
  
              Fail(msg)
        })
    }

    const Fail = (message) => {
        Swal.fire({  
            title: 'Gagal!',  
            text: message,  
            icon: 'warning',  
            showCancelButton: true,  
            cancelButtonText: 'Kembali',           
            showConfirmButton: false,  
        });   
    }

    useEffect(() => {
        getListKelas();
    }, [])

    return (
        <React.Fragment>
            <AkademikSidebar />
            <div className="md:ml-64 tutorDataKelas" style={{ backgroundColor: '#F3F4F6' }}>
                <div className="bg-orange md:px-8 pb-5" style={{height: '20vh', position: 'relative', padding: '0 30px 30px 30px'}}>
                    <h1 className="tracking-wider title-24-600">E-Course</h1>
                    <h2 className="tracking-wider title-20-700 mt-5">Daftar Semua E-Course</h2>
                    <img src={baseUrl + "/assets/logo/evaluasikelas.png"} className="img-opacity"></img>
                </div>

                <div className="px-3 md:px-8 mt-4">
                    <div className="container mx-auto max-w-full">
                        <div className="row">
                            <div className="col-12 mt-3 mb-4">
                                <FormGroup>
                                    <div className="SearchTable2 gap-3">
                                        <input value={keyword} onChange={(e) => setKeyword(e.target.value)} type="text" name="" className="inputSearchTable" placeholder="&#x1F50E;&#xFE0E; Search..." id="" />
                                        <Button onClick={() => {
                                                getListKelas(`page=${page}&perPage=${perPage}&keyword=${keyword}`)
                                            }} className="btnSearchTable">Cari</Button>
                                    </div>
                                </FormGroup>
                            </div>
                        </div>
                        <div className="col-md-12 col-lg-12 col-xs-12 mt-3 mb-5">
                            {/* <DataTable columns={columns} customStyles={customStyles} data={data} pagination /> */}
                            <CustomDatatable 
                                filteredItems={kelasData} 
                                columns={columns}
                                isLoading={isLoading}
                                page={page}
                                perPage={perPage}
                                hasPrev={hasPrev}
                                hasNext={hasNext}
                                count={count}
                                onTableChange={(e, data) => {
                                    switch (e) {
                                    case 'rowPerPageChange':
                                        setPerPage(data.perPage)
                                        getListKelas(`page=${page}&perPage=${data.perPage}&keyword=${keyword}`)
                                        break;
                                    case 'rowPageChange':
                                        setPage(data.page)
                                        getListKelas(`page=${data.page}&perPage=${perPage}&keyword=${keyword}`)
                                        break;
                                    
                                    default:
                                        break;
                                    }
                                }}
                            />
                        </div>
                    </div>
                </div>

            </div>
        </React.Fragment >

    )
}