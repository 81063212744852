import React, { useState, useEffect } from 'react';
import AkademikSidebar from '../../../../components/layout/dashboard/akademik/AkademikSideBar';
import CustomDatatable from '../../../../components/common/CustomDatatable'
import AkademikNav from '../../../../components/layout/dashboard/akademik/AkademikNav';
import { FormGroup, FormControl, Form } from 'react-bootstrap';
import { Table, Pagination, PaginationItem, PaginationLink } from 'reactstrap'
import DataTable from 'react-data-table-component';
import { Link } from 'react-router-dom';
import Button from '@restart/ui/esm/Button';
import { FaRegEdit, FaRegTrashAlt } from 'react-icons/fa';
import InputIcon from '@material-tailwind/react/InputIcon';
import Swal from 'sweetalert2';

import { connect } from "react-redux";
import { useHistory, useLocation, useParams, Redirect } from "react-router-dom";
import { deleteTryout, getAllProgram, getAllTryout } from '../../../../store/actions/tryout';
import moment from 'moment-timezone';
import './Styles/style.css'

function TryOut(props) {
  document.title = "INGENIO Indonesia | Dashboard Akademik - Tryout List";
  document.body.style.backgroundColor = "#f3f4f6";
  const [programs, setPrograms] = useState([]);
  const [dataTryout, setDataTryout] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  // FILTERING DATA
  const [hasNext, setHasNext] = useState(false)
  const [hasPrev, setHasPrev] = useState(false)
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [keyword, setKeyword] = useState('');
  const [program, setProgram] = useState('');
  const [selectedProgram, setSelectedProgram] = React.useState(null)
  const [filterText, setFilterText] = React.useState('');
  const [filterObj, setFilterObj] = React.useState({
    text: '',
    selectedProgram: ''
  })
  const onFilter = (data) => {
    setKeyword(data)
  }
  const onFilterSubmit = (e) => {
    e.preventDefault();
    getAllTryoutApi(`page=${page}&perPage=${perPage}&keyword=${keyword}&program=${program}`)
  }

  const filteredItems = dataTryout.filter(
		item => (item.paket && item.paket.toLowerCase().includes(filterObj.text.toLowerCase())) && (!filterObj.program || item.program.includes(filterObj.program)),
	);
  // END FILTERING DATA
  const { dispatch } = props;

  useEffect(() => {
    getAllTryoutApi(`page=${page}&perPage=${perPage}&keyword=${keyword}&program=${program}`)
    getAllProgramApi()
  }, []);

  const toggleDelete = (id) => {
    Swal.fire({
      title: 'Anda Yakin?',
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'Kembali',
      confirmButtonText: 'Ya, Hapus!'
    })
      .then((result) => {
        if (result.isConfirmed) {
          dispatch(deleteTryout(id))
            .then((response) => {
              getAllTryoutApi()
              // window.location.reload();
            })
            .catch((err) => {
              console.log(err);
            })
        }
      });

  }

  const getAllProgramApi = () => {
    setIsLoading(true)
    dispatch(getAllProgram())
      .then((response) => {
        let obj = { ...response.data };
        let count = 1;
        let programArr = [];
        for (const key in obj) {
          console.log(obj[key]);
          let payload = {
            key: count,
            id: obj[key].id,
            nama: obj[key].nama,
          };
          programArr.push(payload)
          count++
        }
        setPrograms(programArr);
        setIsLoading(false)
      });
  }

  const getAllTryoutApi = (params = '') => {
    setIsLoading(true)
    dispatch(getAllTryout(params))
    .then((response) => {
      console.log(response);
      let obj = { ...response.data };
      let tryoutArr = [];
      let count = 1;
      for (const key in obj) {
        let jumlahSoal = 0;
        if (obj[key].subtryout.length > 0) {
          for (let i = 0; i < obj[key].subtryout.length; i++) {
            jumlahSoal += obj[key].subtryout[i].jumlah_soal;
          }
        }
        let payload = {
          no: count,
          id: obj[key].id,
          paket: obj[key].nama_tryout,
          kode: obj[key].kode_tryout,
          id_program: obj[key].id_program,
          program: obj[key].program.nama,
          tanggal_awal: obj[key].tanggal_awal,
          tanggal_akhir: obj[key].tanggal_akhir,
          jumlah: jumlahSoal.toString()
        };
        tryoutArr.push(payload)
        count++
      }
      setDataTryout(tryoutArr);

      setPage(response.meta.page)
      setPerPage(response.meta.perPage)
      setCount(response.meta.count)

      setHasNext(response.meta.hasNext)
      setHasPrev(response.meta.hasPrev);
      
      setIsLoading(false)
    });
  }

  const dropdown = {
    borderRadius: "10px",
    height: 50,
    padding: "25px",
  };
  const columns = [
    {
      name: 'No.',
      field: 'no',
      selector: row => row.no,
      index: true,
      sortable: false,
      minWidth: '1px',
    },
    {
      name: 'Nama TryOut',
      field: 'paket',
      selector: row => row.paket,
      sortable: true,
      minWidth: '257px',
      wrap: 'yes'
    },
    {
      name: 'Kode',
      field: 'kode',
      selector: row => row.kode,
      sortable: true,
      minWidth: '171px',
      wrap: 'yes'
    },
    {
      name: 'Program',
      field: 'program',
      selector: row => row.program,
      width: '10rem',
      sortable: true,
      wrap: 'yes'
    },
    {
      name: 'Tanggal Mulai',
      field: 'tanggal_awal',
      render: true,
      component: (row) => {
        return (
          <td className='main-table__bodyRow'>{moment(row.tanggal_awal).format("DD MMM YYYY HH:mm")}</td>
        )
      },
      selector: row => moment(row.tanggal_awal).tz("Asia/Jakarta").format("DD MM YYYY HH:mm"),
      sortable: true,
      wrap: 'no',
      minWidth: '235px',
    },
    {
      name: 'Tanggal Berakhir',
      field: 'tanggal_akhir',
      render: true,
      component: (row) => {
        return (
          <td className='main-table__bodyRow'>{moment(row.tanggal_akhir).format("DD MMM YYYY HH:mm")}</td>
        )
      },
      selector: row => moment(row.tanggal_akhir).tz("Asia/Jakarta").format("DD MM YYYY HH:mm"),
      sortable: true,
      wrap: 'no',
      minWidth: '235px',
    },
    {
      name: 'Jumlah Soal',
      field: 'jumlah',
      render: true,
      component: (row) => {
        return (
          <td className='main-table__bodyRow'>{row.jumlah + ' Soal'}</td>
        )
      },
      selector: row => row.jumlah,
      sortable: true,
      minWidth: '171px',
      wrap: 'yes'
    },
    {
      name: '',
      render: true,
      component: (row) => {
        return (
          <td className='main-table__bodyRow'>
            <Link
              className="flex content-center items-center"
              style={{width: '35px', borderRadius: '50%', padding: '10px', color: 'black', background: '#F8CA2B' }}
              to={'/dashboard/akademik/tryout/' + row.id}>
              <FaRegEdit />
            </Link>
          </td>
        )
      },
      selector: row => row.jumlah,
      sortable: true,
      minWidth: '20px',
      wrap: 'yes'
    },
    {
      name: '',
      render: true,
      component: (row) => {
        return (
          <td className='main-table__bodyRow'>
            <Button
              className="flex content-center items-center"
              style={{width: '35px', borderRadius: '50%', padding: '10px', color: 'black', background: '#F8CA2B' }}
              onClick={() => { toggleDelete(row.id) }}
              >
              <FaRegTrashAlt />
            </Button>
          </td>
        )
      },
      selector: row => row.jumlah,
      sortable: true,
      minWidth: '20px',
      wrap: 'yes'
    }
  ];

  return (
    <React.Fragment>
      <AkademikSidebar judul={"Daftar Semua TryOut"} />
      <div className="md:ml-64">
        <div className="bg-orange px-3 md:px-8 py-1" style={{ height: '2.5rem' }}>
          <div className="container md:pr-8 md:pl-10">
          </div>
        </div>

        <div className="px-3 md:px-8 mt-5">
          <div className="container mx-auto max-w-full">
            <div className="flex-2 flex-col">
              <div className="flex-2 mb-4">
                <form onSubmit={(e) => onFilterSubmit(e)}>
                  <div className="flex flex-row">
                    <div className="bg-white px-4 py-3 mr-3" style={{ borderRadius: '20px' }}>
                      <input
                        type="text"
                        name=""
                        value={keyword}
		                  	onChange={(e) => onFilter(e.target.value)}
                        style={{ backgroundColor: '#F3F4F6', width: '25rem', borderRadius: '12px', padding: '0.75rem', border: 'none !important', paddingLeft: '10px', fontFamily: 'Quicksand' }}
                        className="mr-3"
                        placeholder="Nama Tryout atau keyword"
                        id=""
                      />
                      <select
                        as="select"
                        aria-label="Program"
                        aria-describedby="basic-addon1"
                        className="my-tryout px-3 py-2 mx-3"
                        style={{ fontFamily: 'Quicksand' }}
                        onChange={(e) => setProgram(e.target.value)}
                      >
                        <option value="">Pilih Program</option>
                        {programs.map(item =>
                          <option key={item.key} value={item.nama}>{item.nama}</option>
                        )};
                      </select>
                      <Button type="submit" className="pilih-tryout mx-3" onClick={(e) => onFilterSubmit(e)}>Cari</Button>
                    </div>
                  </div>
                </form>
              </div>
              <div className="flex-2">
                <CustomDatatable 
                  filteredItems={dataTryout} 
                  columns={columns}
                  isLoading={isLoading}
                  page={page}
                  perPage={perPage}
                  hasPrev={hasPrev}
                  hasNext={hasNext}
                  count={count}
                  onTableChange={(e, data) => {
                    switch (e) {
                      case 'rowPerPageChange':
                        setPerPage(data.perPage)
                        getAllTryoutApi(`page=${page}&perPage=${data.perPage}&keyword=${keyword}&program=${program}`)
                        break;
                      case 'rowPageChange':
                        setPage(data.page)
                        getAllTryoutApi(`page=${data.page}&perPage=${perPage}&keyword=${keyword}&program=${program}`)
                        break;
                    
                      default:
                        break;
                    }
                  }}
                />

              </div>
            </div>
          </div>
        </div>

      </div>
    </React.Fragment>

  )
}

function mapStateToProps(state) {
  const { message } = state.message;
  return {
    message
  };
}

export default connect(mapStateToProps)(TryOut);