import React, { useEffect, useState } from 'react';

import { FormGroup, FormControl, Form } from 'react-bootstrap';
import { BsPencilSquare, BsFillFileEarmarkArrowUpFill } from 'react-icons/bs';
import { useLocation } from 'react-router';
import { useParams, useHistory, Link } from "react-router-dom";
import AkademikSidebar from '../../../../components/layout/dashboard/akademik/AkademikSideBar';
import EcourseService from '../../../../services/ecourse';
import CustomDatatable from '../../../../components/common/CustomDatatable';
import moment from 'moment-timezone';
import Swal from 'sweetalert2';

export default function OlahEcourse() {
    document.title = "INGENIO Indonesia";
    var baseUrl = window.location.origin;
    document.body.style.backgroundColor = "#f3f4f6";

    const [isLoading, setIsLoading] = useState(false);

    const [idEcourse, setIdEcourse] = useState(null);
    const [kodeEcourse, setKodeEcourse] = useState('');
    const [nameEcourse, setNameEcourse] = useState('');
    const [priceEcourse, setPriceEcourse] = useState('');
    const [descEcourse, setDescEcourse] = useState('');
    const [urlThumbnail, setUrlThumbnail] = useState('');

    const [fileData, setFileData] = useState(null);

    const [bodyRequest, setBodyRequest] = useState(null);

    const history = useHistory();
    const location = useLocation();
    const { isUpdate, id_ecourse, kode_ecourse, name, price, desc, url_thumbnail } = location.state;

    const Succeed = () => {
        Swal.fire({  
            title: 'Data Berhasil Tersimpan !',  
            text: '',  
            icon: 'success',  
            showCancelButton: false,              
            confirmButtonText: 'OK'  
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.reload();
          }
        })
    }

    const AreYouSure = (id_ecourse) => {
        Swal.fire({  
            title: 'Data Berhasil Tersimpan, Upload Video ?',
            icon: 'success',  
            showCancelButton: true,  
            cancelButtonText: 'Tidak',  
            confirmButtonText: 'Ya'  
        }).then((result) => {
            if (result.isConfirmed) {
                history.push('/dashboard/akademik/e-course/kelola-video', {id_ecourse})
                
            }
        })
    }

    const AddEcourse = (body) => {

        Swal.fire({  
            title: 'Sudah Yakin?',  
            text: 'Apakah Anda Yakin Ingin Menambah Data Ini?',  
            icon: 'warning',  
            showCancelButton: true,  
            cancelButtonText: 'Tidak',  
            confirmButtonText: 'Ya'  
        }).then((result) => {
          if (result.isConfirmed) {
              setIsLoading(true)
              if((!fileData === null ||fileData === null)){
                Fail('File harus diisi')
                return;
            }
            if(fileData && fileData !== null) {
                const slideFileType = fileData.name.split('.').pop();
                if(!'png,jpg,jpeg'.split(',').includes(slideFileType.toLowerCase())){ // Slide file validation
                    Fail('File harus gambar');
                    return;
                }
            }
            let formData = new FormData();
    
            if(fileData && fileData !== null)
                formData.append('file', fileData);
            
            EcourseService.uploadFile(formData, (progressEvent) => {
    
            })
            .then(resp => {
                const response = resp.data;
                const url = response.data.url;
                setUrlThumbnail(url);
                EcourseService.addEcourseAkamdeik({
                    ...body,
                    url_thumbnail: url
                }).then(resp => {
                    AreYouSure(resp.data.data.id)
                    setIsLoading(false)
                }).catch(err => {
                  let msg = 'Internal Server Error'
                  setIsLoading(false)
                  console.error(err)
                  if(err.response) {
                    if(err.response.data.message) {
                      msg = err.response.data.message
                    }
                  }
      
                  Fail(msg)
                })
    
            }).catch(err => {
                let msg = 'Internal Server Error'
                setIsLoading(false)
                console.error(err)
                if(err.response) {
                    if(err.response.data.message) {
                    msg = err.response.data.message
                    }
                }
    
                Fail(msg)
            })
          }
        });  
      }

      const UpdateEcourse = () => {

        Swal.fire({  
            title: 'Sudah Yakin?',  
            text: 'Apakah Anda Yakin Ingin Mengubah Data Ini?',  
            icon: 'warning',  
            showCancelButton: true,  
            cancelButtonText: 'Tidak',  
            confirmButtonText: 'Ya'  
        }).then((result) => {
          if (result.isConfirmed) {
              setIsLoading(true)
            if(fileData && fileData !== null) {
                const slideFileType = fileData.name.split('.').pop();
                if(!'png,jpg,jpeg'.split(',').includes(slideFileType.toLowerCase())){ // Slide file validation
                    Fail('File harus gambar');
                    return;
                }
            }
            
            if(!fileData && fileData === null) {
                setBodyRequest({
                    kode_ecourse: kodeEcourse,
                    name: nameEcourse,
                    price: parseInt(priceEcourse),
                    desc: descEcourse,
                    is_active: true
                })
                return;
            }
    
            if(fileData && fileData !== null) {
                let formData = new FormData();
                formData.append('file', fileData);

                EcourseService.uploadFile(formData, (progressEvent) => {
    
                })
                .then(resp => {
                    const response = resp.data;
                    const url = response.data.url;
                    setUrlThumbnail(url);

                    setBodyRequest({
                        kode_ecourse: kodeEcourse,
                        name: nameEcourse,
                        price: parseInt(priceEcourse),
                        desc: descEcourse,
                    })
        
                }).catch(err => {
                    let msg = 'Internal Server Error'
                    setIsLoading(false)
                    console.error(err)
                    if(err.response) {
                        if(err.response.data.message) {
                            msg = err.response.data.message
                        }
                    }
        
                    Fail(msg)
                })
            }
            
          }
        });  
      }

      const Fail = (message) => {
        Swal.fire({  
            title: 'Gagal!',  
            text: message,  
            icon: 'warning',  
            showCancelButton: true,  
            cancelButtonText: 'Kembali',           
            showConfirmButton: false,  
        });   
    }

    useEffect(() => {
        if(bodyRequest !== null) {
            EcourseService.updateEcourseAkamdeik(idEcourse, {
                ...bodyRequest,
                url_thumbnail: urlThumbnail
            }).then(resp => {
                AreYouSure(resp.data.data.id)
                setIsLoading(false)
            }).catch(err => {
              let msg = 'Internal Server Error'
              setIsLoading(false)
              console.error(err)
              if(err.response) {
                if(err.response.data.message) {
                  msg = err.response.data.message
                }
              }
  
              Fail(msg)
            })
        }
    }, [bodyRequest])

    useEffect(() => {
        if(isUpdate === true) {
            setIdEcourse(id_ecourse);
            setKodeEcourse(kode_ecourse);
            setNameEcourse(name);
            setPriceEcourse(price);
            setDescEcourse(desc);
            setUrlThumbnail(url_thumbnail)

        }
    }, [isUpdate, id_ecourse, kode_ecourse, name, price, desc, url_thumbnail])

    return (
        <React.Fragment>
            <AkademikSidebar />
            <div className="md:ml-64">
                <div className="bg-orange px-3 md:px-8 py-1" style={{ height: '2.5rem' }}>
                </div>
                <div className="py-4 px-3 md:px-8 mt-4">
                    <div className="container mx-auto max-w-full">
                        <div className="row">
                            <div className="col-lg-8 mx-auto">
                                <div className="cards pb-4">
                                    <div className="row">
                                        <div className="col-12 flex gap-4" style={{padding: '2.4rem 4.3rem 0 4.3rem'}}>
                                            <img src={baseUrl + "/assets/img/jurnal-2.png"} className="imgDetail" />
                                            <div className="title-24-700 mt-2">Buat E-Course</div>
                                        </div>
                                        <div className="col-12 mb-4" style={{padding: '0 4.3rem'}}>
                                            <div className="borderBottomGrey"></div>
                                        </div>
                                    </div>
                                    <div className="row" style={{padding: '0 3.7rem'}}>
                                        <div className="col-12">
                                            <div className="titleForm">Kode E-Course</div>
                                            <input value={kodeEcourse} onChange={(e) => {setKodeEcourse(e.target.value)}} type="text" class="formInput" placeholder="Masukkan kode..." required/>

                                            <div className="titleForm">Nama E-Course</div>
                                            <input value={nameEcourse} onChange={(e) => {setNameEcourse(e.target.value)}} type="text" class="formInput" placeholder="Masukkan nama..." required/>

                                            <div className="titleForm">Harga</div>
                                            <input value={priceEcourse} onChange={(e) => {setPriceEcourse(e.target.value)}} type="text" class="formInput" placeholder="Rp." required/>

                                            <div className="titleForm">Deskripsi</div>
                                            <textarea value={descEcourse} onChange={(e) => {setDescEcourse(e.target.value)}} class="formInput" placeholder="Masukkan deskripsi" style={{height: '100px', padding: '15px 20px'}} />

                                            {/* <div className="title-16-500">Upload Video</div>
                                            <div className="relative mt-2">
                                                <input type="file" id="file" className="custom-file-input" />
                                                <label htmlFor="file" className="labelFile">Upload Here<BsFillFileEarmarkArrowUpFill size="18px"/></label>
                                            </div> */}

                                            <div className="title-16-500">Upload Thumbnail</div>
                                            <div className="relative mt-2">
                                                <input onChange={(e) => {
                                                    setFileData(e.target.files[0]);
                                                }} type="file" id="file2" className="custom-file-input" style={{ position: 'absolute', left: '2rem' }} />
                                                <label htmlFor="file2" className="labelFile">Upload Here<BsFillFileEarmarkArrowUpFill size="18px"/></label>
                                            </div>

                                            <div className="justify-end pb-3" style={{ marginTop: '4rem' }}>
                                                <button type="submit" onClick={() => {
                                                    if(isUpdate === true) {
                                                        UpdateEcourse()
                                                        return
                                                    }
                                                    AddEcourse({
                                                        kode_ecourse: kodeEcourse,
                                                        name: nameEcourse,
                                                        desc: descEcourse,
                                                        price: parseInt(priceEcourse),
                                                    })
                                                }} class="btnOrange3">Submit</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment >

    )
}