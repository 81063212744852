import Button from '@material-tailwind/react/Button';
import React, { useState, useEffect } from 'react';
import { FormGroup, FormControl, Form, Modal, Row, Col } from 'react-bootstrap';
import Swal from 'sweetalert2';
import { NavLink, useLocation, useHistory, useParams } from 'react-router-dom';
import { BsChevronLeft, BsChevronRight } from 'react-icons/bs';
import ElearningService from '../../../services/elearning'
import { getSubtryoutDetail } from '../../../store/actions/tryout';
import { connect } from 'react-redux';
import moment from 'moment';
import { set } from 'immutable';
import { Player } from 'video-react';

import "video-react/dist/video-react.css";

export default function MateriPreview(props) {
    document.title = "INGENIO Indonesia | Review Subtryout";
    document.body.style.backgroundColor = "#F3F4F6";
    const [isLoading, setIsLoading] = useState(false)
    const [contentState, setContentState] = useState('Slide')

    const [materiName, setMateriName] = useState('');
    const [slideContent, setSlideContent] = useState([]);
    const [videoContent, setVideoContent] = useState([]);
    const [hasPrevious, setHasPrevious] = useState(false);
    const [hasNext, setHasNext] = useState(false);
    const [totalSlide, setTotalSlide] = useState(0);
    const [currentSlide, setCurrentSlide] = useState(0);

    const { kelasjadwalid, materiid } = useParams();
    const location = useLocation();
    const { nama_kelas, jadwal, tutor } = location.state;

    const [selectedContent, setSelectedContent] = useState(null)


    const dropdown = {
        borderRadius: "10px",
        height: 50,
        padding: "25px",
    };
    
    const previewMateriContentJadwalKelas = () => {
        setIsLoading(true)
        ElearningService.previewMateriContentJadwalKelasAkademik(materiid, kelasjadwalid)
        .then(resp => {
            const response = resp.data;
            setIsLoading(false);

            setMateriName(response.data.nama_materi);
            setSlideContent(response.data.slides);
            setVideoContent(response.data.video);

            setSelectedContent(response.data.slides[currentSlide]);
            setTotalSlide(response.data.slides.length);

            if(currentSlide < 1){
                setHasPrevious(false)
            } else {
                setHasPrevious(true)
            }
    
            if(currentSlide >= response.data.slides.length-1) {
                setHasNext(false)
            } else {
                setHasNext(true)
            }

        }).catch(err => {
            setIsLoading(false)
            console.error(err)
        })
    }

    const nextSlide = () => {
        const hasNextChange = !(currentSlide >= totalSlide-1);

        if(hasNextChange){
            const currentSlideNext = currentSlide+1;
            setCurrentSlide(currentSlideNext);
            setSelectedContent(slideContent[currentSlideNext])
        } else {
            alert('Maximum')
        }
    }

    const previousSlide = () => {
        if (currentSlide > 0) {
            const currentSlideNext = currentSlide-1;
            setCurrentSlide(currentSlideNext);
            setSelectedContent(slideContent[currentSlideNext])
        }
    }

    useEffect(() => {
        if(currentSlide < 1){
            setHasPrevious(false)
        } else {
            setHasPrevious(true)
        }

        if(currentSlide >= totalSlide-1) {
            setHasNext(false)
        } else {
            setHasNext(true)
        }
    }, [currentSlide])

    useEffect(() => {
        previewMateriContentJadwalKelas()
    }, [])

    return (
        <React.Fragment>
            <div className="fill-window" style={{ backgroundColor: '#F3F4F6', }}>
                <nav className="bg-orange py-2 px-3">
                    <div className="container max-w-full mx-auto flex items-center justify-between md:pr-8 md:pl-10">
                        <div className="flex justify-between items-center w-full">
                            <NavLink
                                to={{
                                    pathname: `/dashboard/tutor/upload-materi/materi-editor/kelas-jadwal/${kelasjadwalid}`,
                                    state: {
                                        nama_kelas, 
                                        jadwal, 
                                        tutor
                                    }
                                }}
                                // to={`/dashboard/akademik/e-learning/materi-editor/kelas-jadwal/${kelasjadwalid}`}
                                className="flex items-center gap-4 text-md text-black font-bold px-2 py-2 rounded-lg"
                                style={{ fontFamily: 'Quicksand' }}
                            >
                                <BsChevronLeft style={{ stroke: "black", strokeWidth: "1" }} />
                                Kembali
                            </NavLink>
                        </div>
                    </div>
                </nav>
                <div className="container mx-auto max-w-full flex" style={{ padding: '2rem' }}>
                    <div className="flex-2 w-9/12 mx-3">
                        <div
                            className="container flex"
                        >
                            <div
                                className="flex-1 flex-grow"
                                style={{
                                    backgroundColor: '#FFF',
                                    padding: '1rem',
                                    borderRadius: '1rem',
                                    boxShadow: '0 0 8px 0 #aaa'
                                }}
                            >
                                <h3 className="text-left tracking-wider title-dashboard" style={{ fontSize: '1rem', color: '#000' }}>
                                    {`Materi: ${materiName}`}
                                </h3>
                            </div>
                        </div>
                        <FormGroup className="container flex">
                            <div
                                style={{
                                    backgroundColor: '#FFF',
                                    padding: '1rem',
                                    marginTop: '1rem',
                                    borderRadius: '1rem',
                                    boxShadow: '0 0 8px 0 #aaa'
                                }}
                                className="flex-1 flex-grow"
                            >
                                <div class="flex flex-col p-4">
                                    <div className="flex flex-row justify-between">
                                        <div class="flex">
                                            {
                                                contentState === 'Slide' ?
                                                <h3 className="text-left tracking-wider title-dashboard" style={{ fontSize: '1.5rem', color: '#6B7280', fontWeight: 700 }}>Slide {(parseInt(currentSlide) + 1)}</h3>
                                                :
                                                <h3 className="text-left tracking-wider title-dashboard" style={{ fontSize: '1.5rem', color: '#6B7280', fontWeight: 700 }}>Video</h3>
                                            }
                                            
                                        </div>
                                        {
                                            contentState === 'Slide' ?
                                            <div class="flex">
                                                {
                                                    hasPrevious ? <button
                                                        className="mr-2"
                                                        style={{ background: '#F8CA2B', fontWeight: 700, color: '#000', borderRadius: '8px', padding: '0.5rem' }}
                                                        onClick={() => {previousSlide()}}
                                                    >
                                                        <BsChevronLeft style={{ fontWeight: 'heavy', strokeWidth: "1", stroke: 'black' }} />
                                                    </button> : <button className="mr-2" disabled style={{ background: '#e5e7eb', fontWeight: 700, color: '#000', borderRadius: '8px', padding: '0.5rem' }}>
                                                        <BsChevronLeft style={{ fontWeight: 'heavy', strokeWidth: "1", stroke: 'black' }} />
                                                    </button>
                                                }
                                                <span className='mr-2'>{`${(parseInt(currentSlide) + 1)} of ${totalSlide}`}</span>
                                                {
                                                    hasNext ? <button
                                                        className="mr-2"
                                                        style={{ background: '#F8CA2B', fontWeight: 700, color: '#000', borderRadius: '8px', padding: '0.5rem' }}
                                                        onClick={() => {nextSlide()}}
                                                    >
                                                        <BsChevronRight style={{ fontWeight: 'heavy', strokeWidth: "1", stroke: 'black' }} />
                                                    </button> : <button className="mr-2" disabled style={{ background: '#e5e7eb', fontWeight: 700, color: '#000', borderRadius: '8px', padding: '0.5rem' }}>
                                                        <BsChevronRight style={{ fontWeight: 'heavy', strokeWidth: "1", stroke: 'black' }} />
                                                    </button>
                                                }
                                            </div>
                                            :
                                            <div class="flex">
                                            </div>
                                        }
                                        
                                    </div>
                                    <div className="soal my-3" style={{ fontFamily: 'Quicksand', fontSize: '1.1rem' }} dangerouslySetInnerHTML={{ __html: '' }}>
                                    </div>
                                    {
                                        contentState === 'Slide' ?
                                            selectedContent && selectedContent !== null ?
                                            <img src={selectedContent.content_url} alt={"Gambar materi"} />
                                            :
                                            null
                                        :
                                        null
                                    }
                                    {
                                        contentState === 'Video' ?
                                            videoContent.length > 0 ?
                                            <Player
                                                playsInline
                                                src={videoContent[0].content_url}
                                            />
                                            :
                                            null
                                        :
                                        null
                                    }
                                    <div class="my-2">
                                        {/* {
                                            jawaban.map((i, idx) =>
                                                <div className="mr-3" key={idx}>
                                                    <label class="inline-flex items-center">
                                                        <div className="flex">
                                                            <div className="round rounded-full py-3 mr-2">
                                                                <button
                                                                    class="false items-center justify-center gap-1 outline-none tracking-wider focus:outline-none focus:shadow-none transition-all duration-300 rounded-lg py-1.5 px-3 text-sm leading-normal border border-solid shadow-none border-black hover:border-black undefined mr-3"
                                                                    style={{ position: 'relative', overflow: 'hidden', fontFamily: 'Quicksand', backgroundColor: (selectedSoal.answer_key == i.key ? '#22C55E' : '#EF4444'), color: 'white', fontWeight: 600, width: '3rem' }}
                                                                >
                                                                    {i.key.toLowerCase()}.
                                                                </button>
                                                            </div>
                                                        </div>
                                                        <span class="ml-2">{i.jawaban}</span>
                                                    </label>
                                                </div>
                                            )
                                        } */}
                                    </div>
                                    {/* {
                                        detailSubTO.hasil_laboratorium ?
                                            <div style={{ paddingTop: '1rem' }} className="flex-1 flex-grow">
                                                <Form.Select
                                                    as="select"
                                                    aria-label="Program"
                                                    aria-describedby="basic-addon1"
                                                    className="lab flex"
                                                >
                                                    <option value="1">Lihat Nilai Normal Laboratorium</option>
                                                </Form.Select>
                                            </div> : null
                                    }
                                    {
                                        selectedSoal.gambar_pembahasan ?
                                            <div style={{ paddingTop: '1rem' }} className="flex-1 flex-grow">
                                                <h2><strong>Pembahasan: </strong></h2>
                                                <div>{selectedSoal.pembahasan}</div>
                                                <img src={selectedSoal.gambar_pembahasan} alt={"Gambar soal " + selectedNumber} style={{ maxWidth: '25rem' }} />
                                            </div> : null
                                    } */}

                                </div>

                            </div>
                        </FormGroup>
                    </div>
                    <div
                        className="box flex-1 w-3/12 mx-3 p-3"
                        style={{ borderRadius: '1rem', backgroundColor: '#FFF', boxShadow: '0 0 8px 0 #aaa' }}
                    >
                        <h3 className="text-left tracking-wider title-dashboard" style={{ fontSize: '1.2rem', color: '#000' }}>Materi Konten</h3>
                        <div className="container">
                            <div>
                                <button
                                    style={{
                                        backgroundColor: contentState === 'Slide' ? '#e5e7eb' : '#F8CA2B',
                                        fontFamily: 'Quicksand',
                                        fontStyle: 'normal',
                                        fontWeight: 'bold',
                                        fontSize: '1rem',
                                        lineHeight: '1.25rem',
                                        letterSpacing: '0.06em',
                                        color: 'black',
                                        padding: '10px',
                                        marginTop: '0.7rem',
                                    }}
                                    onClick={() => setContentState('Slide')}>
                                    Materi Slide
                                </button>
                                <button
                                    style={{
                                        backgroundColor: contentState === 'Video' ? '#e5e7eb' : '#F8CA2B',
                                        fontFamily: 'Quicksand',
                                        fontStyle: 'normal',
                                        fontWeight: 'bold',
                                        fontSize: '1rem',
                                        lineHeight: '1.25rem',
                                        letterSpacing: '0.06em',
                                        color: 'black',
                                        padding: '10px',
                                        marginTop: '0.7rem',
                                    }}
                                    onClick={() => setContentState('Video')}>
                                    Materi Video
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment >

    )
}