import React, { useState } from 'react';

export default function ProgramRocketPlus() {

    return (
        <React.Fragment>
            <section className="container-fluid">
                <div className="site-header site-header--menu-left landing-11-menu site-header--absolute" style={{ backgroundPositionY: 'bottom', backgroundPositionX: 'right', backgroundRepeat: 'no-repeat', paddingTop: '6rem', backgroundColor: '#83D0CF' }}>
                    <div className="py-5" style={{ paddingBottom: '2rem !important' }}>
                        {/* <h5 className="sub-title text-center">Kelas Bimbingan</h5> */}
                        <h5 className="tentang_title g-title">Program Rocket Plus</h5>

                    </div>
                </div>
            </section>
            <section className="container-fluid bg-desktop">
                <div className="container px-4" style={{ paddingTop: '10rem', width: '85%', paddingBottom: '5rem' }}>
                    <div className="row g-2" style={{ textAlign: 'left', paddingTop: '13.5rem' }}>
                        <div className="col-lg-6 col-md-12">
                            <img src="/assets/landing/new-rocket-plus-1.png" />
                        </div>
                        <div className="col-lg-6 col-md-12 mt5r">
                            <h5 className="tdt36px balooc2 prl5r" style={{ textDecoration: 'none', lineHeight: '50px' }}>Rocket Plus</h5>
                            <h5 className="txt20px mt1r prl5r" style={{ marginBottom: '1rem', lineHeight: '30px' }}>Merupakan program bimbingan di Ingenio yang mempersiapkan mahasiswa kebidanan menghadapi Ujian Kompetensi secara daring (online)
                            </h5>
                            <h5 className="txt20px mt1r prl5r" style={{ marginBottom: '1rem', lineHeight: '30px' }}>Ingenio memberikan pengenalan materi preklinik sejak dini dengan menanamkan pola pikir dan alur yang ringkas, yang mudah dipahami tanpa berkompromi tentang kualitas pendidikan dan mentalitas tenaga medis
                            </h5>
                        </div>
                    </div>
                    <h5 className="txt24px mt1r" style={{ textDecoration: 'none', marginBottom: '0.2rem', lineHeight: '30px' }}><b>MENGAPA HARUS IKUT ROCKET PLUS?</b></h5>
                    <h5 className="txt20px mt1r" style={{ marginBottom: '1rem', lineHeight: '30px' }}>a. Pengenalan materi dasar preklinik<br />
                        b. Tutor yang seru dan ahli dalam bidangnya<br />
                        c. Konsultasi bersama tutor selama masa bimbingan<br />
                        d. Tips dan trik survive selama studi<br />
                        e. Soft file materi<br />
                        f. E-book sebagai penunjang belajar <br />
                    </h5>
                    <h5 className="txt20px mt1r" style={{ marginBottom: '1rem', lineHeight: '30px' }}>
                    For further information<br />
                    Contact Person: <br />
                    Ingenio Indonesia  081358889123<br />

                    </h5>
                </div>
            </section>
        </React.Fragment >
    )
}

