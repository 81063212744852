import React from 'react';
import styles from '../../index.module.css';
import { TestimoniSlider } from './index';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckSquare, faStar } from "@fortawesome/free-solid-svg-icons";

export default function Testimoni() {
  let baseURL = window.location.origin;

  return(
    <React.Fragment>
      <section className="container-fluid" id="testimoni-section-container">
        <div className="container pb-2">
          <div className="row align-items-center g-2 py-1 mb-4">
            <h1 className="text-center jd-home2">Testimoni <span className={styles.textYellow}>Peserta</span></h1>
          </div>
        </div>
        <div className="container px-5">
          <div className="row align-items-center">
            <div className="col-12 col-md-8 mx-auto mb-4">
              <TestimoniSlider />
            </div>
          </div>
          <div className="row align-items-center justify-content-center text-center g-2 py-1 mt-4 mb-4">
            <a className="bt-m" href="/testimoni_peserta" style={{width: '300px', background: 'linear-gradient(248.1deg, #F8CA2B 27.1%, #FCA826 73.3%)', boxShadow: '0px 2px 10px #F8CA2B',borderRadius: '50px'}}>
                <span className="bt-link">Lihat Testimoni Lainnya</span>
            </a>
          </div>
        </div>
        <div className="container py-5">
          <div className="row align-items-center g-2 py-1">
            <h1 className="jd-home4 text-center">10 Alasan Mengapa <span className={styles.textYellow}>Harus</span> Bimbingan di <span className={styles.textYellow}>INGENIO</span></h1>
          </div>
        </div>
        <div className="container vertical-middle py-1">
          <div style={{backgroundImage: "url('" + baseURL + "/assets/landing/content.png')", backgroundSize: 'cover', borderRadius: '15px'}}>
            <div className="row pt-5 px-4">
              <div className="col-md-6 text-start">
                <ul className="fa-ul">
                 
                  <li>
                    <h4 className="mb-4" style={{fontWeight: '700', fontSize: '1.25rem'}}>
                      <FontAwesomeIcon icon={faCheckSquare} className="fa-li" />
                      Fasilitas yang Lengkap
                    </h4>
                  </li>
                  <li>
                    <h4 className="mb-4" style={{fontWeight: '700', fontSize: '1.25rem'}}>
                      <FontAwesomeIcon icon={faCheckSquare} className="fa-li" />
                      Silabus Materi Sesuai Daftar Kompetensi Nasional
                    </h4>
                  </li>
                  <li>
                    <h4 className="mb-4" style={{fontWeight: '700', fontSize: '1.25rem'}}>
                      <FontAwesomeIcon icon={faCheckSquare} className="fa-li" />
                      Tutor Pilihan Berkualitas
                    </h4>
                  </li>
                  <li>
                    <h4 className="mb-4" style={{fontWeight: '700', fontSize: '1.25rem'}}>
                      <FontAwesomeIcon icon={faCheckSquare} className="fa-li" />
                      Budget Sesuai Kualitas dan Fasilitas
                    </h4>
                  </li>
                </ul>
              </div>
              <div className="col-md-6 text-start">
                <ul className="fa-ul">
                  <li>
                    <h4 className="mb-4" style={{fontWeight: '700', fontSize: '1.25rem'}}>
                      <FontAwesomeIcon icon={faCheckSquare} className="fa-li" />
                      Mengajar dengan Pola Pikir Sistematis dan Runut
                    </h4>
                  </li>
                  <li>
                    <h4 className="mb-4" style={{fontWeight: '700', fontSize: '1.25rem'}}>
                      <FontAwesomeIcon icon={faCheckSquare} className="fa-li" />
                      Kepuasan dan Prestasi Peserta
                    </h4>
                  </li>
                  <li>
                    <h4 className="mb-4" style={{fontWeight: '700', fontSize: '1.25rem'}}>
                      <FontAwesomeIcon icon={faCheckSquare} className="fa-li" />
                      Fasilitas Try Out Online dan Kelas Online Interaktif
                    </h4>
                  </li>
                  <li>
                    <h4 className="mb-4" style={{fontWeight: '700', fontSize: '1.25rem'}}>
                      <FontAwesomeIcon icon={faCheckSquare} className="fa-li" />
                      Materi Up-To-Date
                    </h4>
                  </li>
                  <li>
                    <h4 className="mb-4" style={{fontWeight: '700', fontSize: '1.25rem'}}>
                      <FontAwesomeIcon icon={faCheckSquare} className="fa-li" />
                      Fasilitas Kelas Terbang ke Kota Peserta &nbsp;&nbsp; 
                      <FontAwesomeIcon icon={faStar}/>
                    </h4>
                  </li>
                </ul>
              </div>
            </div>
            <div className="row pt-5 px-5">
              <div class="col-12 mx-auto col-md-4 p-4 tingkat-kelulusan-card text-center">
                  <img src="/assets/landing/winner 2.png" alt="Trophy" className="mx-auto"></img>
                  <h3 className="tingkat-kelulusan-title"><b>TINGKAT KELULUSAN</b></h3>
                  <h5 className="tingkat-kelulusan-peserta mb-4">peserta bimbingan INGENIO UKMPPD</h5>
                  <h4 className="tingkat-kelulusan-program">Sepanjang 2022</h4>
                  <h2 className="tingkat-kelulusan-persen"><b>94-97%</b></h2>
              </div>
              <div class="col-12 mx-auto col-md-4 p-4 tingkat-kelulusan-card-middle text-center">
                  <img src="/assets/landing/winner 2.png" alt="Trophy" className="mx-auto"></img>
                  <h3 className="tingkat-kelulusan-title"><b>TINGKAT KELULUSAN</b></h3>
                  <h5 className="tingkat-kelulusan-peserta mb-4">peserta bimbingan INGENIO</h5>
                  <h4 className="tingkat-kelulusan-program">UKMP2DG</h4>
                  <h2 className="tingkat-kelulusan-persen"><b>97.5%</b></h2>
              </div>
              <div class="col-12 mx-auto col-md-4 p-4 tingkat-kelulusan-card text-center">
                  <img src="/assets/landing/winner 2.png" alt="Trophy" className="mx-auto"></img>
                  <h3 className="tingkat-kelulusan-title"><b>TINGKAT KELULUSAN</b></h3>
                  <h5 className="tingkat-kelulusan-peserta mb-4">UKOM KEBIDANAN</h5>
                  <h4 className="tingkat-kelulusan-program">BATCH MEI 2021</h4>
                  <h2 className="tingkat-kelulusan-persen"><b>100%</b></h2>
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  )
}

