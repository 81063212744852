import React from 'react';

export default function Disclaimer() {

    return (
        <React.Fragment>
            <section className="container-fluid">
                <div className="site-header site-header--menu-left landing-11-menu site-header--absolute" style={{ backgroundImage: 'url(/assets/landing/Genio2.png)', backgroundPositionY: 'bottom', backgroundPositionX: 'right', backgroundRepeat: 'no-repeat', paddingTop: '6rem', backgroundColor: '#ffcc00' }}>
                    <h5 className="text-center tentang_title py-5" style={{ paddingBottom: '2rem !important' }}>DISCLAIMER</h5>
                </div>
            </section>
            <section className="container-fluid bg-desktop" style={{ backgroundImage: 'url(/assets/landing/bg-value.png)' }}>
                <div className="px-4 mx-auto" style={{ paddingTop: '12rem', width: '85%' }}>
                    <div className="row g-2" style={{ textAlign: 'justify', paddingTop: '8rem' }}>
                        <p className="txt32px" style={{ paddingBottom: '0.5rem', textDecoration: 'none', fontWeight: '500', textAlign: 'left', color: '#000' }}>
                            DISCLAIMER FOR WWW.INGENIOINDONESIA.COM
                        </p>

                        <p className="ct pb-4" style={{ color: '#000000', fontWeight: 'normal' }}>
                            If you require any more information or have any questions about our site's disclaimer,
                            please feel free to contact us by email at <strong>informationtechnology@gmail.com</strong>
                        </p>

                        <p className="txt32px" style={{ paddingBottom: '0.5rem', textDecoration: 'none', fontWeight: '500', textAlign: 'left', color: '#000' }}>
                            DISCLAIMER FOR WWW.INGENIOINDONESIA.COM
                        </p>

                        <p className="ct pb-4" style={{ color: '#000000', fontWeight: 'normal' }}>
                            All the information on this website - <strong>https://www.ingenioindonesia.com</strong> - is published in good faith and for general information purpose only.
                            www.ingenioindonesia.com does not make any warranties about the completeness,
                            reliability and accuracy of this information. Any action you take upon the information you find on this website
                            (www.ingenioindonesia.com), is strictly at your own risk. www.ingenioindonesia.com will not be liable for any losses
                            and/or damages in connection with the use of our website. Our Disclaimer was generated with the help of the Disclaimer
                            Generator and the Privacy Policy Generator.
                        </p>
                        <p className="ct pb-4" style={{ color: '#000000', fontWeight: 'normal' }}>
                            From our website, you can visit other websites by following hyperlinks to such external sites.
                            While we strive to provide only quality links to useful and ethical websites,
                            we have no control over the content and nature of these sites. These links to other websites do not imply
                            a recommendation for all the content found on these sites. Site owners and content may change without notice
                            and may occur before we have the opportunity to remove a link which may have gone 'bad'.
                        </p>
                        <p className="ct pb-4" style={{ color: '#000000', fontWeight: 'normal' }}>
                            Please be also aware that when you leave our website, other sites may have different privacy policies
                            and terms which are beyond our control. Please be sure to check the Privacy Policies of these sites as
                            well as their "Terms of Service" before engaging in any business or uploading any information.
                        </p>

                        <p className="txt32px" style={{ paddingBottom: '0.5rem', textDecoration: 'none', fontWeight: '500', textAlign: 'left', color: '#000' }}>
                            CONSENT
                        </p>
                        <p className="ct pb-4" style={{ color: '#000000', fontWeight: 'normal' }}>
                            By <strong>using our website</strong>, you hereby consent to our disclaimer and agree to its terms.
                        </p>
                        <p className="txt32px" style={{ paddingBottom: '0.5rem', textDecoration: 'none', fontWeight: '500', textAlign: 'left', color: '#000' }}>
                            UPDATE
                        </p>
                        <p className="ct pb-4" style={{ color: '#000000', fontWeight: 'normal' }}>
                            Should we update, amend or make any changes to this document, those changes will be prominently posted here.
                        </p>
                    </div>
                </div>
            </section>
        </React.Fragment >
    )
}

