import React, { useState } from 'react';
import { useEffect } from 'react';
import DataTable from 'react-data-table-component';
import Button from '@restart/ui/esm/Button';
import { Link, useParams, useHistory } from 'react-router-dom';
import { SideBar } from '../../../components/layout/dashboard';
import { useLocation } from 'react-router';
import { contactLink } from '../../../helpers/utils'
import CustomDatatable from '../../../components/common/CustomDatatable';
import ElearningService from '../../../services/elearning';
import moment from 'moment-timezone';
import Swal from 'sweetalert2';

export default function KelasSayaDetails(props) {
    document.title = "INGENIO Indonesia";
    document.body.style.backgroundColor = "#f3f4f6";
    const [program, setProgram] = useState('')

    // Filtering data
    const [isLoading, setIsLoading] = useState(true);
    const [hasNext, setHasNext] = useState(false)
    const [hasPrev, setHasPrev] = useState(false)
    const [count, setCount] = useState(0);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [keyword, setKeyword] = useState('');
    const [userId, setUserId] = useState('');
    const [kelasJadwal, setKelasJadwal] = useState([])
    const [elearningPrograms, setElearningPrograms] = useState([])
    const [idElearningProgram, setIdElearningProgram] = useState('');

    const { id } = useParams();
    const history = useHistory();
    
    const location = useLocation();
    const { nama_program, nama_regio, nama_kelas } = location.state

    const dropdown = {
        borderRadius: "10px",
        height: 50,
        padding: "25px",
    };
    const jadwalValidation = (jam_awal, jam_akhir) => {
        const startTime = moment(moment(jam_awal).format('HH:mm'), 'HH:mm');
        const endTime = moment(moment(jam_akhir).format('HH:mm'), 'HH:mm');
        const currentTime = moment(moment().format('HH:mm'), 'HH:mm');

        if(!currentTime.isAfter(startTime)){
            return false;
        }

        if(!currentTime.isBefore(endTime)){
            return false;
        }

        return true
        
    }
    const renderMateriContent = (materiType, materiContents) => {
        if(materiContents && materiContents !== null) {
            if(materiContents.length > 0) {
                for (let k = 0; k < materiContents.length; k++) {
                    const materiContent = materiContents[k];
                    if(materiContent.is_active === false) {
                        continue;
                    }
                    for (let n = 0; n < materiContent.materi.materi_contents.length; n++) {
                        const content = materiContent.materi.materi_contents[n];
                        if(content.content_type === materiType) {
                            return true
                        }
                    }
                }
                // if(materiContents[0].materi.materi_contents.filter((content) => {
                //     return content.content_type === materiType
                // }).length > 0) {
                //     return true
                // }
            }
        }
        return false
        
    }
    const columns = [
        {
            name: 'NO.',
            field: 'no',
            index: true,
            selector: row => row.no,
            sortable: true,
            width: '6.2vw',
        },
        {
            name: 'NAMA MATERI',
            field: 'nama_materi',
            selector: row => row.namamateri,
            sortable: true,
            // width: '15vw',
        },
        {
            name: 'PROGRAM BIMBINGAN',
            render: true,
            component: (row) => {
                return (
                    <td className='main-table__bodyRow'>{row.kelas.elearning_program.nama_program}</td>
                )
            },
            selector: row => row.programbimbingan,
            sortable: true,
            minWidth: '170px'
            // width: '15vw',
        },
        {
            name: 'REGIO',
            render: true,
            component: (row) => {
                return (
                    <td className='main-table__bodyRow'>{row.kelas.nama_regio}</td>
                )
            },
            selector: row => row.regio,
            sortable: true,
            // width: '15vw',
        },
        {
            name: 'TANGGAL',
            render: true,
            component: (row) => {
                return (
                    <td className='main-table__bodyRow'>{moment(row.tanggal).format('DD-MMM-YYYY')}</td>
                )
            },
            selector: row => row.regio,
            sortable: true,
            // width: '15vw',
        },
        {
            name: 'JADWAL KELAS',
            render: true,
            component: (row) => {
                return (
                    <td className='main-table__bodyRow'>{`${moment(row.jam_mulai).format('HH:mm')} - ${moment(row.jam_akhir).format('HH:mm')}`}</td>
                )
            },
            selector: row => row.jadwalkelas,
            sortable: true,
            // width: '15vw',
        },
        {
            name: 'NAMA TUTOR',
            field: 'nama_tutor',
            selector: row => row.namatutor,
            sortable: true,
        },
        {
            name: 'MATERI TEORI',
            selector: row => row.namatutor,
            sortable: true,
            render: true,
            component: (row) => {
                return (
                    <td className='main-table__bodyRow'>
                        {
                            renderMateriContent('docs', row.kelas_jadwal_materies) ?
                            <Link className="pilih-tryout p-2 text-center m-1" 
                                to={{
                                    pathname: "/dashboard/e-learning/kelas-saya/materi/akses-materi",
                                    state: {
                                        nama_program: row.kelas.elearning_program.nama_program,
                                        nama_regio: row.kelas.nama_regio,
                                        nama_kelas: row.kelas.nama_kelas,
                                        materi_contents: row.kelas_jadwal_materies.filter(x => x.is_active === true && x.materi.materi_contents.length > 0)[0].materi.materi_contents.filter(x => x.content_type === 'docs'),
                                        kelas_jadwal_materies: row.kelas_jadwal_materies.filter(x => x.is_active === true && x.materi.materi_contents.length > 0)
                                    }
                                }}>
                                Akses Materi
                            </Link>
                            :
                            <Button onClick={() => {
                                Swal.fire({  
                                    title: 'Materi Teori tidak tersedia',  
                                    text: 'Materi teori tidak tersedia',  
                                    icon: 'warning',  
                                    showCancelButton: true,  
                                    cancelButtonText: 'Kembali',           
                                    showConfirmButton: false,  
                                  }); 
                            }} className="pilih-tryout-disable">Akses Materi</Button>
                        }
                    </td>
                )
            },
            cell: () => <Link className="pilih-tryout p-2 text-center m-1" to="/dashboard/e-learning/kelas-saya/materi/akses-materi">Akses Materi</Link>,
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
            minWidth: '210px'
        },
        {
            name: 'AUDIO/VIDEO',
            render: true,
            component: (row) => {
                return (
                    <td className='main-table__bodyRow'>
                        {
                            renderMateriContent('video', row.kelas_jadwal_materies) ?
                            <Link className="pilih-tryout p-2 text-center m-1" 
                                to={{
                                    pathname: "/dashboard/e-learning/kelas-saya/materi/akses-video",
                                    state: {
                                        nama_program: row.kelas.elearning_program.nama_program,
                                        nama_regio: row.kelas.nama_regio,
                                        nama_kelas: row.kelas.nama_kelas,
                                        materi_contents: row.kelas_jadwal_materies.filter(x => x.is_active === true && x.materi.materi_contents.length > 0)[0].materi.materi_contents.filter(x => x.content_type === 'video'),
                                        kelas_jadwal_materies: row.kelas_jadwal_materies.filter(x => x.is_active === true && x.materi.materi_contents.length > 0)
                                    }
                                }}>
                                Akses Video
                            </Link>
                            :
                            <Button onClick={() => {
                                Swal.fire({  
                                    title: 'Materi Video tidak tersedia',  
                                    text: 'Materi Video tidak tersedia',  
                                    icon: 'warning',  
                                    showCancelButton: true,  
                                    cancelButtonText: 'Kembali',           
                                    showConfirmButton: false,  
                                  }); 
                            }} className="pilih-tryout-disable">Akses Video</Button>
                        }
                        
                    </td>
                )
            },
            selector: row => row.namatutor,
            sortable: true,
            cell: () => <Link className="pilih-tryout p-2 text-center m-1" to="/dashboard/e-learning/kelas-saya/details/akses-video">Akses Video</Link>,
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
            minWidth: '210px'
        },
    ];
    const data = [
        {
            no: 1,
            namamateri: 'Lorem ipsum dolor.',
            programbimbingan: 'Lorem ipsum dolor.',
            regio: 'Progam 1',
            jadwalkelas: 'Hari / Tanggal',
            namatutor: 'Lorem ipsum.',
        },
        {
            no: 2,
            namamateri: 'Lorem ipsum dolor.',
            programbimbingan: 'Lorem ipsum dolor.',
            regio: 'Progam 1',
            jadwalkelas: 'Hari / Tanggal',
            namatutor: 'Lorem ipsum.',
        },
        {
            no: 3,
            namamateri: 'Lorem ipsum dolor.',
            programbimbingan: 'Lorem ipsum dolor.',
            regio: 'Progam 1',
            jadwalkelas: 'Hari / Tanggal',
            namatutor: 'Lorem ipsum.',
        },
        {
            no: 4,
            namamateri: 'Lorem ipsum dolor.',
            programbimbingan: 'Lorem ipsum dolor.',
            regio: 'Progam 1',
            jadwalkelas: 'Hari / Tanggal',
            namatutor: 'Lorem ipsum.',
        },
        {
            no: 5,
            namamateri: 'Lorem ipsum dolor.',
            programbimbingan: 'Lorem ipsum dolor.',
            regio: 'Progam 1',
            jadwalkelas: 'Hari / Tanggal',
            namatutor: 'Lorem ipsum.',
        },
        {
            no: 6,
            namamateri: 'Lorem ipsum dolor.',
            programbimbingan: 'Lorem ipsum dolor.',
            regio: 'Progam 1',
            jadwalkelas: 'Hari / Tanggal',
            namatutor: 'Lorem ipsum.',
        },
    ];

    const getListKelasJadwal = (params = '') => {
        setIsLoading(true)
        ElearningService.getListJadwalKelasParticipant(params).then(resp => {
            if(resp.data.success === true) {
                const response = resp.data;
                setKelasJadwal(response.data);

                setPage(response.meta.page)
                setPerPage(response.meta.perPage)
                setCount(response.meta.count)

                setHasNext(response.meta.hasNext)
                setHasPrev(response.meta.hasPrev);

                if(response.meta.userId) {
                    setUserId(response.meta.userId)
                }
            }
            setIsLoading(false)

            if(resp.data.code === 401){
                localStorage.clear()
                history.push('/signin');
                return;
            }
        }).catch(err => {
            setIsLoading(false)
            console.error(err)
        })
    }

    useEffect(() => {

        getListKelasJadwal(id)
    }, [])

    return (
        <React.Fragment>
            <SideBar />
            <div className="md:ml-64" style={{ backgroundColor: '#F3F4F6' }}>
                {/* <div className="bg-orange px-3 md:px-8 py-1 h-40">
                    <div className="container md:pr-8 md:pl-10">
                        <h3 className="text-left tracking-wider title-dashboard">Selamat datang di Kelas Bimbingan Intensif Ingenio Indonesia</h3>
                        <br />
                        <h3 className="text-left tracking-wider">Anda dapat menghubungi Admin terlebih dahulu untuk memastikan kelas dan program yang Anda daftarkan. <br /> <br />Kontak Admin <a href={contactLink()} target={'_blank'}> <i class="fa fa-whatsapp" aria-hidden="true"></i></a></h3>
                    </div>
                </div> */}

                <div className="container md:pr-8 md:pl-10"  style={{position: 'absolute', top: '80px'}}>
                    <h3 className="text-left tracking-wider title-dashboard">Selamat datang di Kelas Bimbingan Intensif Ingenio Indonesia</h3>
                    <br />
                    <h3 className="text-left tracking-wider">Anda dapat menghubungi Admin terlebih dahulu untuk memastikan kelas dan program yang Anda daftarkan. <br /> <br />Kontak Admin <a href={contactLink()} target={'_blank'}> <i class="fa fa-whatsapp" aria-hidden="true"></i></a></h3>
                </div> 

                <div className="px-3 md:px-8 mt-4">
                    <div className="container mx-auto max-w-full">
                        <div className="row">
                            <div className="col-md-4 col-lg-4 col-xs-12 mb-4">
                                <h2 className="text-left tracking-wider text-muted">Nama Kelas  /  <strong>{nama_kelas}</strong></h2>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-4 col-lg-4 col-xs-12 mb-4">
                                <table style={{marginBottom: '15px'}}>
                                    <tr>
                                        <td style={{padding: '0px 10px 0px 0px'}}>Regio</td>
                                        <td style={{padding: '0px 10px 0px 0px'}}>:</td>
                                        <td style={{padding: '0px'}}><b>{nama_regio}</b></td>
                                    </tr>
                                    <tr>
                                        <td style={{padding: '0px'}}>Program</td>
                                        <td style={{padding: '0px'}}>:</td>
                                        <td style={{padding: '0px'}}><b>{nama_program}</b></td>
                                    </tr>
                                    <tr>
                                        <td style={{padding: '0px'}}>Kelas</td>
                                        <td style={{padding: '0px'}}>:</td>
                                        <td style={{padding: '0px'}}><b>{nama_kelas}</b></td>
                                    </tr>
                                </table>
                                {/* <h2 className="text-left tracking-wider">Regio : <strong>{nama_regio}</strong></h2><br />
                                <h2 className="text-left tracking-wider">Program : <strong>{nama_program}</strong></h2><br />
                                <h2 className="text-left tracking-wider">Kelas : <strong>{nama_kelas}</strong></h2> */}
                            </div>
                        </div>
                        <div className="col-md-12 col-lg-12 col-xs-12 mt-3">
                            {/* <DataTable columns={columns} data={data} pagination /> */}
                            <CustomDatatable 
                                filteredItems={kelasJadwal} 
                                columns={columns}
                                isLoading={isLoading}
                                page={page}
                                perPage={perPage}
                                hasPrev={hasPrev}
                                hasNext={hasNext}
                                count={count}
                                onTableChange={(e, data) => {
                                    switch (e) {
                                    case 'rowPerPageChange':
                                        setPerPage(data.perPage)
                                        getListKelasJadwal(`page=${page}&perPage=${data.perPage}&keyword=${keyword}&program=${program}`)
                                        break;
                                    case 'rowPageChange':
                                        setPage(data.page)
                                        getListKelasJadwal(`page=${data.page}&perPage=${perPage}&keyword=${keyword}&program=${program}`)
                                        break;
                                    
                                    default:
                                        break;
                                    }
                                }}
                            />
                        </div>
                    </div>
                </div>

            </div>
        </React.Fragment >

    )
}