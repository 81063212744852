import React, { useEffect } from 'react';
import { BrowserRouter as Router, Redirect, Switch, Route } from 'react-router-dom';
import { 
  //LandingPage, 
  // LoginPage,
  // LoginAkademikPage,
  // LoginTutorPage,
  // RegisterPage, 
  // LupaPasswordPage, 
  // VerificationPage,
  // KonfirmasiPasswordPage, 
  // TestimoniPage,
  // TutorPage,
  // PrestasiPage,
  // OpeningPage,
  // ValuesPage,
  // HistoryPage,
  // DisclaimerPage,
  // PartnershipPage,
  // HubungikamiPage,
  // SeleksitutorPage,
  // IngeniostorePage,
  // FasilitasPage,
  // GalleryPage,
  // ArtikelPage,
  // ArtikelSelectPage,
  // ProgramUtbPage,
  // ProgramPreklinikPage,
  // ProgramUkomKebPage,
  // ProgramUkmppdPage,
  // ProgramBimbinganCoasPage,
  // ProgramDokterMudaPage,
  // FaqPage,
  // Page404,
  // Page403
} from './views';
import {
  // DashboardHomePage,
  // DashboardProfilePage,
  // AvailableCourse,
  // MyMateri,
  // ViewMateriCourse,
  // BuyCourse,
  // TransactionCourse,
  // TransactionDone,
  // MyCourse,
  // AksesCourse,
  // TataCara,
  // AvailableTryOut,
  // MyTryOut,
  // Result,
  // ResultDetail,
  // DetailTO,
  // DetailMyTO,
  // Payment,
  // Testimoni,
  // UploadDataPeserta,
  // Kerjakan,
  // ProsesBayar,
  // ProsesBayarSukses,
  // AkademikHomePage,
  // MyProfileAkademik,
  // UploadDataAkademik,
  // AkademisTryOut,
  // BuatTryOut,
  // EditTryOut,
  // SoalEditor,
  // SoalEditorTambahSoal,
  // BankTryOut,
  // DataPeserta,
  // DetailPeserta,
  // History,
  // Kompetensi,
  // Topik,
  // DetailTryOut,
  // Bidang,
  // DataKelasTutor,
  // JurnalMengajar,
  // InputJurnalMengajar,
  // DataHonorarium,
  // Akun,
  // KelasDetail,
  // OlahProgram,
  // OlahKelas,
  // OlahJadwal,
  // OlahMateri,
  // AktivasiPeserta,
  // ProfilePeserta,
  // RekapAbsensi,
  // EvaluasiTutor,
  // JurnalMengajarAkademik,
  // AktivasiVideo,
  // SemuaEcourse,
  // OlahEcourse,
  // KelolaVideo,
  // EvaluasiEcourse,
  // DetailPesertaEcourse,
  // DetailAktivasiPeserta,
  // DetailRekapAbsensi,
  // DetailEvaluasiTutor,
  // DetailEvaluasiAkademik,
  // DetailProfilePeserta,
  // SoalTryout,
  // KelasTersedia,
  // PaymentElearning,
  // Resi,
  // Elibrary,
  // HistoryKelas,
  // EvaluasiKelas,
  // InfoKelas,
  // IsiEvaluasi,
  // CekTracking,
  // RaporTutor,
  // RekapRaporTutor,
  // UploadMateri,
  // UploadDataTutor,
  // Transaction,
  // DetailOlahJadwal,
  // DetailOlahMateri,
  // MateriEditor,
  // DetailJurnalMengajar,
  // DetailJurnalMengajar2,
  // DetailProfileJurnal,
  // DetailKelasTersedia,
  // BayarKelas,
  // StatusBayar,
  // AksesMateri,
  // AksesVideo,
  // SoalEditorEditSoal,
  // ReviewSubTO,
  // HasilPengerjaan,
  // DetailHasilPeserta,
  // DetailNilai,
  // DataPesertaSubtryout,
  // MateriPreview,
  // ElearningProsesBayar,
  // ElearningProsesBayarSukses,

  // AdminHomePage,
  // AdminUsers,
  // MyProfileAdmin,
  // OlahProgramAdmin,
  // OlahRegioAdmin,
  // OlahSubRegioAdmin,
  // OlahKategoriLibraryAdmin,
  // OlahLibraryAdmin,
  // OlahTutorAdmin,
  // AdminTransaction,
  // PaymentEcourseAdmin,
  // PaymentElearningAdmin,
  // PaymentTryoutAdmin,
  // UpdateLandingPage,
  // PengumumanAdmin,

  // InformasiKelasAdmin,
  // InformasiTutorAdmin,
  // PenilaianTutorAdmin,
  // ProfileTutorAdmin,
  // InformasiDataPesertaTO,
  // InformasiDataPesertaEL,
  // InformasiDataPesertaEC,
  // InformasiTestimoniAdmin,
  // InformasiUploadDataAdmin,
  // InformasiLogistikAdmin,
  // InformasiEcourseAdmin,
  // InformasiTryOutAdmin,
  // InformasiTryOutDetail,
  // ProfileTryOutDetail,
  // InformasiElearningAdmin,

  // TutorHomePage,
  // MyProfileTutor,
  // TutorOlahMateri,
  // TutorDetailOlahMateri,
  // TutorMateriEditor,
  // TutorMateriPreview,
  // Absensi,
  // InputAbsensi,

  // TutorDataKelasParticipant,
  // TutorDetailDataKelasParticipant,
  // TutorDetailProfilePeserta,

  // LogistikHomePage,
  // MyProfileLogistik,
  // InformasiKelasLogistik,
  // InformasiResiLogistik,
  // OlahResiLogistik,
  // PrintResiLogistik,
  // UploadDataLogistik,

  // AkuntanHomePage,
  // MyProfileAkuntan,
  // PaymentEcourseAkuntan,
  // PaymentElearningAkuntan,
  // PaymentTryoutAkuntan,
  // ViewEcourseAkuntan,
  // ViewElearningAkuntan,
  // ViewTryoutAkuntan,
  // UploadDataAkuntan,
  // InformasiSubTryOutAdmin,
  // InformasiDetailPesertaAdmin,
  // InformasiDetailNilaiAdmin,

  // EcourseProsesBayar,
  // EcourseProsesBayarSukses

} from './views/dashboard';
import ProtectedRoute from './components/ProtectedRoute';
import GuestOnlyRoute from './components/GuestOnlyRoute';
import Authorization from './helpers/hoc/Authorization';
import 'bootstrap/dist/css/bootstrap.min.css';
import { clearMessage } from "./store/actions/message";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import { history } from './helpers/history';
import OnlyCheckRole from './helpers/hoc/OnlyCheckRole';
import "tailwindcss/tailwind.css"
import './main.css'
import AuthGuard from './helpers/hoc/AuthGuard';
// import UploadData from './views/dashboard/tutor/UploadData';
// import InformasiKelas from './views/dashboard/admin/Informasi/InformasiKelas';
// import OlahResiLogistik from './views/dashboard/logistik/OlahResi';
// import MyProfileTutor from './views/dashboard/tutor/MyProfile';
// import OlahEcourse from './views/dashboard/akademik/ecourse/OlahEcourse';

import moment from 'moment'

const loading = (
  <div className="pt-3 text-center">
    Loading...
  </div>
)

const LandingPage = React.lazy(() => import('./views/LandingPage'));
const LoginPage = React.lazy(() => import('./views/LoginPage'));
const LoginAkademikPage = React.lazy(() => import('./views/LoginAkademikPage'));
const LoginTutorPage = React.lazy(() => import('./views/LoginTutorPage'));
const RegisterPage = React.lazy(() => import('./views/RegisterPage'));
const LupaPasswordPage = React.lazy(() => import('./views/LupaPasswordPage'));
const VerificationPage = React.lazy(() => import('./views/VerificationPage'));
const KonfirmasiPasswordPage = React.lazy(() => import('./views/KonfirmasiPasswordPage'));
const TestimoniPage = React.lazy(() => import('./views/TestimoniPage'));
const TutorPage = React.lazy(() => import('./views/TutorPage'));
const PrestasiPage = React.lazy(() => import('./views/PrestasiPage'));
const OpeningPage = React.lazy(() => import('./views/OpeningPage'));
const ValuesPage = React.lazy(() => import('./views/ValuesPage'));
const HistoryPage = React.lazy(() => import('./views/HistoryPage'));
const DisclaimerPage = React.lazy(() => import('./views/DisclaimerPage'));
const PartnershipPage = React.lazy(() => import('./views/PartnershipPage'));
const HubungikamiPage = React.lazy(() => import('./views/HubungikamiPage'));
const SeleksitutorPage = React.lazy(() => import('./views/SeleksitutorPage'));
const IngeniostorePage = React.lazy(() => import('./views/IngeniostorePage'));
const FasilitasPage = React.lazy(() => import('./views/FasilitasPage'));
const GalleryPage = React.lazy(() => import('./views/GalleryPage'));
const ArtikelPage = React.lazy(() => import('./views/ArtikelPage'));
const ArtikelSelectPage = React.lazy(() => import('./views/ArtikelSelectPage'));
const ProgramUtbPage = React.lazy(() => import('./views/ProgramUtbPage'));
const ProgramPreklinikPage = React.lazy(() => import('./views/ProgramPreklinikPage'));
const ProgramUkomKebPage = React.lazy(() => import('./views/ProgramUkomKebPage'));
const ProgramUkmppdPage = React.lazy(() => import('./views/ProgramUkmppdPage'));
const ProgramBimbinganCoasPage = React.lazy(() => import('./views/ProgramBimbinganCoasPage'));
const ProgramRocketPlusPage = React.lazy(() => import('./views/ProgramRocketPlusPage'));
const ProgramDokterMudaPage = React.lazy(() => import('./views/ProgramDokterMudaPage'));
const BaksosPage = React.lazy(() => import('./views/BaksosPage'))
const EventPage = React.lazy(() => import('./views/EventPage'))
const FaqPage = React.lazy(() => import('./views/FaqPage'));
const Page404 = React.lazy(() => import('./views/404'));
const Page403 = React.lazy(() => import('./views/403'));

const PrivacypolicyPage = React.lazy(() => import('./views/PrivacypolicyPage'));
const ProgramUkmp2dgPage = React.lazy(() => import('./views/ProgramUkmp2dgPage'));

const KelasSaya = React.lazy(() => import('./views/dashboard/elearning/KelasSaya'));
const KelasSayaDetails = React.lazy(() => import('./views/dashboard/elearning/KelasSayaDetails'));

const DashboardHomePage = React.lazy(() => import('./views/dashboard/HomePage'))
const DashboardProfilePage = React.lazy(() => import('./views/dashboard/ProfilePage'))
const AvailableCourse = React.lazy(() => import('./views/dashboard/CourseAvailable'))
const MyMateri = React.lazy(() => import('./views/dashboard/MyMateri'))
const ViewMateriCourse = React.lazy(() => import('./views/dashboard/ViewMateri'))
const BuyCourse = React.lazy(() => import('./views/dashboard/BuyCourse'))
const TransactionCourse = React.lazy(() => import('./views/dashboard/TransactionCourse'))
const TransactionDone = React.lazy(() => import('./views/dashboard/TransactionDone'))
const MyCourse = React.lazy(() => import('./views/dashboard/MyCourse'))
const AksesCourse = React.lazy(() => import('./views/dashboard/AksesCourse'))
const TataCara = React.lazy(() => import('./views/dashboard/tryout/TataCara'))
const AvailableTryOut = React.lazy(() => import('./views/dashboard/tryout/TryOut'))
const MyTryOut = React.lazy(() => import('./views/dashboard/tryout/MyTryOut'))
const Result = React.lazy(() => import('./views/dashboard/tryout/HasilTryOut'))
const ResultDetail = React.lazy(() => import('./views/dashboard/tryout/DetailHasilTryOut'))
const DetailTO = React.lazy(() => import('./views/dashboard/tryout/DetailTryOut'))
const DetailMyTO = React.lazy(() => import('./views/dashboard/tryout/MyTryOutDetail'))
const Payment = React.lazy(() => import('./views/dashboard/tryout/Payment'))
const Testimoni = React.lazy(() => import('./views/dashboard/Testimoni'))
const UploadDataPeserta = React.lazy(() => import('./views/dashboard/UploadData'))
const Kerjakan = React.lazy(() => import('./views/dashboard/tryout/Kerjakan'))
const ProsesBayar = React.lazy(() => import('./views/dashboard/tryout/ProsesBayar'))
const ProsesBayarSukses = React.lazy(() => import('./views/dashboard/tryout/ProsesBayarSukses'))
const AkademikHomePage = React.lazy(() => import('./views/dashboard/akademik/Home'))
const MyProfileAkademik = React.lazy(() => import('./views/dashboard/akademik/MyProfile'))
const UploadDataAkademik = React.lazy(() => import('./views/dashboard/akademik/UploadData'))
const AkademisTryOut = React.lazy(() => import('./views/dashboard/akademik/tryout/TryOut'))
const BuatTryOut = React.lazy(() => import('./views/dashboard/akademik/tryout/BuatTryOut'))
const EditTryOut = React.lazy(() => import('./views/dashboard/akademik/tryout/EditTryOut'))
const SoalEditor = React.lazy(() => import('./views/dashboard/akademik/tryout/SoalEditor'))
const SoalEditorTambahSoal = React.lazy(() => import('./views/dashboard/akademik/tryout/SoalEditorTambahSoal'))
const BankTryOut = React.lazy(() => import('./views/dashboard/akademik/tryout/BankTryOut'))
const DataPeserta = React.lazy(() => import('./views/dashboard/akademik/tryout/DataPeserta'))
const DetailPeserta = React.lazy(() => import('./views/dashboard/akademik/tryout/DetailPeserta'))
const History = React.lazy(() => import('./views/dashboard/akademik/tryout/History'))
const Kompetensi = React.lazy(() => import('./views/dashboard/akademik/tryout/Kompetensi'))
const Topik = React.lazy(() => import('./views/dashboard/akademik/tryout/Topik'))
const DetailTryOut = React.lazy(() => import('./views/dashboard/akademik/tryout/DetailTryOut'))
const Bidang = React.lazy(() => import('./views/dashboard/akademik/tryout/Bidang'))
const DataKelasTutor = React.lazy(() => import('./views/dashboard/tutor/DataKelasTutor'))
const JurnalMengajar = React.lazy(() => import('./views/dashboard/tutor/JurnalMengajar'))
const InputJurnalMengajar = React.lazy(() => import('./views/dashboard/tutor/InputJurnalMengajar'))
const DataHonorarium = React.lazy(() => import('./views/dashboard/tutor/DataHonorarium'))
const Akun = React.lazy(() => import('./views/dashboard/tutor/Akun'))
const KelasDetail = React.lazy(() => import('./views/dashboard/tutor/KelasDetail'))
const OlahProgram = React.lazy(() => import('./views/dashboard/akademik/elearning/OlahProgram'))
const OlahKelas = React.lazy(() => import('./views/dashboard/akademik/elearning/OlahKelas'))
const OlahJadwal = React.lazy(() => import('./views/dashboard/akademik/elearning/OlahJadwal'))
const OlahMateri = React.lazy(() => import('./views/dashboard/akademik/elearning/OlahMateri'))
const AktivasiPeserta = React.lazy(() => import('./views/dashboard/akademik/elearning/AktivasiPeserta'))
const ProfilePeserta = React.lazy(() => import('./views/dashboard/akademik/elearning/ProfilePeserta'))
const RekapAbsensi = React.lazy(() => import('./views/dashboard/akademik/elearning/RekapAbsensi'))
const EvaluasiTutor = React.lazy(() => import('./views/dashboard/akademik/elearning/EvaluasiTutor'))
const JurnalMengajarAkademik = React.lazy(() => import('./views/dashboard/akademik/elearning/JurnalMengajar'))
const AktivasiVideo = React.lazy(() => import('./views/dashboard/akademik/elearning/AktivasiVideo'))
const SemuaEcourse = React.lazy(() => import('./views/dashboard/akademik/ecourse/SemuaEcourse'))
const OlahEcourse = React.lazy(() => import('./views/dashboard/akademik/ecourse/OlahEcourse'))
const KelolaVideo = React.lazy(() => import('./views/dashboard/akademik/ecourse/KelolaVideo'))
const EvaluasiEcourse = React.lazy(() => import('./views/dashboard/akademik/ecourse/EvaluasiEcourse'))
const DetailPesertaEcourse = React.lazy(() => import('./views/dashboard/akademik/ecourse/DetailPesertaEcourse'))
const DetailAktivasiPeserta = React.lazy(() => import('./views/dashboard/akademik/elearning/DetailAktivasiPeserta'))
const DetailRekapAbsensi = React.lazy(() => import('./views/dashboard/akademik/elearning/DetailRekapAbsensi'))
const DetailEvaluasiTutor = React.lazy(() => import('./views/dashboard/akademik/elearning/DetailEvaluasiTutor'))
const DetailEvaluasiAkademik = React.lazy(() => import('./views/dashboard/akademik/elearning/DetailEvaluasiAkademik'))
const DetailProfilePeserta = React.lazy(() => import('./views/dashboard/akademik/elearning/DetailProfilePeserta'))
const SoalTryout = React.lazy(() => import('./views/dashboard/tryout/SoalTryout'))
const KelasTersedia = React.lazy(() => import('./views/dashboard/elearning/KelasTersedia'))
const PaymentElearning = React.lazy(() => import('./views/dashboard/elearning/Payment'))
const Resi = React.lazy(() => import('./views/dashboard/elearning/Resi'))
const Elibrary = React.lazy(() => import('./views/dashboard/elearning/Elibrary'))
const HistoryKelas = React.lazy(() => import('./views/dashboard/elearning/HistoryKelas'))
const EvaluasiKelas = React.lazy(() => import('./views/dashboard/elearning/EvaluasiKelas'))
const InfoKelas = React.lazy(() => import('./views/dashboard/elearning/InfoKelas'))
const IsiEvaluasi = React.lazy(() => import('./views/dashboard/elearning/IsiEvaluasi'))
const CekTracking = React.lazy(() => import('./views/dashboard/elearning/CekTracking'))
const RaporTutor = React.lazy(() => import('./views/dashboard/tutor/RaporTutor'))
const RekapRaporTutor = React.lazy(() => import('./views/dashboard/tutor/RekapRaporTutor'))
const UploadMateri = React.lazy(() => import('./views/dashboard/tutor/UploadMateri'))
const UploadDataTutor = React.lazy(() => import('./views/dashboard/tutor/UploadData'))
const Transaction = React.lazy(() => import('./views/dashboard/Transaction'))
const DetailOlahJadwal = React.lazy(() => import('./views/dashboard/akademik/elearning/DetailOlahJadwal'))
const DetailOlahMateri = React.lazy(() => import('./views/dashboard/akademik/elearning/DetailOlahMateri'))
const MateriEditor = React.lazy(() => import('./views/dashboard/akademik/elearning/MateriEditor'))
const DetailJurnalMengajar = React.lazy(() => import('./views/dashboard/akademik/elearning/DetailJurnalMengajar'))
const DetailJurnalMengajar2 = React.lazy(() => import('./views/dashboard/akademik/elearning/DetailJurnalMengajar2'))
const DetailProfileJurnal = React.lazy(() => import('./views/dashboard/akademik/elearning/DetailProfileJurnal'))
const DetailKelasTersedia = React.lazy(() => import('./views/dashboard/elearning/DetailKelasTersedia'))
const BayarKelas = React.lazy(() => import('./views/dashboard/elearning/BayarKelas'))
const StatusBayar = React.lazy(() => import('./views/dashboard/elearning/StatusBayar'))
const AksesMateri = React.lazy(() => import('./views/dashboard/elearning/AksesMateri'))
const AksesVideo = React.lazy(() => import('./views/dashboard/elearning/AksesVideo'))
const SoalEditorEditSoal = React.lazy(() => import('./views/dashboard/akademik/tryout/SoalEditorEditSoal'))
const ReviewSubTO = React.lazy(() => import('./views/dashboard/akademik/tryout/ReviewSubTO'))
const HasilPengerjaan = React.lazy(() => import('./views/dashboard/tryout/HasilPengerjaan'))
const DetailHasilPeserta = React.lazy(() => import('./views/dashboard/akademik/elearning/DetailHasilPeserta'))
const DetailNilai = React.lazy(() => import('./views/dashboard/akademik/tryout/DetailNilai'))
const DataPesertaSubtryout = React.lazy(() => import('./views/dashboard/akademik/tryout/DataPesertaSubtryout'))
const MateriPreview = React.lazy(() => import('./views/dashboard/akademik/elearning/MateriPreview'))
const ElearningProsesBayar = React.lazy(() => import('./views/dashboard/elearning/ElearningProsesBayar'))
const ElearningProsesBayarSukses = React.lazy(() => import('./views/dashboard/elearning/ElearningProsesBayarSukses'))

const AdminHomePage = React.lazy(() => import('./views/dashboard/admin/Home'))
const AdminUsers = React.lazy(() => import('./views/dashboard/admin/Users'))
const MyProfileAdmin = React.lazy(() => import('./views/dashboard/admin/MyProfile'))
const OlahProgramAdmin = React.lazy(() => import('./views/dashboard/admin/OlahData/OlahProgramAdmin'))
const OlahRegioAdmin = React.lazy(() => import('./views/dashboard/admin/OlahData/OlahRegioAdmin'))
const OlahSubRegioAdmin = React.lazy(() => import('./views/dashboard/admin/OlahData/OlahSubRegioAdmin'))
const OlahKategoriLibraryAdmin = React.lazy(() => import('./views/dashboard/admin/OlahData/OlahKategoriLibraryAdmin'))
const OlahLibraryAdmin = React.lazy(() => import('./views/dashboard/admin/OlahData/OlahLibraryAdmin'))
const OlahTutorAdmin = React.lazy(() => import('./views/dashboard/admin/OlahData/OlahTutorAdmin'))
const AdminTransaction = React.lazy(() => import('./views/dashboard/admin/transactions/AdminTransaction'))
const PaymentEcourseAdmin = React.lazy(() => import('./views/dashboard/admin/transactions/PaymentEcourse'))
const PaymentElearningAdmin = React.lazy(() => import('./views/dashboard/admin/transactions/PaymentElearning'))
const PaymentTryoutAdmin = React.lazy(() => import('./views/dashboard/admin/transactions/PaymentTryout'))
const UpdateLandingPage = React.lazy(() => import('./views/dashboard/admin/UpdateLanding'))
const PengumumanAdmin = React.lazy(() => import('./views/dashboard/admin/Pengumuman'))

const InformasiKelasAdmin = React.lazy(() => import('./views/dashboard/admin/Informasi/InformasiKelas'))
const InformasiTutorAdmin = React.lazy(() => import('./views/dashboard/admin/Informasi/InformasiTutor'))
const PenilaianTutorAdmin = React.lazy(() => import('./views/dashboard/admin/Informasi/PenilaianTutor'))
const ProfileTutorAdmin = React.lazy(() => import('./views/dashboard/admin/Informasi/ProfileTutor'))
const InformasiDataPesertaTO = React.lazy(() => import('./views/dashboard/admin/Informasi/InformasiDataPesertaTO'))
const InformasiDataPesertaEL = React.lazy(() => import('./views/dashboard/admin/Informasi/InformasiDataPesertaEL'))
const InformasiDataPesertaEC = React.lazy(() => import('./views/dashboard/admin/Informasi/InformasiDataPesertaEC'))
const InformasiTestimoniAdmin = React.lazy(() => import('./views/dashboard/admin/Informasi/InformasiTestimoni'))
const InformasiUploadDataAdmin = React.lazy(() => import('./views/dashboard/admin/Informasi/InformasiUploadData'))
const InformasiLogistikAdmin = React.lazy(() => import('./views/dashboard/admin/Informasi/InformasiLogistik'))
const InformasiEcourseAdmin = React.lazy(() => import('./views/dashboard/admin/Informasi/InformasiEcourse'))
const InformasiTryOutAdmin = React.lazy(() => import('./views/dashboard/admin/Informasi/InformasiTryOut'))
const InformasiTryOutDetail = React.lazy(() => import('./views/dashboard/admin/Informasi/InformasiTryOutDetail'))
const ProfileTryOutDetail = React.lazy(() => import('./views/dashboard/admin/Informasi/ProfileTryOut'))
const InformasiElearningAdmin = React.lazy(() => import('./views/dashboard/admin/Informasi/InformasiElearning'))

const TutorHomePage = React.lazy(() => import('./views/dashboard/tutor/Home'))
const MyProfileTutor = React.lazy(() => import('./views/dashboard/tutor/MyProfile'))
const TutorOlahMateri = React.lazy(() => import('./views/dashboard/tutor/OlahMateri'))
const TutorDetailOlahMateri = React.lazy(() => import('./views/dashboard/tutor/DetailOlahMateri'))
const TutorMateriEditor = React.lazy(() => import('./views/dashboard/tutor/MateriEditor'))
const TutorMateriPreview = React.lazy(() => import('./views/dashboard/tutor/MateriPreview'))
const Absensi = React.lazy(() => import('./views/dashboard/tutor/Absensi'))
const InputAbsensi = React.lazy(() => import('./views/dashboard/tutor/InputAbsensi'))

const TutorDataKelasParticipant = React.lazy(() => import('./views/dashboard/tutor/DataKelasParticipant'))
const TutorDetailDataKelasParticipant = React.lazy(() => import('./views/dashboard/tutor/DetailDataKelasParticipant'))
const TutorDetailProfilePeserta = React.lazy(() => import('./views/dashboard/tutor/DetailProfilePeserta'))

const LogistikHomePage = React.lazy(() => import('./views/dashboard/logistik/Home'))
const MyProfileLogistik = React.lazy(() => import('./views/dashboard/logistik/MyProfile'))
const InformasiKelasLogistik = React.lazy(() => import('./views/dashboard/logistik/InformasiKelas'))
const InformasiResiLogistik = React.lazy(() => import('./views/dashboard/logistik/InformasiResi'))
const OlahResiLogistik = React.lazy(() => import('./views/dashboard/logistik/OlahResi'))
const PrintResiLogistik = React.lazy(() => import('./views/dashboard/logistik/PrintResi'))
const UploadDataLogistik = React.lazy(() => import('./views/dashboard/logistik/UploadData'))

const AkuntanHomePage = React.lazy(() => import('./views/dashboard/akuntan/Home'))
const MyProfileAkuntan = React.lazy(() => import('./views/dashboard/akuntan/MyProfile'))
const PaymentEcourseAkuntan = React.lazy(() => import('./views/dashboard/akuntan/PaymentEcourse'))
const PaymentElearningAkuntan = React.lazy(() => import('./views/dashboard/akuntan/PaymentElearning'))
const PaymentTryoutAkuntan = React.lazy(() => import('./views/dashboard/akuntan/PaymentTryout'))
const ViewEcourseAkuntan = React.lazy(() => import('./views/dashboard/akuntan/ViewEcourse'))
const ViewElearningAkuntan = React.lazy(() => import('./views/dashboard/akuntan/ViewElearning'))
const ViewTryoutAkuntan = React.lazy(() => import('./views/dashboard/akuntan/ViewTryout'))
const UploadDataAkuntan = React.lazy(() => import('./views/dashboard/akuntan/UploadData'))

const InformasiSubTryOutAdmin = React.lazy(() => import('./views/dashboard/admin/Informasi/InformasiSubTryOut'))
const InformasiDetailPesertaAdmin = React.lazy(() => import('./views/dashboard/admin/Informasi/InformasiDetailPeserta'))
const InformasiDetailNilaiAdmin = React.lazy(() => import('./views/dashboard/admin/Informasi/InformasiDetailNilai'))

const EcourseProsesBayar = React.lazy(() => import('./views/dashboard/EcourseProsesBayar'))
const EcourseProsesBayarSukses = React.lazy(() => import('./views/dashboard/EcourseProsesBayarSukses'))

function App(props) {
  // history.listen((location) => {
  //   props.dispatch(clearMessage()); // clear message when changing location
  // });
  useEffect(() => {
    moment().locale('id')
  }, [])
  return (
    <div className="App">
      <Router>
      <React.Suspense fallback={loading}>
        <Switch>
          <Route path="/404" exact>
            <Page404 />
          </Route>
          <Route path="/403" exact>
            <Page403 />
          </Route>
          <Route path="/" exact>
            <LandingPage />
          </Route>
          <Route path="/signin" exact component={LoginPage} />
          <GuestOnlyRoute path="/akademik/signin" exact component={LoginAkademikPage} />
          <GuestOnlyRoute path="/tutor/signin" exact component={LoginTutorPage} />
          <Route path="/signup" exact component={RegisterPage} />
          <Route path="/forgetpassword" component={LupaPasswordPage} />
          <Route path="/verification" component={VerificationPage} />
          <Route exact path="/confirm_new_password" component={KonfirmasiPasswordPage} />
          <Route path="/testimoni_peserta">
            <TestimoniPage />
          </Route>
          <Route path="/tutor" exact>
            <TutorPage />
          </Route>
          <Route path="/prestasi" exact>
            <PrestasiPage />
          </Route>
          <Route path="/opening" exact>
            <OpeningPage />
          </Route>
          <Route path="/values" exact>
            <ValuesPage />
          </Route>
          <Route path="/history" exact>
            <HistoryPage />
          </Route>
          <Route path="/disclaimer" exact>
            <DisclaimerPage />
          </Route>
          <Route path="/privacy-policy" exact>
            <PrivacypolicyPage />
          </Route>
          <Route path="/partnership" exact>
            <PartnershipPage />
          </Route>
          <Route path="/hubungi-kami" exact>
            <HubungikamiPage />
          </Route>
          <Route path="/seleksi-tutor" exact>
            <SeleksitutorPage />
          </Route>
          <Route path="/ingenio-store" exact>
            <IngeniostorePage />
          </Route>
          <Route path="/fasilitas" exact>
            <FasilitasPage />
          </Route>
          <Route path="/gallery" exact>
            <GalleryPage />
          </Route>
          <Route path="/ingenio-peduli" exact>
            <BaksosPage />
          </Route>
          <Route path="/event" exact>
            <EventPage />
          </Route>
          <Route path="/artikel" exact>
            <ArtikelPage />
          </Route>
          <Route 
            path="/artikel-select" 
            exact
            component={ArtikelSelectPage}
          />
          <Route 
            path="/program-utb" 
            exact
            component={ProgramUtbPage}
          />
          <Route 
            path="/program-preklinik" 
            exact
            component={ProgramPreklinikPage}
          />
          <Route 
            path="/program-ukom-kebidanan" 
            exact
            component={ProgramUkomKebPage}
          />
          <Route 
            path="/program-rocket-plus" 
            exact
            component={ProgramRocketPlusPage}
          />
          
          <Route 
            path="/program-ukmp2dg" 
            exact 
            component={ProgramUkmp2dgPage}
          />
          <Route 
            path="/program-ukmppd" 
            exact 
            component={ProgramUkmppdPage}
          />
          <Route 
            path="/program-bimbingan-pre-coass" 
            exact 
            component={ProgramBimbinganCoasPage}
          />
          <Route 
            path="/program-dokter-muda" 
            exact 
            component={ProgramDokterMudaPage}
          />
          <Route 
            path="/faq" 
            exact
            component={FaqPage}
          />
          
          {/* AKADEMIK DASHBOARD */}
          <Route 
            path="/dashboard/akademik" 
            exact 
            // component={Authorization(AkademikHomePage, "AKADEMIK", false)} 
            component={AuthGuard(AkademikHomePage, 2)}
          />
          <Route 
            path="/dashboard/akademik/profile-saya" 
            exact
            component={MyProfileAkademik}
          />
          <Route 
            path="/dashboard/akademik/upload-data" 
            exact
            component={AuthGuard(UploadDataAkademik, 2)}
          />
          <Route 
            path="/dashboard/akademik/tryout-list" 
            exact
            component={AuthGuard(AkademisTryOut, 2)}
          />
          <Route 
            path="/dashboard/akademik/buat-tryout" 
            component={AuthGuard(BuatTryOut, 2)}
            exact
          />
          <Route 
            path="/dashboard/akademik/edit-tryout/:id" 
            component={AuthGuard(EditTryOut, 2)}
            exact
          />
          <Route 
            path="/dashboard/akademik/tryout/:id"
            exact 
            component={AuthGuard(DetailTryOut, 2)} 
          />
          <Route 
            path="/dashboard/akademik/bank-tryout" 
            exact
            component={AuthGuard(BankTryOut, 2)}
          />
          <Route 
            path="/dashboard/akademik/tryout/:id/soal-editor/:subtryoutId" 
            exact
            component={AuthGuard(SoalEditor, 2)}
          />
          <Route 
            path="/dashboard/akademik/tryout/:id/soal-editor/:subtryoutId/review" 
            exact
            component={AuthGuard(ReviewSubTO, 2)}
          />
          <Route 
            path="/dashboard/akademik/tryout/:id/soal-editor/:subtryoutId/add"
            exact
            component={AuthGuard(SoalEditorTambahSoal, 2)}
          />
          <Route 
            path="/dashboard/akademik/tryout/:id/soal-editor/:subtryoutId/edit/:soalId"
            exact
            component={AuthGuard(SoalEditorEditSoal, 2)}
          />
          <Route 
            path="/dashboard/akademik/data-peserta" 
            exact
            component={AuthGuard(DataPeserta, 2)}
          />
          <Route 
            path="/dashboard/akademik/data-peserta/detail/:id" 
            exact
            component={AuthGuard(DetailPeserta, 2)}
          />
          <Route 
            path="/dashboard/akademik/data-peserta/subtryout/:id" 
            exact
            component={AuthGuard(DataPesertaSubtryout, 2)}
          />
          <Route 
            path="/dashboard/akademik/data-peserta/detail-peserta/:id" 
            exact
            component={AuthGuard(DetailProfilePeserta, 2)}
          />
          <Route 
            path="/dashboard/akademik/data-peserta/detail-hasil-peserta/:id" 
            exact
            component={AuthGuard(DetailNilai, 2)}
          />
          <Route 
            path="/dashboard/akademik/history" 
            exact
            component={AuthGuard(History, 2)}
          />
          <Route 
            path="/dashboard/akademik/kompetensi"
            exact
            component={AuthGuard(Kompetensi, 2)}
          />
          <Route 
            path="/dashboard/akademik/topik" 
            exact
            component={AuthGuard(Topik, 2)}
          />
          <Route 
            path="/dashboard/akademik/bidang" 
            exact
            component={AuthGuard(Bidang, 2)}
          />
          {/* AKADEMIK E-LEARNING */}
          <Route path="/dashboard/akademik/e-learning/olah-program" exact component={AuthGuard(OlahProgram, 2)} />
          <Route path="/dashboard/akademik/e-learning/olah-kelas" exact component={AuthGuard(OlahKelas, 2)} />
          <Route path="/dashboard/akademik/e-learning/olah-jadwal" exact component={AuthGuard(OlahJadwal, 2)} />
          <Route path="/dashboard/akademik/e-learning/detail-olah-jadwal/:id" exact component={AuthGuard(DetailOlahJadwal, 2)} />
          <Route path="/dashboard/akademik/e-learning/detail-olah-materi/:id" exact>
            <DetailOlahMateri />
          </Route>
          <Route path="/dashboard/akademik/e-learning/materi-editor/kelas-jadwal/:kelasjadwalid" exact>
            <MateriEditor />
          </Route>
          <Route path="/dashboard/akademik/e-learning/materi-preview/kelas-jadwal/:kelasjadwalid/materi/:materiid" exact>
            <MateriPreview />
          </Route>
          <Route path="/dashboard/akademik/e-learning/olah-materi" exact>
            <OlahMateri />
          </Route>
          <Route path="/dashboard/akademik/e-learning/aktivasi-peserta" exact>
            <AktivasiPeserta />
          </Route>
          <Route path="/dashboard/akademik/e-learning/aktivasi-peserta/detail" exact>
            <DetailAktivasiPeserta />
          </Route>
          <Route path="/dashboard/akademik/e-learning/profile-peserta" exact>
            <ProfilePeserta />
          </Route>
          <Route path="/dashboard/akademik/e-learning/profile-peserta/detail" exact>
            <DetailProfilePeserta />
          </Route>
          <Route path="/dashboard/akademik/e-learning/rekap-absensi" exact component={AuthGuard(RekapAbsensi, 2)} />
          <Route path="/dashboard/akademik/e-learning/rekap-absensi/detail/:id" exact>
            <DetailRekapAbsensi />
          </Route>
          <Route path="/dashboard/akademik/e-learning/evaluasi-tutor" exact component={AuthGuard(EvaluasiTutor, 2)} />
          <Route path="/dashboard/akademik/e-learning/evaluasi-tutor/detail/:id" exact>
            <DetailEvaluasiTutor />
          </Route>
          <Route path="/dashboard/akademik/e-learning/evaluasi-tutor/detail-evaluasi/:id/kelas/:kelasId" exact>
            <DetailEvaluasiAkademik />
          </Route>
          <Route path="/dashboard/akademik/e-learning/jurnal-mengajar" exact component={AuthGuard(JurnalMengajarAkademik, 2)} />
          <Route path="/dashboard/akademik/e-learning/jurnal-mengajar/detail/:id" exact>
            <DetailJurnalMengajar />
          </Route>
          <Route path="/dashboard/akademik/e-learning/jurnal-mengajar/kelas/:kelasid/detail-jurnal/:id" exact>
            <DetailJurnalMengajar2 />
          </Route>
          <Route path="/dashboard/akademik/e-learning/jurnal-mengajar/detail-jurnal/profile/:id" exact>
            <DetailProfileJurnal />
          </Route>
          <Route path="/dashboard/akademik/e-learning/aktivasi-video" exact>
            <AktivasiVideo />
          </Route>

          {/* AKADEMIK E-COURSE */}
          <Route path="/dashboard/akademik/e-course/semua" exact component={AuthGuard(SemuaEcourse, 2)} />
          <Route path="/dashboard/akademik/e-course/olah" exact component={AuthGuard(OlahEcourse, 2)} />
          <Route path="/dashboard/akademik/e-course/kelola-video" exact>
            <KelolaVideo />
          </Route>
          <Route path="/dashboard/akademik/e-course/evaluasi-ecourse" exact component={AuthGuard(EvaluasiEcourse, 2)} />
          <Route path="/dashboard/akademik/e-course/detail-peserta" exact component={AuthGuard(DetailPesertaEcourse, 2)} />

          {/* <Route path="/dashboard" exact component={DashboardHomePage} />
          <Route path="/dashboard/profil-saya" exact component={DashboardProfilePage} />
          <Route path="/dashboard/course-tersedia" exact component={AvailableCourse} />
          <Route path="/dashboard/course-saya" exact component={MyCourse} />
          <Route path="/dashboard/tryout" exact component={AvailableTryOut} />
          <Route path="/dashboard/tryout-detail" exact component={DetailTO} />
          <Route path="/dashboard/payment" exact component={Payment} />
          <Route path="/dashboard/payment-process" exact component={ProsesBayar} />
          <Route path="/dashboard/tryout-saya" exact component={MyTryOut} />
          <Route path="/dashboard/detail-tryout-saya" exact component={DetailMyTO} />
          <Route path="/dashboard/kerjakan" exact component={Kerjakan} />
          <Route path="/dashboard/hasil-tryout" exact component={Result} />
          <Route path="/dashboard/tata-cara" exact component={TataCara} /> */}

          {/* Logistik Dashboard */}

          <Route path="/dashboard/logistik" exact component={AuthGuard(LogistikHomePage, 5)} />
          <Route path="/dashboard/logistik/profile-saya" exact component={MyProfileLogistik} />
          <Route path="/dashboard/logistik/informasi-kelas" exact component={InformasiKelasLogistik} />
          <Route path="/dashboard/logistik/informasi-resi/:id" exact component={InformasiResiLogistik} />
          <Route path="/dashboard/logistik/olah-resi/:id" exact component={OlahResiLogistik} />
          <Route path="/dashboard/logistik/olah-resi/:id/print" exact component={PrintResiLogistik} />
          <Route path="/dashboard/logistik/upload-data" exact component={UploadDataLogistik} />

          {/* END Logistik Dashboard */}

          {/* Akuntan Dashboard */}

          <Route path="/dashboard/akuntan" exact component={AuthGuard(AkuntanHomePage, 6)} />
          <Route path="/dashboard/akuntan/profile-saya" exact component={MyProfileAkuntan} />
          <Route path="/dashboard/akuntan/payment-ecourse" exact component={PaymentEcourseAkuntan} />
          <Route path="/dashboard/akuntan/payment-elearning" exact component={PaymentElearningAkuntan} />
          <Route path="/dashboard/akuntan/payment-tryout" exact component={PaymentTryoutAkuntan} />
          <Route path="/dashboard/akuntan/view-ecourse" exact component={ViewEcourseAkuntan} />
          <Route path="/dashboard/akuntan/view-elearning" exact component={ViewElearningAkuntan} />
          <Route path="/dashboard/akuntan/view-tryout" exact component={ViewTryoutAkuntan} />
          <Route path="/dashboard/akuntan/upload-data" exact component={UploadDataAkuntan} />

          {/* END Akuntan Dashboard */}

          {/* TUTOR DASHBOARD */}
          <Route path="/dashboard/tutor" exact component={AuthGuard(TutorHomePage, 3)} />
          <Route path="/dashboard/tutor/profile-saya" exact component={MyProfileTutor} />
          <Route path="/dashboard/tutor/upload-materi" exact component={AuthGuard(TutorOlahMateri, 3)} />
          <Route path="/dashboard/tutor/upload-materi/detail-olah-materi/:id" exact component={AuthGuard(TutorDetailOlahMateri, 3)} />
          <Route path="/dashboard/tutor/upload-materi/materi-editor/kelas-jadwal/:kelasjadwalid" exact component={AuthGuard(TutorMateriEditor, 3)} />
          <Route path="/dashboard/tutor/upload-materi/materi-editor/kelas-jadwal/:kelasjadwalid/materi/:materiid/preview" exact component={AuthGuard(TutorMateriPreview, 3)} />
          
          <Route path="/dashboard/tutor/data-kelas" exact component={AuthGuard(TutorDataKelasParticipant, 3)} />
          <Route path="/dashboard/tutor/data-kelas/:kelasid/data-peserta" exact component={AuthGuard(TutorDetailDataKelasParticipant, 3)} />
          <Route path="/dashboard/tutor/data-kelas/:kelasid/data-peserta/:userid" exact component={AuthGuard(TutorDetailProfilePeserta, 3)} />
          <Route path="/dashboard/tutor/data-kelas/absensi/:kelasid" component={AuthGuard(Absensi, 3)} exact />
          <Route path="/dashboard/tutor/data-kelas/absensi/input-absensi/:kelasid" component={AuthGuard(InputAbsensi, 3)} exact />
          <Route path="/dashboard/tutor/jurnal-mengajar" component={AuthGuard(JurnalMengajar, 3)} exact />
          <Route path="/dashboard/tutor/jurnal-mengajar/input-jurnal/:id" component={AuthGuard(InputJurnalMengajar, 3)} exact />
          
          <Route path="/dashboard/tutor/data-kelas-tutor" exact>
            <DataKelasTutor />
          </Route>
          <Route path="/dashboard/tutor/data-kelas/kelas-a" exact>
            <KelasDetail />
          </Route>
          
          
          <Route path="/dashboard/tutor/data-kelas/kelas-a/absensi" exact>
            <DetailRekapAbsensi />
          </Route>
          
          <Route path="/dashboard/tutor/data-honorarium" exact>
            <DataHonorarium />
          </Route>
          <Route path="/dashboard/tutor/profile-information" exact>
            <Akun />
          </Route>
          <Route path="/dashboard/tutor/rapor-tutor" exact>
            <RaporTutor/>
          </Route>
          <Route path="/dashboard/tutor/rapor-tutor/rekap-rapor" exact>
            <RekapRaporTutor/>
          </Route>
          <Route path="/dashboard/tutor/upload-materi" exact>
            <UploadMateri/>
          </Route>
          <Route path="/dashboard/tutor/upload-data" exact>
            <UploadDataTutor/>
          </Route>
          <Route path="/dashboard/soaltryout" exact>
            <SoalTryout/>
          </Route>
          {/* END TUTOR DASHBOARD */}

          {/* STUDENT DASHBOARD */}
          <Route 
            path="/dashboard" 
            exact 
            component={AuthGuard(DashboardHomePage, 4)} 
          />
          <Route path="/dashboard/transaction" exact component={AuthGuard(PaymentElearning, 4)} />
          <Route path="/dashboard/profil-saya" exact component={AuthGuard(DashboardProfilePage, 4)} />
          <Route path="/dashboard/course-tersedia" exact component={AuthGuard(AvailableCourse, 4)} />
          <Route path="/dashboard/course-saya" exact component={AuthGuard(MyCourse, 4)} />
          <Route path="/dashboard/tryout" exact component={AuthGuard(AvailableTryOut, 4)} />
          <Route path="/dashboard/tryout-detail/:id" exact component={AuthGuard(DetailTO, 4)} />
          <Route path="/dashboard/payment" exact 
          component={AuthGuard(Payment, 4)}
          />
          <Route path="/dashboard/course-saya/materi/:id" exact>
            <MyMateri/>
          </Route>
          <Route path="/dashboard/course-saya/materi/:id/akses-video" exact>
            <ViewMateriCourse/>
          </Route>
          <Route path="/dashboard/course-tersedia/beli" exact>
            <BuyCourse/>
          </Route>
          <Route path="/dashboard/course-tersedia/beli/pembayaran" exact>
            <TransactionCourse/>
          </Route>
          <Route path="/dashboard/course-tersedia/beli/pembayaran/proses" exact>
            <EcourseProsesBayar/>
          </Route>
          <Route path="/dashboard/course-tersedia/beli/pembayaran/sukses" exact>
            <EcourseProsesBayarSukses/>
          </Route>
          <Route path="/dashboard/course-tersedia/beli/pembayaran/selesai" exact>
            <TransactionDone/>
          </Route>
          <Route path="/dashboard/course-saya/akses-kelas" exact>
            <AksesCourse/>
          </Route>
          <Route path="/dashboard/testimoni" exact>
            <Testimoni/>
          </Route>
          <Route path="/dashboard/upload-data" exact>
            <UploadDataPeserta/>
          </Route>
          {/* TERBARU */}
          {/* <Route 
            path="/dashboard/soaltryout"
            exact 
            component={SoalTryout}
          /> */}
          <Route 
            path="/dashboard/hasil-pengerjaan"
            exact 
            component={AuthGuard(HasilPengerjaan, 4)}
          />
          
          <Route 
            path="/dashboard/e-learning/kelas-tersedia"
            exact 
            component={AuthGuard(KelasTersedia, 4)}
          />
          <Route 
            path="/dashboard/e-learning/kelas-tersedia/details/:id"
            exact 
            component={AuthGuard(DetailKelasTersedia, 4)}
          />
          <Route 
            path="/dashboard/e-learning/kelas-tersedia/bayar"
            exact 
            component={AuthGuard(BayarKelas, 4)}
          />
          <Route 
            path="/dashboard/e-learning/kelas-tersedia/status" 
            exact
            component={AuthGuard(StatusBayar, 4)}
          />
          <Route 
            path="/dashboard/e-learning/kelas-saya" 
            exact
            component={AuthGuard(KelasSaya, 4)}
          />
          <Route 
            path="/dashboard/e-learning/kelas-saya/details/:id" 
            exact
            component={AuthGuard(KelasSayaDetails, 4)}
          />
          <Route 
            path="/dashboard/e-learning/resi" 
            exact
            component={Resi}
          />
          <Route 
            path="/dashboard/e-learning/resi/cek-tracking/:resi" 
            exact
            component={CekTracking}
          />
          <Route 
            path="/dashboard/e-learning/e-library" 
            exact
            component={Elibrary}
          />
          <Route 
             path="/dashboard/e-learning/history-kelas" 
             exact
             component={HistoryKelas}
           />
           <Route 
             path="/dashboard/e-learning/evaluasi-kelas" 
             exact
             component={EvaluasiKelas}
           />
           <Route 
             path="/dashboard/e-learning/info-kelas/:id" 
             exact
             component={InfoKelas}
           />
           <Route
             path="/dashboard/e-learning/isi-evaluasi" 
             exact
             component={IsiEvaluasi}
           />
           <Route
             path="/dashboard/e-learning/kelas-saya/materi/akses-video" 
             exact
             component={AksesVideo}
           />
           <Route
             path="/dashboard/e-learning/kelas-saya/materi/akses-materi" 
             exact
             component={AuthGuard(AksesMateri, 4)}
           />
          <Route path="/dashboard/elearning-payment-process" exact component={AuthGuard(ElearningProsesBayar, 4)} />
          <Route path="/dashboard/elearning-payment-process/sukses" exact component={AuthGuard(ElearningProsesBayarSukses, 4)} />
          <Route path="/dashboard/payment-process" exact component={AuthGuard(ProsesBayar, 4)} />
          <Route path="/dashboard/payment-process/sukses" exact component={AuthGuard(ProsesBayarSukses, 4)} />
          <Route path="/dashboard/tryout-saya" exact component={AuthGuard(MyTryOut, 4)} />
          <Route path="/dashboard/detail-tryout-saya/:id" exact component={AuthGuard(DetailMyTO, 4)} />
          <Route path="/dashboard/kerjakan" exact component={AuthGuard(Kerjakan, 4)} />
          <Route path="/dashboard/hasil-tryout" exact component={AuthGuard(Result, 4)} />
          <Route path="/dashboard/hasil-tryout/:id" exact component={AuthGuard(ResultDetail, 4)} />
          <Route path="/dashboard/tata-cara" exact component={AuthGuard(TataCara, 4)} />
          {/* END STUDENT DASHBOARD */}

          {/* ADMIN DASHBOARD */}
          <Route path="/dashboard/admin" exact component={AuthGuard(AdminHomePage, 1)} />
          <Route path="/dashboard/admin/profile-saya" exact component={AuthGuard(MyProfileAdmin, 1)} />
          <Route path="/dashboard/admin/olah-program" exact component={AuthGuard(OlahProgramAdmin, 1)} />
          <Route path="/dashboard/admin/olah-regio" exact component={AuthGuard(OlahRegioAdmin, 1)} />
          <Route path="/dashboard/admin/olah-sub-regio" exact component={AuthGuard(OlahSubRegioAdmin, 1)} />
          <Route path="/dashboard/admin/olah-kategori" exact component={AuthGuard(OlahKategoriLibraryAdmin, 1)} />
          <Route path="/dashboard/admin/olah-library" exact component={AuthGuard(OlahLibraryAdmin, 1)} />
          <Route path="/dashboard/admin/olah-tutor" exact component={AuthGuard(OlahTutorAdmin, 1)} />
          <Route path="/dashboard/admin/users" exact component={AuthGuard(AdminUsers, 1)} />
          <Route path="/dashboard/admin/transactions" exact component={AuthGuard(AdminTransaction, 1)} />
          <Route path="/dashboard/admin/payment/ecourse" exact component={AuthGuard(PaymentEcourseAdmin, 1)} />
          <Route path="/dashboard/admin/payment/elearning" exact component={AuthGuard(PaymentElearningAdmin, 1)} />
          <Route path="/dashboard/admin/payment/tryout" exact component={AuthGuard(PaymentTryoutAdmin, 1)} />
          <Route path="/dashboard/admin/update-landing-page" exact component={AuthGuard(UpdateLandingPage, 1)} />
          <Route path="/dashboard/admin/pengumuman" exact component={AuthGuard(PengumumanAdmin, 1)} />

          {/* Informasi */}

          {/* Akuntan Payment detail */}
          <Route path="/dashboard/akuntan/elearning-payment-process" exact component={AuthGuard(ElearningProsesBayar, 6)} />
          <Route path="/dashboard/akuntan/elearning-payment-process/sukses" exact component={AuthGuard(ElearningProsesBayarSukses, 6)} />
          <Route path="/dashboard/akuntan/payment-process" exact component={AuthGuard(ProsesBayar, 6)} />
          <Route path="/dashboard/akuntan/payment-process/sukses" exact component={AuthGuard(ProsesBayarSukses, 6)} />
          <Route path="/dashboard/akuntan/course-tersedia/beli/pembayaran/proses" exact>
            <EcourseProsesBayar/>
          </Route>
          <Route path="/dashboard/akuntan/course-tersedia/beli/pembayaran/sukses" exact>
            <EcourseProsesBayarSukses/>
          </Route>

          <Route path="/dashboard/admin/informasi-kelas" exact component={AuthGuard(InformasiKelasAdmin, 1)} />
          <Route path="/dashboard/admin/informasi-tutor" exact component={AuthGuard(InformasiTutorAdmin, 1)} />
          <Route path="/dashboard/admin/informasi-tutor/penilaian/:id" exact component={AuthGuard(PenilaianTutorAdmin, 1)} />
          <Route path="/dashboard/admin/informasi-tutor/profile/:id" exact component={AuthGuard(ProfileTutorAdmin, 1)} />
          <Route path="/dashboard/admin/informasi-data-peserta/tryout" exact component={AuthGuard(InformasiDataPesertaTO, 1)} />
          <Route path="/dashboard/admin/informasi-data-peserta/elearning" exact component={AuthGuard(InformasiDataPesertaEL, 1)} />
          <Route path="/dashboard/admin/informasi-data-peserta/ecourse" exact component={AuthGuard(InformasiDataPesertaEC, 1)} />

          <Route path="/dashboard/admin/informasi-testimoni" exact component={AuthGuard(InformasiTestimoniAdmin, 1)} />
          <Route path="/dashboard/admin/informasi-upload-data" exact component={AuthGuard(InformasiUploadDataAdmin, 1)} />
          <Route path="/dashboard/admin/informasi-logistik" exact component={AuthGuard(InformasiLogistikAdmin, 1)} />
          <Route path="/dashboard/admin/informasi-ecourse" exact component={AuthGuard(InformasiEcourseAdmin, 1)} />
          <Route path="/dashboard/admin/informasi-tryout" exact component={AuthGuard(InformasiTryOutAdmin, 1)} />
          <Route path="/dashboard/admin/informasi-tryout/:id/subtryout" exact component={AuthGuard(InformasiSubTryOutAdmin, 1)} />
          <Route path="/dashboard/admin/informasi-tryout/:tryoutId/subtryout/:subtryoutId" exact component={AuthGuard(InformasiTryOutDetail, 1)} />
          <Route path="/dashboard/admin/informasi-tryout/detail/profile/:userId" exact component={AuthGuard(InformasiDetailPesertaAdmin, 1)} />
          <Route path="/dashboard/admin/informasi-tryout/detail/nilai/:id" exact component={AuthGuard(InformasiDetailNilaiAdmin, 1)} />
          <Route path="/dashboard/admin/informasi-elearning" exact component={AuthGuard(InformasiElearningAdmin, 1)} />
          {/* END ADMIN DASHBOARD */}
        </Switch>
        </React.Suspense>
      </Router>
    </div>
  );
}

export default App;