import Button from '@material-tailwind/react/Button';
import React, { useEffect, useState } from 'react';
import { FormGroup } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import { Link, useHistory } from 'react-router-dom';
import { SideBar } from '../../../components/layout/dashboard';
import { contactLink } from '../../../helpers/utils'
import ElearningService from '../../../services/elearning';
import CustomDatatable from '../../../components/common/CustomDatatable';
import moment from 'moment-timezone';
import Swal from 'sweetalert2';

export default function HistoryKelas() {
    document.title = "INGENIO Indonesia";

    const [isLoading, setIsLoading] = useState(true);
    const [hasNext, setHasNext] = useState(false)
    const [hasPrev, setHasPrev] = useState(false)
    const [count, setCount] = useState(0);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [keyword, setKeyword] = useState('');
    const [userId, setUserId] = useState('');
    const [kelasSaya, setKelasSaya] = useState([])
    const [elearningPrograms, setElearningPrograms] = useState([])
    const [idElearningProgram, setIdElearningProgram] = useState('');

    const history = useHistory();
    const [program, setProgram] = useState('');
    const dropdown = {
        borderRadius: "10px",
        height: 50,
        padding: "25px",
    };
    const columns = [
        {
            name: 'NO.',
            field: 'no',
            index: true,
            selector: row => row.no,
            sortable: true,
            // width: '6vw',
        },
        {
            name: 'NAMA KELAS',
            selector: row => row.kelas,
            render: true,
            component: (row) => {
                return (
                    <td className='main-table__bodyRow'>{row.kelas.nama_kelas}</td>
                )
            },
            sortable: true,
            // width: '15vw',
        },
        {
            name: 'PROGRAM BIMBINGAN',
            render: true,
            component: (row) => {
                return (
                    <td className='main-table__bodyRow'>{row.kelas.elearning_program.nama_program}</td>
                )
            },
            selector: row => row.program,
            sortable: true,
            // width: '15vw',
        },
        {
            name: 'Regio',
            render: true,
            component: (row) => {
                return (
                    <td className='main-table__bodyRow'>{row.kelas.nama_regio}</td>
                )
            },
            sortable: true,
            // width: '15vw',
        },
        {
            name: 'MASA BERLAKU',
            render: true,
            component: (row) => {
                return (
                    <td className='main-table__bodyRow'>{`${moment(row.kelas.elearning_program.tanggal_mulai).format('DD-MM-YYYY')} s/d ${moment(row.kelas.elearning_program.tanggal_akhir).format('DD-MM-YYYY')}`}</td>
                )
            },
            selector: row => row.masaberlaku,
            sortable: true,
            // width: '15vw',
        },
        {
            name: 'STATUS KELAS',
            render: true,
            component: (row) => {
                return (
                    <td className='main-table__bodyRow'>{`Tidak Aktif`}</td>
                )
            },
            selector: row => row.statuskelas,
            sortable: true,
        },
    ];
    const data = [
        {
            no: 1,
            kelas: 'Lorem ipsum.',
            program: 'Lorem ipsum.',
            topik: 'Lorem ipsum.',
            masaberlaku: 'Tgl s/d Tgl',
            statuskelas: 'Tidak Aktif',
        },
        {
            no: 2,
            kelas: 'Lorem ipsum.',
            program: 'Lorem ipsum.',
            topik: 'Lorem ipsum.',
            masaberlaku: 'Tgl s/d Tgl',
            statuskelas: 'Tidak Aktif',
        },
        {
            no: 3,
            kelas: 'Lorem ipsum.',
            program: 'Lorem ipsum.',
            topik: 'Lorem ipsum.',
            masaberlaku: 'Tgl s/d Tgl',
            statuskelas: 'Tidak Aktif',
        },
        {
            no: 4,
            kelas: 'Lorem ipsum.',
            program: 'Lorem ipsum.',
            topik: 'Lorem ipsum.',
            masaberlaku: 'Tgl s/d Tgl',
            statuskelas: 'Tidak Aktif',
        },
        {
            no: 5,
            kelas: 'Lorem ipsum.',
            program: 'Lorem ipsum.',
            topik: 'Lorem ipsum.',
            masaberlaku: 'Tgl s/d Tgl',
            statuskelas: 'Tidak Aktif',
        },
        {
            no: 6,
            kelas: 'Lorem ipsum.',
            program: 'Lorem ipsum.',
            topik: 'Lorem ipsum.',
            masaberlaku: 'Tgl s/d Tgl',
            statuskelas: 'Tidak Aktif',
        },
    ];

    const getListKelasHistory = (params = '') => {
        setIsLoading(true)
        ElearningService.getListKelasHistoryParticipant(params).then(resp => {
            if(resp.data.success === true) {
                const response = resp.data;
                setKelasSaya(response.data);

                setPage(response.meta.page)
                setPerPage(response.meta.perPage)
                setCount(response.meta.count)

                setHasNext(response.meta.hasNext)
                setHasPrev(response.meta.hasPrev);

                if(response.meta.userId) {
                    setUserId(response.meta.userId)
                }
            }
            setIsLoading(false)

            if(resp.data.code === 401){
                localStorage.clear()
                history.push('/signin');
                return;
            }
        }).catch(err => {
            setIsLoading(false)
            console.error(err)
        })
    }

    useEffect(() => {
        getListKelasHistory();
    }, [])

    return (
        <React.Fragment>
            <SideBar />
            <div className="md:ml-64" style={{ backgroundColor: '#F3F4F6' }}>
                {/* <div className="bg-orange px-3 md:px-8 py-1 h-40">
                    <div className="container md:pr-8 md:pl-10">
                        <h3 className="text-left tracking-wider title-dashboard">Selamat datang di Kelas Bimbingan Intensif Ingenio Indonesia</h3>
                        <br />
                        <h3 className="text-left tracking-wider">Anda dapat menghubungi Admin terlebih dahulu untuk memastikan kelas dan program yang Anda daftarkan. <br /> <br />Kontak Admin <a href={contactLink()} target={'_blank'}> <i class="fa fa-whatsapp" aria-hidden="true"></i></a></h3>
                    </div>
                </div> */}

                <div className="container md:pr-8 md:pl-10"  style={{position: 'absolute', top: '80px'}}>
                    <h3 className="text-left tracking-wider title-dashboard">Selamat datang di Kelas Bimbingan Intensif Ingenio Indonesia</h3>
                    <br />
                    <h3 className="text-left tracking-wider">Anda dapat menghubungi Admin terlebih dahulu untuk memastikan kelas dan program yang Anda daftarkan. <br /> <br />Kontak Admin <a href={contactLink()} target={'_blank'}> <i class="fa fa-whatsapp" aria-hidden="true"></i></a></h3>
                </div> 

                <div className="px-3 md:px-8 mt-4">
                    <div className="container mx-auto max-w-full">
                        <div className="row">
                            <div className="col-md-4 col-lg-4 col-xs-12 mb-4">
                                <form onSubmit={(e) => {
                                    e.preventDefault()
                                    getListKelasHistory(`page=${page}&perPage=${perPage}&keyword=${keyword}`)
                                }}>
                                    <div className="row" style={{ backgroundColor: '#FFF', padding: '1rem', borderRadius: '1rem' }}>
                                        <div className="col-md-9">
                                            <input 
                                                value={keyword}
                                                onChange={(e) => setKeyword(e.target.value)}
                                                type="text" name="" style={{ backgroundColor: '#F3F4F6', width: '100%', borderRadius: '8px', border: 'none !important', height: '45px', paddingLeft: '10px' }} placeholder="&#x1F50E;&#xFE0E; Nama Kelas atau keyword." id="" />
                                        </div>
                                        <div className="col-3">
                                            <Button type='submit' className="pilih-tryout">Cari</Button>
                                        </div>

                                    </div>

                                </form>
                            </div>
                        </div>
                        <div className="col-md-12 col-lg-12 col-xs-12 mt-3">
                            {/* <DataTable columns={columns} data={data} pagination /> */}
                            <CustomDatatable 
                                filteredItems={kelasSaya} 
                                columns={columns}
                                isLoading={isLoading}
                                page={page}
                                perPage={perPage}
                                hasPrev={hasPrev}
                                hasNext={hasNext}
                                count={count}
                                onTableChange={(e, data) => {
                                    switch (e) {
                                    case 'rowPerPageChange':
                                        setPerPage(data.perPage)
                                        getListKelasHistory(`page=${page}&perPage=${data.perPage}&keyword=${keyword}`)
                                        break;
                                    case 'rowPageChange':
                                        setPage(data.page)
                                        getListKelasHistory(`page=${data.page}&perPage=${perPage}&keyword=${keyword}`)
                                        break;
                                    
                                    default:
                                        break;
                                    }
                                }}
                            />
                        </div>
                    </div>
                </div>

            </div>
        </React.Fragment >

    )
}