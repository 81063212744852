import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import Card from "@material-tailwind/react/Card";
import CardHeader from "@material-tailwind/react/CardHeader";
import CardBody from "@material-tailwind/react/CardBody";
import CardFooter from "@material-tailwind/react/CardFooter";
import H5 from "@material-tailwind/react/Heading5";
import { Image } from 'react-bootstrap';
import { GrClose } from 'react-icons/gr';
import { Modal } from 'react-bootstrap';

import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { getProfileStudent } from '../../../store/actions/auth';
import tryoutService from '../../../services/tryout'
import { useLocation } from 'react-router';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip as ChartTooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import faker from 'faker';
import { Link, useParams } from 'react-router-dom';
import {
  SideBar
} from '../../../components/layout/dashboard';
import { getHasilTryout, getSubtryoutDetail, getUserRankingParticipant, getUserTryout } from '../../../store/actions/tryout';

function DetailHasilTryOut(props) {
  document.title = "INGENIO Indonesia";
  document.body.style.backgroundColor = "#F3F4F6";
  const [progressPercentage, setProgressPercentage] = useState(0)
  const [resultPerSubtryout, setResultPerSubtryout] = useState([]);
  const [dataProfile, setDataProfile] = useState({})
  const [dataSub, setDataSub] = useState({})
  const [jawaban, setJawaban] = useState([])
  const [jawabanPerBidang, setJawabanPerBidang] = useState([])
  const [jawabanPerTopik, setJawabanPerTopik] = useState([])
  const [jawabanPerKompetensi, setJawabanPerKompetensi] = useState([])
  const [labels, setLabels] = useState([]);
  const [dataPerTopik, setDataPerTopik] = useState([])
  const [detailTO, setDetailTO] = useState({})
  const [selectedSoal, setSelectedSoal] = useState({})
  const [selectedNumber, setSelectedNumber] = useState(1)
  const [showModal, setShowModal] = useState(false)
  const [jumlahPeserta, setJumlahPeserta] = useState(1);
  const [ranking, setRanking] = useState(1);
  const [waktuPengerjaan, setWaktuPengerjaan] = useState(0);
  const [durasi, setDurasi] = useState(0);

  const location = useLocation();
  const { id_tryout } = location.state.user;

  useEffect(() => {
    tryoutService.getHasilTryoutPesertaSummaryPerSubtryoutParticipant(id_tryout)
      .then(resp => {
        // alert(JSON.stringify(resp.data.data))
        setResultPerSubtryout(resp.data.data)
      }).catch(err => {
        console.error(err);
      })
  }, [])

  const showPembahasan = (nomor, id) => {
    setSelectedNumber(nomor)
    setSelectedSoal(jawaban.find((el) => el.id === id))
    return setShowModal(true)
  }

  const handleCloseModal = () => {
    return setShowModal(false)
  }

  const { dispatch } = props;

  const { id } = useParams();

  useEffect(() => {
    dispatch(getHasilTryout(id))
      .then(async (response) => {
        console.log(response);
        await setProgressPercentage(Math.round((response.data.total_nilai + Number.EPSILON) * 100) / 100);
        await setDataSub(response.data);
        await setJawabanPerBidang(response.data.nilai_per_bidang);
        await setJawabanPerKompetensi(response.data.nilai_per_kompetensi);
        await setJawabanPerTopik(response.data.nilai_per_topik);
        // await setLabels(response.data.nilai_per_topik.map((i) => i.nama));
        // let arrPerTopik = response.data.nilai_per_topik.map((i) => (
        //   {
        //     label: i.nama,
        //     data: i.nilai,
        //     backgroundColor: '#6366F1'
        //   }
        // ))
        await setJawaban(response.data.userhasiltryout_detail);
        setWaktuPengerjaan(response.data.userhasiltryout_summary.waktu_pengerjaan)
        // console.log(arrPerTopik)
        // return await setDataPerTopik(arrPerTopik);
      })
      .catch((err) => {
        console.log(err)
      })
  }, [labels[0]]);

  useEffect(() => {
    dispatch(getSubtryoutDetail(id))
      .then((response) => {
        
        const payloadSubTO = {
          id: response.data.tryout.id,
          nama: response.data.tryout.nama_subtryout,
          duration: response.data.duration,
          hasil_laboratorium: response.data.hasil_laboratorium,
          nama_tryout: response.data.tryout.nama_tryout,
          kode_tryout: response.data.tryout.kode_tryout,
        }
        setDetailTO(payloadSubTO)
      })
  }, [])

  useEffect(() => {
    dispatch(getProfileStudent())
      .then((response) => {
        console.log(response.data.profile);
        setDataProfile(response.data.profile);
      })
      .catch((err) => {
        console.log(err)
      })
  }, [])

  useEffect(() => {
    dispatch(getUserRankingParticipant(id))
      .then((response) => {
        console.log(response.data)
        setRanking(response.data.ranking)
        setJumlahPeserta(response.data.jumlah_peserta)
      })
      .catch(err => {
        console.log(err)
      })
  })

  useEffect(() => {
    dispatch(getUserTryout(detailTO.id))
      .then((response) => {
        console.log(response)
        // setJumlahPeserta(response.data.length)
      })
      .catch((err) => {
        console.log(err)
      })
  })

  return (
    <React.Fragment>
      <SideBar judul={'Detail Hasil TryOut'} />
      <div className="md:ml-64">
        {/* <div className="bg-orange px-3 md:px-8 py-1" style={{ height: '5rem' }}>
          <div className="container md:pr-8 md:pl-10">
          </div>
        </div> */}

        <div className="px-3 md:px-8">
          <div className="container max-w-full mx-auto flex items-center justify-between py-5 px-1">
            <Card
              className="-mt-24"
              style={{ borderRadius: '5rem !important' }}
            >
              <CardHeader className="bg-orange" style={{ backgroundColor: "#F8CA2B" }} size="lg">
                <H5 style={{ color: 'black', fontFamily: 'Quicksand' }}>Hasil Tryout - {detailTO.nama} - {detailTO.kode_tryout}</H5>
              </CardHeader>
              <CardBody>
                {/* DETAIL RANGKING */}
                <div className="mb-4 rounded-lg overflow-hidden flex-2 mx-auto py-4"
                  style={{
                    borderRadius: '2.5rem',
                    paddingLeft: '2rem',
                    paddingRight: "2rem"
                  }}
                >
                  <div className="py-5 px-3 font-medium flex justify-between" style={{ fontFamily: 'Quicksand' }}>
                    <div
                      className="flex-row w-9/12"
                      style={{ marginRight: '5rem' }}
                    >
                      <div className="py-3 font-medium flex-2 flex-col justify-between" style={{ fontFamily: 'Quicksand' }}>
                        <h1
                          style={{
                            fontSize: '1.5rem',
                            lineHeight: 1,
                            fontWeight: 700
                          }}
                          className="mb-2"
                        >Nilai</h1>
                        <OverlayTrigger
                          placement="top"
                          overlay={<Tooltip id="button-tooltip-2">{progressPercentage + '%'}</Tooltip>}
                        >
                          {({ ref, ...triggerHandler }) => (
                            <div class="progress my-3" {...triggerHandler} style={{ height: '2rem', backgroundColor: "#EF4444", borderRadius: '1rem', border: "3px solid black" }}>
                              <div
                                class="progress-bar progress-bar-success"
                                role="progressbar"
                                aria-valuenow={progressPercentage}
                                aria-valuemin="0"
                                aria-valuemax="100"
                                ref={ref}
                                style={{ width: progressPercentage + '%', background: '#4ADE80', borderRight: "1.25rem solid white" }}>
                              </div>
                            </div>
                          )}
                        </OverlayTrigger>
                        <h1
                          style={{
                            fontSize: '1.3rem',
                            lineHeight: 1,
                            fontWeight: 500,
                            textAlign: 'right'
                          }}
                          className="mb-2 text-right"
                        ><b>{Math.round((progressPercentage / 100) * dataSub.jumlah_soal)}</b> dari <b>{dataSub.jumlah_soal}</b> soal</h1>
                      </div>
                    </div>
                    <div
                      className="flex-col w-3/12 mx-auto"
                      style={{ textAlign: 'left' }}
                    >
                      <h1
                        style={{
                          fontSize: '1.5rem',
                          lineHeight: 1,
                          fontWeight: 700
                        }}
                        className="mb-3"
                      >Ranking</h1>
                      <h1
                        style={{
                          fontSize: '3.2rem',
                          lineHeight: 1,
                          fontWeight: 700
                        }}
                        className="my-4"
                      >#{ranking}</h1>
                      <h1
                        style={{
                          fontSize: '1.5rem',
                          lineHeight: 1,
                          fontWeight: 500
                        }}
                        className="mb-3"
                      >dari <b>{jumlahPeserta}</b> peserta</h1>
                    </div>
                  </div>
                  <div className="py-3 px-3 font-medium flex-2 flex-col justify-between" style={{ fontFamily: 'Quicksand' }}>
                      <table style={{marginBottom: '15px'}}>
                          <tr>
                              <td style={{padding: '0px 10px 0px 0px', fontSize: '1.5rem', fontWeight: 700}}>Nilai Anda</td>
                              <td style={{padding: '0px 10px 0px 0px', fontSize: '1.5rem', fontWeight: 700}}>:</td>
                              <td style={{padding: '0px', fontSize: '1.5rem', fontWeight: 700}}><b>{parseFloat(progressPercentage).toFixed(2)}</b></td>
                          </tr>
                          <tr>
                              <td style={{padding: '0px', fontSize: '1.5rem', fontWeight: 700}}>Passing Score</td>
                              <td style={{padding: '0px', fontSize: '1.5rem', fontWeight: 700}}>:</td>
                              <td style={{padding: '0px', fontSize: '1.5rem', fontWeight: 700}}><b>{dataSub.passing_grade}</b></td>
                          </tr>
                          <tr>
                              <td style={{padding: '0px', fontSize: '1.5rem', fontWeight: 700}}>Result</td>
                              <td style={{padding: '0px', fontSize: '1.5rem', fontWeight: 700}}>:</td>
                              <td style={{padding: '0px', fontSize: '1.5rem', fontWeight: 700}}><b>{dataSub.total_nilai >= dataSub.passing_grade ? 'Lulus' : 'Tidak Lulus'}</b></td>
                          </tr>
                          <tr>
                              <td style={{padding: '0px 10px 0px 0px', fontSize: '1.5rem', fontWeight: 700}}>Waktu pengerjaan</td>
                              <td style={{padding: '0px 10px 0px 0px', fontSize: '1.5rem', fontWeight: 700}}>:</td>
                              <td style={{padding: '0px', fontSize: '1.5rem', fontWeight: 700}}><b>{`${detailTO.duration - Math.floor(waktuPengerjaan / 60)} Menit`}</b></td>
                          </tr>
                      </table>
                    {/* <h1
                      style={{
                        fontSize: '1.5rem',
                        lineHeight: 1,
                        fontWeight: 700
                      }}
                      className="my-4 py-2"
                    >Nilai Anda : {parseFloat(progressPercentage).toFixed(2)}</h1>
                    <h1
                      style={{
                        fontSize: '1.5rem',
                        lineHeight: 1,
                        fontWeight: 700
                      }}
                      className="my-4 py-2"
                    >Passing Score : {dataSub.passing_grade}</h1>
                    <h1
                      style={{
                        fontSize: '1.5rem',
                        lineHeight: 1,
                        fontWeight: 700
                      }}
                      className="my-4 py-2"
                    >Result : {dataSub.total_nilai >= dataSub.passing_grade ? 'Lulus' : 'Tidak Lulus'}</h1> */}
                    {/* <h1
                      style={{
                        fontSize: '1.5rem',
                        lineHeight: 1,
                        fontWeight: 700
                      }}
                      className="my-4 py-2"
                    >Waktu Pengerjaan : 57 Menit</h1> */}
                  </div>
                </div>
                {/* DETAIL BERDASARKAN SUBTO */}
                <div className="shadow-md rounded-lg overflow-hidden flex-2 mx-auto mb-4 py-4"
                  style={{
                    backgroundColor: "#FFF",
                    border: "6px solid black",
                    borderRadius: '2.5rem',
                    paddingLeft: '10rem',
                    paddingRight: "10rem"
                  }}
                >
                  <div className="py-5 px-5 font-medium flex justify-center" style={{ fontFamily: 'Quicksand' }}>
                    <div
                      className="flex flex-col"
                      style={{ textAlign: 'center' }}
                    >
                      <h1
                        style={{
                          fontSize: '2rem',
                          lineHeight: 1,
                          fontWeight: 700,
                          textAlign: 'center'
                        }}
                        className="mb-2 text-center"
                      >Nilai Tryout - {detailTO.nama_tryout}</h1>
                    </div>
                  </div>
                  <div className="py-3 px-5 font-medium flex-2 flex-row-reverse justify-between" style={{ fontFamily: 'Quicksand' }}>
                    <div
                      className="container flex flex-row"
                    >
                      {
                          resultPerSubtryout.map((item, i) => {
                            return (
                              <div
                                className="flex flex-col flex-1 mx-auto justify-center"
                              >
                                <OverlayTrigger
                                  placement="top"
                                  overlay={<Tooltip id="button-tooltip-2">{parseFloat(item.total_nilai).toFixed(2) + '%'}</Tooltip>}
                                >
                                  {({ ref, ...triggerHandler }) => (
                                    <div 
                                      className="progress vertical my-3 mx-auto" 
                                      {...triggerHandler}
                                      style={{
                                        display: 'inline-block',
                                        position: 'relative',
                                        width: '20px',
                                        height: '25rem',
                                        backgroundColor: "#F3F4F6", 
                                        borderRadius: '1rem',
                                        boxShadow: "0px 5px 6px rgba(0, 0, 0, 0.25)",
                                        transform: 'rotate(180deg)'
                                      }}>
                                      <div 
                                        className="progress-bar progress-bar-success" 
                                        role="progressbar" 
                                        aria-valuenow={item.total_nilai} 
                                        aria-valuemin="0" 
                                        aria-valuemax="100"
                                        ref={ref}
                                        style={{
                                          height:item.total_nilai + '%', 
                                          borderRadius: '1rem',
                                          background: '#F8CA2B', 
                                          boxShadow: "2px 0px 6px rgba(0, 0, 0, 0.25)",
                                        }}>
                                      </div>
                                    </div>
                                  )}
                                </OverlayTrigger>
                                <h1
                                  style={{
                                    fontSize: '1.25rem',
                                    lineHeight: 1,
                                    fontWeight: 500,
                                    textAlign: 'center'
                                  }}
                                  className="mb-2 text-center"
                                >{item.subtryout.nama_subtryout}</h1>
                              </div>
                            )
                          })
                        }
                    </div>
                  </div>
                </div>
                {/* DETAIL BERDASARKAN KOMPETENSI */}
                <div className="shadow-md rounded-lg overflow-hidden flex-2 mx-auto mb-4 py-4"
                  style={{
                    backgroundColor: "#FFF",
                    border: "6px solid black",
                    borderRadius: '2.5rem',
                    paddingLeft: '10rem',
                    paddingRight: "10rem"
                  }}
                >
                  <div className="py-5 px-5 font-medium flex justify-center" style={{ fontFamily: 'Quicksand' }}>
                    <div
                      className="flex flex-col"
                      style={{ textAlign: 'center' }}
                    >
                      <h1
                        style={{
                          fontSize: '2rem',
                          lineHeight: 1,
                          fontWeight: 700,
                          textAlign: 'center'
                        }}
                        className="mb-2 text-center"
                      >Jawaban benar sesuai standar kompetensi</h1>
                    </div>
                  </div>
                  {jawabanPerKompetensi.map((i) =>
                    <div className="py-3 px-5 font-medium flex-2 flex-col justify-between" style={{ fontFamily: 'Quicksand' }}>
                      <div className="flex-2 flex-col">
                        <h1
                          style={{
                            fontSize: '1.5rem',
                            lineHeight: 1,
                            fontWeight: 500
                          }}
                          className="mb-2"
                        >Poin Standar Kompetensi <b>{`${i.nama} (${i.total_benar ? i.total_benar : '0'} / ${i.total})`}</b></h1>
                        <OverlayTrigger
                          placement="top"
                          overlay={<Tooltip id="button-tooltip-2">{parseFloat(i.nilai).toFixed(2) + '%'}</Tooltip>}
                        >
                          {({ ref, ...triggerHandler }) => (
                            <div
                              className="progress my-3"
                              {...triggerHandler}
                              style={{ height: '1rem', backgroundColor: "#f3f4f6", borderRadius: '1rem', boxShadow: "0px 5px 6px rgba(0, 0, 0, 0.25)", }}
                            >
                              <div
                                class="progress-bar progress-bar-success"
                                role="progressbar"
                                aria-valuenow={i.nilai}
                                aria-valuemin="0"
                                aria-valuemax="100"
                                ref={ref}
                                style={{
                                  width: i.nilai + '%',
                                  background: '#F8CA2B',
                                  borderRadius: '1rem',
                                }}>
                              </div>
                            </div>
                          )}
                        </OverlayTrigger>
                      </div>
                    </div>
                  )}
                </div>
                {/* DETAIL BERDASARKAN Bidang */}
                <div className="shadow-md rounded-lg overflow-hidden flex-2 mx-auto mb-4 py-4"
                  style={{
                    backgroundColor: "#FFF",
                    border: "6px solid black",
                    borderRadius: '2.5rem',
                    paddingLeft: '10rem',
                    paddingRight: "10rem"
                  }}
                >
                  <div className="py-5 px-5 font-medium flex justify-center" style={{ fontFamily: 'Quicksand' }}>
                    <div
                      className="flex flex-col"
                      style={{ textAlign: 'center' }}
                    >
                      <h1
                        style={{
                          fontSize: '2rem',
                          lineHeight: 1,
                          fontWeight: 700,
                          textAlign: 'center'
                        }}
                        className="mb-2 text-center"
                      >Jawaban benar sesuai standar bidang</h1>
                    </div>
                  </div>
                  {jawabanPerTopik.map((i) =>
                    <div className="py-3 px-5 font-medium flex-2 flex-col justify-between" style={{ fontFamily: 'Quicksand' }}>
                      <div className="flex-2 flex-col">
                        <h1
                          style={{
                            fontSize: '1.5rem',
                            lineHeight: 1,
                            fontWeight: 500
                          }}
                          className="mb-2"
                        >{`${i.nama} (${i.total_benar ? i.total_benar : '0'} / ${i.total})`}</h1>
                        <OverlayTrigger
                          placement="top"
                          overlay={<Tooltip id="button-tooltip-2">{parseFloat(i.nilai).toFixed(2) + '%'}</Tooltip>}
                        >
                          {({ ref, ...triggerHandler }) => (
                            <div
                              className="progress my-3"
                              {...triggerHandler}
                              style={{ height: '1rem', backgroundColor: "#f3f4f6", borderRadius: '1rem', boxShadow: "0px 5px 6px rgba(0, 0, 0, 0.25)", }}
                            >
                              <div
                                class="progress-bar progress-bar-success"
                                role="progressbar"
                                aria-valuenow={i.nilai}
                                aria-valuemin="0"
                                aria-valuemax="100"
                                ref={ref}
                                style={{
                                  width: i.nilai + '%',
                                  background: '#F8CA2B',
                                  borderRadius: '1rem',
                                }}>
                              </div>
                            </div>
                          )}
                        </OverlayTrigger>
                      </div>
                    </div>
                  )}
                </div>
                {/* DETAIL BERDASARKAN TOPIK */}
                <div className="shadow-md rounded-lg overflow-hidden flex-2 mx-auto mb-4 py-4"
                  style={{
                    backgroundColor: "#FFF",
                    border: "6px solid black",
                    borderRadius: '2.5rem',
                    paddingLeft: '10rem',
                    paddingRight: "10rem"
                  }}
                >
                  <div className="py-5 px-5 font-medium flex justify-center" style={{ fontFamily: 'Quicksand' }}>
                    <div
                      className="flex flex-col"
                      style={{ textAlign: 'center' }}
                    >
                      <h1
                        style={{
                          fontSize: '2rem',
                          lineHeight: 1,
                          fontWeight: 700,
                          textAlign: 'center'
                        }}
                        className="mb-2 text-center"
                      >Jawaban benar sesuai standar topik</h1>
                    </div>
                  </div>
                  <div className="py-4">
                    {/* <Bar options={options} data={data} style={{fontFamily: 'Quicksand'}}/> */}
                    {jawabanPerBidang.map((i) =>
                      <div className="py-3 px-5 font-medium flex-2 flex-col justify-between" style={{ fontFamily: 'Quicksand' }}>
                        <div className="flex-2 flex-col">
                          <h1
                            style={{
                              fontSize: '1.5rem',
                              lineHeight: 1,
                              fontWeight: 500
                            }}
                            className="mb-2"
                          >{`${i.nama} (${i.total_benar ? i.total_benar : '0'} / ${i.total})`}</h1>
                          <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip id="button-tooltip-2">{parseFloat(i.nilai).toFixed(2) + '%'}</Tooltip>}
                          >
                            {({ ref, ...triggerHandler }) => (
                              <div
                                className="progress my-3"
                                {...triggerHandler}
                                style={{ height: '1rem', backgroundColor: "#f3f4f6", borderRadius: '1rem', boxShadow: "0px 5px 6px rgba(0, 0, 0, 0.25)", }}
                              >
                                <div
                                  class="progress-bar progress-bar-success"
                                  role="progressbar"
                                  aria-valuenow={i.nilai}
                                  aria-valuemin="0"
                                  aria-valuemax="100"
                                  ref={ref}
                                  style={{
                                    width: i.nilai + '%',
                                    background: '#F8CA2B',
                                    borderRadius: '1rem',
                                  }}>
                                </div>
                              </div>
                            )}
                          </OverlayTrigger>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                {/* REVIEW SOAL */}
                <div className="shadow-md rounded-lg overflow-hidden flex-2 mx-auto mb-4 py-4"
                  style={{
                    backgroundColor: "#F3F4F6",
                    borderRadius: '2.5rem',
                    paddingLeft: '5rem',
                    paddingRight: "5rem"
                  }}
                >
                  <div className="py-5 font-medium flex flex-col justify-between" style={{ fontFamily: 'Quicksand' }}>
                    <div className="flex flex-row mr-3">
                      <div
                        className="flex flex-col mx-4 justify-center"
                        style={{ textAlign: 'left' }}
                      >
                        <h1
                          style={{
                            fontSize: '1.75rem',
                            lineHeight: 1,
                            fontWeight: 700
                          }}
                          className="mb-3"
                        >Review Soal</h1>
                        <h1
                          style={{
                            fontSize: '1rem',
                            lineHeight: 1,
                            fontWeight: 500
                          }}
                          className="mb-3"
                        >Klik untuk melihat soal, jawaban dan pembahasan</h1>
                      </div>
                    </div>
                    <div className="container">
                      <div
                        className="grid grid-cols-10"
                        style={{ gridTemplateColumns: "repeat(10, minmax(0, 1fr))" }}
                      >
                        {jawaban.map((i, idx) => (
                          i.is_answer_true ?
                            <button
                              className='mx-auto'
                              style={{
                                backgroundColor: '#4ADE80',
                                fontFamily: 'Quicksand',
                                fontStyle: 'normal',
                                fontWeight: 500,
                                fontSize: '2rem',
                                width: '4rem',
                                height: '4rem',
                                lineHeight: '2rem',
                                letterSpacing: '0.06em',
                                color: 'black',
                                borderRadius: '8px',
                                padding: '10px',
                                marginTop: '0.7rem',
                              }}
                              key={idx}
                              onClick={() => showPembahasan(idx + 1, i.id)}
                            >
                              {idx + 1}
                            </button> :
                            <button
                              className='mx-auto'
                              style={{
                                backgroundColor: '#F87171',
                                fontFamily: 'Quicksand',
                                fontStyle: 'normal',
                                fontWeight: 500,
                                fontSize: '2rem',
                                width: '4rem',
                                height: '4rem',
                                lineHeight: '2rem',
                                letterSpacing: '0.06em',
                                color: 'black',
                                borderRadius: '8px',
                                padding: '10px',
                                marginTop: '0.7rem',
                              }}
                              key={idx}
                              onClick={() => showPembahasan(idx + 1, i.id)}
                            >
                              {idx + 1}
                            </button>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </CardBody>
            </Card>
          </div>
        </div>
        <Modal
          show={showModal}
          onHide={handleCloseModal}
          size="xl"
          centered
        >
          <Modal.Header
            closeButton
            style={{
              boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.25)'
            }}
          >
            <Modal.Title
              style={{ fontFamily: 'Quicksand' }}
            >
              Soal No. {selectedNumber}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="container mx-auto max-w-full">
              <div className="block px-5 py-2">
              {
                  selectedSoal.gambar_soal ?
                    <div className="flex">
                      <img
                        src={selectedSoal.gambar_soal}
                        alt={'Gambar Soal No. ' + selectedNumber}
                        effect="blur"
                      />
                    </div> : null
                }
                <div
                  className="flex w-full mx-auto xl:mx-0 mb-2 p-4"
                  style={{ fontFamily: 'Quicksand', borderRadius: '10px', backgroundColor: '#f3f4f6', fontSize: '1.25rem' }}
                  dangerouslySetInnerHTML={{ __html: selectedSoal.soal }}
                >
                </div>
                <hr className="my-4" />
                <div className="flex flex-col w-full mx-auto xl:mx-0 mb-2">
                  {
                    selectedSoal.is_answer_true ?
                      <div className="flex flex-row p-4" style={{ borderRadius: '10px', backgroundColor: '#f3f4f6', }}>
                        <div className="flex flex-col mx-auto justify-center">
                          <h1 style={{ color: '#4ADE80', textAlign: 'center' }}>Jawaban Anda:</h1>
                          <button
                            className='mx-auto'
                            style={{
                              backgroundColor: '#4ADE80',
                              fontFamily: 'Quicksand',
                              fontStyle: 'normal',
                              fontWeight: 500,
                              fontSize: '2rem',
                              width: '4rem',
                              height: '4rem',
                              lineHeight: '2rem',
                              letterSpacing: '0.06em',
                              color: 'black',
                              borderRadius: '8px',
                              padding: '10px',
                              marginTop: '0.7rem',
                            }}
                          >
                            {selectedSoal.jawaban_benar}
                          </button>
                          {selectedSoal.jawaban_benar_desc}
                          {
                            selectedSoal.jawaban_benar_image ?
                              <div className="flex">
                                <img
                                  src={selectedSoal.jawaban_benar_image}
                                  alt={'Gambar Soal No. ' + selectedNumber}
                                  effect="blur"
                                />
                              </div> : null
                          }
                          
                        </div>
                      </div> :
                      <div className="flex flex-row p-4" style={{ borderRadius: '10px', backgroundColor: '#f3f4f6', }}>
                        <div className="flex flex-col mx-auto justify-center">
                          <h1 style={{ color: '#F87171', textAlign: 'center' }}>Jawaban Anda:</h1>
                          <button
                            className='mx-auto'
                            style={{
                              backgroundColor: '#F87171',
                              fontFamily: 'Quicksand',
                              fontStyle: 'normal',
                              fontWeight: 500,
                              fontSize: '2rem',
                              width: '4rem',
                              height: '4rem',
                              lineHeight: '2rem',
                              letterSpacing: '0.06em',
                              color: 'black',
                              borderRadius: '8px',
                              padding: '10px',
                              marginTop: '0.7rem',
                            }}
                          >
                            {selectedSoal.jawaban_peserta}
                          </button>
                          {selectedSoal.jawaban_peserta_desc}
                          {
                            selectedSoal.jawaban_peserta_image ?
                              <div className="flex">
                                <img
                                  src={selectedSoal.jawaban_peserta_image}
                                  alt={'Gambar Soal No. ' + selectedNumber}
                                  effect="blur"
                                />
                              </div> : null
                          }
                        </div>
                        <div className="flex flex-col mx-auto justify-center" >
                          <h1 style={{ color: '#4ADE80', textAlign: 'center' }}>Jawaban Benar:</h1>
                          <button
                            className='mx-auto'
                            style={{
                              backgroundColor: '#4ADE80',
                              fontFamily: 'Quicksand',
                              fontStyle: 'normal',
                              fontWeight: 500,
                              fontSize: '2rem',
                              width: '4rem',
                              height: '4rem',
                              lineHeight: '2rem',
                              letterSpacing: '0.06em',
                              color: 'black',
                              borderRadius: '8px',
                              padding: '10px',
                              marginTop: '0.7rem',
                            }}
                          >
                            {selectedSoal.jawaban_benar}
                          </button>
                          {selectedSoal.jawaban_benar_desc}
                          {
                            selectedSoal.jawaban_benar_image ?
                              <div className="flex">
                                <img
                                  src={selectedSoal.jawaban_benar_image}
                                  alt={'Gambar Soal No. ' + selectedNumber}
                                  effect="blur"
                                />
                              </div> : null
                          }
                        </div>
                      </div>
                  }
                </div>
                {selectedSoal.pembahasan ?
                  <div>
                    <hr className="my-4" />
                    <h5 class="mb-4" style={{ fontSize: '1.25rem', fontFamily: 'Quicksand', fontWeight: 'bold' }}>
                      Pembahasan
                    </h5>
                    <div className="flex w-full mx-auto xl:mx-0 mb-2 p-4" style={{ borderRadius: '10px', backgroundColor: '#f3f4f6', }}>
                      <div className={selectedSoal.gambar_pembahasan ? "w-9/12 flex px-4" : "w-100 flex px-4"}>
                        <h1 style={{ fontFamily: 'Quicksand' }}>{selectedSoal.pembahasan}</h1>
                      </div>
                      {
                        selectedSoal.gambar_pembahasan ?
                          <div className="flex">
                            <img
                              src={selectedSoal.gambar_pembahasan}
                              alt={'Gambar Pembahasan Soal No. ' + selectedNumber}
                              effect="blur"
                            />
                          </div> : null
                      }
                    </div>
                  </div> : null
                }

              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </React.Fragment>

  )
}

export default connect()(DetailHasilTryOut)