import Button from '@material-tailwind/react/Button';
import React, { useState, useEffect } from 'react';
import { FormGroup, FormControl, Form, Modal, Row, Col } from 'react-bootstrap';
import { kerjakanSoal, getSubtryoutDetail, submitAnswer } from '../../../store/actions/tryout';
import Swal from 'sweetalert2';
import { Link, NavLink, useLocation, useHistory } from 'react-router-dom';
import { BsPencilSquare, BsFillTrashFill, BsFillPlusCircleFill, BsPen, BsUpload, BsChevronLeft, BsCloudUploadFill, BsFillEyeFill, BsFillCircleFill, BsChevronRight } from 'react-icons/bs';
import { connect } from 'react-redux';
import moment from 'moment';
import Countdown from 'react-countdown';
import { FaEye, FaEyeSlash } from 'react-icons/fa';

function SoalTryout(props) {
    document.title = "INGENIO Indonesia";
    document.body.style.backgroundColor = "#F3F4F6";
    const [viewHasilLab, setViewHasilLab] = useState('0');
    const [detailSubTO, setDetailSubTO] = useState({});
    const [duration, setDuration] = useState('');
    const [timer, setTimer] = useState(0);
    const [selectedNumber, setSelectedNumber] = useState(1);
    const [topik, setTopik] = useState('');
    const [kompetensi, setKompetensi] = useState('');
    const [bidang, setBidang] = useState('');
    const [soal, setSoal] = useState('');
    const [pembahasan, setPembahasan] = useState('');
    const [jawaban, setJawaban] = useState([]);
    const [gambarSoal, setGambarSoal] = useState(null);
    const [gambarPembahasan, setGambarJawaban] = useState(null);
    const [hasNext, setHasNext] = useState(true);
    const [hasPrevious, setHasPrevious] = useState(true);
    const [jumlahSoal, setJumlahSoal] = useState([]);
    const [chosenJawaban, setChosenJawaban] = useState('');
    const [idBank, setIdBank] = useState(0);
    const [jawabanExisting, setJawabanExisting] = useState(JSON.parse(localStorage.getItem('jawaban')))
    const [showTime, setShowTime] = useState(true);
    const [isRaguRagu, setIsRaguRagu] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [isEligibleAnswer, setIsEligibleAnswer] = useState(false)

    const dropdown = {
        borderRadius: "10px",
        height: 50,
        padding: "25px",
    };

    function useQuery() {
        const { search } = useLocation();

        return React.useMemo(() => new URLSearchParams(search), [search]);
    }
    const query = useQuery()

    const { dispatch } = props;
    const history = useHistory();

    useEffect(() => {
        let id = query.get('sub');
        let jawabanExist = JSON.parse(localStorage.getItem('jawaban'));
        setJawabanExisting(jawabanExist);
        dispatch(kerjakanSoal(Number(id), 1))
            .then(async (response) => {
                console.log(response.data)
                let totalSoal = response.data.totalSoal;
                let arr = [];
                for (let i = 1; i <= totalSoal; i++) {
                    let obj = {
                        nomor: i,
                        terjawab: false,
                        ragu: false,
                    }
                    if (jawabanExist) {
                        for (let j = 0; j < jawabanExist.length; j++) {
                            if (jawabanExist[j].nomor === obj.nomor) {
                                if(jawabanExist[j].jawaban_peserta !== null) {
                                    obj.terjawab = true
                                }
                                
                                if (jawabanExist[j].ragu === true) {
                                    obj.ragu = true
                                }
                            }
                            
                            if (jawabanExist[j].nomor == 1) {
                                setChosenJawaban(jawabanExist[j].jawaban_peserta)
                            }
                            if(jawabanExist[j].nomor == selectedNumber) {
                                if (jawabanExist[j].ragu === true) {
                                    setIsRaguRagu(true)
                                }
                            }
                        }
                    }
                    arr.push(obj);
                }
                setIsEligibleAnswer(response.data.isEligibleAnswer)
                await setIdBank(response.data.contentSoal.banksoal_id)
                await setJumlahSoal(arr);
                await setTopik(response.data.contentSoal.topik);
                await setKompetensi(response.data.contentSoal.kompetensi);
                await setBidang(response.data.contentSoal.bidang);
                await setSoal(response.data.contentSoal.soal);
                await setJawaban(response.data.contentSoal.pilihan_jawaban);
                await setPembahasan(response.data.contentSoal.pilihan_jawaban);
                await setHasNext(response.data.hasNext);
                await setHasPrevious(response.data.hasPrevious);
                setDuration(response.data.finishDateTimeUTC);
            })
            .catch((err) => {
                console.log(err)
            })
    }, [])

    useEffect(() => {
        let id = query.get('sub');
        dispatch(getSubtryoutDetail(id))
            .then(async (response) => {
                const payloadSubTO = {
                    id: response.data.id,
                    nama: response.data.nama_subtryout,
                    duration: response.data.duration,
                    hasil_laboratorium: response.data.hasil_laboratorium,
                    nama_tryout: response.data.tryout.nama_tryout,
                    kode_tryout: response.data.tryout.kode_tryout,
                }
                return await setDetailSubTO(payloadSubTO)
            })
    }, [])

    // PERHITUNGAN WAKTU
    const calculateTimeLeft = () => {
        let timeLeft = '';
        if (duration === '') {
            return timeLeft
        }
        let difference = moment.utc(duration) - moment.utc();
        // let difference = +new Date(`10/01/2022`) - +new Date();

        if (difference > 0) {
            let duration = moment.duration(difference, 'milliseconds')
            timeLeft = duration.hours() + ":" + duration.minutes() + ":" + duration.seconds()
        } else {
            setIsEligibleAnswer(false)
            // Swal.fire({
            //     title: 'Waktu telah habis',
            //     text: 'Jawaban Anda akan ter-submit otomatis',
            //     iconHtml: '<img src="/assets/logo/logo-jam.png">',
            //     showConfirmButton: true,
            //     confirmButtonText: 'Ya',
            //     showCancelButton: false,
            // });
            Swal.fire({
                title: 'Waktu telah habis',
                text: 'Anda sudah tidak bisa mengisi / mengubah jawaban!',
                icon: 'question',
                iconColor: 'red',
                showConfirmButton: true,
                confirmButtonText: 'Ya, Submit !',
                showCancelButton: true,
                cancelButtonText: 'Kembali',
            }).then((result) => {
                if (result.isConfirmed) {
                    setIsLoading(true)
                    let jawabanExist = JSON.parse(localStorage.getItem('jawaban'));
                    let id = query.get('sub');
                    let payload = {
                        subtryout_id: id,
                        answers: jawabanExist,
                    }
                    dispatch(submitAnswer(payload))
                        .then((response) => {
                            setIsLoading(false)
                            localStorage.setItem('jawaban', JSON.stringify([]));
                            history.push('/dashboard/hasil-pengerjaan?sub=' + id)
                        }).catch((err) => {
                            setIsLoading(false)
                            Swal.fire({
                                title: 'Gagal submit tryout',
                                text: `Silahkan Coba lagi nanti`,
                                iconHtml: '<img src="/assets/logo/logo-jam.png">',
                                showConfirmButton: true,
                                confirmButtonText: 'Ya',
                                showCancelButton: false,
                            });
                        })
                }
            })
        }

        return timeLeft;
    }

    useEffect(() => {
        const timer = setTimeout(() => {
            setTimer(calculateTimeLeft());
        }, 1000);

        return () => clearTimeout(timer);
    });
    // END PERHITUNGAN WAKTU

    const gantiSoal = (nomor) => {
        setIsRaguRagu(false)
        setSelectedNumber(nomor)
        setChosenJawaban('')
        let jawabanExist = JSON.parse(localStorage.getItem('jawaban'));
        // console.log(jawabanExist)
        let id = query.get('sub');
        setJawabanExisting(jawabanExist);
        dispatch(kerjakanSoal(Number(id), nomor))
            .then(async (response) => {
                
                let totalSoal = response.data.totalSoal;
                let arr = [];
                for (let i = 1; i <= totalSoal; i++) {
                    let obj = {
                        nomor: i,
                        terjawab: false,
                        ragu: false,
                    }
                    if (jawabanExist) {
                        for (let j = 0; j < jawabanExist.length; j++) {
                            if (jawabanExist[j].nomor === obj.nomor) {
                                if(jawabanExist[j].jawaban_peserta !== null) {
                                    obj.terjawab = true
                                }
                                if (jawabanExist[j].ragu === true) {
                                    obj.ragu = true
                                }
                            }
                            
                            if (jawabanExist[j].nomor == nomor) {
                                setChosenJawaban(jawabanExist[j].jawaban_peserta)
                            }

                            if(jawabanExist[j].nomor == nomor) {
                                if (jawabanExist[j].ragu === true) {
                                    setIsRaguRagu(true)
                                }
                            }
                        }
                        arr.push(obj);
                    } else {
                        arr.push(obj);
                    }
                    console.log(arr);
                }
                await setIdBank(response.data.contentSoal.banksoal_id)
                setJumlahSoal(arr);
                await setTopik(response.data.contentSoal.topik);
                setGambarSoal(response.data.contentSoal.gambar_soal)
                await setKompetensi(response.data.contentSoal.kompetensi);
                await setBidang(response.data.contentSoal.bidang);
                await setSoal(response.data.contentSoal.soal);
                await setJawaban(response.data.contentSoal.pilihan_jawaban);
                await setPembahasan(response.data.contentSoal.pilihan_jawaban);
                await setHasNext(response.data.hasNext);
                await setHasPrevious(response.data.hasPrevious);
                setDuration(response.data.finishDateTimeUTC);
            })
            .catch((err) => {
                console.log(err)
            })
    }

    const simpanJawaban = (nomorSoal, nomor, jawabanSoal, isRagu = false) => {
        if (!isEligibleAnswer) {
            return
        }
        setChosenJawaban(jawabanSoal);
        let jawabanExist = JSON.parse(localStorage.getItem('jawaban'));
        let arr = [];
        let obj = {
            nomor: nomorSoal,
            id_banksoal: nomor,
            jawaban_peserta: jawabanSoal,
            ragu: isRagu,
        }
        if (!jawabanExist) {
            arr.push(obj)
        } else {
            arr = jawabanExist;
            let flag = false;
            for (let i = 0; i < jawabanExist.length; i++) {
                if (arr[i].nomor == nomorSoal) {
                    arr[i].ragu = isRagu;
                    if(jawabanSoal !== null)
                        arr[i].jawaban_peserta = jawabanSoal;
                    flag = true;
                }
            }
            if (!flag) {
                arr.push(obj)
            }
        }
        console.log(jawabanExist)
        const jawabanExistBaru = localStorage.setItem('jawaban', JSON.stringify(arr));
        setJawabanExisting(jawabanExistBaru);

        if (!hasNext) {
            Swal.fire({
                title: 'Apakah anda sudah selesai mengerjakan soal tryout ini?',
                text: 'Pastikan semua jawaban sudah terisi !',
                icon: 'question',
                iconColor: 'red',
                showConfirmButton: true,
                confirmButtonText: 'Ya, Submit !',
                showCancelButton: true,
                cancelButtonText: 'Kembali ke nomer 1',
            }).then((result) => {
                if (result.isConfirmed) {
                    let jawabanExist = JSON.parse(localStorage.getItem('jawaban'));
                    let id = query.get('sub');
                    let payload = {
                        subtryout_id: id,
                        answers: jawabanExist,
                    }
                    dispatch(submitAnswer(payload))
                        .then((response) => {
                            localStorage.setItem('jawaban', JSON.stringify([]));
                            history.push('/dashboard/hasil-pengerjaan?sub=' + id)
                        }).catch((err) => {
                            alert(err);
                        })
                }

                gantiSoal(1)

            })
        }
        // console.log(nomor, jawaban);
        // return dispatch(simpanJawaban(nomor, jawabanSoal))
    }

    const SubmitTryout = () => {
        let id = query.get('sub');
        Swal.fire({
            title: 'Apakah Anda Sudah Yakin ?',
            text: 'Pastikan semua jawaban sudah terisi !',
            icon: 'question',
            iconColor: 'red',
            showConfirmButton: true,
            confirmButtonText: 'Ya, Submit !',
            showCancelButton: true,
            cancelButtonText: 'Kembali',
        }).then((result) => {
            if (result.isConfirmed) {
                setIsLoading(true)
                let jawabanExist = JSON.parse(localStorage.getItem('jawaban'));
                let id = query.get('sub');
                let payload = {
                    subtryout_id: id,
                    answers: jawabanExist,
                }
                dispatch(submitAnswer(payload))
                    .then((response) => {
                        setIsLoading(false)
                        localStorage.setItem('jawaban', JSON.stringify([]));
                        history.push('/dashboard/hasil-pengerjaan?sub=' + id)
                    }).catch((err) => {
                        setIsLoading(false)
                        Swal.fire({
                            title: 'Gagal submit tryout',
                            text: `Silahkan Coba lagi nanti`,
                            iconHtml: '<img src="/assets/logo/logo-jam.png">',
                            showConfirmButton: true,
                            confirmButtonText: 'Ya',
                            showCancelButton: false,
                        });
                    })
            }
        })
    }

    const renderJumlahSoal = (data, idx) => {
        if(selectedNumber === data.nomor) {
            return <Button
            className='mx-1'
                style={{
                    backgroundColor: '#FACC15',
                    fontFamily: 'Quicksand',
                    fontStyle: 'normal',
                    fontWeight: 'bold',
                    fontSize: '1rem',
                    lineHeight: '1.25rem',
                    letterSpacing: '0.06em',
                    color: 'black',
                    borderRadius: '8px',
                    padding: '10px',
                    marginTop: '0.7rem',
                }}
                key={idx}
                onClick={() => gantiSoal(data.nomor)}>
                {data.nomor}
            </Button>
        }
        if(data.ragu) {
            return <Button
                className='mx-3'
                style={{
                    backgroundColor: 'red',
                    fontFamily: 'Quicksand',
                    fontStyle: 'normal',
                    fontWeight: 'bold',
                    fontSize: '1rem',
                    lineHeight: '1.25rem',
                    letterSpacing: '0.06em',
                    color: 'black',
                    borderRadius: '8px',
                    padding: '10px',
                    marginTop: '0.7rem',
                }}
                key={idx}
                onClick={() => gantiSoal(data.nomor)}>
                {data.nomor}
            </Button>
        }

        if(data.terjawab) {
            return <Button
                className='mx-3'
                style={{
                    backgroundColor: '#22C55E',
                    fontFamily: 'Quicksand',
                    fontStyle: 'normal',
                    fontWeight: 'bold',
                    fontSize: '1rem',
                    lineHeight: '1.25rem',
                    letterSpacing: '0.06em',
                    color: 'black',
                    borderRadius: '8px',
                    padding: '10px',
                    marginTop: '0.7rem',
                }}
                key={idx}
                onClick={() => gantiSoal(data.nomor)}
            >{data.nomor}</Button>
        }

        return <Button
            className='mx-3'
            style={{
                backgroundColor: '#E5E7EB',
                fontFamily: 'Quicksand',
                fontStyle: 'normal',
                fontWeight: 'bold',
                fontSize: '1rem',
                lineHeight: '1.25rem',
                letterSpacing: '0.06em',
                color: 'black',
                borderRadius: '8px',
                padding: '10px',
                marginTop: '0.7rem',
            }}
            key={idx}
            onClick={() => gantiSoal(data.nomor)}>
            {data.nomor}
        </Button>
    }

    return (
        <React.Fragment>
            <div className="fill-window" style={{ backgroundColor: '#F3F4F6', }}>
                <div className="navv">
                    <Row style={{ backgroundColor: '#FFF', padding: '2rem', border: '1px solid #aaa', boxShadow: '0 0 8px 0 #aaa', borderRadius: '15px' }}>
                        <Col md={4}>
                            <h1
                                exact
                                className="flex title-dashboard items-center gap-4 px-2 py-2 rounded-lg"
                                activeClassName="bg-gradient-to-tr from-orange-500 to-orange-700 text-black shadow-md"
                            >{detailSubTO.nama_tryout} / {detailSubTO.kode_tryout}
                            </h1>
                        </Col>
                        <Col md={4}>
                            <div className="duration flex flex-col justify-center align-center">
                                <div className="flex flex-row text-center justify-center align-center">
                                    {
                                        showTime ? <h1
                                            className="flex font-bold text-center mr-3"
                                            style={{ fontSize: '2rem' }}
                                        >{timer ? <span>{timer}</span> : <span>Time's up!</span>}
                                        </h1>
                                            : <h1
                                                className="flex font-bold text-center mr-3"
                                                style={{ fontSize: '2rem' }}
                                            >Timer Hidden
                                            </h1>
                                    }

                                    {showTime ? <button onClick={() => setShowTime(false)}>
                                        <FaEyeSlash className="flex content-center items-center" size={24} />
                                    </button> : <button onClick={() => setShowTime(true)}>
                                        <FaEye className="flex content-center items-center" size={24} />
                                    </button>}
                                </div>
                                <h5>{jawabanExisting ? jawabanExisting.length : '0'} dari {jumlahSoal.length} pertanyaan terjawab</h5>
                            </div>
                        </Col>
                        <Col md={{ span: 2, offset: 1 }}>
                            {
                                isLoading == true ?
                                <Button disabled={true} className="subm-tryout-disabled" onClick={SubmitTryout}>Mohon Tunggu...</Button>
                                :
                                <Button className="subm-tryout" onClick={SubmitTryout}>Submit</Button>
                            }
                            
                        </Col>
                    </Row>
                </div>
                <div className="container mx-auto max-w-full flex" style={{ padding: '2rem' }}>
                    <div className="flex-2 w-9/12 mx-3">
                        <div
                            className="container flex"
                        >
                            <div
                                className="flex-1 flex-grow"
                                style={{
                                    backgroundColor: '#FFF',
                                    padding: '1rem',
                                    borderRadius: '1rem',
                                    boxShadow: '0 0 8px 0 #aaa'
                                }}
                            >
                                {/* <h3 className="text-left tracking-wider title-dashboard" style={{ fontSize: '1rem', color: '#000' }}>
                                    Kompetensi : {kompetensi}  /  Topik : {topik} /  Bidang : {bidang}
                                </h3> */}
                            </div>
                        </div>
                        <FormGroup className="container flex">
                            <div
                                style={{
                                    backgroundColor: '#FFF',
                                    padding: '1rem',
                                    marginTop: '1rem',
                                    borderRadius: '1rem',
                                    boxShadow: '0 0 8px 0 #aaa'
                                }}
                                className="flex-1 flex-grow"
                            >
                                <div class="flex flex-col p-4">
                                    <div className="flex flex-row justify-between">
                                        <div class="flex">
                                            <h3 className="text-left tracking-wider title-dashboard" style={{ fontSize: '1.5rem', color: '#6B7280', fontWeight: 700 }}>Soal {selectedNumber}</h3>
                                        </div>
                                        <div class="flex">
                                            <div className="btn-ragu mr-2 flex items-center flex-row">
                                                <input disabled={isLoading || !isEligibleAnswer} onChange={(e) => {
                                                    simpanJawaban(selectedNumber, idBank, null, e.target.checked)
                                                    setIsRaguRagu(e.target.checked)
                                                }} checked={isRaguRagu} type="checkbox" className="mr-2" />
                                                Ragu-ragu
                                            </div>
                                            {
                                                hasPrevious ? <button
                                                    className="mr-2"
                                                    style={{ background: '#F8CA2B', fontWeight: 700, color: '#000', borderRadius: '8px', padding: '0.5rem' }}
                                                    onClick={() => gantiSoal(selectedNumber - 1)}
                                                >
                                                    <BsChevronLeft style={{ fontWeight: 'heavy', strokeWidth: "1", stroke: 'black' }} />
                                                </button> : <button className="mr-2" disabled style={{ background: '#e5e7eb', fontWeight: 700, color: '#000', borderRadius: '8px', padding: '0.5rem' }}>
                                                    <BsChevronLeft style={{ fontWeight: 'heavy', strokeWidth: "1", stroke: 'black' }} />
                                                </button>
                                            }
                                            {
                                                hasNext ? <button
                                                    className="mr-2"
                                                    style={{ background: '#F8CA2B', fontWeight: 700, color: '#000', borderRadius: '8px', padding: '0.5rem' }}
                                                    onClick={() => gantiSoal(selectedNumber + 1)}
                                                >
                                                    <BsChevronRight style={{ fontWeight: 'heavy', strokeWidth: "1", stroke: 'black' }} />
                                                </button> : <button className="mr-2" disabled style={{ background: '#e5e7eb', fontWeight: 700, color: '#000', borderRadius: '8px', padding: '0.5rem' }}>
                                                    <BsChevronRight style={{ fontWeight: 'heavy', strokeWidth: "1", stroke: 'black' }} />
                                                </button>
                                            }
                                        </div>
                                    </div>
                                    {
                                        gambarSoal ? <img src={gambarSoal} alt={"Gambar soal " + selectedNumber} style={{ maxWidth: '25rem' }} /> : null
                                    }
                                    <div className="soal my-3" style={{ fontFamily: 'Quicksand', fontSize: '1.1rem', color: 'black' }} dangerouslySetInnerHTML={{ __html: soal }}>
                                    </div>
                                    <div class="my-2">
                                        {
                                            jawaban.map((i, idx) =>
                                                <div className="mr-3" key={idx}>
                                                    <label class="inline-flex items-center">
                                                        <input
                                                            disabled={isLoading || !isEligibleAnswer}
                                                            type="radio"
                                                            class="form-radio"
                                                            name={"jawaban"}
                                                            value={i.key}
                                                            checked={chosenJawaban == i.key}
                                                            onChange={(e) => simpanJawaban(selectedNumber, idBank, e.target.value)}
                                                        ></input>
                                                        <div>
                                                            <span class="ml-2">{i.jawaban}</span>
                                                            {
                                                                i.gambar && i.gambar !== null ?
                                                                <img src={i.gambar} alt={"Gambar soal"} style={{ maxWidth: '25rem' }} />
                                                                :
                                                                null
                                                            }
                                                        </div>
                                                    </label>
                                                </div>
                                            )
                                        }
                                    </div>
                                    {
                                        detailSubTO.hasil_laboratorium ?
                                            <div style={{ paddingTop: '1rem' }} className="flex-1 flex-grow">
                                                <Form.Select
                                                    as="select"
                                                    aria-label="Program"
                                                    aria-describedby="basic-addon1"
                                                    className="lab flex"
                                                    onChange={(e) => {
                                                        setViewHasilLab(e.target.value)
                                                    }}
                                                >
                                                    <option value="0">Tutup Nilai Normal Laboratorium</option>
                                                    <option value="1">Lihat Nilai Normal Laboratorium</option>
                                                </Form.Select>
                                            </div> : null
                                    }
                                    {
                                        viewHasilLab === '1' ?
                                            detailSubTO.hasil_laboratorium ? 
                                                <div style={{ paddingTop: '1rem' }} className="flex-1 flex-grow">
                                                    <h2><strong>Hasil Lab: </strong></h2>
                                                    <img src={detailSubTO.hasil_laboratorium} alt={"Gambar hasil lab " + selectedNumber} style={{ maxWidth: '25rem' }} />
                                                </div> : null
                                        :
                                        null
                                    }

                                </div>

                            </div>
                        </FormGroup>
                    </div>
                    <div
                        className="box flex-1 w-3/12 mx-3 p-3"
                        style={{ borderRadius: '1rem', backgroundColor: '#FFF', boxShadow: '0 0 8px 0 #aaa' }}
                    >
                        <h3 className="text-left tracking-wider title-dashboard" style={{ fontSize: '1.2rem', color: '#000' }}>Soal</h3>
                        <div className="container">
                            <div
                                className="grid grid-cols-4"
                            >
                                {jumlahSoal.map((i, idx) => (
                                    renderJumlahSoal(i, idx)
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment >

    )
}

function mapStateToProps(state) {
    const { jawaban } = state.tryout;
    return {
        jawaban
    };
}

export default connect(mapStateToProps)(SoalTryout)