import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
// import InputIcon from '@material-tailwind/react/InputIcon';
import AkademikSidebar from '../../../../components/layout/dashboard/akademik/AkademikSideBar';
import Image from '@material-tailwind/react/Image';

import { useHistory, useLocation, useParams, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { updateTryout, getTryoutDetail, getAllProgram } from "../../../../store/actions/tryout";

import * as Yup from 'yup';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import moment from 'moment';

import { addCommas } from '../../../../helpers/utils';

function EditTryOut(props) {
    const validationSchema = Yup.object().shape({
        kode_tryout: Yup.string()
            .required('Kode Tryout is required'),
        nama_tryout: Yup.string()
            .required('Nama Tryout is required'),
        id_program: Yup.number()
            .required('Program must be selected'),
        tanggal_awal: Yup.date()
            .required('Tanggal Awal is required'),
        tanggal_akhir: Yup.date()
            .required('Tanggal Akhir is required'),
        max_attempt: Yup.number()
            .required('Jumlah Take Tryout is required'),
        passing_grade: Yup.number()
            .required('Nilai batas lulus is required'),
        harga: Yup.number().required('Harga is required'),
    });

    const history = useHistory();
    const { message, dispatch } = props;
    let { id } = useParams();

    const [dataTryout, setDataTryout] = useState({});
    const [programs, setPrograms] = useState([]);

    const {
        register,
        handleSubmit,
        watch,
        reset,
        setValue,
        formState: { errors, isSubmitSuccessful, submitCount, isSubmitted }
    } = useForm({ resolver: yupResolver(validationSchema) });

    const onSubmit = (data) => {
        // console.log(data);
        data.tanggal_awal = moment(data.tanggal_awal).format();
        data.tanggal_akhir = moment(data.tanggal_akhir).format();
        dispatch(updateTryout(id, data))
            .then((response) => {
                history.push('/dashboard/akademik/tryout/' + id)
            })
            .catch((err) => {
                console.log(err);
            });
    };

    useEffect(() => {
        dispatch(getAllProgram())
            .then((response) => {
                let obj = { ...response.data };
                let count = 1;
                let programArr = [];
                for (const key in obj) {
                    console.log(obj[key]);
                    let payload = {
                        key: count,
                        id: obj[key].id,
                        nama: obj[key].nama,
                    };
                    programArr.push(payload)
                    count++
                }
                setPrograms(programArr);
            });
    }, []);

    useEffect(() => {
        dispatch(getTryoutDetail(id))
            .then((response) => {
                const { ...tryout } = response.data
                const inputDatas = [
                    { name: 'kode_tryout', value: tryout.kode_tryout },
                    { name: 'nama_tryout', value: tryout.nama_tryout },
                    { name: 'id_program', value: tryout.id_program },
                    { name: 'tanggal_awal', value: (moment(tryout.tanggal_awal).format().split('+')[0]) },
                    { name: 'tanggal_akhir', value: (moment(tryout.tanggal_akhir).format().split('+')[0]) },
                    { name: 'max_attempt', value: tryout.max_attempt },
                    { name: 'passing_grade', value: tryout.passing_grade },
                    { name: 'harga', value: tryout.harga },
                ]
                for (let o = 0; o < inputDatas.length; o++) {
                    const inputData = inputDatas[o];
                    setValue(inputData.name, inputData.value)
                }
                // return setDataTryout({ ...tryout })
            });
    }, []);

    document.title = "INGENIO Indonesia";
    return (
        <React.Fragment>
            <AkademikSidebar judul={"Edit TryOut"} />
            <div className="md:ml-64">
                <div className="px-3 md:px-8">
                    <div className="container mx-auto max-w-full">
                        <div className="card-gede card-bayar my-5 mx-auto">
                            <div style={{ padding: '20px 25px' }}>
                                <table className="mb-4">
                                    <tr>
                                        <td>
                                            <Image style={{ width: '58px' }} src="/assets/landing/trophy 1.png" />
                                        </td>
                                        <td>
                                            <p style={{ fontSize: "20px", lineHeight: "25px", letterSpacing: "0.1em", color: "#000000", fontWeight: '500' }}>Edit Paket Tryout</p>
                                        </td>
                                    </tr>
                                </table>
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <div className="form-group">
                                        <div className="row">
                                            <div className="col-12 mb-3">
                                                <label htmlFor="kode_tryout" className="label-tryout">Kode Tryout</label> <br />
                                                <input type="text" {...register("kode_tryout")} name="kode_tryout" className="form-tryout" placeholder="Kode Tryout" defaultValue={dataTryout.kode_tryout} />
                                                <span class="text-xs text-danger">{errors.kode_tryout?.message}</span>
                                            </div>
                                            <div className="col-12 mb-3">
                                                <label htmlFor="nama_tryout" className="label-tryout">Nama Paket Tryout</label> <br />
                                                <input type="text" {...register("nama_tryout")} name="nama_tryout" className="form-tryout" placeholder="Nama Paket Tryout" defaultValue={dataTryout.nama_tryout} />
                                                <span class="text-xs text-danger">{errors.nama_tryout?.message}</span>
                                            </div>
                                            <div className="col-12 mb-3">

                                                <label htmlFor="id_program" className="label-tryout">Pilih Program</label> <br />
                                                <select {...register("id_program")} name="id_program" className="form-tryout" placeholder="Pilih Program">
                                                    <option value="">Pilih Program</option>
                                                    {programs.map(item =>
                                                        <option key={item.key} value={item.id}>{item.nama}</option>
                                                    )};
                                                </select>
                                                <span class="text-xs text-danger">{errors.id_program?.message}</span>
                                            </div>
                                            <div className="col-12 mb-3">
                                                <label htmlFor="tanggal_awal" className="label-tryout">Tanggal Awal</label> <br />
                                                <input type="datetime-local" {...register("tanggal_awal")} name="tanggal_awal" className="form-tryout" />
                                                <span class="text-xs text-danger">{errors.tanggal_awal?.message}</span>
                                            </div>
                                            <div className="col-12 mb-3">
                                                <label htmlFor="tanggal_akhir" className="label-tryout">Tanggal Akhir</label> <br />
                                                <input type="datetime-local" {...register("tanggal_akhir")} name="tanggal_akhir" className="form-tryout" />
                                                <span class="text-xs text-danger">{errors.tanggal_akhir?.message}</span>
                                            </div>
                                            <div className="col-md-6 col-xs-12 mb-3">
                                                <label htmlFor="max_attempt" className="label-tryout">Jumlah Take Tryout</label> <br />
                                                <input type="number" {...register("max_attempt")} name="max_attempt" min="0" className="form-tryout" placeholder="Jumlah Take" defaultValue={dataTryout.max_attempt} />
                                                <span class="text-xs text-danger">{errors.max_attempt?.message}</span>
                                            </div>
                                            <div className="col-md-6 col-xs-12 mb-3">
                                                <label htmlFor="passing_grade" className="label-tryout">Nilai Batas Lulus</label> <br />
                                                <input type="text" {...register("passing_grade")} name="passing_grade" className="form-tryout" placeholder="Nilai Batas" defaultValue={dataTryout.passing_grade} />
                                                <span class="text-xs text-danger">{errors.passing_grade?.message}</span>
                                            </div>
                                            <div className="col-12 mb-3">
                                                <label htmlFor="harga" className="label-tryout">Harga Paket Tryout (IDR)</label> <br />
                                                <input type="text" {...register("harga")} name="harga" className="form-tryout" placeholder="Harga Paket Tryout" defaultValue={dataTryout.harga} />
                                                <span class="text-xs text-danger">{errors.harga?.message}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <hr className="my-3" />
                                    <div style={{ width: 'fit-content', marginRight: '0px', marginLeft: 'auto' }}>
                                        <Button className="mx-2" variant="light" onClick={() => history.goBack()}>Batal</Button>
                                        <Button type="submit" className="mx-2" variant="success">Lanjut</Button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>

    )
}

function mapStateToProps(state) {
    const { message } = state.message;
    return {
        message
    };
}

export default connect(mapStateToProps)(EditTryOut);