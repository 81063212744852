import React, { useState, useEffect } from 'react';
import { Col, Form, FormGroup, Row, Button } from 'react-bootstrap';
import { BsArrowLeftShort, BsFillGeoAltFill, BsPlusCircle } from 'react-icons/bs';
import DataTable from 'react-data-table-component';
import { Link, useHistory } from 'react-router-dom';
import { SideBar } from '../../../components/layout/dashboard';
import { contactLink } from '../../../helpers/utils'
import ElearningService from '../../../services/elearning';
import CustomDatatable from '../../../components/common/CustomDatatable';
import moment from 'moment-timezone';
import Swal from 'sweetalert2';

export default function EvaluasiKelas() {
    document.title = "INGENIO Indonesia";
    var baseUrl = window.location.origin;

    // Filtering data
    const [isLoading, setIsLoading] = useState(true);
    const [hasNext, setHasNext] = useState(false)
    const [hasPrev, setHasPrev] = useState(false)
    const [count, setCount] = useState(0);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [keyword, setKeyword] = useState('');
    const [userId, setUserId] = useState('');
    const [kelasSaya, setKelasSaya] = useState([])

    const [program, setProgram] = useState('');

    const [elearningPrograms, setElearningPrograms] = useState([])

    const dropdown = {
        borderRadius: "10px",
        height: 50,
        padding: "25px",
    };
    const columns = [
        {
            name: 'NO.',
            field: 'no',
            index: true,
            selector: row => row.no,
            sortable: true,
        },
        {
            name: 'NAMA KELAS',
            render: true,
            component: (row) => {
                return (
                    <td className='main-table__bodyRow'>{row.kelas.nama_kelas}</td>
                )
            },
            selector: row => row.namakelas,
            sortable: true,
            // width: '15vw',
        },
        {
            name: 'PROGRAM BIMBINGAN',
            render: true,
            component: (row) => {
                return (
                    <td className='main-table__bodyRow'>{row.kelas.elearning_program.nama_program}</td>
                )
            },
            selector: row => row.programbimbingan,
            sortable: true,
            // width: '15vw',
        },
        {
            name: 'REGIO',
            render: true,
            component: (row) => {
                return (
                    <td className='main-table__bodyRow'>{row.kelas.nama_regio}</td>
                )
            },
            selector: row => row.regio,
            sortable: true,
            // width: '15vw',
        },
        {
            name: 'MASA BERLAKU',
            selector: row => row.masaberlaku,
            render: true,
            component: (row) => {
                return (
                    <td className='main-table__bodyRow'>{`${moment(row.kelas.elearning_program.tanggal_mulai).format('DD-MM-YYYY')} s/d ${moment(row.kelas.elearning_program.tanggal_akhir).format('DD-MM-YYYY')}`}</td>
                )
            },
            sortable: true,
            // width: '15vw',
        },
        {
            name: 'STATUS KELAS',
            render: true,
            component: (row) => {
                return (
                    <td className='main-table__bodyRow'>{row.kelas.is_active ? 'Aktif' : 'Tidak Aktif'}</td>
                )
            },
            selector: row => row.statuskelas,
            sortable: true,
        },
        {
            render: true,
            component: (row) => {
                return (
                    <td className='main-table__bodyRow'>
                        <Link className="btnTable" to={`/dashboard/e-learning/info-kelas/${row.kelas.id}`}>Info</Link>
                        {/* {
                            row.kelas.is_active ?
                            <Link className="pilih-tryout p-2 text-center m-1"
                                to={{
                                    pathname: `/dashboard/e-learning/kelas-saya/details/${row.kelas.id}`,
                                    state: {
                                        nama_program: row.kelas.elearning_program.nama_program,
                                        nama_regio: row.kelas.nama_regio,
                                        nama_kelas: row.kelas.nama_kelas
                                    }
                                }}>
                                Akses Kelas
                            </Link>
                            :
                            <Button onClick={() => {
                                Swal.fire({
                                    title: 'Kelas tidak aktif',  
                                    text: 'Kelas tidak aktif, silahkan menghubungi administrator',  
                                    icon: 'warning',  
                                    showCancelButton: true,  
                                    cancelButtonText: 'Kembali',           
                                    showConfirmButton: false,  
                                  }); 
                            }} className="pilih-tryout-disable">Akses Kelas</Button>
                            
                        } */}
                        
                    </td>
                )
            },
            minWidth: '190px',
            cell: () => <Link className="pilih-tryout p-2 text-center m-1" to="/dashboard/e-learning/kelas-saya/details">Akses Kelas</Link>,
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
    ];
    const data = [
        {
            no: 1,
            kelas: 'Lorem ipsum dolor.',
            program: 'Lorem ipsum',
            regio: 'Regio A',
            masa: '25 Des s.d 28 Des',
            status: 'Aktif',
        },
    ];

    const customStyles = {
        table: {
          style: {
            borderRadius: '15px',
            border: '1px solid #BDC1C9',
          },
        },
        head: {
          style: {
            fontSize: '1.25rem',
            textTransform: "uppercase",
            color: '#6B7280',
            backgroundColor: '#f3f4f6',
            border: '1px solid #BDC1C9',
            borderTopLeftRadius: '15px',
            borderTopRightRadius: '15px',
          },
        },
        pagination: {
          style: {
            color: '#6B7280',
            fontSize: '1.1rem',
            minHeight: '56px',
            backgroundColor: '#f3f4f6',
            border: '1.25px solid #BDC1C9',
            borderBottomLeftRadius: '15px',
            borderBottomRightRadius: '15px',
          },
        },
    };

    const getListKelasSaya = (params = '') => {
        setIsLoading(true)
        ElearningService.getListKelasSayaParticipant(params).then(resp => {
            if(resp.data.success === true) {
                const response = resp.data;
                setKelasSaya(response.data);

                setPage(response.meta.page)
                setPerPage(response.meta.perPage)
                setCount(response.meta.count)

                setHasNext(response.meta.hasNext)
                setHasPrev(response.meta.hasPrev);

                if(response.meta.userId) {
                    setUserId(response.meta.userId)
                }
            }
            setIsLoading(false)
        }).catch(err => {
            setIsLoading(false)
            console.error(err)
        })
    }

    const getListElearningProgramDropdown = () => {
        ElearningService.getListElearningProgramAkademik().then(resp => {
            const response = resp.data
            setElearningPrograms(response.data)
        }).catch(err => {
            console.error(err)
        })
    }

    useEffect(() => {
        getListKelasSaya();
        getListElearningProgramDropdown();
    }, [])

    return (
        <React.Fragment>
            <SideBar />
            <div className="md:ml-64 tutorDataKelas" style={{ backgroundColor: '#F3F4F6' }}>
                {/* <div className="bg-orange md:px-8" style={{height: '220px', position: 'relative', padding: '0 30px'}}>
                    <h1 className="tracking-wider title-24-600" style={{marginTop: '-20px'}}>Evaluasi Kelas</h1><br />
                    <h3 className="text-left tracking-wider" style={{lineHeight: '25px'}}>Anda dapat menghubungi Admin terlebih dahulu untuk memastikan kelas dan program<br /> yang Anda daftarkan. <br /> <br />
                    Kontak Admin <a href={contactLink()} target={'_blank'}> <i class="fa fa-whatsapp" aria-hidden="true"></i></a></h3>
                    <img src={baseUrl + "/assets/logo/evaluasikelas.png"} className="img-opacity"></img>
                </div> */}

                <div className="container md:pr-8 md:pl-10"  style={{position: 'absolute', top: '80px'}}>
                    <h1 className="tracking-wider title-24-600" style={{marginTop: '-20px'}}>Evaluasi Kelas</h1><br />
                    <h3 className="text-left tracking-wider" style={{lineHeight: '25px'}}>Anda dapat menghubungi Admin terlebih dahulu untuk memastikan kelas dan program<br /> yang Anda daftarkan. <br /> <br />
                    Kontak Admin <a href={contactLink()} target={'_blank'}> <i class="fa fa-whatsapp" aria-hidden="true"></i></a></h3>
                </div> 

                <div className="px-3 md:px-8 mt-4">
                    <div className="container mx-auto max-w-full">
                        <div className="row">
                            <div className="col-md-12 col-lg-12 col-xs-12 mb-4">
                                <FormGroup>
                                    <Row style={{ backgroundColor: '#FFF', padding: '1rem', borderRadius: '1rem' }}>
                                        <Col sm={2}> <img src={baseUrl + "/assets/logo/evaluasikelas.png"} className="object-center mx-auto"></img></Col>
                                        <Col sm={9}> <h3 className="text-left tracking-wider title-dashboard">Halo Teman - Teman !</h3> <br />
                                            <h3 className="text-left tracking-wider">Tak terasa kita sudah berada pada akhir pertemuan bimbingan.<br />
                                                Oleh karena ini, kami mohon bantuan teman-teman untuk mengisi form evaluasi ini secara jujur dan objektif agar Ingenio Indonesia dapat melakukan perbaikan terus menerus lebih baik.<br />
                                                Pastikan teman-teman mengisi dengan jujur dan tidak terburu-buru.<br />
                                                Atas bantuan dan partisipasi teman-teman, kami ucapkan terima kasih.
                                            </h3><br />
                                            <h3 className="text-left tracking-wider"><strong>#AkuBanggaMenjadiAnakIngenio</strong></h3>
                                        </Col>
                                    </Row>
                                </FormGroup>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12 col-lg-12 col-xs-12 mb-4">
                                <FormGroup>
                                    <Row className="bg-orange" style={{ padding: '1rem', borderRadius: '1rem' }}>
                                        <Col sm={1}> <img src={baseUrl + "/assets/logo/info.png"} className="object-center mx-auto" style={{ padding: '0.5rem' }}></img></Col>
                                        <Col sm={9}> <h3 className="text-left tracking-wider">Evaluasi kelas <strong>WAJIB</strong> diisi setelah semua jadwal materi bimbingan selesai disampaikan atau setelah arahan penutupan kelas dari akademik</h3>
                                        </Col>
                                    </Row>
                                </FormGroup>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 mt-3 mb-4">
                                <FormGroup>
                                    <div className="SearchTable2 gap-3">
                                        <input value={keyword}
                                            onChange={(e) => setKeyword(e.target.value)} type="text" name="" className="inputSearchTable" placeholder="&#x1F50E;&#xFE0E; Search..." id="" />
                                        <Form.Select
                                            as="select"
                                            value={program}
                                            aria-label="Program"
                                            aria-describedby="basic-addon1"
                                            className="my-tryout"
                                            onChange={(e) => setProgram(e.target.value)}
                                        >
                                            <option value="">Semua Program</option>
                                            {elearningPrograms.map(item =>
                                                <option key={item.key} value={item.nama_program}>{item.nama_program}</option>
                                            )};
                                        </Form.Select>
                                        <Button onClick={() => {
                                            getListKelasSaya(`page=${page}&perPage=${perPage}&keyword=${keyword}&program=${program}`)
                                        }} className="btnSearchTable">Cari</Button>
                                    </div>
                                </FormGroup>
                            </div>
                        </div>
                        <div className="col-12 mt-3 mb-5">
                            {/* <DataTable columns={columns} customStyles={customStyles} data={data} pagination /> */}
                            <CustomDatatable 
                                filteredItems={kelasSaya} 
                                columns={columns}
                                isLoading={isLoading}
                                page={page}
                                perPage={perPage}
                                hasPrev={hasPrev}
                                hasNext={hasNext}
                                count={count}
                                onTableChange={(e, data) => {
                                    switch (e) {
                                    case 'rowPerPageChange':
                                        setPerPage(data.perPage)
                                        getListKelasSaya(`page=${page}&perPage=${data.perPage}&keyword=${keyword}&program=${program}`)
                                        break;
                                    case 'rowPageChange':
                                        setPage(data.page)
                                        getListKelasSaya(`page=${data.page}&perPage=${perPage}&keyword=${keyword}&program=${program}`)
                                        break;
                                    
                                    default:
                                        break;
                                    }
                                }}
                            />
                        </div>
                    </div>
                </div>

            </div>
        </React.Fragment >

    )
}