import React, { useState, useEffect, useRef } from 'react';
import { InputGroup, FormControl, Form, FormGroup } from 'react-bootstrap';
import './ingenioform.css';
import Swal from 'sweetalert2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import axios from 'axios';

export default function Content () {
  const history = useHistory();
  const didMountRef = useRef(false);
  const [validated, setValidated] = useState(false);
  const [validationError, setValidationError] = useState('');
  const validationSchema = Yup.object().shape({
      password: Yup.string()
        .required('Password is required')
        .min(8, 'Password must be at least 6 characters')
        .max(40, 'Password must not exceed 40 characters')
        .test("isValidPass", "Password is not valid", (value, context) => {
          const hasUpperCase = /[A-Z]/.test(value);
          const hasNumber = /[0-9]/.test(value);
          let validConditions = 0;
          const numberOfMustBeValidConditions = 2;
          const conditions = [hasUpperCase, hasNumber];
          conditions.forEach((condition) =>
            condition ? validConditions++ : null
          );
          if (validConditions >= numberOfMustBeValidConditions) {
            return true;
          }
          return false;
        }),
      confirmPassword: Yup.string()
        .required('Confirm Password is required')
        .oneOf([Yup.ref('password')], 'Confirm Password does not match'),
  });
  let queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const token = urlParams.get('id')


  const { register, handleSubmit, watch, reset, formState: { errors, isSubmitSuccessful, submitCount, isSubmitted } } = useForm({resolver: yupResolver(validationSchema)});

  React.useEffect(() => {
    if (didMountRef.current){
        if(!isSubmitSuccessful && submitCount > 0) {
            Fail();
        }
    } else {
        didMountRef.current = true;
    }
  }, [submitCount]);

  const onSubmit = (data) => {
    const payload = {
      password: data.password,
      token,
    }
    axios.put('api/auth-service/confirm-new-password', payload)
      .then((response) => {
        if(response.data.code === 400) {
          console.log(response)
          setValidationError(response.data.message)
        } else if (response.data.code === 200){
          setValidationError();
          console.log(response);
          Success();
        }
      })
      .catch((error) => {
        console.log(error);
        ErrorSwal(error);
      })
  };

  const Success = () => {
    Swal.fire({  
        title: 'Password berhasil di ubah !',  
        icon: 'success',  
        showCancelButton: false,  
        cancelButtonText: 'Kembali',           
        showConfirmButton: true,  
      }).then((result) => {
        if(result.isConfirmed) {
          history.push('/signin')
        }
      });   
  }

  const Fail = () => {
    Swal.fire({  
        title: 'Data yang Anda isikan belum lengkap !',  
        text: 'Silahkan lengkapi data Anda',  
        icon: 'warning',  
        showCancelButton: true,  
        cancelButtonText: 'Kembali',           
        showConfirmButton: false,  
      });   
  }

  const ErrorSwal = (error) => {
    Swal.fire({  
        title: "Something's Wrong",  
        text: error,  
        icon: 'warning',  
        showCancelButton: true,  
        cancelButtonText: 'Kembali',           
        showConfirmButton: false,  
      });   
  }

  return (
    <React.Fragment>
      <section className="container-fluid">
        <div style={{ backgroundImage: 'url(/assets/landing/bg-racoon.jpg)', backgroundSize: '100%', backgroundRepeat: 'no-repeat', backgroundPosition: 'bottom' }}>
          <div className="container forgetpass-container">
              <Form validated={validated} onSubmit={handleSubmit(onSubmit)}>
                <div className="row align-items-center g-2 py-5">
                    <div className="col-sm-12 col-md-6 mx-auto text-center">
                        <h1 className="title-big">Konfirmasi Password Baru</h1>
                    </div>
                    <div className="col-sm-12 col-md-10 col-lg-8 mx-auto text-center">
                        <p style={{fontFamily: 'Quicksand', fontWeight: '800'}}><FontAwesomeIcon icon={faInfoCircle} /> &nbsp; Password panjangnya minimal 8 karakter, dan mengandung min. 1 huruf besar dan 1 angka </p>
                    </div>
                </div>
                <div className="row align-items-center g-2">
                    <div className="col-sm-12 col-md-6 col-lg-4 mx-auto">
                        <FormGroup>
                            <Form.Label htmlFor="password-baru form-label">Silahkan masukan Password baru</Form.Label>
                            <FormControl
                              {...register("password")}
                              placeholder="Password Baru"
                              aria-label="Password Baru"
                              aria-describedby="basic-addon1"
                              isInvalid={errors.password}
                              type="password"
                            />
                            <Form.Control.Feedback type="invalid">
                              {errors.password?.message}
                            </Form.Control.Feedback>
                        </FormGroup>
                        
                    </div>
                </div>
                <div className="row align-items-center g-2 py-4">
                    <div className="col-sm-12 col-md-6 col-lg-4 mx-auto">
                        <FormGroup>
                            <Form.Label htmlFor="password-baru form-label">Silahkan masukan ulang Password baru</Form.Label>
                            <FormControl
                              {...register("confirmPassword")}
                              placeholder="Password Baru"
                              aria-label="Password Baru"
                              aria-describedby="basic-addon1"
                              isInvalid={errors.confirmPassword}
                              type="password"
                            />
                            <Form.Control.Feedback type="invalid">
                              {errors.confirmPassword?.message}
                            </Form.Control.Feedback>
                        </FormGroup>
                    </div>
                </div>
                <div className="row align-items-center g-2 py-2">
                  <div className="col-sm-12 col-md-6 col-lg-4 mx-auto text-center">
                    <div class="form-group">
                      <button type="submit" class="bt-form-submit" onSubmit={handleSubmit(onSubmit)}>
                        <span class="bt-link" style={{color: 'black'}}>Kirim</span>
                      </button>
                    </div>
                  </div>
                </div>
              </Form>
          </div>
        </div>
      </section>
    </React.Fragment>
  )
}