import React, { useState, useRef, useEffect } from 'react';
import { connect, useSelector } from "react-redux";
import { Redirect, useHistory } from "react-router-dom";
import AuthService from "../../services/auth";

function AuthGuard (WrappedComponent, allowedRoles) {
  function AuthHOC (props) {
    const history = useHistory();
    AuthService.accountVerify()
      .then(resp => {
        const response = resp.data
        const rolecode = response.data.rolecode;
        if(rolecode !== allowedRoles) {
          history.push('/403');
          return;
        }
      }).catch(err => {
        console.error(err)
        localStorage.removeItem("token");
        localStorage.removeItem("m_f__master");
        history.push('/signin');
        return;
      })
    return (
      <WrappedComponent {...props} />
    )
  }

  return AuthHOC
}

export default AuthGuard;