import React from 'react';
import {
    Tutor,
} from '../components/tutor';
import {
    IngenioNav,
    IngenioFooter
} from '../components/layout'

export default function TutorPage() {
    return (
        <React.Fragment>
            <IngenioNav />
            <div style={{ backgroundColor: 'white' }}>
                <Tutor />
            </div>
            <IngenioFooter />
        </React.Fragment>

    )
}