import React, { useEffect, useState } from 'react';
import {
  SideBar
} from '../../../../components/layout/dashboard';
import { FormGroup, FormControl, Form } from 'react-bootstrap';
import { useParams, useHistory } from "react-router-dom";
import { Link, useLocation } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import Button from '@restart/ui/esm/Button';
import InputIcon from '@material-tailwind/react/InputIcon';
import axios from 'axios';
import { addCommas } from '../../../../helpers/utils';
import CustomDatatable from '../../../../components/common/CustomDatatable';
import AdminSideBar from '../../../../components/layout/dashboard/admin/AdminSideBar';

export default function AdminTransaction() {
  document.title = "INGENIO Indonesia";
  const [program, setProgram] = useState('');
  const [transaction, setTransaction] = useState([])
  const [myTryout, setMyTryout] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [hasNext, setHasNext] = useState(false)
  const [hasPrev, setHasPrev] = useState(false)
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [keyword, setKeyword] = useState('');
  const tx_black = {
    color: 'black !important'
  }
  const jenisTransactionTypeRender = (transactionItems) => {
    if(transactionItems.tryout !== null) {
      return 'Tryout'
    }

    if(transactionItems.elearning !== null) {
      return 'E-learning'
    }
  }
  const columns = [
    {
        name: 'No.',
        index: true,
        selector: (row, idx) => (idx+1),
        sortable: true,
    },
    {
      name: 'Jenis Transaksi',
      render: true,
        component: (row) => {
            return (
                <td className='main-table__bodyRow'>{(row.transaction_items.length > 0) ? jenisTransactionTypeRender(row.transaction_items[0]) : ''}</td>
            )
        },
      sortable: true,
    },
    {
        name: 'Kode Transaksi',
        render: true,
          component: (row) => {
              return (
                  <td className='main-table__bodyRow'>{`#${row.id}`}</td>
              )
          },
        selector: row => (row.transaction_items.length > 0) ? row.transaction_items[0].item_name : '',
        sortable: true,
      },
    {
      name: 'Nama Transaksi',
      render: true,
        component: (row) => {
            return (
                <td className='main-table__bodyRow'>{(row.transaction_items.length > 0) ? row.transaction_items[0].item_name : ''}</td>
            )
        },
      selector: row => (row.transaction_items.length > 0) ? row.transaction_items[0].item_name : '',
      sortable: true,
    },
    {
      name: 'Harga',
      render: true,
        component: (row) => {
            return (
                <td className='main-table__bodyRow'>{(`Rp ${addCommas(row.subtotal)}`)}</td>
            )
        },
      selector: row => `Rp ${addCommas(row.subtotal)}`,
      sortable: true,
    },
    {
      name: 'Status',
      render: true,
      component: (row) => {
        return (
            <td className='main-table__bodyRow'>
                {
                    row.transaction_status === 'success' ?
                    <div style={{backgroundColor: '#22C55E',
                        borderRadius: '20px',
                        color: 'white',
                        textAlign: 'center'}}>
                    {row.transaction_status}
                    </div>
                    :
                    <div style={{backgroundColor: '#EF4444',
                        borderRadius: '20px',
                        color: 'white',
                        textAlign: 'center'}}>
                    {row.transaction_status}
                    </div>
                }
            </td>
        )
      },
      selector: (row) => row.transaction_status,
      conditionalCellStyles: [
        {
          when: row => row.transaction_status === 'pending',
          style: {
            backgroundColor: '#EAB308',
            borderRadius: '20px',
            color: 'white',
            '&:hover': {
              cursor: 'pointer',
            },
            textAlign: 'center'
          },
        },
        {
          when: row => row.transaction_status === 'success',
          style: {
            backgroundColor: '#22C55E',
            borderRadius: '20px',
            color: 'white',
            '&:hover': {
              cursor: 'pointer',
            },
            textAlign: 'center'
          },
        },
        {
          when: row => row.transaction_status === 'failed',
          style: {
            backgroundColor: '#EF4444',
            borderRadius: '20px',
            color: 'white',
            '&:hover': {
              cursor: 'not-allowed',
            },
            textAlign: 'center'
          },
        },
      ],
      sortable: true,
    },
    {
        render: true,
        component: (row) => {
            return (
                <td className='main-table__bodyRow'>
                    {
                        row.transaction_status === 'success' ? 
                            null 
                            : 
                            <Button onClick={() => {
                                setSuccessTransaction(row.id)
                            }} className="pilih-tryout p-2">Set Success</Button>
                    }
                </td>
            )
        },
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];
  const data = [
    {
      no: 1,
      paket: 'Tryout 1 Lorem ipsum dolor.',
      kode: 'AAA',
      program: 'Program 1',
      tryout: [
        {
          harga: '100.000'
        }
      ]
    },
  ];

  function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
  }

  const query = useQuery()

  const setSuccessTransaction = (transactionId) => {
    const body = {
        id_transaction: transactionId,
        transaction_status: 'success'
    }
    axios.post(`${process.env.REACT_APP_TRYOUTURL}transaction/private/notify`, body, {
        headers: {
          Authorization: `Bearer ${JSON.parse(localStorage.getItem('token'))}`
        }
      }).then(resp => {
        getListTransactions();
      }).catch(err => {
        console.error(err)
      })
  }

  const getListTransactions = () => {
    axios.get(`${process.env.REACT_APP_TRYOUTURL}transaction/admin/get`, {
        headers: {
          Authorization: `Bearer ${JSON.parse(localStorage.getItem('token'))}`
        }
      }).then(resp => {
        console.log(resp);
        setTransaction(resp.data.data)
      }).catch(err => {
        console.error(err)
      })
  }

  useEffect(() => {
    getListTransactions();
  }, [])

  return (
    <React.Fragment>
      <AdminSideBar judul={'Daftar Transaksi'}/>
      <div className="md:ml-64">
        <div className="bg-orange px-3 md:px-8 py-1" style={{height: '2.5rem'}}>
          <div className="container md:pr-8 md:pl-10">
          </div>
        </div>

        <div className="py-5 px-3 md:px-8">
          <div className="container mx-auto max-w-full">
            <div className="row">
              <div className="col-md-8 col-lg-8 col-xs-12 mb-4">
                <FormGroup>
                  <div className="row">
                    <div className="col-3">
                      <Form.Select
                        as="select"
                        value={program}
                        aria-label="Pembayaran"
                        aria-describedby="basic-addon1"
                        className="my-tryout"
                      >
                        <option value="">Semua Pembayaran</option>
                        <option value="1">Pembayaran A</option>
                        <option value="2">Pembayaran B</option>
                        <option value="3">Pembayaran C</option>
                      </Form.Select>
                    </div>
                  </div>
                </FormGroup>
              </div>
              <div className="col-md-12 col-lg-12 col-xs-12 px-4">
                {/* <DataTable columns={columns} data={transaction} pagination /> */}
                <CustomDatatable 
                    filteredItems={transaction} 
                    columns={columns}
                    isLoading={isLoading}
                    page={page}
                    perPage={perPage}
                    hasPrev={hasPrev}
                    hasNext={hasNext}
                    count={count}
                    onTableChange={(e, data) => {
                        switch (e) {
                        case 'rowPerPageChange':
                            setPerPage(data.perPage)
                            //getListKelasSaya(`page=${page}&perPage=${data.perPage}&keyword=${keyword}&program=${program}`)
                            break;
                        case 'rowPageChange':
                            setPage(data.page)
                            //getListKelasSaya(`page=${data.page}&perPage=${perPage}&keyword=${keyword}&program=${program}`)
                            break;
                        
                        default:
                            break;
                        }
                    }}
                />
              </div>
            </div>
          </div>
        </div>

      </div>
    </React.Fragment>

  )
}