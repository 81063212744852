import React, { useState, useRef } from 'react';
import { InputGroup, FormControl, Form, FormGroup, Alert } from 'react-bootstrap';
import { useForm } from "react-hook-form";
import { useHistory, Redirect } from "react-router-dom";
import Swal from 'sweetalert2';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

import { connect } from "react-redux";
import { forgotPass } from "../../store/actions/auth";
import { clearMessage } from '../../store/actions/message';

function Content (props) {
  const history = useHistory();
  const didMountRef = useRef(false);
  const [validated, setValidated] = useState(false);
  const [validationError, setValidationError] = useState('');

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .required('Email is required')
      .email('Email is invalid'),
  });

  const { register, handleSubmit, watch, reset, formState: { errors, isSubmitSuccessful, submitCount, isSubmitted } } = useForm({resolver: yupResolver(validationSchema)});

  const { isLoggedIn, message, dispatch } = props;

  const onSubmit = (data) => {
    dispatch(forgotPass(data))
      .then(() => {
        Success();
      })
      .catch((err) => {
          console.log(err);
      });
  };

  React.useEffect(() => {
    if (didMountRef.current){
        if(!isSubmitSuccessful && submitCount > 0) {
            Fail();
        }
    } else {
        didMountRef.current = true;
    }
  }, [submitCount]);

  const Success = () => {
    Swal.fire({  
        title: 'Request berhasil di kirimkan !',  
        text: 'Silahkan cek email Anda',  
        icon: 'success',  
        showCancelButton: false,  
        cancelButtonText: 'Kembali',           
        showConfirmButton: true,  
      }).then((result) => {
        if(result.isConfirmed) {
          history.push('/signin')
        }
      });   
  }

  const Fail = () => {
    Swal.fire({  
        title: 'Data yang Anda isikan belum lengkap !',  
        text: 'Silahkan lengkapi data Anda',  
        icon: 'warning',  
        showCancelButton: true,  
        cancelButtonText: 'Kembali',           
        showConfirmButton: false,  
      });   
  }

  // const ErrorSwal = (error) => {
  //   Swal.fire({  
  //       title: "Something's Wrong",  
  //       text: error,  
  //       icon: 'warning',  
  //       showCancelButton: true,  
  //       cancelButtonText: 'Kembali',           
  //       showConfirmButton: false,  
  //     });   
  // }

  if (isLoggedIn) {
    return <Redirect to="/dashboard" />;
  }
  return (
    <React.Fragment>
      <section className="container-fluid" style={{ backgroundImage: 'url(/assets/landing/bg-racoon.jpg)', backgroundSize: '100%', backgroundRepeat: 'no-repeat', backgroundPosition: 'bottom' }}>
        <div className="container forgetpass-container">
          <Form validated={validated} onSubmit={handleSubmit(onSubmit)}>
            <div className="row align-items-center g-2 py-5">
              <div className="col-sm-12 col-md-6 col-lg-6 mx-auto text-center">
                <h1 className="title-big">Lupa Kata Sandi Anda?</h1>
                <p className="text-regular py-5">Masukan Email yang telah terdaftar di INGENIO<br/>dan kami akan mengirimkan instruksi untuk<br/> mengganti kata sandi Anda. </p>
                { message &&
                  (<div className="row align-items-center">
                      <div className="col-12 col-lg-8 mx-auto pb-3">
                          <Alert variant="danger" onClose={() => dispatch(clearMessage())} dismissible>
                              {message}
                          </Alert>
                      </div>
                  </div>)}
                <FormGroup className="mx-auto" style={{width: '80%', minHeight: '50px'}}>
                  <FormControl
                    {...register("email")}
                    placeholder="Email"
                    aria-label="Email"
                    aria-describedby="basic-addon1"
                    isInvalid={errors.email || validationError}
                    type="email"
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.email?.message || validationError}
                  </Form.Control.Feedback>
                </FormGroup>
              </div>
            </div>
            <div className="row align-items-center g-2 py-2">
              <div className="col-sm-12 col-md-6 col-lg-4 mx-auto text-center">
                <div class="form-group">
                  <button type="submit" class="bt-form-submit">
                    <span class="bt-link" style={{color: 'black'}}>Kirim</span>
                  </button>
                </div>
              </div>
            </div>
          </Form>
        </div>
      </section>
    </React.Fragment>
  )
}


function mapStateToProps(state) {
  const { isLoggedIn } = state.auth;
  const { message } = state.message;
  return {
      isLoggedIn,
      message
  };
}

export default connect(mapStateToProps)(Content);