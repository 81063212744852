import React from 'react';
import Collapsible from 'react-collapsible';
import { BsChevronDown } from "react-icons/bs"; //react-icon

const faqs = [
    {
        q: "Kapan saya dapat melakukan pendaftaran bimbingan UKMPPD/Kelas Terbang/UKMPPDG?",
        a: `Bagi peserta yang telah memiliki anggota kelompok, bisa melakukan pendaftaran kapan saja.
        Bagi peserta yang belum memiliki teman kelompok, kami akan membuka pendaftaran pada tanggal sebagai berikut:
        Batch 1 tanggal 1 November
        Batch 2 tanggal 1 Februari
        Batch 3 tanggal 1 Mei
        Batch 4 tanggal 1 Agustus
        (konfirmasi kepada admin staff).
        `
    },
    {
        q: "Tanggal berapa bimbingan UKMPPD/UKMP2DG Batch 1/Batch 2/Batch 3/Batch 4 berlangsung?",
        a: `Ingenio tidak menentukan tanggal mulai bimbingan (kecuali dalam kondisi khusus). Tanggal mulai bimbingan umumnya ditentukan oleh peserta bimbingan kelompok yang terdaftar. Bagi peserta individu/tidak memiliki teman kelompok, akan mengikuti tanggal bimbingan sesuai dengan tanggal permintaan kelompok yang telah terdaftar (konfirmasi kepada admin staff).`
    },
    {
        q: "Apakah saya dapat mengikuti bimbingan UKMPPD/Kelas Terbang/UKMP2D di Ingenio dengan mendaftar secara individu?",
        a: `Bagi pendaftar individu akan kami gabungkan dengan kelompok yang telah terdaftar dan belum memenuhi kuota maksimal kelompok dengan ketentuan mengikuti tanggal bimbingan yang sudah di tentukan oleh pendaftar kelompok. Untuk Kelas Terbang, pada umumnya sudah memiliki jumlah minimal anggota kelompok tersendiri sesuai dengan ketentuan sehingga pendaftar individu akan lebih disarankan mengikuti bimbingan di kota-kota yang tersedia (Malang, Denpasar, Surabaya & Jakarta).`
    },
    {
        q: "Berapa jumlah minimal kepesertaan bimbingan UKMPPD/Kelas Terbang/UKMP2DG di Ingenio?",
        a: `Sesuai ketentuan, minimal kepesertaan adalah 18 orang. Namun apabila kurang dari 18 orang, peserta akan kami gabungkan dengan peserta dari FK lain yang masih belum memiliki anggota kelompok yang mencukupi (konfirmasi kepada admin staff).`
    },
    {
        q: "Berapa jumlah maksimal kepesertaan bimbingan UKMPPD/Kelas Terbang/UKMP2DG?",
        a: `Secara ketentuan, maksimal kepesertaan adalah 24 orang. Namun akan kami sesuaikan dengan ketersediaan ruangan di setiap area bimbingan masing-masing (konfirmasi kepada admin staff).`
    },
    {
        q: "Berapa lama bimbingan belajar UKMPPD/ UKMP2DG Offline di Ingenio?",
        a: `Untuk bimbingan offline kurang lebih selama 30 hari atau 4 minggu untuk UKMPPD & Kelas Terbang (tidak termasuk waktu bimbingan OSCE). Kurang lebih selama 21 hari atau 3 minggu untuk UKMP2DG (tidak termasuk waktu bimbingan OSCE).`
    },
    {
        q: "Bagaimana sistem bimbingan belajar UKMPPD/Kelas Terbang/UKMP2DG di Ingenio?",
        a: `Ingenio memberikan pendalaman materi terlebih dahulu dan membentuk pola pikir peserta. Dilanjutkan dengan melakukan pembahasan soal-soal yang sudah dikerjakan terlebih dahulu oleh peserta.`
    },
    {
        q: "Berapa lama jam bimbingan dalam 1 hari untuk UKMPPD/Kelas Terbang/UKMP2DG di Ingenio?",
        a: `Untuk bimbingan ujian kompetensi offline dalam 1 hari kurang lebih 4 hingga 8 jam per hari (ada waktu ISHOMA).`
    },
    {
        q: "Apa saja fasilitas yang di dapatkan saat mengikuti bimbingan UKMPPD/Kelas Terbang/UKMP2DG?",
        a: `1.	Buku modul dengan penyusunan materi ringkas sesuai dengan SNPPDI
        2.	Eksklusif Bag
        3.	Kaos Ingenio
        4.	Try Out online sebanyak 3 kali
        5.	Pembahasan lebih dari 3.000 soal
        6.	Tempat bimbingan yang nyaman (hanya di Kota Malang, Denpasar, Surabaya, Makassar, & Jakarta). Untuk kelas terbang, tempat bimbingan disediakan oleh Ingenio atau oleh calon peserta bimbingan sesuai kesepakatan biaya paket bimbingan. 
        7.	Tutor terbaik berpengalaman di bidangnya
        8.	Konsultasi tutor selama masa bimbingan
        9.	Soal-soal prediksi
        10.	Garansi bimbingan (*sesuai dengan syarat & ketentuan yang berlaku)
        `
    },
    {
        q: "Apakah kami boleh melakukan pembayaran secara bertahap/cicilan?",
        a: `Ingenio memiliki opsi program pembayaran secara bertahap/cicilan dengan penyedia layanan Danacita atau Edufund. Ketentuan pembayaran pada umumnya sudah lunas maksimal 1 minggu sebelum tanggal mulai bimbingan berlangsung. Hubungin tim marketing dan admin kami untuk ketentuan dan arahan layanan tersebut lebih detail.`
    },
    {
        q: "Apakah Ingenio menyediakan program bimbingan untuk latihan soal saja atau TO online saja?",
        a: `Untuk program UKMPPD, Ingenio tidak memberikan bdimbingan hanya untuk latihan soal atau TO online saja. Kami sangat ingin membantu peserta dalam pemantapan pola pikir dan landasan teori yang kuat terlebih dahulu sehingga saat peserta menghadapi soal ujian peserta mampu menjawab soal dengan pola pikir yang benar.`
    },
    {
        q: "Apakah saya dapat memilih tenaga pengajar bimbingan?",
        a: `Tenaga pengajar Ingenio dipilih melalui proses seleksi. Kami memberikan yang terbaik bagi para peserta bimbingan Ingenio Indonesia. Dengan ciri khas cara mengajar masing-masing tutor yang berpengalaman di bidangnya. Ingenio menyesuaikan tenaga pengajar dengan area bimbingan masing-masing. Oleh karena itu pemilihan tenaga mengajar tidak tersedia karena kami pasti memberikan tenaga pengajar yang terbaik bagi para peserta.`
    },
    {
        q: "Bagaimana ketentuan dari pihak Ingenio apabila saya mengundurkan diri dari pendaftaran bimbingan",
        a: `1.	Apabila peserta telah melakukan pendaftaran namun kelas belum dimulai dan belum melakukan pembayaran, silahkan untuk konfirmasi saja kepada ketua kelompok (apabila pendaftaran dilakukan secara kelompok, untuk kemudian ketua mengkonfirmasikan kepada admin staff). Maksimal pengunduran diri adalah 2 minggu sebelum bimbingan berlangsung.
        2.	Apabila peserta telah melakukan pendaftaran dan telah melakukan pembayaran, namun kelas belum di mulai (maksimal 3 hari sebelum waktu bimbingan di mulai) ataupun telah memasuki masa bimbingan (maksimal telah berlangsung selama 3 hari terhitung dari tanggal mulai), maka peserta dapat menerima refund biaya bimbingan setelah di potong biaya buku dan biaya transfer refund (nominal biaya akan dikonfirmasi oleh admin).
        3.	Apabila peserta telah melewati masa bimbingan (lebih dari 2 hari), maka peserta tidak akan mendapatkan refund biaya bimbingan.
        `
    },
    {
        q: "Bagaimana ketentuan dari pihak Ingenio apabila saat saya mengikuti bimbingan, saya dinyatakan tidak lolos untuk mengikuti ujian pada batch tersebut dan tidak dapat melanjutkan masa bimbingan pada batch tersebut?",
        a: `1.	Apabila peserta telah melewati masa bimbingan selama 1-2 minggu, diperbolehkan meneruskan masa bimbingan pada batch berikutnya dengan cara melaporkan diri kepada akademik & admin staff Ingenio. Apabila peserta tidak melaporkan diri, maka peserta akan dikenakan biaya sebesar 30% dari biaya bimbingan yang telah dbayarkan sebelumnya pada batch berikutnya (hanya mengikuti bimbingan materi yg belum di dapatkan saja pada batch berikutnya).

        2.	Apabila peserta telah memasuki masa bimbingan di minggu ke-3 dan ke-4, kami menyarankan untuk menyelesaikan bimbingan yang tersisa (apabila masih bisa di ikuti). Laporkan diri kepada akademik & admin staff. (hanya mengikuti bimbingan materi yg belum di dapatkan saja pada batch berikutnya).
        
        3.	Apabila pada batch berikutnya Ingenio mengeluarkan edisi buku terbaru dan berbeda dengan edisi buku yang pernah di ikuti sebelumnya, maka peserta disarankan mengganti buku lama dengan buku edisi terbaru dengan harga buku sesuai dengan yang di infokan oelh admin staff.
        
        4.	Peserta dapat melanjutkan bimbingan ke batch berikutnya di kota yang di ikuti sebelumnya apabila ada tersedia kelas di kota tersebut. Namun apabila tidak tersedia kelas , maka akan disarankan untuk meneruskan bimbingannya di kota lain (konfirmasi kepada admin staff).
        
        5.	Untuk kelas terbang (Papua) peserta yang akan mundur ke batch berikutnya akan dikenakan ‘special price’ (harga konfirmasi kepada admin staff).
        
        6.	Peserta hanya di ijinkan mundur 1 batch dari batch yang pernah di ikuti. Apabila peserta mundur hingga 2 batch, maka peserta akan dikenakan harga normal (potongan harga buku apabila edisi buku yang digunakan masih sama dan menggunakan buku lama yang telah dimiliki. Namun apabila ada perubahan edisi buku, maka peserta disarankan untuk menggunakan buku edisi terbaru).
        `
    },
    {
        q: "Apakah ada jaminan PASTI LULUS UKMPPD/UKMPPDG dari Ingenio?",
        a: `Ingenio adalah sebuah wadah pendukung para calon dokter ataupun dokter gigi untuk membantu keberhasilan atas kelulusan dari sebuah ujian profesi kedokteran. Kami memberikan tutor terbaik, modul dengan penyusunan materi ringkas dan sesuai dengan SNPPDI yang telah di buat oleh para team terbaik Ingenio, serta memberikan pembahasan lebih dari 3000 soal. Namun peran pendukung dari Ingenio tidak akan maksimal apabila peserta bimbingan sendiri tidak cukup memiliki semangat dalam proses belajar. Hasil akan menyesuaikan usaha peserta sendiri dalam belajar.`
    },
    {
        q: "Apa yang dimaksud dengan GARANSI bimbingan Ingenio & Syarat ketentuan untuk mendapatkan garansi?",
        a: `Garansi adalah sebuah penghargaan bagi para peserta yang telah mengikuti bimbingan di Ingenio.
        Persyaratan garansi:
        1. Peserta selalu mengikuti bimbingan dengan baik 
        2. Peserta selalu mengikuti Fasilitas Try Out online yang telah disediakan oleh Ingenio 
        3. Nilai Try Out online 3 minimal 70.50 atau sesuai ketentuan akademik di batch berjalan
        4. Tidak tersedia bagi peserta yang mundur ke batch berikutnya.
        5. Diperuntukkan bagi peserta yang tidak berhasil lolos ujian.
        Peserta dapat mengikuti bimbingan kembali secara gratis (apabila ada perubahan edisi buku, maka peserta hanya membayar biaya buku edisi terbaru dengan info biaya oleh admin staff). Apabila kelas bimbingan tidak tersedia pada kota yang pernah di ikuti sebelumnya, maka peserta akan disarankan untuk mengikuti bimbingan di kota lain.
        `
    },
    {
        q: "Siapa saja yang dapat mengikuti TO online Ingenio?",
        a: `Hanya peserta yang mengikuti program bimbingan belajar di Ingenio atau program khusus Tryout kerjasama dengan instansi tertentu.`
    },
    {
        q: "Berapa lama bimbingan OSCE di Ingenio dilaksanakan?",
        a: `OSCE UKMPPD kurang lebih  dilaksanakan selama 10 hari dengan peralatan sesuai dengan standart ujian OSCE.`
    },
    {
        q: "Apakah waktu bimbingan OSCE bersamaan dengan pelaksanaan bimbingan intensif UKMPPD/Kelas Terbang/UKMP2DG?",
        a: `Untuk bimbingan OSCE UKMPPD regio pada umumnya dilaksanakan di luar dari waktu bimbingan intensif UKMPPD berlangsung. Untuk bimbingan OSCE UKMPPD Regio, selama peserta mencukupi bimbingan bisa dilaksanakan dengan waktu sesuai dengan permintaan peserta namun pada umumnya pelaksanaan bimbingan OSCE akan dilaksanakan 1 minggu sebelum ujian OSCE berlangsung atau setelah bimbingan intensif UKMPPD berakhir. Untuk bimbingan OSCE UKMPPD Kelas Terbang akan dilaksanakan di sela-sela bimbingan intensif. Untuk bimbingan OSCE UKMP2DG bimbingan pada umumnya akan dilaksanakan di sela-sela bimbingan intensif.
        Ketentuan lain dijelaskan oleh tim akademik. 
        `
    },
    {
        q: "Bagaimana cara mendaftar bimbingan Preklinik?",
        a: `Pendaftaran bisa dilakukan melalui admin staff Ingenio Indonesia. Pendaftaran tersedia bagi peserta individu atau kelompok, bimbingan secara individu adalah bimbingan secara private (1 peserta) saja, sedangkan bimbingan secara kelompok berisikan lebih dari 2 sampai 15 peserta per kelompoknya. (lebih dari 15 peserta dikondisikan).`
    },
    {
        q: "Berapa jumlah pertemuan bimbingan preklinik akan dilaksanakan?",
        a: `Jumlah pertemuan akan disesuaikan dengan materi semester atau blok yang akan di ambil, dan kami sesuaikan dengan paket pertemuan yang disediakan dari tiap regio dimana peserta akan mengikuti bimbingan (konfirmasi kepada admin staff).`
    },
    {
        q: "Berapa lama durasi bimbingan Preklinik ?",
        a: `Durasi bimbingan preklinik kurang lebih 2 hingga maksimal 3 jam sesuai dengan materi dan kebutuhan peserta (tidak termasuk waktu ISHOMA).`
    },
    {
        q: "Bagaimana sistem penjadwalan bimbingan Preklinik sedangkan kami masih kuliah ?",
        a: `Untuk jadwal bimbingan Preklinik sangat fleksibel, bimbingan bisa dilaksanakan di jam kosong diluar jam kuliah. Jadwal bisa disesuaikan oelh permintaan peserta H-1 sesuai kesepakatan bersama antara anggota kelompok dengan tutor pengajar. Apabila peserta tidak memberikan konfirmasi keterlambatan ataupun membatalkan pertemuan maksimal 30 menit sebelum jam bimbingan di mulai, maka pada hari itu akan dihitung sebagai sebuah pertemuan.`
    },
    {
        q: "Apa saja yang akan kami dapatkan dari bimbingan preklinik di Ingenio?",
        a: `1.	Tutor yang berkompeten di bidangnya 
        2.	Materi disesuaikan dengan FK masing-masing
        3.	Pemahaman materi
        4.	Latihan soal untuk persiapan ujian mingguan, ujian akhir blok, dan ujian akhir semester atau pembahasan soal “by Request” (program menyesuaikan dengan masing-masing regio bimbingan).
        5.	Soft file materi atau hard copy materi (disesuaikan dengan regio bimbingan peserta dan program yang diikuti).
        `
    },
    {
        q: "Dimanakah tempat bimbingan akan dilaksanakan?",
        a: `Bimbingan saat ini dapat dilakukan online via Zoom. Atau untuk kelas offline dapat dilaksanakan di regio tertentu (hubungi Admin untuk ketersediaan kelas).`
    },
    {
        q: "Apakah kami boleh melakukan pembayaran preklinik secara bertahap/cicilan?",
        a: `Untuk sistem pembayaran preklinik silahkan untuk menghubungi admin staff Ingenio.`
    },
]

export default function Faq() {

    return (
        <React.Fragment>
            <section className="container-fluid">
                <div className="site-header site-header--menu-left landing-11-menu site-header--absolute" style={{ backgroundImage: 'url(/assets/landing/Genio2.png)', backgroundPositionY: 'bottom', backgroundPositionX: 'right', backgroundRepeat: 'no-repeat', paddingTop: '6rem', backgroundColor: '#ffcc00' }}>
                    <h5 className="text-center tentang_title py-5" style={{ paddingBottom: '2rem !important' }}>FAQ</h5>
                </div>
            </section>
            <section className="container-fluid bg-desktop" style={{ backgroundImage: 'url(/assets/landing/bg-value.png)' }}>
                <div className="container px-4" style={{ paddingTop: '10rem', width: '85%' }}>
                    <div className="row g-2" style={{ textAlign: 'left', paddingTop: '12rem', paddingBottom: '15rem' }}>
                        {
                            faqs.map((item, i) => {
                                return (
                                    <Collapsible className="txt16px" trigger={[item.q, <BsChevronDown style={{ width: '5rem', color: '#F8CA2B' }} />]}><br />
                                        <p>{item.a}</p><br />
                                    </Collapsible>
                                )
                            })
                        }
                        {/* <Collapsible className="txt16px" trigger={["Kapan saya dapat melakukan pendaftaran bimbingan UKMPPD/Kelas Terbang/UKMPPDG?", <BsChevronDown style={{ width: '5rem', color: '#F8CA2B' }} />]}><br />
                            <p>Bagi peserta yang telah memiliki anggota kelompok, bisa melakukan pendaftaran kapan saja.</p><br />
                            <p>Bagi peserta yang belum memiliki teman kelompok, kami akan membuka pendaftaran pada tanggal sebagai berikut:<br />
                                Batch 1 tanggal 1 November<br />
                                Batch 2 tanggal 1 Februari<br />
                                Batch 3 tanggal 1 Mei<br />
                                Batch 4 tanggal 1 Agustus<br />
                                (konfirmasi kepada admin staff).
                            </p>
                        </Collapsible>
                        <Collapsible className="txt16px" trigger={["Tanggal berapa bimbingan UKMPPD/Kelas Terbang/UKMP2DG Batch 1/Batch 2/Batch 3/Batch 4 berlangsung?", <BsChevronDown style={{ width: '5rem', color: '#F8CA2B' }} />]}><br />
                            <p>Comming Soon !</p><br />
                        </Collapsible>
                        <Collapsible className="txt16px" trigger={["Apakah saya dapat mengikuti bimbingan UKMPPD/Kelas Terbang/UKMP2D di Ingenio dengan mendaftar secara individu?", <BsChevronDown style={{ width: '5rem', color: '#F8CA2B' }} />]}><br />
                            <p>Comming Soon !</p><br />
                        </Collapsible>
                        <Collapsible className="txt16px" trigger={["Berapa jumlah minimal kepesertaan bimbingan UKMPPD/Kelas Terbang/UKMP2DG di Ingenio?", <BsChevronDown style={{ width: '5rem', color: '#F8CA2B' }} />]}><br />
                            <p>Comming Soon !</p><br />
                        </Collapsible>
                        <Collapsible className="txt16px" trigger={["Berapa jumlah maksimal kepesertaan bimbingan UKMPPD/Kelas Terbang/UKMP2DG?", <BsChevronDown style={{ width: '5rem', color: '#F8CA2B' }} />]}><br />
                            <p>Comming Soon !</p><br />
                        </Collapsible>
                        <Collapsible className="txt16px" trigger={["Berapa lama bimbingan belajar UKMPPD/Kelas Terbang/UKMP2DG di Ingenio?", <BsChevronDown style={{ width: '5rem', color: '#F8CA2B' }} />]}><br />
                            <p>Comming Soon !</p><br />
                        </Collapsible>
                        <Collapsible className="txt16px" trigger={["Bagaimana sistem bimbingan belajar UKMPPD/Kelas Terbang/UKMP2DG di Ingenio?", <BsChevronDown style={{ width: '5rem', color: '#F8CA2B' }} />]}><br />
                            <p>Comming Soon !</p><br />
                        </Collapsible> */}
                    </div>
                </div>
            </section>
        </React.Fragment >
    )
}

