import React from 'react';
import {
    Partnership,
} from '../components/partnership';
import {
    IngenioNav2,
    IngenioFooter2
} from '../components/layout'

export default function PartnershipPage() {
    return (
        <React.Fragment>
            <IngenioNav2 />
            <div style={{ backgroundColor: 'white' }}>
                <Partnership />
            </div>
            <IngenioFooter2 />
        </React.Fragment>

    )
}