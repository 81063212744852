import React, { useState, useEffect } from 'react';
import { Form, FormGroup } from 'react-bootstrap';
import Button from '@restart/ui/esm/Button';
import DataTable from 'react-data-table-component';
import { Link, useHistory } from 'react-router-dom';
import { SideBar } from '../../../components/layout/dashboard';
import { addCommas, contactLink } from '../../../helpers/utils'
import CustomDatatable from '../../../components/common/CustomDatatable';
import ElearningService from '../../../services/elearning';
import moment from 'moment-timezone';
import Swal from 'sweetalert2';
import '../akademik/tryout/Styles/style.css'

export default function KelasTersedia(props) {
    document.title = "INGENIO Indonesia";
    document.body.style.backgroundColor = "#f3f4f6";
    const [program, setProgram] = useState('')

    // Filtering data
    const [isLoading, setIsLoading] = useState(true);
    const [hasNext, setHasNext] = useState(false)
    const [hasPrev, setHasPrev] = useState(false)
    const [count, setCount] = useState(0);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [keyword, setKeyword] = useState('');
    const [userId, setUserId] = useState('');
    const [kelasTersedia, setKelasTersedia] = useState([])
    const [elearningPrograms, setElearningPrograms] = useState([])
    const [idElearningProgram, setIdElearningProgram] = useState('');

    const history = useHistory();

    const dropdown = {
        borderRadius: "10px",
        height: 50,
        padding: "25px",
    };
    const columns = [
        {
            name: 'NO.',
            field: 'no',
            index: true,
            selector: row => row.no,
            sortable: true,
            
        },
        {
            name: 'NAMA KELAS',
            field: 'nama_kelas',
            selector: row => row.namakelas,
            sortable: true,
            
        },
        {
            name: 'PROGRAM BIMBINGAN',
            render: true,
            component: (row) => {
                return (
                    <td className='main-table__bodyRow'>{row.elearning_program.nama_program}</td>
                )
            },
            selector: row => row.programbimbingan,
            sortable: true,
            
        },
        {
            name: 'TANGGAL PELAKSANAAN',
            render: true,
            component: (row) => {
                return (
                    <td className='main-table__bodyRow'>{moment(row.elearning_program.tanggal_mulai).format('DD-MMM-YYYY')}</td>
                )
            },
            selector: row => row.programbimbingan,
            sortable: true,
            
        },
        {
            name: 'REGIO',
            field: 'nama_regio',
            selector: row => row.regio,
            sortable: true,
            
        },
        {
            name: 'BIAYA',
            render: true,
            component: (row) => {
                return (
                    <td className='main-table__bodyRow'>{addCommas(row.harga_kelas)}</td>
                )
            },
            selector: row => row.biaya,
            sortable: true,
            
        },
        {
            name: 'KAPASITAS',
            render: true,
            component: (row) => {
                return (
                    <td className='main-table__bodyRow'>{`${row.kapasitas} Orang`}</td>
                )
            },
            selector: row => row.kapasitas,
            sortable: true,
            minWidth: '150px'
        },
        {
            name: '',
            render: true,
            component: (row) => {
                return (
                    <td className='main-table__bodyRow'>
                        {
                            row.elearning_participants.length > 0 ?
                                checkValidationUserId(userId, row.elearning_participants) ? 
                                <Link className="pilih-tryout p-2 text-center m-1" 
                                    to={{
                                        pathname: `/dashboard/e-learning/kelas-saya/details/${row.id}`,
                                        state: {
                                            nama_program: row.elearning_program.nama_program,
                                            nama_regio: row.nama_regio,
                                            nama_kelas: row.nama_kelas
                                        }
                                    }}>
                                    Akses Kelas
                                </Link>
                                :
                                <Link className="pilih-tryout p-2 text-center m-1" to={`/dashboard/e-learning/kelas-tersedia/details/${row.id}`}>
                                    Beli Kelas
                                </Link>
                            :
                            <Link className="pilih-tryout p-2 text-center m-1" to={`/dashboard/e-learning/kelas-tersedia/details/${row.id}`}>
                                Beli Kelas
                            </Link>
                        }
                        
                    </td>
                )
            },
            selector: row => row.kapasitas,
            sortable: true,
            minWidth: '180px'
        },
        // {
        //     cell: () => <Link className="pilih-tryout p-2 text-center m-1" to="/dashboard/e-learning/kelas-tersedia/details">Pilih Kelas</Link>,
        //     ignoreRowClick: true,
        //     allowOverflow: true,
        //     button: true,
        // },
    ];

    const checkValidationUserId = (currentUserId, elearningParticipants = []) => {
        for (let i = 0; i < elearningParticipants.length; i++) {
            const data = elearningParticipants[i];
            if(data.id_peserta === currentUserId) {
                if(data.is_active) {
                    return true
                }
            }
        }
        return false;
    }

    const getListKelasTersedia = (params = '') => {
        setIsLoading(true)
        ElearningService.getListKelasTersediaParticipant(params).then(resp => {
            if(resp.data.success === true) {
                const response = resp.data;
                setKelasTersedia(response.data);

                setPage(response.meta.page)
                setPerPage(response.meta.perPage)
                setCount(response.meta.count)

                setHasNext(response.meta.hasNext)
                setHasPrev(response.meta.hasPrev);

                if(response.meta.userId) {
                    setUserId(response.meta.userId)
                }
            }
            setIsLoading(false)

            if(resp.data.code === 401){
                localStorage.clear()
                history.push('/signin');
                return;
            }
        }).catch(err => {
            setIsLoading(false)
            console.error(err)
        })
    }

    const getListElearningProgramDropdown = () => {
        ElearningService.getListElearningProgramAkademik().then(resp => {
            const response = resp.data
            setElearningPrograms(response.data)
        }).catch(err => {
            console.error(err)
        })
    }

    useEffect(() => {
        getListKelasTersedia();
        getListElearningProgramDropdown();
    }, [])

    return (
        <React.Fragment>
            <SideBar />
            <div className="md:ml-64" style={{ backgroundColor: '#F3F4F6' }}>
                {/* <div className="bg-orange px-3 md:px-8 py-1 h-40">
                    <div className="container md:pr-8 md:pl-10">
                        <h3 className="text-left tracking-wider title-dashboard">Selamat datang di Kelas Bimbingan Intensif Ingenio Indonesia</h3>
                        <br />
                        <h3 className="text-left tracking-wider">Anda dapat menghubungi Admin terlebih dahulu untuk memastikan kelas dan program yang Anda daftarkan. <br /> <br />Kontak Admin <a href={contactLink()} target={'_blank'}> <i class="fa fa-whatsapp" aria-hidden="true"></i></a></h3>
                    </div>
                </div> */}

                <div className="container md:pr-8 md:pl-10"  style={{position: 'absolute', top: '80px'}}>
                    <h3 className="text-left tracking-wider title-dashboard">Selamat datang di Kelas Bimbingan Intensif Ingenio Indonesia</h3>
                    <br />
                    <h3 className="text-left tracking-wider">Anda dapat menghubungi Admin terlebih dahulu untuk memastikan kelas dan program yang Anda daftarkan. <br /> <br />Kontak Admin <a href={contactLink()} target={'_blank'}> <i class="fa fa-whatsapp" aria-hidden="true"></i></a></h3>
                </div> 

                <div className="px-3 md:px-8 mt-5">
                <div className="container mx-auto max-w-full">
                    <div className="flex-2 flex-col">
                    <div className="flex-2 mb-4">
                        <form onSubmit={(e) => {
                            e.preventDefault()
                            getListKelasTersedia(`page=${page}&perPage=${perPage}&keyword=${keyword}&program=${program}`)
                        }}>
                        <div className="flex flex-row">
                            <div className="bg-white px-4 py-3 mr-3" style={{ borderRadius: '20px' }}>
                            <input
                                type="text"
                                name=""
                                value={keyword}
                                onChange={(e) => setKeyword(e.target.value)}
                                style={{ backgroundColor: '#F3F4F6', width: '25rem', borderRadius: '12px', padding: '0.75rem', border: 'none !important', paddingLeft: '10px', fontFamily: 'Quicksand' }}
                                className="mr-3"
                                placeholder="Nama kelas"
                                id=""
                            />
                            <select
                                as="select"
                                aria-label="Program"
                                aria-describedby="basic-addon1"
                                className="my-tryout px-3 py-2 mx-3"
                                style={{ fontFamily: 'Quicksand' }}
                                onChange={(e) => setProgram(e.target.value)}
                            >
                                <option value="">Pilih Program</option>
                                {elearningPrograms.map(item =>
                                    <option key={item.key} value={item.nama_program}>{item.nama_program}</option>
                                )};
                            </select>
                            <Button type="submit" className="pilih-tryout mx-3">Cari</Button>
                            </div>
                        </div>
                        </form>
                    </div>
                    <div className="flex-2">
                        <CustomDatatable 
                            filteredItems={kelasTersedia} 
                            columns={columns}
                            isLoading={isLoading}
                            page={page}
                            perPage={perPage}
                            hasPrev={hasPrev}
                            hasNext={hasNext}
                            count={count}
                            onTableChange={(e, data) => {
                                switch (e) {
                                case 'rowPerPageChange':
                                    setPerPage(data.perPage)
                                    getListKelasTersedia(`page=${page}&perPage=${data.perPage}&keyword=${keyword}&program=${program}`)
                                    break;
                                case 'rowPageChange':
                                    setPage(data.page)
                                    getListKelasTersedia(`page=${data.page}&perPage=${perPage}&keyword=${keyword}&program=${program}`)
                                    break;
                                
                                default:
                                    break;
                                }
                            }}
                        />

                    </div>
                    </div>
                </div>
                </div>

            </div>
        </React.Fragment >

    )
}