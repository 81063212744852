import React, { useEffect, useState } from 'react';

import { FormGroup, FormControl, Form } from 'react-bootstrap';
import AkademikSidebar from '../../../../components/layout/dashboard/akademik/AkademikSideBar';

export default function DetailPesertaEcourse() {
    document.title = "INGENIO Indonesia";
    var baseUrl = window.location.origin;
    document.body.style.backgroundColor = "#f3f4f6";

    return (
        <React.Fragment>
            <AkademikSidebar />
            <div className="md:ml-64">
                <div className="bg-orange px-3 md:px-8 py-1" style={{ height: '2.5rem' }}>
                </div>
                <div className="py-4 px-3 md:px-8 -mt-12">
                    <div className="container mx-auto max-w-full">
                        <div className="row">
                            <div className="col-lg-11 mx-auto">
                                <div className="cards" style={{height: '150vh', paddingRight: '.3rem'}}>
                                    <div className="row">
                                        <div className="col-12 flex gap-4" style={{padding: '2.4rem 4.3rem 0 4.3rem'}}>
                                            <img src={baseUrl + "/assets/img/jurnal-1.png"} className="imgDetail" />
                                            <div className="title-24-700 mt-2">Detail Peserta E-Course</div>
                                        </div>
                                        <div className="col-12 mb-4" style={{padding: '0 4.3rem'}}>
                                            <div className="borderBottomGrey"></div>
                                        </div>
                                        <div className="col-12">
                                            <div className="cardsScroll scrollY" style={{height: '122vh', padding: '0 4.3rem'}} id="scrollBar">
                                                <div className="row">
                                                    <div className="col-lg-3 mb-4 relative">
                                                        <div className="sticky-top">
                                                            <img src={baseUrl + "/assets/img/profile-image-default.png"} className="object-center mx-auto"></img>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-9">
                                                        <div className="row mb-4">
                                                            <div className="col-md-6 lh-detail" style={{paddingLeft: '30px'}}>
                                                                <h4 className="font-bold">Nama Lengkap Peserta</h4>
                                                            </div>
                                                            <div className="col-md-6 lh-detail">
                                                                <h4>nama_lengkap_peserta</h4>
                                                            </div>
                                                        </div>
                                                        <div className="row mb-4">
                                                            <div className="col-md-6 lh-detail" style={{paddingLeft: '30px'}}>
                                                                <h4 className="font-bold">Nama Panggilan</h4>
                                                            </div>
                                                            <div className="col-md-6 lh-detail">
                                                                <h4>nama_panggilan</h4>
                                                            </div>
                                                        </div>
                                                        <div className="row mb-4">
                                                            <div className="col-md-6 lh-detail" style={{paddingLeft: '30px'}}>
                                                                <h4 className="font-bold">Nomor HP</h4>
                                                            </div>
                                                            <div className="col-md-6 lh-detail">
                                                                <h4>nomor_hp</h4>
                                                            </div>
                                                        </div>
                                                        <div className="row mb-4">
                                                            <div className="col-md-6 lh-detail" style={{paddingLeft: '30px'}}>
                                                                <h4 className="font-bold">Akun Instagram</h4>
                                                            </div>
                                                            <div className="col-md-6 lh-detail">
                                                                <h4>akun_ig</h4>
                                                            </div>
                                                        </div>
                                                        <div className="row mb-4">
                                                            <div className="col-md-6 lh-detail" style={{paddingLeft: '30px'}}>
                                                                <h4 className="font-bold">Jenis Kelamin</h4>
                                                            </div>
                                                            <div className="col-md-6 lh-detail">
                                                                <h4>jenis_kelamin</h4>
                                                            </div>
                                                        </div>
                                                        <div className="row mb-4">
                                                            <div className="col-md-6 lh-detail" style={{paddingLeft: '30px'}}>
                                                                <h4 className="font-bold">Email</h4>
                                                            </div>
                                                            <div className="col-md-6 lh-detail">
                                                                <h4>email</h4>
                                                            </div>
                                                        </div>
                                                        <div className="row mb-4">
                                                            <div className="col-md-6 lh-detail" style={{paddingLeft: '30px'}}>
                                                                <h4 className="font-bold">Asal Fakultas</h4>
                                                            </div>
                                                            <div className="col-md-6 lh-detail">
                                                                <h4>fakultas</h4>
                                                            </div>
                                                        </div>
                                                        <div className="row mb-4">
                                                            <div className="col-md-6 lh-detail" style={{paddingLeft: '30px'}}>
                                                                <h4 className="font-bold">Asal Universitas</h4>
                                                            </div>
                                                            <div className="col-md-6 lh-detail">
                                                                <h4>asal_univ</h4>
                                                            </div>
                                                        </div>
                                                        <div className="row mb-4">
                                                            <div className="col-md-6 lh-detail" style={{paddingLeft: '30px'}}>
                                                                <h4 className="font-bold">Angkatan</h4>
                                                            </div>
                                                            <div className="col-md-6 lh-detail">
                                                                <h4>pend_terakhir</h4>
                                                            </div>
                                                        </div>
                                                        <div className="row mb-4">
                                                            <div className="col-md-6 lh-detail" style={{paddingLeft: '30px'}}>
                                                                <h4 className="font-bold">Alamat Lengkap KTP</h4>
                                                            </div>
                                                            <div className="col-md-6 lh-detail">
                                                                <h4>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Vero, soluta? </h4>
                                                            </div>
                                                        </div>
                                                        <div className="row mb-4">
                                                            <div className="col-md-6 lh-detail" style={{paddingLeft: '30px'}}>
                                                                <h4 className="font-bold">Alamat Lengkap Domisili</h4>
                                                            </div>
                                                            <div className="col-md-6 lh-detail">
                                                                <h4>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corrupti magnam saepe culpa recusandae similique temporibus numquam dolorem quaerat nesciunt perferendis.  </h4>
                                                            </div>
                                                        </div>
                                                        <div className="row mb-4">
                                                            <div className="col-md-6 lh-detail" style={{paddingLeft: '30px'}}>
                                                                <h4 className="font-bold">Negara</h4>
                                                            </div>
                                                            <div className="col-md-6 lh-detail">
                                                                <h4>Afrika</h4>
                                                            </div>
                                                        </div>
                                                        <div className="row mb-4">
                                                            <div className="col-md-6 lh-detail" style={{paddingLeft: '30px'}}>
                                                                <h4 className="font-bold">Status Hamil / Materi yang Sulit Dipahami</h4>
                                                            </div>
                                                            <div className="col-md-6 lh-detail">
                                                                <h4>status_hamil</h4>
                                                            </div>
                                                        </div>
                                                        <div className="row mb-4">
                                                            <div className="col-md-6 lh-detail" style={{paddingLeft: '30px'}}>
                                                                <h4 className="font-bold">Mengetahui Ingenio Dari</h4>
                                                            </div>
                                                            <div className="col-md-6 lh-detail">
                                                                <h4>info_ingenio</h4>
                                                            </div>
                                                        </div>
                                                        <div className="row mb-4">
                                                            <div className="col-md-6 lh-detail" style={{paddingLeft: '30px'}}>
                                                                <h4 className="font-bold">Bimbingan yang Pernah di Ikuti</h4>
                                                            </div>
                                                            <div className="col-md-6 lh-detail">
                                                                <h4>bimbingan</h4>
                                                            </div>
                                                        </div>
                                                        <div className="row mb-4">
                                                            <div className="col-12 mb-4" style={{paddingLeft: '30px'}}>
                                                                <div className="title-20-700">*Form khusus untuk peserta yang mengambil UKMPPD</div>
                                                            </div>
                                                            <div className="col-md-6 lh-detail" style={{paddingLeft: '30px'}}>
                                                                <h4 className="font-bold">IPK Terakhir</h4>
                                                            </div>
                                                            <div className="col-md-6 lh-detail">
                                                                <h4>3.5</h4>
                                                            </div>
                                                        </div>
                                                        <div className="row mb-4">
                                                            <div className="col-md-6 lh-detail" style={{paddingLeft: '30px'}}>
                                                                <h4 className="font-bold">Ujian Ke</h4>
                                                            </div>
                                                            <div className="col-md-6 lh-detail">
                                                                <h4>Ujian Ke-2</h4>
                                                            </div>
                                                        </div>
                                                        <div className="row mb-4">
                                                            <div className="col-md-6 lh-detail" style={{paddingLeft: '30px'}}>
                                                                <h4 className="font-bold">Materi yang sulit dipahami</h4>
                                                            </div>
                                                            <div className="col-md-6 lh-detail">
                                                                <h4>materi_sulit</h4>
                                                            </div>
                                                        </div>
                                                        <div className="row mb-4">
                                                            <div className="col-md-6 lh-detail" style={{paddingLeft: '30px'}}>
                                                                <h4 className="font-bold">Kesulitan yang dirasakan</h4>
                                                            </div>
                                                            <div className="col-md-6 lh-detail">
                                                                <h4>kesulitan</h4>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment >

    )
}