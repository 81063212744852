import React from 'react'
import styles from '../../index.module.css';
import { DaftarButton, AboutCard, AboutPlayer } from './index';

export default function About(props) {
  let baseURL = window.location.origin;
  let dataAbout = [{
      id: 1,
      icon: 'teach 1.png',
      text: 'Mengajar dengan Pola Pikir Bukan Hanya Soal atau Menghafal Teori',
    },
    {
      id: 2,
      icon: 'open-book 1.png',
      text: 'Materi Sesuai dengan Standar Uji Kompetensi Terbaru dan Guideline TerUpdate',
    },
    {
      id: 3,
      icon: 'mentor 1.png',
      text: 'FREE Konsultasi dengan Tutor Dokter / Dokter Gigi / Bidan Terpilih yang Terstandarisasi dari Universitas Terpilih di Indonesia',
    },
    {
      id: 4,
      icon: 'clock 1.png',
      text: 'Jadwal Fleksibel Menyesuaikan dengan Kebutuhan Belajar Para Peserta',
    }
  ]

  return (
    <React.Fragment>
      <section className="container-fluid">
        <div className="container py-5">
          <div className="row align-items-center g-2 py-1">
              <h1 className="text-center about"><span className={styles.textYellow}>INGENIO</span> INDONESIA</h1>
              <div className="col-12 col-md-8 mx-auto">
                <img src="/assets/landing/motto.png" alt="Its Not That Hard Seriously!" className="px-2" style={{width: '100%'}}></img>
              </div>
              
              
              {/* <div className="tag-c" style={{backgroundImage: `url('/assets/landing/border.png')`, backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundSize: '100%, 100%'}}>It's Not That Hard <b className={styles.textYellow}>Seriously</b>! </div> */}
          </div>
          <DaftarButton />
        </div>
        <div className="container py-5 about-section">
          <div className="row align-items-center g-4 py-1">
            {dataAbout.map(about => <div class="col-12 col-md-6 col-lg-6 col-xl-3"><AboutCard key={about.id} data={about} /></div>)}
          </div>
        </div>
      </section>
      <section className="container-fluid" id="about-section-container">
        <div className="svg-container">
          <svg className="circles-about" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 873" fill="none">
            <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="1440" height="873">
              <rect width="1920" height="873" fill="black"/>
            </mask>
            <g mask="url(#mask0)">
              <circle cx="54.5" cy="224.5" r="204.5" fill="url(#paint1_linear)"/>
              <circle cx="1439.5" cy="541.5" r="296.5" fill="url(#paint1_linear)"/>
            </g>
            <defs>
              <linearGradient id="paint0_linear" x1="55" y1="-259.5" x2="55" y2="450.5" gradientUnits="userSpaceOnUse">
                <stop stopColor="#FCA61F"/>
                <stop offset="1" stopOpacity="0.8"/>
              </linearGradient>
              <linearGradient id="paint1_linear" x1="1440.22" y1="-160.241" x2="1440.22" y2="869.172" gradientUnits="userSpaceOnUse">
                <stop stopColor="#FCA61F"/>
                <stop offset="1" stopColor="#000000" stopOpacity="1"/>
              </linearGradient>
            </defs>
          </svg>
        </div>
        <div className="container py-5 about-section">
          {/* <img src={baseURL}></img> */}
          <div className="row align-items-center g-2 py-4 px-4">
            <AboutPlayer />
            <div class="col-12 text-center">
              <h2 className="text-center jd-home3 my-4 pb-4 pt-2" style={{textAlign: 'center'}}>Apa itu <span className={styles.textYellow}>INGENIO</span> INDONESIA ?</h2>
              <h4 className="about-text">Program Bimbingan Belajar Kedokteran dan Tenaga Medis
                <br/><br/> <span className={styles.textYellow} style={{fontSize: "1.75rem"}}>TERBAIK DI INDONESIA</span> <br/><br/>
                Dengan Program Terlengkap Berdasarkan Pola Pikir Sistematis
              </h4>
            </div>
          </div>
        </div>
      </section> 
    </React.Fragment>
  )
}