import React from 'react';
import Card from '@material-tailwind/react/Card';
import CardHeader from '@material-tailwind/react/CardHeader';
import CardBody from '@material-tailwind/react/CardBody';
import moment from 'moment';
import { useState } from 'react';
import { useEffect } from 'react';

export default function MyScheduleCard () {
  const [startDay, setStartDay] = useState(0);
  const [endDay, setEndDay] = useState(0);

  const [currentDate, setCurrentDate] = useState(0)
  const [currentMonth, setCurrentMonth] = useState(0)
  const [currentYear, setCurrentYear] = useState(0)

  const [nowDate, setNowDate] = useState(0)
  const [nowMonth, setNowMonth] = useState(0)
  const [nowYear, setNowYear] = useState(0)

  const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']

  const renderDateOfMonth = (startDayOfMonth, endDate) => {
    // const now = new Date();
    // const firstDay = new Date(2018, 9, 1);
    // console.log(firstDay.getDay())
    let dateOfMonths = [];
    let dateCounter = 1;
    let i = 0
    while (dateCounter <= endDate) {
      if((i) >= startDayOfMonth) {
        let d = <li>{dateCounter}</li>
        if(currentMonth === nowMonth && currentYear == nowYear) {
          if(nowDate === dateCounter) {
            d = <li><span className="active">{dateCounter}</span></li>
          }
        }
        dateOfMonths.push(d)
        dateCounter++;
        continue
      }
      dateOfMonths.push(<li></li>)
      i++
    }
    return dateOfMonths
  }

  useEffect(() => {
    const now = new Date();

    setCurrentMonth(now.getMonth())
    setCurrentYear(now.getFullYear())

    setNowMonth(now.getMonth())
    setNowYear(now.getFullYear())
    setNowDate(now.getDate())

  }, [])

  useEffect(() => {
    const firstDay = new Date(currentYear, currentMonth, 1);
    const lastDay = new Date(currentYear, currentMonth + 1, 0);

    setStartDay(firstDay.getDay())
    setEndDay(lastDay.getDate())

  }, [currentMonth, currentYear])

  const handlingPrev = () => {
    let currMonth = currentMonth
    let currYear = currentYear
    currMonth--;
    if(currMonth < 0) {
      currMonth = 11
      currYear--;
    }

    setCurrentMonth(currMonth);
    setCurrentYear(currYear);
  }

  const handlingNext = () => {
    let currMonth = currentMonth
    let currYear = currentYear
    currMonth++;
    if(currMonth > 11) {
      currMonth = 0
      currYear++;
    }

    setCurrentMonth(currMonth);
    setCurrentYear(currYear);
  }

  return (
    <Card>
        <CardHeader contentPosition="left" className="bg-orange border-4 border-white-600">
            <h3 className="text-white text-2xl">Calendar</h3>
        </CardHeader>
        <CardBody>
            <div className="relative h-50">
              <div className="month">      
                <ul>
                  <li onClick={() => handlingPrev()} className="prev">&#10094;</li>
                  <li onClick={() => handlingNext()} className="next">&#10095;</li>
                  <li>
                    {months[currentMonth]}<br></br>
                    <span style={{fontSize: '18px'}}>{currentYear}</span>
                  </li>
                </ul>
              </div>
              <ul className="weekdays">
                <li>Sun</li>
                <li>Mon</li>
                <li>Tue</li>
                <li>Wed</li>
                <li>Thu</li>
                <li>Fri</li>
                <li>Sat</li>
              </ul>

              <ul className="days">  
                {
                  renderDateOfMonth(startDay, endDay)
                }
                {/* <li>1</li>
                <li>2</li>
                <li>3</li>
                <li>4</li>
                <li>5</li>
                <li>6</li>
                <li>7</li>

                <li>8</li>
                <li>9</li>
                <li><span className="active">10</span></li>
                <li>11</li>
                <li>12</li>
                <li>13</li>
                <li>14</li>

                <li>15</li>
                <li>16</li>
                <li>17</li>
                <li>18</li>
                <li>19</li>
                <li>20</li>
                <li>21</li>

                <li>22</li>
                <li>23</li>
                <li>24</li>
                <li>25</li>
                <li>26</li>
                <li>27</li>
                <li>28</li>

                <li>29</li>
                <li>30</li>
                <li>31</li>
                <li>32</li>
                <li>33</li>
                <li>34</li>
                <li>35</li> */}
              </ul>
            </div>
        </CardBody>
    </Card>
  );
}