import Button from '@material-tailwind/react/Button';
import React, { useState } from 'react';
import { FormGroup, FormControl, Form } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import { Link } from 'react-router-dom';
import { BsPencilSquare, BsFillTrashFill, BsDownload, BsFillFileEarmarkArrowUpFill } from 'react-icons/bs';
import {
    SideBar
  } from '../../components/layout/dashboard';
import Swal from 'sweetalert2';

export default function UploadData() {
    var baseUrl = window.location.origin;

    document.title = "INGENIO Indonesia";
    const [program, setProgram] = useState('');
    const dropdown = {
        borderRadius: "10px",
        height: 50,
        padding: "25px",
    };
    const columns = [
        {
            name: 'NO.',
            selector: row => row.no,
            sortable: true,
            width: '6.2vw',
        },
        {
            name: 'NAMA',
            selector: row => row.nama,
            sortable: true,
            // width: '15vw',
        },
        {
            name: 'TANGGAL',
            selector: row => row.tanggal,
            sortable: true,
            // width: '15vw',
        },
        {
            cell: () => <Link className="btnTable3 gap-2" to=""><BsPencilSquare size="16"/>Edit</Link>,
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
            width: '8vw',
        },
        {
            cell: () => <Link className="btnTable3 gap-2" to=""><BsDownload size="20"/>Download</Link>,
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
            width: '10.2vw',
        },
        {
            cell: () => <Link className="btnTable3 gap-2" onClick={AreYouSure}><BsFillTrashFill size="16"/>Delete</Link>,
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
            width: '10vw',
        },
    ];
    const data = [
        {
            no: 1,
            nama: 'Lorem.',
            tanggal: '10-10-2022',
        },
        {
            no: 2,
            nama: 'Lorem.',
            tanggal: '10-10-2022',
        },
    ];

    const customStyles = {
        table: {
          style: {
            borderRadius: '15px',
            border: '1px solid #BDC1C9',
          },
        },
        head: {
          style: {
            fontSize: '1.25rem',
            textTransform: "uppercase",
            color: '#6B7280',
            backgroundColor: '#f3f4f6',
            border: '1px solid #BDC1C9',
            borderTopLeftRadius: '15px',
            borderTopRightRadius: '15px',
          },
        },
        pagination: {
          style: {
            color: '#6B7280',
            fontSize: '1.1rem',
            minHeight: '56px',
            backgroundColor: '#f3f4f6',
            border: '1.25px solid #BDC1C9',
            borderBottomLeftRadius: '15px',
            borderBottomRightRadius: '15px',
          },
        },
    };

    const AreYouSure = () => {
        Swal.fire({  
            title: 'Sudah Yakin?',  
            text: 'Apakah Anda Yakin Ingin Menghapus Data Ini?',  
            icon: 'warning',  
            showCancelButton: true,  
            cancelButtonText: 'Tidak',  
            confirmButtonText: 'Ya'  
        })
    }

    const Succeed = () => {
        Swal.fire({  
            title: 'Data Berhasil Tersimpan !',  
            text: '',  
            icon: 'success',  
            showCancelButton: false,              
            confirmButtonText: 'OK'  
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.reload();
          }
        })
    }

    return (
        <React.Fragment>
            <SideBar />
            <div className="md:ml-64 tutorDataKelas" style={{ backgroundColor: '#F3F4F6' }}>
                <div className="bg-orange md:px-8 py-5" style={{height: '250px', position: 'relative', padding: '30px'}}>
                    <h1 className="tracking-wider title-24-600">Upload Data</h1>
                    <h2 className="tracking-wider title-20-700 mt-5">Selamat Datang Tutor INGENIO !</h2>
                    <img src={baseUrl + "/assets/logo/evaluasikelas.png"} className="img-opacity"></img>
                </div>

                <div className="px-3 md:px-8 mt-4 pb-5">
                    <div className="container mx-auto max-w-full">
                        <div className="row">
                            <div className="col-12 mt-4 mb-5">
                                <div className="cards pd-InJurnal">
                                    <div className="row">
                                        <div className="col-12 mb-4">
                                            <div className="title-20-700">Upload Data Tutor</div>
                                            <div className="borderBottomGrey"></div>
                                        </div>
                                        <div className="col-12">
                                            <div className="title-18-500 mb-1">Nama</div>
                                            <input type="text" class="formInputJurnal" placeholder="masukkan nama" />
                                        </div>
                                        <div className="col-12 mt-3">
                                            <div className="title-18-500">Upload<span className="txtpdf ml-2">(PDF)</span></div>
                                            <div className="relative mt-1">
                                                <input type="file" id="file" className="custom-file-input" style={{ position: 'absolute', left: '2rem' }} />
                                                <label htmlFor="file" className="labelFile">Upload File <BsFillFileEarmarkArrowUpFill size="18px"/></label>
                                            </div>
                                        </div>
                                        <div className="col-12 d-grid mt-5">
                                            <Button type="submit" onClick={Succeed} className="btnSubmit" size="lg">
                                                <span className="txt-btn">Submit</span>
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
   
                            <div className="col-12 my-2">
                                <FormGroup>
                                    <div className="SearchTable">
                                        <input type="text" name="" className="inputSearchTable" placeholder="&#x1F50E;&#xFE0E; Search..." id="" />
                                        <Button className="btnSearchTable">Cari</Button>
                                    </div>
                                </FormGroup>
                            </div>
                            <div className="col-12 mt-3">
                                <DataTable columns={columns} customStyles={customStyles} data={data} pagination />
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </React.Fragment >

    )
}