import React, {useState, useRef} from 'react';
import { useHistory } from "react-router-dom";
import {
  SideBar
} from '../../components/layout/dashboard';
import { useForm, useFormState} from "react-hook-form";
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import Swal from 'sweetalert2';

import { connect } from "react-redux";
import { getProfileStudent, updateProfileStudent, uploadAvatar } from '../../store/actions/auth';
import CircularProgress from "react-cssfx-loading/lib/CircularProgress";
import { Modal, Button } from 'react-bootstrap';
import ElearningService from '../../services/elearning'
import axios from 'axios';

function ProfilePage (props) {
  document.title = "INGENIO Indonesia";
  var baseUrl = window.location.origin;
  const [openTab, setOpenTab] = React.useState(1);
  const [selectedFile, setSelectedFile] = useState({});
	const [isFilePicked, setIsFilePicked] = useState(false);
  const [isSelected, setIsSelected] = useState(false);
  const [isBimbel, setIsBimbel] = useState(false)
  const [formError, setFromError] = useState(null)
  const [otp, setOtp] = useState('');
  const [otpError, setOtpError] = useState(null)
  const [isRequestOtp, setIsRequestOtp] = useState(false)
  const [isUploadImage, setIsUploadImage] = useState(false)

  const [dataProfile, setDataProfile] = useState({});

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [keyword, setKeyword] = useState('');
  const [districts, setDistricts] = useState([]);
  const [selectedDistrict, setSelectedDistrict] = useState(null)
  const [showSelectDistricts, setShowSelectDistricts] = useState(false)

  const [isDisabledRequestOtp, setDisabledRequestOtp] = useState(false)
  const [timerOtp, setTimerOtp] = useState(0)

  const {dispatch} = props

  const didMountRef = useRef(false);
  const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
  const validationSchema = Yup.object().shape({
      full_name: Yup.string().required('Fullname is required'),
      nick_name: Yup.string()
        .required('Nickname is required')
        .min(2, 'Nickname must be at least 2 characters')
        .max(20, 'Nickname must not exceed 20 characters'),
      email: Yup.string()
        .required('Email is required')
        .email('Email is invalid'),
      phone_number: Yup.string()
          .matches(phoneRegExp, 'Phone number is not valid'),
      university: Yup.string()
          .required('Universitas is required'),
      faculty: Yup.string()
          .required('Fakultas is required'),
      gender: Yup.string()
          .required('Gender is required'),
      generation: Yup.string()
          .required('Generation is required'),
      education: Yup.string()
          .required('Education is required'),
      ktp_address: Yup.string()
          .required('Alamat KTP is required'),
      domicile_address: Yup.string()
          .required('Alamat Domisili is required'),
      nationality: Yup.string()
          .required('Alamat Domisili is required'),
      knowIngenio: Yup.string()
          .required('This field is required'),
      remarks: Yup.string()
          .required('This field is required'),
  });
  const { 
    register, 
    handleSubmit, 
    watch, 
    reset, 
    setValue,
    formState: { errors, isSubmitSuccessful, submitCount, isSubmitted } 
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  React.useEffect(() => {
    if (didMountRef.current){
        if(!isSubmitSuccessful && submitCount > 0) {
            Fail();
        }
    } else {
        didMountRef.current = true;
    }
  }, [submitCount]);

  React.useEffect(() => {
    getListDistrictSuggest();
  }, [keyword])

  const getListDistrictSuggest = () => {
    
    ElearningService.getListDistrictSuggest(keyword).then(resp => {
      const listDistricts = resp.data.data
      setDistricts(listDistricts.map(item => {return `${item.kecamatan.toUpperCase()}, ${item.kota.toUpperCase()}`}))
    }).catch(err => {
      let msg = 'Internal Server Error'
      console.error(err)
      if(err.response) {
          if(err.response.data.message) {
              msg = err.response.data.message
          }
      }
    })
  }

  React.useEffect(() => {
    dispatch(getProfileStudent())
      .then((response)=> {
        setDataProfile(response.data.profile);
        setIsBimbel(response.data.profile.isBimbel);
        if(response.data.profile.isBimbel === true) {
          document.getElementById('pernah').checked = true;
          document.getElementById('tidak_pernah').checked = false;
        } else {
          document.getElementById('pernah').checked = false;
          document.getElementById('tidak_pernah').checked = true;
        }

        if(response.data.profile.district !== null && response.data.profile.city !== null) {
          setSelectedDistrict(`${response.data.profile.district}, ${response.data.profile.city}`)
        }
        
        console.log(response.data)
      })
      .then(() => {
        [ 
          { name: 'full_name', value: dataProfile.full_name }, 
          { name: 'nick_name', value: dataProfile.nick_name },
          { name: 'phone_number', value: dataProfile.phone_number },
          { name: 'instagram', value: (dataProfile.instagram ? dataProfile.instagram.substring(1) : null) },
          { name: 'gender', value: dataProfile.gender },
          { name: 'email', value: dataProfile.email },
          { name: 'faculty', value: dataProfile.faculty },
          { name: 'university', value: dataProfile.university },
          { name: 'generation', value: dataProfile.generation },
          { name: 'regional', value: dataProfile.regional },
          { name: 'education', value: dataProfile.education },
          { name: 'ktp_address', value: dataProfile.ktp_address },
          { name: 'domicile_address', value: dataProfile.domicile_address },
          { name: 'kota_kecamatan', value: `${dataProfile.district}, ${dataProfile.city}` },
          { name: 'nationality', value: dataProfile.nationality },
          // { name: 'isBimbel', value: dataProfile.isBimbel},
          { name: 'bimbelLocation', value: dataProfile.bimbelLocation },
          { name: 'knowIngenio', value: dataProfile.knowIngenio },
          { name: 'remarks', value: dataProfile.remarks },
          { name: 'ipk_terakhir', value: dataProfile.ipk_terakhir },
          { name: 'ujian_ke', value: dataProfile.ujian_ke },
          { name: 'materi_sulit', value: dataProfile.materi_sulit },
          { name: 'kesulitan', value: dataProfile.kesulitan },
        ].forEach(({ name, value }) => setValue(name, value))
      })
      .catch((err) => {
        console.log(err)
      })
  }, [dataProfile.id])

  const onSubmit = (data) => {
    if(data.isBimbel === null)
    {
      data.isBimbel = `${dataProfile.isBimbel}`;
    }
    console.log(data)
    if(data.isBimbel == 'true')
    {
      if(data.bimbelLocation === '' || data.bimbelLocation === null || data.bimbelLocation === '-') {
        Swal.fire({  
          title: 'Data yang Anda isikan belum lengkap !',  
          text: 'Nama Bimbel harap diisi!',  
          icon: 'warning',  
          showCancelButton: true,  
          cancelButtonText: 'Kembali',           
          showConfirmButton: false,  
        });
        return;
      } 
    }

    if(selectedDistrict === null || selectedDistrict === '') {
      Swal.fire({  
        title: 'Data yang Anda isikan belum lengkap !',  
        text: 'Kota & Kecamatan harap diisi!',  
        icon: 'warning',  
        showCancelButton: true,  
        cancelButtonText: 'Kembali',           
        showConfirmButton: false,  
      });
      return;
    }

    data.bimbelLocation = data.isBimbel == 'true' ? data.bimbelLocation : '-';
    AreYouSure(data);
  };

  const imageUpload = (event) => {
    event.preventDefault()
    setIsUploadImage(true)
    let formData = new FormData();

    formData.append('file',selectedFile)

    dispatch(uploadAvatar(formData, dataProfile.user_id))
      .then(() => {
        setIsUploadImage(false)
        Succeed();
      }).catch((err) => {
        setIsUploadImage(false)
        Swal.fire({  
          title: 'Oops',  
          text: 'Terjadi Kesalahan',  
          icon: 'danger',  
          showCancelButton: true,  
          cancelButtonText: 'Kembali',           
          showConfirmButton: false,  
        });   
      })
  };

  const AreYouSure = (data) => {
    Swal.fire({  
        title: 'Sudah Yakin?',  
        text: 'Pastikan semua data yang Anda isikan sudah sesuai',  
        icon: 'warning',  
        showCancelButton: true,  
        cancelButtonText: 'Tidak',  
        confirmButtonText: 'Ya'  
      })
      .then((result) => {
        if (result.isConfirmed) {
            data.instagram = '@' + data.instagram;
            data.isBimbel = (data.isBimbel === 'true');
            if(data.bimbelLocation === '' || data.bimbelLocation === null || data.bimbelLocation === '-') {
              data.bimbelLocation = '-';
            }
            data.city = selectedDistrict.split(', ')[1].toUpperCase();
            data.district = selectedDistrict.split(', ')[0].toUpperCase();
            dispatch(updateProfileStudent(data))
            .then(() => {
              ElearningService.updateElearningParticipantProfile(data)
                .then(() => {
                  Succeed();
                })
                .catch((updateErr) => {
                  console.log(updateErr);
                });
              
            })
            .catch((err) => {
                console.log(err);
            });
        }
      });   
  }

  const Succeed = () => {
    Swal.fire({  
        title: 'Update Profile Berhasil !',  
        text: '',  
        icon: 'success',  
        showCancelButton: false,              
        confirmButtonText: 'OK'  
    }).then((result) => {
      if (result.isConfirmed) {
        window.location.reload();
      }
    })
  }

  const Fail = () => {
    Swal.fire({  
        title: 'Data yang Anda isikan belum lengkap !',  
        text: 'Silahkan lengkapi data Anda',  
        icon: 'warning',  
        showCancelButton: true,  
        cancelButtonText: 'Kembali',           
        showConfirmButton: false,  
      });   
  }

  React.useEffect(() => {
    eligibilityOtp()
  }, [])

  const eligibilityOtp = () => {
    const disabledRequestOtp = localStorage.getItem('disabledOtp')
    const timeoutDisabled = localStorage.getItem("timeoutOtp")
     if (disabledRequestOtp == '1') {
      if (timeoutDisabled !== null) {
        setDisabledRequestOtp(true)
        var seconds = parseInt(timeoutDisabled)
        setTimerOtp(seconds)
        var interval = setInterval(() => {
          seconds--
          setTimerOtp(seconds)
          localStorage.setItem('timeoutOtp', seconds)
          if (seconds === 0) {
            clearInterval(interval)
            setDisabledRequestOtp(false)
            localStorage.removeItem('disabledOtp')
            localStorage.removeItem('timeoutOtp')
          }
        }, 1000);
      }
     }
  }

  const changeHandler = (event) => {
		setSelectedFile(event.target.files[0]);
		setIsSelected(true);
	};

  const requestOtp = () => {
    if (isDisabledRequestOtp == true) {
      return
    }
    setIsRequestOtp(true)
    axios.get(`${process.env.REACT_APP_AUTHURL}phone-verify/gettoken`, {
      headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem('token'))}`
      }
    }).then(resp => {
      setDisabledRequestOtp(true)
      localStorage.setItem('disabledOtp', '1')
      localStorage.setItem('timeoutOtp', '60')
      eligibilityOtp()
      setIsRequestOtp(false)
      handleShow()
    }).catch(err => {
      setIsRequestOtp(false)
      console.error(err)
    })
  }

  return(
    <React.Fragment>
      <SideBar judul={'Profil Page'} />
      <div className="md:ml-64">
        {/* <div className="bg-orange px-3 md:px-8 py-1 h-40">
          <h3 className="text-left font-medium tracking-wider" style={{marginLeft: '3rem'}}>Account Settings</h3>
        </div> */}

        <div className="container md:pr-8 md:pl-10"  style={{position: 'absolute', top: '80px'}}>
          <h3 className="text-left font-medium tracking-wider" style={{marginLeft: '3rem'}}>Account Settings</h3>
        </div> 

        <div className="py-4 px-3 md:px-8 -mt-12">
          <div className="container mx-auto max-w-full">
            <div class="shadow-md rounded-md overflow-hidden col-12 col-md-10 col-lg-8 mx-auto">
            
              <div className="py-3 px-3 bg-white font-bold" style={{fontFamily: 'Quicksand'}}>
                  Profile Settings
              </div>
              <div className="py-3 px-5 bg-gray-100 font-bold" style={{fontFamily: 'Quicksand'}}>
                <ul className="list-reset flex">
                  <li className="-mb-px mr-1">
                    <a 
                      className={openTab === 1 ? "inline-block rounded-t py-2 px-4 font-bold" : "inline-block rounded-t py-2 px-4 font-semibold"} 
                      style={{fontFamily: 'Quicksand'}} 
                      onClick={e => {e.preventDefault();setOpenTab(1);}}
                      href="#">
                      Profile Information
                    </a>
                  </li>
                  <li className="mr-1">
                    <a 
                      className={openTab === 2 ? "inline-block rounded-t py-2 px-4 font-bold" : "inline-block rounded-t py-2 px-4 font-semibold"}
                      style={{fontFamily: 'Quicksand'}}
                      onClick={e => {e.preventDefault();setOpenTab(2);}}
                      href="#">
                      Profile Image
                    </a>
                  </li>
                </ul>
              </div>
              <div className={openTab === 1 ? "block" : "hidden"} id="profileInformation" >
                <form action="" className="overflow-y-scroll" style={{maxHeight: '55vh'}} onSubmit={handleSubmit(onSubmit)}>
                  <div className="form-group p-5">
                    <div className="row">
                        <div className="col-12 lg:px-4">
                            <label style={{'color': '#000', 'fontFamily': 'Quicksand', 'marginBottom': '0.5rem'}}>Nama Lengkap</label> <br />
                            <input type="text" {...register("full_name")} className="form-tryout mb-3" id="" />
                            <span class="text-xs text-danger">{errors.full_name?.message}</span>
                        </div>
                        <div className="col-12 lg:px-4">
                            <label style={{'color': '#000', 'fontFamily': 'Quicksand', 'marginBottom': '0.5rem'}}>Nama Panggilan</label> <br />
                            <input type="text" {...register("nick_name")} className="form-tryout mb-3" id="" />
                            <span class="text-xs text-danger">{errors.nick_name?.message}</span>
                        </div>
                        <div className="col-12 col-md-6 lg:px-4">
                            <label style={{'color': '#000', 'fontFamily': 'Quicksand', 'marginBottom': '0.5rem'}}>No. Handphone <b>(dengan WA Aktif)</b> </label> <br />
                            {
                              dataProfile.is_phone_verified ? 
                              (<span><i className="fa fa-check-circle" style={{color: 'green'}}></i>{' Terverifikasi'}</span>)
                              :
                              (
                                <span>
                                  <i className="fa fa-times-circle" style={{color: 'red'}}></i>{' Belum terverifikasi - '}
                                  {
                                    isDisabledRequestOtp == true ?
                                    <a href="#" onClick={() => requestOtp()}>{`Mohon tunggu ${timerOtp} detik lagi!`}</a>
                                    :
                                    <a href="#" onClick={() => requestOtp()}>{isRequestOtp ? 'Requesting...' : 'klik untuk verifikasi'}</a>
                                  }
                                  
                                </span>
                              )
                            }
                            
                            <input type="text" {...register("phone_number")}  className="form-tryout mb-3" id="" />
                            <span class="text-xs text-danger">{errors.phone_number?.message}</span>
                        </div>
                        <div className="col-12 col-md-6 lg:px-4">
                            <label style={{'color': '#000', 'fontFamily': 'Quicksand', 'marginBottom': '0.5rem'}}>Akun Instagram</label> <br />
                            <div className="flex form-tryout">
                              <span className="flex items-center justify-center py-2 px-3 text-gray-700"><i class="fas fa-at"></i></span>
                              <input type="text" {...register("instagram")}  className="w-full p-2" id="" />
                              <span class="text-xs text-danger">{errors.instagram?.message}</span>
                            </div>
                        </div>
                        <div className="col-12 lg:px-4">
                          <div class="block mb-3">
                            <label style={{'color': '#000', 'fontFamily': 'Quicksand', 'marginBottom': '0.5rem'}}>Jenis Kelamin</label> <br />
                            <div class="mt-2 flex">
                              <div className="mr-3">
                                <label class="inline-flex items-center">
                                  <input type="radio" {...register("gender")} class="form-radio" name="gender" value="Male"></input>
                                  <span class="ml-2">Laki-laki</span>
                                </label>
                              </div>
                              <div className="mr-3">
                                <label class="inline-flex items-center">
                                  <input type="radio" {...register("gender")} class="form-radio" name="gender" value="Female"></input>
                                  <span class="ml-2">Perempuan</span>
                                </label>
                              </div>
                              <span class="text-xs text-danger">{errors.gender?.message}</span>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 lg:px-4">
                            <label style={{'color': '#000', 'fontFamily': 'Quicksand', 'marginBottom': '0.5rem'}}>Email</label> <br />
                            <input type="text" {...register("email")} readOnly className="form-control-plaintext form-tryout mb-3" disabled={true} />
                            <span class="text-xs text-danger">{errors.email?.message}</span>
                        </div>
                        <div className="col-12 lg:px-4">
                            <label style={{'color': '#000', 'fontFamily': 'Quicksand', 'marginBottom': '0.5rem'}}>Asal Fakultas</label> <br />
                            <input type="text" {...register("faculty")} className="form-tryout mb-3" id="" />
                            <span class="text-xs text-danger">{errors.faculty?.message}</span>
                        </div>
                        <div className="col-12 lg:px-4">
                            <label style={{'color': '#000', 'fontFamily': 'Quicksand', 'marginBottom': '0.5rem'}}>Asal Universitas</label> <br />
                            <input type="text" {...register("university")} readOnly className="form-control-plaintext form-tryout mb-3" id="" disabled={true} />
                            <span class="text-xs text-danger">{errors.university?.message}</span>
                        </div>
                        <div className="col-12 lg:px-4">
                            <label style={{'color': '#000', 'fontFamily': 'Quicksand', 'marginBottom': '0.5rem'}}>Regio</label> <br />
                            <input type="text" {...register("regional")} readOnly className="form-control-plaintext form-tryout mb-3" disabled={true} />
                            <span class="text-xs text-danger">{errors.regional?.message}</span>
                        </div>
                        <div className="col-12 col-md-6 lg:px-4">
                            <label style={{'color': '#000', 'fontFamily': 'Quicksand', 'marginBottom': '0.5rem'}}>Angkatan (tahun masuk kuliah)</label> <br />
                            <input type="text" {...register("generation")} className="form-tryout mb-3" id="" />
                            <span class="text-xs text-danger">{errors.generation?.message}</span>
                        </div>
                        <div className="col-12 col-md-6 lg:px-4">
                            <label style={{'color': '#000', 'fontFamily': 'Quicksand', 'marginBottom': '0.5rem'}}>Pendidikan Terakhir</label> <br />
                            <input type="text" {...register("education")} className="form-tryout mb-3" id="" />
                            <span class="text-xs text-danger">{errors.education?.message}</span>
                        </div>
                        <div className="col-12 lg:px-4">
                            <label style={{'color': '#000', 'fontFamily': 'Quicksand', 'marginBottom': '0.5rem'}}>Alamat Lengkap KTP</label> <br />
                            <textarea type="text" rows={6} cols={100} {...register("ktp_address")} className="form-tryout mb-3" style={{'height': 'auto', padding: '1rem'}} id="" />
                            <span class="text-xs text-danger">{errors.ktp_address?.message}</span>
                        </div>
                        <div className="col-12 lg:px-4">
                            <label style={{'color': '#000', 'fontFamily': 'Quicksand', 'marginBottom': '0.5rem'}}>Alamat Lengkap Domisili</label> <br />
                            <textarea type="text" rows={6} cols={100} {...register("domicile_address")} className="form-tryout mb-3" style={{'height': 'auto', padding: '1rem'}} id="" />
                            <span class="text-xs text-danger">{errors.domicile_address?.message}</span>
                        </div>
                        <div style={{position: 'relative'}} className="col-12 lg:px-4">
                            <label style={{'color': '#000', 'fontFamily': 'Quicksand', 'marginBottom': '0.5rem'}}>Kota & Kecamatan</label> <br />
                            {/* <input type="text" {...register("kota_kecamatan")} className="form-tryout mb-3" id="" /> */}
                            <input placeholder='Cari kecamatan / kota' type="text" onChange={(e) => {
                              setKeyword(e.target.value)
                              setSelectedDistrict(e.target.value)
                              setShowSelectDistricts(true)
                            }} value={selectedDistrict} className="form-tryout mb-3" id="" />
                            {
                              showSelectDistricts ? 
                              <div style={{position: 'absolute', backgroundColor: 'white', width: '100%', height: '150px', overflow: 'auto'}}>
                                <li>
                                  {
                                    districts.map((item, i) => {
                                      return <ul style={{cursor: 'pointer'}} onClick={() => {
                                        setSelectedDistrict(item);
                                        setShowSelectDistricts(false)
                                      }}>{item}</ul> 
                                    })
                                  }
                                </li>
                              </div>
                              :
                              null
                            }
                            
                        </div>
                        <div className="col-12 lg:px-4">
                            <label style={{'color': '#000', 'fontFamily': 'Quicksand', 'marginBottom': '0.5rem'}}>Negara</label> <br />
                            <input type="text" {...register("nationality")} className="form-tryout mb-3" id="" />
                            <span class="text-xs text-danger">{errors.nationality?.message}</span>
                        </div>
                        <div className="col-12 lg:px-4">
                          <div class="block mb-3">
                            <label style={{'color': '#000', 'fontFamily': 'Quicksand', 'marginBottom': '0.5rem'}}>Apakah Anda sudah pernah mengikuti bimbingan preklinik/ujian kompetensi sebelumnya?</label> <br />
                            <div class="mt-2 flex">
                              <div className="mr-3">
                                <label class="inline-flex items-center">
                                  <input type="radio" name="isBimbel" id="pernah" class="form-radio" {...register("isBimbel")} value={'true'} onChange={(e) => {setIsBimbel(e.target.value)}}></input>
                                  <span class="ml-2" style={{'fontFamily': 'Quicksand'}}>Sudah Pernah</span>
                                </label>
                              </div>
                              <div className="mr-3">
                                <label class="inline-flex items-center">
                                  <input type="radio" name="isBimbel" id="tidak_pernah" class="form-radio" {...register("isBimbel")} value={'false'} onChange={(e) => {setIsBimbel(e.target.value)}}></input>
                                  <span class="ml-2" style={{'fontFamily': 'Quicksand'}}>Belum pernah</span>
                                </label>
                              </div>
                              {/* <span class="text-xs text-danger">{errors.isBimbel?.message}</span> */}
                              <span class="text-xs text-danger">{formError?.isBimbel?.message}</span>
                              
                            </div>
                          </div>
                        </div>
                        <div className="col-12 lg:px-4">
                            <label style={{'color': '#000', 'fontFamily': 'Quicksand', 'marginBottom': '0.5rem'}}><b>Jika Sudah,</b> apa bimbel yang anda ikuti?</label> <br />
                            <input type="text" {...register("bimbelLocation")} className="form-tryout mb-3" id="" />
                            <span class="text-xs text-danger">{errors.bimbelLocation?.message}</span>
                        </div>
                        <div className="col-12 lg:px-4">
                            <label style={{'color': '#000', 'fontFamily': 'Quicksand', 'marginBottom': '0.5rem'}}>Status Hamil / Materi yang sulit dipahami</label> <br />
                            <textarea type="text" rows={6} {...register("remarks")} className="form-tryout mb-3" style={{'height': 'auto', padding: '1rem'}} id="" />
                            <span class="text-xs text-danger">{errors.remarks?.message}</span>
                        </div>
                        <div className="col-12 lg:px-4">
                          <div class="block mb-3">
                            <label style={{'color': '#000', 'fontFamily': 'Quicksand', 'marginBottom': '0.5rem'}}>Mengetahui Ingenio dari:</label> <br />
                            <div class="mt-2">
                              <div className="mb-2 mr-3">
                                <label class="inline-flex items-center">
                                  <input type="radio" class="form-radio" name="knowIngenio" {...register("knowIngenio")} value="Instagram"></input>
                                  <span class="ml-2" style={{'fontFamily': 'Quicksand'}}>Instagram</span>
                                </label>
                              </div>
                              <div className="mb-2 mr-3">
                                <label class="inline-flex items-center">
                                  <input type="radio" class="form-radio" name="knowIngenio" {...register("knowIngenio")}  value="Website"></input>
                                  <span class="ml-2" style={{'fontFamily': 'Quicksand'}}>Website</span>
                                </label>
                              </div>
                              <div className="mb-2 mr-3">
                                <label class="inline-flex items-center">
                                  <input type="radio" class="form-radio" name="knowIngenio" {...register("knowIngenio")} value="Rekomendasi Teman"></input>
                                  <span class="ml-2" style={{'fontFamily': 'Quicksand'}}>Rekomendasi Teman</span>
                                </label>
                              </div>
                              <div className="mb-2 mr-3">
                                <label class="inline-flex items-center">
                                  <input type="radio" class="form-radio" name="knowIngenio" {...register("knowIngenio")} value="Rekomendasi Senior"></input>
                                  <span class="ml-2" style={{'fontFamily': 'Quicksand'}}>Rekomendasi Senior</span>
                                </label>
                              </div>
                              <div className="mb-2 mr-3">
                                <label class="inline-flex items-center">
                                  <input type="radio" class="form-radio" name="knowIngenio" {...register("knowIngenio")} value="Youtube"></input>
                                  <span class="ml-2" style={{'fontFamily': 'Quicksand'}}>Youtube</span>
                                </label>
                              </div>
                              <div className="mb-2 mr-3">
                                <label class="inline-flex items-center">
                                  <input type="radio" class="form-radio" name="knowIngenio" {...register("knowIngenio")} value="Facebook"></input>
                                  <span class="ml-2" style={{'fontFamily': 'Quicksand'}}>Facebook</span>
                                </label>
                              </div>
                              <div className="mb-2 mr-3">
                                <label class="inline-flex items-center">
                                  <input type="radio" class="form-radio" name="knowIngenio" {...register("knowIngenio")} value="E-Brochure"></input>
                                  <span class="ml-2" style={{'fontFamily': 'Quicksand'}}>E-Brochure</span>
                                </label>
                              </div>
                              <span class="text-xs text-danger">{errors.knowIngenio?.message}</span>
                            </div>
                          </div>
                        </div>
                        <span class="text-lg">{'*Form diisi khusus untuk peserta yang mengambil UKMPPD'}</span>
                        <div className="col-12 lg:px-4">
                            <label style={{'color': '#000', 'fontFamily': 'Quicksand', 'marginBottom': '0.5rem'}}>IPK Terakhir</label> <br />
                            <input type="text" {...register("ipk_terakhir")} className="form-tryout mb-3" id="" />
                            {/* <span class="text-xs text-danger">{errors.i?.message}</span> */}
                        </div>
                        <div className="col-12 lg:px-4">
                            <label style={{'color': '#000', 'fontFamily': 'Quicksand', 'marginBottom': '0.5rem'}}>Ujian Ke</label> <br />
                            <input type="text" {...register("ujian_ke")} className="form-tryout mb-3" id="" />
                            {/* <span class="text-xs text-danger">{errors.i?.message}</span> */}
                        </div>
                        <div className="col-12 lg:px-4">
                            <label style={{'color': '#000', 'fontFamily': 'Quicksand', 'marginBottom': '0.5rem'}}>Materi yang sulit dipahami</label> <br />
                            <textarea type="text" rows={6} {...register("materi_sulit")} className="form-tryout mb-3" style={{'height': 'auto', padding: '1rem'}} id="" />
                            {/* <span class="text-xs text-danger">{errors.remarks?.message}</span> */}
                        </div>
                        <div className="col-12 lg:px-4">
                            <label style={{'color': '#000', 'fontFamily': 'Quicksand', 'marginBottom': '0.5rem'}}>Kesulitan yang dirasakan</label> <br />
                            <textarea type="text" rows={6} {...register("kesulitan")} className="form-tryout mb-3" style={{'height': 'auto', padding: '1rem'}} id="" />
                            {/* <span class="text-xs text-danger">{errors.remarks?.message}</span> */}
                        </div>
                    </div>
                  </div>
                  <div className="py-3 px-5 bg-white text-center">
                    <button
                      class="bg-orange text-white active:bg-orange font-bold text-base px-8 py-3 rounded shadow-md hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      type="submit">
                      {/* <CircularProgress /> */}
                      Submit Data
                    </button>
                  </div>
                </form>
              </div>
              <div className={openTab === 2 ? "block p-5" : "hidden p-5"} id="profileImage">
                <form className="row" onSubmit={imageUpload} encType="multipart/form-data">
                  <div className="col-6 mx-auto text-center mb-3">
                    <img src={dataProfile ? (dataProfile.profile_photo && dataProfile.profile_photo !== null) ? dataProfile.profile_photo : baseUrl + "/assets/img/profile-image-default.png" : baseUrl + "/assets/img/profile-image-default.png"} className="object-center mx-auto"></img>
                  </div>
                  <div className="col-12 text-center mb-3">
                    <h4 className="font-bold">JPEG, GIF, and PNG images below 5MB can be uploaded.</h4>
                  </div>
                  <div className="col-12 col-md-5 mx-auto text-left mb-3">
                    <input type="file" name="file" onChange={changeHandler} />
                  </div>
                  <div className="col-12 text-center mb-3">
                    <button type="submit" disabled={isUploadImage} class="bg-transparent hover:bg-blue-500 text-black font-semibold hover:text-black py-2 px-4 border border-gray-800 hover:border-transparent rounded">
                      {isUploadImage ? 'Uploading...' : 'Upload Image'}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

      </div>
      <Modal show={show} size="lg" onHide={handleClose}>
          <Modal.Header closeButton>
              <Modal.Title>Masukan kode OTP</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <label htmlFor="">Masukan Kode OTP</label>
            <input type="text" onChange={(e) => setOtp(e.target.value)} maxLength="6" placeholder="Masukan Kode OTP" id="" className="mb-4" style={{ backgroundColor: '#F3F4F6', width: '100%', borderRadius: '8px', border: 'none !important', height: '45px', paddingLeft: '10px' }} /> <br />
            
            <Button 
                className="bg-primary mx-2"
                disabled={isRequestOtp}
                onClick={() => {
                  setIsRequestOtp(true)
                  axios.put(`${process.env.REACT_APP_AUTHURL}phone-verify/validate`, {
                    otp: otp
                  }, {
                    headers: {
                      Authorization: `Bearer ${JSON.parse(localStorage.getItem('token'))}`
                    }
                  }).then(resp => {
                    setIsRequestOtp(false)
                    if(resp.data.data !== '') {
                      setIsRequestOtp(false)
                      setOtpError('Invalid Token')
                      return;
                    }
                    handleClose()
                    window.location.reload()
                  }).catch(err => {
                    setIsRequestOtp(false)
                    setOtpError('Invalid Token')
                    console.error(err)
                  })
                }} 
                style={{ padding: '' }}>
                Kirim
            </Button> <br></br>
            <span class="text-xs text-danger">{otpError}</span>

          </Modal.Body>
      </Modal>
    </React.Fragment>
    
  )
}

export default connect()(ProfilePage)