import React, { useEffect, useState, useRef } from 'react';
import {useReactToPrint} from "react-to-print";
import LogistikSideBar from '../../../components/layout/dashboard/logistik/LogistikSideBar';
import Button from '@restart/ui/esm/Button';
import { Link, useLocation} from 'react-router-dom';
import { Image } from 'react-bootstrap';



const ComponentToPrint = React.forwardRef((props, ref) => {
  const {transaction, handlePrint} = props;
  document.body.style.backgroundColor = "#f3f4f6";

  const location = useLocation();
  const { resi, weight, ongkir, insurance, participant_name, participant_address, postalcode, phone } = location.state

  return (
      <div ref={ref} className="row PrintResi my-5">
        <div className="col-lg-8 mx-auto">
            <div className="card-print" style={{padding: '.4rem'}}>
              <div className="borderCard">
                <div className="justify-sb" style={{padding: '1rem 2rem'}}>
                  <Image src="/assets/logo/ingenio rakun 2.png" style={{ width: '160px' }}></Image>
                  <div className="label">
                    <div className="label1">Label Pengiriman</div>
                    <div className="label2">Non Tunai</div> 
                  </div>
                </div>
                <div className="Containerborder" style={{paddingLeft: '.8rem', paddingRight: '.8rem'}}>
                  <div className="borderDashed"></div>
                </div>
                <div className="sideTwo">
                  <Image src="/assets/logo/LogoLionParcel.png" style={{ width: '120px' }}></Image>
                  <div className="packetName">
                    <div className="label1"><b>Lion Parcel</b></div> 
                    <div className="label2">Reguler</div> 
                  </div>
                  <div className="invoice">
                    <div className="label1">Nomor Invoice</div>
                    <div className="label2">INV/20220712/XI/VI/22</div> 
                  </div>
                  <div className="resi">
                    <div className="label1">No Resi</div>
                    <div className="label2">{resi}</div> 
                  </div>
                </div>
                <div className="sideThree">
                  <div className="adm">
                    <div className="label1">Administrasi</div>
                    <div className="label2">+ Rp 0</div> 
                  </div>
                  <div className="asuransi">
                    <div className="label1">Asuransi</div>
                    <div className="label2 txt-through">+ Rp 2.000</div> 
                    {/* <div className="label2">+ Rp 2.000</div>  */}
                  </div>
                  <div className="ongkir">
                    <div className="label1">Ongkir</div>
                    <div className="label2 txt-through">{`Rp ${ongkir}`}</div> 
                    {/* <div className="label2">Rp 12.000</div>  */}
                  </div>
                  <div className="berat">
                    <div className="label1">Berat</div>
                    <div className="label2">{`${weight} Kg`}</div> 
                  </div>
                </div>
                <div className="sideFour">
                  <div className="alertPrint">
                  <Image src="/assets/logo/money.png" style={{ width: '40px' }}></Image>
                    <div className="desc">Penjual <b>tidak perlu</b> bayar asuransi & ongkir ke kurir, sudah dibayarkan otomatis</div>
                  </div>
                </div>
                <div className="sideFive">
                  <div className="kepada">
                    <div className="label1">Kepada</div>
                    <div className="label2">
                      <div className="nama">{participant_name}</div>  
                      <div className="alamat">{`${participant_address}`}</div>  
                      <div className="pos">{`${postalcode}`}</div>  
                      <div className="noHP">{phone}</div>  
                    </div> 
                  </div>
                  <div className="dari">
                    <div className="label1">Dari</div>
                    <div className="label2">
                      <div className="nama">Ingenio Indonesia</div>  
                      <div className="alamat">Ruko Ditas, Jl. MT. Haryono No.14, Dinoyo, Kec. Lowokwaru, Kota Malang, Jawa Timur 65145, Indonesia 65145</div>  
                      <div className="noHP">089765432134</div>  
                    </div> 
                  </div>
                </div>
              </div>
            </div>
            <div className="justify-center">
              <Button className="pilih-tryout text-black mb-4" onClick={handlePrint}>
              <i class="fa fa-arrow-circle-down" aria-hidden="true"></i>
              {' Unduh Resi'}
              </Button>
            </div>
        </div>
      </div>
  );
});

export default function PrintResiLogistik() {
  document.title = "INGENIO Indonesia";
  const [program, setProgram] = useState('');

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <React.Fragment>
      <LogistikSideBar />
      
      <div className="md:ml-64">

        <div className="px-3 md:px-8">
          <div className="container mx-auto max-w-full">
            <ComponentToPrint ref={componentRef} handlePrint={handlePrint} />
            
          </div>
        </div>

      </div>
    </React.Fragment>

  )
}