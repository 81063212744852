import React from 'react';
import { Card, Button } from 'react-bootstrap';

export default function AboutCard (props) {
  return(
    <Card style={{height: '280px', minWidth: '210px', position: 'relative', marginBottom: '2.5rem'}} className="zoom mx-auto">
      <Card.Body>
        <img src={"/assets/landing/" + props.data.icon} className="aboutcard-icon-bg" alt="Icon"></img>
        <Card.Text className="aboutcard-text">
          {props.data.text}
        </Card.Text>
      </Card.Body>
    </Card>
  )
}