import React, { useState } from 'react';

export default function ProgramUkomKeb() {

    return (
        <React.Fragment>
            <section className="container-fluid">
                <div className="site-header site-header--menu-left landing-11-menu site-header--absolute" style={{ backgroundPositionY: 'bottom', backgroundPositionX: 'right', backgroundRepeat: 'no-repeat', paddingTop: '6rem', backgroundColor: '#83D0CF' }}>
                    <div className="py-5" style={{ paddingBottom: '2rem !important' }}>
                        <h5 className="sub-title text-center">Kelas Bimbingan</h5>
                        <h5 className="tentang_title g-title">Ujian Kompetensi Kebidanan</h5>

                    </div>
                </div>
            </section>
            <section className="container-fluid bg-desktop">
                <div className="container px-4" style={{ paddingTop: '10rem', width: '85%', paddingBottom: '5rem' }}>
                    <div className="row g-2" style={{ textAlign: 'left', paddingTop: '13.5rem' }}>
                        <div className="col-lg-6 col-md-12">
                            <img src="/assets/landing/ukomkeb-1.png" />
                        </div>
                        <div className="col-lg-6 col-md-12 mt5r">
                            <h5 className="tdt36px balooc2 prl5r" style={{ textDecoration: 'none', lineHeight: '50px' }}>Bimbingan CBT UKOM Kebidanan (Online)</h5>
                            <h5 className="txt20px mt1r prl5r" style={{ marginBottom: '1rem', lineHeight: '30px' }}>Merupakan program bimbingan di Ingenio yang mempersiapkan mahasiswa kebidanan menghadapi Ujian Kompetensi secara daring (online)
                            </h5>
                        </div>
                    </div>
                    <h5 className="txt24px mt1r" style={{ marginBottom: '0.2rem', lineHeight: '30px' }}>Dengan berbagai fasilitas penunjang yaitu:</h5>
                    <h5 className="txt20px mt1r" style={{ marginBottom: '1rem', lineHeight: '30px' }}>a.	Lama Bimbingan : 12 – 18 Hari<br />
                        b.	Penanaman Pola Pikir ala INGENIO yang bukan hanya sekedar menghafal materi dan soal. Program ini menggabungkan teori menjadi kerangka berpikir untuk dapat menjawab soal dengan tepat dan menjadi bidan yang profesional<br />
                        c.	Biaya Terjangkau<br />
                        d.	Kelas tatap muka, sistematis dan interaktif (via zoom)<br />
                        e.	Materi Softfile <br />
                        f.	3x Try Out Online UKOM (Total 600 Soal) <br />
                        g.	Durasi Try Out 200 menit / Sesi<br />
                        h.	Jumlah Peserta 15 – 25 orang / kelompok<br />
                        i.	Pembahasan Materi Try Out disertai Pola Pikir 18 Pertemuan<br />
                        j.	Durasi Pembahasan Materi 90 – 120 menit / Pertemuan<br />
                        k.	Tutor handal, berkualitas, dan kompeten di bidangnya<br />
                        l.	Akses Tutor 24 / 7 selama masa bimbingan


                    </h5>
                    <a href="https://drive.google.com/file/d/1lolhvYeB3yVBkX-8y82KWvwMCY4kcele/view?usp=sharing" className="txt20px" style={{ color: '#FCA61F', textDecoration: 'underline', lineHeight: '30px' }}>Download Brosur UKOM Kebidanan
                    </a>
                </div>
            </section>
        </React.Fragment >
    )
}

