import Button from '@material-tailwind/react/Button';
import React, { useEffect, useState } from 'react';
import { FormGroup, FormControl, Form, Modal } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import { Link, useParams } from 'react-router-dom';
import { BsArrowLeftShort, BsFileEarmark } from 'react-icons/bs';
// import AkademikSidebar from '../../../components/layout/dashboard/akademik/AkademikSideBar';
import TutorSidebar from '../../../components/layout/dashboard/tutor/TutorSideBar';
import { useLocation } from 'react-router';
import CustomDatatable from '../../../components/common/CustomDatatable';
import ElearningService from '../../../services/elearning';
import moment from 'moment-timezone';
import Swal from 'sweetalert2';

export default function DetailDataKelasParticipant() {
    var baseUrl = window.location.origin;
    document.title = "INGENIO Indonesia";

    // Filtering data
    const [isLoading, setIsLoading] = useState(true);
    const [hasNext, setHasNext] = useState(false)
    const [hasPrev, setHasPrev] = useState(false)
    const [count, setCount] = useState(0);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [keyword, setKeyword] = useState('');
    const [kelasData, setKelasData] = useState([]);

    
    const location = useLocation();
    const { nama_kelas, nama_regio, elearning_program } = location.state
    const { kelasid } = useParams();

    const dropdown = {
        borderRadius: "10px",
        height: 50,
        padding: "25px",
    };
    const columns = [
        {
            name: 'NO.',
            field: 'no',
            index: true,
            selector: row => row.no,
            sortable: true,
            minWidth: '1px'
            
        },
        {
            name: 'NAMA LENGKAP',
            field: 'nama_peserta',
            selector: row => row.nama_peserta,
            sortable: true,
            width: '15vw',
            
        },
        {
            name: 'ASAL FK',
            field: 'asal_fakultas',
            selector: row => row.asal_fakultas,
            sortable: true,
            width: '10vw',
             
        },
        {
            name: 'ANGKATAN',
            field: 'angkatan',
            selector: row => row.angkatan,
            sortable: true,
            width: '12vw',
             
        },
        {
            name: 'IPK TERAKHIR',
            field: 'ipk_terakhir',
            selector: row => row.angkatan,
            sortable: true,
            width: '13vw',
             
        },
        {
            name: 'UJIAN KE-',
            field: 'ujian_ke',
            selector: row => row.angkatan,
            sortable: true,
            width: '12vw',
             
        },
        {
            name: 'MATERI YANG SULIT',
            field: 'materi_sulit',
            selector: row => row.angkatan,
            sortable: true,
             
        },
        {
            name: 'KESULITAN YANG DIRASAKAN',
            field: 'kesulitan',
            selector: row => row.angkatan,
            sortable: true,
            width: '24vw',
             
        },
        {
            name: '',
            render: true,
            component: (row) => {
              return (
                <td className='main-table__bodyRow'>
                  <Link className="pilih-tryout p-2" 
                    to={{
                        pathname: `/dashboard/tutor/data-kelas/${row.id}/data-peserta/${row.id_peserta}`,
                        state: {
                          user: {
                            id_user: row.id_peserta,
                            id_tryout: null
                          },
                        }
                      }}
                  >View</Link>
                </td>
              )
            },
            selector: row => row.jumlah,
            sortable: true,
            minWidth: '20px',
            wrap: 'yes'
        }
    ];

    const getListKelasParticipant = (params = '') => {
        setIsLoading(true)
        ElearningService.getListKelasParticipantTutor(kelasid, params).then(resp => {
            const response = resp.data;
            setKelasData(response.data);

            setPage(response.meta.page)
            setPerPage(response.meta.perPage)
            setCount(response.meta.count)

            setHasNext(response.meta.hasNext)
            setHasPrev(response.meta.hasPrev);
            
            setIsLoading(false)
        }).catch(err => {
            setIsLoading(false)
            console.error(err)
        })
    }

    const Succeed = (message, onConfirm) => {
        Swal.fire({  
            title: 'Berhasil!',  
            text: message,  
            icon: 'success',  
            showCancelButton: false,              
            confirmButtonText: 'OK'  
        }).then((result) => {
          if (result.isConfirmed) {
            onConfirm()
          }
        })
    }
    
    const Fail = (message) => {
        Swal.fire({  
            title: 'Gagal!',  
            text: message,  
            icon: 'warning',  
            showCancelButton: true,  
            cancelButtonText: 'Kembali',           
            showConfirmButton: false,  
        });   
    }

    useEffect(() => {
        getListKelasParticipant();
    }, [])

    return (
        <React.Fragment>
            <TutorSidebar />
            <div className="md:ml-64 tutorDataKelas" style={{ backgroundColor: '#F3F4F6' }}>
                <div className="bg-orange md:px-8 pb-5" style={{height: '24vh', position: 'relative', padding: '0 30px 30px 30px'}}>
                    <h1 className="tracking-wider title-24-600">Data Kelas</h1>
                    <h2 className="tracking-wider title-20-700 mt-5">Selamat Datang Tutor INGENIO !</h2>
                    <img src={baseUrl + "/assets/logo/evaluasikelas.png"} className="img-opacity"></img>
                </div>
                <div className="px-3 md:px-8 mt-3 mb-5">
                    <div className="container mx-auto max-w-full">
                        <div className="justify-start">
                            <Link to="/dashboard/tutor/data-kelas">
                                <div className="navBack">
                                    <BsArrowLeftShort size="19px"/>Kembali
                                </div> 
                            </Link>
                        </div>
                        <div className="row">
                            <div className="col-12 my-4 justify-start">
                                <FormGroup>
                                    <div className="SearchTable">
                                        <input value={keyword} onChange={(e) => setKeyword(e.target.value)} type="text" name="" className="inputSearchTable" placeholder="&#x1F50E;&#xFE0E; Search..." id="" />
                                        <Button onClick={() => {
                                                getListKelasParticipant(`page=${page}&perPage=${perPage}&keyword=${keyword}`)
                                            }} className="btnSearchTable">Cari</Button>
                                    </div>
                                </FormGroup>
                                <Button className="btnOrange2">
                                    <Link 
                                        to={{
                                            pathname: `/dashboard/tutor/data-kelas/absensi/${kelasid}`,
                                            state: {
                                                nama_kelas, 
                                                nama_regio, 
                                                elearning_program
                                            }
                                          }}>
                                        <div className="titleBtnOrange">
                                            <BsFileEarmark className="mr-2"/>Absensi Kelas
                                        </div> 
                                    </Link>
                                </Button>
                            </div>
                            {/* <div className="col-md-8 col-lg-4 col-xs-12 mb-4">
                                <FormGroup>
                                    <div className="row" style={{ backgroundColor: '#FFF', padding: '1rem', borderRadius: '1rem' }}>
                                        <div className="col-8">
                                            <input value={keyword} onChange={(e) => setKeyword(e.target.value)} type="text" name="" style={{ backgroundColor: '#F3F4F6', width: '100%', borderRadius: '8px', border: 'none !important', height: '45px', paddingLeft: '10px' }} placeholder="&#x1F50E;&#xFE0E; Nama Kelas." id="" />
                                        </div>
                                        <div className="col-3">
                                            <Button onClick={() => {
                                                getListKelasParticipant(`page=${page}&perPage=${perPage}&keyword=${keyword}`)
                                            }} className="pilih-tryout">Cari</Button>
                                        </div>
                                    </div>
                                </FormGroup>
                            </div>
                            <div className="col-8" style={{ padding: '1rem' }}>
                                
                            </div> */}
                            <div className="col-md-12 col-lg-12 col-xs-12">
                                {/* <DataTable columns={columns} data={data} pagination /> */}
                                <CustomDatatable 
                                    filteredItems={kelasData} 
                                    columns={columns}
                                    isLoading={isLoading}
                                    page={page}
                                    perPage={perPage}
                                    hasPrev={hasPrev}
                                    hasNext={hasNext}
                                    count={count}
                                    onTableChange={(e, data) => {
                                        switch (e) {
                                        case 'rowPerPageChange':
                                            setPerPage(data.perPage)
                                            getListKelasParticipant(`page=${page}&perPage=${data.perPage}&keyword=${keyword}`)
                                            break;
                                        case 'rowPageChange':
                                            setPage(data.page)
                                            getListKelasParticipant(`page=${data.page}&perPage=${perPage}&keyword=${keyword}`)
                                            break;
                                        
                                        default:
                                            break;
                                        }
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </React.Fragment >

    )
}