import authReducer from './authReducer'
import messageReducer from './messageReducer'

import { combineReducers } from 'redux'
import tryoutReducer from './tryoutReducer'

const reducers = combineReducers({
  auth: authReducer,
  message: messageReducer,
  tryout: tryoutReducer
})

export default reducers