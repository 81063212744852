import React, {useRef} from 'react';
// import styles from '../../index.module.css';
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import { PreklinikCard } from './index';
import { RocketCard } from './index';
import { FaArrowRight, FaArrowLeft } from 'react-icons/fa'

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import 'swiper/components/scrollbar/scrollbar.scss';

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y ]);

const testimonies = [
  {
    id: 1,
    img_url: "/assets/landing/new-preklinik-testi-1.png"
  },
  {
    id: 2,
    img_url: "/assets/landing/new-preklinik-testi-2.jpg"
  },
  {
    id: 3,
    img_url: "/assets/landing/new-preklinik-testi-3.jpg"
  },
  {
    id: 4,
    img_url: "/assets/landing/new-preklinik-testi-4.jpg"
  },
  {
    id: 5,
    img_url: "/assets/landing/new-preklinik-testi-5.jpg"
  },
  {
    id: 6,
    img_url: "/assets/landing/new-preklinik-testi-6.jpg"
  },
]

export default function PreklinikSlider(props) {
  const navigationPrevRef = React.useRef(null)
  const navigationNextRef = React.useRef(null)
  
  return (
    <Swiper
      id="main"
      spaceBetween={75}
      slidesPerView={"1"}
      navigation={{
        prevEl: '.preklinikslider_button-prev',
        nextEl: '.preklinikslider_button-next',
      }}
      beforeInit={(swiper) => {
        swiper.params.navigation.prevEl= navigationPrevRef.current
        swiper.params.navigation.nextEl= navigationNextRef.current
        console.log(navigationNextRef)
      }}
      style={{paddingBottom: '2.5vh', alignSelf: 'center'}}
    > 
      <div className="preklinikslider_button-wrapper">
        <button className="btn preklinikslider_button-prev" ref={navigationPrevRef}><FaArrowLeft size="20px" color="white"/></button>
        <button className="btn preklinikslider_button-next" ref={navigationNextRef}><FaArrowRight size="20px" color="white"/></button>
      </div>
      {
        testimonies.map((item, i) => {
          return (
            <SwiperSlide>
              <RocketCard {...item} />
            </SwiperSlide>
          )
        })
      }
    </Swiper>
  )
}