import React from 'react';
import {
  IngenioNav,
  IngenioFooter
} from '../components/layout';
import {
  Hero,
  UKMPPDSlider,
  PreklinikSlider,
  RocketSlider
} from '../components/testimoni';

import {
  TestimoniSlider
} from '../components/landing/index'

export default function TestimoniPage () {
  document.title = "INGENIO Indonesia | Testimoni Peserta";
  return(
    <React.Fragment>
      <IngenioNav />
        <div style={{backgroundColor: 'white'}}>
          <section className="container-fluid">
            <div className="container section-di-testimoni">
              <div className="row align-items-center px-2 py-5">
                <Hero />
              </div>
            </div>
            <div className="container section-di-testimoni">
              <div className="row align-items-center px-2 py-5">
                <div className="col-12 col-md-6 col-lg-6 mr-auto">
                  <h4 className="testimoni-page__title text-center" style={{color: 'black'}}>UKMPPD / UKMPPDG</h4>
                </div>
              </div>
              <div className="row align-items-center px-2 py-5">
                <TestimoniSlider />
              </div>
            </div>
            <div className="container section-di-testimoni">
              <div className="row align-items-center px-2 py-5">
                <div className="col-12 col-md-6 col-lg-6">
                  <h4 className="testimoni-page__title text-center" style={{color: 'black'}}>KELAS PREKLINIK</h4>
                </div>
              </div>
              <div className="row align-items-center px-2 py-5">
                <PreklinikSlider />
              </div>
            </div>
            <div className="container section-di-testimoni">
              <div className="row align-items-center px-2 py-5">
                <div className="col-12 col-md-6 col-lg-6">
                  <h4 className="testimoni-page__title-purple text-center" style={{color: 'black'}}>ROCKET PLUS</h4>
                </div>
              </div>
              <div className="row align-items-center px-2 py-5">
                <RocketSlider />
              </div>
              <div className="row justify-content-center align-items-center py-4">
                <a className="bt-m text-center" href="/" style={{width: '300px', background: 'linear-gradient(248.1deg, #F8CA2B 27.1%, #FCA826 73.3%)', boxShadow: '0px 2px 10px #F8CA2B',borderRadius: '50px'}}>
                    <span className="bt-link" style={{fontSize: '1.5rem', color: 'black'}}>{'<< Kembali'}</span>
                </a>
              </div>
            </div>
          </section>
        </div>
      <IngenioFooter />
    </React.Fragment>
    
  )
}