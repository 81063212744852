import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  SET_PROFILECOMPLETE,
  SET_ROLE_STUDENT,
  SET_ROLE_AKADEMIK,
  SET_MESSAGE,
} from "./types";

import AuthService from "../../services/auth";

// AUTHENTICATION ACTION

export const registerUser = (payload) => (dispatch) => {
  return AuthService.register(payload).then(
    (response) => {
      dispatch({
        type: REGISTER_SUCCESS,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: response.data.message,
      });

      return Promise.resolve();
    },
  ).catch(err => {
    let message = ''
    if(err.response) {
      if(err.response.data.message) {
        message = err.response.data.message
      }
    }

    dispatch({
      type: REGISTER_FAIL,
    });

    dispatch({
      type: SET_MESSAGE,
      payload: message,
    });

    return Promise.reject({message});
  })
};

export const login = (payload) => (dispatch, getState) => {
  return AuthService.login(payload).then(
    (data) => {
      console.log(data);
      if(data.success === true && data.code === 200) {
        dispatch({
          type: LOGIN_SUCCESS,
          payload: (data.data),
        });
  
        return Promise.resolve();
      } else {
        let message = data.data;
 
        dispatch({
          type: LOGIN_FAIL,
        });

        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });

        return Promise.reject();
      }
    },
  );
};

export const forgotPass = (payload) => (dispatch) => {
  return AuthService.forgotPassword(payload).then(
    (data) => {
      if (data.success === true) {
        return Promise.resolve()
      } else {
        let message = "";
        if(data.code === 400) {
          message = data.message
        }

        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });

        return Promise.reject();
      }
    }
  )
}

export const logout = () => (dispatch) => {
  AuthService.logout();

  dispatch({
    type: LOGOUT,
  });
};

export const verify = () => (dispatch) => {
  return AuthService.verify().then(
    (data) => {
      if (data.success === true) {
        return Promise.resolve(data)
      } else if(data.success === false) {
        dispatch({
          type: LOGOUT,
        });

        return Promise.reject()
      }
    }
  );

 
};
// END AUTHENTICATION ACTION

// AUTHORIZATION STUDENT

export const getProfileStudent = () => (dispatch) => {
  return AuthService.getProfileStudent().then(
    (data) => {
      if (data.success === true) {
        dispatch({
          type: SET_PROFILECOMPLETE,
          payload: (data.data.isProfileComplete === true && data.data.profile.is_phone_verified === true),
        })
        dispatch({
          type: LOGIN_SUCCESS,
          payload: (data.data.isProfileComplete),
        });

        return Promise.resolve(data)

        
      } else {
        console.log(data)

        return Promise.reject(data);
      }
    })
}

export const updateProfileStudent = (payload) => (dispatch) => {
  return AuthService.updateProfileStudent(payload).then(
    (data) => {
        console.log(data)
    })
}

export const uploadAvatar = (payload, id) => (dispatch) => {
  return AuthService.uploadAvatar(payload, id).then(
    (data) => {
        console.log(data)
    })
}

// END AUTHORIZATION STUDENT

// AUTHORIZATION AKADEMIK

// END AUTHORIZATION AKADEMIK