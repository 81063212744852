import React from 'react';
import {
  SideBar
} from '../../components/layout/dashboard';
import { Col, Form, FormGroup, Row, Button } from 'react-bootstrap';
import DataTable from 'react-data-table-component'
import { Link } from 'react-router-dom';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Swal from 'sweetalert2';
import { BsPlayFill } from 'react-icons/bs';


export default function AksesCourse () {
  document.title = "INGENIO Indonesia";
  var baseUrl = window.location.origin;

  const dropdown = {
      borderRadius: "10px",
      height: 50,
      padding: "25px",
  };

  const columns = [
    {
        name: 'NO.',
        selector: row => row.no,
        sortable: true,
        width: '6.2vw',
    },
    {
        name: 'Nama Materi',
        selector: row => row.materi,
        sortable: true,
        width: '15vw',
    },
    {
        name: 'Program Bimbingan',
        selector: row => row.program,
        sortable: true,
        width: '18vw',
    },
    {
        name: 'Regio',
        selector: row => row.regio,
        sortable: true,
        width: '10vw',
    },
    {
        name: 'Masa Berlaku',
        selector: row => row.masa,
        sortable: true,
        width: '15vw',
    },
    {
        name: 'Aksi',
        selector: row => row.aksi,
        sortable: true,
        width: '18vw',
    },
  ];
  const data = [
    {
        no: 1,
        materi: 'Tutorial Memandikan Bayi',
        program: 'Lorem ipsum',
        regio: 'Regio A',
        masa: '25 Des s.d 28 Des',
        aksi: <Link className="btnTable3" to="">Akses Video</Link>,
    },
  ];

  const customStyles = {
    table: {
      style: {
        borderRadius: '15px',
        border: '1px solid #BDC1C9',
      },
    },
    head: {
      style: {
        fontSize: '1.25rem',
        textTransform: "uppercase",
        color: '#6B7280',
        backgroundColor: '#f3f4f6',
        border: '1px solid #BDC1C9',
        borderTopLeftRadius: '15px',
        borderTopRightRadius: '15px',
      },
    },
    pagination: {
      style: {
        color: '#6B7280',
        fontSize: '1.1rem',
        minHeight: '56px',
        backgroundColor: '#f3f4f6',
        border: '1.25px solid #BDC1C9',
        borderBottomLeftRadius: '15px',
        borderBottomRightRadius: '15px',
      },
    },
  };

  return(
    <React.Fragment>
      <SideBar />
      <div className="md:ml-64 styleHead" style={{ backgroundColor: '#F3F4F6', minWidth: '100vh' }}>
        <div className="bg-orange md:px-8" style={{height: '180px', position: 'relative', padding: '0 30px'}}>
            <h1 className="tracking-wider title-24-600">Course Saya</h1><br />
            {/* <h3 className="text-left tracking-wider">Anda dapat menghubungi Admin terlebih dahulu untuk memastikan kelas dan program yang Anda daftarkan. <br /> <br />Kontak Admin <a href={contactLink()} target={'_blank'}> <i class="fa fa-whatsapp" aria-hidden="true"></i></a></h3> */}
            <img src={baseUrl + "/assets/logo/evaluasikelas.png"} className="img-opacity"></img>
        </div>

        <div className="px-3 md:px-8 pb-5">
          <div className="container mx-auto max-w-full">
            <div className="row">
              <div className="col-12">
                <Breadcrumb className="breadcrumbss my-4">
                  <Breadcrumb.Item href="/dashboard/course-saya">Course Saya</Breadcrumb.Item>
                  <Breadcrumb.Item active><span style={{fontWeight: 'bold'}}>Akses Course</span></Breadcrumb.Item>
                </Breadcrumb>
              </div>
              <div className="col-lg-4">
                <div className="cards pd-4">
                    <div className="flex lh-35">
                        <div className="w-40 fw-600">Regio</div>
                        <div className="w-60">: Bandung</div>
                    </div>
                    <div className="flex lh-35">
                        <div className="w-40 fw-600">Program</div>
                        <div className="w-60">: program</div>
                    </div>
                    <div className="flex lh-35">
                        <div className="w-40 fw-600">Kelas</div>
                        <div className="w-60">: Kelas A</div>
                    </div>
                </div>
              </div>
              <div className="col-12 my-4">
                <FormGroup>
                  <div className="SearchTable2 gap-3">
                    <input type="text" name="" className="inputSearchTable" placeholder="&#x1F50E;&#xFE0E; Search..." id="" />
                    <Form.Select
                        as="select"
                        aria-label="Program"
                        aria-describedby="basic-addon1"
                        className="my-tryout"
                    >
                        <option value="">Pilih Program</option>
                        <option value="1">Program A</option>
                        <option value="2">Program B</option>
                        <option value="3">Program C</option>
                    </Form.Select>
                    <Button className="btnSearchTable">Cari</Button>
                  </div>
                </FormGroup>
              </div>
              <div className="col-12 mt-3 mb-5">
                  <DataTable columns={columns} customStyles={customStyles} data={data} pagination />
              </div>
            </div>
          </div>
        </div>

      </div>
    </React.Fragment>
    
  )
}