import Button from '@material-tailwind/react/Button';
import React, { useEffect, useState } from 'react';
import { FormGroup, FormControl, Form, Modal } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import { Link, useParams } from 'react-router-dom';
import { BsPrinter } from 'react-icons/bs';
import { HiOutlinePencilAlt } from 'react-icons/hi';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import LogistikSideBar from '../../../components/layout/dashboard/logistik/LogistikSideBar';
import ElearningService from '../../../services/elearning';
import CustomDatatable from '../../../components/common/CustomDatatable';
import moment from 'moment-timezone';
import Swal from 'sweetalert2';

export default function InformasiResiLogistik() {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    var baseUrl = window.location.origin;
    document.title = "INGENIO Indonesia";

    const [inIdShipment, setInIdShipment] = useState(null);
    const [inResi, setInResi] = useState('');

    // Filtering data
    const [isLoading, setIsLoading] = useState(true);
    const [hasNext, setHasNext] = useState(false)
    const [hasPrev, setHasPrev] = useState(false)
    const [count, setCount] = useState(0);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [keyword, setKeyword] = useState('');
    const [userId, setUserId] = useState('');
    const [kelasSaya, setKelasSaya] = useState([])

    const { id } = useParams();

    const columns = [
        {
            name: 'NO.',
            field: 'no',
            index: true,
            selector: row => row.no,
            sortable: true,
        },
        {
            name: 'NAMA PESERTA',
            render: true,
            component: (row) => {
                return (
                    <td className='main-table__bodyRow'>{row.nama_peserta}</td>
                )
            },
            selector: row => row.namakelas,
            sortable: true,
            // width: '15vw',
        },
        {
            name: 'ALAMAT',
            render: true,
            component: (row) => {
                return (
                    <td className='main-table__bodyRow'>
                        {row.domicile_address}
                    </td>
                )
            },
            selector: row => row.programbimbingan,
            sortable: true,
            // width: '15vw',
        },
        {
            name: 'STATUS',
            render: true,
            component: (row) => {
                return (
                    <td className='main-table__bodyRow'>
                        {
                            row.last_status_tracking === 'sent' ?
                            <div className="btnTableDone">Terkirim</div>
                            :
                            <div className="btnTablePending">Pending</div>
                        }
                    </td>
                )
            },
            selector: row => row.programbimbingan,
            sortable: true,
            // width: '15vw',
        },
        {
            name: 'AKSI',
            render: true,
            component: (row) => {
                return (
                    <td className='main-table__bodyRow'>
                        {
                            row.resi === null ?
                            <OverlayTrigger placement="top" overlay={<Tooltip id="button-tooltip-2">Edit</Tooltip>}>
                                <Link to='#' className="btnCircleBlack mr-3" onClick={() => {
                                    setInIdShipment(row.id)
                                    handleShow()
                                }}><HiOutlinePencilAlt size="22"/></Link>
                            </OverlayTrigger>
                            :
                            <OverlayTrigger placement="top" overlay={<Tooltip id="button-tooltip-2">Print Resi</Tooltip>}><Link className="btnCircleBlack" 
                                to={{
                                    pathname: `/dashboard/logistik/olah-resi/${row.id}/print`,
                                    state: {
                                        resi: row.resi, 
                                        weight: row.weight ? row.weight : '0', 
                                        ongkir: row.ongkir ? row.ongkir : '0',
                                        insurance: row.insurance ? row.insurance : '0',
                                        participant_name: row.nama_peserta ? row.nama_peserta : '-',
                                        participant_address: row.domicile_address ? row.domicile_address : '-',
                                        postalcode: row.postalcode ? row.postalcode : '-',
                                        phone: row.phone_number ? row.phone_number : '-',
                                    }
                                }}><BsPrinter size="22"/></Link></OverlayTrigger>
                        }
                        
                    </td>
                )
            },
            minWidth: '190px',
            cell: () => <Link className="pilih-tryout p-2 text-center m-1" to="/dashboard/e-learning/kelas-saya/details">Akses Kelas</Link>,
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
    ];
    const data = [
        {
            no: 1,
            kode: '123345',
            nama: 'Lorem.',
            alamat: 'lorem ipsum dolor.',
            aksi: <Link className="btnSdhProses" to="/dashboard/logistik/olah-resi">Sudah Proses</Link>,
        },
        {
            no: 2,
            kode: '123346',
            nama: 'Lorem.',
            alamat: 'lorem ipsum.',
            aksi: <Link className="btnBlmProses" to="/dashboard/logistik/olah-resi">Belum Proses</Link>,
        },
    ];

    const customStyles = {
        table: {
          style: {
            borderRadius: '15px',
            border: '1px solid #BDC1C9',
          },
        },
        head: {
          style: {
            fontSize: '1.25rem',
            textTransform: "uppercase",
            color: '#6B7280',
            backgroundColor: '#f3f4f6',
            border: '1px solid #BDC1C9',
            borderTopLeftRadius: '15px',
            borderTopRightRadius: '15px',
          },
        },
        pagination: {
          style: {
            color: '#6B7280',
            fontSize: '1.1rem',
            minHeight: '56px',
            backgroundColor: '#f3f4f6',
            border: '1.25px solid #BDC1C9',
            borderBottomLeftRadius: '15px',
            borderBottomRightRadius: '15px',
          },
        },
    };

    const InputResi = (body) => {
        Swal.fire({  
            title: 'Sudah Yakin?',  
            text: 'Apakah Anda Yakin Ingin Update Data Ini?',  
            icon: 'warning',  
            showCancelButton: true,  
            cancelButtonText: 'Tidak',  
            confirmButtonText: 'Ya'  
        }).then((result) => {
          if (result.isConfirmed) {
              setIsLoading(true)
              ElearningService.inputResi(inIdShipment, body).then(resp => {
                  Succeed()
                  setIsLoading(false)
              }).catch(err => {
                  setIsLoading(false)
                  let msg = 'Internal Server Error'
                  console.error(err)
                  if(err.response) {
                      if(err.response.data.message) {
                          msg = err.response.data.message
                      }
                  }
  
                  Fail(msg)
              })
          }
        });   
    }
  
    const Succeed = () => {
        Swal.fire({  
            title: 'Data Berhasil Tersimpan !',  
            text: '',  
            icon: 'success',  
            showCancelButton: false,              
            confirmButtonText: 'OK'  
        }).then((result) => {
          if (result.isConfirmed) {
            getListKelasSaya();
            handleClose()
          }
        })
    }

    const getListKelasSaya = (params = '') => {
        setIsLoading(true)
        ElearningService.getListShipmentByKelasIdLogistik(id, params).then(resp => {
            if(resp.data.success === true) {
                const response = resp.data;
                setKelasSaya(response.data);

                setPage(response.meta.page)
                setPerPage(response.meta.perPage)
                setCount(response.meta.count)

                setHasNext(response.meta.hasNext)
                setHasPrev(response.meta.hasPrev);

                if(response.meta.userId) {
                    setUserId(response.meta.userId)
                }
            }
            setIsLoading(false)
        }).catch(err => {
            setIsLoading(false)
            console.error(err)
        })
    }

    useEffect(() => {
        getListKelasSaya();
    }, [])

    const Fail = (message) => {
        Swal.fire({  
            title: 'Gagal!',  
            text: message,  
            icon: 'warning',  
            showCancelButton: true,  
            cancelButtonText: 'Kembali',           
            showConfirmButton: false,  
        });   
      }

    return (
        <React.Fragment>
            <LogistikSideBar />
            <div className="md:ml-64 tutorDataKelas" style={{ backgroundColor: '#F3F4F6' }}>
                <div className="bg-orange md:px-8 pb-5" style={{height: '20vh', position: 'relative', padding: '0 30px 30px 30px'}}>
                    <h1 className="tracking-wider title-24-600">Informasi Resi</h1>
                    <img src={baseUrl + "/assets/logo/evaluasikelas.png"} className="img-opacity"></img>
                </div>

                <div className="px-3 md:px-8 mt-4 pb-5">
                    <div className="container mx-auto max-w-full">
                        <div className="row">
                            <div className="col-12 my-2">
                                <FormGroup>
                                    <div className="SearchTable">
                                        <input value={keyword}
                                            onChange={(e) => setKeyword(e.target.value)} type="text" name="" className="inputSearchTable" placeholder="&#x1F50E;&#xFE0E; Cari." id="" />
                                        <Button onClick={() => {
                                            getListKelasSaya(`page=${page}&perPage=${perPage}&keyword=${keyword}`)
                                        }} className="btnSearchTable">Cari</Button>
                                    </div>
                                </FormGroup>
                            </div>
                            <div className="col-12 mt-3">
                                {/* <DataTable columns={columns} customStyles={customStyles} data={data} pagination /> */}
                                <CustomDatatable 
                                    filteredItems={kelasSaya} 
                                    columns={columns}
                                    isLoading={isLoading}
                                    page={page}
                                    perPage={perPage}
                                    hasPrev={hasPrev}
                                    hasNext={hasNext}
                                    count={count}
                                    onTableChange={(e, data) => {
                                        switch (e) {
                                        case 'rowPerPageChange':
                                            setPerPage(data.perPage)
                                            getListKelasSaya(`page=${page}&perPage=${data.perPage}&keyword=${keyword}`)
                                            break;
                                        case 'rowPageChange':
                                            setPage(data.page)
                                            getListKelasSaya(`page=${data.page}&perPage=${perPage}&keyword=${keyword}`)
                                            break;
                                        
                                        default:
                                            break;
                                        }
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <Modal show={show} onHide={handleClose}       
                    size="md"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered>
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        <div className="title-20-700">Edit No Resi</div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row p-4">
                        <div className="col-12">
                            <div className="title-18-500 mb-1">No Resi</div>
                            <input value={inResi} onChange={(e) => setInResi(e.target.value)} type="text" class="formInputJurnal" placeholder="masukkan resi..." />
                        </div>
                        <div className="col-12 justify-end mt-5">
                            <Button type="submit" onClick={() => {
                                InputResi({
                                    resi: inResi
                                })
                            }} className="btnOrange3">
                                <span className="txt-btn">Submit</span>
                            </Button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            </div>
        </React.Fragment >

    )
}