import Button from '@material-tailwind/react/Button';
import React, { useState } from 'react';
import { FormGroup, FormControl, Form } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import { BsFillFileEarmarkFill } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import TutorSidebar from '../../../components/layout/dashboard/tutor/TutorSideBar';

export default function DataHonorarium() {
    document.title = "INGENIO Indonesia";
    const [program, setProgram] = useState('');
    const dropdown = {
        borderRadius: "10px",
        height: 50,
        padding: "25px",
    };
    const columns = [
        {
            name: 'NO.',
            selector: row => row.no,
            sortable: true,
            // width: '6vw',
        },
        {
            name: 'BULAN/TAHUN',
            selector: row => row.bulantahun,
            sortable: true,
            // width: '15vw',
        },
        {
            name: 'JUMLAH HONOR',
            selector: row => row.jumlah_honor,
            sortable: true,
            // width: '15vw',
        },
        {
            name: 'KETERANGAN',
            selector: row => row.keterangan,
            sortable: true,
            // width: '15vw',
        },

        {
            cell: () => <Link className="pilih-tryout p-2" to="download">
                <div className="row" style={{ padding: '0.1rem' }}>
                    <div className="col-2"><BsFillFileEarmarkFill /></div>
                    <div className="col-10">Download Slip Gaji</div>
                </div>
            </Link>,
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
            width: '20vw',
        },
    ];
    const data = [
        {
            no: 1,
            bulantahun: 'Januari/2021',
            jumlah_honor: 'Lorem ipsum dolor.',
            keterangan: <div className="paid" style={{ padding: '0.5rem', backgroundColor: '#22C55E', borderRadius: '0.3rem' }}>PAID</div>,
        },
        {
            no: 2,
            bulantahun: 'Februari/2021',
            jumlah_honor: 'Lorem ipsum dolor.',
            keterangan: <div className="paid" style={{ padding: '0.5rem', backgroundColor: '#F8CA2B', borderRadius: '0.3rem' }}>UNPAID</div>,
        },

        {
            no: 3,
            bulantahun: 'Maret/2021',
            jumlah_honor: 'Lorem ipsum dolor.',
            keterangan: <div className="paid" style={{ padding: '0.5rem', backgroundColor: '#22C55E', borderRadius: '0.3rem' }}>PAID</div>,
        },

        {
            no: 4,
            bulantahun: 'April/2021',
            jumlah_honor: 'Lorem ipsum dolor.',
            keterangan: <div className="paid" style={{ padding: '0.5rem', backgroundColor: '#F8CA2B', borderRadius: '0.3rem' }}>UNPAID</div>,
        },

        {
            no: 5,
            bulantahun: 'Mei/2021',
            jumlah_honor: 'Lorem ipsum dolor.',
            keterangan: <div className="paid" style={{ padding: '0.5rem', backgroundColor: '#22C55E', borderRadius: '0.3rem' }}>PAID</div>,
        },

        {
            no: 6,
            bulantahun: 'Juni/2021',
            jumlah_honor: 'Lorem ipsum dolor.',
            keterangan: <div className="paid" style={{ padding: '0.5rem', backgroundColor: '#22C55E', borderRadius: '0.3rem' }}>PAID</div>,
        },


    ];

    return (
        <React.Fragment>
            <TutorSidebar />
            <div className="md:ml-64" style={{ backgroundColor: '#F3F4F6' }}>
                <div className="bg-orange px-3 md:px-8 py-1 h-40">
                    <div className="container md:pr-8 md:pl-10">
                        <h3 className="text-left tracking-wider title-dashboard">Selamat Datang Tutor INGENIO!</h3>
                    </div>
                </div>

                <div className="px-3 md:px-8 mt-4">
                    <div className="container mx-auto max-w-full">
                        <div className="container md:pr-0 md:pl-0">
                            <h3 className="text-left tracking-wider title-dashboard" style={{ fontSize: '16px', color: '#6B7280' }}><strong style={{ color: 'black' }}>Nama Tutor :</strong> Tutor 1</h3>
                        </div>
                        <div className="container md:pr-0 md:pl-0">
                            <h3 className="text-left tracking-wider title-dashboard" style={{ fontSize: '16px', color: '#6B7280' }}><strong style={{ color: 'black' }}>Regio :</strong> Regio A</h3>
                        </div>
                        <div className="row">
                            <div className="col-md-4 col-lg-4 col-xs-12 mb-4 mt-3">
                                <FormGroup>
                                    <div className="row" style={{ backgroundColor: '#FFF', padding: '1rem', borderRadius: '1rem' }}>
                                        <div className="col-md-9">
                                            <Form.Control type="date" name="duedate" placeholder="Due date" />
                                        </div>
                                        <div className="col-3">
                                            <Button className="pilih-tryout">Cari</Button>
                                        </div>

                                    </div>

                                </FormGroup>
                            </div>
                        </div>
                        <div className="col-md-12 col-lg-12 col-xs-12 mt-3">
                            <DataTable columns={columns} data={data} pagination />
                        </div>
                    </div>
                </div>

            </div>
        </React.Fragment >

    )
}