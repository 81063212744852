export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";
export const SET_PROFILECOMPLETE = "SET_PROFILECOMPLETE";
export const SET_ROLE_STUDENT = "SET_ROLE_STUDENT";
export const SET_ROLE_AKADEMIK = "SET_ROLE_AKADEMIK";

export const SET_MESSAGE = "SET_MESSAGE";
export const CLEAR_MESSAGE = "CLEAR_MESSAGE";

export const SET_JAWABAN = "SET_JAWABAN";