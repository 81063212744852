import React from 'react';
import {
    IngenioNav2,
    IngenioFooter2
} from '../components/layout';
import { useHistory } from 'react-router-dom';

export default function Page404() {
    const history = useHistory();
    return (
      <React.Fragment>
        <div style={{ backgroundColor: 'white', height: '100vh' }}>
          <section className="container-fluid bg-desktop" style={{ backgroundImage: 'url(/assets/landing/bg-racoon.jpg)', height: '100%' ,backgroundRepeat: 'repeat', backgroundSize: 'cover' }}>
            <div className="container hero-section"> 
              <div className="row align-items-center g-2 px-4 py-5">
                <div className="col-sm-12 col-lg-6 mb-4">
                  <h1 className="display-1 1h-1 mb-1 text-start" style={{fontWeight: '700', color: 'black'}}>403</h1>
                  <h1 className="display-3 1h-1 mb-3 text-start" style={{fontWeight: '700', color: 'black'}}>Access Denied</h1>
                  <h3 className="mb-3 text-start" style={{fontWeight: '600', color: 'black', fontFamily: 'Quicksand', fontSize: '1.2rem'}}>Kamu tidak mempunyai akses untuk halaman ini</h3>
                  <button type="button" className="btn-home btn-hero" style={{color: 'black'}} onClick={() => {history.goBack()}}>Back</button>
                </div>
                <div className="col-sm-12 col-lg-6">
                  <img src="/assets/landing/partnership.png" alt="Rakun" className="mx-auto"></img>
                </div>
              </div>
            </div>
          </section>
        </div>
      </React.Fragment>
    )
}