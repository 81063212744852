import React from 'react';
import Card from '@material-tailwind/react/Card';
import CardHeader from '@material-tailwind/react/CardHeader';
import CardBody from '@material-tailwind/react/CardBody';

export default function NewActivityCard () {
  return (
    <Card>
        <CardHeader contentPosition="left" className="bg-orange border-4 border-white-600">
            <h2 className="text-white text-2xl">Aktivitas Terbaru</h2>
        </CardHeader>
        <CardBody>
            <div className="relative h-50">
                <ul>
                  <li>Dani Mochamad (Active yesterday)</li>
                </ul>
            </div>
        </CardBody>
    </Card>
  );
}