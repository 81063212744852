import React from 'react';
import {
    HalPrestasi,
} from '../components/prestasi';
import {
    IngenioNav,
    IngenioFooter
} from '../components/layout'
import '../index.css'

export default function PrestasiPage() {
    return (
        <React.Fragment>
            <IngenioNav />
            <div style={{ backgroundColor: 'white' }}>
                <HalPrestasi />
            </div>
            <IngenioFooter />
        </React.Fragment>

    )
}