import Button from '@material-tailwind/react/Button';
import React, { useEffect, useState } from 'react';
import { FormGroup, FormControl, Form, Modal } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import { Link } from 'react-router-dom';
import { BsPencilSquare, BsFillTrashFill, BsFillPlusCircleFill } from 'react-icons/bs';
import AkademikSidebar from '../../../../components/layout/dashboard/akademik/AkademikSideBar';
import CustomDatatable from '../../../../components/common/CustomDatatable';
import ElearningService from '../../../../services/elearning';
import moment from 'moment-timezone';
import Swal from 'sweetalert2';

export default function OlahMateri() {
    document.title = "INGENIO Indonesia";

    // Filtering data
    const [isLoading, setIsLoading] = useState(true);
    const [hasNext, setHasNext] = useState(false)
    const [hasPrev, setHasPrev] = useState(false)
    const [count, setCount] = useState(0);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [keyword, setKeyword] = useState('');
    const [kelasData, setKelasData] = useState([]);

    const dropdown = {
        borderRadius: "10px",
        height: 50,
        padding: "25px",
    };
    const columns = [
        {
            name: 'NO.',
            field: 'no',
            index: true,
            selector: row => row.no,
            sortable: true,
            minWidth: '1px'
            
        },
        {
            name: 'NAMA KELAS',
            field: 'nama_kelas',
            selector: row => row.kelas,
            sortable: true,
            
        },
        {
            name: 'NAMA REGIO',
            field: 'nama_regio',
            selector: row => row.regio,
            sortable: true,
             
        },
        {
            name: 'NAMA PROGRAM',
            render: true,
            component: (row) => {
                return (
                    <td className='main-table__bodyRow'>{row.elearning_program.nama_program}</td>
                )
            },
            selector: row => row.program,
            sortable: true,
            
        },
        {
            name: 'KAPASITAS',
            field: 'kapasitas',
            render: true,
            component: (row) => {
                return (
                    <td className='main-table__bodyRow'>{`${row.kapasitas} Peserta`}</td>
                )
            },
            selector: row => row.kapasitas,
            sortable: true,
            
        },
        {
            name: '',
            render: true,
            component: (row) => {
              return (
                <td className='main-table__bodyRow'>
                  <Link className="pilih-tryout p-2" to={`/dashboard/akademik/e-learning/detail-olah-materi/${row.id}`}>Pilih Kelas</Link>
                </td>
              )
            },
            selector: row => row.jumlah,
            sortable: true,
            minWidth: '20px',
            wrap: 'yes'
        }
    ];

    const getListKelas = (params = '') => {
        setIsLoading(true)
        ElearningService.getListKelasAkademik(params).then(resp => {
            const response = resp.data;
            setKelasData(response.data);

            setPage(response.meta.page)
            setPerPage(response.meta.perPage)
            setCount(response.meta.count)

            setHasNext(response.meta.hasNext)
            setHasPrev(response.meta.hasPrev);
            
            setIsLoading(false)
        }).catch(err => {
            setIsLoading(false)
            console.error(err)
        })
    }

    const Succeed = (message, onConfirm) => {
        Swal.fire({  
            title: 'Berhasil!',  
            text: message,  
            icon: 'success',  
            showCancelButton: false,              
            confirmButtonText: 'OK'  
        }).then((result) => {
          if (result.isConfirmed) {
            onConfirm()
          }
        })
    }
    
    const Fail = (message) => {
        Swal.fire({  
            title: 'Gagal!',  
            text: message,  
            icon: 'warning',  
            showCancelButton: true,  
            cancelButtonText: 'Kembali',           
            showConfirmButton: false,  
        });   
    }

    useEffect(() => {
        getListKelas();
    }, [])

    return (
        <React.Fragment>
            <AkademikSidebar judul={'Olah Materi'} />
            <div className="md:ml-64" style={{ backgroundColor: '#F3F4F6' }}>
                <div className="bg-orange px-3 md:px-8 py-1" style={{ height: '5vh' }}>
                    <div className="container md:pr-8 md:pl-10">
                        {/* <h3 className="text-left tracking-wider title-dashboard">Selamat Datang Staff Akademik INGENIO!</h3> */}
                    </div>
                </div>
                <div className="px-3 md:px-8 mt-5">
                    <div className="container mx-auto max-w-full">
                        <div className="row">
                            <div className="col-md-8 col-lg-4 col-xs-12 mb-4">
                                <FormGroup>
                                    <div className="row" style={{ backgroundColor: '#FFF', padding: '1rem', borderRadius: '1rem' }}>
                                        <div className="col-8">
                                            <input value={keyword} onChange={(e) => setKeyword(e.target.value)} type="text" name="" style={{ backgroundColor: '#F3F4F6', width: '100%', borderRadius: '8px', border: 'none !important', height: '45px', paddingLeft: '10px' }} placeholder="&#x1F50E;&#xFE0E; Nama Kelas." id="" />
                                        </div>
                                        <div className="col-3">
                                            <Button onClick={() => {
                                                getListKelas(`page=${page}&perPage=${perPage}&keyword=${keyword}`)
                                            }} className="pilih-tryout">Cari</Button>
                                        </div>
                                    </div>
                                </FormGroup>
                            </div>
                            <div className="col-8" style={{ padding: '1rem' }}>
                                
                            </div>
                            <div className="col-md-12 col-lg-12 col-xs-12">
                                {/* <DataTable columns={columns} data={data} pagination /> */}
                                <CustomDatatable 
                                    filteredItems={kelasData} 
                                    columns={columns}
                                    isLoading={isLoading}
                                    page={page}
                                    perPage={perPage}
                                    hasPrev={hasPrev}
                                    hasNext={hasNext}
                                    count={count}
                                    onTableChange={(e, data) => {
                                        switch (e) {
                                        case 'rowPerPageChange':
                                            setPerPage(data.perPage)
                                            getListKelas(`page=${page}&perPage=${data.perPage}&keyword=${keyword}`)
                                            break;
                                        case 'rowPageChange':
                                            setPage(data.page)
                                            getListKelas(`page=${data.page}&perPage=${perPage}&keyword=${keyword}`)
                                            break;
                                        
                                        default:
                                            break;
                                        }
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </React.Fragment >

    )
}