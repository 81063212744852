import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  SET_PROFILECOMPLETE,
  SET_ROLE_STUDENT,
  SET_ROLE_AKADEMIK
} from "../actions/types";

const user = localStorage.getItem("token") && localStorage.getItem("token") !== null ? JSON.parse(localStorage.getItem("token")) : null;
const role = localStorage.getItem("role");

const initialState = user ? {
  isLoggedIn: true,
  isProfileComplete: false,
  role
} : {
  isLoggedIn: false,
  isProfileComplete: false,
  role: ''
}; 

function authReducer ( state = initialState, action ) {

  const { type, payload } = action;
  // alert(JSON.stringify(state))

  switch(type) {
    case SET_PROFILECOMPLETE:
      return {
        ...state, 
        isProfileComplete: payload
      }
    case SET_ROLE_STUDENT:
      return {
        ...state, 
        role: 'STUDENT'
      }
    case SET_ROLE_AKADEMIK:
      return {
        ...state, 
        role: 'AKADEMIK'
      }
    case REGISTER_SUCCESS:
      return {
        ...state,
        isLoggedIn: false,
      };
    case REGISTER_FAIL:
      return {
        ...state,
        isLoggedIn: false,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        isLoggedIn: true,
        isProfileComplete: payload,
        role: 'STUDENT',
      };
    case LOGIN_FAIL:
      return {
        ...state,
        isLoggedIn: false,
      };
    case LOGOUT:
      return {
        ...state,
        isLoggedIn: false,
      };
    default:
      return state;
  }
}

export default authReducer