import Button from '@material-tailwind/react/Button';
import React, { useState, useEffect } from 'react';
import { FormGroup } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import { BsFillFileEarmarkArrowDownFill } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import { SideBar } from '../../../components/layout/dashboard';
import { contactLink } from '../../../helpers/utils'
import AuthService from '../../../services/auth'
import CustomDatatable from '../../../components/common/CustomDatatable';
import moment from 'moment-timezone';
import Swal from 'sweetalert2';

export default function Elibrary() {
    document.title = "INGENIO Indonesia";

    // Filtering data
  const [isLoading, setIsLoading] = useState(true);
  const [hasNext, setHasNext] = useState(false)
  const [hasPrev, setHasPrev] = useState(false)
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [keyword, setKeyword] = useState('');
  const [kelasData, setKelasData] = useState([]);

    const [program, setProgram] = useState('');
    const dropdown = {
        borderRadius: "10px",
        height: 50,
        padding: "25px",
    };
    const columns = [
        {
            name: 'NO.',
            field: 'no',
            index: true,
            selector: row => row.no,
            sortable: true,
            minWidth: '1px'
        },
        {
            name: 'KATEGORI',
            render: true,
            component: (row) => {
                return (
                    <td className='main-table__bodyRow'>{row.category.name}</td>
                )
            },
            selector: row => row.tanggal,
            sortable: true,
            // width: '15vw',
        },
        {
            name: 'NAMA DOKUMEN',
            render: true,
            component: (row) => {
                return (
                    <td className='main-table__bodyRow'>{row.file_name}</td>
                )
            },
            selector: row => row.tanggal,
            sortable: true,
            // width: '15vw',
        },
        {
            name: 'TAHUN',
            render: true,
            component: (row) => {
                return (
                    <td className='main-table__bodyRow'>{row.tahun}</td>
                )
            },
            selector: row => row.tanggal,
            sortable: true,
            // width: '15vw',
        },
        {
            name: 'PENULIS',
            render: true,
            component: (row) => {
                return (
                    <td className='main-table__bodyRow'>{row.author}</td>
                )
            },
            selector: row => row.tanggal,
            sortable: true,
            // width: '15vw',
        },
        {
          name: 'Aksi',
          render: true,
          component: (row) => {
              return (
                  <td className='main-table__bodyRow'>
                      <Link onClick={() => window.open(row.url, '_blank')} className="pilih-tryout p-2 text-center justify-center" to="#">Download <BsFillFileEarmarkArrowDownFill style={{marginLeft:'10px'}} /></Link>
                  </td>
              )
          },
          selector: row => row.tanggal,
          sortable: true,
          // width: '15vw',
      },
    ];
    const data = [
        {
            no: 1,
            namafile: 'Lorem ipsum.',
            bidang: 'Lorem ipsum.',
            topik: 'Lorem ipsum.',
            tahun: '0000',
            penulis: 'Lorem ipsum.',
        },
        {
            no: 2,
            namafile: 'Lorem ipsum.',
            bidang: 'Lorem ipsum.',
            topik: 'Lorem ipsum.',
            tahun: '0000',
            penulis: 'Lorem ipsum.',
        },
        {
            no: 3,
            namafile: 'Lorem ipsum.',
            bidang: 'Lorem ipsum.',
            topik: 'Lorem ipsum.',
            tahun: '0000',
            penulis: 'Lorem ipsum.',
        },
        {
            no: 4,
            namafile: 'Lorem ipsum.',
            bidang: 'Lorem ipsum.',
            topik: 'Lorem ipsum.',
            tahun: '0000',
            penulis: 'Lorem ipsum.',
        },
        {
            no: 5,
            namafile: 'Lorem ipsum.',
            bidang: 'Lorem ipsum.',
            topik: 'Lorem ipsum.',
            tahun: '0000',
            penulis: 'Lorem ipsum.',
        },
        {
            no: 6,
            namafile: 'Lorem ipsum.',
            bidang: 'Lorem ipsum.',
            topik: 'Lorem ipsum.',
            tahun: '0000',
            penulis: 'Lorem ipsum.',
        },
    ];

    const getListKelas = (params = '') => {
        setIsLoading(true)
        AuthService.getListElibParticipant(params).then(resp => {
            const response = resp.data;
            setKelasData(response.data);
    
            setPage(response.meta.page)
            setPerPage(response.meta.perPage)
            setCount(response.meta.count)
    
            setHasNext(response.meta.hasNext)
            setHasPrev(response.meta.hasPrev);
            
            setIsLoading(false)
        }).catch(err => {
            let msg = 'Internal Server Error'
            setIsLoading(false)
            console.error(err)
            if(err.response) {
                if(err.response.data.message) {
                    msg = err.response.data.message
                }
            }
    
            Fail(msg)
        })
      }

      const Fail = (message) => {
        Swal.fire({  
            title: 'Gagal!',  
            text: message,  
            icon: 'warning',  
            showCancelButton: true,  
            cancelButtonText: 'Kembali',           
            showConfirmButton: false,  
        });   
      }
    
      useEffect(() => {
        getListKelas()
      }, [])

    return (
        <React.Fragment>
            <SideBar />
            <div className="md:ml-64 styleHead" style={{ backgroundColor: '#F3F4F6' }}>
                <div className="bg-orange px-3 md:px-8 py-1 h-40">
                    <div className="container md:pr-8 md:pl-10">
                        <h3 className="text-left tracking-wider title-dashboard">Selamat datang di Kelas Bimbingan Intensif Ingenio Indonesia</h3>
                        <br />
                        <h3 className="text-left tracking-wider txt-none">Anda dapat menghubungi Admin terlebih dahulu untuk memastikan kelas dan program yang Anda daftarkan. <br /> <br />Kontak Admin <a href={contactLink()} target={'_blank'}> <i class="fa fa-whatsapp" aria-hidden="true"></i></a></h3>
                    </div>
                </div>

                <div className="px-3 md:px-8 mt-4">
                    <div className="container mx-auto max-w-full">
                        <div className="row">
                            <div className="col-md-4 col-lg-4 col-xs-12 mb-4">
                                <FormGroup>
                                    <div className="SearchTable gap-3">
                                    <input  onChange={(e) => {
                                    setKeyword(e.target.value)
                                    }}type="text" name="" className="inputSearchTable" placeholder="&#x1F50E;&#xFE0E; cari kategori." id="" />
                                    <Button onClick={() => {
                                        getListKelas(`page=${page}&perPage=${perPage}&keyword=${keyword}`)
                                    }} className="btnSearchTable">Cari</Button>
                                    </div>

                                </FormGroup>
                            </div>
                        </div>
                        <div className="col-md-12 col-lg-12 col-xs-12 mt-3">
                            {/* <DataTable columns={columns} data={data} pagination /> */}
                            <CustomDatatable 
                                filteredItems={kelasData} 
                                columns={columns}
                                isLoading={isLoading}
                                page={page}
                                perPage={perPage}
                                hasPrev={hasPrev}
                                hasNext={hasNext}
                                count={count}
                                onTableChange={(e, data) => {
                                    switch (e) {
                                    case 'rowPerPageChange':
                                        setPerPage(data.perPage)
                                        getListKelas(`page=${page}&perPage=${data.perPage}&keyword=${keyword}`)
                                        break;
                                    case 'rowPageChange':
                                        setPage(data.page)
                                        getListKelas(`page=${data.page}&perPage=${perPage}&keyword=${keyword}`)
                                        break;
                                    
                                    default:
                                        break;
                                    }
                                }}
                            />
                        </div>
                    </div>
                </div>

            </div>
        </React.Fragment >

    )
}