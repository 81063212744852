import Button from '@material-tailwind/react/Button';
import React, { useEffect, useState } from 'react';
import { FormGroup, FormControl, Form, Modal } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import { Link, useParams } from 'react-router-dom';
import { BsArrowLeftShort, BsFillTrashFill, BsFillPlusCircleFill, BsFillCloudArrowDownFill } from 'react-icons/bs';
import AkademikSidebar from '../../../../components/layout/dashboard/akademik/AkademikSideBar';
import ElearningService from '../../../../services/elearning';
import CustomDatatable from '../../../../components/common/CustomDatatable';
import moment from 'moment-timezone';
import Swal from 'sweetalert2';

export default function DetailEvaluasiTutor() {
    document.title = "INGENIO Indonesia";
    var baseUrl = window.location.origin;

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    // Filtering data
    const [isLoading, setIsLoading] = useState(true);
    const [hasNext, setHasNext] = useState(false)
    const [hasPrev, setHasPrev] = useState(false)
    const [count, setCount] = useState(0);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [keyword, setKeyword] = useState('');
    const [jadwalKelasData, setJadwalKelasData] = useState([])

    const { id } = useParams();

    const dropdown = {
        borderRadius: "10px",
        height: 50,
        padding: "25px",
    };

    const columns = [
        {
            name: 'NO.',
            index: true,
            selector: row => row.no,
            sortable: true,
            // width: '6vw',
        },
        {
            name: 'NAMA MATERI',
            field: 'kelas',
            render: true,
            component: (row) => {
                return (
                    <td className='main-table__bodyRow'>{`${row.nama_materi}`}</td>
                )
            },
            selector: row => row.kelas,
            sortable: true,
            // width: '10vw',
        },
        {
            name: 'NAMA TUTOR',
            field: 'kelas',
            render: true,
            component: (row) => {
                return (
                    <td className='main-table__bodyRow'>{`${row.nama_tutor}`}</td>
                )
            },
            selector: row => row.kelas,
            sortable: true,
            // width: '10vw',
        },
        {
            name: 'AKSI',
            render: true,
            component: (row) => {
              return (
                <td className='main-table__bodyRow'>
                  <Link className="btnTable" to={`/dashboard/akademik/e-learning/evaluasi-tutor/detail-evaluasi/${row.id_user}/kelas/${row.kelas.id}`}>Detail Evaluasi</Link>
                </td>
              )
            },
            selector: row => row.jumlah,
            sortable: true,
            minWidth: '20px',
            wrap: 'yes'
        },
        // {
        //     cell: () => <Link className="pilih-tryout p-2" to="#"><BsPencilSquare /></Link>,
        //     ignoreRowClick: true,
        //     allowOverflow: true,
        //     button: true,
        //     // width: '5vw',
        // },
        // {
        //     cell: () => <Link className="pilih-tryout p-2" to="#"><BsFillTrashFill /></Link>,
        //     ignoreRowClick: true,
        //     allowOverflow: true,
        //     button: true,
        //     // width: '5vw',
        // },
    ];
    const data = [
        {
            no: 1,
            kode: '11223344',
            nama: 'Lorem ipsum.',
            aksi: <Link className="btnTable" to="/dashboard/akademik/e-learning/evaluasi-tutor/detail/detail-evaluasi">Detail Evaluasi</Link>,
        },
        {
            no: 2,
            kode: '11223344',
            nama: 'Lorem ipsum.',
            aksi: <Link className="btnTable" to="/dashboard/akademik/e-learning/evaluasi-tutor/detail/detail-evaluasi">Detail Evaluasi</Link>,
        },
        {
            no: 3,
            kode: '11223344',
            nama: 'Lorem ipsum.',
            aksi: <Link className="btnTable" to="/dashboard/akademik/e-learning/evaluasi-tutor/detail/detail-evaluasi">Detail Evaluasi</Link>,
        },
        {
            no: 4,
            kode: '11223344',
            nama: 'Lorem ipsum.',
            aksi: <Link className="btnTable" to="/dashboard/akademik/e-learning/evaluasi-tutor/detail/detail-evaluasi">Detail Evaluasi</Link>,
        },
        {
            no: 5,
            kode: '11223344',
            nama: 'Lorem ipsum.',
            aksi: <Link className="btnTable" to="/dashboard/akademik/e-learning/evaluasi-tutor/detail/detail-evaluasi">Detail Evaluasi</Link>,
        },
    ];

    const customStyles = {
        table: {
          style: {
            borderRadius: '15px',
            border: '1px solid #BDC1C9',
          },
        },
        head: {
          style: {
            fontSize: '1.25rem',
            textTransform: "uppercase",
            color: '#6B7280',
            backgroundColor: '#f3f4f6',
            border: '1px solid #BDC1C9',
            borderTopLeftRadius: '15px',
            borderTopRightRadius: '15px',
          },
        },
        pagination: {
          style: {
            color: '#6B7280',
            fontSize: '1.1rem',
            minHeight: '56px',
            backgroundColor: '#f3f4f6',
            border: '1.25px solid #BDC1C9',
            borderBottomLeftRadius: '15px',
            borderBottomRightRadius: '15px',
          },
        },
    };

    const getListJadwalKelas = (params = '') => {
        setIsLoading(true)
        ElearningService.getListJadwalKelasAkademik(params, id).then(resp => {
            const response = resp.data;
            setJadwalKelasData(response.data);

            setPage(response.meta.page)
            setPerPage(response.meta.perPage)
            setCount(response.meta.count)

            setHasNext(response.meta.hasNext)
            setHasPrev(response.meta.hasPrev);
            
            setIsLoading(false)
        }).catch(err => {
            setIsLoading(false)
            console.error(err)
        })
    }

    useEffect(() => {
        getListJadwalKelas();
    }, [])

    return (
        <React.Fragment>
            <AkademikSidebar />
            <div className="md:ml-64 tutorDataKelas" style={{ backgroundColor: '#F3F4F6', minHeight: '100vh' }}>
                <div className="bg-orange md:px-8 pb-4" style={{height: '24vh', position: 'relative', padding: '0 30px 30px 30px'}}>
                    <h1 className="tracking-wider title-26-600">Evaluasi Tutor</h1>
                    <h2 className="tracking-wider title-20-700 mt-5">Selamat Datang Staff Akademik INGENIO !</h2>
                    <img src={baseUrl + "/assets/logo/evaluasikelas.png"} className="img-opacity"></img>
                </div>

                <div className="px-3 md:px-8 mt-4 pb-5">
                    <div className="container mx-auto max-w-full">
                        <div className="justify-start mb-4">
                            <Link to="/dashboard/akademik/e-learning/evaluasi-tutor">
                                <div className="navBack">
                                    <BsArrowLeftShort size="19px"/>Kembali
                                </div> 
                            </Link>
                        </div>
                        <div className="row">
                            <div className="col-12 mb-4">
                                <FormGroup>
                                    <div className="SearchTable">
                                        <input value={keyword} onChange={(e) => setKeyword(e.target.value)}  type="text" name="" className="inputSearchTable" placeholder="&#x1F50E;&#xFE0E; Search..." id="" />
                                        <Button onClick={() => {
                                                getListJadwalKelas(`page=${page}&perPage=${perPage}&keyword=${keyword}`)
                                            }} className="btnSearchTable">Cari</Button>
                                    </div>
                                </FormGroup>
                            </div>
                            <div className="col-12">
                                {/* <DataTable columns={columns} customStyles={customStyles} data={data} pagination /> */}
                                <CustomDatatable 
                                    filteredItems={jadwalKelasData} 
                                    columns={columns}
                                    isLoading={isLoading}
                                    page={page}
                                    perPage={perPage}
                                    hasPrev={hasPrev}
                                    hasNext={hasNext}
                                    count={count}
                                    onTableChange={(e, data) => {
                                        switch (e) {
                                        case 'rowPerPageChange':
                                            setPerPage(data.perPage)
                                            getListJadwalKelas(`page=${page}&perPage=${data.perPage}&keyword=${keyword}`)
                                            break;
                                        case 'rowPageChange':
                                            setPage(data.page)
                                            getListJadwalKelas(`page=${data.page}&perPage=${perPage}&keyword=${keyword}`)
                                            break;
                                        
                                        default:
                                            break;
                                        }
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment >

    )
}