import React from 'react';
import { useState } from 'react';
import AdminSideBar from '../../../components/layout/dashboard/admin/AdminSideBar';
import { BsFillFileEarmarkArrowUpFill } from 'react-icons/bs';
import { Form, Tabs, Tab } from 'react-bootstrap';
import { filePdfValidation, fileValidation } from '../../../helpers/utils'
import Swal from 'sweetalert2';
import LandingService from '../../../services/landing'
import moment from 'moment-timezone';

export default function PengumumanAdmin () {
  document.title = "INGENIO Indonesia";

  const [selectedImage, setSelectedImage] = useState();
  const [selectedFile, setSelectedFile] = useState();
  const imageChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setSelectedImage(e.target.files[0]);
    }
  };

  const [isloading, setIsLoading] = useState(false)

  const [inName, setInName] = useState('');
  const [inContent, setInContent] = useState('');
  const [inRolecode, setInRolecode] = useState('');
  const [inStartDate, setInStartDate] = useState('');
  const [inUrlImage, setInUrlImage] = useState('-');
  const [inUrlFile, setInUrlFile] = useState('-');

  const removeSelectedImage = () => {
    setSelectedImage();
  };

  const AreYouSure = (body) => {
    Swal.fire({  
        title: 'Sudah Yakin?',  
        text: 'Apakah Anda Yakin?',  
        icon: 'warning',  
        showCancelButton: true,  
        cancelButtonText: 'Tidak',  
        confirmButtonText: 'Ya'  
    }).then((result) => {
      if (result.isConfirmed) {
        createPengumuman(body)
      }
    });   
}

const Succeed = () => {
    Swal.fire({  
        title: 'Data Berhasil Tersimpan !',  
        text: '',  
        icon: 'success',  
        showCancelButton: false,              
        confirmButtonText: 'OK'  
    })
}

const Fail = (message) => {
  Swal.fire({  
      title: 'Gagal!',  
      text: message,  
      icon: 'warning',  
      showCancelButton: true,  
      cancelButtonText: 'Kembali',           
      showConfirmButton: false,  
  });   
}

const createPengumuman = async (body) => {
    setIsLoading(true)
    let urlFile = '-';
    let urlImage = '-'

    // Image validation
    if(selectedImage){
      const file = selectedImage
      const size = file.size;
      const name = file.name;
      if(!fileValidation(name.split('.')[name.split('.').length -1])){
          Swal.fire({  
              title: 'Gagal!',  
              text: 'File not allowed (jpg, png)',  
              icon: 'warning',  
              showCancelButton: true,  
              cancelButtonText: 'Kembali',           
              showConfirmButton: false,  
          });  
          setIsLoading(false)
          return;
      }

      let formData = new FormData();
      formData.append('file', selectedImage);

      try {
          const fileResp = await LandingService.uploadFile(formData);
          urlImage = fileResp.data.data.url
      }catch(err) {
          let msg = 'Internal Server Error'
          setIsLoading(false)
          console.error(err)
          if(err.response) {
              if(err.response.data.message) {
                  msg = err.response.data.message
              }
          }

          Fail(msg)
      }
    }

    if(selectedFile) {
      const file = selectedFile
      const size = file.size;
      const name = file.name;
      if(!filePdfValidation(name.split('.')[name.split('.').length -1])){
          Swal.fire({  
              title: 'Gagal!',  
              text: 'File not allowed (pdf)',  
              icon: 'warning',  
              showCancelButton: true,  
              cancelButtonText: 'Kembali',           
              showConfirmButton: false,  
          });  
          setIsLoading(false)
          return;
      }

      let formData = new FormData();
      formData.append('file', selectedFile);

      try {
          const fileResp = await LandingService.uploadFile(formData);
          urlFile = fileResp.data.data.url
      }catch(err) {
          let msg = 'Internal Server Error'
          setIsLoading(false)
          console.error(err)
          if(err.response) {
              if(err.response.data.message) {
                  msg = err.response.data.message
              }
          }

          Fail(msg)
      }
    }

    const bodyRequest = {
      ...body,
      url_image: urlImage,
      url_file: urlFile,
    }

    LandingService.createPengumuman(bodyRequest).then(resp => {
        Succeed()
        setSelectedImage()
        setSelectedFile()
        setIsLoading(false)
    }).catch(err => {
      setIsLoading(false)
      let msg = 'Internal Server Error'
      console.error(err)
      if(err.response) {
          if(err.response.data.message) {
              msg = err.response.data.message
          }
      }

      Fail(msg)
    })
}



  return(
    <React.Fragment>
      <AdminSideBar judul={'Pengumuman'} />
      <div className="md:ml-64 Landing" style={{ backgroundColor: '#F3F4F6' }}>
        <div className="bg-orange px-3 md:px-8 py-1" style={{height: '2.5rem'}}>
          <div className="container md:pr-8 md:pl-10">
          </div>
        </div>

        <div className="px-3 md:px-8 pb-5">
          <div className="container mx-auto max-w-full">
            <div className="row mt-5">
              <div className="col-12">
                <div className="cards pb-4">
                  <div className="row">
                    <div className="col-12 flex gap-4" style={{padding: '2.4rem 3.6rem 0 3.6rem'}}>
                      <img src={"/assets/img/info.jpg"} className="imgInfo" />
                      <div className="title-24-700 mt-4">Pengumuman</div>
                    </div>
                    <div className="col-12 mt-2 mb-4" style={{padding: '0 3.6rem'}}>
                      <div className="borderBottomGrey2"></div>
                    </div>
                  </div>
                  <div className="row" style={{padding: '0 3.6rem 2rem 3.6rem'}}>
                    <div className="col-12">
                      <div className="title-20-600">Update Pengumuman</div>
                      <div className="borderOrange2"></div>
                    </div>
                    <div className="col-12 mb-4">
                        <Form.Select
                          onChange={(e) => setInRolecode(e.target.value)}
                          style={{maxWidth:'400px'}}>
                            <option selected disabled>Pilih Tujuan</option>
                            <option value="4">Portal Peserta</option>
                            <option value="3">Portal Tutor</option>
                            <option value="2">Portal Akademik</option>
                            <option value="6">Portal Akuntan</option>
                            <option value="5">Portal Logistik</option>
                            <option value="1">Portal Admin</option>
                        </Form.Select>
                    </div>
                    <div className="col-md-6">
                      <div className="title-18-500 mb-1">Nama Pengumuman</div>
                      <input value={inName} onChange={(e) => setInName(e.target.value)} type="text" class="formInput" placeholder="nama pengumuman..." />
                    </div>
                    <div className="col-md-6">
                      <div className="title-18-500 mb-1">Tanggal</div>
                      <input value={inStartDate} onChange={(e) => {
                        const inDate = moment(e.target.value).format('YYYY-MM-DD')
                        setInStartDate(inDate)
                      }} type="date" class="formInput" />
                    </div>
                    <div className="col-12">
                      <div className="title-18-500 mb-1">Isi Pengumuman</div>
                      <textarea value={inContent} onChange={(e) => setInContent(e.target.value)} className="formInput" name="" id="" placeholder="tuliskan isi pengumuman..." style={{minHeight: '100px', padding: '12px 25px'}}></textarea>
                    </div>
                    <div className="col-md-6">
                    <div className="title-18-500 mb-1">Upload File <span className="txtpdf ml-2">(Gambar)</span></div>
                      <div className="imgContainer">
                        <input type="file" className="d-none" id="images" accept="image/*" onChange={imageChange} />
                        <label htmlFor="images" className="imgLanding">
                          <i class="imgIcon fas fa-upload"></i>
                          <div className="name">Click for upload</div> 
                        </label>
                        {selectedImage && (
                          <div className="selectedImg">
                            <img src={URL.createObjectURL(selectedImage)} alt="Thumb" className="preview" />
                            <button className="btnRemove" onClick={removeSelectedImage}>
                              <i class="removeIcon fas fa-times"></i>
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="title-18-500 mb-1">Upload File<span className="txtpdf ml-2">(PDF)</span></div>
                      <div className="relative">
                        <input onChange={(e) => {setSelectedFile(e.target.files[0])}} type="file" id="file" className="custom-file-input" style={{ position: 'absolute', right: '16px', display: 'none' }} />
                        <label htmlFor="file" className="labelFile">Upload Here <BsFillFileEarmarkArrowUpFill size="18px"/></label>
                      </div>
                    </div>
                    <div className="col-12 mt-4">
                      <div className="borderTopGrey"></div>
                      <div className="justify-end mt-4">
                        <button type='submit' onClick={() => {
                          AreYouSure({
                            name: inName,
                            content: inContent,
                            start_date: inStartDate,
                            rolecode: parseInt(inRolecode)
                          })
                        }} className="btnOrange3">Submit</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
    
  )
}