import React from 'react'
import styles from '../../index.module.css';
import { Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";


export default function ProgramCard(props) {
  let baseURL = window.location.origin;

  return (
    <React.Fragment>
      <Card className="program-card-container">
        <Card.Body className="content-box">
          <div className="row p-2">
            <div className="col-12 col-md-3 mb-4">
              <img src="/assets/landing/nurse 2.png" className={"nurse-bg-" + props.data.colorName + ' mx-auto'} alt="nurse"></img>
            </div>
            <div className="col-12 col-md-9">
            <h5 className="button5-title text-center text-md-start">{props.data.text}</h5>
            </div>
          </div>
        </Card.Body>
        <Card.Footer className="content-box-footer">
          <button className={"btn button5 button5-title button5-" + props.data.colorName} style={{color: props.data.color}} onClick={(e) => {e.preventDefault();window.location.href= props.data.href;}}>Detail Program &nbsp; <FontAwesomeIcon icon={faChevronRight} /></button>
        </Card.Footer>
      </Card>
    </React.Fragment>
  )
}