import React, { useEffect, useState } from 'react';
import {
  SideBar
} from '../../../components/layout/dashboard';
import AkuntanSideBar from '../../../components/layout/dashboard/akuntan/AkuntanSidebar';
import { BsArrowLeft } from 'react-icons/bs';
import Button from '@restart/ui/esm/Button';
import { Image } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import axios from 'axios';
import { addCommas, contactLink } from '../../../helpers/utils'

export default function ElearningProsesBayar() {
  document.title = "INGENIO Indonesia";
  const [program, setProgram] = useState('');
  const [transaction, setTransaction] = useState(null)

  function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
  }
  const query = useQuery()

  useEffect(() => {
    const transactionId = query.get("transaction_id");
    axios.get(`${process.env.REACT_APP_TRYOUTURL}transaction/participant/${transactionId}`, {
      headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem('token'))}`
      }
    }).then(resp => {
      setTransaction(resp.data.data)
    }).catch(err => {
      console.error(err)
    })
  }, [])

  const renderIncludeTryout = (transactionItems) => {
    if(transactionItems.elearning.transaction_item_elearning_tryouts && transactionItems.elearning.transaction_item_elearning_tryouts !== null){
        if(transactionItems.elearning.transaction_item_elearning_tryouts.length > 0) {
            return transactionItems.elearning.transaction_item_elearning_tryouts[0].paket_tryout
        }
    }
    
    return '-'
  }
  const renderCourierCost = (transactionItems) => {
    if(transactionItems.courier_cost && transactionItems.courier_cost !== null){
      return `IDR ${addCommas(transactionItems.courier_cost)}`;
    }
    
    return '-'
  }

  const renderCourierService = (transactionItems) => {
    if(transactionItems.courier_service && transactionItems.courier_service !== null){
      return `LION PARCEL (${transactionItems.courier_service})`;
    }
    
    return '-'
  }

  return (
    <React.Fragment>
      {
        query.get("single_view") ?
        <AkuntanSideBar />
        :
        <SideBar />
      }
      <div className="md:ml-64">

        <div className="px-3 md:px-8">
          <div className="container mx-auto max-w-full">
            <div className="card-gede card-bayar my-3 mx-auto text-center">
              <Image src="/assets/landing/rotate-left.png" className="mx-auto mt-3" style={{ width: '10%' }}></Image>
              <p style={{ fontSize: "14px", lineHeight: "22px", letterSpacing: "0.06em", color: "#F8CA2B", fontWeight: '500' }} className="mt-2">Pembayaran Anda sedang diproses</p> <br />
              <p style={{ fontSize: "36px", lineHeight: "45px", letterSpacing: "0.06em", color: "#000000", fontWeight: 'bold' }}>{(transaction && transaction !== null) ? `IDR ${addCommas(transaction.grand_total)}` : ''} </p> <br />

              <table className="" style={{ width: '100%' }}>
                <tr>
                  <td style={{ width: '50%', textAlign: 'left' }}><p>Nama Kelas E-Elearning</p></td>
                  {
                    (transaction && transaction !== null) ?
                    (<td style={{ width: '50%', textAlign: 'right' }}><b>{(transaction.transaction_items.length > 0) ? transaction.transaction_items[0].elearning.nama_kelas : ''}</b></td>)
                    :
                    null
                  }
                  
                </tr>
                <tr>
                  <td style={{ width: '50%', textAlign: 'left' }}><p>Program Bimbingan</p></td>
                  {
                    (transaction && transaction !== null) ?
                    (<td style={{ width: '50%', textAlign: 'right' }}><b>{(transaction.transaction_items.length > 0) ? transaction.transaction_items[0].elearning.kelas_elearning_program : ''}</b></td>)
                    :
                    null
                  }
                  
                </tr>
                <tr>
                  <td style={{ width: '50%', textAlign: 'left' }}><p>Kelas Regio</p></td>
                  {
                    (transaction && transaction !== null) ?
                    (<td style={{ width: '50%', textAlign: 'right' }}><b>{(transaction.transaction_items.length > 0) ? transaction.transaction_items[0].elearning.kelas_regio : ''}</b></td>)
                    :
                    null
                  }
                  
                </tr>
                <tr>
                  <td style={{ width: '50%', textAlign: 'left' }}><p>Termasuk Tryout</p></td>
                  {
                    (transaction && transaction !== null) ?
                    (<td style={{ width: '50%', textAlign: 'right' }}><b>{(transaction.transaction_items.length > 0) ? renderIncludeTryout(transaction.transaction_items[0]) : ''}</b></td>)
                    :
                    null
                  }
                  
                </tr>
                <tr>
                  <td style={{ width: '50%', textAlign: 'left' }}><p>Biaya Pengiriman</p></td>
                  {
                    (transaction && transaction !== null) ?
                    (<td style={{ width: '50%', textAlign: 'right' }}><b>{(transaction.transaction_items.length > 0) ? renderCourierCost(transaction.transaction_items[0]) : ''}</b></td>)
                    :
                    null
                  }
                </tr>
                <tr>
                  <td style={{ width: '50%', textAlign: 'left' }}><p>Layanan Pengiriman</p></td>
                  {
                    (transaction && transaction !== null) ?
                    (<td style={{ width: '50%', textAlign: 'right' }}><b>{(transaction.transaction_items.length > 0) ? renderCourierService(transaction.transaction_items[0]) : ''}</b></td>)
                    :
                    null
                  }
                </tr>
                <tr>
                  <td style={{ width: '50%', textAlign: 'left' }}><p>Biaya Admin</p></td>
                  <td style={{ width: '50%', textAlign: 'right' }}><b>IDR 2.500</b></td>
                </tr>
                <tr>
                  <td style={{ width: '50%', textAlign: 'left' }}><p>No. Transaksi</p></td>
                  <td style={{ width: '50%', textAlign: 'right' }}><b>{(transaction && transaction !== null) ? `#${transaction.id}` : ''}</b></td>
                </tr>
                <tr>
                  <td style={{ width: '50%', textAlign: 'left' }}><p>Kode Pembayaran</p></td>
                  <td style={{ width: '50%', textAlign: 'right' }}><b>{(transaction && transaction !== null) ? transaction.payment_code : ''}</b></td>
                </tr>
              </table>

              <p className="py-5" style={{ fontSize: "14px", lineHeight: "17px", letterSpacing: "0.06em", color: "#000000", fontWeight: '500', textAlign: "center" }}>Segera selesaikan pembayaran Anda selama <strong>2x24 jam</strong></p>

              <Link className="pilih-tryout text-black mb-4" to="/dashboard/transaction">Keluar</Link>

              <p className="pt-5 pb-2" style={{ fontSize: "14px", lineHeight: "17px", letterSpacing: "0.06em", color: "#000000", fontWeight: '500', textAlign: "center" }}>Jika dalam 2x24 jam setelah Anda membayar E-Learning belum teraktivasi, <strong>hubungi WA Admin <a href={contactLink()}>+6281358889123</a></strong></p>
            </div>
          </div>
        </div>

      </div>
    </React.Fragment>

  )
}