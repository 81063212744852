import TryoutService from "../../services/tryout";
import { SET_JAWABAN } from "./types";

// TRYOUT ACTIONS
export const createTryout = (payload) => (dispatch) => {
  return TryoutService.createTryout(payload).then(
    (response) => {
      if(response.data.success === true) {
        console.log(response.data);
        return Promise.resolve(response.data);
      } else {
        console.log(response.data);

        return Promise.reject();
      }
    },
  );
};

export const updateTryout = (id, payload) => (dispatch) => {
  return TryoutService.updateTryout(id, payload).then(
    (response) => {
      if(response.data.success === true) {
        console.log(response.data);
        return Promise.resolve(response.data);
      } else {
        console.log(response.data);

        return Promise.reject();
      }
    },
  );
};

export const getAllTryout = (param = '') => (dispatch) => {
  return TryoutService.getAllTryout(param).then(
    (response) => {
      if(response.data.success === true) {
        console.log(response.data);
        return Promise.resolve(response.data);
      } else {
        console.log(response.data);

        return Promise.reject();
      }
    }
  )
}

export const getAllTryoutHistory = () => (dispatch) => {
  return TryoutService.getAllTryoutHistory().then(
    (response) => {
      if(response.data.success === true) {
        console.log(response.data);
        return Promise.resolve(response.data);
      } else {
        console.log(response.data);

        return Promise.reject();
      }
    }
  )
}

export const getAllTryoutBank = () => (dispatch) => {
  return TryoutService.getAllTryoutBank().then(
    (response) => {
      if(response.data.success === true) {
        console.log(response.data);
        return Promise.resolve(response.data);
      } else {
        console.log(response.data);

        return Promise.reject();
      }
    }
  )
}

export const getAllTryoutStudent = () => (dispatch) => {
  return TryoutService.getAllTryoutStudent().then(
    (response) => {
      if(response.data.success === true) {
        console.log(response.data);
        return Promise.resolve(response.data);
      } else {
        console.log(response.data);

        return Promise.reject();
      }
    }
  )
}

export const getTryoutDetail = (id) => (dispatch) => {
  return TryoutService.getTryoutDetail(id).then(
    (response) => {
      if(response.data.success === true) {
        console.log(response.data);
        return Promise.resolve(response.data);
      } else {
        console.log(response.data);

        return Promise.reject();
      }
    },
  );
};

export const aktivasi = (id, payload) => (dispatch) => {
  return TryoutService.aktivasi(id, payload).then(
    (response) => {
      if(response.data.success === true) {
        console.log(response.data);
        return Promise.resolve(response.data);
      } else {
        console.log(response.data);

        return Promise.reject();
      }
    }
  )
}

export const deleteTryout = (id) => (dispatch) => {
  return TryoutService.deleteTryout(id).then(
    (response) => {
      if(response.data.success === true) {
        console.log(response.data);
        return Promise.resolve(response.data);
      } else {
        console.log(response.data);

        return Promise.reject();
      }
    },
  );
}
// END OF TRYOUT ACTIONS

// PROGRAM ACTIONS
export const getAllProgram = () => (dispatch) => {
  return TryoutService.getAllProgram().then(
    (response) => {
      if(response.data.success === true) {
        console.log(response.data);
        return Promise.resolve(response.data);
      } else {
        console.log(response.data);

        return Promise.reject();
      }
    }
  )
}
// END OF PROGRAM ACTIONS

// SUBTRYOUT ACTIONS
export const createSubtryout = (payload) => (dispatch) => {
  return TryoutService.createSubtryout(payload).then(
    (response) => {
      if(response.data.success === true) {
        console.log(response.data);
        return Promise.resolve(response.data);
      } else {
        console.log(response.data);

        return Promise.reject();
      }
    },
  );
};

export const deleteSubtryout = (id) => (dispatch) => {
  return TryoutService.deleteSubtryout(id).then(
    (response) => {
      if(response.data.success === true) {
        console.log(response.data);
        return Promise.resolve(response.data);
      } else {
        console.log(response.data);

        return Promise.reject();
      }
    },
  );
}

export const tambahSoal = (id) => (dispatch) => {
  return TryoutService.tambahSoal(id).then(
    (response) => {
      if(response.data.success === true) {
        console.log(response.data);
        return Promise.resolve(response.data);
      } else {
        console.log(response.data);

        return Promise.reject();
      }
    }
  )
}

export const uploadHasilLaboratorium = (id, payload) => (dispatch) => {
  return TryoutService.uploadHasilLaboratorium(id, payload).then(
    (response) => {
      if(response.data.success === true) {
        console.log(response.data);
        return Promise.resolve(response.data);
      } else {
        console.log(response.data);

        return Promise.reject();
      }
    }
  )
}

export const updateDuration = (id, payload) => (dispatch) => {
  return TryoutService.updateDuration(id, payload).then(
    (response) => {
      if(response.data.success === true) {
        console.log(response.data);
        return Promise.resolve(response.data);
      } else {
        console.log(response.data);

        return Promise.reject();
      }
    }
  )
}

export const getSubtryoutDetail = (id) => (dispatch) => {
  return TryoutService.getSubtryoutDetail(id).then(
    (response) => {
      if(response.data.success === true) {
        console.log(response.data);
        return Promise.resolve(response.data);
      } else {
        console.log(response.data);

        return Promise.reject();
      }
    },
  );
};

export const getSubtryoutByTO = (id) => (dispatch) => {
  return TryoutService.getSubtryoutByTO(id).then(
    (response) => {
      if(response.data.success === true) {
        console.log(response.data);
        return Promise.resolve(response.data);
      } else {
        console.log(response.data);

        return Promise.reject();
      }
    }
  )
}
// END SUBTRYOUT ACTIONS

// KOMPETENSI ACTIONS
export const getAllKompetensi = (params = '') => (dispatch) => {
  return TryoutService.getAllKompetensi(params).then(
    (response) => {
      if(response.data.success === true) {
        return Promise.resolve(response.data);
      } else {

        return Promise.reject();
      }
    }
  )
}

export const createKompetensi = (payload) => (dispatch) => {
  return TryoutService.createKompetensi(payload).then(
    (response) => {
      if(response.data.success === true) {

        return Promise.resolve(response.data);
      } else {

        return Promise.reject();
      }
    }
  )
}

export const updateKompetensi = (id, payload) => (dispatch) => {
  return TryoutService.updateKompetensi(id, payload).then(
    (response) => {
      if(response.data.success === true) {
        return Promise.resolve(response.data);
      } else {

        return Promise.reject();
      }
    }
  )
}

export const deleteKompetensi = (id) => (dispatch) => {
  return TryoutService.deleteKompetensi(id).then(
    (response) => {
      if(response.data.success === true) {
        return Promise.resolve(response.data);
      } else {

        return Promise.reject();
      }
    }
  )
}
// END KOMPETENSI ACTIONS

// BIDANG ACTIONS
export const getAllBidang = (params = '') => (dispatch) => {
  return TryoutService.getAllBidang(params).then(
    (response) => {
      if(response.data.success === true) {
        return Promise.resolve(response.data);
      } else {

        return Promise.reject();
      }
    }
  )
}

export const createBidang = (payload) => (dispatch) => {
  return TryoutService.createBidang(payload).then(
    (response) => {
      if(response.data.success === true) {
        return Promise.resolve(response.data);
      } else {

        return Promise.reject();
      }
    }
  )
}

export const updateBidang = (id, payload) => (dispatch) => {
  return TryoutService.updateBidang(id, payload).then(
    (response) => {
      if(response.data.success === true) {
        return Promise.resolve(response.data);
      } else {

        return Promise.reject();
      }
    }
  )
}

export const deleteBidang = (id) => (dispatch) => {
  return TryoutService.deleteBidang(id).then(
    (response) => {
      if(response.data.success === true) {
        return Promise.resolve(response.data);
      } else {

        return Promise.reject();
      }
    }
  )
}
// END BIDANG ACTIONS

// TOPIK ACTIONS
export const getAllTopik = (params = '') => (dispatch) => {
  return TryoutService.getAllTopik(params).then(
    (response) => {
      if(response.data.success === true) {
        return Promise.resolve(response.data);
      } else {

        return Promise.reject();
      }
    }
  )
}

export const createTopik = (payload) => (dispatch) => {
  return TryoutService.createTopik(payload).then(
    (response) => {
      if(response.data.success === true) {
        return Promise.resolve(response.data);
      } else {

        return Promise.reject();
      }
    }
  )
}

export const updateTopik = (id, payload) => (dispatch) => {
  return TryoutService.updateTopik(id, payload).then(
    (response) => {
      if(response.data.success === true) {
        return Promise.resolve(response.data);
      } else {

        return Promise.reject();
      }
    }
  )
}

export const deleteTopik = (id) => (dispatch) => {
  return TryoutService.deleteTopik(id).then(
    (response) => {
      if(response.data.success === true) {
        return Promise.resolve(response.data);
      } else {

        return Promise.reject();
      }
    }
  )
}
// END TOPIK ACTIONS

// BANKSOAL ACTIONS
export const getBankSoal = (id) => (dispatch) => {
  return TryoutService.getBankSoal(id).then(
    (response) => {
      if(response.data.success === true) {
        return Promise.resolve(response.data);
      } else {

        return Promise.reject(response);
      }
    }
  )
}

export const updateBankSoal = (id, payload) => (dispatch) => {
  return TryoutService.updateBankSoal(id, payload).then(
    (response) => {
      if(response.data.success === true) {
        return Promise.resolve(response.data);
      } else {

        return Promise.reject(response);
      }
    }
  )
}

export const createBankSoal = (id, payload) => (dispatch) => {
  return TryoutService.createBankSoal(id, payload).then(
    (response) => {
      if(response.data.success === true) {
        return Promise.resolve(response.data);
      } else {

        return Promise.reject(response);
      }
    }
  )
}

export const uploadGambarSoal = (payload) => (dispatch) => {
  return TryoutService.uploadGambarSoal(payload).then(
    (response) => {
      if(response.data.success === true) {
        return Promise.resolve(response.data);
      } else {

        return Promise.reject(response);
      }
    }
  )
}

export const uploadGambarPembahasan = (payload) => (dispatch) => {
  return TryoutService.uploadGambarPembahasan(payload).then(
    (response) => {
      if(response.data.success === true) {
        return Promise.resolve(response.data);
      } else {

        return Promise.reject(response);
      }
    }
  )
}

export const uploadGambarJawaban = (payload) => (dispatch) => {
  return TryoutService.uploadGambarJawaban(payload).then(
    (response) => {
      if(response.data.success === true) {
        return Promise.resolve(response.data);
      } else {

        return Promise.reject(response);
      }
    }
  )
}

export const deleteBankSoal = (id, payload) => (dispatch) => {
  return TryoutService.deleteBankSoal(id, payload).then(
    (response) => {
      if(response.data.success === true) {
        return Promise.resolve(response.data);
      } else {

        return Promise.reject();
      }
    }
  )
}
// END BANKSOAL ACTIONS

// USER TRYOUT ACTIONS
export const getUserTryout = (id) => (dispatch) => {
  return TryoutService.getUserTryout(id).then(
    (response) => {
      if(response.data.success === true) {
        return Promise.resolve(response.data);
      } else {

        return Promise.reject(response);
      }
    }
  )
}

export const getMyTryout = () => (dispatch) => {
  return TryoutService.getMyTryout().then(
    (response) => {
      if(response.data.success === true) {
        console.log(response.data);
        return Promise.resolve(response.data);
      } else {
        console.log(response.data);

        return Promise.reject();
      }
    }
  )
}

export const getMyTryoutDetail = (id) => (dispatch) => {
  return TryoutService.getMyTryoutDetail(id).then(
    (response) => {
      if(response.data.success === true) {
        console.log(response.data);
        return Promise.resolve(response.data);
      } else {
        console.log(response.data);

        return Promise.reject();
      }
    }
  )
}
// END USER TRYOUT ACTIONS

// BANKSOAL SUBTRYOUT ACTIONS
export const createBankSoalSubTO = (payload) => (dispatch) => {
  return TryoutService.createBankSoalSubTO(payload).then(
    (response) => {
      if(response.data.success === true) {
        return Promise.resolve(response.data);
      } else {

        return Promise.reject(response);
      }
    }
  )
}

export const deleteBankSoalSubTO  = (payload) => (dispatch) => {
  return TryoutService.deleteBankSoalSubTO(payload).then(
    (response) => {
      if(response.data.success === true) {
        return Promise.resolve(response.data);
      } else {

        return Promise.reject();
      }
    }
  )
}

export const importBank = (id, payload) => (dispatch) => {
  return TryoutService.importBank(id, payload).then(
    (response) => {
      if(response.data.success === true) {
        return Promise.resolve(response.data);
      } else {
        return Promise.reject();
      }
    }
  )
}
// END BANKSOAL SUBTRYOUT ACTIONS

// PENGERJAAN TRYOUT ACTIONS

export const getListSummary = () => (dispatch) => {
  return TryoutService.getListSummary().then(
    (response) => {
      if(response.data.success === true) {
        return Promise.resolve(response.data);
      } else {
        return Promise.reject();
      }
    }
  )
}

export const getParticipantCompletion = (id) => (dispatch) => {
  return TryoutService.getParticipantCompletion(id).then(
    (response) => {
      if(response.data.success === true) {
        return Promise.resolve(response.data);
      } else {
        return Promise.reject();
      }
    }
  )
}

export const getKerjakanDetail = (id, subtryoutId) => (dispatch) => {
  return TryoutService.getKerjakanDetail(id, subtryoutId).then(
    (response) => {
      if(response.data.success === true) {
        return Promise.resolve(response.data);
      } else {
        return Promise.reject();
      }
    }
  )
}

export const kerjakanSoal = (id, soal) => (dispatch) => {
  return TryoutService.kerjakanSoal(id, soal).then(
    (response) => {
      if(response.data.success === true) {
        return Promise.resolve(response.data);
      } else {
        return Promise.reject();
      }
    }
  )
}

export const submitAnswer = (payload) => (dispatch) => {
  return TryoutService.submitAnswer(payload).then(
    (response) => {
      if(response.data.success === true) {
        return Promise.resolve(response.data);
      } else {
        return Promise.reject();
      }
    }
  )
}

export const getHasilTryout = (id) => (dispatch) => {
  return TryoutService.getHasilTryout(id).then(
    (response) => {
      if(response.data.success === true) {
        return Promise.resolve(response.data);
      } else {
        return Promise.reject();
      }
    }
  )
}

export const getHasilTryoutAll = (id) => (dispatch) => {
  return TryoutService.getHasilTryoutAll(id).then(
    (response) => {
      if(response.data.success === true) {
        return Promise.resolve(response.data);
      } else {
        return Promise.reject();
      }
    }
  )
}

export const simpanJawaban = (nomor, jawaban) => (dispatch) => {
  let jawabanExist = JSON.parse(localStorage.getItem('jawaban'));
  let arr = [];
  let obj = {
    nomor,
    jawaban,
  }
  if(!jawabanExist) {
    arr.push(obj)
  } else {
    arr = jawabanExist;
    arr.push(obj);
  }
  console.log(jawabanExist)
  return localStorage.setItem(JSON.stringify(arr));
  // dispatch({
  //   type: SET_JAWABAN,
  //   payload: arr,
  // })
  // return Promise.resolve()
}

// END PENGERJAAN TRYOUT ACTIONS

// USER RANKING

export const getUserRankingTryout = (id) => (dispatch) => {
  return TryoutService.getUserRankingTryout(id).then(
    (response) => {
      if(response.data.success === true) {
        return Promise.resolve(response.data);
      } else {
        return Promise.reject();
      }
    }
  )
}

export const getUserRankingParticipant = (id) => (dispatch) => {
  return TryoutService.getUserRankingParticipant(id).then(
    (response) => {
      if(response.data.success === true) {
        return Promise.resolve(response.data);
      } else {
        return Promise.reject(response.data);
      }
    }
  )
}

// END USER RANKING