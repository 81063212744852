import Button from '@material-tailwind/react/Button';
import React, { useState } from 'react';
import { FormGroup } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import { Link } from 'react-router-dom';
import TutorSidebar from '../../../components/layout/dashboard/tutor/TutorSideBar';
import '../../../main.css'

export default function DataKelasTutor() {
    document.title = "INGENIO Indonesia";
    var baseUrl = window.location.origin;
    
    const [program, setProgram] = useState('');
    const dropdown = {
        borderRadius: "10px",
        height: 50,
        padding: "25px",
    };
    const columns = [
        {
            name: 'NO.',
            selector: row => row.no,
            sortable: true,
            width: '6.2vw',
        },
        {
            name: 'PROGRAM',
            selector: row => row.program,
            sortable: true,
            width: '18vw',
        },
        {
            name: 'REGIO',
            selector: row => row.regio,
            sortable: true,
            width: '10vw',
        },
        {
            name: 'KELAS',
            selector: row => row.kelas,
            sortable: true,
            width: '10vw',
        },
        {
            name: 'MATERI',
            selector: row => row.materi,
            sortable: true,
            width: '12vw',
        },
        {
            name: 'TANGGAL',
            selector: row => row.tanggal,
            sortable: true,
            width: '10vw',
        },
        {
            cell: () => <Link className="btnTable" to="data-kelas/kelas-a">View</Link>,
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
    ];
    const data = [
        {
            no: 1,
            program: 'Lorem ipsum dolor.',
            regio: 'Regio A',
            kelas: <Link className="kelas" to="data-kelas/kelas-a" style={{ textDecoration: 'underline' }}>Kelas A</Link>,
            materi: 'Materi A',
            tanggal: '20 Jan 2022',
        },
        {
            no: 2,
            program: 'Lorem ipsum dolor.',
            regio: 'Regio B',
            kelas: <Link className="kelas" to="data-kelas/kelas-a" style={{ textDecoration: 'underline' }}>Kelas B</Link>,
            materi: 'Materi A',
            tanggal: '20 Jan 2022',
        },
        {
            no: 3,
            program: 'Lorem ipsum dolor.',
            regio: 'Regio C',
            kelas: <Link className="kelas" to="data-kelas/kelas-a" style={{ textDecoration: 'underline' }}>Kelas C</Link>,
            materi: 'Materi A',
            tanggal: '20 Jan 2022',
        },
        {
            no: 4,
            program: 'Lorem ipsum dolor.',
            regio: 'Regio D',
            kelas: <Link className="kelas" to="data-kelas/kelas-a" style={{ textDecoration: 'underline' }}>Kelas D</Link>,
            materi: 'Materi A',
            tanggal: '20 Jan 2022',
        },
        {
            no: 5,
            program: 'Lorem ipsum dolor.',
            regio: 'Regio E',
            kelas: <Link className="kelas" to="data-kelas/kelas-a" style={{ textDecoration: 'underline' }}>Kelas A</Link>,
            materi: 'Materi A',
            tanggal: '20 Jan 2022',
        },
    ];

    const customStyles = {
        table: {
          style: {
            borderRadius: '15px',
            border: '1px solid #BDC1C9',
          },
        },
        head: {
          style: {
            fontSize: '1.25rem',
            textTransform: "uppercase",
            color: '#6B7280',
            backgroundColor: '#f3f4f6',
            border: '1px solid #BDC1C9',
            borderTopLeftRadius: '15px',
            borderTopRightRadius: '15px',
          },
        },
        pagination: {
          style: {
            color: '#6B7280',
            fontSize: '1.1rem',
            minHeight: '56px',
            backgroundColor: '#f3f4f6',
            border: '1.25px solid #BDC1C9',
            borderBottomLeftRadius: '15px',
            borderBottomRightRadius: '15px',
          },
        },
    };

    return (
        <React.Fragment>
            <TutorSidebar />
            <div className="md:ml-64 tutorDataKelas" style={{ backgroundColor: '#F3F4F6' }}>
                <div className="bg-orange md:px-8 py-5" style={{height: '250px', position: 'relative', padding: '30px'}}>
                    <h1 className="tracking-wider title-24-600">Data Kelas</h1>
                    <h2 className="tracking-wider title-20-700 mt-5">Selamat Datang Tutor INGENIO !</h2>
                    <img src={baseUrl + "/assets/logo/evaluasikelas.png"} className="img-opacity"></img>
                </div>

                <div className="px-3 md:px-8 mt-4">
                    <div className="container mx-auto max-w-full">
                        <div className="row">
                            <div className="col-12 my-4">
                                <FormGroup>
                                    <div className="SearchTable">
                                        <input type="text" name="" className="inputSearchTable" placeholder="&#x1F50E;&#xFE0E; Search..." id="" />
                                        <Button className="btnSearchTable">Cari</Button>
                                    </div>
                                </FormGroup>
                            </div>
                        </div>
                        <div className="col-12 mt-3 mb-5">
                            <DataTable columns={columns} customStyles={customStyles} data={data} pagination />
                        </div>
                    </div>
                </div>

            </div>
        </React.Fragment >

    )
}