import React from 'react';
// import styles from '../../index.module.css';
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from 'swiper';

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import 'swiper/components/scrollbar/scrollbar.scss';
import './slider.css'

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y ]);

export default function HeroCarousel({props}) {
  return (
    <React.Fragment>
        <Swiper
          id="main"
          spaceBetween={150}
          slidesPerView={1}
          pagination={{ clickable: true }}
          scrollbar={{ draggable: true }}
          onSwiper={(swiper) => console.log(swiper)}
          onSlideChange={() => console.log('slide change')}
        >
          <SwiperSlide>
            <div className="col-12 hero-slider">
              <img src="/assets/landing/banner/new-banner-1.png" alt="UKMPPD" width="100%"/>
              <button className="button-slider-daftar" onClick={(e) => {e.preventDefault();window.location.href='/signup';}}>Daftar Sekarang</button>
              <button className="button-slider-pelajari" onClick={(e) => {e.preventDefault();window.location.href='/Program-Ukmppd';}}>Pelajari Lebih Lanjut</button>
              <div className="hero-carousel-icon">
                <img src="/assets/landing/hero-carousel-icon.png" alt="INGENIO INDONESIA" className="img-responsive" style={{maxWidth: '180px'}}></img>
              </div>
              {/* <div className="hero-carousel-best">
                <img src="/assets/landing/hero-carousel-best.png" alt="INGENIO INDONESIA" className="img-responsive" style={{maxWidth: '180px'}}></img>
              </div> */}
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="col-12 hero-slider">
              <img src="/assets/landing/banner/new-banner-2.png" alt="UKMPPD" width="100%"/>
              <button className="button-slider-daftar" onClick={(e) => {e.preventDefault();window.location.href='/signup';}}>Daftar Sekarang</button>
              <button className="button-slider-pelajari" onClick={(e) => {e.preventDefault();window.location.href='/Program-Ukmppd';}}>Pelajari Lebih Lanjut</button>
              <div className="hero-carousel-icon">
                <img src="/assets/landing/hero-carousel-icon.png" alt="INGENIO INDONESIA" className="img-responsive" style={{maxWidth: '180px'}}></img>
              </div>
              {/* <div className="hero-carousel-best">
                <img src="/assets/landing/hero-carousel-best.png" alt="INGENIO INDONESIA" className="img-responsive" style={{maxWidth: '180px'}}></img>
              </div> */}
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="col-12 hero-slider">
              <img src="/assets/landing/banner/new-banner-7.png" alt="UKMPPD" width="100%"/>
              <button className="button-slider-daftar" onClick={(e) => {e.preventDefault();window.location.href='/signup';}}>Daftar Sekarang</button>
              <button className="button-slider-pelajari" onClick={(e) => {e.preventDefault();window.location.href='/Program-Ukmppd';}}>Pelajari Lebih Lanjut</button>
              <div className="hero-carousel-icon">
                <img src="/assets/landing/hero-carousel-icon.png" alt="INGENIO INDONESIA" className="img-responsive" style={{maxWidth: '180px'}}></img>
              </div>
              {/* <div className="hero-carousel-best">
                <img src="/assets/landing/hero-carousel-best.png" alt="INGENIO INDONESIA" className="img-responsive" style={{maxWidth: '180px'}}></img>
              </div> */}
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="col-12 hero-slider">
              <img src="/assets/landing/banner/new-banner-8.png" alt="UKMPPD" width="100%"/>
              <button className="button-slider-daftar" onClick={(e) => {e.preventDefault();window.location.href='/signup';}}>Daftar Sekarang</button>
              <button className="button-slider-pelajari" onClick={(e) => {e.preventDefault();window.location.href='/Program-Ukmppd';}}>Pelajari Lebih Lanjut</button>
              <div className="hero-carousel-icon">
                <img src="/assets/landing/hero-carousel-icon.png" alt="INGENIO INDONESIA" className="img-responsive" style={{maxWidth: '180px'}}></img>
              </div>
              {/* <div className="hero-carousel-best">
                <img src="/assets/landing/hero-carousel-best.png" alt="INGENIO INDONESIA" className="img-responsive" style={{maxWidth: '180px'}}></img>
              </div> */}
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="col-12 hero-slider">
              <img src="/assets/landing/banner/new-banner-9.png" alt="UKMPPD" width="100%"/>
              <button className="button-slider-daftar" onClick={(e) => {e.preventDefault();window.location.href='/signup';}}>Daftar Sekarang</button>
              <button className="button-slider-pelajari" onClick={(e) => {e.preventDefault();window.location.href='/Program-Ukmppd';}}>Pelajari Lebih Lanjut</button>
              <div className="hero-carousel-icon">
                <img src="/assets/landing/hero-carousel-icon.png" alt="INGENIO INDONESIA" className="img-responsive" style={{maxWidth: '180px'}}></img>
              </div>
              {/* <div className="hero-carousel-best">
                <img src="/assets/landing/hero-carousel-best.png" alt="INGENIO INDONESIA" className="img-responsive" style={{maxWidth: '180px'}}></img>
              </div> */}
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="col-12 hero-slider">
              <img src="/assets/landing/banner/new-banner-4.png" alt="UKMPPD" width="100%"/>
              <button className="button-slider-daftar" onClick={(e) => {e.preventDefault();window.location.href='/signup';}}>Daftar Sekarang</button>
              <button className="button-slider-pelajari" onClick={(e) => {e.preventDefault();window.location.href='/Program-Ukmppd';}}>Pelajari Lebih Lanjut</button>
              <div className="hero-carousel-icon">
                <img src="/assets/landing/hero-carousel-icon.png" alt="INGENIO INDONESIA" className="img-responsive" style={{maxWidth: '180px'}}></img>
              </div>
              {/* <div className="hero-carousel-best">
                <img src="/assets/landing/hero-carousel-best.png" alt="INGENIO INDONESIA" className="img-responsive" style={{maxWidth: '180px'}}></img>
              </div> */}
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="col-12 hero-slider">
              <img src="/assets/landing/banner/new-banner-5.png" alt="UKMPPD" width="100%"/>
              <button className="button-slider-daftar" onClick={(e) => {e.preventDefault();window.location.href='/signup';}}>Daftar Sekarang</button>
              <button className="button-slider-pelajari" onClick={(e) => {e.preventDefault();window.location.href='/Program-Ukmppd';}}>Pelajari Lebih Lanjut</button>
              <div className="hero-carousel-icon">
                <img src="/assets/landing/hero-carousel-icon.png" alt="INGENIO INDONESIA" className="img-responsive" style={{maxWidth: '180px'}}></img>
              </div>
              {/* <div className="hero-carousel-best">
                <img src="/assets/landing/hero-carousel-best.png" alt="INGENIO INDONESIA" className="img-responsive" style={{maxWidth: '180px'}}></img>
              </div> */}
            </div>
          </SwiperSlide>
        </Swiper>
    </React.Fragment>
  )
}