import React from 'react';
import {
    LoginTutor,
} from '../components/logintutor';
import {
    IngenioNav,
    IngenioFooter
} from '../components/layout'

export default function LoginTutorPage() {
    document.title = "INGENIO Indonesia | Login";
    return (
        <React.Fragment>
            <IngenioNav />
            <div style={{ backgroundColor: 'white' }}>
                <LoginTutor />
            </div>
            <IngenioFooter bgColor="transparent" />
        </React.Fragment>

    )
}