import React from 'react'

export default function DaftarButton(props) {
  return(
    <React.Fragment>
      <div class="row align-items-center g-4 my-4" style={{marginLeft: 'auto', marginRight: 'auto'}}>
        <div class="col-12 col-md-4 justify-content-center">
            <div class="form-group text-center">
                <a class="bt-l" href="/signup">
                    <span class="bt-link-l">Daftar E-Course</span>
                </a>
            </div>
        </div>

        <div class="col-12 col-md-4 justify-content-center">
            <div class="form-group text-center">
                <a class="bt-m" href="/signup">
                  <span class="bt-link">Daftar E-Learning</span>
                </a>
            </div>
        </div>

        <div class="col-12 col-md-4 justify-content-center">
            <div class="form-group text-center">
                <a class="bt-r" href="/signup">
                  <span class="bt-link-r">Daftar Try Out</span>
                </a>
            </div>
        </div>
    </div>
    </React.Fragment>
  )
}