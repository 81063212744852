import React, { useState, useEffect } from 'react';
import {
  SideBar
} from '../../../components/layout/dashboard';
import { useParams, useHistory } from "react-router-dom";
import { Link, useLocation} from 'react-router-dom';
import { BsArrowLeft } from 'react-icons/bs';
import Button from '@restart/ui/esm/Button';

import { connect } from "react-redux";
import { getTryoutDetail } from "../../../store/actions/tryout";
import { addCommas } from '../../../helpers/utils'
import axios from 'axios';

function Payment (props) {
  document.title = "INGENIO Indonesia";
  const [ programs, setPrograms ] = useState([]);
  const [dataTryout, setDataTryout] = useState({})
  const [dataSubTryout, setDataSubTryout] = useState([]);
  const [total, setTotal] = useState(0); 
  const [isLoading, setIsLoading] = useState(false)

  const { dispatch } = props;

  function useQuery() {
    const { search } = useLocation();
  
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }

  const history = useHistory();
  const query = useQuery()

  // const {id} = location.state;

  useEffect(() => {
    console.log(query.get("id"))
    dispatch(getTryoutDetail(query.get("id")))
    .then((response) =>{
        const { subtryout, program, ...tryout } = response.data
        setDataSubTryout(subtryout)
        setPrograms(program)
        setDataTryout({...tryout})
    });
  }, []);

  const orderTryout = () => {
    setIsLoading(true)
    const tryoutId = query.get("id");
    axios.post(`${process.env.REACT_APP_TRYOUTURL}tryout/participant/order-tryout`, {
      id_tryout: tryoutId
    }, {
      headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem('token'))}`
      }
    }).then(resp => {
      window.location.replace(`/dashboard/payment-process?transaction_id=${resp.data.data.id}`)
    }).catch(err => {
      setIsLoading(false)
      console.error(err)
    })
  }

  return(
    <React.Fragment>
      <SideBar />
      <div className="md:ml-64">
        {/* <div className="bg-orange px-3 md:px-8 py-1 h-40">
          <div className="container md:pr-8 md:pl-10">
            <h3 className="text-left tracking-wider title-dashboard">Selamat Datang, Peserta TryOut INGENIO Indonesia</h3>
          </div>           
        </div>

        <div className="px-3 md:px-8 -mt-24">
            <div className="container mx-auto max-w-full">
                <div className="grid grid-cols-2 xl:grid-cols-5">
                    <div className="xl:col-start-1 xl:col-end-4 px-4 mb-14">
                      <a href="/dashboard/tryout" className="rounded-full btn-tryout">Mulai TryOut</a>
                      <a href="/dashboard/tata-cara" className="rounded-full btn-tryout">Tata Cara TryOut</a>
                      <a href="/dashboard/hasil-tryout" className="rounded-full btn-tryout">Hasil TryOut</a>
                    </div>
                </div>
            </div>
        </div> */}

          <div className="container md:pr-8 md:pl-10"  style={{position: 'absolute', top: '80px'}}>
              <h3 className="text-left tracking-wider title-dashboard">Selamat Datang, Peserta TryOut INGENIO Indonesia</h3>
              <div className="grid grid-cols-2 xl:grid-cols-5" style={{marginTop: '21px'}}>
                  <div className="xl:col-start-1 xl:col-end-4 px-4 mb-14">
                  <a href="/dashboard/tryout" className="rounded-full btn-tryout">Mulai TryOut</a>
                  <a href="/dashboard/tata-cara" className="rounded-full btn-tryout">Tata Cara TryOut</a>
                  <a href="/dashboard/hasil-tryout" className="rounded-full btn-tryout">Hasil TryOut</a>
                  </div>
              </div>
          </div> 

        <div className="px-3 md:px-8">
            <div className="container mx-auto max-w-full">
                <Link className="flex items-center breadcrumbss py-4" to="/dashboard/tryout-detail"> <BsArrowLeft size="25px"/> Kembali</Link>
                <div className="card-gede">
                    <div className="card-head px-4 py-4" >
                        Pembayaran Manual
                    </div>
                    <div className="row">
                        <div className="col-lg-6 col-md-6 col-xs-12 py-4 px-5">
                            <p style={{fontSize:"18px !important", lineHeight: "22px", letterSpacing: "0.06em", color: "#000000", fontWeight: 'bold'}}>Detail</p> <br />
                            <table style={{marginBottom: '15px'}}>
                                <tr>
                                    <td style={{padding: '0px 10px 0px 0px'}}>Nama Paket Tryout</td>
                                    <td style={{padding: '0px 10px 0px 0px'}}>:</td>
                                    <td style={{padding: '0px'}}><b>{dataTryout.nama_tryout}</b></td>
                                </tr>
                                <tr>
                                    <td style={{padding: '0px'}}>Total Tryout</td>
                                    <td style={{padding: '0px'}}>:</td>
                                    <td style={{padding: '0px'}}><b>{dataSubTryout.length}</b></td>
                                </tr>
                                <tr>
                                    <td style={{padding: '0px'}}>Total jumlah soal</td>
                                    <td style={{padding: '0px'}}>:</td>
                                    <td style={{padding: '0px'}}><b>{dataSubTryout.length > 0 ? dataSubTryout.map(o => o.jumlah_soal).reduce((a, c) => { return a + c }) : 0}</b></td>
                                </tr>
                                <tr>
                                    <td style={{padding: '0px'}}>Total Harga</td>
                                    <td style={{padding: '0px'}}>:</td>
                                    <td style={{padding: '0px'}}><b>{addCommas(dataTryout.harga)}</b></td>
                                </tr>
                            </table>
                            {/* <p className="text-black mb-3">Nama Paket Tryout : <b>{dataTryout.nama_tryout}</b></p>                     
                            <p className="text-black mb-3">Total Tryout : <b>{dataSubTryout.length}</b></p>
                            <p className="text-black mb-4">Total Jumlah Soal : <b>{dataSubTryout.length > 0 ? dataSubTryout.map(o => o.jumlah_soal).reduce((a, c) => { return a + c }) : 0}</b></p>
                            <p className="text-black mb-4">Total Harga : <b>Rp. {addCommas(dataTryout.harga)}</b></p> */}
                        </div>
                        <div className="col-lg-6 col-md-6 col-xs-12 py-4 px-5">
                            <p style={{fontSize:"18px !important", lineHeight: "22px", letterSpacing: "0.06em", color: "#000000", fontWeight: 'bold'}}>Metode Pembayaran ke Bank :</p> <br />
                            <label class="plan basic-plan" for="basic">
                            <input type="radio" name="plan" id="basic" />
                            <div class="plan-content">                              
                              <div class="plan-details">
                                <img loading="lazy" src="/assets/landing/Mandiri_logo 1.png" alt="" />
                                <p className="mt-2">Mandiri Virtual Account</p>
                              </div>
                            </div>
                          </label>
                        </div>
                        <div className="col-lg-6 col-md-6 col-xs-12 px-5">
                          {
                            isLoading ?
                            <Button disabled={true} className="pilih-tryout-disable text-black my-3" onClick={() => {}}>Processing...</Button>
                            :
                            <Button className="pilih-tryout text-black my-3" onClick={() => orderTryout()}>Lanjutkan</Button>
                          }
                          
                          
                          <br /><br />
                          <Button onClick={() => {
                            window.open('https://ingenioindonesia.com/public/assets/panduan_pembayaran_va_ingenio.pdf', '_blank')
                          }} className="panduan my-3">Panduan Pembayaran</Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>      

      </div>
    </React.Fragment>
    
  )
}

export default connect()(Payment);