import React from 'react';
import { ArticleSlider } from './index';

export default function Article (props) {
  return (
    <React.Fragment>
      <div className="container px-4 py-2">
        <div className="row align-items-center g-2 py-1">
          <h1 className="text-center jd-home2">Artikel</h1>
        </div>
      </div>
      <div className="container px-4 py-4">
        <div className="row g-3 mb-5">
          <ArticleSlider />
        </div>
        <div className="row justify-content-center align-items-center py-4">
          <a className="bt-m text-center" href="/artikel" style={{width: '300px', background: 'linear-gradient(248.1deg, #F8CA2B 27.1%, #FCA826 73.3%)', boxShadow: '0px 2px 10px #F8CA2B',borderRadius: '50px'}}>
              <span className="bt-link" style={{fontSize: '1.5rem'}} >Lihat Artikel Lainnya</span>
          </a>
        </div>
      </div>
    </React.Fragment>
  )
}

