import React, { useEffect, useState } from 'react';
import {
  SideBar
} from '../../../components/layout/dashboard';
import { Image } from 'react-bootstrap';
import { Link, useLocation} from 'react-router-dom';
import axios from 'axios';
import { addCommas } from '../../../helpers/utils'

export default function StatusBayar () {
  document.title = "INGENIO Indonesia";
  const [ program, setProgram ] = useState('');
  const [transaction, setTransaction] = useState(null)

  function useQuery() {
    const { search } = useLocation();
  
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }
  const query = useQuery()

  useEffect(() => {
    const transactionId = query.get("transaction_id");
    axios.get(`${process.env.REACT_APP_TRYOUTURL}transaction/participant/${transactionId}`, {
      headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem('token'))}`
      }
    }).then(resp => {
      setTransaction(resp.data.data)
    }).catch(err => {
      console.error(err)
    })
  }, [])

  return(
    <React.Fragment>
      <SideBar />
      <div className="md:ml-64">

        <div className="px-3 md:px-8">
            <div className="container mx-auto max-w-full">
                <div className="card-gede card-bayar my-3 mx-auto text-center">
                    <Image src="/assets/landing/rotate-left.png" className="mx-auto mt-3" style={{width: '10%'}}></Image>
                    <p style={{fontSize:"14px", lineHeight: "22px", letterSpacing: "0.06em", color: "#F8CA2B", fontWeight: '500'}} className="mt-2">Pembayaran Anda sedang diproses</p> <br />
                    <p style={{fontSize: "36px", lineHeight: "45px", letterSpacing: "0.06em", color: "#000000", fontWeight: 'bold'}}>{(transaction && transaction !== null) ? `IDR ${addCommas(transaction.grand_total)}` : ''} </p> <br />

                    <table className="" style={{width: '100%'}}>
                        <tr>
                            <td style={{width: '50%', textAlign: 'left'}}><p>Nama Kelas E-Learning</p></td>
                            <td style={{width: '50%', textAlign: 'right'}}><b>Nama_Kelas</b></td>
                        </tr>
                        <tr>
                            <td style={{width: '50%', textAlign: 'left'}}><p>Program Bimbingan</p></td>
                            <td style={{width: '50%', textAlign: 'right'}}><b>Program</b></td>
                        </tr>
                        <tr>
                            <td style={{width: '50%', textAlign: 'left'}}><p>Regio</p></td>
                            <td style={{width: '50%', textAlign: 'right'}}><b>Regio</b></td>
                        </tr>
                        <tr>
                            <td style={{width: '50%', textAlign: 'left'}}><p>Biaya Admin</p></td>
                            <td style={{width: '50%', textAlign: 'right'}}><b>IDR 2.500</b></td>
                        </tr>
                        <tr>
                            <td style={{width: '50%', textAlign: 'left'}}><p>Total Paket Tryout</p></td>
                            <td style={{width: '50%', textAlign: 'right'}}><b>2</b></td>
                        </tr>
                        <tr>
                            <td style={{width: '50%', textAlign: 'left'}}><p>Keterangan Kelas</p></td>
                            <td style={{width: '50%', textAlign: 'right'}}><b>Keterangan_Kelas</b></td>
                        </tr>
                        <tr>
                            <td style={{width: '50%', textAlign: 'left'}}><p>No. Transaksi</p></td>
                            <td style={{width: '50%', textAlign: 'right'}}><b>AAA-BBB-CCC-EEE</b></td>
                        </tr>
                        <tr>
                            <td style={{width: '50%', textAlign: 'left'}}><p>Kode Pembayaran </p></td>
                            <td style={{width: '50%', textAlign: 'right'}}><b>123456789</b></td>
                        </tr>
                    </table>

                    <p className="py-5" style={{fontSize: "14px", lineHeight: "17px", letterSpacing: "0.06em", color: "#000000", fontWeight: '500', textAlign: "center"}}>Segera selesaikan pembayaran Anda selama <strong>24 jam</strong></p>

                    <Link className="pilih-tryout text-black mb-4" to="/dashboard/payment-process">Keluar</Link>

                    <p className="pt-5 pb-2" style={{fontSize: "14px", lineHeight: "17px", letterSpacing: "0.06em", color: "#000000", fontWeight: '500', textAlign: "center"}}>Mengalami kesulitan/masalah? <strong>Hubungi CS Kami Disini</strong></p>
                </div>
            </div>
        </div>      

      </div>
    </React.Fragment>
    
  )
}