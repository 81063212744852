import React from 'react'
import styles from '../../index.module.css';
import { Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";


export default function FasilitasCard(props) {
    let baseURL = window.location.origin;

    return (
        <React.Fragment>
            <Card className="program-card-container-new">
                <Card.Body className="content-box">
                    <div className="row p-2">
                        <div className="col-12 col-md-3 mb-4">
                            <img src={props.data.img} className={"nurse-bg-" + props.data.colorName + ' mx-auto'} alt="nurse"></img>
                        </div>
                        <div className="col-12 col-md-9">
                            <h5 className="text-center text-md-start" style={{fontFamily: 'Quicksand', fontWeight: '500', fontSize: '16px', lineHeight: '32px'}}>{props.data.text}</h5>
                        </div>
                    </div>
                </Card.Body>
            </Card>
        </React.Fragment>
    )
}