import {
    SET_JAWABAN
} from "../actions/types";

const jawaban = JSON.parse(localStorage.getItem("jawaban"));

const initialState = jawaban ? {
    jawaban: jawaban,
} : {
    jawaban: null
};

function tryoutReducer(state = initialState, action) {

    const { type, payload } = action;

    switch (type) {
        case SET_JAWABAN:
            return {
                ...state,
                jawaban: payload
            }
        default:
            return state;
    }
}

export default tryoutReducer