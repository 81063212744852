import axios from "axios";

const API_URL = `${process.env.REACT_APP_LANDINGURL}`


axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('token');
class LandingService {
    uploadFile(body) {
        return axios.post(API_URL + `pengumuman/admin/upload`, body, {
            headers: {
                "Authorization": "Bearer " + JSON.parse(localStorage.getItem("token")),
                'Content-Type': 'multipart/form-data'
            }
        })
    }
    createPengumuman(body) {
        return axios.post(API_URL + `pengumuman/admin/create`, body, {
            headers: {
                "Authorization": "Bearer " + JSON.parse(localStorage.getItem("token")),
                
            }
        })
    }
    getPengumuman() {
        return axios.get(API_URL + `pengumuman/get`, {
            headers: {
                "Authorization": "Bearer " + JSON.parse(localStorage.getItem("token")),
                
            }
        })
    }
}

export default new LandingService();