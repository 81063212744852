import React from 'react';
// import styles from '../../index.module.css';
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from 'swiper';

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import 'swiper/components/scrollbar/scrollbar.scss';

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y ]);

const achievements = [
  {
    id: 1,
    alt: "prestasi 1",
    image_url: "/assets/landing/new-prestasi-1.png"
  },
  {
    id: 2,
    alt: "prestasi 2",
    image_url: "/assets/landing/new-prestasi-2.png"
  },
  {
    id: 3,
    alt: "prestasi 3",
    image_url: "/assets/landing/new-prestasi-3.png"
  },
  {
    id: 4,
    alt: "prestasi 4",
    image_url: "/assets/landing/new-prestasi-4.png"
  },
  {
    id: 5,
    alt: "prestasi 5",
    image_url: "/assets/landing/new-prestasi-5.jpg"
  },
  {
    id: 6,
    alt: "prestasi 6",
    image_url: "/assets/landing/new-prestasi-6.jpg"
  },
  {
    id: 7,
    alt: "prestasi 7",
    image_url: "/assets/landing/new-prestasi-7.jpg"
  },
  {
    id: 8,
    alt: "prestasi 8",
    image_url: "/assets/landing/new-prestasi-8.jpg"
  },
  {
    id: 9,
    alt: "prestasi 9",
    image_url: "/assets/landing/new-prestasi-9.jpg"
  },
  {
    id: 10,
    alt: "prestasi 10",
    image_url: "/assets/landing/new-prestasi-10.jpg"
  },
  {
    id: 11,
    alt: "prestasi 11",
    image_url: "/assets/landing/new-prestasi-11.jpg"
  },
  {
    id: 12,
    alt: "prestasi 12",
    image_url: "/assets/landing/new-prestasi-12.jpg"
  },
]

export default function PrestasiSlider(props) {
  const prevRef = React.useRef(null);
  const nextRef = React.useRef(null);
  return (
    <Swiper
      id="main"
      spaceBetween={200}
      slidesPerView={1}
      navigation
      scrollbar={{ draggable: true }}
      onSwiper={(swiper) => {
        // Delay execution for the refs to be defined
        setTimeout(() => {
          // Override prevEl & nextEl now that refs are defined
          swiper.params.navigation.prevEl = prevRef.current
          swiper.params.navigation.nextEl = nextRef.current

          // Re-init navigation
          swiper.navigation.destroy()
          swiper.navigation.init()
          swiper.navigation.update()
        })
      }}
      onSlideChange={() => console.log('slide change')}
    >
      {
        achievements.map((item, i) => {
          return (
            <SwiperSlide>
              <img src={item.image_url} alt={item.alt} style={{borderRadius: '15px', maxWidth: '90%'}} className="mx-auto"/>
            </SwiperSlide>
          )
        })
      }
      <div ref={prevRef} />
      <div ref={nextRef} />
    </Swiper>
  )
}