import React, { useState, useEffect } from 'react';
import './styles/style.css'

function CustomSwitchToggle(props) {
    const { 
        checked, 
        onChange
    } = props
    return(
        <label class="switch">
            <input onChange={onChange} type="checkbox"  checked={checked}/>
            <span class="slider round"></span>
        </label>
    )
}

export default CustomSwitchToggle