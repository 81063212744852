import React from 'react';
import {
    ProgramBimbinganCoas,
} from '../components/programbimbingancoas';
import {
    IngenioFooterPink,
    IngenioNavPink
} from '../components/layout'

export default function ProgramBimbinganCoasPage() {
    return (
        <React.Fragment>
            <IngenioNavPink />
            <div style={{ backgroundColor: 'white' }}>
                <ProgramBimbinganCoas />
            </div>
            <IngenioFooterPink />
        </React.Fragment>

    )
}