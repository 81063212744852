import React from 'react';
import { useState } from 'react';
import AdminSideBar from '../../../../components/layout/dashboard/admin/AdminSideBar';


export default function ProfileTryOutDetail () {
  document.title = "INGENIO Indonesia";
  var baseUrl = window.location.origin;
  document.body.style.backgroundColor = "#f3f4f6";


  return(
    <React.Fragment>
      <AdminSideBar />
        <div className="md:ml-64">
          <div className="bg-orange px-3 md:px-8 py-1" style={{ height: '2.5rem' }}>
          </div>
          <div className="py-4 px-3 md:px-8">
              <div className="container mx-auto max-w-full">
                  <div className="row">
                      <div className="col-lg-11 mx-auto">
                          <div className="cards">
                              <div className="row">
                                  <div className="col-12 flex gap-4" style={{padding: '2.4rem 4.3rem 0 4.3rem'}}>
                                      <img src={baseUrl + "/assets/img/jurnal-1.png"} className="imgDetail" />
                                      <div className="title-24-700 mt-3">Profile Peserta Try Out</div>
                                  </div>
                                  <div className="col-12 mb-4" style={{padding: '0 4.3rem'}}>
                                      <div className="borderBottomGrey"></div>
                                  </div>
                                  <div className="col-12">
                                    <div className="row" style={{ padding: '1.2rem 3.7rem'}}>
                                        <div className="col-lg-3 mb-4 relative">
                                            <div className="stickyTopPosition">
                                                <img src={baseUrl + "/assets/img/profile-image-default.png"} className="object-center mx-auto"></img>
                                            </div>
                                        </div>
                                        <div className="col-lg-9">
                                          <div className="row mb-4">
                                            <div className="col-md-6 lh-detail" style={{paddingLeft: '30px'}}>
                                              <h4>Nama Peserta</h4>
                                            </div>
                                            <div className="col-md-6 lh-detail">
                                              <h4 className="font-bold">nama_peserta</h4>
                                            </div>
                                          </div>
                                          <div className="row mb-4">
                                            <div className="col-md-6 lh-detail" style={{paddingLeft: '30px'}}>
                                              <h4>Kode Registrasi</h4>
                                            </div>
                                            <div className="col-md-6 lh-detail">
                                              <h4 className="font-bold">112233455</h4>
                                            </div>
                                          </div>
                                          <div className="row mb-4">
                                            <div className="col-md-6 lh-detail" style={{paddingLeft: '30px'}}>
                                              <h4>Total Nilai</h4>
                                            </div>
                                            <div className="col-md-6 lh-detail">
                                              <h4 className="font-bold">80</h4>
                                            </div>
                                          </div>
                                          <div className="row mb-4">
                                            <div className="col-md-6 lh-detail" style={{paddingLeft: '30px'}}>
                                              <h4>Rangking</h4>
                                            </div>
                                            <div className="col-md-6 lh-detail">
                                              <h4 className="font-bold">7</h4>
                                            </div>
                                          </div>
                                        </div>
                                    </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
        </div>
    </React.Fragment>
    
  )
}