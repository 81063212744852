import React, { useState } from 'react';
import {
  SideBar
} from '../../../components/layout/dashboard';
import { FormGroup, FormControl, Form } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import Button from '@material-tailwind/react/Button';
import Card from '@material-tailwind/react/Card';
import CardBody from '@material-tailwind/react/CardBody';
import Image from "@material-tailwind/react/Image";
import { contactLink } from '../../../helpers/utils'

export default function TataCara () {
  document.title = "INGENIO Indonesia | Tata Cara TryOut";
  document.body.style.backgroundColor = "#F3F4F6";
  const [ program, setProgram ] = useState('');

  return(
    <React.Fragment>
      <SideBar judul={"Tata Cara TryOut"}/>
      <div className="md:ml-64">
        {/* <div className="bg-orange px-3 md:px-8 py-1" style={{ height: '2.5rem' }}>
          <div className="container md:pr-8 md:pl-10">
          </div>
        </div> */}

        <div className="px-3 md:px-8" style={{marginTop: '-3rem'}}>
            <div className="container mx-auto max-w-full">
                <div className="row">
                    <div className="col-lg-4 col-md-4 col-xs-12 mb-4 mx-auto">
                        <Card className="tata-cara mx-auto">
                            <CardBody>
                                <div className="nomor-card mx-auto mb-4">
                                    1
                                </div>
                                <div className="txt-card mx-auto mb-3">
                                Lihat waktu tryout yang
                                ditentukan, sekali tryout berjalan
                                maka tidak dapat dihentikan.
                                </div>
                                <div>
                                <Image
                                    src="/assets/tata-cara/1.png"
                                    rounded={false}
                                    raised={false}
                                    alt="Image"
                                    className="mx-auto"
                                    style={{width: '150px'}}
                                />
                                </div>
                            </CardBody>
                        </Card>
                    </div>
                    <div className="col-lg-4 col-md-4 col-xs-12 mb-4 mx-auto">
                        <Card className="tata-cara mx-auto">
                            <CardBody>
                                <div className="nomor-card mx-auto mb-4">
                                    2
                                </div>
                                <div className="txt-card mx-auto mb-3">
                                Peserta mengakses tryout
                                dengan menggunakan
                                perangkat laptop/PC/
                                smartphone.
                                </div>
                                <div>
                                <Image
                                    src="/assets/tata-cara/2.png"
                                    rounded={false}
                                    raised={false}
                                    alt="Image"
                                    className="mx-auto"
                                    style={{width: '150px'}}
                                />
                                </div>
                            </CardBody>
                        </Card>
                    </div>
                    <div className="col-lg-4 col-md-4 col-xs-12 mb-4 mx-auto">
                        <Card className="tata-cara mx-auto">
                            <CardBody>
                                <div className="nomor-card mx-auto mb-4">
                                    3
                                </div>
                                <div className="txt-card mx-auto mb-5">
                                    Pastikan jaringan internet
                                    Anda stabil.
                                </div>
                                <div>
                                <Image
                                    src="/assets/tata-cara/3.png"
                                    rounded={false}
                                    raised={false}
                                    alt="Image"
                                    className="mx-auto"
                                    style={{width: '150px'}}
                                />
                                </div>
                            </CardBody>
                        </Card>
                    </div>
                    <div className="col-lg-4 col-md-4 col-xs-12 mb-4 mx-auto">
                        <Card className="tata-cara mx-auto">
                            <CardBody>
                                <div className="nomor-card mx-auto mb-4">
                                    4
                                </div>
                                <div className="txt-card mx-auto mb-3">
                                    Jika terjadi gangguan, maka hasil
                                    terakhir akan tersimpan secara
                                    otomatis. 
                                </div>
                                <div>
                                <Image
                                    src="/assets/tata-cara/4.png"
                                    rounded={false}
                                    raised={false}
                                    alt="Image"
                                    className="mx-auto"
                                    style={{width: '150px'}}
                                />
                                </div>
                            </CardBody>
                        </Card>
                    </div>
                    <div className="col-lg-4 col-md-4 col-xs-12 mb-4 mx-auto">
                        <Card className="tata-cara mx-auto">
                            <CardBody>
                                <div className="nomor-card mx-auto mb-4">
                                    5
                                </div>
                                <div className="txt-card mx-auto mb-5">
                                    Hasil tryout akan keluar langsung
                                    setelah hasil disubmit atau waktu
                                    habis. 
                                </div>
                                <div>
                                <Image
                                    src="/assets/tata-cara/5.png"
                                    alt="Image"
                                    className="mx-auto"
                                    style={{width: '150px',}}
                                />
                                </div>
                            </CardBody>
                        </Card>
                    </div>
                    <div className="col-lg-4 col-md-4 col-xs-12 mb-4 mx-auto">
                        <Card className="tata-cara mx-auto">
                            <CardBody>
                                <div className="nomor-card mx-auto mb-4">
                                    6
                                </div>
                                <div className="txt-card mx-auto mb-4">
                                    Jawaban benar dari tryout akan
                                    ada fitur ulasan.
                                </div>
                                <div>
                                <Image
                                    src="/assets/tata-cara/6.png"
                                    alt="Image"
                                    className="mx-auto"
                                    style={{width: '170px',}}
                                />
                                </div>
                            </CardBody>
                        </Card>
                    </div>
                    <div className="col-lg-4 col-md-4 col-xs-12 mb-4 mx-auto">
                        <Card className="tata-cara mx-auto">
                            <CardBody>
                                <div className="nomor-card mx-auto mb-4">
                                    7
                                </div>
                                <div className="txt-card mx-auto mb-3">
                                    Jika terdapat error atau kesulitan
                                    saat akses, Anda dapat
                                    menghubungi admin via WA
                                    <a href={contactLink()} target={'_blank'}> <i class="fa fa-whatsapp" aria-hidden="true"></i></a>
                                </div>
                                <div>
                                <Image
                                    src="/assets/tata-cara/7.png"
                                    alt="Image"
                                    className="mx-auto"
                                    style={{width: '150px',}}
                                />
                                </div>
                            </CardBody>
                        </Card>
                    </div>
                </div>
            </div>
        </div>      

      </div>
    </React.Fragment>
    
  )
}