import React, { useState, useEffect } from 'react';
import AkademikSidebar from '../../../../components/layout/dashboard/akademik/AkademikSideBar';
import { useHistory } from 'react-router';

import { FormGroup, FormControl, Form } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import { Link } from 'react-router-dom';
import Button from '@restart/ui/esm/Button';

import { connect } from 'react-redux';
import { getAllTryout, getAllProgram } from '../../../../store/actions/tryout';

function DataPeserta(props) {
  document.title = "INGENIO Indonesia | Dashboard Akademik - Data Peserta Tryout";
  document.body.style.backgroundColor = "#f3f4f6";
  const [programs, setPrograms] = useState([]);
  const [dataTryout, setDataTryout] = useState([]);

  // FILTERING DATA
  const [selectedProgram, setSelectedProgram] = React.useState(null)
  const [filterText, setFilterText] = React.useState('');
  const [filterObj, setFilterObj] = React.useState({
    text: '',
    selectedProgram: ''
  })
  const onFilter = (data) => {
    setFilterText(data)
  }
  const onFilterSubmit = (e) => {
    e.preventDefault();
    let obj = {
      text: filterText,
      program: selectedProgram
    }
    setFilterObj(obj)
  }

  const filteredItems = dataTryout.filter(
		item => (item.paket && item.paket.toLowerCase().includes(filterObj.text.toLowerCase())) && (!filterObj.program || item.program.includes(filterObj.program)),
	);
  // END FILTERING DATA

  const history = useHistory();
  const { dispatch } = props;

  useEffect(() => {
    dispatch(getAllTryout('perPage=1000'))
      .then((response) => {
        console.log(response);
        let obj = { ...response.data };
        let tryoutArr = [];
        let count = 1;
        for (const key in obj) {
          console.log(obj[key]);
          let jumlahSoal = 0;
          if (obj[key].subtryout.length > 0) {
            for (let i = 0; i < obj[key].subtryout.length; i++) {
              jumlahSoal += obj[key].subtryout[i].jumlah_soal;
            }
          }
          let payload = {
            no: count,
            id: obj[key].id,
            paket: obj[key].nama_tryout,
            kode: obj[key].kode_tryout,
            program: obj[key].program.nama,
            status: obj[key].is_active ? 'ACTIVE' : 'NONACTIVE',
            jumlah: jumlahSoal
          };
          tryoutArr.push(payload)
          count++
        }
        setDataTryout(tryoutArr);
        console.log(dataTryout)
        // const tryout = response.data
        // return setDataTryout({tryout})
      });
  }, []);

  useEffect(() => {
    dispatch(getAllProgram())
      .then((response) => {
        let obj = { ...response.data };
        let count = 1;
        let programArr = [];
        for (const key in obj) {
          console.log(obj[key]);
          let payload = {
            key: count,
            id: obj[key].id,
            nama: obj[key].nama,
          };
          programArr.push(payload)
          count++
        }
        setPrograms(programArr);
      });
  }, []);

  const dropdown = {
    borderRadius: "10px",
    height: 50,
    padding: "25px",
  };

  const columns = [
    {
      name: 'No.',
      selector: row => row.no,
      sortable: true,
      width: '7.5rem',
    },
    {
      name: 'Nama TryOut',
      selector: row => row.paket,
      sortable: true,
    },
    {
      name: 'Kode TryOut',
      selector: row => row.kode,
      sortable: true,
    },
    {
      name: 'Program',
      selector: row => row.program,
      sortable: true,
    },
    {
      name: 'Status',
      selector: row => row.status,
      sortable: true,
    },
    {
      cell: (row) => <Link className="pilih-tryout p-2" to={"/dashboard/akademik/data-peserta/subtryout/" + row.id}>View</Link>,
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  const customStyles = {
    table: {
      style: {
        borderRadius: '15px',
        border: '1px solid #BDC1C9',
      },
    },
    head: {
      style: {
        fontSize: '1.25rem',
        textTransform: "uppercase",
        color: '#6B7280',
        backgroundColor: '#f3f4f6',
        border: '1px solid #BDC1C9',
        borderTopLeftRadius: '15px',
        borderTopRightRadius: '15px',
      },
    },
    pagination: {
      style: {
        color: '#6B7280',
        fontSize: '1.1rem',
        minHeight: '56px',
        backgroundColor: '#f3f4f6',
        border: '1.25px solid #BDC1C9',
        borderBottomLeftRadius: '15px',
        borderBottomRightRadius: '15px',
      },
    },
  };

  return (
    <React.Fragment>
      <AkademikSidebar judul={"Data Peserta TryOut"} />
      <div className="md:ml-64">
        <div className="bg-orange px-3 md:px-8 py-1" style={{ height: '5vh' }}>
          <div className="container md:pr-8 md:pl-10">
            {/* <h3 className="text-left tracking-wider title-dashboard">Selamat Datang Staff Akademik INGENIO!</h3> */}
          </div>
        </div>

        <div className="px-3 md:px-8 mt-5">
          <div className="container mx-auto max-w-full">
            <div className="flex-2 flex-col">
              <div className="flex-2 mb-4">
                <form onSubmit={(e) => onFilterSubmit(e)}>
                  <div className="flex flex-row">
                    <div className="bg-white px-4 py-3 mr-3" style={{ borderRadius: '20px' }}>
                      <input
                        type="text"
                        name=""
                        value={filterText}
		                  	onChange={(e) => onFilter(e.target.value)}
                        style={{ backgroundColor: '#F3F4F6', width: '25rem', borderRadius: '12px', padding: '0.75rem', border: 'none !important', paddingLeft: '10px', fontFamily: 'Quicksand' }}
                        className="mr-3"
                        placeholder="Nama Tryout atau keyword"
                        id=""
                      />
                      <select
                        as="select"
                        aria-label="Program"
                        aria-describedby="basic-addon1"
                        className="my-tryout px-3 py-2 mx-3"
                        style={{ fontFamily: 'Quicksand' }}
                        onChange={(e) => setSelectedProgram(e.target.value)}
                      >
                        <option value="">Pilih Program</option>
                        {programs.map(item =>
                          <option key={item.key} value={item.nama}>{item.nama}</option>
                        )};
                      </select>
                      <Button type="submit" className="pilih-tryout mx-3" onClick={(e) => onFilterSubmit(e)}>Cari</Button>
                    </div>
                  </div>
                </form>
              </div>
              <div className="flex-2 mb-4">
                <DataTable columns={columns} data={filteredItems} customStyles={customStyles} pagination />
              </div>
            </div>
          </div>
        </div>

      </div>
    </React.Fragment>

  )
}

export default connect()(DataPeserta)