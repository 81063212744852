import React from 'react';
import {
    Gallery,
} from '../components/gallery';
import {
    IngenioNav2,
    IngenioFooter2
} from '../components/layout'

const galleryConfig = {
    title: "Event",
    type: "event"
}

export default function EventPage() {
    return (
        <React.Fragment>
            <IngenioNav2 />
            <div style={{ backgroundColor: 'white' }}>
                <Gallery {...galleryConfig} />
            </div>
            <IngenioFooter2 />
        </React.Fragment>

    )
}