import Button from '@material-tailwind/react/Button';
import React, { useState, useEffect } from 'react';
import { FormGroup, FormControl, Form, Modal } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import { Link } from 'react-router-dom';
import { BsFillFileEarmarkArrowUpFill } from 'react-icons/bs';
import { HiOutlinePencilAlt, HiOutlineDocumentDownload, HiOutlineTrash } from 'react-icons/hi';
import AkademikSidebar from '../../../components/layout/dashboard/akademik/AkademikSideBar';
import Swal from 'sweetalert2';
import moment from 'moment-timezone';
import AuthService from '../../../services/auth'
import CustomDatatable from '../../../components/common/CustomDatatable';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';


export default function UploadDataAkademik(props) {
    const [show, setShow] = useState(false);

    const handleClose = () => {
        setUploadDataId(null);
        setShow(false)
    };
    const handleShow = (id, filename) => {
        console.log(filename)
        setUploadDataId(id);
        setFileName(filename)
        setShow(true)
    };
    var baseUrl = window.location.origin;

    document.title = "INGENIO Indonesia";
    const [program, setProgram] = useState('');

    // Filtering data
    const [isLoading, setIsLoading] = useState(true);
    const [hasNext, setHasNext] = useState(false)
    const [hasPrev, setHasPrev] = useState(false)
    const [count, setCount] = useState(0);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [keyword, setKeyword] = useState('');
    const [kelasData, setKelasData] = useState([]);

    // for upload file
    const [uploadDataId, setUploadDataId] = useState(null);
    const [fileName, setFileName] = useState('');
    const [fileUpload, setFileUpload] = useState(null);
    const dropdown = {
        borderRadius: "10px",
        height: 50,
        padding: "25px",
    };
    const columns = [
        {
            name: 'NO.',
            field: 'no',
            index: true,
            selector: row => row.no,
            sortable: true,
            minWidth: '1px'
        },
        {
            name: 'NAMA DOKUMEN',
            field: 'name',
            selector: row => row.nama,
            sortable: true,
            // width: '15vw',
        },
        {
            name: 'TANGGAL UPLOAD',
            render: true,
            component: (row) => {
                return (
                    <td className='main-table__bodyRow'>{moment(row.created_at).format('yyyy-MM-DD')}</td>
                )
            },
            selector: row => row.tanggal,
            sortable: true,
            // width: '15vw',
        },
        {
            name: 'Aksi',
            render: true,
            component: (row) => {
                return (
                    <td className='main-table__bodyRow'>
                        <div style={{display: 'flex', flexDirection: 'row'}}>
                            <OverlayTrigger placement="top" overlay={<Tooltip id="button-tooltip-2">Edit</Tooltip>}>
                                <Link to='#' className="btnCircleBlack mr-3" onClick={() => handleShow(row.id, row.name)}><HiOutlinePencilAlt size="22"/></Link>
                            </OverlayTrigger>
                            <OverlayTrigger placement="top" overlay={<Tooltip id="button-tooltip-2">Download</Tooltip>}>
                                <Link to='#' onClick={() => window.open(row.url, '_blank')} className="btnCircleBlack mr-3"><HiOutlineDocumentDownload size="22"/></Link>
                            </OverlayTrigger>
                            <OverlayTrigger placement="top" overlay={<Tooltip id="button-tooltip-2">Delete</Tooltip>}>
                                <Link to='#' className="btnCircleBlack mr-3" onClick={() => AreYouSure(row.id)}><HiOutlineTrash size="22"/></Link>
                            </OverlayTrigger>
                        </div>
                    </td>
                )
            },
            selector: row => row.tanggal,
            sortable: true,
            // width: '15vw',
        },
    ];
    const data = [
        {
            no: 1,
            nama: 'Lorem.',
            tanggal: '10-10-2022',
        },
        {
            no: 2,
            nama: 'Lorem.',
            tanggal: '10-10-2022',
        },
    ];

    const customStyles = {
        table: {
          style: {
            borderRadius: '15px',
            border: '1px solid #BDC1C9',
          },
        },
        head: {
          style: {
            fontSize: '1.25rem',
            textTransform: "uppercase",
            color: '#6B7280',
            backgroundColor: '#f3f4f6',
            border: '1px solid #BDC1C9',
            borderTopLeftRadius: '15px',
            borderTopRightRadius: '15px',
          },
        },
        pagination: {
          style: {
            color: '#6B7280',
            fontSize: '1.1rem',
            minHeight: '56px',
            backgroundColor: '#f3f4f6',
            border: '1.25px solid #BDC1C9',
            borderBottomLeftRadius: '15px',
            borderBottomRightRadius: '15px',
          },
        },
    };

    const AreYouSure = (id) => {
        Swal.fire({  
            title: 'Sudah Yakin?',  
            text: 'Apakah Anda Yakin Ingin Menghapus Data Ini?',  
            icon: 'warning',  
            showCancelButton: true,  
            cancelButtonText: 'Tidak',  
            confirmButtonText: 'Ya'  
        }).then((result) => {
            if (result.isConfirmed) {
                deleteUploadFile(id)
            }
          }); 
    }

    const Succeed = () => {
        Swal.fire({  
            title: 'Data Berhasil Tersimpan !',  
            text: '',  
            icon: 'success',  
            showCancelButton: false,              
            confirmButtonText: 'OK'  
        }).then((result) => {
          if (result.isConfirmed) {
            getListKelas();
          }
        })
    }

    const getListKelas = (params = '') => {
        setIsLoading(true)
        AuthService.getListUploadFiles(params).then(resp => {
            const response = resp.data.data;
            setKelasData(response.data);

            setPage(response.meta.page)
            setPerPage(response.meta.perPage)
            setCount(response.meta.count)

            setHasNext(response.meta.hasNext)
            setHasPrev(response.meta.hasPrev);
            
            setIsLoading(false)
        }).catch(err => {
            setIsLoading(false)
            console.error(err)
        })
    }

    const uploadData = (body) => {
        setIsLoading(true)
        AuthService.uploadFile(body).then(resp => {
            Succeed()

            setIsLoading(false)
        }).catch(err => {
            setIsLoading(false)
            console.error(err)
        })
    }

    const updateUploadFile = (id, body) => {
        setIsLoading(true)
        AuthService.updateUploadFile(id, body).then(resp => {
            Succeed()

            setIsLoading(false)
        }).catch(err => {
            setIsLoading(false)
            console.error(err)
        })
    }

    const deleteUploadFile = (id) => {
        setIsLoading(true)
        AuthService.deleteUploadFile(id).then(resp => {
            Succeed()

            setIsLoading(false)
        }).catch(err => {
            setIsLoading(false)
            console.error(err)
        })
    }

    useEffect(() => {
        getListKelas();
    }, [])

    return (
        <React.Fragment>
            <AkademikSidebar />
            <div className="md:ml-64 tutorDataKelas" style={{ backgroundColor: '#F3F4F6' }}>
                <div className="bg-orange md:px-8 pb-5" style={{height: '20vh', position: 'relative', padding: '0 30px 30px 30px'}}>
                    <h1 className="tracking-wider title-24-600">Upload Data Akademik</h1>
                    {/* <h2 className="tracking-wider title-20-700 mt-5">Selamat Datang Tutor INGENIO !</h2> */}
                    <img src={baseUrl + "/assets/logo/evaluasikelas.png"} className="img-opacity"></img>
                </div>

                <div className="px-3 md:px-8 mt-4 pb-5">
                    <div className="container mx-auto max-w-full">
                        <div className="row">
                            <div className="col-12 mt-4 mb-5">
                                <div className="cards pd-InJurnal">
                                    <div className="row">
                                        <div className="col-12 mb-4">
                                            <div className="title-20-700">Upload Data Akademik</div>
                                            <div className="borderBottomGrey"></div>
                                        </div>
                                        <div className="col-12">
                                            <div className="title-18-500 mb-1">Nama Dokumen</div>
                                            <input value={fileName} onChange={(e) => setFileName(e.target.value)} type="text" class="formInputJurnal" placeholder="masukkan nama dokumen..." />
                                        </div>
                                        <div className="col-12 mt-3">
                                            <div className="title-18-500">Upload<span className="txtpdf ml-2">(PDF,DOC,PPT,EXCEL)</span></div>
                                            <div className="relative mt-1">
                                                <input onChange={(e) => {
                                                    const file = e.target.files[0];
                                                    setFileUpload(file);
                                                }} type="file" id="file" className="custom-file-input" style={{ position: 'absolute', left: '2rem' }} />
                                                <label htmlFor="file" className="labelFile">Upload File <BsFillFileEarmarkArrowUpFill size="18px"/></label>
                                            </div>
                                        </div>
                                        <div className="col-12 justify-end mt-5">
                                            <Button type="submit" onClick={() => {
                                                let formData = new FormData();
                                                formData.append('nama_file', fileName);
                                                formData.append('file', fileUpload);

                                                uploadData(formData);
                                            }} className="btnOrange3">
                                                <span className="txt-btn">Submit</span>
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
   
                            <div className="col-12 my-2">
                                <FormGroup>
                                    <div className="SearchTable">
                                        <input value={keyword} onChange={(e) => setKeyword(e.target.value)} type="text" name="" className="inputSearchTable" placeholder="&#x1F50E;&#xFE0E; Search..." id="" />
                                        <Button onClick={() => {
                                                getListKelas(`page=${page}&perPage=${perPage}&keyword=${keyword}`)
                                            }} className="btnSearchTable">Cari</Button>
                                    </div>
                                </FormGroup>
                            </div>
                            <div className="col-12 mt-3">
                                {/* <DataTable columns={columns} customStyles={customStyles} data={data} pagination /> */}
                                <CustomDatatable 
                                    filteredItems={kelasData} 
                                    columns={columns}
                                    isLoading={isLoading}
                                    page={page}
                                    perPage={perPage}
                                    hasPrev={hasPrev}
                                    hasNext={hasNext}
                                    count={count}
                                    onTableChange={(e, data) => {
                                        switch (e) {
                                        case 'rowPerPageChange':
                                            setPerPage(data.perPage)
                                            getListKelas(`page=${page}&perPage=${data.perPage}&keyword=${keyword}`)
                                            break;
                                        case 'rowPageChange':
                                            setPage(data.page)
                                            getListKelas(`page=${data.page}&perPage=${perPage}&keyword=${keyword}`)
                                            break;
                                        
                                        default:
                                            break;
                                        }
                                    }}
                                />
                            </div>
                        </div>
                        <Modal show={show} onHide={handleClose}       
                                {...props}
                                size="lg"
                                aria-labelledby="contained-modal-title-vcenter"
                                centered>
                            <Modal.Header closeButton>
                                <Modal.Title id="contained-modal-title-vcenter">
                                    <div className="title-20-700">Edit Data Akademik</div>
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <div className="row p-4">
                                    <div className="col-12">
                                        <div className="title-18-500 mb-1">Nama Dokumen</div>
                                        <input value={fileName} onChange={(e) => setFileName(e.target.value)} type="text" class="formInputJurnal" placeholder="masukkan nama dokumen..." />
                                    </div>
                                    <div className="col-12 mt-3">
                                        <div className="title-18-500">Upload<span className="txtpdf ml-2">(PDF,DOC,PPT,EXCEL)</span></div>
                                        <div className="relative mt-1">
                                            <input onChange={(e) => {
                                                    const file = e.target.files[0];
                                                    setFileUpload(file);
                                                }} type="file" id="fileEdit" className="custom-file-input" style={{ position: 'absolute', left: '2rem' }} />
                                            <label htmlFor="fileEdit" className="labelFile">Upload File <BsFillFileEarmarkArrowUpFill size="18px"/></label>
                                        </div>
                                    </div>
                                    <div className="col-12 justify-end mt-5">
                                        <Button type="submit" onClick={() => {
                                            let formData = new FormData();
                                            formData.append('nama_file', fileName);
                                            formData.append('file', fileUpload);

                                            updateUploadFile(uploadDataId, formData)
                                        }} className="btnOrange3">
                                            <span className="txt-btn">Submit</span>
                                        </Button>
                                    </div>
                                </div>
                            </Modal.Body>
                        </Modal>
                    </div>
                </div>

            </div>
        </React.Fragment >

    )
}