import React from 'react';
import { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import AdminSideBar from '../../../../components/layout/dashboard/admin/AdminSideBar';
import AuthService from '../../../../services/auth'
import moment from 'moment-timezone';
import Swal from 'sweetalert2';


export default function ProfileTutorAdmin () {
  document.title = "INGENIO Indonesia";
  var baseUrl = window.location.origin;
  document.body.style.backgroundColor = "#f3f4f6";

  const [profile, setProfile] = useState({tutor: [{}]});
  const [isLoading, setIsLoading] = useState(false);

  const { id } = useParams();

  const getListKelas = () => {
    setIsLoading(true)
    AuthService.getDetailUserProfileTutor(id).then(resp => {
        const response = resp.data;
        setProfile({...response.data});
        
        setIsLoading(false)
    }).catch(err => {
        let msg = 'Internal Server Error'
        setIsLoading(false)
        console.error(err)
        if(err.response) {
            if(err.response.data.message) {
                msg = err.response.data.message
            }
        }

        Fail(msg)
    })
  }

  const Fail = (message) => {
    Swal.fire({  
        title: 'Gagal!',  
        text: message,  
        icon: 'warning',  
        showCancelButton: true,  
        cancelButtonText: 'Kembali',           
        showConfirmButton: false,  
    });   
  }

  useEffect(() => {
    getListKelas()
  }, [])

  if(isLoading) {
    return (
      <React.Fragment>
      <AdminSideBar />
        <div className="md:ml-64">
          <div className="bg-orange px-3 md:px-8 py-1" style={{ height: '2.5rem' }}>
            Loading...
          </div>
        </div>
    </React.Fragment>
    )
  }

  return(
    <React.Fragment>
      <AdminSideBar />
        <div className="md:ml-64">
          <div className="bg-orange px-3 md:px-8 py-1" style={{ height: '2.5rem' }}>
          </div>
          <div className="py-4 px-3 md:px-8">
              <div className="container mx-auto max-w-full">
                  <div className="row">
                      <div className="col-lg-11 mx-auto">
                          <div className="cards">
                              <div className="row">
                                  <div className="col-12 flex gap-4" style={{padding: '2.4rem 4.3rem 0 4.3rem'}}>
                                      <img src={baseUrl + "/assets/img/jurnal-1.png"} className="imgDetail" />
                                      <div className="title-24-700 mt-2">Profile Tutor</div>
                                  </div>
                                  <div className="col-12 mb-4" style={{padding: '0 4.3rem'}}>
                                      <div className="borderBottomGrey"></div>
                                  </div>
                                  <div className="col-12">
                                    <div className="row" style={{ padding: '1.2rem 3.7rem'}}>
                                        <div className="col-lg-3 mb-4 relative">
                                            <div className="stickyTopPosition">
                                                {
                                                  profile.profile_photo !== null ? 
                                                  <img src={profile.profile_photo} className="object-center mx-auto"></img>
                                                  :
                                                  <img src={baseUrl + "/assets/img/profile-image-default.png"} className="object-center mx-auto"></img>
                                                }
                                                
                                            </div>
                                        </div>
                                        <div className="col-lg-9">
                                          <div className="row mb-4">
                                            <div className="col-md-6 lh-detail" style={{paddingLeft: '30px'}}>
                                              <h4>Nama Lengkap</h4>
                                            </div>
                                            <div className="col-md-6 lh-detail">
                                              <h4 className="font-bold">{profile.full_name}</h4>
                                            </div>
                                          </div>
                                          <div className="row mb-4">
                                            <div className="col-md-6 lh-detail" style={{paddingLeft: '30px'}}>
                                              <h4>Nama Panggilan</h4>
                                            </div>
                                            <div className="col-md-6 lh-detail">
                                              <h4 className="font-bold">{profile.nick_name}</h4>
                                            </div>
                                          </div>
                                          <div className="row mb-4">
                                            <div className="col-md-6 lh-detail" style={{paddingLeft: '30px'}}>
                                              <h4>Tempat/Tgl Lahir</h4>
                                            </div>
                                            <div className="col-md-6 lh-detail">
                                              <h4 className="font-bold">{profile.tutor[0].born_date ? moment(profile.tutor[0].born_date).format('DD-MMM-YYYY') : moment('1900-01-01T00:00:00.000Z').format('DD-MMM-YYYY')}</h4>
                                            </div>
                                          </div>
                                          <div className="row mb-4">
                                            <div className="col-md-6 lh-detail" style={{paddingLeft: '30px'}}>
                                              <h4>Status</h4>
                                            </div>
                                            <div className="col-md-6 lh-detail">
                                              <h4 className="font-bold">{profile.tutor[0].marital_status}</h4>
                                            </div>
                                          </div>
                                          <div className="row mb-4">
                                            <div className="col-md-6 lh-detail" style={{paddingLeft: '30px'}}>
                                              <h4>Agama</h4>
                                            </div>
                                            <div className="col-md-6 lh-detail">
                                              <h4 className="font-bold">Islam</h4>
                                            </div>
                                          </div>
                                          <div className="row mb-4">
                                            <div className="col-md-6 lh-detail" style={{paddingLeft: '30px'}}>
                                              <h4>No HP</h4>
                                            </div>
                                            <div className="col-md-6 lh-detail">
                                              <h4 className="font-bold">{profile.phone_number}</h4>
                                            </div>
                                          </div>
                                          <div className="row mb-4">
                                            <div className="col-md-6 lh-detail" style={{paddingLeft: '30px'}}>
                                              <h4>Akun Instagram</h4>
                                            </div>
                                            <div className="col-md-6 lh-detail">
                                              <h4 className="font-bold">{'-'}</h4>
                                            </div>
                                          </div>
                                          <div className="row mb-4">
                                            <div className="col-md-6 lh-detail" style={{paddingLeft: '30px'}}>
                                              <h4>Jenis Kelamin</h4>
                                            </div>
                                            <div className="col-md-6 lh-detail">
                                              <h4 className="font-bold">{profile.gender}</h4>
                                            </div>
                                          </div>
                                          <div className="row mb-4">
                                            <div className="col-md-6 lh-detail" style={{paddingLeft: '30px'}}>
                                              <h4>Email</h4>
                                            </div>
                                            <div className="col-md-6 lh-detail">
                                              <h4 className="font-bold">{profile.email}</h4>
                                            </div>
                                          </div>
                                          <div className="row mb-4">
                                            <div className="col-md-6 lh-detail" style={{paddingLeft: '30px'}}>
                                              <h4>Alamat Sesuai KTP</h4>
                                            </div>
                                            <div className="col-md-6 lh-detail">
                                              <h4 className="font-bold">{profile.tutor[0].ktp_address}</h4>
                                            </div>
                                          </div>
                                          <div className="row mb-4">
                                            <div className="col-md-6 lh-detail" style={{paddingLeft: '30px'}}>
                                              <h4>Alamat Domisili</h4>
                                            </div>
                                            <div className="col-md-6 lh-detail">
                                              <h4 className="font-bold">{profile.tutor[0].domicile_address}</h4>
                                            </div>
                                          </div>
                                          <div className="row mb-4">
                                            <div className="col-md-6 lh-detail" style={{paddingLeft: '30px'}}>
                                              <h4>Regio</h4>
                                            </div>
                                            <div className="col-md-6 lh-detail">
                                              <h4 className="font-bold">{profile.regional}</h4>
                                            </div>
                                          </div>
                                          <div className="row mb-4">
                                            <div className="col-md-6 lh-detail" style={{paddingLeft: '30px'}}>
                                              <h4>NPWP</h4>
                                            </div>
                                            <div className="col-md-6 lh-detail">
                                              <h4 className="font-bold">{profile.tutor[0].npwp_number}</h4>
                                            </div>
                                          </div>
                                          <div className="row mb-4">
                                            <div className="col-md-6 lh-detail" style={{paddingLeft: '30px'}}>
                                              <h4>Nama Bank</h4>
                                            </div>
                                            <div className="col-md-6 lh-detail">
                                              <h4 className="font-bold">{'-'}</h4>
                                            </div>
                                          </div>
                                          <div className="row mb-4">
                                            <div className="col-md-6 lh-detail" style={{paddingLeft: '30px'}}>
                                              <h4>No Rekening</h4>
                                            </div>
                                            <div className="col-md-6 lh-detail">
                                              <h4 className="font-bold">{profile.tutor[0].account_number}</h4>
                                            </div>
                                          </div>
                                          <div className="row mb-4">
                                            <div className="col-md-6 lh-detail" style={{paddingLeft: '30px'}}>
                                              <h4>Pendidikan Terakhir</h4>
                                            </div>
                                            <div className="col-md-6 lh-detail">
                                              <h4 className="font-bold">
                                                {profile.tutor[0].university}<br />
                                                -<br />
                                                -<br />
                                              </h4>
                                            </div>
                                          </div>
                                          <div className="row mb-4">
                                            <div className="col-md-6 lh-detail" style={{paddingLeft: '30px'}}>
                                              <h4>Pelatihan Yang Diikuti</h4>
                                            </div>
                                            <div className="col-md-6 lh-detail">
                                              <h4 className="font-bold">-</h4>
                                            </div>
                                          </div>
                                        </div>
                                    </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
        </div>
    </React.Fragment>
    
  )
}