import Button from '@material-tailwind/react/Button';
import React, { useEffect, useState } from 'react';
import { FormGroup, FormControl, Form, Modal } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import { Link } from 'react-router-dom';
import { BsPencilSquare, BsFillTrashFill, BsFillPlusCircleFill } from 'react-icons/bs';
import AkademikSidebar from '../../../../components/layout/dashboard/akademik/AkademikSideBar';
import ElearningService from '../../../../services/elearning';
import AuthService from '../../../../services/auth';
import CustomDatatable from '../../../../components/common/CustomDatatable';
import moment from 'moment-timezone';
import Swal from 'sweetalert2';

export default function OlahKelas() {
    document.title = "INGENIO Indonesia";
    const [program, setProgram] = useState('');
    const [show, setShow] = useState(false);
    const [showUpdate, setShowUpdate] = useState(false);
    const [programData, setPRogramData] = useState([])
    const [regioDatas, setRegioDatas] = useState([])

    // Filtering data
    const [isLoading, setIsLoading] = useState(true);
    const [hasNext, setHasNext] = useState(false)
    const [hasPrev, setHasPrev] = useState(false)
    const [count, setCount] = useState(0);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [keyword, setKeyword] = useState('');
    const [kelasData, setKelasData] = useState([]);

    // Form Add Kelas
    const [idKelas, setIdKelas] = useState(null);
    const [namaKelas, setNamaKelas] = useState('');
    const [kodeKelas, setKodeKelas] = useState('');
    const [hargaKelas, setHargaKelas] = useState('');
    const [regio, setRegio] = useState('');
    const [idRegio, setIdRegio] = useState('');
    const [idElearningProgram, setIdElearningProgram] = useState('');
    const [kapasitas, setKapasitas] = useState(0);
    const [isActive, setIsActive] = useState(true);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleUpdateClose = () => setShowUpdate(false);
    const handleUpdateShow = () => setShowUpdate(true);
    const dropdown = {
        borderRadius: "10px",
        height: 50,
        padding: "25px",
    };
    const columns = [
        {
            name: 'NO.',
            field: 'no',
            index: true,
            selector: row => row.no,
            sortable: true,
            minWidth: '1px'
            
        },
        {
            name: 'NAMA KELAS',
            field: 'nama_kelas',
            selector: row => row.kelas,
            sortable: true,
            
        },
        {
            name: 'NAMA REGIO',
            field: 'nama_regio',
            selector: row => row.regio,
            sortable: true,
             
        },
        {
            name: 'NAMA PROGRAM',
            render: true,
            component: (row) => {
                return (
                    <td className='main-table__bodyRow'>{row.elearning_program.nama_program}</td>
                )
            },
            selector: row => row.program,
            sortable: true,
            
        },
        {
            name: 'KAPASITAS',
            field: 'kapasitas',
            render: true,
            component: (row) => {
                return (
                    <td className='main-table__bodyRow'>{`${row.kapasitas} Peserta`}</td>
                )
            },
            selector: row => row.kapasitas,
            sortable: true,
            
        },
        {
            name: '',
            render: true,
            component: (row) => {
              return (
                <td className='main-table__bodyRow'>
                    <Button
                        className="flex content-center items-center"
                        style={{width: '35px', borderRadius: '50%', padding: '10px', color: 'black', background: '#F8CA2B' }}
                        onClick={() => { 
                            setIdKelas(row.id)
                            setNamaKelas(row.nama_kelas)
                            setKodeKelas(row.kode_kelas)
                            setHargaKelas(row.harga_kelas)
                            setRegio(row.nama_regio)
                            setIdRegio(row.id_regio)
                            setIdElearningProgram(row.id_elearning_program)
                            setKapasitas(row.kapasitas)
                            setIsActive(row.is_active)
                            handleUpdateShow()
                        }}
                    >
                        <BsPencilSquare color='black' />
                    </Button>
                </td>
              )
            },
            selector: row => row.jumlah,
            sortable: true,
            minWidth: '20px',
            wrap: 'yes'
        },
        {
            name: '',
            render: true,
            component: (row) => {
              return (
                <td className='main-table__bodyRow'>
                  <Button
                    className="flex content-center items-center"
                    style={{width: '35px', borderRadius: '50%', padding: '10px', color: 'black', background: '#F8CA2B' }}
                    onClick={() => { 
                        deleteKelas(row.id)
                    }}
                    >
                    <BsFillTrashFill color='black'  />
                  </Button>
                </td>
              )
            },
            selector: row => row.jumlah,
            sortable: true,
            minWidth: '20px',
            wrap: 'yes'
        },
        {
            name: '',
            render: true,
            component: (row) => {
              return (
                <td className='main-table__bodyRow'>
                  <Button
                    className="flex content-center items-center"
                    style={{padding: '10px', color: 'black', background: '#F8CA2B' }}
                    onClick={() => { 
                        // deleteKelas(row.id)
                        updateStatusKelas(row.id, row)
                    }}
                  >
                    <span style={{color: 'black'}}>{row.is_active ? 'Inactivate' : 'Activate'}</span> 
                  </Button>
                </td>
              )
            },
            selector: row => row.jumlah,
            sortable: true,
            minWidth: '20px',
            wrap: 'yes'
        }
    ];

    const getListKelas = (params = '') => {
        setIsLoading(true)
        ElearningService.getListKelasAkademik(params).then(resp => {
            const response = resp.data;
            setKelasData(response.data);

            setPage(response.meta.page)
            setPerPage(response.meta.perPage)
            setCount(response.meta.count)

            setHasNext(response.meta.hasNext)
            setHasPrev(response.meta.hasPrev);
            
            setIsLoading(false)
        }).catch(err => {
            setIsLoading(false)
            console.error(err)
        })
    }

    const getListProgram = () => {
        ElearningService.getListElearningProgramAkademik().then(resp => {
            const response = resp.data
            setPRogramData(response.data)
        }).catch(err => {
            console.error(err)
        })
    }

    const getListRegio = () => {
        AuthService.getListRegioAll().then(resp => {
            const response = resp.data
            setRegioDatas(response.data)
        }).catch(err => {
            console.error(err)
        })
    }

    const updateStatusKelas = (id, data) => {
        const body = {
            nama_kelas: data.nama_kelas,
            kode_kelas: data.kode_kelas,
            nama_regio: data.nama_regio,
            harga_kelas: data.harga_kelas,
            id_elearning_program: data.id_elearning_program,
            kapasitas: data.kapasitas,
            is_active: !data.is_active
        }

        ElearningService.updateKelasAkademik(body, id).then(resp => {
            Succeed('Data berhasil diupdate', () => {
                handleUpdateClose()
                getListKelas()
            })
        }).catch(err => {
            Fail('Gagal menambahkan data')
        })

    }

    const updateKelas = () => {
        if(namaKelas == '' || namaKelas == null) {
            Fail('Nama kelas harus diisi')
            return;
        }
        if(hargaKelas == '' || hargaKelas == null) {
            Fail('Harga kelas harus diisi')
            return;
        }
        if(kodeKelas == '' || kodeKelas == null) {
            Fail('Kode kelas harus diisi')
            return;
        }
        if(regio == '' || regio == null) {
            Fail('Regio harus diisi')
            return;
        }
        if(idElearningProgram == '' || idElearningProgram == null) {
            Fail('Program harus diisi')
            return;
        }
        if(kapasitas == '' || kapasitas == null) {
            Fail('Kapasitas harus diisi')
            return;
        }
        if(parseInt(kapasitas) < 1) {
            Fail('Kapasitas harus lebih dari 0')
            return;
        }

        const body = {
            nama_kelas: namaKelas,
            kode_kelas: kodeKelas,
            nama_regio: regio,
            id_regio: idRegio,
            id_elearning_program: idElearningProgram,
            kapasitas: kapasitas,
            is_active: isActive
        }

        Swal.fire({  
            title: 'Sudah Yakin?',  
            text: 'Pastikan semua data yang Anda isikan sudah sesuai',  
            icon: 'warning',  
            showCancelButton: true,  
            cancelButtonText: 'Tidak',  
            confirmButtonText: 'Ya'  
          })
          .then((result) => {
            if (result.isConfirmed) {
                ElearningService.updateKelasAkademik(body, idKelas).then(resp => {
                    Succeed('Data berhasil diupdate', () => {
                        handleUpdateClose()
                        getListKelas()
                    })
                }).catch(err => {
                    Fail('Gagal menambahkan data')
                })
            }
          });
    }

    const addKelas = () => {
        if(namaKelas == '' || namaKelas == null) {
            Fail('Nama kelas harus diisi')
            return;
        }
        if(kodeKelas == '' || kodeKelas == null) {
            Fail('Kode kelas harus diisi')
            return;
        }
        if(hargaKelas == '' || hargaKelas == null) {
            Fail('Harga kelas harus diisi')
            return;
        }
        if(regio == '' || regio == null) {
            Fail('Regio harus diisi')
            return;
        }
        if(idElearningProgram == '' || idElearningProgram == null) {
            Fail('Program harus diisi')
            return;
        }
        if(kapasitas == '' || kapasitas == null) {
            Fail('Kapasitas harus diisi')
            return;
        }
        if(parseInt(kapasitas) < 1) {
            Fail('Kapasitas harus lebih dari 0')
            return;
        }

        const body = {
            nama_kelas: namaKelas,
            kode_kelas: kodeKelas,
            nama_regio: regio,
            id_regio: idRegio,
            harga_kelas: hargaKelas,
            id_elearning_program: idElearningProgram,
            kapasitas: kapasitas,
            is_active: true
        }

        Swal.fire({  
            title: 'Sudah Yakin?',  
            text: 'Pastikan semua data yang Anda isikan sudah sesuai',  
            icon: 'warning',  
            showCancelButton: true,  
            cancelButtonText: 'Tidak',  
            confirmButtonText: 'Ya'  
          })
          .then((result) => {
            if (result.isConfirmed) {
                ElearningService.addKelasAkademik(body).then(resp => {
                    Succeed('Data berhasil ditambahkan', () => {
                        handleClose()
                        getListKelas()
                    })
                }).catch(err => {
                    Fail('Gagal menambahkan data')
                })
            }
          });
    }

    const deleteKelas = (id) => {
        Swal.fire({  
            title: 'Sudah Yakin?',  
            text: 'Anda yakin ingin menghapus data?',  
            icon: 'warning',  
            showCancelButton: true,  
            cancelButtonText: 'Tidak',  
            confirmButtonText: 'Ya'  
          })
          .then((result) => {
            if (result.isConfirmed) {
                ElearningService.deleteKelasAkademik(id).then(resp => {
                    Succeed('Data berhasil dihapus', () => {
                        handleClose()
                        getListKelas()
                    })
                }).catch(err => {
                    Fail('Gagal menambahkan data')
                })
            }
          });
    }

    const Succeed = (message, onConfirm) => {
        Swal.fire({  
            title: 'Berhasil!',  
            text: message,  
            icon: 'success',  
            showCancelButton: false,              
            confirmButtonText: 'OK'  
        }).then((result) => {
          if (result.isConfirmed) {
            onConfirm()
          }
        })
      }
    
    const Fail = (message) => {
        Swal.fire({  
            title: 'Gagal!',  
            text: message,  
            icon: 'warning',  
            showCancelButton: true,  
            cancelButtonText: 'Kembali',           
            showConfirmButton: false,  
        });   
    }

    useEffect(() => {
        getListKelas();
        getListProgram();
        getListRegio()
    }, [])

    return (
        <React.Fragment>
            <AkademikSidebar judul={'Olah Kelas'} />
            <div className="md:ml-64" style={{ backgroundColor: '#F3F4F6' }}>
                <div className="bg-orange px-3 md:px-8 py-1" style={{ height: '5vh' }}>
                    <div className="container md:pr-8 md:pl-10">
                        {/* <h3 className="text-left tracking-wider title-dashboard">Selamat Datang Staff Akademik INGENIO!</h3> */}
                    </div>
                </div>
                <div className="px-3 md:px-8 mt-5">
                    <div className="container mx-auto max-w-full">
                        <div className="row">
                            <div className="col-md-8 col-lg-4 col-xs-12 mb-4">
                                <FormGroup>
                                    <div className="row" style={{ backgroundColor: '#FFF', padding: '1rem', borderRadius: '1rem' }}>
                                        <div className="col-8">
                                            <input value={keyword} onChange={(e) => setKeyword(e.target.value)} type="text" name="" style={{ backgroundColor: '#F3F4F6', width: '100%', borderRadius: '8px', border: 'none !important', height: '45px', paddingLeft: '10px' }} placeholder="&#x1F50E;&#xFE0E; Nama Kelas." id="" />
                                        </div>
                                        <div className="col-3">
                                            <Button onClick={() => {
                                                getListKelas(`page=${page}&perPage=${perPage}&keyword=${keyword}`)
                                            }} className="pilih-tryout">Cari</Button>
                                        </div>
                                    </div>
                                </FormGroup>
                            </div>
                            <div className="col-8" style={{ padding: '1rem' }}>
                                <Button className="pilih-tryout" onClick={() => {
                                    setNamaKelas('')
                                    setKodeKelas('')
                                    setRegio('')
                                    setIdRegio('')
                                    setIdElearningProgram('')
                                    setKapasitas(0)
                                    handleShow()
                                }}><BsFillPlusCircleFill /> Tambah Kelas</Button>

                                <Modal size="lg" show={show} onHide={handleClose}>
                                    <Modal.Header closeButton>
                                        <Modal.Title><strong>Tambah Kelas</strong></Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <div className="form-group p-5">
                                            <div className="row">
                                                <div className="col-12 lg:px-4">
                                                    <label htmlFor="" className="label-tryout">Nama Kelas</label> <br />
                                                    <Form.Control onChange={(e) => {
                                                        setNamaKelas(e.target.value)
                                                    }} value={namaKelas} type="text" name="" className="form-tryout mb-3" id="" />
                                                </div>
                                                <div className="col-12 lg:px-4">
                                                    <label htmlFor="" className="label-tryout">Kode Kelas</label> <br />
                                                    <Form.Control onChange={(e) => {
                                                        setKodeKelas(e.target.value)
                                                    }} value={kodeKelas} type="text" name="" className="form-tryout mb-3" id="" />
                                                </div>
                                                <div className="col-12 lg:px-4">
                                                    <label htmlFor="" className="label-tryout">Harga Kelas</label> <br />
                                                    <Form.Control onChange={(e) => {
                                                        setHargaKelas(e.target.value)
                                                    }} value={hargaKelas} type="text" name="" className="form-tryout mb-3" id="" />
                                                </div>
                                                <div className="col-12 lg:px-4 pb-3">
                                                    <label htmlFor="" className="label-tryout">Regio</label> <br />
                                                    <Form.Select
                                                        as="select"
                                                        value={idRegio}
                                                        aria-label="Regio"
                                                        aria-describedby="basic-addon1"
                                                        className="my-tryout"
                                                        onChange={(e) => {
                                                            setIdRegio(e.target.value)
                                                            const regios = regioDatas.filter(x => x.id === parseInt(e.target.value));
                                                            setRegio(regios[0].name)
                                                        }}
                                                    >
                                                        <option value="">--Pilih Regio--</option>
                                                        {
                                                            regioDatas.map((item, i) => {
                                                                return (
                                                                    <option key={i} value={item.id}>{item.name}</option>
                                                                )
                                                            })
                                                        }
                                                        
                                                    </Form.Select>
                                                </div>
                                                <div className="col-12 lg:px-4 pb-3">
                                                    <label htmlFor="" className="label-tryout">Nama Program</label> <br />
                                                    <Form.Select
                                                        as="select"
                                                        value={idElearningProgram}
                                                        aria-label="Regio"
                                                        aria-describedby="basic-addon1"
                                                        className="my-tryout"
                                                        onChange={(e) => {
                                                            setIdElearningProgram(e.target.value)
                                                        }}
                                                    >
                                                        <option value="">--Pilih Program--</option>
                                                        {
                                                            programData.map((item, i) => {
                                                                return (
                                                                    <option selected={idElearningProgram == item.id} key={i} value={item.id}>{item.nama_program}</option>
                                                                )
                                                            })
                                                        }
                                                        
                                                    </Form.Select>
                                                </div>
                                                <div className="col-12 lg:px-4">
                                                    <label htmlFor="" className="label-tryout">Kapasitas</label> <br />
                                                    <Form.Control onChange={(e) => {
                                                        setKapasitas(e.target.value)
                                                    }} value={kapasitas} type="text" name="" className="form-tryout mb-3" id="" />
                                                </div>
                                            </div>
                                        </div>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <Button className="btn btn-dark" style={{ backgroundColor: '#00' }} onClick={handleClose}>
                                            Batal
                                        </Button>
                                        <Button className="btn btn-success" onClick={() => {
                                            addKelas()
                                        }}>
                                            Submit
                                        </Button>
                                    </Modal.Footer>
                                </Modal>

                                <Modal size="lg" show={showUpdate} onHide={handleUpdateClose}>
                                    <Modal.Header closeButton>
                                        <Modal.Title><strong>Update Kelas</strong></Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <div className="form-group p-5">
                                            <div className="row">
                                                <div className="col-12 lg:px-4">
                                                    <label htmlFor="" className="label-tryout">Nama Kelas</label> <br />
                                                    <Form.Control onChange={(e) => {
                                                        setNamaKelas(e.target.value)
                                                    }} value={namaKelas} type="text" name="" className="form-tryout mb-3" id="" />
                                                </div>
                                                <div className="col-12 lg:px-4">
                                                    <label htmlFor="" className="label-tryout">Kode Kelas</label> <br />
                                                    <Form.Control onChange={(e) => {
                                                        setKodeKelas(e.target.value)
                                                    }} value={kodeKelas} type="text" name="" className="form-tryout mb-3" id="" />
                                                </div>
                                                <div className="col-12 lg:px-4 pb-3">
                                                    <label htmlFor="" className="label-tryout">Regio</label> <br />
                                                    <Form.Select
                                                        as="select"
                                                        value={idRegio}
                                                        aria-label="Regio"
                                                        aria-describedby="basic-addon1"
                                                        className="my-tryout"
                                                        onChange={(e) => {
                                                            setIdRegio(e.target.value)
                                                            const regios = regioDatas.filter(x => x.id === parseInt(e.target.value));
                                                            setRegio(regios[0].name)
                                                        }}
                                                    >
                                                        <option value="">--Pilih Regio--</option>
                                                        {
                                                            regioDatas.map((item, i) => {
                                                                return (
                                                                    <option key={i} value={item.id}>{item.name}</option>
                                                                )
                                                            })
                                                        }
                                                    </Form.Select>
                                                </div>
                                                <div className="col-12 lg:px-4 pb-3">
                                                    <label htmlFor="" className="label-tryout">Nama Program</label> <br />
                                                    <Form.Select
                                                        as="select"
                                                        value={idElearningProgram}
                                                        aria-label="Regio"
                                                        aria-describedby="basic-addon1"
                                                        className="my-tryout"
                                                        onChange={(e) => {
                                                            setIdElearningProgram(e.target.value)
                                                        }}
                                                    >
                                                        <option value="">--Pilih Program--</option>
                                                        {
                                                            programData.map((item, i) => {
                                                                return (
                                                                    <option selected={idElearningProgram == item.id} key={i} value={item.id}>{item.nama_program}</option>
                                                                )
                                                            })
                                                        }
                                                        
                                                    </Form.Select>
                                                </div>
                                                <div className="col-12 lg:px-4">
                                                    <label htmlFor="" className="label-tryout">Kapasitas</label> <br />
                                                    <Form.Control onChange={(e) => {
                                                        setKapasitas(e.target.value)
                                                    }} value={kapasitas} type="text" name="" className="form-tryout mb-3" id="" />
                                                </div>
                                            </div>
                                        </div>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <Button className="btn btn-dark" style={{ backgroundColor: '#00' }} onClick={handleUpdateClose}>
                                            Batal
                                        </Button>
                                        <Button className="btn btn-success" onClick={() => {
                                            updateKelas()
                                        }}>
                                            Submit
                                        </Button>
                                    </Modal.Footer>
                                </Modal>
                            </div>
                            <div className="col-md-12 col-lg-12 col-xs-12">
                                {/* <DataTable columns={columns} data={data} pagination /> */}
                                <CustomDatatable 
                                    filteredItems={kelasData} 
                                    columns={columns}
                                    isLoading={isLoading}
                                    page={page}
                                    perPage={perPage}
                                    hasPrev={hasPrev}
                                    hasNext={hasNext}
                                    count={count}
                                    onTableChange={(e, data) => {
                                        switch (e) {
                                        case 'rowPerPageChange':
                                            setPerPage(data.perPage)
                                            getListKelas(`page=${page}&perPage=${data.perPage}&keyword=${keyword}`)
                                            break;
                                        case 'rowPageChange':
                                            setPage(data.page)
                                            getListKelas(`page=${data.page}&perPage=${perPage}&keyword=${keyword}`)
                                            break;
                                        
                                        default:
                                            break;
                                        }
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </React.Fragment >

    )
}