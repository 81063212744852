import React, { useState, useEffect } from 'react';
import {
  SideBar
} from '../../../components/layout/dashboard';
import { useParams, useHistory } from "react-router-dom";
import { Link, useLocation} from 'react-router-dom';
import { BsArrowLeft } from 'react-icons/bs';
import Button from '@restart/ui/esm/Button';
import { addCommas, contactLink } from '../../../helpers/utils'
import ElearningService from '../../../services/elearning';
import moment from 'moment-timezone';
import Swal from 'sweetalert2';

export default function BayarKelas (props) {
  document.title = "INGENIO Indonesia";
  const [ programs, setPrograms ] = useState([]);
  const [dataTryout, setDataTryout] = useState({})
  const [dataSubTryout, setDataSubTryout] = useState([]);
  const [total, setTotal] = useState(0); 
  const [domicileAddress, setDomicileAddress] = useState(""); 
  const [isLoading, setIsLoading] = useState(false)

  const { dispatch } = props;

  function useQuery() {
    const { search } = useLocation();
  
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }

  const history = useHistory();
  const query = useQuery()

  const location = useLocation();
  const { kelas_id, nama_kelas, nama_regio, nama_program, biaya, is_package_include, biaya_pengiriman, service_pengiriman } = location.state

  const orderElearningKelas = (kelasId) => {
    setIsLoading(true)
    const body = {
      kelasId: kelasId,
      package_shipment: service_pengiriman
    }

    ElearningService.orderKelasParticipant(body).then(resp => {
      if(resp.data.success === true) {
        const response = resp.data

        ElearningService.getListElearningTransactionParticipant().then(trans => {
          const respTransactions = trans.data.data
          const id = respTransactions[0].id
          setIsLoading(false)

          window.location.replace(`/dashboard/elearning-payment-process?transaction_id=${id}`)

        }).catch(err => {
          console.log(err)
        })

        return;
      }
      setIsLoading(false)

    }).catch(err => {
      setIsLoading(false)
      let msg = 'Internal Server Error'
      console.error(err)
      if(err.response) {
          if(err.response.data.message) {
              msg = err.response.data.message
          }
      }

      Swal.fire({  
        title: 'Gagal Order',  
        text: msg + ', Silahkan hubungi admin',  
        icon: 'warning',  
        showCancelButton: true,  
        cancelButtonText: 'Kembali',           
        showConfirmButton: false,  
      });
    })
    
  }

  React.useEffect(() => {
    ElearningService.getStudentInfo().then(resp => {
      const profile = resp.data.data.profile
      setDomicileAddress(profile.domicile_address)
    }).catch(err => {
      let msg = 'Internal Server Error'
      console.error(err)
      if(err.response) {
          if(err.response.data.message) {
              msg = err.response.data.message
          }
      }

      Swal.fire({  
        title: 'Gagal Order',  
        text: msg + ', Silahkan hubungi admin',  
        icon: 'warning',  
        showCancelButton: true,  
        cancelButtonText: 'Kembali',           
        showConfirmButton: false,  
      });
    })
    
  })

  return(
    <React.Fragment>
      <SideBar />
      <div className="md:ml-64">
        <div className="bg-orange px-3 md:px-8 py-1 h-40">
          <div className="container md:pr-8 md:pl-10">
            <h3 className="text-left tracking-wider title-dashboard">Selamat datang di Kelas Bimbingan Intensif Ingenio Indonesia</h3>
          </div>           
        </div>

        <div className="px-3 md:px-8 -mt-24">
            <div className="container mx-auto max-w-full">
                <div className="grid grid-cols-2 xl:grid-cols-5">
                    <div className="xl:col-start-1 xl:col-end-4 px-4 mb-14">
                    <h3 className="text-left tracking-wider">Anda dapat menghubungi Admin terlebih dahulu untuk memastikan kelas dan program yang Anda daftarkan. <br /> <br />Kontak Admin <a href={contactLink()} target={'_blank'}> <i class="fa fa-whatsapp" aria-hidden="true"></i></a></h3>
                    </div>
                </div>
            </div>
        </div>

        <div className="px-3 md:px-8">
            <div className="container mx-auto max-w-full">
                <Link className="flex items-center breadcrumbss py-4" to=""> <BsArrowLeft size="25px"/> Kembali</Link>
                <div className="card-gede">
                    <div className="card-head px-4 py-4" >
                        Via Virtual Akun
                    </div>
                    <div className="row">
                        <div className="col-lg-6 col-md-6 col-xs-12 py-4 px-5">
                            <p style={{fontSize:"18px !important", lineHeight: "22px", letterSpacing: "0.06em", color: "#000000", fontWeight: 'bold'}}>Detail</p> <br />
                            <table style={{marginBottom: '15px'}}>
                                <tr>
                                    <td style={{padding: '0px 10px 0px 0px'}}>Nama Kelas E-Learning</td>
                                    <td style={{padding: '0px 10px 0px 0px'}}>:</td>
                                    <td style={{padding: '0px'}}><b>{nama_kelas}</b></td>
                                </tr>
                                <tr>
                                    <td style={{padding: '0px'}}>Program Bimbingan</td>
                                    <td style={{padding: '0px'}}>:</td>
                                    <td style={{padding: '0px'}}><b>{nama_program}</b></td>
                                </tr>
                                <tr>
                                    <td style={{padding: '0px'}}>Regio</td>
                                    <td style={{padding: '0px'}}>:</td>
                                    <td style={{padding: '0px'}}><b>{nama_regio}</b></td>
                                </tr>
                                {
                                  is_package_include ?
                                  <>
                                    <tr>
                                        <td style={{padding: '0px'}}>Paket pengiriman modul</td>
                                        <td style={{padding: '0px'}}>:</td>
                                        <td style={{padding: '0px'}}><b>{service_pengiriman}</b></td>
                                    </tr>
                                    <tr>
                                        <td style={{padding: '0px'}}>Biaya pengiriman modul</td>
                                        <td style={{padding: '0px'}}>:</td>
                                        <td style={{padding: '0px'}}><b>{'Rp'+addCommas(biaya_pengiriman)}</b></td>
                                    </tr>
                                    <tr>
                                        <td style={{padding: '0px'}}>Alamat Tujuan Pengiriman Modul</td>
                                        <td style={{padding: '0px'}}>:</td>
                                        <td style={{padding: '0px'}}><b>{domicileAddress}</b></td>
                                    </tr>
                                  </>
                                  :
                                  null
                                }
                                <tr>
                                    <td style={{padding: '0px'}}>Biaya Kelas</td>
                                    <td style={{padding: '0px'}}>:</td>
                                    <td style={{padding: '0px'}}><b>{'Rp'+addCommas(biaya)}</b></td>
                                </tr>
                            </table>
                            {/* <p className="text-black mb-3">Nama Kelas E-Learning : <b>{nama_kelas}</b></p>                     
                            <p className="text-black mb-3">Program Bimbingan : <b>{nama_program}</b></p>
                            <p className="text-black mb-4">Regio : <b>{nama_regio}</b></p>
                            <p className="text-black mb-4">Biaya Kelas : <b>{`Rp${biaya}`}</b></p> */}
                        </div>
                        <div className="col-lg-6 col-md-6 col-xs-12 py-4 px-5">
                            <p style={{fontSize:"18px !important", lineHeight: "22px", letterSpacing: "0.06em", color: "#000000", fontWeight: 'bold'}}>Metode Pembayaran ke Bank :</p> <br />
                            <label class="plan basic-plan" for="basic">
                            <input type="radio" name="plan" id="basic" />
                            <div class="plan-content">                              
                              <div class="plan-details">
                                <img loading="lazy" src="/assets/landing/Mandiri_logo 1.png" alt="" />
                                <p className="mt-2">Mandiri Virtual Account</p>
                              </div>
                            </div>
                          </label>
                        </div>
                        <div className="col-lg-6 col-md-6 col-xs-12 px-5">
                            <p style={{fontSize:"18px !important", lineHeight: "22px", letterSpacing: "0.06em", color: "#000000", fontWeight: 'bold'}}>* Termasuk Paket Tryout</p>
                            <br />
                          {
                            isLoading ? 
                            <Button onClick={() => {
                            }} className="pilih-tryout text-black my-3">Sending...</Button>
                            :
                            <Button onClick={() => {
                              if(!isLoading){
                                orderElearningKelas(kelas_id)  
                              }
                              
                            }} className="pilih-tryout text-black my-3">Lanjutkan</Button>
                          }
                          
                          {/* <Link className="pilih-tryout text-black my-3" to="/dashboard/e-learning/kelas-tersedia/status">Lanjutkan</Link> */}
                          <br /><br />
                          <Button onClick={() => {
                            window.open('https://ingenioindonesia.com/public/assets/panduan_pembayaran_va_ingenio.pdf', '_blank')
                          }} className="panduan my-3">Panduan Pembayaran</Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>      

      </div>
    </React.Fragment>
    
  )
}
