import React from 'react';
import styles from '../../index.module.css';
import { DaftarButton, PrestasiSlider } from './index';
import { Image, Card } from 'react-bootstrap';
import { FaWhatsapp } from 'react-icons/fa';
import { contactLink } from '../../helpers/utils'

export default function Prestasi() {
  return(
    <React.Fragment>
      <section className="container-fluid" style={{backgroundImage: 'url(/assets/landing/bg-prestasi.png)', backgroundSize: '100%', backgroundRepeat: 'no-repeat'}} id="#prestasi-section-container">
        <div className="container px-4 py-5">
          <div className="row align-items-center g-2 py-1 mb-4">
            <h1 className="text-center jd-home4" style={{fontWeight: 'bold'}}>Prestasi Peserta</h1>
            <h1 className="text-center jd-home4" style={{fontWeight: 'bold'}}><span className={styles.textYellow}>INGENIO INDONESIA</span></h1>
          </div>
          <div className="row align-items-center py-4">
            <div className="col-12 col-md-8 mx-auto">
              <PrestasiSlider />
            </div>
          </div>
          <DaftarButton />
        </div>
      </section>
      <section className="container-fluid" style={{backgroundImage: 'url(/assets/landing/beanstalk-dark.png)', backgroundSize: '100%', backgroundRepeat: 'repeat'}} id="#tutor-section-container">
        <div className="container px-4 py-5">
          <div className="row align-items-center g-2 py-1 mb-4">
            <h1 className="text-center jd-home2" style={{fontWeight: 'bold'}}>Tim Tutor <span className={styles.textYellow}>INGENIO</span></h1>
          </div>
          <div className="row row-tim-tutor no-gutters align-items-center py-4 mb-4">
            <div class="col-xl-6 col-lg-6 col-md-6 order-lg-1 order-1">
              <img src="assets/landing/tutor denpasar.png" style={{width: '100%', height: '96%', paddingTop: '2rem'}}></img>
            </div>
            <div class="col-xl-6 col-lg-6 col-md-6 order-lg-1 order-1">
              <img src="assets/landing/tutor malang.png" style={{width: '100%', paddingTop: '2rem'}}></img>
              <img src="assets/landing/tutor surabaya.png" style={{width: '100%', paddingTop: '2rem'}}></img>
            </div>
          </div>
          <div className="row align-items-center g-2 py-4 mb-4">
            <h1 className="kelebihan-text text-center">Tutor Terbaik Yang Kompeten, Unik, Professional dan Telah Terstandarisasi</h1>
          </div>
        </div>
      </section>
      <section className="container-fluid" id="#sistem-section-container">
        <div className="container px-4 py-5">
          <div className="row align-items-center g-2 py-1 mb-4">
            <h1 className="text-center jd-home2">Sistem Bimbingan <span className={styles.textYellow}>INGENIO</span></h1>
          </div>
          <div className="row align-items-center my-4" style={{marginBottom: '2.5rem'}}>
            <img src="/assets/landing/iso-2100.png" style={{maxWidth: '400px'}} className="mx-auto" alt="ISO 2100"></img>
            <h2 className="iso-text" style={{color: '#FFFFFF', marginLeft: 'auto', marginRight: 'auto', textAlign: 'center'}}>Seluruh sistem dan Bimbingan&nbsp;
                  <span className={styles.textYellow}>INGENIO</span> Indonesia mengimplementasikan<br/>
                  Internasional Standar Organization<br/>
                  ISO 21001:2018</h2>
          </div>
        </div>
        <div className="container py-5">
          <div className="row align-items-center my-4 pb-4">
            <div className="card flex-column mx-auto text-left" style={{background: "linear-gradient(90deg, #55CD6C 0%, #28FF52 100%)", borderRadius: '8px'}}>
              <div className="row p-1">
                <div className="col-12 col-md-8 p-3 ">
                  <div className="row no-gutters">
                    <div class="col-12 col-md-3 mb-3" style={{maxHeight: '80px'}}>
                      <FaWhatsapp color="white" className="whatsapp-contact_logo mx-auto" size={75}/>
                    </div>
                    <div class="col-12 col-md-9 mb-3 text-center" style={{maxHeight: '60px'}}>
                      <p style={{fontFamily: 'Quicksand', fontWeight: 600, fontSize: '1.2rem', lineHeight: '1.4rem', color: '#000', padding: '25px 0'}}>
                        Konsultasikan Kebutuhan Program Bimbinganmu Sekarang !
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-4" style={{maxHeight: '100px', padding: '25px 0'}}>
                  <a class="bt-m text-center" href={contactLink()} style={{background: 'white'}}><span class="bt-link" style={{color: 'black'}}>Contact Us</span></a>
                </div>
              </div>
            </div>
          </div>
          <div className="row align-items-center my-4 d-none d-lg-block text-center">
            <h4 style={{color: '#FFFFFF', fontSize: '1.25rem'}}><span className="ig-text">Instagram</span> : @ingenioindonesia  &nbsp;|&nbsp;  <span className="yt-text">Youtube</span> : Ingenio Indonesia  &nbsp;|&nbsp;  <span className="fb-text">Facebook</span> : Ingenio Learning centre</h4>
          </div>
          <div className="row align-items-center my-4 d-lg-none d-xs-block text-center">
            <h4 style={{color: '#FFFFFF', fontSize: '1.25rem'}}><span className="ig-text">Instagram</span> : @ingenioindonesia  &nbsp;<br/><br/>&nbsp;  <span className="yt-text">Youtube</span> : Ingenio Indonesia  &nbsp;<br/><br/>&nbsp;  <span className="fb-text">Facebook</span> : Ingenio Learning centre</h4>
          </div>
        </div>
        <div className="container px-4 py-5">
          <div className="row align-items-center my-4">
            <h2 className="about-text" style={{color: '#FFFFFF', width: '70%', marginLeft: 'auto', marginRight: 'auto', textAlign: 'center', lineHeight: '3.2rem'}}>
              Partnership:
            </h2>
            <div className="col-12">
              <img src="/assets/landing/danacita.png" style={{maxWidth: '200px'}} className="mx-auto" alt="ISO 2100"></img>
              <img src="/assets/landing/mitra-2.png" style={{maxWidth: '200px'}} className="mx-auto" alt="Mitra 2"></img>
              <img src="/assets/landing/mitra-3.png" style={{maxWidth: '200px'}} className="mx-auto" alt="Mitra 3"></img>
            </div>
          </div>
        </div>
      </section>
      
    </React.Fragment>
  )
}

