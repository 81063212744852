import React, { useState, useRef } from 'react';
import { FormGroup, FormControl, Form, Alert} from 'react-bootstrap';
import styles from '../../index.module.css';
import Swal from 'sweetalert2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { useForm, useFormState} from "react-hook-form";
import { useHistory, useLocation, Redirect } from "react-router-dom";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

import { connect } from "react-redux";
import { login } from "../../store/actions/auth";
import { clearMessage } from '../../store/actions/message';


function LoginTutor(props) {
    const validationSchema = Yup.object().shape({
        email: Yup.string()
          .required('Email is required')
          .email('Email is invalid'),
        password: Yup.string()
          .required('Password is required')
          .min(8, 'Password must be at least 6 characters')
          .max(40, 'Password must not exceed 40 characters')
          .test("isValidPass", "Password is not valid", (value, context) => {
            const hasUpperCase = /[A-Z]/.test(value);
            const hasNumber = /[0-9]/.test(value);
            let validConditions = 0;
            const numberOfMustBeValidConditions = 2;
            const conditions = [hasUpperCase, hasNumber];
            conditions.forEach((condition) =>
              condition ? validConditions++ : null
            );
            if (validConditions >= numberOfMustBeValidConditions) {
              return true;
            }
            return false;
          }),
    });
    const { register, handleSubmit, watch, reset, formState: { errors, isSubmitSuccessful, submitCount, isSubmitted } } = useForm({resolver: yupResolver(validationSchema)});

    const history = useHistory();
    const didMountRef = useRef(false);
    const [validated, setValidated] = useState(false);

    // const [loginError, setLoginError] = useState(false);
    // const [verifyError, setVerifyError] = useState(false);
    const [emailVerified, setEmailVerified] = useState('');

    const location = useLocation();

    const { isLoggedIn, message, dispatch } = props;

    React.useEffect(() => {
        if (didMountRef.current){
            if(!isSubmitSuccessful && submitCount > 0) {
                Fail();
            }
        } else {
            didMountRef.current = true;
        }
        
    }, [submitCount]);

    React.useEffect(() => {
        if(location.state) {
            if(!location.state.emailVerified) {
                setEmailVerified('');
                console.log(emailVerified)
            } else {
                setEmailVerified(location.state.emailVerified);
                console.log(emailVerified)
            }
        }
    }, [location.state])

    React.useEffect(() => {
        
    }, [message])

    const Column = {
        borderRadius: "10px",
        height: 30,
        padding: "25px",
    };

    const onSubmit = (data) => {
        dispatch(login(data))
        .then(() => {
            history.push("tutor/dashboard");
        })
        .catch((err) => {
            console.log(err);
        });
    };

    const Fail = () => {
        Swal.fire({  
            title: 'Data yang Anda isikan belum lengkap !',  
            text: 'Silahkan lengkapi data Anda',  
            icon: 'warning',  
            showCancelButton: true,  
            cancelButtonText: 'Kembali',           
            showConfirmButton: false,  
          });   
    }

    if (isLoggedIn) {
      return <Redirect to="/dashboard" />;
    }
    return (
        <React.Fragment>
            <section className="container-fluid bg-desktop" style={{ backgroundImage: 'url(/assets/landing/bg-racoon.jpg)' }}>
                <Form noValidate validated={validated} onSubmit={handleSubmit(onSubmit)}>
                    <div className="container px-4" style={{ paddingTop: '10rem' }}>
                        <div className="row align-items-center g-2 py-3">
                            <h1 className="text-center jd-home2 title-big">Masuk</h1>
                            <h5 className="text-regular">Pilihan Belajar dengan Tutor, Sistem dan Pola Pikir Terbaik<br />untuk persiapan ujianmu bersama INGENIO INDONESIA</h5>
                        </div>
                        <div className="row align-items-center">
                            {/* <div className="col-12 col-md-8 mx-auto py-3">
                                <h5 className="text-regular">Belum punya akun? <b><a href="/signup" style={{ color: 'black', textDecoration: 'underline' }}>Sign Up</a></b></h5>
                            </div> */}
                        </div>
                        { message &&
                        (<div className="row align-items-center">
                            <div className="col-12 col-md-8 col-lg-4 mx-auto pb-3">
                                <Alert variant="danger" onClose={() => dispatch(clearMessage())} dismissible>
                                    {message}
                                </Alert>
                            </div>
                        </div>)}
                        {/* { verifyError ?
                        <div className="row align-items-center">
                            <div className="col-12 col-md-8 col-lg-4 mx-auto pb-3">
                                <Alert variant="danger" onClose={() => setVerifyError(false)} dismissible>
                                    Account is not verified, Please check your email to verify
                                </Alert>
                            </div>
                        </div>
                        : null } */}
                        { emailVerified ?
                        <div className="row align-items-center">
                            <div className="col-12 col-md-8 col-lg-4 mx-auto pb-3">
                                <Alert variant="info" onClose={() => setEmailVerified('')} dismissible>
                                   {emailVerified} 
                                </Alert>
                            </div>
                        </div>
                        : null }
                        <div className="row align-items-center">
                            <div className="col-12 col-md-8 col-lg-4 mx-auto pb-3">
                                <FormGroup>
                                    <FormControl
                                        {...register("email")}
                                        placeholder="Username / Email"
                                        aria-label="Email"
                                        aria-describedby="basic-addon1"
                                        type="text"
                                        isInvalid={errors.email}
                                        style={Column}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.email?.message}
                                    </Form.Control.Feedback>
                                </FormGroup>
                            </div>
                        </div>
                        <div className="row align-items-center">
                            <div className="col-12 col-md-8 col-lg-4 mx-auto pb-3">
                                <FormGroup>
                                    <FormControl
                                        {...register("password")}
                                        placeholder="Password"
                                        aria-label="Password"
                                        aria-describedby="basic-addon1"
                                        type="password"
                                        isInvalid={errors.password}
                                        style={Column}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.password?.message}
                                    </Form.Control.Feedback>
                                </FormGroup>
                            </div>
                        </div>
                        <div className="row align-items-center">
                            <div className="col-12 col-md-8 mx-auto text-center pt-3">
                                <button className="bt-form-submit"><b>Masuk &nbsp; </b><FontAwesomeIcon icon={faArrowRight} /></button>
                            </div>
                        </div>
                        <div className="row align-items-center mx-auto">
                            {/* <div className="col-12 col-md-8 mx-auto text-center pt-3 mb-5">
                                <h5><a href="/forgetpassword" style={{ color: 'black' }}>Lupa Password?</a></h5>
                            </div> */}
                        </div>

                    </div>
                </Form>
            </section>
        </React.Fragment>
    )
}

function mapStateToProps(state) {
    const { isLoggedIn } = state.auth;
    const { message } = state.message;
    return {
        isLoggedIn,
        message
    };
}

export default connect(mapStateToProps)(LoginTutor);
