import Button from '@material-tailwind/react/Button';
import React, { useState, useEffect } from 'react';
import { FormGroup, Modal } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import { BsArrowLeftShort, BsFillFileEarmarkArrowUpFill } from 'react-icons/bs';
import { HiOutlineTrash, HiOutlinePencilAlt  } from 'react-icons/hi';
import { useLocation } from 'react-router';
import { useParams, useHistory, Link } from "react-router-dom";
import AkademikSidebar from '../../../../components/layout/dashboard/akademik/AkademikSideBar';
import CustomDatatable from '../../../../components/common/CustomDatatable';
import EcourseService from '../../../../services/ecourse';
import Swal from 'sweetalert2';
import moment from 'moment-timezone';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';


export default function KelolaVideo(props) {
    var baseUrl = window.location.origin;

    document.title = "INGENIO Indonesia";
    const [program, setProgram] = useState('');
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    // Filtering data
    const [isLoading, setIsLoading] = useState(true);
    const [hasNext, setHasNext] = useState(false)
    const [hasPrev, setHasPrev] = useState(false)
    const [count, setCount] = useState(0);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [keyword, setKeyword] = useState('');
    const [kelasData, setKelasData] = useState([]);

    const [fileData, setFileData] = useState(null);

    const [idContent, setIdContent] = useState(null);

    const [nameContent, setNameContent] = useState('');
    const [urlContent, setUrlContent] = useState('');

    const [bodyRequest, setBodyRequest] = useState(null);

    const location = useLocation();
    const { id_ecourse } = location.state;

    const dropdown = {
        borderRadius: "10px",
        height: 50,
        padding: "25px",
    };
    const columns = [
        {
            name: 'NO.',
            field: 'no',
            index: true,
            selector: row => row.no,
            sortable: true,
            minWidth: '1px'
            
        },
        {
            name: 'NAMA MATERI',
            field: 'name',
            selector: row => row.kelas,
            sortable: true,
            
        },
        {
            name: 'JENIS MATERI',
            field: 'name',
            selector: row => row.kelas,
            render: true,
            component: (row) => {
                return (
                    <td className='main-table__bodyRow'>
                        {
                            row.content_type !== 'video' ? 
                            'Document'
                            :
                            'Video'
                        }
                    </td>
                )
            },
            sortable: true,
            
        },
        {
            name: 'Aksi',
            render: true,
            component: (row) => {
                return (
                    <td className='main-table__bodyRow'>
                        <div style={{display: 'flex', flexDirection: 'row'}}>
                            <OverlayTrigger placement="top" overlay={<Tooltip id="button-tooltip-2">Edit</Tooltip>}>
                                <Link to={{
                                    pathname: `/dashboard/akademik/e-course/kelola-video`,
                                    state: {
                                        id_ecourse: row.id_ecourse,
                                    }
                                }} className="btnCircleBlack" onClick={() => {
                                    setIdContent(row.id)
                                    setNameContent(row.name)
                                    setUrlContent(row.url_content)
                                    handleShow()
                                }}><HiOutlinePencilAlt size="22"/>
                                </Link>
                            </OverlayTrigger>
                            <OverlayTrigger placement="top" overlay={<Tooltip id="button-tooltip-2">Delete</Tooltip>}>
                                <Link to={{
                                    pathname: `/dashboard/akademik/e-course/kelola-video`,
                                    state: {
                                        id_ecourse: row.id_ecourse,
                                    }
                                }} className="btnCircleBlack" onClick={() => {DeleteContent(row.id)}}><HiOutlineTrash size="22" />
                                </Link>
                            </OverlayTrigger>
                        </div>
                    </td>
                )
            },
            selector: row => row.tanggal,
            sortable: true,
            // width: '15vw',
        }
    ];
    // const columns = [
    //     {
    //         name: 'NO.',
    //         selector: row => row.no,
    //         sortable: true,
    //         width: '6.2vw',
    //     },
    //     {
    //         name: 'Nama Video',
    //         selector: row => row.nama,
    //         sortable: true,
    //         width: '40vw',
    //     },
    //     {
    //         name: 'Aksi',
    //         cell: () => <OverlayTrigger placement="top" overlay={<Tooltip id="button-tooltip-2">Edit</Tooltip>}><Link className="btnCircleBlack" onClick={handleShow}><HiOutlinePencilAlt size="22"/></Link></OverlayTrigger>,
    //         ignoreRowClick: true,
    //         allowOverflow: true,
    //         button: true,
    //         width: '3vw',
    //     },
    //     {
    //         cell: () => <OverlayTrigger placement="top" overlay={<Tooltip id="button-tooltip-2">Delete</Tooltip>}><Link className="btnCircleBlack" onClick={AreYouSure}><HiOutlineTrash size="22" /></Link></OverlayTrigger>,
    //         ignoreRowClick: true,
    //         allowOverflow: true,
    //         button: true,
    //         width: '5vw',
    //     },
    // ];
    const data = [
        {
            no: 1,
            nama: 'lorem ipsum',
        },
        {
            no: 2,
            nama: 'lorem ipsum dolor',
        },
        {
            no: 3,
            nama: 'lorem ipsum dolor sit amet',
        },
    ];

    const customStyles = {
        table: {
          style: {
            borderRadius: '15px',
            border: '1px solid #BDC1C9',
          },
        },
        head: {
          style: {
            fontSize: '1.25rem',
            textTransform: "uppercase",
            color: '#6B7280',
            backgroundColor: '#f3f4f6',
            border: '1px solid #BDC1C9',
            borderTopLeftRadius: '15px',
            borderTopRightRadius: '15px',
          },
        },
        pagination: {
          style: {
            color: '#6B7280',
            fontSize: '1.1rem',
            minHeight: '56px',
            backgroundColor: '#f3f4f6',
            border: '1.25px solid #BDC1C9',
            borderBottomLeftRadius: '15px',
            borderBottomRightRadius: '15px',
          },
        },
    };

    const AreYouSure = () => {
        Swal.fire({  
            title: 'Hapus Video ?',
            icon: 'warning',  
            showCancelButton: true,  
            cancelButtonText: 'Tidak',  
            confirmButtonText: 'Ya'  
        }).then((result) => {
            if (result.isConfirmed) {
              window.location.reload();
            }
        })
    }
    const Succeed = () => {
        Swal.fire({  
            title: 'Materi Berhasil Tersimpan !',  
            text: '',  
            icon: 'success',  
            showCancelButton: false,              
            confirmButtonText: 'OK'  
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.reload();
          }
        })
    }
    const Edit = () => {
        Swal.fire({  
            title: 'Materi Berhasil Tersimpan !',  
            text: '',  
            icon: 'success',  
            showCancelButton: false,              
            confirmButtonText: 'OK'  
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.reload();
          }
        })
    }

    const Fail = (message) => {
        Swal.fire({  
            title: 'Gagal!',  
            text: message,  
            icon: 'warning',  
            showCancelButton: true,  
            cancelButtonText: 'Kembali',           
            showConfirmButton: false,  
        });   
    }

    const DeleteContent = (id) => {

        Swal.fire({  
            title: 'Sudah Yakin?',  
            text: 'Apakah Anda Yakin Ingin Menghapus Data Ini?',  
            icon: 'warning',  
            showCancelButton: true,  
            cancelButtonText: 'Tidak',  
            confirmButtonText: 'Ya'  
        }).then((result) => {
          if (result.isConfirmed) {
            setIsLoading(true)
            EcourseService.deleteContentEcourseAkademik(id)
            .then(resp => {
                setIsLoading(false)
                const response = resp.data;
                Succeed()
    
            }).catch(err => {
                let msg = 'Internal Server Error'
                setIsLoading(false)
                console.error(err)
                if(err.response) {
                    if(err.response.data.message) {
                        msg = err.response.data.message
                    }
                }
    
                Fail(msg)
            })
          }
        });  
      }

    const UpdateContent = () => {
        let contentType = '-'
        Swal.fire({  
            title: 'Sudah Yakin?',  
            text: 'Apakah Anda Yakin Ingin Mengubah Data Ini?',  
            icon: 'warning',  
            showCancelButton: true,  
            cancelButtonText: 'Tidak',  
            confirmButtonText: 'Ya'  
        }).then((result) => {
          if (result.isConfirmed) {
              setIsLoading(true)
            if(fileData && fileData !== null) {
                const slideFileType = fileData.name.split('.').pop();
                const slideFilesize = fileData.size;
                if(!'mp4,doc,docx,ppt,pptx,xls,xlsx,pdf'.split(',').includes(slideFileType.toLowerCase())){
                    Fail('File harus Video atau Document');
                    return;
                }

                if(slideFilesize > 500000000) {
                    Fail('Materi harus kurang dari 500 MB');
                    return;
                }
    
                switch (slideFileType.toLowerCase()) {
                    case 'mp4':
                        contentType = 'video'
                        break;
                    case 'doc':
                        contentType = 'doc'
                        break;
                    case 'docx':
                        contentType = 'doc'
                        break;
                    case 'ppt':
                        contentType = 'doc'
                        break;
                    case 'pptx':
                        contentType = 'doc'
                        break;
                    case 'xls':
                        contentType = 'doc'
                        break;
                    case 'xlsx':
                        contentType = 'doc'
                        break;
                    case 'pdf':
                        contentType = 'pdf'
                        break;
                
                    default:
                        break;
                }
            }
            
            if(!fileData && fileData === null) {
                setBodyRequest({
                    name: nameContent,
                    is_active: true
                })
                return;
            }
    
            if(fileData && fileData !== null) {
                let formData = new FormData();
                formData.append('file', fileData);

                EcourseService.uploadFile(formData, (progressEvent) => {
    
                })
                .then(resp => {
                    const response = resp.data;
                    const url = response.data.url;
                    setUrlContent(url);

                    setBodyRequest({
                        name: nameContent,
                        content_type: contentType,
                        is_active: true
                    })
        
                }).catch(err => {
                    let msg = 'Internal Server Error'
                    setIsLoading(false)
                    console.error(err)
                    if(err.response) {
                        if(err.response.data.message) {
                            msg = err.response.data.message
                        }
                    }
        
                    Fail(msg)
                })
            }
            
          }
        });  
      }

    const AddContent = (id, body) => {
        let contentType = '-';
        if((!fileData === null ||fileData === null)){
            Fail('File harus diisi')
            return;
        }
        if(fileData && fileData !== null) {
            const slideFileType = fileData.name.split('.').pop();
            const slideFilesize = fileData.size;
            if(!'mp4,ppt,pptx'.split(',').includes(slideFileType.toLowerCase())){
                Fail('File harus Video atau Document');
                return;
            }

            if(slideFilesize > 500000000) {
                Fail('Materi harus kurang dari 500 MB');
                return;
            }

            switch (slideFileType.toLowerCase()) {
                case 'mp4':
                    contentType = 'video'
                    break;
                case 'ppt':
                    contentType = 'doc'
                    break;
                case 'pptx':
                    contentType = 'doc'
                    break;
            
                default:
                    break;
            }
        }
        Swal.fire({  
            title: 'Sudah Yakin?',  
            text: 'Apakah Anda Yakin Ingin Menambah Data Ini?',  
            icon: 'warning',  
            showCancelButton: true,  
            cancelButtonText: 'Tidak',  
            confirmButtonText: 'Ya'  
        }).then((result) => {
          if (result.isConfirmed) {
              setIsLoading(true)
            let formData = new FormData();
    
            if(fileData && fileData !== null)
                formData.append('file', fileData);
            
            EcourseService.uploadFile(formData, (progressEvent) => {
    
            })
            .then(resp => {
                const response = resp.data;
                const url = response.data.url;
                setUrlContent(url);
                EcourseService.addContentEcourseAkademik(id, {
                    ...body,
                    url_content: url,
                    content_type: contentType
                }).then(resp => {
                    Succeed()
                    setIsLoading(false)
                }).catch(err => {
                  let msg = 'Internal Server Error'
                  setIsLoading(false)
                  console.error(err)
                  if(err.response) {
                    if(err.response.data.message) {
                      msg = err.response.data.message
                    }
                  }
      
                  Fail(msg)
                })
    
            }).catch(err => {
                let msg = 'Internal Server Error'
                setIsLoading(false)
                console.error(err)
                if(err.response) {
                    if(err.response.data.message) {
                    msg = err.response.data.message
                    }
                }
    
                Fail(msg)
            })
          }
        });  
      }

      const getListKelas = (params = '') => {
        setIsLoading(true)
        EcourseService.getListEcourseContentAkademik(id_ecourse, params).then(resp => {
            const response = resp.data;
            setKelasData(response.data);

            setPage(response.meta.page)
            setPerPage(response.meta.perPage)
            setCount(response.meta.count)

            setHasNext(response.meta.hasNext)
            setHasPrev(response.meta.hasPrev);
            
            setIsLoading(false)
        }).catch(err => {
            let msg = 'Internal Server Error'
              setIsLoading(false)
              console.error(err)
              if(err.response) {
                if(err.response.data.message) {
                  msg = err.response.data.message
                }
              }
  
              Fail(msg)
        })
    }

    useEffect(() => {
        getListKelas();
    }, [])

    useEffect(() => {
        if(bodyRequest !== null) {
            EcourseService.updateContentEcourseAkademik(id_ecourse, idContent, {
                ...bodyRequest,
                url_content: urlContent
            }).then(resp => {
                Succeed()
                setIsLoading(false)
            }).catch(err => {
              let msg = 'Internal Server Error'
              setIsLoading(false)
              console.error(err)
              if(err.response) {
                if(err.response.data.message) {
                  msg = err.response.data.message
                }
              }
  
              Fail(msg)
            })
        }
    }, [bodyRequest])

    return (
        <React.Fragment>
            <AkademikSidebar />
            <div className="md:ml-64 tutorDataKelas" style={{ backgroundColor: '#F3F4F6' }}>
                <div className="bg-orange md:px-8 pb-5" style={{height: '20vh', position: 'relative', padding: '0 30px 30px 30px'}}>
                    <h1 className="tracking-wider title-24-600">Kelola Video</h1>
                    {/* <h2 className="tracking-wider title-20-700 mt-2">Upload Video Materi</h2> */}
                    <img src={baseUrl + "/assets/logo/evaluasikelas.png"} className="img-opacity"></img>
                </div>

                <div className="px-3 md:px-8 mt-4">
                    <div className="container mx-auto max-w-full pb-5">
                        <div className="justify-start mt-4 mb-3">
                            <Link to="/dashboard/akademik/e-course/semua">
                                <div className="navBack">
                                    <BsArrowLeftShort size="19px"/>Kembali
                                </div> 
                            </Link>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <div className="cards pb-4">
                                    <div className="row">
                                        <div className="col-12 flex gap-4" style={{padding: '2.4rem 4.3rem 0 4.3rem'}}>
                                            <img src={baseUrl + "/assets/img/upload-vid.jpg"} style={{width: '70px', marginBottom: '-10px'}} />
                                            <div className="title-24-700 mt-4">Upload Materi</div>
                                        </div>
                                        <div className="col-12 mb-4" style={{padding: '0 4.3rem'}}>
                                            <div className="borderBottomGrey"></div>
                                        </div>
                                    </div>
                                    <div className="row" style={{padding: '0 3.7rem'}}>
                                        <div className="col-12">
                                            <div className="titleForm">Nama File</div>
                                            <input value={nameContent} onChange={(e) => {setNameContent(e.target.value)}} type="text" class="formInput mb-4" placeholder="Masukkan nama..." required/>

                                            <div className="title-16-500">Upload File <span style={{fontSize: '10pt'}}>(mp4 / ppt - Maks. 500 MB)</span></div>
                                            <div className="relative mt-2">
                                                <input onChange={(e) => {
                                                    setFileData(e.target.files[0]);
                                                }} type="file" id="file" className="custom-file-input" style={{ position: 'absolute', left: '2rem' }} />
                                                <label htmlFor="file" className="labelFile">Upload Here<BsFillFileEarmarkArrowUpFill size="18px"/></label>
                                            </div>

                                            <div className="justify-end pb-3" style={{ marginTop: '4rem' }}>
                                                <button type="submit" onClick={() => {
                                                    AddContent(id_ecourse, {
                                                        id_ecourse: id_ecourse,
                                                        name: nameContent,
                                                    })
                                                }} disabled={isLoading} class="btnOrange3">{isLoading ? 'Saving...' : 'Submit'}</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 mt-3 mb-4">
                                <FormGroup>
                                    <div className="SearchTable">
                                        <input value={keyword} onChange={(e) => setKeyword(e.target.value)} type="text" name="" className="inputSearchTable" placeholder="&#x1F50E;&#xFE0E; Search..." id="" />
                                        <Button onClick={() => {
                                                getListKelas(`page=${page}&perPage=${perPage}&keyword=${keyword}`)
                                            }} className="btnSearchTable">Cari</Button>
                                    </div>
                                </FormGroup>
                            </div>
                        </div>
                        <div className="col-md-12 col-lg-12 col-xs-12 mb-5">
                            {/* <DataTable columns={columns} customStyles={customStyles} data={data} pagination /> */}
                            <CustomDatatable 
                                    filteredItems={kelasData} 
                                    columns={columns}
                                    isLoading={isLoading}
                                    page={page}
                                    perPage={perPage}
                                    hasPrev={hasPrev}
                                    hasNext={hasNext}
                                    count={count}
                                    onTableChange={(e, data) => {
                                        switch (e) {
                                        case 'rowPerPageChange':
                                            setPerPage(data.perPage)
                                            getListKelas(`page=${page}&perPage=${data.perPage}&keyword=${keyword}`)
                                            break;
                                        case 'rowPageChange':
                                            setPage(data.page)
                                            getListKelas(`page=${data.page}&perPage=${perPage}&keyword=${keyword}`)
                                            break;
                                        
                                        default:
                                            break;
                                        }
                                    }}
                                />
                        </div>
                        <Modal show={show} onHide={handleClose}
                            {...props}
                            size="md"
                            aria-labelledby="contained-modal-title-vcenter"
                            centered>
                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">
                                <div className="title-20-700">Edit Materi</div>
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="row p-4">
                                <div className="col-12">
                                    <div className="titleForm">Nama Materi</div>
                                    <input value={nameContent} onChange={(e) => {setNameContent(e.target.value)}} type="text" class="formInput mb-4" placeholder="Masukkan nama..." required/>

                                    <div className="title-16-500">Upload File <span style={{fontSize: '10pt'}}>(Maks. 500 MB)</span></div>
                                    <div className="relative mt-2">
                                        <input onChange={(e) => {
                                                    setFileData(e.target.files[0]);
                                                }} type="file" id="file2" className="custom-file-input" style={{ position: 'absolute', left: '2rem' }} />
                                        <label htmlFor="file2" className="labelFile">Upload Here<BsFillFileEarmarkArrowUpFill size="18px"/></label>
                                    </div>

                                    <div className="justify-end pb-3" style={{ marginTop: '4rem' }}>
                                        <button type="submit" onClick={() => {UpdateContent()}} class="btnOrange3">Submit</button>
                                    </div>
                                </div>
                            </div>
                        </Modal.Body>
                        </Modal>
                    </div>
                </div>

            </div>
        </React.Fragment >

    )
}