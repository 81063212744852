import Button from '@material-tailwind/react/Button';
import React, { useEffect, useState } from 'react';
import { FormGroup, FormControl, Form, Modal } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import { Link, useParams } from 'react-router-dom';
import { BsPencilSquare, BsFillTrashFill, BsFillPlusCircleFill, BsFillCloudArrowDownFill } from 'react-icons/bs';
import AkademikSidebar from '../../../../components/layout/dashboard/akademik/AkademikSideBar';
import ElearningService from '../../../../services/elearning';
import CustomDatatable from '../../../../components/common/CustomDatatable';
import moment from 'moment-timezone';
import Swal from 'sweetalert2';

export default function DetailRekapAbsensi() {
    document.title = "INGENIO Indonesia";
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    // Filtering data
    const [getColumns, setColumns] = useState([])
    const [isLoading, setIsLoading] = useState(true);
    const [hasNext, setHasNext] = useState(false)
    const [hasPrev, setHasPrev] = useState(false)
    const [count, setCount] = useState(0);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [keyword, setKeyword] = useState('');
    const [kelasData, setKelasData] = useState([]);
    const [downloading, setDownloading] = useState(false);

    const { id } = useParams();

    const dropdown = {
        borderRadius: "10px",
        height: 50,
        padding: "25px",
    };
    const columns = [
        {
            name: 'NO.',
            field: 'no',
            index: true,
            selector: row => row.no,
            sortable: true,
            width: '6.2vw',
        },
        {
            name: 'KODE PESERTA',
            field: 'kode_peserta',
            selector: row => row.kode,
            sortable: true,
            // width: '15vw',
        },
        {
            name: 'NAMA PESERTA',
            field: 'nama_peserta',
            selector: row => row.nama,
            sortable: true,
            // width: '15vw',   
        },
        {
            name: 'PERTEMUAN ke',
            render: true,
            component: (row) => {
              return (
                <td className='main-table__bodyRow'>
                    <div style={{width: '100%', display: 'flex', flexDirection: 'row'}}>
                        {
                            (row.detailKehadirans && row.detailKehadirans !== null) ?
                                (row.detailKehadirans.length > 0) ?
                                row.detailKehadirans.map((item, i) => {
                                    return (
                                        <div style={{display: 'flex', flexDirection: 'column', paddingRight: '25px'}}>
                                            <span>{item.pertemuan_ke}</span>
                                            <span>{item.status_absen === 'N/A' ? '-':item.status_absen}</span>
                                        </div>
                                    )
                                })
                                :
                                null
                            :
                            null
                        }
                    </div>
                </td>
              )
            },
            selector: row => row.p1,
            sortable: true,
            minWidth: '150px'
            // width: '13vw',
        },
        {
            name: 'Hadir',
            field: 'total_hadir',
            selector: row => row.hadir,
            sortable: true,
            // width: '8vw',
        },
        {
            name: 'Sakit',
            field: 'total_sakit',
            selector: row => row.sakit,
            sortable: true,
            // width: '8vw',
        },
        {
            name: 'Izin',
            field: 'total_izin',
            selector: row => row.izin,
            sortable: true,
            // width: '8vw',
        },
        {
            name: 'Alpa',
            field: 'total_alfa',
            selector: row => row.alfa,
            sortable: true,
            // width: '8vw',
        },
        {
            name: '%',
            render: true,
            component: (row) => {
              return (
                <td className='main-table__bodyRow'>
                    <span>{`${parseFloat(row.percent_kehadiran+'').toFixed(2)}%`}</span>
                </td>
              )
            },
            selector: row => row.percent,
            sortable: true,
            // width: '8vw',
        },
    ];
    const data = [
        {
            no: 1,
            kode: 'Lorem ipsum dolor.',
            nama: 'Lorem ipsum dolor.',
            p1: 'H',
            p2: 'H',
            p3: 'H',
        },
        {
            no: 2,
            kode: 'Lorem ipsum dolor.',
            nama: 'Lorem ipsum dolor.',
            p1: 'S',
            p2: 'S',
            p3: 'S',
        },
        {
            no: 3,
            kode: 'Lorem ipsum dolor.',
            nama: 'Lorem ipsum dolor.',
            p1: 'I',
            p2: 'I',
            p3: 'I',
        },
        {
            no: 4,
            kode: 'Lorem ipsum dolor.',
            nama: 'Lorem ipsum dolor.',
            p1: 'A',
            p2: 'A',
            p3: 'A',
        },
        {
            no: 5,
            kode: 'Lorem ipsum dolor.',
            nama: 'Lorem ipsum dolor.',
            p1: 'H',
            p2: 'H',
            p3: 'H',
        },
    ];

    const getListKelasParticipant = (params = '') => {
        setIsLoading(true)
        ElearningService.getListAbsensiKelasTutor(id, params).then(resp => {
            const response = resp.data;
            
            setKelasData(response.data);

            setPage(response.meta.page)
            setPerPage(response.meta.perPage)
            setCount(response.meta.count)

            setHasNext(response.meta.hasNext)
            setHasPrev(response.meta.hasPrev);
            
            setIsLoading(false)
        }).catch(err => {
            setIsLoading(false)
            console.error(err)
        })
    }

    const downloadRekapAbsensi = () => {
        setDownloading(true)
        ElearningService.downloadRekapAbsensiTutor(id)
          .then(resp => {
            const url = resp.data.data.url;
            window.location.replace(url)
            setDownloading(false)
          }).catch(err => {
            console.error(err)
            Swal.fire({  
              title: 'Gagal!',  
              text: 'Data tidak ditemukan',  
              icon: 'warning',  
              showCancelButton: true,  
              cancelButtonText: 'Kembali',           
              showConfirmButton: false,  
          });  
            setDownloading(false)
          })
      }

    useEffect(() => {
        getListKelasParticipant();
    }, [])

    return (
        <React.Fragment>
            <AkademikSidebar />
            <div className="md:ml-64" style={{ backgroundColor: '#F3F4F6' }}>
                <div className="bg-orange px-3 md:px-8 py-1 h-40">
                    <div className="container md:pr-8 md:pl-10">
                        <h3 className="text-left tracking-wider title-dashboard">Selamat Datang Staff Akademik INGENIO!</h3>
                    </div>
                </div>

                <div className="px-3 md:px-8 mt-5">
                    <div className="container mx-auto max-w-full">
                        <div className="row">
                            <div className="col-md-8 col-lg-4 col-xs-12 mb-4">
                                <FormGroup>
                                    <Button onClick={() => downloadRekapAbsensi()} className="pilih-tryout"><BsFillCloudArrowDownFill /> Unduh Rekap Absensi</Button>
                                </FormGroup>
                            </div>
                            <div className="col-md-12 col-lg-12 col-xs-12">
                                {/* <DataTable columns={columns} data={data} pagination /> */}
                                <CustomDatatable 
                                    filteredItems={kelasData} 
                                    columns={columns}
                                    isLoading={isLoading}
                                    page={page}
                                    perPage={perPage}
                                    hasPrev={hasPrev}
                                    hasNext={hasNext}
                                    count={count}
                                    onTableChange={(e, data) => {
                                        switch (e) {
                                        case 'rowPerPageChange':
                                            setPerPage(data.perPage)
                                            getListKelasParticipant(`page=${page}&perPage=${data.perPage}&keyword=${keyword}`)
                                            break;
                                        case 'rowPageChange':
                                            setPage(data.page)
                                            getListKelasParticipant(`page=${data.page}&perPage=${perPage}&keyword=${keyword}`)
                                            break;
                                        
                                        default:
                                            break;
                                        }
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment >

    )
}