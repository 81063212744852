import React, { useState, useEffect } from 'react';
import { Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar } from "@fortawesome/free-solid-svg-icons";
// import styles from '../../index.module.css';
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from 'swiper';

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import 'swiper/components/scrollbar/scrollbar.scss';

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);

export default function ArtikelSlider(props) {

    const [isMobile, setIsMobile] = useState(1)

    //choose the screen size 
    const handleResize = () => {
        if (window.innerWidth < 720) {
            setIsMobile(1)
        } else {
            setIsMobile(1)
        }
    }

    useEffect(() => {
        window.addEventListener("resize", handleResize)
    })

    return (
        <Swiper
            id="main"
            loop
            watchOverflow
            observer={'true'}
            spaceBetween={100}
            slidesPerView={isMobile}
            breakpoints={{
                768: {
                    slidesPerView: 2,
                },
            }}
            // navigation
            // scrollbar={{ draggable: true }}
            onSlideChange={() => console.log('slide change')}
        >
            <SwiperSlide>
                <Card className="article-card">
                    <a href="/artikel-select">
                        <Card.Img variant="top" src="/assets/landing/artikel-1.png" className="article-card-img" />
                    </a>
                    <Card.Body style={{ padding: '0rem', paddingTop: '1.5rem' }}>
                        <Card.Title>
                            <p className="txt24px" style={{ textAlign: 'left', color: 'black', lineHeight: '30px', fontWeight: 700, fontFamily: 'Quicksand' }}>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Tincidunt habitasse odio dui placerat ipsum amet ac justo.</p>
                            {/* <p style={{ textAlign: 'left', fontSize: '1rem', fontFamily: 'Quicksand', color: '#F8CA2B' }}><FontAwesomeIcon icon={faCalendar} /> &nbsp; 01 Des 2021</p> */}
                        </Card.Title>
                        <Card.Text>
                            <p className="txt20px" style={{ textAlign: 'left', color: '#666666', fontWeight: 700, fontFamily: 'Quicksand' }}>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed scelerisque viverra felis nibh quis. Mauris porta id turpis cursus est dui ac leo metus. Nunc, dolor, aliquam nulla nulla consequat pharetra. Tempus curabitur et neque gravida.</p>
                            <br />
                            {/* <div className="row">
                                <div className="col-2 artlogo">
                                    <img src="/assets/landing/art-logo.png" />
                                </div>
                                <div className="col-10" style={{ padding: '1rem' }}>
                                    <p style={{ textAlign: 'left', fontSize: '1rem', fontFamily: 'Quicksand', color: '#F8CA2B' }}>Name</p>
                                    <p style={{ textAlign: 'left', fontSize: '1rem', fontFamily: 'Quicksand', color: '#000000' }}><FontAwesomeIcon icon={faCalendar} /> &nbsp; 01 Des 2021</p>
                                </div>
                            </div> */}
                        </Card.Text>
                    </Card.Body>
                </Card>
            </SwiperSlide>
            <SwiperSlide>
                <Card className="article-card">
                    <a href="/artikel-select">
                        <Card.Img variant="top" src="/assets/landing/artikel-2.png" className="article-card-img" />
                    </a>
                    <Card.Body>
                        <Card.Title>
                            <p className="txt24px" style={{ textAlign: 'left', color: 'black', lineHeight: '30px', fontWeight: 700, fontFamily: 'Quicksand' }}>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                        </Card.Title>
                        <Card.Text>
                            <p className="txt20px" style={{ textAlign: 'left', color: '#666666', fontWeight: 700, fontFamily: 'Quicksand' }}>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed scelerisque viverra felis nibh quis. Mauris porta id turpis cursus est dui ac leo metus. Nunc, dolor, aliquam nulla nulla consequat pharetra. Tempus curabitur et neque gravida.</p>
                            <br />
                            {/* <div className="row">
                                <div className="col-2 artlogo">
                                    <img src="/assets/landing/art-logo.png" />
                                </div>
                                <div className="col-10 art-tx">
                                    <p style={{ textAlign: 'left', fontSize: '1rem', fontFamily: 'Quicksand', color: '#F8CA2B' }}>Name</p>
                                    <p style={{ textAlign: 'left', fontSize: '1rem', fontFamily: 'Quicksand', color: '#000000' }}><FontAwesomeIcon icon={faCalendar} /> &nbsp; 01 Des 2021</p>
                                </div>
                            </div> */}
                        </Card.Text>
                    </Card.Body>
                </Card>
            </SwiperSlide>
        </Swiper>
    )
}