import { useState } from 'react';
import { NavLink, Link } from 'react-router-dom';
import { BsGrid, BsFileEarmark, BsFileEarmarkText, BsPerson, BsInfo, BsInfoLg } from "react-icons/bs";
import { MdUpdate, MdOutlinePayments, MdOutlinePayment } from "react-icons/md";
import Dropdown from "@material-tailwind/react/Dropdown"
import { BiDollar } from "react-icons/bi";
import { TiWarningOutline } from "react-icons/ti";
import DropdownLink from "@material-tailwind/react/DropdownLink"
import AdminNav2 from './AdminNav2';

export default function AkademikSidebar({ judul }) {
    const logoFooter = {
        width: "100%",
        height: "auto",
    };
    const [showSidebar, setShowSidebar] = useState('-left-64');
    var baseUrl = window.location.origin;
    return (
        <>
            <AdminNav2
                judul={judul}
            />
            <div
                className={`h-screen fixed top-0 md:left-0 ${showSidebar} overflow-y-auto flex-row flex-nowrap overflow-hidden shadow-xl bg-white w-64 z-10 py-4 px-6 transition-all duration-300`}
            >
                <div className="flex-col items-stretch min-h-full flex-nowrap px-0 relative">
                    <NavLink
                        to="/dashboard/admin"
                        exact
                        className="mt-2 text-center w-full inline-block"
                    >
                        <img src={baseUrl + "/assets/logo/ingenio rakun 2.png"} alt="logo" style={logoFooter} />
                    </NavLink>
                    <div className="flex flex-col">
                        <hr className="my-4 min-w-full" />

                        <ul className="flex-col min-w-full flex list-none" style={{fontFamily: 'Quicksand'}}>
                            <li className="rounded-lg mb-2">
                                <NavLink
                                    to="/dashboard/admin"
                                    exact
                                    className="flex items-center gap-4 text-sm font-bold px-2 py-2 rounded-lg"
                                    activeClassName="SidebarActive"
                                >
                                    <BsGrid size="25px" />
                                    <span className="SidebarName">Dashboard</span>
                                </NavLink>
                            </li>
                            <li className="rounded-lg mb-2">
                                <Dropdown className="flex items-center gap-4 text-sm text-gray-700 font-light px-2 py-2 rounded-lg"
                                        activeClassName="SidebarActive"
                                        placement="bottom-start"
                                        buttonText={
                                            <div style={{display: 'inline-flex', fontWeight: 500}}>
                                                <BsFileEarmarkText style={{marginRight: '25px'}} size="25px" />
                                                Olah Data
                                            </div>
                                        }
                                        buttonType="link"
                                        size="regular"
                                        rounded={false}
                                        block={false}
                                        ripple="dark"
                                        style={{fontWeight: 'normal'}}>
                                    <DropdownLink href="/dashboard/admin/olah-regio">
                                        <div style={{display: 'inline-flex', fontWeight: 500}}>
                                            <BsFileEarmark style={{marginRight: '15px'}} size="20px" />
                                            Olah Regio
                                        </div>                                        
                                    </DropdownLink>
                                    <DropdownLink href="/dashboard/admin/olah-sub-regio">
                                        <div style={{display: 'inline-flex', fontWeight: 500}}>
                                            <BsFileEarmark style={{marginRight: '15px'}} size="20px" />
                                            Olah Sub Regio
                                        </div>                                        
                                    </DropdownLink>
                                    <DropdownLink href="/dashboard/admin/olah-program">
                                        <div style={{display: 'inline-flex', fontWeight: 500}}>
                                            <BsFileEarmark style={{marginRight: '15px'}} size="20px" />
                                            Olah Program TO
                                        </div>
                                    </DropdownLink>
                                    <DropdownLink href="/dashboard/admin/olah-tutor">
                                        <div style={{display: 'inline-flex', fontWeight: 500}}>
                                            <BsFileEarmark style={{marginRight: '15px'}} size="20px" />
                                            Olah Tutor
                                        </div>
                                    </DropdownLink>
                                    <DropdownLink href="/dashboard/admin/olah-kategori">
                                        <div style={{display: 'inline-flex', fontWeight: 500}}>
                                            <BsFileEarmark style={{marginRight: '15px'}} size="29px" />
                                            Olah Kategori E-Library
                                        </div>
                                    </DropdownLink>
                                    <DropdownLink href="/dashboard/admin/olah-library">
                                        <div style={{display: 'inline-flex', fontWeight: 500}}>
                                            <BsFileEarmark style={{marginRight: '15px'}} size="29px" />
                                            Olah Dokumen E-Library
                                        </div>
                                    </DropdownLink>
                                </Dropdown>
                            </li>
                            <li className="rounded-lg mb-2">
                                <Dropdown className="flex items-center gap-4 text-sm text-gray-700 font-light px-2 py-2 rounded-lg"
                                        activeClassName="SidebarActive"
                                        placement="bottom-start"
                                        buttonText={
                                            <div style={{display: 'inline-flex', fontWeight: 500}}>
                                                <BsInfoLg style={{marginRight: '25px'}} size="25px" />
                                                Informasi
                                            </div>
                                        }
                                        buttonType="link"
                                        size="regular"
                                        rounded={false}
                                        block={false}
                                        ripple="dark"
                                        style={{fontWeight: 'normal'}}>
                                    <div className="cardsScroll scrollY" style={{ height: '50vh'}} id="scrollBar">
                                        {/* <div className="cardsScroll scrollY"> */}
                                        {/* <DropdownLink href="/dashboard/admin/informasi-kelas">
                                            <div style={{display: 'inline-flex', fontWeight: 500}}>
                                                <BsInfo style={{marginRight: '12px'}} size="24px" />
                                                Informasi Kelas
                                            </div>                                        
                                        </DropdownLink> */}
                                        <DropdownLink href="/dashboard/admin/informasi-tutor">
                                            <div style={{display: 'inline-flex', fontWeight: 500}}>
                                                <BsInfo style={{marginRight: '12px'}} size="24px" />
                                                Informasi Tutor
                                            </div>                                        
                                        </DropdownLink>
                                        {/* <DropdownLink href="/dashboard/admin/informasi-data-peserta">
                                            <div style={{display: 'inline-flex', fontWeight: 500}}>
                                                <BsInfo style={{marginRight: '12px'}} size="32px" />
                                                Informasi Data Peserta
                                            </div> */}
                                        <Dropdown className="flex text-left gap-4 text-sm text-gray-700 px-2 py-2 rounded-lg"
                                            activeClassName="SidebarActive"
                                            placement="bottom-start"
                                            buttonText={
                                                <div style={{display: 'inline-flex',fontSize: '13.5px', fontWeight: 500, lineHeight: '18px',width: '140px',marginRight: '-16px'}}>
                                                    <BsInfo style={{marginRight: '10px'}} size="34px" />
                                                    Informasi Data Peserta
                                                </div>
                                            }
                                            buttonType="link"
                                            size="regular"
                                            rounded={false}
                                            block={false}
                                            ripple="dark"
                                            style={{fontWeight: 'normal'}}>
                                            <DropdownLink>
                                                <Link to="/dashboard/admin/informasi-data-peserta/tryout">
                                                    <div style={{ display: 'inline-flex' }}>
                                                        <BsInfo style={{ marginRight: '15px' }} size="28px" />
                                                        Data Peserta TryOut
                                                    </div>
                                                </Link>
                                            </DropdownLink>
                                            <DropdownLink>
                                                <Link to="/dashboard/admin/informasi-data-peserta/elearning">
                                                    <div style={{ display: 'inline-flex' }}>
                                                        <BsInfo style={{ marginRight: '15px' }} size="34px" />
                                                        Data Peserta E-Learning
                                                    </div>
                                                </Link>
                                            </DropdownLink>
                                            <DropdownLink>
                                                <Link to="/dashboard/admin/informasi-data-peserta/ecourse">
                                                    <div style={{ display: 'inline-flex' }}>
                                                        <BsInfo style={{ marginRight: '15px' }} size="34px" />
                                                        Data Peserta E-Course
                                                    </div>
                                                </Link>
                                            </DropdownLink>
                                        </Dropdown>                                        
                                        <DropdownLink href="/dashboard/admin/informasi-testimoni">
                                            <div style={{display: 'inline-flex', fontWeight: 500}}>
                                                <BsInfo style={{marginRight: '12px'}} size="28px" />
                                                Informasi Testimoni
                                            </div>                                        
                                        </DropdownLink>
                                        <DropdownLink href="/dashboard/admin/informasi-upload-data">
                                            <div style={{display: 'inline-flex', fontWeight: 500}}>
                                                <BsInfo style={{marginRight: '12px'}} size="36px" />
                                                Informasi Upload Data
                                            </div>                                        
                                        </DropdownLink>
                                        <DropdownLink href="/dashboard/admin/informasi-logistik">
                                            <div style={{display: 'inline-flex', fontWeight: 500}}>
                                                <BsInfo style={{marginRight: '12px'}} size="28px" />
                                                Informasi Logistik
                                            </div>                                        
                                        </DropdownLink>
                                        <DropdownLink href="/dashboard/admin/informasi-ecourse">
                                            <div style={{display: 'inline-flex', fontWeight: 500}}>
                                                <BsInfo style={{marginRight: '12px'}} size="28px" />
                                                Informasi E-Course
                                            </div>                                        
                                        </DropdownLink>
                                        <DropdownLink href="/dashboard/admin/informasi-tryout">
                                            <div style={{display: 'inline-flex', fontWeight: 500}}>
                                                <BsInfo style={{marginRight: '12px'}} size="28px" />
                                                Informasi TryOut
                                            </div>                                        
                                        </DropdownLink>
                                        <DropdownLink href="/dashboard/admin/informasi-elearning">
                                            <div style={{display: 'inline-flex', fontWeight: 500}}>
                                                <BsInfo style={{marginRight: '12px'}} size="28px" />
                                                Informasi E-learning
                                            </div>                                        
                                        </DropdownLink>
                                    </div>
                                </Dropdown>
                            </li>
                            <li className="rounded-lg mb-2">
                                <NavLink
                                    to="/dashboard/admin/users"
                                    exact
                                    className="flex items-center gap-4 text-sm font-bold px-2 py-2 rounded-lg"
                                    activeClassName="SidebarActive"
                                >
                                    <BsPerson size="25px" />
                                    <span className="SidebarName">Users</span>
                                </NavLink>
                            </li>
                            <li className="rounded-lg mb-2">
                                <Dropdown className="flex items-center gap-4 text-sm text-gray-700 font-light px-2 py-2 rounded-lg"
                                        activeClassName="SidebarActive"
                                        placement="bottom-start"
                                        buttonText={
                                            <div style={{display: 'inline-flex', fontWeight: 500}}>
                                                <MdOutlinePayments style={{marginRight: '25px'}} size="25px"/>
                                                <span className="SidebarName">Payment</span>
                                            </div>
                                        }
                                        buttonType="link"
                                        size="regular"
                                        rounded={false}
                                        block={false}
                                        ripple="dark">
                                    <DropdownLink>
                                        <Link to="/dashboard/admin/payment/ecourse">
                                            <div style={{display: 'inline-flex'}}>
                                                <MdOutlinePayment style={{marginRight: '15px', fontWeight: 500}} size="20px" />
                                                E-Course
                                            </div> 
                                        </Link>                                     
                                    </DropdownLink>
                                    <DropdownLink>
                                        <Link to="/dashboard/admin/payment/elearning">
                                            <div style={{display: 'inline-flex'}}>
                                                <MdOutlinePayment style={{marginRight: '15px', fontWeight: 500}} size="20px" />
                                                E-Learning
                                            </div> 
                                        </Link>                                     
                                    </DropdownLink>
                                    <DropdownLink>
                                        <Link to="/dashboard/admin/payment/tryout">
                                            <div style={{display: 'inline-flex'}}>
                                                <MdOutlinePayment style={{marginRight: '15px', fontWeight: 500}} size="20px" />
                                                TryOut
                                            </div> 
                                        </Link>                                     
                                    </DropdownLink>
                                </Dropdown>
                            </li>
                            {/* <li className="rounded-lg mb-2">
                                <NavLink
                                    to="/dashboard/admin/transactions"
                                    exact
                                    className="flex items-center gap-4 text-sm font-bold px-2 py-2 rounded-lg"
                                    activeClassName="SidebarActive"
                                >
                                    <BiDollar size="25px" />
                                    <span className="SidebarName">Transactions</span>
                                </NavLink>
                            </li> */}

                            <li className="rounded-lg mb-2">
                                <NavLink
                                    to="/dashboard/admin/update-landing-page"
                                    exact
                                    className="flex items-center gap-4 text-sm font-bold px-2 py-2 rounded-lg"
                                    activeClassName="SidebarActive"
                                >
                                    <MdUpdate size="25px" />
                                    <span className="SidebarName">Update Data Landing</span>
                                </NavLink>
                            </li>
                            <li className="rounded-lg mb-2">
                                <NavLink
                                    to="/dashboard/admin/pengumuman"
                                    exact
                                    className="flex items-center gap-4 text-sm font-bold px-2 py-2 rounded-lg"
                                    activeClassName="SidebarActive"
                                >
                                    <TiWarningOutline size="25px" />
                                    <span className="SidebarName">Pengumuman</span>
                                </NavLink>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    );
}
