import React from 'react';
import {
    ProgramUtb,
} from '../components/programutb';
import {
    IngenioFooterGreen,
    IngenioNavGreen
} from '../components/layout'

export default function ProgramUtbPage() {
    return (
        <React.Fragment>
            <IngenioNavGreen />
            <div style={{ backgroundColor: 'white' }}>
                <ProgramUtb />
            </div>
            <IngenioFooterGreen />
        </React.Fragment>

    )
}