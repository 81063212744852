import React from 'react';
import { Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar } from "@fortawesome/free-solid-svg-icons";


export default function ArticleCard (props) {
  return (
    <React.Fragment>
      <Card className="article-card">
        <Card.Img variant="top" src="/assets/landing/article-template.png" className="article-card-img"/>
        <Card.Body>
          <Card.Title>
            <p style={{textAlign: 'left', fontSize: '1rem', fontFamily: 'Quicksand', color: '#F8CA2B'}}><FontAwesomeIcon icon={faCalendar} /> &nbsp; 01 Des 2021</p>
          </Card.Title>
          <Card.Text>
            <h1 style={{textAlign: 'left', color: 'black', fontWeight: 700, fontFamily: 'Quicksand'}}>Article Title</h1>
          </Card.Text>
        </Card.Body>
      </Card>
    </React.Fragment>
  )
}
