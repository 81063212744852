import React, { useState } from 'react';

export default function ProgramUkmppd() {

    return (
        <React.Fragment>
            <section className="container-fluid">
                <div className="site-header site-header--menu-left landing-11-menu site-header--absolute" style={{ backgroundPositionY: 'bottom', backgroundPositionX: 'right', backgroundRepeat: 'no-repeat', paddingTop: '6rem', backgroundColor: '#ffcc00' }}>
                    <div className="py-5" style={{ paddingBottom: '2rem !important' }}>
                        <h5 className="sub-title text-center">Kelas Bimbingan</h5>
                        <h5 className="tentang_title g-title">Ujian Kompetensi Mahasiswa <br />Program Pendidikan Dokter</h5>

                    </div>
                </div>
            </section>
            <section className="container-fluid bg-desktop">
                <div className="container px-4" style={{ paddingTop: '10rem', width: '85%', paddingBottom: '5rem' }}>
                    <div className="row g-2" style={{ textAlign: 'left', paddingTop: '16rem' }}>
                        <div className="col-lg-6 col-md-12">
                            <img src="/assets/landing/ukmppd-1.png" />
                        </div>
                        <div className="col-lg-6 col-md-12 mt5r prl5r">
                            <h5 className="tdt36px balooc2" style={{ textDecoration: 'none', lineHeight: '50px' }}>Bimbingan CBT UKMPPD (Online)</h5>
                            <h5 className="txt20px mt1r" style={{ marginBottom: '1rem', lineHeight: '30px', fontFamily: 'Quicksand' }}>Merupakan program bimbingan unggulan di Ingenio yang mempersiapkan mahasiswa klinik Kedokteran menghadapi CBT UKMPPD secara daring (online)
                            </h5>
                        </div>
                    </div>
                    <h5 className="txt24px mt1r" style={{ marginBottom: '0.2rem', lineHeight: '30px', fontFamily: 'Quicksand' }}>Dengan berbagai fasilitas penunjang yaitu:</h5>
                    <h5 className="txt20px mt1r" style={{ marginBottom: '1rem', lineHeight: '30px', fontFamily: 'Quicksand' }}><strong>a</strong>. Fase Intensif (Materi dan Pola pikir) 26 x pertemuan (@5-6 jam)<br />
                        <strong>b</strong>.	Fase Cepat (Pembahasan Soal) 12 x (@3-4 jam)<br />
                        <strong>d</strong>.	Tryout Online 3x (@150 soal)<br />
                        <strong>e</strong>.	Tryout Suplementasi 6 paket (12 materi)<br />
                        {/* <strong>f</strong>.	Fase Review 12x (2-3 jam)<br /> */}
                        <strong>f</strong>.	Soal Prediksi 12 materi (825 soal)<br />
                        <strong>g</strong>.	Buku Modul UKMPPD Eksklusif Full Colour Ingenio 5 buah<br />
                        <strong>h</strong>.	Kaos eksklusif Ingenio<br />
                        <strong>i</strong>.	Sling Bag Ingenio<br />
                        <strong>j</strong>.	Tutor pilihan Ingenio yang telah terstandarisasi nasional<br />
                        <strong>k</strong>.	Konsultasi tutor selama masa bimbingan

                    </h5>
                    <a href="https://drive.google.com/file/d/12ugYpwjTTzvrU_1xq2t891AyPtxzLElo/view?usp=sharing" className="txt20px" style={{ color: '#FCA61F', textDecoration: 'underline', lineHeight: '30px', fontFamily: 'Quicksand' }}>Download Brosur UKMPPD
                    </a>
                    <div className="row g-2" style={{ textAlign: 'left', paddingTop: '2.5rem' }}>
                        <div className="col-lg-7 col-md-12 mt5r">
                            <h5 className="tdt36px balooc2 w75p" style={{ textDecoration: 'none', lineHeight: '51px', fontFamily: 'Baloo Chettan 2' }}>Bimbingan CBT UKMPPD (Offline)</h5>
                            <h5 className="txt20px mt1r w65p" style={{ marginBottom: '1rem', lineHeight: '30px', fontFamily: 'Quicksand' }}>Merupakan program bimbingan unggulan di Ingenio di regio tertentu yang mempersiapkan mahasiswa klinik Kedokteran menghadapi CBT UKMPPD secara luring (offline)
                            </h5>
                            <h5 className="txt24px mt5r" style={{ marginBottom: '0.2rem', lineHeight: '30px', fontFamily: 'Quicksand' }}>Dengan berbagai fasilitas penunjang yaitu:</h5>
                            <h5 className="txt20px" style={{ marginBottom: '1rem', lineHeight: '30px', fontFamily: 'Quicksand' }}><strong>a</strong>.	Fase Intensif (Materi dan Pola pikir) 17-19x pertemuan (@5-8 jam)<br />
                                <strong>b</strong>.	Fase Cepat (Pembahasan Soal) 12 x (@3-5 jam)<br />
                                {/* <strong>c</strong>.	Fase penerapan pola pikir yang<br /> */}
                                <strong>c</strong>.	Tryout Online 3x (@150 soal)<br />
                                <strong>d</strong>.	Soal Prediksi 12 materi (825 soal)<br />
                                <strong>e</strong>.	Buku Modul UKMPPD Eksklusif Full Colour Ingenio 5 buah<br />
                                <strong>f</strong>.	Kaos eksklusif Ingenio<br />
                                <strong>g</strong>.	Sling Bag Ingenio<br />
                                <strong>h</strong>.	Kelas tatap muka, sistematis dan interaktif<br />
                                <strong>i</strong>.	Tutor pilihan Ingenio yang telah terstandarisasi nasional<br />
                                <strong>j</strong>.	Konsultasi tutor selama masa bimbingan<br />
                                <strong>k</strong>.	Ruang kelas nyaman ber-AC dan proyektor


                            </h5>
                        </div>
                        <div className="col-lg-5 col-md-12 mt2r">
                            <img src="/assets/landing/ukmppd-2.png" />
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment >
    )
}

