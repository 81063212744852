import { useState } from 'react';
import { NavLink, Link } from 'react-router-dom';
import { BsUpload, BsFillGridFill, BsTv, BsBookHalf, BsPen, BsFileEarmarkText, BsFillEaselFill, BsPersonFill, BsCameraVideoFill, BsFile, BsPeople, BsFillCameraReelsFill } from "react-icons/bs";
import { MdOutlineMonitor } from "react-icons/md";
import Dropdown from "@material-tailwind/react/Dropdown"
import DropdownLink from "@material-tailwind/react/DropdownLink"
import AkademikNav from './AkademikNav';

export default function AkademikSidebar({ judul }) {
    const logoFooter = {
        width: "100%",
        height: "auto",
    };
    const [showSidebar, setShowSidebar] = useState('-left-64');
    var baseUrl = window.location.origin;
    return (
        <>
            <AkademikNav
                judul={judul}
                showSidebar={showSidebar}
                setShowSidebar={setShowSidebar}
            />
            <div
                className={`h-screen fixed top-0 md:left-0 ${showSidebar} overflow-y-auto flex-row flex-nowrap overflow-hidden shadow-xl bg-white w-64 z-10 py-4 px-6 transition-all duration-300`}
            >
                <div className="flex-col items-stretch min-h-full flex-nowrap px-0 relative">
                    <NavLink
                        to="/dashboard/akademik"
                        exact
                        className="mt-2 text-center w-full inline-block"
                    >
                        <img src={baseUrl + "/assets/logo/ingenio rakun 2.png"} alt="logo" style={logoFooter} />
                    </NavLink>
                    <div className="flex flex-col">
                        <hr className="my-4 min-w-full" />

                        <ul className="flex-col min-w-full flex list-none" style={{fontFamily: 'Quicksand'}}>
                            <li className="rounded-lg mb-2">
                                <NavLink
                                    to="/dashboard/akademik"
                                    exact
                                    className="flex items-center gap-4 text-sm font-bold px-2 py-2 rounded-lg"
                                    activeClassName="SidebarActive"
                                >
                                    <BsFillGridFill size="25px" />
                                    <span className="SidebarName">Dashboard</span>
                                </NavLink>
                            </li>
                            <li className="rounded-lg mb-2">
                                <Dropdown 
                                    className="flex items-center gap-4 text-sm font-medium px-2 py-2 rounded-lg"
                                    activeClassName="bg-gradient-to-tr from-light-blue-500 to-light-blue-700 text-white shadow-md"
                                    placement="bottom-start"
                                    buttonText={
                                        <div style={{ display: 'inline-flex' }}>
                                            <BsPen style={{ marginRight: '25px' }} size="25px" />
                                            TryOut
                                        </div>
                                    }
                                    buttonType="link"
                                    size="regular"
                                    rounded={false}
                                    block={false}
                                    ripple="dark">
                                    <DropdownLink>
                                        <Link to="/dashboard/akademik/tryout-list">
                                            <div style={{display: 'inline-flex'}}>
                                                <BsPen style={{marginRight: '15px'}} size="20px" />
                                                Semua Tryout
                                            </div> 
                                        </Link>                                   
                                    </DropdownLink>
                                    <DropdownLink style={{paddingRight: '0.5rem', paddingLeft: '0.5rem'}}>
                                        <Link to="/dashboard/akademik/buat-tryout">
                                            <div style={{ display: 'inline-flex' }}>
                                                <BsPen style={{ marginRight: '15px' }} size="20px" />
                                                Buat Tryout
                                            </div>
                                        </Link>
                                    </DropdownLink>
                                    {/* <DropdownLink href="/dashboard/tryout-saya">
                                        <div style={{ display: 'inline-flex' }}>
                                            <BsPen style={{ marginRight: '15px' }} size="20px" />
                                            Edit Tryout
                                        </div>
                                    </DropdownLink> */}
                                    <DropdownLink style={{paddingRight: '0.5rem', paddingLeft: '0.5rem'}}>
                                        <Link to="/dashboard/akademik/bank-tryout">
                                        <div style={{display: 'inline-flex'}}>
                                            <BsPen style={{marginRight: '15px'}} size="20px" />
                                            Bank Tryout
                                        </div>      
                                        </Link>                                  
                                    </DropdownLink>
                                    <DropdownLink style={{paddingRight: '0.5rem', paddingLeft: '0.5rem'}} >
                                        <Link to="/dashboard/akademik/data-peserta">
                                            <div style={{ display: 'inline-flex' }}>
                                                <BsPen style={{ marginRight: '15px' }} size="20px" />
                                                Data Peserta
                                            </div>
                                        </Link>
                                    </DropdownLink>
                                    <DropdownLink 
                                        style={{paddingRight: '0.5rem', paddingLeft: '0.5rem'}}
                                    >
                                        <Link to="/dashboard/akademik/history">
                                            <div style={{ display: 'inline-flex' }}>
                                                <BsPen style={{ marginRight: '15px' }} size="20px" />
                                                History Tryout
                                            </div>
                                        </Link>
                                    </DropdownLink>
                                    <Dropdown className="flex items-center gap-4 text-sm px-2 py-2 rounded-lg"
                                        activeClassName="bg-gradient-to-tr from-light-blue-500 to-light-blue-700 text-white shadow-md"
                                        placement="bottom-start"
                                        buttonText={
                                            <div style={{ display: 'inline-flex' }}>
                                                <BsFillGridFill style={{ marginRight: '15px' }} size="20px" />
                                                Kelola Data
                                            </div>
                                        }
                                        buttonType="link"
                                        size="regular"
                                        rounded={false}
                                        block={false}
                                        ripple="dark"
                                        style={{ fontWeight: 'normal' }}
                                    >
                                        <DropdownLink>
                                            <Link to="/dashboard/akademik/kompetensi">
                                                <div style={{ display: 'inline-flex' }}>
                                                    <BsPen style={{ marginRight: '15px' }} size="20px" />
                                                    Kompetensi
                                                </div>
                                            </Link>
                                        </DropdownLink>
                                        <DropdownLink>
                                            <Link to="/dashboard/akademik/topik">
                                            <div style={{ display: 'inline-flex' }}>
                                                <BsPen style={{ marginRight: '15px' }} size="20px" />
                                                Topik
                                            </div>
                                            </Link>
                                        </DropdownLink>
                                        <DropdownLink>
                                            <Link to="/dashboard/akademik/bidang">
                                                <div style={{ display: 'inline-flex' }}>
                                                    <BsPen style={{ marginRight: '15px' }} size="20px" />
                                                    Bidang
                                                </div>
                                            </Link>
                                        </DropdownLink>
                                    </Dropdown>
                                </Dropdown>
                            </li>
                            <li className="rounded-lg mb-2 ">
                                <Dropdown 
                                    className="flex items-center gap-4 text-sm font-medium px-2 py-2 rounded-lg"
                                    activeClassName="bg-gradient-to-tr from-light-blue-500 to-light-blue-700 text-white shadow-md"
                                    placement="bottom-start"
                                    buttonText={
                                        <div style={{ display: 'inline-flex' }}>
                                            <BsBookHalf style={{ marginRight: '25px' }} size="25px" />
                                            E-Learning
                                        </div>
                                    }
                                    buttonType="link"
                                    size="regular"
                                    rounded={false}
                                    block={false}
                                    ripple="dark">
                                    <div className="cardsScroll scrollY" style={{ height: '50vh'}} id="scrollBar">
                                        <DropdownLink>
                                            <Link to="/dashboard/akademik/e-learning/olah-program">
                                                <div style={{ display: 'inline-flex' }}>
                                                    <BsPen style={{ marginRight: '15px' }} size="20px" />
                                                    Olah Program
                                                </div>
                                            </Link>
                                            
                                        </DropdownLink>
                                        <DropdownLink>
                                            <Link to="/dashboard/akademik/e-learning/olah-kelas">
                                                <div style={{ display: 'inline-flex' }}>
                                                    <BsPen style={{ marginRight: '15px' }} size="20px" />
                                                    Olah Kelas
                                                </div>
                                            </Link>
                                        </DropdownLink>
                                        <DropdownLink>
                                            <Link to="/dashboard/akademik/e-learning/olah-jadwal">
                                                <div style={{ display: 'inline-flex' }}>
                                                    <BsPen style={{ marginRight: '15px' }} size="20px" />
                                                    Olah Jadwal
                                                </div>
                                            </Link>
                                        </DropdownLink>
                                        <DropdownLink>
                                            <Link to="/dashboard/akademik/e-learning/olah-materi">
                                            <div style={{ display: 'inline-flex' }}>
                                                <BsPen style={{ marginRight: '15px' }} size="20px" />
                                                Olah Materi
                                            </div>
                                            </Link>
                                        </DropdownLink>
                                        <Dropdown className="flex items-center gap-4 text-sm font-medium px-2 py-2 rounded-lg"
                                            activeClassName="bg-gradient-to-tr from-light-blue-500 to-light-blue-700 text-white shadow-md"
                                            placement="bottom-start"
                                            buttonText={
                                                <div style={{ display: 'inline-flex' }}>
                                                    <BsPeople style={{ marginRight: '15px' }} size="20px" />
                                                    Data Peserta
                                                </div>
                                            }
                                            buttonType="link"
                                            size="regular"
                                            rounded={false}
                                            block={false}
                                            ripple="dark"
                                            style={{ fontWeight: 'normal' }}
                                        >
                                            <DropdownLink>
                                                <Link to="/dashboard/akademik/e-learning/aktivasi-peserta">
                                                    <div style={{ display: 'inline-flex' }}>
                                                        Aktivasi Peserta
                                                    </div>
                                                </Link>
                                            </DropdownLink>
                                            <DropdownLink>
                                                <Link to="/dashboard/akademik/e-learning/profile-peserta">
                                                    <div style={{ display: 'inline-flex' }}>
                                                        Profile Peserta
                                                    </div>
                                                </Link>
                                            </DropdownLink>

                                            {/* <DropdownLink href="/dashboard/akademik/topik">
                                                <div style={{ display: 'inline-flex' }}>
                                                    <BsPen style={{ marginRight: '15px' }} size="20px" />
                                                    Topik
                                                </div>
                                            </DropdownLink>
                                            <DropdownLink href="/dashboard/akademik/bidang">
                                                <div style={{ display: 'inline-flex' }}>
                                                    <BsPen style={{ marginRight: '15px' }} size="20px" />
                                                    Bidang
                                                </div>
                                            </DropdownLink> */}
                                        </Dropdown>
                                        <DropdownLink href="/dashboard/akademik/e-learning/rekap-absensi">
                                            <div style={{ display: 'inline-flex' }}>
                                                <BsFileEarmarkText style={{ marginRight: '15px' }} size="20px" />
                                                Rekap Absensi
                                            </div>
                                        </DropdownLink>
                                        <DropdownLink href="/dashboard/akademik/e-learning/evaluasi-tutor">
                                            <div style={{ display: 'inline-flex' }}>
                                                <BsPersonFill style={{ marginRight: '15px' }} size="20px" />
                                                Evaluasi Tutor
                                            </div>
                                        </DropdownLink>
                                        <DropdownLink href="/dashboard/akademik/e-learning/jurnal-mengajar">
                                            <div style={{ display: 'inline-flex' }}>
                                                <BsFileEarmarkText style={{ marginRight: '15px' }} size="20px" />
                                                Jurnal Mengajar
                                            </div>
                                        </DropdownLink>
                                        {/* <DropdownLink href="/dashboard/akademik/e-learning/aktivasi-video">
                                            <div style={{ display: 'inline-flex' }}>
                                                <BsFillCameraReelsFill style={{ marginRight: '15px' }} size="20px" />
                                                Aktivasi Video
                                            </div>
                                        </DropdownLink> */}
                                    </div>
                                </Dropdown>
                            </li>
                            <li className="rounded-lg mb-2 ">
                                <Dropdown 
                                    className="flex items-center gap-4 text-sm font-medium 0 px-2 py-2 rounded-lg"
                                    activeClassName="bg-gradient-to-tr from-light-blue-500 to-light-blue-700 text-white shadow-md"
                                    placement="bottom-start"
                                    buttonText={
                                        <div style={{ display: 'inline-flex' }}>
                                            <BsTv style={{ marginRight: '25px' }} size="25px" />
                                            E-Course
                                        </div>
                                    }
                                    buttonType="link"
                                    size="regular"
                                    rounded={false}
                                    block={false}
                                    ripple="dark">
                                    <DropdownLink>
                                        <Link to="/dashboard/akademik/e-course/semua">
                                            <div style={{ display: 'inline-flex' }}>
                                                <MdOutlineMonitor style={{ marginRight: '15px' }} size="20px" />
                                                Semua E-Course
                                            </div>
                                        </Link>
                                    </DropdownLink>
                                    <DropdownLink>
                                        <Link to={{
                                                    pathname: `/dashboard/akademik/e-course/olah`,
                                                    state: {
                                                        id_ecourse: null,
                                                        isUpdate: false, 
                                                        kode_ecourse: null, 
                                                        name: null, 
                                                        price: null, 
                                                        desc: null, 
                                                        url_thumbnail: null
                                                    }
                                                }}>
                                            <div style={{ display: 'inline-flex' }}>
                                                <MdOutlineMonitor style={{ marginRight: '15px' }} size="20px" />
                                                Olah E-Course
                                            </div>
                                        </Link>
                                    </DropdownLink>
                                    <DropdownLink>
                                        <Link to="/dashboard/akademik/e-course/evaluasi-ecourse">
                                            <div style={{ display: 'inline-flex' }}>
                                                <MdOutlineMonitor style={{ marginRight: '15px' }} size="20px" />
                                                Evaluasi E-Course
                                            </div>
                                        </Link>
                                    </DropdownLink>
                                    {/* <DropdownLink>
                                        <Link to="">
                                            <div style={{ display: 'inline-flex' }}>
                                                <MdOutlineMonitor style={{ marginRight: '15px' }} size="20px" />
                                                Evaluasi E-Course
                                            </div>
                                        </Link>
                                    </DropdownLink> */}
                                </Dropdown>
                            </li>
                            <li className="rounded-lg mb-2">
                                <NavLink
                                    to="/dashboard/akademik/upload-data"
                                    className="flex items-center gap-4 text-sm font-bold px-2 py-2 rounded-lg"
                                    activeClassName="SidebarActive"
                                >
                                    <BsUpload size="25px" />
                                    <span className="SidebarName">Upload Data</span>
                                </NavLink>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    );
}
