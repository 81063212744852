import React from 'react';
import {
    Values,
} from '../components/values';
import {
    IngenioNav2,
    IngenioFooter2
} from '../components/layout'
import values from 'postcss-modules-values';

export default function ValuesPage() {
    return (
        <React.Fragment>
            <IngenioNav2 />
            <div style={{ backgroundColor: 'white' }}>
                <Values />
            </div>
            <IngenioFooter2 />
        </React.Fragment>

    )
}