import React from 'react';
import {
  IngenioNav,
  IngenioFooter
} from '../components/layout'
import {
  Content
} from '../components/forgetpass'

export default function LupaPasswordPage () {
  document.title = "INGENIO Indonesia | Lupa Password";
  return(
    <React.Fragment>
      <IngenioNav />
        <div style={{backgroundColor: 'white'}}>
          <Content />
        </div>
      <IngenioFooter bgColor="white"/>
    </React.Fragment>
  )
}