import Button from '@material-tailwind/react/Button';
import React, { useState, useEffect } from 'react';
import { FormGroup, FormControl, Form, Modal, Row, Col } from 'react-bootstrap';
import Swal from 'sweetalert2';
import { NavLink, useLocation, useHistory, useParams } from 'react-router-dom';
import { BsChevronLeft, BsChevronRight } from 'react-icons/bs';

import { getSubtryoutDetail } from '../../../../store/actions/tryout';
import { connect } from 'react-redux';
import moment from 'moment';
import { set } from 'immutable';

function ReviewSubTO(props) {
    document.title = "INGENIO Indonesia | Review Subtryout";
    document.body.style.backgroundColor = "#F3F4F6";
    const [detailSubTO, setDetailSubTO] = useState({});
    const [viewHasilLab, setViewHasilLab] = useState('0');
    const [detailTO, setDetailTO] = useState({});
    const [bankSoal, setBankSoal] = useState([]);
    const [duration, setDuration] = useState('');
    const [timer, setTimer] = useState(0);
    const [selectedNumber, setSelectedNumber] = useState(1);
    const [soal, setSoal] = useState('');
    const [pembahasan, setPembahasan] = useState('');
    const [jawaban, setJawaban] = useState([]);
    const [gambarSoal, setGambarSoal] = useState(null);
    const [gambarPembahasan, setGambarJawaban] = useState(null);
    const [hasNext, setHasNext] = useState(true);
    const [hasPrevious, setHasPrevious] = useState(true);
    const [jumlahSoal, setJumlahSoal] = useState([]);
    const [chosenJawaban, setChosenJawaban] = useState('');
    const [idBank, setIdBank] = useState(0);

    const [selectedSoal, setSelectedSoal] = useState({});

    const dropdown = {
        borderRadius: "10px",
        height: 50,
        padding: "25px",
    };

    function useQuery() {
        const { search } = useLocation();

        return React.useMemo(() => new URLSearchParams(search), [search]);
    }
    const query = useQuery();

    const { dispatch } = props;
    let { id, subtryoutId } = useParams();
    const history = useHistory();

    useEffect(() => {
        setSelectedNumber(1)
        setHasPrevious(false)
        dispatch(getSubtryoutDetail(subtryoutId))
            .then(async (response) => {
                await setDetailSubTO(response.data)
                await setDetailTO(response.data.tryout)
                await setBankSoal(response.data.subtryoutBanksoal);
                await setChosenJawaban('')
                setHasNext(true);
                setSelectedSoal(response.data.subtryoutBanksoal[0].banksoal)
                setJawaban(response.data.subtryoutBanksoal[0].banksoal.jawaban)
                setHasPrevious(false);
            })
    }, [])

    const gantiSoal = (nomor) => {
        setSelectedNumber(nomor)
        setChosenJawaban('')
        if (nomor !== bankSoal.length) {
            setHasNext(true);
        } else {
            setHasNext(false);
        }
        setSelectedSoal(bankSoal[nomor - 1].banksoal)
        setJawaban(bankSoal[nomor - 1].banksoal.jawaban)

        if (nomor !== 1) {
            setHasPrevious(true);
        } else {
            setHasPrevious(false);
        }
    }

    return (
        <React.Fragment>
            <div className="fill-window" style={{ backgroundColor: '#F3F4F6', }}>
                <nav className="bg-orange py-2 px-3">
                    <div className="container max-w-full mx-auto flex items-center justify-between md:pr-8 md:pl-10">
                        <div className="flex justify-between items-center w-full">
                            <NavLink
                                to={'/dashboard/akademik/tryout/' + id + '/soal-editor/' + subtryoutId}
                                className="flex items-center gap-4 text-md text-black font-bold px-2 py-2 rounded-lg"
                                style={{ fontFamily: 'Quicksand' }}
                            >
                                <BsChevronLeft style={{ stroke: "black", strokeWidth: "1" }} />
                                Kembali
                            </NavLink>
                        </div>
                    </div>
                </nav>
                <div className="container mx-auto max-w-full flex" style={{ padding: '2rem' }}>
                    <div className="flex-2 w-9/12 mx-3">
                        <div
                            className="container flex"
                        >
                            <div
                                className="flex-1 flex-grow"
                                style={{
                                    backgroundColor: '#FFF',
                                    padding: '1rem',
                                    borderRadius: '1rem',
                                    boxShadow: '0 0 8px 0 #aaa'
                                }}
                            >
                                <h3 className="text-left tracking-wider title-dashboard" style={{ fontSize: '1rem', color: '#000' }}>
                                    Kompetensi : {selectedSoal.kompetensi ? selectedSoal.kompetensi.nama : "-"}  /  Topik : {selectedSoal.topik ? selectedSoal.topik.nama : "-"} /  Bidang : {selectedSoal.bidang ? selectedSoal.bidang.nama : "-"}
                                </h3>
                            </div>
                        </div>
                        <FormGroup className="container flex">
                            <div
                                style={{
                                    backgroundColor: '#FFF',
                                    padding: '1rem',
                                    marginTop: '1rem',
                                    borderRadius: '1rem',
                                    boxShadow: '0 0 8px 0 #aaa'
                                }}
                                className="flex-1 flex-grow"
                            >
                                <div class="flex flex-col p-4">
                                    <div className="flex flex-row justify-between">
                                        <div class="flex">
                                            <h3 className="text-left tracking-wider title-dashboard" style={{ fontSize: '1.5rem', color: '#6B7280', fontWeight: 700 }}>Soal {selectedNumber}</h3>
                                        </div>
                                        <div class="flex">
                                            {
                                                hasPrevious ? <button
                                                    className="mr-2"
                                                    style={{ background: '#F8CA2B', fontWeight: 700, color: '#000', borderRadius: '8px', padding: '0.5rem' }}
                                                    onClick={() => gantiSoal(selectedNumber - 1)}
                                                >
                                                    <BsChevronLeft style={{ fontWeight: 'heavy', strokeWidth: "1", stroke: 'black' }} />
                                                </button> : <button className="mr-2" disabled style={{ background: '#e5e7eb', fontWeight: 700, color: '#000', borderRadius: '8px', padding: '0.5rem' }}>
                                                    <BsChevronLeft style={{ fontWeight: 'heavy', strokeWidth: "1", stroke: 'black' }} />
                                                </button>
                                            }
                                            {
                                                hasNext ? <button
                                                    className="mr-2"
                                                    style={{ background: '#F8CA2B', fontWeight: 700, color: '#000', borderRadius: '8px', padding: '0.5rem' }}
                                                    onClick={() => gantiSoal(selectedNumber + 1)}
                                                >
                                                    <BsChevronRight style={{ fontWeight: 'heavy', strokeWidth: "1", stroke: 'black' }} />
                                                </button> : <button className="mr-2" disabled style={{ background: '#e5e7eb', fontWeight: 700, color: '#000', borderRadius: '8px', padding: '0.5rem' }}>
                                                    <BsChevronRight style={{ fontWeight: 'heavy', strokeWidth: "1", stroke: 'black' }} />
                                                </button>
                                            }
                                        </div>
                                    </div>
                                    <div className="soal my-3" style={{ fontFamily: 'Quicksand', fontSize: '1.1rem' }} dangerouslySetInnerHTML={{ __html: selectedSoal.soal }}>
                                    </div>
                                    {
                                        selectedSoal.gambar_soal ? <img src={selectedSoal.gambar_soal} alt={"Gambar soal " + selectedNumber} style={{ maxWidth: '25rem' }} /> : null
                                    }
                                    <div class="my-2">
                                        {
                                            jawaban.map((i, idx) =>
                                                <div className="mr-3" key={idx}>
                                                    <label class="inline-flex items-center">
                                                        <div className="flex">
                                                            <div className="round rounded-full py-3 mr-2">
                                                                <button
                                                                    class="false items-center justify-center gap-1 outline-none tracking-wider focus:outline-none focus:shadow-none transition-all duration-300 rounded-lg py-1.5 px-3 text-sm leading-normal border border-solid shadow-none border-black hover:border-black undefined mr-3"
                                                                    style={{ position: 'relative', overflow: 'hidden', fontFamily: 'Quicksand', backgroundColor: (selectedSoal.answer_key == i.key ? '#22C55E' : '#EF4444'), color: 'white', fontWeight: 600, width: '3rem' }}
                                                                >
                                                                    {i.key.toLowerCase()}.
                                                                </button>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <span class="ml-2">{i.jawaban}</span>
                                                            {
                                                                i.gambar && i.gambar !== null ?
                                                                <img src={i.gambar} alt={"Gambar soal"} style={{ maxWidth: '25rem' }} />
                                                                :
                                                                null
                                                            }
                                                        </div>
                                                        
                                                        
                                                    </label>
                                                </div>
                                            )
                                        }
                                    </div>
                                    {
                                        detailSubTO.hasil_laboratorium ?
                                            <div style={{ paddingTop: '1rem' }} className="flex-1 flex-grow">
                                                <Form.Select
                                                    as="select"
                                                    aria-label="Program"
                                                    aria-describedby="basic-addon1"
                                                    className="lab flex"
                                                    onChange={(e) => {
                                                        setViewHasilLab(e.target.value)
                                                    }}
                                                >
                                                    <option value="0">Tutup Nilai Normal Laboratorium</option>
                                                    <option value="1">Lihat Nilai Normal Laboratorium</option>
                                                </Form.Select>
                                            </div> : null
                                    }
                                    {
                                        viewHasilLab === '1' ?
                                            detailSubTO.hasil_laboratorium ? 
                                                <div style={{ paddingTop: '1rem' }} className="flex-1 flex-grow">
                                                    <h2><strong>Hasil Lab: </strong></h2>
                                                    <img src={detailSubTO.hasil_laboratorium} alt={"Gambar hasil lab " + selectedNumber} style={{ maxWidth: '25rem' }} />
                                                </div> : null
                                        :
                                        null
                                    }
                                    {
                                        selectedSoal.gambar_pembahasan ?
                                            <div style={{ paddingTop: '1rem' }} className="flex-1 flex-grow">
                                                <h2><strong>Pembahasan: </strong></h2>
                                                <div>{selectedSoal.pembahasan}</div>
                                                <img src={selectedSoal.gambar_pembahasan} alt={"Gambar soal " + selectedNumber} style={{ maxWidth: '25rem' }} />
                                            </div> : null
                                    }

                                </div>

                            </div>
                        </FormGroup>
                    </div>
                    <div
                        className="box flex-1 w-3/12 mx-3 p-3"
                        style={{ borderRadius: '1rem', backgroundColor: '#FFF', boxShadow: '0 0 8px 0 #aaa' }}
                    >
                        <h3 className="text-left tracking-wider title-dashboard" style={{ fontSize: '1.2rem', color: '#000' }}>Soal</h3>
                        <div className="container">
                            <div
                                className="grid grid-cols-4"
                            >
                                {bankSoal.map((i, idx) => (
                                    selectedNumber === idx + 1 ? <button
                                        className='mx-1'
                                        style={{
                                            backgroundColor: '#F8CA2B',
                                            fontFamily: 'Quicksand',
                                            fontStyle: 'normal',
                                            fontWeight: 'bold',
                                            fontSize: '1rem',
                                            lineHeight: '1.25rem',
                                            letterSpacing: '0.06em',
                                            color: 'black',
                                            borderRadius: '8px',
                                            padding: '10px',
                                            marginTop: '0.7rem',
                                        }}
                                        key={idx}
                                        onClick={() => gantiSoal(idx + 1)}>
                                        {idx + 1}
                                    </button> : <button
                                        className='mx-1'
                                        style={{
                                            backgroundColor: '#E5E7EB',
                                            fontFamily: 'Quicksand',
                                            fontStyle: 'normal',
                                            fontWeight: 'bold',
                                            fontSize: '1rem',
                                            lineHeight: '1.25rem',
                                            letterSpacing: '0.06em',
                                            color: 'black',
                                            borderRadius: '8px',
                                            padding: '10px',
                                            marginTop: '0.7rem',
                                        }}
                                        key={idx}
                                        onClick={() => gantiSoal(idx + 1)}>
                                        {idx + 1}
                                    </button>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment >

    )
}

export default connect()(ReviewSubTO)