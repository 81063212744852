import React, { useEffect } from 'react';
import { useState } from 'react';
import { useParams, useHistory } from "react-router-dom";
import { FormGroup, FormControl, Form } from 'react-bootstrap';
import Button from '@material-tailwind/react/Button';
// import InputIcon from '@material-tailwind/react/InputIcon'
import { BsPlusCircle, BsList, BsPen, BsFillTrashFill } from 'react-icons/bs';
import { BiSave } from 'react-icons/bi';
import { FaPen, FaClock } from 'react-icons/fa';
import AkademikSidebar from '../../../../components/layout/dashboard/akademik/AkademikSideBar';
import Swal from 'sweetalert2';
import moment from 'moment';

import { connect } from "react-redux";
import { createSubtryout, getAllProgram, getSubtryoutByTO, getTryoutDetail, deleteSubtryout, aktivasi } from "../../../../store/actions/tryout";

function DetailTryOut(props) {
    document.title = "INGENIO Indonesia";
    document.body.style.backgroundColor = "#F3F4F6";

    const [programs, setPrograms] = useState({});
    const [dataTryout, setDataTryout] = useState({})
    const [dataSubTryout, setDataSubTryout] = useState([]);
    const [isTambah, setIsTambah] = useState(false);
    const [namaSubTO, setNamaSubTO] = useState('');
    var baseUrl = window.location.origin;

    // FILTERING DATA
    const [filterText, setFilterText] = React.useState('');
    const [filterObj, setFilterObj] = React.useState({
        text: '',
    })
    const onFilter = (data) => {
        setFilterText(data)
    }
    const onFilterSubmit = (e) => {
        e.preventDefault();
        let obj = {
            text: filterText,
        }
        setFilterObj(obj)
    }

    const filteredItems = dataSubTryout.filter(
        item => (item.nama_subtryout && item.nama_subtryout.toLowerCase().includes(filterObj.text.toLowerCase()))
    );
    // END FILTERING DATA

    const { dispatch } = props;
    let { id } = useParams();

    const history = useHistory();

    const deleteSub = (idsub) => {
        Swal.fire({
            title: 'Anda Yakin?',
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: 'Kembali',
            confirmButtonText: 'Ya, Hapus!'
        })
            .then((result) => {
                if (result.isConfirmed) {
                    dispatch(deleteSubtryout(idsub))
                        .then((res) => {
                            window.location.reload();
                        })
                }
            });
    }


    function submitSubTO() {
        let payload = {
            id_tryout: id,
            nama_subtryout: namaSubTO
        }

        dispatch(createSubtryout(payload))
            .then((response) => {
                setNamaSubTO('')
                setIsTambah(false)
            }).then(() => {
                dispatch(getSubtryoutByTO(payload.id_tryout))
                    .then((response) => {
                        setDataSubTryout(response.data)
                    })
            });
    }

    useEffect(() => {
        dispatch(getTryoutDetail(id))
            .then((response) => {
                const { subtryout, program, ...tryout } = response.data
                setDataSubTryout(subtryout)
                setPrograms(program)
                setDataTryout({ ...tryout })
            });
    }, []);

    const AreYouSure = (data) => {
        Swal.fire({
            title: 'Anda Yakin?',
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: 'Kembali',
            confirmButtonText: data ? 'Ya, Aktivasi!' : 'Ya, Deaktivasi!',
        }).then((result) => {
            if (result.isConfirmed) {
                let payload = {
                    is_active: data,
                }
                
                dispatch(aktivasi(id, payload))
                    .then((resp) => {
                        dispatch(getTryoutDetail(id))
                        .then((response) => {
                            const { subtryout, program, ...tryout } = response.data
                            setDataSubTryout(subtryout)
                            setPrograms(program)
                            setDataTryout({ ...tryout })
                        });
                    })
                    .catch((err) => {
                        console.log('error')
                    })
            }
        });
    }

    return (
        <React.Fragment>
            <AkademikSidebar judul={"Detail TryOut - " + dataTryout.nama_tryout} />
            <div className="md:ml-64" style={{ backgroundColor: '#F3F4F6' }}>
                <div className="bg-orange px-3 md:px-8 py-1 h-10">
                    <div className="container md:pr-8 md:pl-10">
                    </div>
                </div>
                <div className="px-3 md:px-8 mt-5">
                    <div className="container mx-auto flex sm:flex-col-reverse md:flex-row max-w-full">
                        <div className="flex-auto mb-4">
                            <div className="row">
                                <div className="col-xs-12 col-md-6 col-lg-6">
                                    <form onSubmit={(e) => onFilterSubmit(e)}>
                                        <div className="row" style={{ backgroundColor: '#FFF', padding: '1rem', borderRadius: '1rem' }}>
                                            <div className="col-8">
                                                <input
                                                    type="text"
                                                    name=""
                                                    style={{
                                                        backgroundColor: '#F3F4F6',
                                                        width: '100%',
                                                        borderRadius: '8px',
                                                        border: 'none !important',
                                                        height: '45px',
                                                        paddingLeft: '10px'
                                                    }}
                                                    placeholder="&#x1F50E;&#xFE0E; Nama Sub TryOut"
                                                    id=""
                                                    value={filterText}
                                                    onChange={(e) => onFilter(e.target.value)}
                                                />
                                            </div>
                                            <div className="col-4">
                                                <Button type="submit" className="pilih-tryout" onClick={(e) => onFilterSubmit(e)}>Cari</Button>
                                            </div>

                                        </div>

                                    </form>
                                </div>
                                <div className="col-xs-12 col-md-5 sm:text-center " style={{ padding: '1rem' }}>
                                    <button
                                        className="text-md rounded-lg font-bold px-3 py-2 flex items-center focus:outline-none"
                                        style={{ color: '#F8CA2B', fontFamily: 'Quicksand', backgroundColor: '#Fff' }}
                                        onClick={() => setIsTambah(true)}
                                    >
                                        Tambah Sub Tryout Baru
                                        <span className="pl-4" style={{ color: '#F8CA2B' }}>
                                            <BsPlusCircle style={{ stroke: "#F8CA2B", strokeWidth: "0.5" }} />
                                        </span>
                                    </button>
                                </div>
                                {
                                    filteredItems.length < 1 && !isTambah ? <div className="col-12">
                                        <div className="container md:pr-0 md:pl-0 py-4 my-3">
                                            <h3 className="text-left tracking-wider title-dashboard" style={{ fontSize: '1rem', fontStyle: 'Quicksand', color: '#6B7280' }}>Data tidak ditemukan, silahkan tambah sub tryout.</h3>
                                        </div>
                                    </div> : null
                                }
                                {isTambah ? <div className="mt-3" style={{ paddingRight: '10rem' }}>
                                    <div className="max-w-full md:pr-0 md:pl-0">
                                        <div className="flex flex-row" style={{ backgroundColor: '#FFFF', padding: '1rem', borderRadius: '15px' }}>
                                            <div className="flex flex-1">
                                                <input
                                                    type="text"
                                                    onChange={(e) => { setNamaSubTO(e.target.value); console.log(namaSubTO); }}
                                                    value={namaSubTO}
                                                    className="w-100"
                                                    style={{ backgroundColor: '#F3F4F6', borderRadius: '8px', border: 'none !important', paddingLeft: '10px' }}
                                                    placeholder="Masukkan Nama Sub TryOut"
                                                    id=""
                                                />
                                            </div>
                                            <div className="flex flex-row">
                                                <button
                                                    class="bg-green hover:bg-blue-light text-white font-bold py-2 px-4 hover:border-blue rounded ml-2 flex flex-row"
                                                    style={{ borderBottom: '4px solid #22C55E' }}
                                                    onClick={() => submitSubTO()}
                                                >
                                                    <BiSave size="1.5rem" className="mr-2" />Save
                                                </button>
                                                <button
                                                    class="hover:bg-blue-light font-bold py-2 px-4 hover:border-blue rounded ml-2"
                                                    style={{ borderBottom: '4px solid #9ca3af', color: "#9ca3af", backgroundColor: '#F7F6F6' }}
                                                    onClick={() => setIsTambah(false)}
                                                >
                                                    Cancel
                                                </button>
                                            </div>

                                        </div>
                                    </div>
                                </div> : null}
                                {filteredItems.map(i => {
                                    return (
                                        <div className="mt-3" >
                                            <div className="max-w-full md:pr-0 md:pl-0" style={{ paddingRight: '3.5rem' }}>
                                                <FormGroup>
                                                    <div className="flex flex-row" style={{ backgroundColor: '#FFFF', padding: '1rem', border: '1px solid #BDC1C9', borderTopLeftRadius: '15px', borderTopRightRadius: '15px' }}>
                                                        <div className="flex flex-1" style={{ padding: '1rem' }}>
                                                            <h1
                                                                style={{ fontFamily: 'Quicksand' }}
                                                            >
                                                                {i.nama_subtryout}
                                                            </h1>
                                                        </div>
                                                        <div className="flex flex-row">
                                                            <div className="flex mr-2">
                                                                <button
                                                                    class="bg-white hover:bg-blue-light font-bold py-2 px-4 hover:border-blue rounded ml-2 flex flex-row items-center"
                                                                    style={{ border: '2px solid #9ca3af', fontFamily: 'Quicksand', color: "#000" }}
                                                                    onClick={() => history.push('/dashboard/akademik/tryout/' + id + '/soal-editor/' + i.id)}>
                                                                    <BsPen size="1rem" className="mr-2" /> Edit
                                                                </button>
                                                            </div>
                                                            <div className="flex mr-2">
                                                                <button
                                                                    class="bg-white hover:bg-blue-light font-bold py-2 px-4 hover:border-blue rounded ml-2 flex flex-row items-center"
                                                                    style={{ border: '2px solid #9ca3af', fontFamily: 'Quicksand', color: "#000" }}
                                                                    onClick={() => deleteSub(i.id)}>
                                                                    <BsFillTrashFill />
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </FormGroup>
                                                <div className="py-2 px-3 bg-gray font-medium" style={{ fontFamily: 'Quicksand', border: '1px solid #BDC1C9', borderBottomLeftRadius: '15px', borderBottomRightRadius: '15px' }}>
                                                    <div class="flex justify-between">
                                                        <div class="flex flex-row">
                                                            <h5
                                                                className="false flex items-center justify-center gap-1 outline-none tracking-wider focus:outline-none focus:shadow-none transition-all duration-300 rounded-lg py-1.5 px-3 leading-normal shadow-none hover:bg-white hover:border-black hover:text-black undefined mr-3"
                                                                style={{ position: 'relative', overflow: 'hidden', fontFamily: 'Quicksand', color: "#6B7280" }}
                                                            >
                                                                Jumlah Soal: <b>{i.jumlah_soal}</b>
                                                            </h5>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                        <div className="flex-auto mb-4">
                            <div className="max-w-full rounded-xl border-solid border-2 bg-transparent mb-4" style={{ borderColor: '#9CA3AF' }}>
                                <div className="p-4 flex flex-col justify-between leading-normal">
                                    <div className="mb-3">
                                        <div className="flex justify-between text-xl mb-2" style={{ fontFamily: 'Quicksand', fontWeight: 800 }}>
                                            {dataTryout.nama_tryout}
                                            <Button
                                                color="#FFFFFF"
                                                buttonType="filled"
                                                size="regular"
                                                rounded={false}
                                                block={false}
                                                iconOnly={true}
                                                ripple="light"
                                                onClick={() => history.push('/dashboard/akademik/edit-tryout/' + id)}
                                                className="order-last px-2 py-1"
                                                style={{ backgroundColor: '#FFFFFF' }}
                                            >
                                                <FaPen style={{ color: '#F8CA2B', width: '1.5em', height: '1.5em' }} />
                                            </Button>
                                        </div>
                                        <div
                                            className="text-base mb-2"
                                            style={{ color: '#F8CA2B', fontFamily: 'Quicksand', fontWeight: '600' }}
                                        >
                                            {dataTryout.kode_tryout}
                                        </div>
                                        <button
                                            className="flex justify-between text-base mb-4"
                                            style={{ color: '#F8CA2B', fontFamily: 'Quicksand', fontWeight: '600', cursor: 'auto' }}
                                        >
                                            <span class="flex">
                                                <FaClock style={{ color: '#F8CA2B', width: '1.25em', height: '1.25em' }} className="mr-2" />
                                                {moment(dataTryout.tanggal_awal, moment.ISO_8601).format("DD MMMM YYYY HH:mm") + " to " + moment(dataTryout.tanggal_akhir, moment.ISO_8601).format("DD MMMM YYYY HH:mm")}
                                            </span>
                                        </button>
                                        <hr className="w-100 mb-4" />
                                        <div
                                            className="flex justify-between text-base mb-4"
                                            style={{ color: '#F8CA2B', fontFamily: 'Quicksand', fontWeight: '600' }}
                                        >
                                            <span class="flex">
                                                <BsList style={{ color: '#F8CA2B', width: '1.25em', height: '1.25em' }} className="mr-2" />
                                                {programs.nama}
                                            </span>
                                        </div>
                                        <div className="w-100 px-3">
                                            {
                                                dataTryout.is_active ? <Button
                                                    className="pilih-tryout p-2"
                                                    style={{ width: "100%" }}
                                                    onClick={() => AreYouSure(false)}
                                                >Deaktivasi</Button> :
                                                    <Button
                                                        className="pilih-tryout p-2"
                                                        style={{ width: "100%" }}
                                                        onClick={() => AreYouSure(true)}
                                                    >Aktivasi</Button>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </div >
        </React.Fragment >
    )
}

export default connect()(DetailTryOut);