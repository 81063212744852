import { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { BsGrid, BsFileEarmark, BsFillFileEarmarkFill, BsFileEarmarkPlus, BsUpload } from "react-icons/bs";
import { TutorNav } from '..';

export default function TutorSidebar({judul}) {
    const logoFooter = {
        width: "100%",
        height: "auto",
    };
    const [showSidebar, setShowSidebar] = useState('-left-64');
    var baseUrl = window.location.origin;
    return (
        <>
            <TutorNav
                judul={judul}
                showSidebar={showSidebar}
                setShowSidebar={setShowSidebar}
            />
            <div
                className={`h-screen fixed top-0 md:left-0 ${showSidebar} overflow-y-auto flex-row flex-nowrap overflow-hidden shadow-xl bg-white w-64 z-10 py-4 px-6 transition-all duration-300`}
            >
                <div className="flex-col items-stretch min-h-full flex-nowrap px-0 relative">
                    <NavLink
                        to="/dashboard/tutor/data-kelas"
                        exact
                        className="mt-2 text-center w-full inline-block"
                    >
                        <img src={baseUrl + "/assets/logo/ingenio rakun 2.png"} alt="logo" style={logoFooter} />
                    </NavLink>
                    <div className="flex flex-col">
                        <hr className="my-4 min-w-full" />

                        <ul className="flex-col min-w-full flex list-none">                            
                            <li className="rounded-lg mb-2">
                                <NavLink
                                    to="/dashboard/tutor/data-kelas"
                                    exact
                                    className="flex items-center gap-4 text-sm text-gray-700 font-light px-2 py-2 rounded-lg"
                                    activeClassName="SidebarActive"
                                >
                                    <BsGrid size="25px" />
                                        <span className="SidebarName">Data Kelas</span>
                                </NavLink>
                            </li>
                            {/* <li className="rounded-lg mb-2">
                                <NavLink
                                    to="/dashboard/tutor/rapor-tutor"
                                    exact
                                    className="flex items-center gap-4 text-sm text-gray-700 font-light px-2 py-2 rounded-lg"
                                    activeClassName="SidebarActive"
                                >
                                    <BsFillFileEarmarkFill size="25px" />
                                    <span className="SidebarName">Rapor Tutor</span>
                                </NavLink>
                            </li> */}
                            <li className="rounded-lg mb-2">
                                <NavLink
                                    to="/dashboard/tutor/jurnal-mengajar"
                                    exact
                                    className="flex items-center gap-4 text-sm text-gray-700 font-light px-2 py-2 rounded-lg"
                                    activeClassName="SidebarActive"
                                >
                                    <BsFileEarmark size="25px" />
                                    <span className="SidebarName">Jurnal Mengajar</span>
                                </NavLink>
                            </li>
                            <li className="rounded-lg mb-2">
                                <NavLink
                                    to="/dashboard/tutor/upload-materi"
                                    exact
                                    className="flex items-center gap-4 text-sm text-gray-700 font-light px-2 py-2 rounded-lg"
                                    activeClassName="SidebarActive"
                                >
                                    <BsFileEarmarkPlus size="25px" />
                                    <span className="SidebarName">Upload Materi</span>
                                </NavLink>
                            </li>
                            <li className="rounded-lg mb-2">
                                <NavLink
                                    to="/dashboard/tutor/upload-data"
                                    exact
                                    className="flex items-center gap-4 text-sm text-gray-700 font-light px-2 py-2 rounded-lg"
                                    activeClassName="SidebarActive"
                                >
                                    <BsUpload size="25px" />
                                    <span className="SidebarName">Upload Data</span>
                                </NavLink>
                            </li>
                            {/* <li className="rounded-lg mb-2">
                                <NavLink
                                    to="/dashboard/tutor/profile-information"
                                    exact
                                    className="flex items-center gap-4 text-sm text-gray-700 font-light px-2 py-2 rounded-lg"
                                    activeClassName="SidebarActive"
                                >
                                    <BsFillPeopleFill size="25px" />
                                    <span className="SidebarName">Akun</span>
                                </NavLink>
                            </li> */}
                        </ul>
                    </div>
                </div>
            </div>
        </>
    );
}
