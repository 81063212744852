import React, { useEffect, useState } from 'react';
import { CgSoftwareDownload } from 'react-icons/cg';
import { Image } from 'react-bootstrap';
import {
  SideBar
} from '../../components/layout/dashboard';

export default function TransactionDone() {
    document.title = "INGENIO Indonesia";
    var baseUrl = window.location.origin;
    document.body.style.backgroundColor = "#f3f4f6";

    return (
        <React.Fragment>
            <SideBar />
            <div className="md:ml-64">
                <div className="bg-orange px-3 md:px-8 py-1">
                </div>
                <div className="py-4 px-3 md:px-8 mt-4">
                    <div className="container mx-auto max-w-full">
                        <div className="row">
                            <div className="col-lg-7 mx-auto">
                                <div className="cards" style={{padding: '3rem 3.7rem'}}>
                                    <div className="row">
                                        <div className="col-12 text-center">
                                            <Image src="/assets/landing/success_payment.png" className="mx-auto" style={{ width: '12%' }}></Image>
                                            <p style={{ fontSize: "14px", lineHeight: "22px", letterSpacing: "0.06em", color: "#5ac55f", fontWeight: '500' }} className="mt-2 mb-4">Pembayaran Berhasil</p>
                                            <p style={{ fontSize: "36px", lineHeight: "45px", letterSpacing: "0.06em", color: "#000000", fontWeight: 'bold' }}>IDR 120.000 </p>
                                        </div>
                                        <div className="col-12 mt-5">
                                            <div className="row lh-45">
                                                <div className="col-md-5 name">Nama E-Course</div>
                                                <div className="col-md-7 subName"><b>Tutorial memandikan bayi</b></div> 
                                            </div>
                                            <div className="row lh-45">
                                                <div className="col-md-5 name">Harga E-Course</div>
                                                <div className="col-md-7 subName"><b>Rp. 100.000</b></div> 
                                            </div>
                                            <div className="row lh-45">
                                                <div className="col-md-5 name">Rating</div>
                                                <div className="col-md-7 subName">
                                                  <div className="desc rating flex">
                                                    <span class="fas fa-star active"></span>
                                                    <span class="fas fa-star active"></span>
                                                    <span class="fas fa-star active"></span>
                                                    <span class="fas fa-star active"></span>
                                                    <span class="fas fa-star"></span>
                                                  </div>
                                                </div> 
                                            </div>
                                            <div className="row lh-45">
                                              <div className="col-md-5 name">Deskripsi</div> 
                                              <div className="col-md-7 subName"><b>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Neque, vel?</b></div>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <p className="py-5" style={{ fontSize: "14px", lineHeight: "22px", letterSpacing: "0.06em", color: "#000000", fontWeight: '500', textAlign: "center" }}>Proses aktivasi E-Course maksimal <strong>2x24 jam</strong><br></br>Hari kerja Senin-Jum'at 09:00-17:00 WIB, Sabtu 09:00 - 12:00 WIB</p>
                                        </div>
                                        <div className="justify-center">
                                            <button type="submit" class="btnOrange3 justify-center gap-2"><CgSoftwareDownload size="23" />Unduh Bukti Pembayaran</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment >

    )
}