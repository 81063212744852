import React from 'react';
import ReactPlayer from 'react-player';

export default function AboutPlayer (props) {
  return(
    <React.Fragment>
      <div className="player-wrapper">
        <ReactPlayer
          url="https://ingenioindonesia.com/public/assets/video/landing_video_1.mp4"
          className="react-player"
          playing={false}
          controls={true}
          width="100%"
          height="100%"
          volume={1}
          light={true}
          onReady={() => console.log("ready now")}
        />
      </div>
    </React.Fragment>
  )
}