import React, { useEffect, useState } from 'react';
import { SoalEditorNav } from '../../../../components/layout/dashboard';
import { UploadGambar } from '../../../../components/dashboard';

import Button from "@material-tailwind/react/Button";
import DataTable from 'react-data-table-component';

import { BsSearch, BsPlusCircle, BsFolder, BsUpload, BsImage } from "react-icons/bs";
import { FaPen, FaClock, FaTrash } from "react-icons/fa";
import { BiSave } from 'react-icons/bi';

import { Link, useParams, useHistory } from 'react-router-dom';
import { Modal } from 'react-bootstrap';

import { LazyLoadImage } from 'react-lazy-load-image-component';
import TimePicker from 'react-time-picker';
import Swal from 'sweetalert2';

import { connect } from 'react-redux';
import { getSubtryoutDetail, getAllTryout, importBank, updateDuration, deleteBankSoalSubTO, uploadHasilLaboratorium } from "../../../../store/actions/tryout";

import { useForm, FormProvider, Controller } from "react-hook-form";

import moment from 'moment';

function SoalEditor(props) {
    document.title = "INGENIO Indonesia";
    document.body.style.backgroundColor = "#F3F4F6";
    const [dataAllTO, setDataAllTO] = useState([]);
    const [detailSubTO, setDetailSubTO] = useState({});
    const [detailTO, setDetailTO] = useState({});
    const [bankSoal, setBankSoal] = useState([]);
    const [duration, setDuration] = useState('');
    const [durationSubTO, setDurationSubTO] = useState(0);
    const [showImport, setShowImport] = useState(false);
    const [showUpload, setShowUpload] = useState(false);
    const [showSubTO, setShowSubTO] = useState(false);
    const [dataWaktu, setDataWaktu] = useState(0);
    const [showEditWaktu, setShowEditWaktu] = useState(false);
    const [dataAllSubTO, setDataAllSubTO] = useState([]);

    // FILTERING DATA
    const [filterText, setFilterText] = React.useState('');
    const onFilter = (data) => {
        setFilterText(data)
    }

    const filteredItems = bankSoal.filter(
        item => (item.banksoal.soal && item.banksoal.soal.toLowerCase().includes(filterText.toLowerCase()))
    );
    // END FILTERING DATA

    const columns = [
        {
            name: 'No.',
            selector: (row, index) => index + 1,
            sortable: true,
            width: '7.5rem'
        },
        {
            name: 'Nama Tryout',
            selector: row => row.paket,
            sortable: true,
        },
        {
            name: 'Kode Tryout',
            selector: row => row.kode,
            sortable: true,
        },
        {
            cell: (row) =>
                <Button className="text-center" style={{ borderRadius: '10px', padding: '10px', color: '#000 !important', background: '#F8CA2B' }} onClick={() => { handleCloseImport(); handleShowSubTO(row.subtryout); }}>
                    Pilih
                </Button>,
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
    ]

    const columnsSubTo = [
        {
            name: 'No.',
            selector: (row, index) => index + 1,
            sortable: true,
            width: '7.5rem'
        },
        {
            name: 'Nama Tryout',
            selector: row => row.nama_subtryout,
            sortable: true,
        },
        {
            name: 'Jumlah Soal',
            selector: row => row.jumlah_soal,
            sortable: true,
        },
        {
            cell: (row) =>
                <Button className="text-center" style={{ borderRadius: '10px', padding: '10px', color: '#000 !important', background: '#F8CA2B' }} onClick={() => { clickImportBank(row.id) }}>
                    Pilih
                </Button>,
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
    ]

    const { dispatch } = props;
    const history = useHistory();
    let { id, subtryoutId } = useParams();

    const methods = useForm();

    const handleCloseImport = () => setShowImport(false);
    const handleCloseUpload = () => setShowUpload(false);
    const handleCloseSubTO = () => setShowSubTO(false);
    const handleShowSubTO = (data) => {
        setDataAllSubTO(data);
        setShowSubTO(true);
    }
    const handleCloseEditWaktu = () => {
        setDataWaktu('');
        setShowEditWaktu(false);
    }

    const clickImportBank = (id_sub) => {
        const payload_id = {
            id_subtryout: id_sub,
        }
        dispatch(importBank(subtryoutId, payload_id))
            .then((response) => {
                Swal.fire({
                    title: 'Import dari Bank Tryout berhasil!',
                    icon: 'success',
                    showCancelButton: false,
                    // cancelButtonText: 'Kembali',  
                    confirmButtonText: 'Kembali'
                })
                    .then((result) => {
                        if (result.isConfirmed) {
                            window.location.reload()
                        }
                    });
            })
            .catch((err) => {
                // console.log(err)
            })
    }

    function submitDurationSubTO() {
        let payload = {
            duration: durationSubTO
        }

        dispatch(updateDuration(subtryoutId, payload))
            .then((response) => {
                setDurationSubTO(0)
                setShowEditWaktu(false)
            }).then(() => {
                window.location.reload();
            });
    }

    function deleteSubToBankSoal(id) {
        Swal.fire({
            title: 'Anda Yakin?',
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: 'Kembali',
            confirmButtonText: 'Ya, Hapus!'
        })
            .then((result) => {
                if (result.isConfirmed) {
                    dispatch(deleteBankSoalSubTO(id))
                        .then((response) => {
                            Swal.fire({
                                title: 'Soal berhasil dihapus!',
                                icon: 'success',
                                showCancelButton: false,
                                // cancelButtonText: 'Kembali',  
                                confirmButtonText: 'Kembali'
                            })
                                .then((result) => {
                                    if (result.isConfirmed) {
                                        window.location.reload()
                                    }
                                });
                        });
                }
            });

    }

    const submitLab = async (data) => {
        console.log(data);
        let formData = new FormData();

        formData.append('file', data.file);
        await dispatch(uploadHasilLaboratorium(subtryoutId, formData))
            .then((response) => {
                window.location.reload();
            })
            .catch(err => {
                console.log(err)
            })
    }

    useEffect(() => {
        dispatch(getAllTryout())
            .then((response) => {
                let obj = { ...response.data };
                let tryoutArr = [];
                let count = 1;
                for (const key in obj) {
                    let jumlahSoal = 0;
                    if (obj[key].id === Number(id)) {
                        console.log(obj[key].id)
                        continue;
                    } else {
                        if (obj[key].subtryout.length > 0) {
                            for (let i = 0; i < obj[key].subtryout.length; i++) {
                                jumlahSoal += obj[key].subtryout[i].jumlah_soal;
                            }
                        }
                        let payload = {
                            no: count,
                            id: obj[key].id,
                            paket: obj[key].nama_tryout,
                            kode: obj[key].kode_tryout,
                            program: obj[key].program.nama,
                            subtryout: obj[key].subtryout,
                            jumlah: jumlahSoal
                        };
                        tryoutArr.push(payload)
                        count++
                    }
                }
                setDataAllTO(tryoutArr);
            })
            .then(() => {
                // console.log(dataAllTO);
            });
    }, [])

    useEffect(() => {
        let tanggalawal, tanggalakhir;
        dispatch(getSubtryoutDetail(subtryoutId))
            .then(async (response) => {
                const payloadSubTO = {
                    id: response.data.id,
                    nama: response.data.nama_subtryout,
                    duration: response.data.duration,
                    hasil_laboratorium: response.data.hasil_laboratorium
                }
                tanggalawal = response.data.tryout.tanggal_awal;
                tanggalakhir = response.data.tryout.tanggal_akhir;
                await setDetailSubTO(payloadSubTO)
                await setDetailTO(response.data.tryout)
                await setBankSoal(response.data.subtryoutBanksoal)
            })
            .then(() => {
                console.log(detailTO.tanggal_awal);
                let ms = (moment(tanggalawal, moment.ISO_8601).format("DD MMMM YYYY HH:mm")) + " to " + (moment(tanggalakhir, moment.ISO_8601).format("DD MMMM YYYY HH:mm"));

                // let d = moment.duration(ms);
                // let s = Math.floor(d.asHours()) + moment.utc(ms).format(":mm:ss");
                setDuration(ms);
            })
    }, [])

    return (
        <React.Fragment>
            <SoalEditorNav tryoutId={id} subtryoutId={subtryoutId} />
            <div className="px-5 md:px-8" >
                <div className="container mx-auto max-w-full flex">
                    <div className="col-8 py-0 px-5 flex-2">
                        <div
                            className="max-w-full overflow-hidden shadow-lg bg-white"
                            style={{ borderBottomLeftRadius: '25px', borderBottomRightRadius: '25px', }}
                        >
                            <div class="row px-6 py-4">
                                <div className="flex flex-col col-6">
                                    <label
                                        htmlFor="searchSoal"
                                        className="text-gray-800 dark:text-gray-100 text-md font-bold leading-tight tracking-normal mb-2 pl-4"
                                        style={{ fontFamily: 'Quicksand', fontWeight: 600 }}
                                    >
                                        Cari Soal
                                    </label>
                                    <div className="relative">
                                        <div className="absolute text-gray-600 dark:text-gray-400 flex items-center pl-4 h-full cursor-pointer">
                                            <BsSearch style={{ stroke: "black", strokeWidth: "0.5" }} />
                                        </div>
                                        <input
                                            value={filterText}
                                            onChange={(e) => onFilter(e.target.value)}
                                            className="text-gray-600 dark:text-gray-400 focus:outline dark:border-gray-700 dark:bg-gray-800 bg-white font-normal w-100 h-10 flex items-centertext-sm border-gray-300 rounded-lg border"
                                            style={{ paddingLeft: '3rem' }}
                                            placeholder="Cari Soal"
                                        />
                                    </div>
                                </div>
                                <div class="flex flex-col col-6">
                                    <label
                                        htmlFor="tambahSoal"
                                        className="text-gray-800 dark:text-gray-100 text-md font-bold leading-tight tracking-normal mb-2 pl-4"
                                        style={{ fontFamily: 'Quicksand', fontWeight: 600 }}
                                    >
                                        Buat Soal
                                    </label>
                                    <div className="relative px-2">
                                        <Link
                                            to={{
                                                pathname: "/dashboard/akademik/tryout/" + id + "/soal-editor/" + subtryoutId + '/add',
                                                state: {
                                                    nama_tryout: detailTO.nama_tryout,
                                                    kode_tryout: detailTO.kode_tryout,
                                                    id_tryout: detailTO.id,
                                                    id_subtryout: detailSubTO.id,
                                                    nomor: bankSoal.length + 1
                                                }
                                            }}
                                            className="text-md rounded-lg font-bold px-3 py-2 flex items-center focus:outline-none w-64"
                                            style={{ color: '#F8CA2B', fontFamily: 'Quicksand', backgroundColor: '#F6F6E6' }}>
                                            Tambah Soal Baru
                                            <span className="pl-4" style={{ color: '#F8CA2B' }}>
                                                <BsPlusCircle style={{ stroke: "#F8CA2B", strokeWidth: "0.5" }} />
                                            </span>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {
                            filteredItems.map((i, idx) => {
                                return (
                                    <div className="container max-w-full mx-auto flex items-center justify-between py-5 px-1">
                                        <div className="shadow-md rounded-lg border border-2 overflow-hidden col-12 col-lg-10 mx-auto">
                                            <div className="py-3 px-3 bg-gray font-medium flex justify-between" style={{ fontFamily: 'Quicksand' }}>
                                                <div className="flex flex-row">
                                                    Soal No. {idx + 1}
                                                </div>
                                                <div className="flex flex-row">
                                                    <Link
                                                        to={{
                                                            pathname: "/dashboard/akademik/tryout/" + id + "/soal-editor/" + subtryoutId + '/edit/' + i.banksoal.id,
                                                            state: {
                                                                nama_tryout: detailTO.nama_tryout,
                                                                kode_tryout: detailTO.kode_tryout,
                                                                id_tryout: detailTO.id,
                                                                id_subtryout: detailSubTO.id,
                                                                nomor: idx + 1
                                                            }
                                                        }}
                                                        class="flex flex-row false items-center justify-center gap-1 outline-none tracking-wider focus:outline-none focus:shadow-none transition-all duration-300 rounded-lg py-1.5 px-3 text-sm leading-normal bg-white border border-solid shadow-none text-black border-black hover:bg-white hover:border-black hover:text-black hover:bg-white active:bg-white undefined mr-3"
                                                        style={{ position: 'relative', overflow: 'hidden', fontFamily: 'Quicksand' }}
                                                    >
                                                        <FaPen className="mr-2" />
                                                        Edit
                                                    </Link>
                                                    <button
                                                        class="false items-center justify-center gap-1 outline-none tracking-wider focus:outline-none focus:shadow-none transition-all duration-300 rounded-lg py-1.5 px-3 text-sm leading-normal bg-white border border-solid shadow-none text-black border-black hover:bg-white hover:border-black hover:text-black hover:bg-white active:bg-white undefined mr-3"
                                                        style={{ position: 'relative', overflow: 'hidden', fontFamily: 'Quicksand' }}
                                                        onClick={() => deleteSubToBankSoal(i.id)}
                                                    >
                                                        <FaTrash />
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="block bg-white px-5 py-2">
                                                {i.banksoal.gambar_soal ?
                                                    <LazyLoadImage
                                                        src={i.banksoal.gambar_soal}
                                                        alt={'Gambar ' + `Soal No. ${idx + 1}`}
                                                        effect="blur"
                                                        width="150rem"
                                                    />
                                                    : null
                                                }
                                                <div
                                                    className="flex w-full mx-auto xl:mx-0 mb-2 py-3"
                                                    style={{ fontFamily: 'Quicksand' }}
                                                    dangerouslySetInnerHTML={{ __html: i.banksoal.soal }}
                                                >
                                                </div>
                                                <hr className="my-4" />
                                                <div className="flex flex-column w-full mx-auto xl:mx-0 mb-2">
                                                    {i.banksoal.jawaban.map((item) => {
                                                        return (
                                                            i.banksoal.answer_key == item.key ? <div className="flex">
                                                                <div className="round rounded-full py-3 mr-2">
                                                                    <button
                                                                        class="false items-center justify-center gap-1 outline-none tracking-wider focus:outline-none focus:shadow-none transition-all duration-300 rounded-lg py-1.5 px-3 text-sm leading-normal border border-solid shadow-none border-black hover:border-black undefined mr-3"
                                                                        style={{ position: 'relative', overflow: 'hidden', fontFamily: 'Quicksand', backgroundColor: '#22C55E', color: 'white', fontWeight: 600, width: '3rem' }}
                                                                    >
                                                                        {item.key.toLowerCase()}.
                                                                    </button>
                                                                </div>
                                                                <div
                                                                    style={{ fontFamily: 'Quicksand' }}
                                                                    className="py-3 text-gray-600 dark:text-gray-400 font-normal w-100 flex items-center pl-1 text-sm rounded"
                                                                >
                                                                    <div>
                                                                        <h3>{item.jawaban}</h3><br></br>
                                                                        {
                                                                            item.gambar && item.gambar !== null ?
                                                                            <div>
                                                                                <LazyLoadImage
                                                                                    src={item.gambar}
                                                                                    alt={'Gambar Jawaban Soal No. ' + (idx + 1)}
                                                                                    effect="blur"
                                                                                    width="150rem"
                                                                                />
                                                                            </div>
                                                                            :
                                                                            null
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div> : <div className="flex">
                                                                <div className="self-center round rounded-full py-3 mr-2">
                                                                    <button
                                                                        class="false items-center justify-center gap-1 outline-none tracking-wider focus:outline-none focus:shadow-none transition-all duration-300 rounded-lg py-1.5 px-3 text-sm leading-normal border border-solid shadow-none border-black hover:border-black undefined mr-3"
                                                                        style={{ position: 'relative', overflow: 'hidden', fontFamily: 'Quicksand', backgroundColor: '#EF4444', color: 'white', fontWeight: 600, width: '3rem' }}
                                                                    >
                                                                        {item.key.toLowerCase()}.
                                                                    </button>
                                                                </div>
                                                                <div
                                                                    style={{ fontFamily: 'Quicksand' }}
                                                                    className="py-3 text-gray-600 dark:text-gray-400 font-normal w-100 flex items-center pl-1 text-sm rounded"
                                                                >
                                                                    <div>
                                                                        <h3>{item.jawaban}</h3><br></br>
                                                                        {
                                                                            item.gambar && item.gambar !== null ?
                                                                            <div>
                                                                                <LazyLoadImage
                                                                                    src={item.gambar}
                                                                                    alt={'Gambar Jawaban Soal No. ' + (idx + 1)}
                                                                                    effect="blur"
                                                                                    width="150rem"
                                                                                />
                                                                            </div>
                                                                            :
                                                                            null
                                                                        }
                                                                    </div>
                                                                    
                                                                </div>
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                                {
                                                    i.banksoal.pembahasan ? <div>
                                                        <h5 class="line-divider mb-4" style={{ fontSize: '1rem' }}><span class="span-line-divider ">Pembahasan</span></h5>
                                                        <div className="flex w-full mx-auto xl:mx-0 mb-2">
                                                            <div className="w-9/12 flex px-4">
                                                                <h6 style={{ fontFamily: 'Quicksand' }}>{i.banksoal.pembahasan}</h6>
                                                            </div>
                                                            <div className="w-3/12 flex">
                                                                {i.banksoal.gambar_pembahasan ?
                                                                    <LazyLoadImage
                                                                        src={i.banksoal.gambar_pembahasan}
                                                                        alt={'Gambar Pembahasan Soal No. ' + (idx + 1)}
                                                                        effect="blur"
                                                                        width="150rem"
                                                                    />
                                                                    : null}
                                                            </div>
                                                        </div>
                                                    </div> : null
                                                }
                                            </div>
                                            <div className="py-3 px-3 bg-gray font-medium" style={{ fontFamily: 'Quicksand' }}>
                                                <div class="flex justify-between">
                                                    <div class="flex flex-row">
                                                        <button
                                                            className="false flex items-center justify-center gap-1 outline-none tracking-wider focus:outline-none focus:shadow-none transition-all duration-300 rounded-lg py-1.5 px-3 text-sm leading-normal bg-white border border-solid shadow-none text-black border-black hover:bg-white hover:border-black hover:text-black hover:bg-white active:bg-white undefined mr-3"
                                                            style={{ position: 'relative', overflow: 'hidden', fontFamily: 'Quicksand' }}
                                                        >
                                                            {i.banksoal.bidang.nama}
                                                        </button>
                                                        <button
                                                            className="false flex items-center justify-center gap-1 outline-none tracking-wider focus:outline-none focus:shadow-none transition-all duration-300 rounded-lg py-1.5 px-3 text-sm leading-normal bg-white border border-solid shadow-none text-black border-black hover:bg-white hover:border-black hover:text-black hover:bg-white active:bg-white undefined mr-3"
                                                            style={{ position: 'relative', overflow: 'hidden', fontFamily: 'Quicksand' }}
                                                        >
                                                            {i.banksoal.topik.nama}
                                                        </button>
                                                        <button
                                                            className="false flex items-center justify-center gap-1 outline-none tracking-wider focus:outline-none focus:shadow-none transition-all duration-300 rounded-lg py-1.5 px-3 text-sm leading-normal bg-white border border-solid shadow-none text-black border-black hover:bg-white hover:border-black hover:text-black hover:bg-white active:bg-white undefined mr-3"
                                                            style={{ position: 'relative', overflow: 'hidden', fontFamily: 'Quicksand' }}
                                                        >
                                                            {i.banksoal.kompetensi.nama}
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }

                    </div>
                    <div className="col-4 flex-1 py-5 px-5">
                        <div className="max-w-full rounded-xl border-solid border-2 bg-transparent mb-4" style={{ borderColor: '#9CA3AF' }}>
                            <div className="p-4 flex flex-col justify-between leading-normal">
                                <div className="mb-3">
                                    <div className="flex justify-between text-xl mb-2" style={{ fontFamily: 'Quicksand', fontWeight: 800 }}>
                                        {detailTO.nama_tryout}
                                        <Button
                                            color="#FFFFFF"
                                            buttonType="filled"
                                            size="regular"
                                            rounded={false}
                                            block={false}
                                            iconOnly={true}
                                            ripple="light"
                                            className="order-last px-2 py-1"
                                            style={{ backgroundColor: '#FFFFFF' }}
                                            onClick={() => {
                                                history.push('/dashboard/akademik/edit-tryout/' + id)
                                            }}
                                        >
                                            <FaPen style={{ color: '#F8CA2B', width: '1.5em', height: '1.5em' }} />
                                        </Button>
                                    </div>
                                    <div
                                        className="text-base mb-2"
                                        style={{ color: '#F8CA2B', fontFamily: 'Quicksand', fontWeight: '600' }}
                                    >
                                        {detailTO.kode_tryout}
                                    </div>
                                    <button
                                        className="flex justify-between text-base mb-4"
                                        style={{ color: '#F8CA2B', fontFamily: 'Quicksand', fontWeight: '600', cursor: 'auto' }}
                                    >
                                        <span class="flex">
                                            <FaClock style={{ color: '#F8CA2B', width: '1.25em', height: '1.25em' }} className="mr-2" />
                                            {duration}
                                        </span>
                                    </button>
                                    <button
                                        className="flex justify-between text-base mb-4"
                                        style={{ color: '#F8CA2B', fontFamily: 'Quicksand', fontWeight: '600' }}
                                        onClick={() => setShowEditWaktu(true)}
                                    >
                                        <span class="flex">
                                            <FaClock style={{ color: '#F8CA2B', width: '1.25em', height: '1.25em' }} className="mr-2" />
                                            Jangka Waktu Pengerjaan: {detailSubTO.duration ? (detailSubTO.duration + ' menit') : ('0 menit')}
                                        </span>
                                        <FaPen className="ml-2" style={{ color: '#F8CA2B', width: '1em', height: '1em' }} />
                                    </button>
                                    <hr className="w-100 mb-4" />
                                    <div
                                        className="flex justify-between text-base mb-2"
                                        style={{ color: '#9CA3AF', fontFamily: 'Quicksand', fontWeight: '500' }}
                                    >
                                        <span className="flex">
                                            <BsFolder style={{ color: '#9CA3AF', width: '1.4em', height: '1.4em' }} className="mr-3" />
                                            {detailSubTO.nama}
                                        </span>
                                    </div>
                                    <div
                                        className="flex justify-between text-base mb-2"
                                        style={{ color: '#9CA3AF', fontFamily: 'Quicksand', fontWeight: '500' }}
                                    >
                                        <button
                                            className="flex"
                                            style={{ color: '#9CA3AF' }}
                                            onClick={() => setShowImport(true)}
                                        >
                                            <BsUpload style={{ color: '#9CA3AF', width: '1.4em', height: '1.4em' }} className="mr-3" />
                                            Import dari Bank Tryout
                                        </button>
                                    </div>
                                    <div
                                        className="flex justify-between text-base mb-2"
                                        style={{ color: '#9CA3AF', fontFamily: 'Quicksand', fontWeight: '500' }}
                                    >
                                        <button
                                            className="flex"
                                            style={{ color: '#9CA3AF' }}
                                            onClick={() => setShowUpload(true)}
                                        >
                                            <BsUpload style={{ color: '#9CA3AF', width: '1.4em', height: '1.4em' }} className="mr-3" />
                                            Upload Hasil Laboratorium
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="max-w-full rounded-xl border-solid border-2 bg-transparent mb-4" style={{ borderColor: '#9CA3AF' }}>
                            <div className="p-4 flex flex-col justify-between leading-normal">
                                <div className="mb-3">
                                    <div className="flex justify-between text-lg mb-2 px-5" style={{ fontFamily: 'Quicksand', fontWeight: 500 }}>
                                        Jumlah Soal: <span className="text-xl font-bold">{bankSoal.length}</span>
                                    </div>
                                    <div className="relative">
                                        {detailSubTO.hasil_laboratorium ? <img src={detailSubTO.hasil_laboratorium} alt="Hasil Laboratorium" /> : <button className="flex justify-between text-sm rounded-lg font-bold px-3 py-2 flex items-center focus:outline-none w-100 border-solid border-2" style={{ color: '#9CA3AF', fontFamily: 'Quicksand', backgroundColor: '#F3F4F6' }}>
                                            <span className="pr-2" style={{ color: '#9CA3AF' }}>
                                                <BsImage style={{ stroke: "#9CA3AF", strokeWidth: "0.5", width: '1.25em', height: '1.25em' }} />
                                            </span>
                                            Hasil Laboratorium Belum Ada
                                        </button>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal
                    show={showImport}
                    onHide={handleCloseImport}
                    size="lg"
                    centered
                >
                    <Modal.Header
                        closeButton
                    >
                        <Modal.Title
                            style={{ fontFamily: 'Quicksand' }}
                        >
                            Import dari Bank Tryout
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="container mx-auto max-w-full flex">
                            <div className="col-md-12 col-lg-12 col-xs-12">
                                <DataTable columns={columns} data={dataAllTO} pagination />
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
                <Modal
                    show={showEditWaktu}
                    onHide={handleCloseEditWaktu}
                    size="lg"
                    centered
                >
                    <Modal.Header
                        closeButton
                    >
                        <Modal.Title
                            style={{ fontFamily: 'Quicksand' }}
                        >
                            Ubah Durasi Pengerjaan Subtryout
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="container mx-auto max-w-full flex">
                            <div className="col-md-12 col-lg-12 col-xs-12">
                                <form
                                    onSubmit={(e) => {
                                        e.preventDefault();
                                        submitDurationSubTO()
                                    }}
                                >
                                    <div className="max-w-full md:pr-0 md:pl-0">
                                        <div className="row" style={{ backgroundColor: '#FFFF', padding: '1rem' }}>
                                            <div className="col-9">
                                                <label htmlFor="durationSubTo">Durasi (dalam menit)</label>
                                                <input
                                                    type="number"
                                                    onChange={(e) => setDurationSubTO(e.target.value)}
                                                    value={durationSubTO}
                                                    min="1"
                                                    max="360"
                                                    style={{ backgroundColor: '#F3F4F6', width: '100%', borderRadius: '8px', border: 'none !important', height: '45px', paddingLeft: '10px' }}
                                                    placeholder="contoh: 30"
                                                    id="durationSubto" />
                                            </div>
                                            <div className="col-3 flex">
                                                <Button
                                                    className="save flex-center"
                                                    style={{ backgroundColor: '#4ADE80' }}
                                                    type="submit"
                                                    onClick={() => {
                                                        submitDurationSubTO()
                                                    }
                                                    }>
                                                    <BiSave />Save
                                                </Button>
                                            </div>

                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
                <Modal
                    show={showSubTO}
                    onHide={handleCloseSubTO}
                    size="lg"
                    centered
                >
                    <Modal.Header
                        closeButton
                    >
                        <Modal.Title
                            style={{ fontFamily: 'Quicksand' }}
                        >
                            Pilih Subtryout
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="container mx-auto max-w-full flex">
                            <div className="col-md-12 col-lg-12 col-xs-12">
                                <DataTable columns={columnsSubTo} data={dataAllSubTO} pagination />
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
                <Modal
                    show={showUpload}
                    onHide={handleCloseUpload}
                    size="lg"
                    centered
                >
                    <FormProvider id="form_upload_hasil" {...methods} encType="multipart/form-data">
                        <form onSubmit={methods.handleSubmit(submitLab)}>
                            <Modal.Body>
                                <Controller
                                    name="file"
                                    control={methods.control}
                                    render={({
                                        field: { onChange, onBlur, value, name, ref } }) => {
                                        return <UploadGambar value={value} onChange={(e) => {
                                            onChange(e.target.files[0]);
                                        }} />
                                    }}
                                />
                            </Modal.Body>
                            <Modal.Footer>
                                <div className="flex-row-reverse">
                                    <button type="submit" class="bg-green hover:bg-blue-light text-white font-bold py-2 px-4 hover:border-blue rounded ml-2" style={{ borderBottom: '4px solid #22C55E' }}>
                                        SAVE
                                    </button>
                                    <button onClick={() => handleCloseUpload()} class="bg-white hover:bg-blue-light font-bold py-2 px-4 hover:border-blue rounded ml-2" style={{ borderBottom: '4px solid #9ca3af', color: "#9ca3af" }}>
                                        CANCEL
                                    </button>
                                </div>
                            </Modal.Footer>
                        </form>
                    </FormProvider>
                </Modal>
            </div>
        </React.Fragment>

    )
}

export default connect()(SoalEditor);