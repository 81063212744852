import { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { BsGrid, BsUpload } from "react-icons/bs";
import { MdOutlinePayments, MdOutlinePayment } from "react-icons/md";
import { AkuntanNav } from '..';
import Dropdown from "@material-tailwind/react/Dropdown"
import DropdownLink from "@material-tailwind/react/DropdownLink"
import { Link } from 'react-router-dom'

export default function AkuntanSidebar({judul}) {
    const logoFooter = {
        width: "100%",
        height: "auto",
    };
    const [showSidebar, setShowSidebar] = useState('-left-64');
    var baseUrl = window.location.origin;
    return (
        <>
            <AkuntanNav
                judul={judul}
                showSidebar={showSidebar}
                setShowSidebar={setShowSidebar}
            />
            <div
                className={`h-screen fixed top-0 md:left-0 ${showSidebar} overflow-y-auto flex-row flex-nowrap overflow-hidden shadow-xl bg-white w-64 z-10 py-4 px-6 transition-all duration-300`}
            >
                <div className="flex-col items-stretch min-h-full flex-nowrap px-0 relative">
                    <NavLink
                        to="/dashboard/akuntan"
                        exact
                        className="mt-2 text-center w-full inline-block"
                    >
                        <img src={baseUrl + "/assets/logo/ingenio rakun 2.png"} alt="logo" style={logoFooter} />
                    </NavLink>
                    <div className="flex flex-col">
                        <hr className="my-4 min-w-full" />

                        <ul className="flex-col min-w-full flex list-none">
                            <li className="rounded-lg mb-2">
                                <NavLink
                                    to="/dashboard/akuntan"
                                    exact
                                    className="flex items-center gap-4 text-sm font-bold px-2 py-2 rounded-lg"
                                    activeClassName="SidebarActive"
                                >
                                    <BsGrid size="25px" />
                                    <span className="SidebarName">Dashboard</span>
                                </NavLink>
                            </li>
                            <li className="rounded-lg mb-2">
                                <Dropdown className="flex items-center gap-4 text-sm text-gray-700 font-light px-2 py-2 rounded-lg"
                                        activeClassName="SidebarActive"
                                        placement="bottom-start"
                                        buttonText={
                                            <div style={{display: 'inline-flex', fontWeight: 500}}>
                                                <MdOutlinePayments style={{marginRight: '20px'}} size="25px"/>
                                                <span className="SidebarName">Payment</span>
                                            </div>
                                        }
                                        buttonType="link"
                                        size="regular"
                                        rounded={false}
                                        block={false}
                                        ripple="dark">
                                    <DropdownLink>
                                        <Link to="/dashboard/akuntan/payment-ecourse">
                                            <div style={{display: 'inline-flex'}}>
                                                <MdOutlinePayment style={{marginRight: '15px', fontWeight: 500}} size="20px" />
                                                E-Course
                                            </div> 
                                        </Link>                                     
                                    </DropdownLink>
                                    <DropdownLink>
                                        <Link to="/dashboard/akuntan/payment-elearning">
                                            <div style={{display: 'inline-flex'}}>
                                                <MdOutlinePayment style={{marginRight: '15px', fontWeight: 500}} size="20px" />
                                                E-Learning
                                            </div> 
                                        </Link>                                     
                                    </DropdownLink>
                                    <DropdownLink>
                                        <Link to="/dashboard/akuntan/payment-tryout">
                                            <div style={{display: 'inline-flex'}}>
                                                <MdOutlinePayment style={{marginRight: '15px', fontWeight: 500}} size="20px" />
                                                TryOut
                                            </div> 
                                        </Link>                                     
                                    </DropdownLink>
                                </Dropdown>
                            </li>
                            <li className="rounded-lg mb-2">
                                <NavLink
                                    to="/dashboard/akuntan/upload-data"
                                    exact
                                    className="flex items-center gap-4 text-sm text-gray-700 font-light px-2 py-2 rounded-lg"
                                    activeClassName="SidebarActive"
                                >
                                    <BsUpload size="25px" />
                                    <span className="SidebarName">Upload Data</span>
                                </NavLink>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    );
}
