import React, {useState, useRef} from 'react';
import { BsFillFileEarmarkArrowUpFill } from 'react-icons/bs';
import { Form } from 'react-bootstrap';
import AdminSideBar from '../../../components/layout/dashboard/admin/AdminSideBar';

export default function MyProfileAdmin () {
  document.title = "INGENIO Indonesia";
  var baseUrl = window.location.origin;
  const [openTab, setOpenTab] = React.useState(1);
 

  return(
    <React.Fragment>
      <AdminSideBar />
      <div className="md:ml-64 styleHead" style={{ backgroundColor: '#F3F4F6' }}>
        <div className="bg-orange px-3 md:px-8 py-1 h-40">
            <h1 className="tracking-wider title-24-600" style={{marginLeft: '3rem'}}>Account Setting</h1>
        </div>

        <div className="py-4 px-3 md:px-8 -mt-12">
          <div className="container mx-auto max-w-full">
            <div className="row">
                <div className="col-lg-10 mx-auto">
                    <div className="cards p-4">
                        <div className="py-3 px-3 bg-white font-bold" style={{fontFamily: 'Quicksand'}}>
                            Profile Settings
                        </div>
                        <div className="py-3 px-5 bg-gray-100 font-bold" style={{fontFamily: 'Quicksand'}}>
                            <ul className="list-reset flex">
                            <li className="-mb-px mr-1">
                                <a 
                                className={openTab === 1 ? "inline-block rounded-t py-2 px-4 font-bold" : "inline-block rounded-t py-2 px-4 font-semibold"} 
                                style={{fontFamily: 'Quicksand'}} 
                                onClick={e => {e.preventDefault();setOpenTab(1);}}
                                href="#">
                                Profile Information
                                </a>
                            </li>
                            <li className="mr-1">
                                <a 
                                className={openTab === 2 ? "inline-block rounded-t py-2 px-4 font-bold" : "inline-block rounded-t py-2 px-4 font-semibold"}
                                style={{fontFamily: 'Quicksand'}}
                                onClick={e => {e.preventDefault();setOpenTab(2);}}
                                href="#">
                                Profile Image
                                </a>
                            </li>
                            </ul>
                        </div>
                        <div className={openTab === 1 ? "block" : "hidden"} id="profileInformation" >
                            <form action="" className="scrollY" id="scrollBar" style={{maxHeight: '80vh'}}>
                                <div className="form-group py-5 px-3">
                                    <div className="row">
                                        <div className="col-md-8">
                                            <div className="titleForms mb-1">Nama Lengkap</div>
                                            <input type="text" class="formInput" placeholder="Nama Lengkap..." />
                                        </div>
                                        <div className="col-md-4">
                                            <div className="titleForms mb-1">Nama Panggilan</div>
                                            <input type="text" class="formInput" placeholder="Nama Panggilan..." />
                                        </div>
                                        <div className="col-md-8">
                                            <div className="titleForms mb-1">Tempat Lahir</div>
                                            <input type="text" class="formInput" placeholder="Tempat Lahir..." />
                                        </div>
                                        <div className="col-md-4">
                                            <div className="titleForms mb-1">Tanggal Lahir</div>
                                            <input type="date" class="formInput" placeholder="Tanggal Lahir..." />
                                        </div>
                                        <div className="col-12">
                                            <div className="titleForms mb-1">Status</div>
                                            <Form.Select
                                                as="select"
                                                aria-label="Program"
                                                aria-describedby="basic-addon1"
                                                className="formInput"
                                            >
                                                <option selected disabled >Status</option>
                                                <option value="Menikah">Menikah</option>
                                                <option value="Belum Menikah">Belum Menikah</option>
                                            </Form.Select>
                                        </div>
                                        <div className="col-12">
                                            <div className="titleForms mb-1">Agama</div>
                                            <input type="text" class="formInput" placeholder="Agama..." />
                                        </div>
                                        <div className="col-12">
                                            <div className="titleForms mb-1">No Hp</div>
                                            <input type="text" class="formInput" placeholder="No Hp..." />
                                        </div>
                                        <div className="col-12">
                                            <div className="titleForms mb-1">Akun Instagram</div>
                                            <input type="text" className="formInput" placeholder="Akun Ig..."  />
                                        </div>
                                        <div className="col-12">
                                            <div className="titleForms mb-1">Jenis Kelamin</div>
                                            <Form.Select
                                                as="select"
                                                aria-label="Program"
                                                aria-describedby="basic-addon1"
                                                className="formInput"
                                            >
                                                <option selected disabled >Jenis Kelamin</option>
                                                <option value="Laki-laki">Laki-laki</option>
                                                <option value="Perempuan">Perempuan</option>
                                            </Form.Select>
                                        </div>
                                        <div className="col-12">
                                            <div className="titleForms mb-1">Email</div>
                                            <input type="text" className="formInput" placeholder="Email..."  />
                                        </div>
                                        <div className="col-12">
                                            <div className="titleForms mb-1">Alamat Sesuai KTP</div>
                                            <textarea className="formInput" name="" id="" placeholder="Alamat sesuai ktp..." style={{minHeight: '100px', padding: '12px 25px'}}></textarea>
                                        </div>
                                        <div className="col-12">
                                            <div className="titleForms mb-1">Alamat Domisili</div>
                                            <textarea className="formInput" name="" id="" placeholder="Alamat domisili..." style={{minHeight: '100px', padding: '12px 25px'}}></textarea>
                                        </div>
                                        <div className="col-12">
                                            <div className="titleForms mb-1">Regio</div>
                                            <input type="text" className="formInput" placeholder="Regio..."  />
                                        </div>
                                        <div className="col-12">
                                            <div className="titleForms mb-1">NPWP</div>
                                            <input type="text" className="formInput" placeholder="NPWP..."  />
                                        </div>
                                        <div className="col-md-6">
                                            <div className="titleForms mb-1">Nama Bank</div>
                                            <input type="text" className="formInput" placeholder="Nama Bank..."  />
                                        </div>
                                        <div className="col-md-6">
                                            <div className="titleForms mb-1">No Rekening</div>
                                            <input type="text" className="formInput" placeholder="No Rekening..."  />
                                        </div>

                                        <div className="title-18-600 mt-3 mb-2">Pendidikan Terakhir</div>
                                        <div className="col-lg-4">
                                            <div className="titleForms mb-1">Asal Universitas</div>
                                            <input type="text" className="formInput" placeholder="Asal Universitas..."  />
                                        </div>
                                        <div className="col-lg-3">
                                            <div className="titleForms mb-1">Tahun Masuk</div>
                                            <input type="number" min={1945} max={2099} className="formInput" placeholder="Tahun Masuk..."  />
                                        </div>
                                        <div className="col-lg-3 relative">
                                            <div className="titleForms mb-1">Tahun Keluar</div>
                                            <input type="number" min={1945} max={2099} className="formInput" placeholder="Tahun Keluar..."  />
                                            <div className="dashAbsolute"></div>
                                        </div>
                                        <div className="col-lg-2">
                                            <div className="titleForms mb-1">Gelar</div>
                                            <input type="text" className="formInput" placeholder="Gelar..."  />
                                        </div>
                                        <div className="col-lg-4">
                                            <input type="text" className="formInput" placeholder="Asal Universitas..."  />
                                        </div>
                                        <div className="col-lg-3">
                                            <input type="number" min={1945} max={2099} className="formInput" placeholder="Tahun Masuk..."  />
                                        </div>
                                        <div className="col-lg-3 relative">
                                            <input type="number" min={1945} max={2099} className="formInput" placeholder="Tahun Keluar..."  />
                                            <div className="dashAbsolute"></div>
                                        </div>
                                        <div className="col-lg-2">
                                            <input type="text" className="formInput" placeholder="Gelar..."  />
                                        </div>
                                        <div className="col-lg-4">
                                            <input type="text" className="formInput" placeholder="Asal Universitas..."  />
                                        </div>
                                        <div className="col-lg-3">
                                            <input type="number" min={1945} max={2099} className="formInput" placeholder="Tahun Masuk..."  />
                                        </div>
                                        <div className="col-lg-3 relative">
                                            <input type="number" min={1945} max={2099} className="formInput" placeholder="Tahun Keluar..."  />
                                            <div className="dashAbsolute"></div>
                                        </div>
                                        <div className="col-lg-2">
                                            <input type="text" className="formInput" placeholder="Gelar..."  />
                                        </div>
                                        <div className="col-lg-4">
                                            <input type="text" className="formInput" placeholder="Asal Universitas..."  />
                                        </div>
                                        <div className="col-lg-3">
                                            <input type="number" min={1945} max={2099} className="formInput" placeholder="Tahun Masuk..."  />
                                        </div>
                                        <div className="col-lg-3 relative">
                                            <input type="number" min={1945} max={2099} className="formInput" placeholder="Tahun Keluar..."  />
                                            <div className="dashAbsolute"></div>
                                        </div>
                                        <div className="col-lg-2">
                                            <input type="text" className="formInput" placeholder="Gelar..."  />
                                        </div>
                                        <div className="col-lg-4">
                                            <input type="text" className="formInput" placeholder="Asal Universitas..."  />
                                        </div>
                                        <div className="col-lg-3">
                                            <input type="number" min={1945} max={2099} className="formInput" placeholder="Tahun Masuk..."  />
                                        </div>
                                        <div className="col-lg-3 relative">
                                            <input type="number" min={1945} max={2099} className="formInput" placeholder="Tahun Keluar..."  />
                                            <div className="dashAbsolute"></div>
                                        </div>
                                        <div className="col-lg-2">
                                            <input type="text" className="formInput" placeholder="Gelar..."  />
                                        </div>
                                        <div className="col-12">
                                            <div className="titleForms mb-1">Pelatihan Yang Pernah Diikuti</div>
                                            <textarea className="formInput" name="" id="" placeholder="deskripsikan pelatihan anda..." style={{minHeight: '160px', padding: '12px 25px'}}></textarea>
                                        </div>
                                    </div>
                                </div>
                                <div className="py-3 px-5 bg-white text-center">
                                    <button
                                    class="bg-orange text-white active:bg-orange font-bold text-base px-8 py-3 rounded-lg shadow-md hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                    type="submit">
                                    {/* <CircularProgress /> */}
                                    Submit Data
                                    </button>
                                </div>
                            </form>
                        </div>
                        <div className={openTab === 2 ? "block p-5" : "hidden p-5"} id="profileImage">
                            <form className="row" encType="multipart/form-data">
                                <div className="col-6 mx-auto text-center mb-3">
                                    {/* <img src={dataProfile ? (dataProfile.profile_photo && dataProfile.profile_photo !== null) ? dataProfile.profile_photo : baseUrl + "/assets/img/profile-image-default.png" : baseUrl + "/assets/img/profile-image-default.png"} className="object-center mx-auto"></img> */}
                                    <img src={baseUrl + "/assets/img/profile-image-default.png"} className="object-center mx-auto"></img>
                                </div>
                                <div className="col-12 text-center mb-3">
                                    <h4 className="font-bold">JPEG, GIF, and PNG images below 5MB can be uploaded.</h4>
                                </div>
                                <div className="col-md-6 mx-auto mb-3">
                                    <div className="relative mt-2">
                                        <input type="file" id="file" className="custom-file-input" style={{  position: 'absolute', right: '-5rem' }} />
                                        <label htmlFor="file" className="labelFile">Upload Here<BsFillFileEarmarkArrowUpFill size="18px"/></label>
                                    </div>
                                </div>
                                <div className="col-12 text-center mt-5">
                                    <button type="submit" class="bg-transparent hover:bg-blue-500 text-black font-semibold hover:text-black py-2 px-4 border border-gray-800 hover:border-transparent rounded-lg">
                                        Upload Image
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
          </div>
        </div>

      </div>
    </React.Fragment>
    
  )
}