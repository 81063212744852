import Button from '@material-tailwind/react/Button';
import React, { useEffect, useState } from 'react';
import { FormGroup, FormControl, Form, Modal } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import { Link, useParams } from 'react-router-dom';
import { BsPencilSquare, BsFillTrashFill, BsFillPlusCircleFill } from 'react-icons/bs';
import AkademikSidebar from '../../../../components/layout/dashboard/akademik/AkademikSideBar';
import { timeValidation } from '../../../../helpers/utils'
import CustomDatatable from '../../../../components/common/CustomDatatable';
import ElearningService from '../../../../services/elearning';
import moment from 'moment-timezone';
import Swal from 'sweetalert2';

export default function DetailOlahJadwal() {
    document.title = "INGENIO Indonesia";
    const [show, setShow] = useState(false);
    const [showUpdate, setShowUpdate] = useState(false);

    // Filtering data
    const [isLoading, setIsLoading] = useState(true);
    const [hasNext, setHasNext] = useState(false)
    const [hasPrev, setHasPrev] = useState(false)
    const [count, setCount] = useState(0);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [keyword, setKeyword] = useState('');
    const [jadwalKelasData, setJadwalKelasData] = useState([])

    // Form Add Kelas
    const [idJadwalKelas, setIdJadwalKelas] = useState(null);
    const [namaMateri, setNamaMateri] = useState('');
    const [tanggal, setTanggal] = useState('');
    const [jamMulai, setJamMulai] = useState('00:00');
    const [jamAkhir, setJamAkhir] = useState('00:00');
    const [namaTutor, setNamaTutor] = useState('');
    const [tutorId, setTutorId] = useState('');
    const [isActive, setIsActive] = useState(true);

    const [tutors, setTutors] = useState([]);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleUpdateClose = () => setShowUpdate(false);
    const handleUpdateShow = () => setShowUpdate(true);

    const { id } = useParams();

    const dropdown = {
        borderRadius: "10px",
        height: 50,
        padding: "25px",
    };
    const columns = [
        {
            name: 'NO.',
            index: true,
            selector: row => row.no,
            sortable: true,
            // width: '6vw',
        },
        {
            name: 'NAMA KELAS',
            field: 'kelas',
            render: true,
            component: (row) => {
                return (
                    <td className='main-table__bodyRow'>{`${row.kelas.nama_kelas}`}</td>
                )
            },
            selector: row => row.kelas,
            sortable: true,
            // width: '10vw',
        },
        {
            name: 'NAMA PROGRAM',
            field: 'program',
            render: true,
            component: (row) => {
                return (
                    <td className='main-table__bodyRow'>{`${row.kelas.elearning_program.nama_program}`}</td>
                )
            },
            selector: row => row.program,
            sortable: true,
            // width: '10vw',
        },
        {
            name: 'NAMA MATERI',
            field: 'nama_materi',
            selector: row => row.materi,
            sortable: true,
            // width: '10vw',   
        },
        {
            name: 'TANGGAL',
            field: 'jam',
            render: true,
            component: (row) => {
                return (
                    <td className='main-table__bodyRow'>{moment(row.tanggal).format('DD-MMM-YYYY')}</td>
                )
            },
            selector: row => row.jam,
            sortable: true,
            minWidth: '150px'
            // width: '10vw',
        },
        {
            name: 'JAM',
            field: 'jam',
            render: true,
            component: (row) => {
                return (
                    <td className='main-table__bodyRow'>{`${moment(row.jam_mulai).format('HH:mm')} - ${moment(row.jam_akhir).format('HH:mm')}`}</td>
                )
            },
            selector: row => row.jam,
            sortable: true,
            minWidth: '150px'
            // width: '10vw',
        },
        {
            name: 'TUTOR',
            field: 'nama_tutor',
            selector: row => row.tutor,
            sortable: true,
            // width: '10vw',
        },
        {
            name: '',
            render: true,
            component: (row) => {
              return (
                <td className='main-table__bodyRow'>
                    <Button
                        className="flex content-center items-center"
                        style={{width: '35px', borderRadius: '50%', padding: '10px', color: 'black', background: '#F8CA2B' }}
                        onClick={() => { 
                            setIdJadwalKelas(row.id)
                            setNamaMateri(row.nama_materi)
                            setTanggal(moment(row.tanggal).format('yyyy-MM-DD'))
                            setJamMulai(moment(row.jam_mulai).format('HH:mm'))
                            setJamAkhir(moment(row.jam_akhir).format('HH:mm'))
                            setNamaTutor(row.nama_tutor)
                            setTutorId(row.id_user)
                            setIsActive(row.is_active)
                            handleUpdateShow()
                        }}
                    >
                        <BsPencilSquare color='black' />
                    </Button>
                </td>
              )
            },
            selector: row => row.jumlah,
            sortable: true,
            minWidth: '20px',
            wrap: 'yes'
        },
        {
            name: '',
            render: true,
            component: (row) => {
              return (
                <td className='main-table__bodyRow'>
                  <Button
                    className="flex content-center items-center"
                    style={{width: '35px', borderRadius: '50%', padding: '10px', color: 'black', background: '#F8CA2B' }}
                    onClick={() => { 
                        deleteJadwalKelas(row.id)
                    }}
                    >
                    <BsFillTrashFill color='black'  />
                  </Button>
                </td>
              )
            },
            selector: row => row.jumlah,
            sortable: true,
            minWidth: '20px',
            wrap: 'yes'
        },
        // {
        //     cell: () => <Link className="pilih-tryout p-2" to="#"><BsPencilSquare /></Link>,
        //     ignoreRowClick: true,
        //     allowOverflow: true,
        //     button: true,
        //     // width: '5vw',
        // },
        // {
        //     cell: () => <Link className="pilih-tryout p-2" to="#"><BsFillTrashFill /></Link>,
        //     ignoreRowClick: true,
        //     allowOverflow: true,
        //     button: true,
        //     // width: '5vw',
        // },
    ];

    const Succeed = (message, onConfirm) => {
        Swal.fire({  
            title: 'Berhasil!',  
            text: message,  
            icon: 'success',  
            showCancelButton: false,              
            confirmButtonText: 'OK'  
        }).then((result) => {
          if (result.isConfirmed) {
            onConfirm()
          }
        })
      }
    
    const Fail = (message) => {
        Swal.fire({  
            title: 'Gagal!',  
            text: message,  
            icon: 'warning',  
            showCancelButton: true,  
            cancelButtonText: 'Kembali',           
            showConfirmButton: false,  
        });   
    }

    const deleteJadwalKelas = (jadwalKelasId) => {
        Swal.fire({  
            title: 'Sudah Yakin?',  
            text: 'Anda yakin ingin menghapus data?',  
            icon: 'warning',  
            showCancelButton: true,  
            cancelButtonText: 'Tidak',  
            confirmButtonText: 'Ya'  
          })
          .then((result) => {
            if (result.isConfirmed) {
                ElearningService.deleteJadwalKelasAkademik(id, jadwalKelasId).then(resp => {
                    Succeed('Data berhasil dihapus', () => {
                        getListJadwalKelas()
                    })
                }).catch(err => {
                    Fail('Gagal menghapus data')
                })
            }
          });
    }

    const updateJadwalKelas = () => {
        if(namaMateri == '' || namaMateri == null) {
            Fail('Nama materi harus diisi')
            return;
        }
        if(tanggal == '' || tanggal == null) {
            Fail('Tanggal harus diisi')
            return;
        }
        if(jamMulai == '' || jamMulai == null) {
            Fail('Jam mulai harus diisi')
            return;
        }
        if(!timeValidation(jamMulai)) {
            Fail('Jam mulai tidak valid')
            return;
        }
        if(jamAkhir == '' || jamAkhir == null) {
            Fail('Jam akhir harus diisi')
            return;
        }
        if(!timeValidation(jamAkhir)) {
            Fail('Jam akhir tidak valid')
            return;
        }
        if(namaTutor == '' || namaTutor == null) {
            Fail('Nama Tutor harus diisi')
            return;
        }
        const tanggalDate = moment(tanggal).format('yyyy-MM-DD');
        const jamMulaiDatetime = `${moment(`${moment(tanggal).format('yyyy-MM-DD')} ${jamMulai}`).utc().format('yyyy-MM-DD HH:mm')}`
        const jamAkhirDatetime = `${moment(`${moment(tanggal).format('yyyy-MM-DD')} ${jamAkhir}`).utc().format('yyyy-MM-DD HH:mm')}`
        if(moment(jamAkhirDatetime) < moment(jamMulaiDatetime)){
            Fail('Jam mulai tidak boleh lebih dari jam akhir')
            return;
        }

        const body = {
            nama_materi: namaMateri,
            tanggal: tanggalDate,
            jam_mulai: jamMulaiDatetime,
            jam_akhir: jamAkhirDatetime,
            nama_tutor: namaTutor,
            id_user: tutorId,
            is_active: isActive
        }

        Swal.fire({  
            title: 'Sudah Yakin?',  
            text: 'Pastikan semua data yang Anda isikan sudah sesuai',  
            icon: 'warning',  
            showCancelButton: true,  
            cancelButtonText: 'Tidak',  
            confirmButtonText: 'Ya'  
          })
          .then((result) => {
            if (result.isConfirmed) {
                ElearningService.updateJadwalKelasAkademik(body, id, idJadwalKelas).then(resp => {
                    Succeed('Data berhasil diupdate', () => {
                        handleUpdateClose()
                        getListJadwalKelas()
                    })
                }).catch(err => {
                    Fail('Gagal update data')
                })
            }
          });
    }

    const addJadwalKelas = () => {
        if(namaMateri == '' || namaMateri == null) {
            Fail('Nama materi harus diisi')
            return;
        }
        if(tanggal == '' || tanggal == null) {
            Fail('Tanggal harus diisi')
            return;
        }
        if(jamMulai == '' || jamMulai == null) {
            Fail('Jam mulai harus diisi')
            return;
        }
        if(!timeValidation(jamMulai)) {
            Fail('Jam mulai tidak valid')
            return;
        }
        if(jamAkhir == '' || jamAkhir == null) {
            Fail('Jam akhir harus diisi')
            return;
        }
        if(!timeValidation(jamAkhir)) {
            Fail('Jam akhir tidak valid')
            return;
        }
        if(namaTutor == '' || namaTutor == null) {
            Fail('Nama Tutor harus diisi')
            return;
        }
        const tanggalDate = moment(tanggal).format('yyyy-MM-DD');
        const jamMulaiDatetime = `${moment(`${moment(tanggal).format('yyyy-MM-DD')} ${jamMulai}`).utc().format('yyyy-MM-DD HH:mm')}`
        const jamAkhirDatetime = `${moment(`${moment(tanggal).format('yyyy-MM-DD')} ${jamAkhir}`).utc().format('yyyy-MM-DD HH:mm')}`
        if(moment(jamAkhirDatetime) < moment(jamMulaiDatetime)){
            Fail('Jam mulai tidak boleh lebih dari jam akhir')
            return;
        }

        const body = {
            nama_materi: namaMateri,
            tanggal: tanggalDate,
            jam_mulai: jamMulaiDatetime,
            jam_akhir: jamAkhirDatetime,
            nama_tutor: namaTutor,
            id_user: tutorId,
            is_active: true
        }

        Swal.fire({  
            title: 'Sudah Yakin?',  
            text: 'Pastikan semua data yang Anda isikan sudah sesuai',  
            icon: 'warning',  
            showCancelButton: true,  
            cancelButtonText: 'Tidak',  
            confirmButtonText: 'Ya'  
          })
          .then((result) => {
            if (result.isConfirmed) {
                ElearningService.addJadwalKelasAkademik(body, id).then(resp => {
                    Succeed('Data berhasil ditambah', () => {
                        handleClose()
                        getListJadwalKelas()
                    })
                }).catch(err => {
                    Fail('Gagal menambahkan data')
                })
            }
          });
    }

    const getListJadwalKelas = (params = '') => {
        setIsLoading(true)
        ElearningService.getListJadwalKelasAkademik(params, id).then(resp => {
            const response = resp.data;
            setJadwalKelasData(response.data);

            setPage(response.meta.page)
            setPerPage(response.meta.perPage)
            setCount(response.meta.count)

            setHasNext(response.meta.hasNext)
            setHasPrev(response.meta.hasPrev);
            
            setIsLoading(false)
        }).catch(err => {
            setIsLoading(false)
            console.error(err)
        })
    }

    const getListTutor = () => {
        ElearningService.getListTutorAkademik().then(resp => {
            const response = resp.data;
            setTutors(response.data);
            
            setIsLoading(false)
        }).catch(err => {
            setIsLoading(false)
            console.error(err)
        })
    }

    useEffect(() => {
        getListJadwalKelas();
        getListTutor()
    }, [])

    return (
        <React.Fragment>
            <AkademikSidebar judul={'Olah Jadwal'} />
            <div className="md:ml-64" style={{ backgroundColor: '#F3F4F6' }}>
                <div className="bg-orange px-3 md:px-8 py-1" style={{ height: '5vh' }}>
                    <div className="container md:pr-8 md:pl-10">
                        {/* <h3 className="text-left tracking-wider title-dashboard">Selamat Datang Staff Akademik INGENIO!</h3> */}
                    </div>
                </div>

                <div className="px-3 md:px-8 mt-5">
                    <div className="container mx-auto max-w-full">
                        <div className="row">
                            <div className="col-md-8 col-lg-4 col-xs-12 mb-4">
                                <FormGroup>
                                    <div className="row" style={{ backgroundColor: '#FFF', padding: '1rem', borderRadius: '1rem' }}>
                                        <div className="col-8">
                                            <input value={keyword} onChange={(e) => setKeyword(e.target.value)} type="text" name="" style={{ backgroundColor: '#F3F4F6', width: '100%', borderRadius: '8px', border: 'none !important', height: '45px', paddingLeft: '10px' }} placeholder="&#x1F50E;&#xFE0E; Nama Program." id="" />
                                        </div>
                                        <div className="col-3">
                                            <Button onClick={() => {
                                                getListJadwalKelas(`page=${page}&perPage=${perPage}&keyword=${keyword}`)
                                            }} className="pilih-tryout">Cari</Button>
                                        </div>
                                    </div>
                                </FormGroup>
                            </div>
                            <div className="col-8" style={{ padding: '1rem' }}>
                                <Button className="pilih-tryout" onClick={() => {
                                    setNamaMateri('')
                                    setTanggal('')
                                    setJamMulai('00:00')
                                    setJamAkhir('00:00')
                                    setNamaTutor('')
                                    setTutorId('')
                                    handleShow()
                                }}><BsFillPlusCircleFill /> Tambah Jadwal</Button>

                                <Modal size="lg" show={show} onHide={handleClose}>
                                    <Modal.Header closeButton>
                                        <Modal.Title><strong>Tambah Jadwal</strong></Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <div className="form-group p-5">
                                            <div className="row">
                                                <div className="col-12 lg:px-4">
                                                    <label htmlFor="" className="label-tryout">Nama Materi</label> <br />
                                                    <Form.Control value={namaMateri} onChange={(e) => {
                                                        setNamaMateri(e.target.value)
                                                    }} type="text" name="" className="form-tryout mb-3" id="" />
                                                </div>
                                                <div className="row lg:px-4" style={{ marginLeft: 'auto', marginRight: 'auto' }}>
                                                    <div className="col-4">
                                                        <label htmlFor="" className="label-tryout">Tanggal</label> <br />
                                                        <Form.Control value={tanggal} onChange={(e) => {
                                                            const tanggalDate = moment(e.target.value).format('yyyy-MM-DD')
                                                            setTanggal(tanggalDate)
                                                        }} type="date" name="" placeholder="" />
                                                    </div>
                                                    <div className="col-4">
                                                        <label htmlFor="" className="label-tryout">Jam Mulai</label> <br />
                                                        <Form.Control maxLength={5} value={jamMulai} onChange={(e) => {
                                                            setJamMulai(e.target.value)
                                                        }} type="text" name="" placeholder="" />
                                                    </div>
                                                    <div className="col-4">
                                                        <label htmlFor="" className="label-tryout">Jam Selesai</label> <br />
                                                        <Form.Control maxLength={5} value={jamAkhir} onChange={(e) => {
                                                            setJamAkhir(e.target.value)
                                                        }} type="text" name="" placeholder="" />
                                                    </div>
                                                </div>
                                                {/* <div className="col-12 lg:px-4 pt-2 pb-3">
                                                    <label htmlFor="" className="label-tryout">Nama Tutor</label> <br />
                                                    <Form.Control value={namaTutor} onChange={(e) => {
                                                        setNamaTutor(e.target.value)
                                                    }} type="text" name="" className="form-tryout mb-3" id="" />
                                                </div> */}
                                                <div className="col-12 lg:px-4 pt-2 pb-3">
                                                    <label htmlFor="" className="label-tryout">Nama Tutor</label> <br />
                                                    <Form.Select value={tutorId} onChange={(e) => {
                                                        setTutorId(e.target.value);
                                                        for (let t = 0; t < tutors.length; t++) {
                                                            const tutor = tutors[t];
                                                            if(tutor.user_id == e.target.value) {
                                                                setNamaTutor(tutor.user.full_name);
                                                                return;
                                                            }
                                                        }

                                                        
                                                    }} aria-label="Default select example">
                                                        <option value={''}>--Pilih nama tutor--</option>
                                                        {
                                                            tutors.map((item, index) => {
                                                                return <option value={item.user_id}>{item.user.full_name}</option>
                                                            })
                                                        }
                                                    </Form.Select>
                                                </div>
                                            </div>
                                        </div>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <Button className="btn btn-dark" style={{ backgroundColor: '#00' }} onClick={handleClose}>
                                            Close
                                        </Button>
                                        <Button className="btn btn-success" onClick={() => {
                                            addJadwalKelas()
                                        }}>
                                            Submit
                                        </Button>
                                    </Modal.Footer>
                                </Modal>

                                <Modal size="lg" show={showUpdate} onHide={handleUpdateClose}>
                                    <Modal.Header closeButton>
                                        <Modal.Title><strong>Update Jadwal</strong></Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <div className="form-group p-5">
                                            <div className="row">
                                                <div className="col-12 lg:px-4">
                                                    <label htmlFor="" className="label-tryout">Nama Materi</label> <br />
                                                    <Form.Control value={namaMateri} onChange={(e) => {
                                                        setNamaMateri(e.target.value)
                                                    }} type="text" name="" className="form-tryout mb-3" id="" />
                                                </div>
                                                <div className="row lg:px-4" style={{ marginLeft: 'auto', marginRight: 'auto' }}>
                                                    <div className="col-4">
                                                        <label htmlFor="" className="label-tryout">Tanggal</label> <br />
                                                        <Form.Control value={tanggal} onChange={(e) => {
                                                            const tanggalDate = moment(e.target.value).format('yyyy-MM-DD')
                                                            setTanggal(tanggalDate)
                                                        }} type="date" name="" placeholder="" />
                                                    </div>
                                                    <div className="col-4">
                                                        <label htmlFor="" className="label-tryout">Jam Mulai</label> <br />
                                                        <Form.Control maxLength={5} value={jamMulai} onChange={(e) => {
                                                            setJamMulai(e.target.value)
                                                        }} type="text" name="" placeholder="" />
                                                    </div>
                                                    <div className="col-4">
                                                        <label htmlFor="" className="label-tryout">Jam Selesai</label> <br />
                                                        <Form.Control maxLength={5} value={jamAkhir} onChange={(e) => {
                                                            setJamAkhir(e.target.value)
                                                        }} type="text" name="" placeholder="" />
                                                    </div>
                                                </div>
                                                <div className="col-12 lg:px-4 pt-2 pb-3">
                                                    <label htmlFor="" className="label-tryout">Nama Tutor</label> <br />
                                                    <Form.Select value={tutorId} onChange={(e) => {
                                                        setTutorId(e.target.value);
                                                        for (let t = 0; t < tutors.length; t++) {
                                                            const tutor = tutors[t];
                                                            if(tutor.user_id == e.target.value) {
                                                                setNamaTutor(tutor.user.full_name);
                                                                return;
                                                            }
                                                        }
                                                    }} aria-label="Default select example">
                                                        <option value={''}>--Pilih nama tutor--</option>
                                                        {
                                                            tutors.map((item, index) => {
                                                                return <option value={item.user_id}>{item.user.full_name}</option>
                                                            })
                                                        }
                                                    </Form.Select>
                                                </div>
                                            </div>
                                        </div>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <Button className="btn btn-dark" style={{ backgroundColor: '#00' }} onClick={handleUpdateClose}>
                                            Close
                                        </Button>
                                        <Button className="btn btn-success" onClick={() => {
                                            updateJadwalKelas()
                                        }}>
                                            Submit
                                        </Button>
                                    </Modal.Footer>
                                </Modal>
                            </div>
                            <div className="col-md-12 col-lg-12 col-xs-12">
                                {/* <DataTable columns={columns} data={data} pagination /> */}
                                <CustomDatatable 
                                    filteredItems={jadwalKelasData} 
                                    columns={columns}
                                    isLoading={isLoading}
                                    page={page}
                                    perPage={perPage}
                                    hasPrev={hasPrev}
                                    hasNext={hasNext}
                                    count={count}
                                    onTableChange={(e, data) => {
                                        switch (e) {
                                        case 'rowPerPageChange':
                                            setPerPage(data.perPage)
                                            getListJadwalKelas(`page=${page}&perPage=${data.perPage}&keyword=${keyword}`)
                                            break;
                                        case 'rowPageChange':
                                            setPage(data.page)
                                            getListJadwalKelas(`page=${data.page}&perPage=${perPage}&keyword=${keyword}`)
                                            break;
                                        
                                        default:
                                            break;
                                        }
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment >

    )
}