import React, {useState, useEffect} from 'react';
import {
  SideBar
} from '../../components/layout/dashboard';
import { Col, Form, FormGroup, Row, Button, Modal, OverlayTrigger } from 'react-bootstrap';
import DataTable from 'react-data-table-component'
import { useLocation } from 'react-router';
import { Link, useParams } from 'react-router-dom';
import { BsPlayFill } from 'react-icons/bs';
import { HiOutlineDownload, HiStar } from 'react-icons/hi';
import StarRatingComponent from 'react-star-rating-component';
import EcourseService from '../../services/ecourse';
import CustomDatatable from '../../components/common/CustomDatatable';
import moment from 'moment-timezone';
import Swal from 'sweetalert2';


export default function MyMateri () {
  document.title = "INGENIO Indonesia";
  var baseUrl = window.location.origin;

  const [show, setShow] = useState(false);

  const [comment, setComment] = useState('');
  const [rating, setRating] = useState(0);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // Catch Rating value
  const handleRating = (rate) => {
    setRating(rate)
  }

  // Filtering data
  const [isLoading, setIsLoading] = useState(true);
  const [hasNext, setHasNext] = useState(false)
  const [hasPrev, setHasPrev] = useState(false)
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [keyword, setKeyword] = useState('');
  const [kelasData, setKelasData] = useState([]);

  const dropdown = {
      borderRadius: "10px",
      height: 50,
      padding: "25px",
  };

  const { id } = useParams();

  const location = useLocation();
  const { kode_ecourse, ecourse_name } = location.state;

  const columns = [
    {
        name: 'NO.',
        field: 'no',
        index: true,
        selector: row => row.no,
        sortable: true,
        minWidth: '1px'
        
    },
    {
        name: 'NAMA MATERI',
        field: 'name',
        selector: row => row.kelas,
        sortable: true,
        
    },
    {
        name: 'Aksi',
        render: true,
        component: (row) => {
            return (
                <td className='main-table__bodyRow'>
                    <div style={{display: 'flex', flexDirection: 'row'}}>
                      <Link className="btnTable2" to={{
                                    pathname: `/dashboard/course-saya/materi/${id}/akses-video`,
                                    state: {
                                        kode_ecourse,
                                        ecourse_name,
                                        materi_name: row.name,
                                        url_content: row.url_content,
                                        content_type: row.content_type
                                    }
                                }}>View Materi</Link>
                    </div>
                </td>
            )
        },
        selector: row => row.tanggal,
        sortable: true,
        // width: '15vw',
    }
  ];

  // const columns = [
  //   {
  //       name: 'NO.',
  //       selector: row => row.no,
  //       sortable: true,
  //       width: '6.2vw',
  //   },
  //   {
  //       name: 'Nama Materi',
  //       selector: row => row.nama,
  //       sortable: true,
  //       // width: '18vw',
  //   },
  //   {
  //       name: 'Aksi',
  //       selector: row => row.aksi,
  //       sortable: true,
  //       // width: '18vw',
  //   },
  // ];
  const data = [
    {
        no: 1,
        nama: 'Materi A.',
        aksi: <Link className="btnTable2" to="/dashboard/course-saya/materi/akses-video">View Video Materi</Link>,
    },
  ];

  const customStyles = {
    table: {
      style: {
        borderRadius: '15px',
        border: '1px solid #BDC1C9',
      },
    },
    head: {
      style: {
        fontSize: '1.25rem',
        textTransform: "uppercase",
        color: '#6B7280',
        backgroundColor: '#f3f4f6',
        border: '1px solid #BDC1C9',
        borderTopLeftRadius: '15px',
        borderTopRightRadius: '15px',
      },
    },
    pagination: {
      style: {
        color: '#6B7280',
        fontSize: '1.1rem',
        minHeight: '56px',
        backgroundColor: '#f3f4f6',
        border: '1.25px solid #BDC1C9',
        borderBottomLeftRadius: '15px',
        borderBottomRightRadius: '15px',
      },
    },
  };

  const InputRatingEcourse = (body) => {
    setIsLoading(true)
    EcourseService.inputRatingEcourse(body).then(resp => {
        Succeed()
        setIsLoading(false)
    }).catch(err => {
        setIsLoading(false)
        let msg = 'Internal Server Error'
        console.error(err)
        if(err.response) {
            if(err.response.data.message) {
                msg = err.response.data.message
            }
        }

        Fail(msg)
    })
  }

  const getListKelas = (params = '') => {
    setIsLoading(true)
    EcourseService.getListContentEcourseParticipant(id, params).then(resp => {
        const response = resp.data;
        setKelasData(response.data);

        setPage(response.meta.page)
        setPerPage(response.meta.perPage)
        setCount(response.meta.count)

        setHasNext(response.meta.hasNext)
        setHasPrev(response.meta.hasPrev);
        
        setIsLoading(false)
    }).catch(err => {
        let msg = 'Internal Server Error'
          setIsLoading(false)
          console.error(err)
          if(err.response) {
            if(err.response.data.message) {
              msg = err.response.data.message
            }
          }

          Fail(msg)
    })
  }

  const Fail = (message) => {
      Swal.fire({  
          title: 'Gagal!',  
          text: message,  
          icon: 'warning',  
          showCancelButton: true,  
          cancelButtonText: 'Kembali',           
          showConfirmButton: false,  
      });   
  }

  const Succeed = () => {
    Swal.fire({  
        title: 'Data Berhasil Tersimpan !',  
        text: '',  
        icon: 'success',  
        showCancelButton: false,              
        confirmButtonText: 'OK'  
    }).then((result) => {
      if (result.isConfirmed) {
        getListKelas();
        handleClose()
      }
    })
}

  useEffect(() => {
      getListKelas();
  }, [])

  return(
    <React.Fragment>
      <SideBar />
      <div className="md:ml-64 styleHead" style={{ backgroundColor: '#F3F4F6', minWidth: '100vh' }}>
        {/* <div className="bg-orange md:px-8" style={{height: '180px', position: 'relative', padding: '0 30px'}}>
            <h1 className="tracking-wider title-24-600">Materi Saya</h1><br />
            
            <img src={baseUrl + "/assets/logo/evaluasikelas.png"} className="img-opacity"></img>
        </div> */}

        <div className="container md:pr-8 md:pl-10"  style={{position: 'absolute', top: '80px'}}>
          <h1 className="tracking-wider title-24-600">Materi Saya</h1><br />
          {/* <h3 className="text-left tracking-wider">Anda dapat menghubungi Admin terlebih dahulu untuk memastikan kelas dan program yang Anda daftarkan. <br /> <br />Kontak Admin <a href={contactLink()} target={'_blank'}> <i class="fa fa-whatsapp" aria-hidden="true"></i></a></h3> */}
          {/* <img src={baseUrl + "/assets/logo/evaluasikelas.png"} className="img-opacity"></img> */}
        </div> 

        <div className="px-3 md:px-8 pb-5">
          <div className="container mx-auto max-w-full">
            <div className="row">
              <div className="col-12 my-4">
                <FormGroup>
                <div className="justify-start">
                  <div className="SearchTable">
                    <input value={keyword} onChange={(e) => setKeyword(e.target.value)} type="text" name="" className="inputSearchTable" placeholder="&#x1F50E;&#xFE0E; Search..." id="" />
                    <Button onClick={() => {
                            getListKelas(`page=${page}&perPage=${perPage}&keyword=${keyword}`)
                        }} className="btnSearchTable">Cari</Button>
                  </div>
                  <Button onClick={() => {handleShow()}} className="btnOrange2">
                      <div className="titleBtnOrange">
                        <HiStar size="23px" className="mr-2"/>Give Rate
                      </div> 
                  </Button>
                </div>
                </FormGroup>
              </div>
              <div className="col-12 mt-3 mb-5">
                  {/* <DataTable columns={columns} customStyles={customStyles} data={data} pagination /> */}
                  <CustomDatatable 
                      filteredItems={kelasData} 
                      columns={columns}
                      isLoading={isLoading}
                      page={page}
                      perPage={perPage}
                      hasPrev={hasPrev}
                      hasNext={hasNext}
                      count={count}
                      onTableChange={(e, data) => {
                          switch (e) {
                          case 'rowPerPageChange':
                              setPerPage(data.perPage)
                              getListKelas(`page=${page}&perPage=${data.perPage}&keyword=${keyword}`)
                              break;
                          case 'rowPageChange':
                              setPage(data.page)
                              getListKelas(`page=${data.page}&perPage=${perPage}&keyword=${keyword}`)
                              break;
                          
                          default:
                              break;
                          }
                      }}
                  />
              </div>
            </div>
          </div>
        </div>
        <Modal show={show} onHide={handleClose}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered>
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    <div className="title-20-700">Beri Nilai</div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row Ecourse p-4">
                    <div className="col-12" style={{display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column'}}>
                        <div className="flex">
                            <StarRatingComponent
                              style={{fontSize: '100px'}}
                              name="rate1"
                              starCount={5}
                              value={rating}
                              emptyStarColor={'#D3D3D3'}
                              onStarClick={(nextValue, prevValue, name) => setRating(nextValue)}
                            />
                        </div>
                        <div className="flex">
                          <textarea value={comment} onChange={(e) => setComment(e.target.value)} type="text" placeholder='Masukan komentar...' rows={6} cols={100} className="form-tryout" style={{'height': 'auto', padding: '1rem'}} id="" />
                        </div>
                    </div>
                    <div className="col-12 justify-end mt-4">
                        <Button type="submit" onClick={() => InputRatingEcourse({ id_ecourse: id, rate: rating, comment })} className="btnOrange3">
                            <span className="txt-btn">Submit</span>
                        </Button>
                    </div>
                </div>
            </Modal.Body>
        </Modal>

      </div>
    </React.Fragment>
    
  )
}