import React from 'react';
import { useState, useEffect } from 'react';
import AdminSideBar from '../../../components/layout/dashboard/admin/AdminSideBar';
import Button from '@material-tailwind/react/Button';
import { FormGroup, Form,  Modal } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import { Link } from 'react-router-dom';
import { MdOutlineNoteAdd, MdOutlineAddCircleOutline } from 'react-icons/md';
import { HiOutlinePencilAlt, HiOutlineTrash } from 'react-icons/hi';
import AuthService from '../../../services/auth'
import CustomDatatable from '../../../components/common/CustomDatatable';
import CustomSwitchToggle from '../../../components/common/CustomSwitchToggle';
import moment from 'moment-timezone';
import Swal from 'sweetalert2';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';


export default function AdminUsers (props) {
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  // Filtering data
  const [isLoading, setIsLoading] = useState(true);
  const [hasNext, setHasNext] = useState(false)
  const [hasPrev, setHasPrev] = useState(false)
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [keyword, setKeyword] = useState('');
  const [kelasData, setKelasData] = useState([]);

  // Add data
  const [inIdUser, setInIdUser] = useState('')
  const [inNama, setInNama] = useState('');
  const [inEmail, setInEmail] = useState('');
  const [inPassword, setInPassword] = useState('');
  const [inRolecode, setInRolecode] = useState('');

  document.title = "INGENIO Indonesia";
  const [showSidebar, setShowSidebar] = useState('-left-64');
  var baseUrl = window.location.origin;

  const columns = [
    {
        name: 'NO.',
        field: 'no',
        index: true,
        selector: row => row.no,
        sortable: true,
        minWidth: '1px'
    },
    {
        name: 'NAMA',
        render: true,
        component: (row) => {
            return (
                <td className='main-table__bodyRow'>{row.full_name}</td>
            )
        },
        selector: row => row.tanggal,
        sortable: true,
        // width: '15vw',
    },
    {
      name: 'EMAIL',
      render: true,
      component: (row) => {
          return (
              <td className='main-table__bodyRow'>{row.email}</td>
          )
      },
      selector: row => row.tanggal,
      sortable: true,
      // width: '15vw',
  },
  {
      name: 'ROLE',
      render: true,
      component: (row) => {
          return (
              <td className='main-table__bodyRow'>{row.role}</td>
          )
      },
      selector: row => row.tanggal,
      sortable: true,
      // width: '15vw',
  },
  {
      name: 'Aksi',
      render: true,
      component: (row) => {
          return (
              <td className='main-table__bodyRow'>
                  <div style={{display: 'flex', flexDirection: 'row'}}>
                      <OverlayTrigger placement="top" overlay={<Tooltip id="button-tooltip-2">Edit</Tooltip>}>
                        <Link to='#' className="btnCircleBlack mr-3" onClick={() => {
                          setInIdUser(row.user_id);
                          setInNama(row.full_name);
                          setInEmail(row.email);
                          setInPassword(null)
                          setInRolecode(roleNameToRoleCodeParser(row.role));
                          handleShow2();
                        }}><HiOutlinePencilAlt size="22"/></Link>
                      </OverlayTrigger>
                      <Button onClick={() => {
                        ResendActivation(row.email)
                      }} className="justify-center mx-3" style={{padding: '6px', background: '#F8CA2B', width: '110px' }}>
                        <span style={{color: 'black', fontSize: '14px'}}>Resend Activation</span> 
                      </Button>
                      <Button onClick={() => {
                        SendResetPassword(row.user_id)
                      }} className="justify-center mx-3" style={{padding: '6px', background: '#F8CA2B', width: '110px' }}>
                        <span style={{color: 'black', fontSize: '14px'}}>Reset Password</span> 
                      </Button>
                      <Button onClick={() => {
                        ResetSessionUser(row.email)
                      }} className="justify-center mx-3" style={{padding: '6px', background: '#F8CA2B', width: '110px' }}>
                        <span style={{color: 'black', fontSize: '14px'}}>Reset Session</span> 
                      </Button>
                      <CustomSwitchToggle checked={row.is_active} onChange={(e) => {
                        EditStatusTutor(row.user_id, {is_active: e.target.checked})
                      }} />
                  </div>
              </td>
          )
      },
      selector: row => row.tanggal,
      sortable: true,
      // width: '15vw',
  },
];
  const data = [
      {
          no: 1,
          username: 'Soleh',
          email: 'solehkece@gmail.com',
          password: 'admin123',
          role: 'admin',
      },
      {
          no: 2,
          username: 'Aceng',
          email: 'aceng666@gmail.com',
          password: 'admin123',
          role: 'Peserta',
      },
  ];

  const customStyles = {
      table: {
        style: {
          borderRadius: '15px',
          border: '1px solid #BDC1C9',
        },
      },
      head: {
        style: {
          fontSize: '1.25rem',
          textTransform: "uppercase",
          color: '#6B7280',
          backgroundColor: '#f3f4f6',
          border: '1px solid #BDC1C9',
          borderTopLeftRadius: '15px',
          borderTopRightRadius: '15px',
        },
      },
      pagination: {
        style: {
          color: '#6B7280',
          fontSize: '1.1rem',
          minHeight: '56px',
          backgroundColor: '#f3f4f6',
          border: '1.25px solid #BDC1C9',
          borderBottomLeftRadius: '15px',
          borderBottomRightRadius: '15px',
        },
      },
  };

  const [passwordType, setPasswordType] = useState("password");
  const [passwordInput, setPasswordInput] = useState("");
  const handlePasswordChange =(evnt)=>{
      setPasswordInput(evnt.target.value);
  }
  const togglePassword =()=>{
    if(passwordType==="password")
    {
     setPasswordType("text")
     return;
    }
    setPasswordType("password")
  }

  const [passwordType2, setPasswordType2] = useState("password");
  const [passwordInput2, setPasswordInput2] = useState("");
  const handlePasswordChange2 =(evnt)=>{
      setPasswordInput2(evnt.target.value);
  }
  const togglePassword2 =()=>{
    if(passwordType2==="password")
    {
     setPasswordType2("text")
     return;
    }
    setPasswordType2("password")
  }

  const AreYouSure = () => {
    Swal.fire({  
        title: 'Sudah Yakin?',  
        text: 'Apakah Anda Yakin Ingin Menghapus Data Ini?',  
        icon: 'warning',  
        showCancelButton: true,  
        cancelButtonText: 'Tidak',  
        confirmButtonText: 'Ya'  
    })
  }

  const Submit = () => {
    Swal.fire({  
        title: 'Berhasil!',  
        text: '',  
        icon: 'success',  
        showCancelButton: false,              
        confirmButtonText: 'OK'  
    }).then((result) => {
      if (result.isConfirmed) {
        getListKelas()
      }
    })
  }

const EditSucceed = () => {
    Swal.fire({  
        title: 'Data Berhasil Tersimpan !',  
        text: '',  
        icon: 'success',  
        showCancelButton: false,              
        confirmButtonText: 'OK'  
    }).then((result) => {
      if (result.isConfirmed) {
        window.location.reload();
      }
    })
  }

  const AddUser = (body) => {
    Swal.fire({  
        title: 'Sudah Yakin?',  
        text: 'Apakah Anda Yakin Ingin Menambah Data Ini?',  
        icon: 'warning',  
        showCancelButton: true,  
        cancelButtonText: 'Tidak',  
        confirmButtonText: 'Ya'  
    }).then((result) => {
      if (result.isConfirmed) {
          setIsLoading(true)
          AuthService.createUserAdmin(body).then(resp => {
              Submit()
              setIsLoading(false)
          }).catch(err => {
            let msg = 'Internal Server Error'
            setIsLoading(false)
            console.error(err)
            if(err.response) {
              if(err.response.data.message) {
                msg = err.response.data.message
              }
            }

            Fail(msg)
          })
      }
    });  
  }

  const UpdateUser = (body) => {
    Swal.fire({  
        title: 'Sudah Yakin?',  
        text: 'Apakah Anda Yakin Ingin Mengubah Data Ini?',  
        icon: 'warning',  
        showCancelButton: true,  
        cancelButtonText: 'Tidak',  
        confirmButtonText: 'Ya'  
    }).then((result) => {
      if (result.isConfirmed) {
          setIsLoading(true)
          AuthService.updateUserAdmin(inIdUser, body).then(resp => {
              Submit()
              setIsLoading(false)
          }).catch(err => {
            let msg = 'Internal Server Error'
            setIsLoading(false)
            console.error(err)
            if(err.response) {
              if(err.response.data.message) {
                msg = err.response.data.message
              }
            }

            Fail(msg)
          })
      }
    });  
  }

  const ResetSessionUser = (email) => {
    AuthService.resetSessionUser(email).then(resp => {
      Submit()
    }).catch(err => {
      let msg = 'Internal Server Error'
      console.error(err)
      if(err.response) {
        if(err.response.data.message) {
          msg = err.response.data.message
        }
      }

      Fail(msg)
    })
  }

  const SendResetPassword = (id) => {
    AuthService.sendResetPasswod(id).then(resp => {
      Submit()
    }).catch(err => {
      let msg = 'Internal Server Error'
      console.error(err)
      if(err.response) {
        if(err.response.data.message) {
          msg = err.response.data.message
        }
      }

      Fail(msg)
    })
  }

  const ResendActivation = (email) => {
    AuthService.resendActivation(email).then(resp => {
      Submit()
    }).catch(err => {
      let msg = 'Internal Server Error'
      console.error(err)
      if(err.response) {
        if(err.response.data.message) {
          msg = err.response.data.message
        }
      }

      Fail(msg)
    })
  }

  const EditStatusTutor = (id, body) => {
    Swal.fire({  
        title: 'Sudah Yakin?',  
        text: 'Apakah Anda Yakin Ingin Mengubah Data Ini?',  
        icon: 'warning',  
        showCancelButton: true,  
        cancelButtonText: 'Tidak',  
        confirmButtonText: 'Ya'  
    }).then((result) => {
      if (result.isConfirmed) {
          setIsLoading(true)
          AuthService.updateStatusTutorAdmin(id, body).then(resp => {
            Submit()
              setIsLoading(false)
          }).catch(err => {
            setIsLoading(false)
            let msg = 'Internal Server Error'
            console.error(err)
            if(err.response) {
              if(err.response.data.message) {
                msg = err.response.data.message
              }
            }

            Fail(msg)
          })
      }
    });  
  }

  const getListKelas = (params = '') => {
    setIsLoading(true)
    AuthService.getListUserAdmin(params).then(resp => {
        const response = resp.data;
        setKelasData(response.data);

        setPage(response.meta.page)
        setPerPage(response.meta.perPage)
        setCount(response.meta.count)

        setHasNext(response.meta.hasNext)
        setHasPrev(response.meta.hasPrev);
        
        setIsLoading(false)
    }).catch(err => {
        setIsLoading(false)
        let msg = 'Internal Server Error'
        console.error(err)
        if(err.response) {
          if(err.response.data.message) {
            msg = err.response.data.message
          }
        }

        Fail(msg)
    })
  }

  const Fail = (message) => {
    Swal.fire({  
        title: 'Gagal!',  
        text: message,  
        icon: 'warning',  
        showCancelButton: true,  
        cancelButtonText: 'Kembali',           
        showConfirmButton: false,  
    }); 
  }

  useEffect(() => {
    getListKelas()
  }, [])

  const roleNameToRoleCodeParser = (role) => {
    // when au.rolecode = 1 then 'Admin'
    // when au.rolecode = 2 then 'Akademik'
    // when au.rolecode = 3 then 'Tutor'
    // when au.rolecode = 4 then 'Peserta'
    // when au.rolecode = 5 then 'Logistik'
    // when au.rolecode = 6 then 'Akuntan'

    switch (role) {
      case 'Admin':
        return 1;
        
      case 'Akademik':
        return 2;
        
      case 'Tutor':
        return 3;
        
      case 'Peserta':
        return 4;
        
      case 'Logistik':
        return 5;

      case 'Akuntan':
        return 6;
    
      default:
        return 0;
    }
  }

  return(
    <React.Fragment>
      <AdminSideBar />
      <div className="md:ml-64 styleHead" style={{ backgroundColor: '#F3F4F6' }}>
        <div className="bg-orange md:px-8 pb-5" style={{height: '20vh', position: 'relative', padding: '0 30px 30px 30px'}}>
            <h1 className="tracking-wider title-24-600">User Administrator</h1>
            {/* <h2 className="tracking-wider title-20-700 mt-5">Selamat Datang Tutor INGENIO !</h2> */}
            <img src={baseUrl + "/assets/logo/evaluasikelas.png"} className="img-opacity"></img>
        </div>

        <div className="px-3 md:px-8 pb-5">
            <div className="container mx-auto max-w-full">
                <div className="row mt-5">
                  <div className="col-12 my-2">
                    <FormGroup>
                      <div className="justify-start">
                        <div className="SearchTable">
                            <input onChange={(e) => {
                              setKeyword(e.target.value)
                            }} type="text" name="" className="inputSearchTable" placeholder="&#x1F50E;&#xFE0E; Search..." id="" />
                            <Button onClick={() => {
                                getListKelas(`page=${page}&perPage=${perPage}&keyword=${keyword}`)
                            }}  className="btnSearchTable">Cari</Button>
                        </div>
                        <Button className="btnOrange2" onClick={handleShow}>
                            <div className="titleBtnOrange">
                                <MdOutlineAddCircleOutline size="23px" className="mr-2"/>Tambah User
                            </div> 
                        </Button>
                      </div>
                    </FormGroup>
                  </div>
                  <div className="col-12 mt-3">
                    {/* <DataTable columns={columns} customStyles={customStyles} data={data} pagination /> */}
                    <CustomDatatable 
                          filteredItems={kelasData} 
                          columns={columns}
                          isLoading={isLoading}
                          page={page}
                          perPage={perPage}
                          hasPrev={hasPrev}
                          hasNext={hasNext}
                          count={count}
                          onTableChange={(e, data) => {
                              switch (e) {
                              case 'rowPerPageChange':
                                  setPerPage(data.perPage)
                                  getListKelas(`page=${page}&perPage=${data.perPage}&keyword=${keyword}`)
                                  break;
                              case 'rowPageChange':
                                  setPage(data.page)
                                  getListKelas(`page=${data.page}&perPage=${perPage}&keyword=${keyword}`)
                                  break;
                              
                              default:
                                  break;
                              }
                          }}
                      />
                  </div>
                </div>
                <Modal show={show} onHide={handleClose}
                      {...props}
                      size="md"
                      aria-labelledby="contained-modal-title-vcenter"
                      centered>
                  <Modal.Header closeButton>
                      <Modal.Title id="contained-modal-title-vcenter">
                          <div className="title-20-700">Tambah User</div>
                      </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                      <div className="row p-4">
                          <div className="col-12">
                              <div className="title-18-500 mb-1">Name</div>
                              <input onChange={(e) => {
                                setInNama(e.target.value)
                              }} type="text" class="formInputJurnal" placeholder="masukkan nama..." />

                              <div className="title-18-500 mb-1">Email</div>
                              <input onChange={(e) => {
                                setInEmail(e.target.value)
                              }} type="text" class="formInputJurnal" placeholder="masukkan email..." />

                              {/* <div className="title-18-500 mb-1">Password</div>
                              <div className="relative">
                                <input type={passwordType} onChange={(e) => {
                                  setInPassword(e.target.value)
                                }} name="password" class="formInputJurnal" placeholder="Password" />
                                <button className="btnPass" onClick={togglePassword}>
                                { passwordType==="password"? <i className="far fa-eye-slash"></i> :<i className="far fa-eye"></i> }
                                </button>
                              </div> */}

                              <div className="title-18-500 mb-1">Role</div>
                              <Form.Select
                                as="select"
                                aria-label="Program"
                                aria-describedby="basic-addon1"
                                className="formInput3"
                                onChange={(e) => {
                                  setInRolecode(e.target.value);
                                }}
                              >
                                  <option selected disabled>Pilih Role</option>
                                  <option value="1">Admin</option>
                                  <option value="2">Akademik</option>
                                  <option value="6">Akuntan</option>
                                  <option value="5">Logistik</option>
                                  <option value="4">Peserta</option>
                                  <option value="3">Tutor</option>
                              </Form.Select>
                          </div>
                          <div className="col-12 justify-end mt-4">
                              <Button type="submit" onClick={() => {
                                AddUser({
                                  full_name: inNama,
                                  nick_name: inNama,
                                  email: inEmail,
                                  password: 'random',
                                  rolecode: inRolecode
                                })
                              }} className="btnOrange3">
                                  <span className="txt-btn">Submit</span>
                              </Button>
                          </div>
                      </div>
                  </Modal.Body>
                </Modal>
                <Modal show={show2} onHide={handleClose2}
                      {...props}
                      size="md"
                      aria-labelledby="contained-modal-title-vcenter"
                      centered>
                  <Modal.Header closeButton>
                      <Modal.Title id="contained-modal-title-vcenter">
                          <div className="title-20-700">Edit User</div>
                      </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                      <div className="row p-4">
                          <div className="col-12">
                          <div className="title-18-500 mb-1">Name</div>
                              <input value={inNama} onChange={(e) => {
                                setInNama(e.target.value)
                              }} type="text" class="formInputJurnal" placeholder="masukkan nama..." />

                              <div className="title-18-500 mb-1">Email</div>
                              <input value={inEmail} onChange={(e) => {
                                setInEmail(e.target.value)
                              }} type="text" class="formInputJurnal" placeholder="masukkan email..." />

                              {/* <div className="title-18-500 mb-1">Password</div>
                              <div className="relative">
                                <input value={inPassword} type={passwordType} onChange={(e) => {
                                  setInPassword(e.target.value)
                                }} name="password" class="formInputJurnal" placeholder="Password" />
                                <button className="btnPass" onClick={togglePassword}>
                                { passwordType==="password"? <i className="far fa-eye-slash"></i> :<i className="far fa-eye"></i> }
                                </button>
                              </div> */}

                              <div className="title-18-500 mb-1">Role</div>
                              <Form.Select
                                as="select"
                                aria-label="Program"
                                aria-describedby="basic-addon1"
                                className="formInput3"
                                value={inRolecode}
                                onChange={(e) => {
                                  setInRolecode(e.target.value);
                                }}
                              >
                                  <option selected disabled>Pilih Role</option>
                                  <option value="1">Admin</option>
                                  <option value="2">Akademik</option>
                                  <option value="6">Akuntan</option>
                                  <option value="5">Logistik</option>
                                  <option value="4">Peserta</option>
                                  <option value="3">Tutor</option>
                              </Form.Select>
                          </div>
                          <div className="col-12 justify-end mt-4">
                              <Button type="submit" onClick={() => {
                                UpdateUser({
                                  full_name: inNama,
                                  nick_name: inNama,
                                  email: inEmail,
                                  rolecode: inRolecode
                                })
                              }} className="btnOrange3">
                                  <span className="txt-btn">Submit</span>
                              </Button>
                          </div>
                      </div>
                  </Modal.Body>
                </Modal>
            </div>
        </div>

      </div>
    </React.Fragment>
    
  )
}
