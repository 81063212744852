import React from 'react';
import { Card, Button } from 'react-bootstrap';

export default function Hubungikami() {

    return (
        <React.Fragment>
            <section className="container-fluid">
                <div className="site-header site-header--menu-left landing-11-menu site-header--absolute" style={{ backgroundImage: 'url(/assets/landing/Genio2.png)', backgroundPositionY: 'bottom', backgroundPositionX: 'right', backgroundRepeat: 'no-repeat', paddingTop: '6rem', backgroundColor: '#ffcc00' }}>
                    <h5 className="text-center tentang_title py-5" style={{ paddingBottom: '2rem !important' }}>HUBUNGI KAMI</h5>
                </div>
            </section>
            <section className="container-fluid bg-desktop" style={{ backgroundImage: 'url(/assets/landing/bg-value.png)' }}>
                <div className="container px-4" style={{ paddingTop: '10rem' }}>
                    <div className="row g-2 pdd10r" style={{ textAlign: 'justify', paddingTop: '11rem' }}>
                        <h5 className="" style={{ fontWeight: '500', textAlign: 'center', fontSize: '26px', lineHeight: '32px' }}>
                            Butuh Bantuan? Silahkan hubungi kami.
                        </h5>

                        <h5 className="" style={{ paddingBottom: '1rem', textAlign: 'center', paddingBottom: '5rem', fontSize: '16px' }}>
                            Mulai dari cara pendaftaran, pembelian, hingga cara tryout.
                        </h5>

                        <div className="col-lg-6 col-md-6 col-sm-12 mx-auto">
                            <Card style={{ height: '34rem', width: '100%', position: 'relative', marginBottom: '2.5rem', paddingBottom: '2rem' }} className="zoom mx-auto">
                                <Card.Body>
                                    <img src="/assets/icons/icon-comp.png" style={{ borderRadius: '50%', marginLeft: 'auto', marginRight: 'auto' }} alt="Icon"></img>
                                    <h5 className="" style={{ fontWeight: '500', textAlign: 'center', fontSize: '22px', lineHeight: '27px' }}>
                                        Kantor Pusat
                                    </h5>
                                    <h5 className="" style={{ fontWeight: 'normal', textAlign: 'center', fontSize: '14px', lineHeight: '17px' }}>
                                        PT. INGENIO MEDIKA NUSANTARA
                                    </h5>
                                    <hr style={{ width: '30%', marginLeft: 'auto', marginRight: 'auto' }} />

                                    <div className="row" style={{ paddingTop: '2rem' }}>
                                        <div className="col-10 mx-auto" style={{ paddingTop: '0.3rem' }}>
                                            <table width="100%" height="230px" style={{ color: '#68727E', fontSize: '14px', lineHeight: '17px' }}>
                                                <tr>
                                                    <td style={{ width: '30%' }}>
                                                        <img className="mx-auto" src="/assets/icons/map-mark.png" />
                                                    </td>
                                                    <td>
                                                        <h5 className="t18p" style={{ textAlign: 'left' }}>
                                                            Ruko Ditas Jl. MT. Haryono No.14, Dinoyo, Kec. Lowokwaru, Kota Malang, Jawa Timur 65145, Indonesia
                                                        </h5>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style={{ width: '30%' }}>
                                                        <img className="mx-auto" src="/assets/icons/at-sign.png" />
                                                    </td>
                                                    <td>
                                                        <h5 className="t18p" style={{ textAlign: 'left' }}>
                                                            informationtechnology@gmail.com
                                                        </h5>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style={{ width: '30%' }}>
                                                        <img className="mx-auto" src="/assets/icons/phone.png" />
                                                    </td>
                                                    <td>
                                                        <h5 className="t18p" style={{ textAlign: 'left' }}>
                                                            081358889123 
                                                        </h5>
                                                    </td>
                                                </tr>
                                            </table>
                                        </div>
                                    </div>
                                    <Card.Text className="aboutcard-text">
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </div>

                        <div className="peta">
                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3951.5154952623475!2d112.61012141472688!3d-7.945560294276899!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2dd629e99d965e9b%3A0x7bb648e805d19e4c!2sIngenio%20Learning%20Center!5e0!3m2!1sid!2sus!4v1633003555428!5m2!1sid!2sus" style={{ border: '0', width: '80%', height: '466px', marginLeft: 'auto', marginRight: 'auto' }} allowfullscreen="" loading="lazy"></iframe>
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment >
    )
}

