import React from 'react';
import {
  IngenioNav,
  IngenioFooter
} from '../components/layout';
import {
  Content
} from '../components/passconfirm'

export default function KonfirmasiPasswordPage () {
  document.title = "INGENIO Indonesia | Konfirmasi Password";
  return(
    <React.Fragment>
      <IngenioNav />
        <div style={{backgroundColor: 'white'}}>
          <Content />
        </div>
      <IngenioFooter bgColor="white"/>
    </React.Fragment>
    
  )
}