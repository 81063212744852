import Button from '@material-tailwind/react/Button';
import React, { useEffect, useState } from 'react';
import { FormGroup, FormControl, Form, Modal } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import { Link, useParams } from 'react-router-dom';
import { BsPencilSquare, BsArrowLeftShort, BsCloudDownload } from 'react-icons/bs';
import TutorSidebar from '../../../components/layout/dashboard/tutor/TutorSideBar';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import CustomDatatable from '../../../components/common/CustomDatatable';
import { useLocation } from 'react-router';
import ElearningService from '../../../services/elearning';
import moment from 'moment-timezone';
import Swal from 'sweetalert2';
import { RiWubiInput } from 'react-icons/ri';

export default function Absensi() {
    var baseUrl = window.location.origin;

    document.title = "INGENIO Indonesia";
    const [show, setShow] = useState(false);

    // Filtering data
    const [getColumns, setColumns] = useState([])
    const [isLoading, setIsLoading] = useState(true);
    const [hasNext, setHasNext] = useState(false)
    const [hasPrev, setHasPrev] = useState(false)
    const [count, setCount] = useState(0);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [keyword, setKeyword] = useState('');
    const [kelasData, setKelasData] = useState([]);
    const [downloading, setDownloading] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const { kelasid } = useParams();
    const location = useLocation();
    const { nama_kelas, nama_regio, elearning_program } = location.state

    const dropdown = {
        borderRadius: "10px",
        height: 50,
        padding: "25px",
    };
    
    const columns = [
        {
            name: 'NO.',
            field: 'no',
            index: true,
            selector: row => row.no,
            sortable: true,
            width: '6.2vw',
        },
        {
            name: 'KODE PESERTA',
            field: 'kode_peserta',
            selector: row => row.kode,
            sortable: true,
            // width: '15vw',
        },
        {
            name: 'NAMA PESERTA',
            field: 'nama_peserta',
            selector: row => row.nama,
            sortable: true,
            // width: '15vw',   
        },
        {
            name: 'PERTEMUAN ke',
            render: true,
            component: (row) => {
              return (
                <td className='main-table__bodyRow'>
                    <div style={{width: '100%', display: 'flex', flexDirection: 'row'}}>
                        {
                            (row.detailKehadirans && row.detailKehadirans !== null) ?
                                (row.detailKehadirans.length > 0) ?
                                row.detailKehadirans.map((item, i) => {
                                    return (
                                        <div style={{display: 'flex', flexDirection: 'column', paddingRight: '25px'}}>
                                            <span>{item.pertemuan_ke}</span>
                                            <span>{item.status_absen === 'N/A' ? '-':item.status_absen}</span>
                                        </div>
                                    )
                                })
                                :
                                null
                            :
                            null
                        }
                    </div>
                </td>
              )
            },
            selector: row => row.p1,
            sortable: true,
            minWidth: '150px'
            // width: '13vw',
        },
        {
            name: 'Hadir',
            field: 'total_hadir',
            selector: row => row.hadir,
            sortable: true,
            // width: '8vw',
        },
        {
            name: 'Sakit',
            field: 'total_sakit',
            selector: row => row.sakit,
            sortable: true,
            // width: '8vw',
        },
        {
            name: 'Izin',
            field: 'total_izin',
            selector: row => row.izin,
            sortable: true,
            // width: '8vw',
        },
        {
            name: 'Alpa',
            field: 'total_alfa',
            selector: row => row.alfa,
            sortable: true,
            // width: '8vw',
        },
        {
            name: '%',
            render: true,
            component: (row) => {
              return (
                <td className='main-table__bodyRow'>
                    <span>{`${parseFloat(row.percent_kehadiran+'').toFixed(2)}%`}</span>
                </td>
              )
            },
            selector: row => row.percent,
            sortable: true,
            // width: '8vw',
        },
    ];
    const data = [
        {
            no: 1,
            kode: '12345.',
            nama: 'Bambang.',
            p1: 'H',
            p2: 'H',
            p3: 'H',
            hadir: '3',
            sakit: '0',
            izin: '0',
            alfa: '0',
            percent: '100%',
        },
    ];

    const customStyles = {
        table: {
          style: {
            borderRadius: '15px',
            border: '1px solid #BDC1C9',
          },
        },
        head: {
          style: {
            fontSize: '1.25rem',
            textTransform: "uppercase",
            color: '#6B7280',
            backgroundColor: '#f3f4f6',
            border: '1px solid #BDC1C9',
            borderTopLeftRadius: '15px',
            borderTopRightRadius: '15px',
          },
        },
        pagination: {
          style: {
            color: '#6B7280',
            fontSize: '1.1rem',
            minHeight: '56px',
            backgroundColor: '#f3f4f6',
            border: '1.25px solid #BDC1C9',
            borderBottomLeftRadius: '15px',
            borderBottomRightRadius: '15px',
          },
        },
    };

    const getListKelasParticipant = (params = '') => {
        setIsLoading(true)
        ElearningService.getListAbsensiKelasTutor(kelasid, params).then(resp => {
            const response = resp.data;
            
            setKelasData(response.data);

            setPage(response.meta.page)
            setPerPage(response.meta.perPage)
            setCount(response.meta.count)

            setHasNext(response.meta.hasNext)
            setHasPrev(response.meta.hasPrev);
            
            setIsLoading(false)
        }).catch(err => {
            setIsLoading(false)
            console.error(err)
        })
    }

    const downloadRekapAbsensi = () => {
        setDownloading(true)
        ElearningService.downloadRekapAbsensiTutor(kelasid)
          .then(resp => {
            const url = resp.data.data.url;
            window.location.replace(url)
            setDownloading(false)
          }).catch(err => {
            console.error(err)
            Swal.fire({  
              title: 'Gagal!',  
              text: 'Data tidak ditemukan',  
              icon: 'warning',  
              showCancelButton: true,  
              cancelButtonText: 'Kembali',           
              showConfirmButton: false,  
          });  
            setDownloading(false)
          })
      }

    useEffect(() => {
        getListKelasParticipant();
    }, [])

    return (
        <React.Fragment>
            <TutorSidebar />
            <div className="md:ml-64 tutorDataKelas" style={{ backgroundColor: '#F3F4F6' }}>
                <div className="bg-orange md:px-8 pb-5" style={{height: '24vh', position: 'relative', padding: '0 30px 30px 30px'}}>
                    <h1 className="tracking-wider title-24-600">Absensi Kelas</h1>
                    <h2 className="tracking-wider title-20-700 mt-5">Selamat Datang Tutor INGENIO !</h2>
                    <img src={baseUrl + "/assets/logo/evaluasikelas.png"} className="img-opacity"></img>
                </div>

                <div className="px-3 md:px-8 mb-5">
                    <div className="container mx-auto max-w-full">
                        <div className="justify-start mt-4 mb-3">
                            <Link to="/dashboard/tutor/data-kelas">
                                <div className="navBack">
                                    <BsArrowLeftShort size="19px"/>Kembali
                                </div> 
                            </Link>
                        </div>
                        <div className="row">
                            <div className="col-12 mb-4">
                                <FormGroup>
                                    <div className="flex mb-2">
                                        <Button onClick={() => {
                                            if(downloading)
                                                return;
                                            downloadRekapAbsensi()
                                        }} className="btnOrange">
                                            <div className="titleBtnOrange">
                                                <BsCloudDownload size="20px" className="mr-2"/>{downloading ? 'Processing...':'Unduh Rekap Absensi'}
                                            </div> 
                                        </Button>
                                        {
                                            kelasData.length > 0 ?
                                            <Button className="btnOrange2">
                                                <Link 
                                                    to={{
                                                        pathname: `/dashboard/tutor/data-kelas/absensi/input-absensi/${kelasid}`,
                                                        state: {
                                                            nama_kelas, 
                                                            nama_regio, 
                                                            elearning_program
                                                        }
                                                      }}>
                                                    <div className="titleBtnOrange">
                                                        <BsPencilSquare size="20px" className="mr-2"/>Input Absensi
                                                    </div> 
                                                </Link>
                                            </Button>
                                            :
                                            <Button className="btnOrange2 btnDisabled">
                                                <div className="titleBtnOrange">
                                                    <BsPencilSquare size="20px" className="mr-2"/>Input Absensi
                                                </div> 
                                            </Button>
                                        }
                                        
                                    </div>
                                </FormGroup>
                            </div>
                            <div className="col-md-12 col-lg-12 col-xs-12">
                                {/* <DataTable columns={columns} customStyles={customStyles} data={data} pagination /> */}
                                <CustomDatatable 
                                    filteredItems={kelasData} 
                                    columns={columns}
                                    isLoading={isLoading}
                                    page={page}
                                    perPage={perPage}
                                    hasPrev={hasPrev}
                                    hasNext={hasNext}
                                    count={count}
                                    onTableChange={(e, data) => {
                                        switch (e) {
                                        case 'rowPerPageChange':
                                            setPerPage(data.perPage)
                                            getListKelasParticipant(`page=${page}&perPage=${data.perPage}&keyword=${keyword}`)
                                            break;
                                        case 'rowPageChange':
                                            setPage(data.page)
                                            getListKelasParticipant(`page=${data.page}&perPage=${perPage}&keyword=${keyword}`)
                                            break;
                                        
                                        default:
                                            break;
                                        }
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment >

    )
}