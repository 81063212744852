import React, { useEffect, useState } from "react";
import { BsImage } from "react-icons/bs";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import { useDropzone } from 'react-dropzone';

const thumbsContainer = {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: 16
};

const thumb = {
    display: "inline-flex",
    borderRadius: 2,
    border: "1px solid #eaeaea",
    marginBottom: 8,
    marginRight: 8,
    width: 100,
    height: 100,
    padding: 4,
    boxSizing: "border-box"
};

const thumbInner = {
    display: "flex",
    minWidth: 0,
    overflow: "hidden"
};

const img = {
    display: "block",
    width: "auto",
    height: "100%"
};

const UploadGambar = ({ onChange, ...rest }) => {
    // const blocksFromHtml = htmlToDraft(props.value);
    // const { contentBlocks, entityMap } = blocksFromHtml;
    let formData = new FormData();

    const { acceptedFiles: gambar, getRootProps: getRootPropsSoal, getInputProps: getInputPropsSoal } = useDropzone({
        accept: "image/*",
        onDrop: gambar => {
            setFiles(
                gambar.map(file =>
                    Object.assign(file, {
                        preview: URL.createObjectURL(file)
                    })
                )
            );
        },
        ...rest
    });

    const [files, setFiles] = React.useState([]);

    const thumbs = files.map(file => (
        <div style={thumb} key={file.name}>
            <div style={thumbInner}>
                <img src={file.preview} style={img} />
            </div>
        </div>
    ));

    useEffect(
        () => () => {
            // Make sure to revoke the data uris to avoid memory leaks
            files.forEach(file => URL.revokeObjectURL(file.preview));
        },
        [files]
    );

    return (
        <React.Fragment>
            <div {...getRootPropsSoal({ className: 'dropzone p-1 mr-3 content-center', style: { border: '2px dotted black' } })}>
                <input {...getInputPropsSoal({ onChange })} />
                <a className="flex flex-col justify-center items-center text-center">
                    {
                        files.length > 0 ? <div style={thumbsContainer}>{thumbs}</div> :
                            <div className="text-center">
                                <BsImage className="mb-2" style={{ color: '#9CA3AF', stroke: "#9CA3AF", strokeWidth: "0.5", width: '1.25em', height: '1.25em', cursor: 'pointer' }} />
                                <h5 style={{ color: '#9CA3AF', fontFamily: 'Quicksand' }}>Gambar</h5>
                            </div>
                    }
                </a>
            </div>
        </React.Fragment>
    );
};

export default UploadGambar;