import React from 'react';
import styles from '../../index.module.css';
import { DaftarButton } from './index';

export default function Kelebihan() {
  return(
    <React.Fragment>
      <section className="container-fluid" id="kelebihan-section-container">
        <svg class="wave-kurikulum" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 126" fill="none">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M0 94.5L80.4 86.625C159.6 78.75 320.4 63 480 63C639.6 63 800.4 78.75 960 91.875C1119.6 105 1280.4 115.5 1359.6 120.75L1440 126V0H1359.6C1280.4 0 1119.6 0 960 0C800.4 0 639.6 0 480 0C320.4 0 159.6 0 80.4 0H0V94.5Z" fill="#FCA826"/>
        </svg>
        <div className="container px-4 py-5 kelebihan-section">
          <div className="row align-items-center g-2 mb-4" style={{paddingBottom: '5rem'}}>
            <h1 className="text-center jd-home3">Ini yang Akan Anda dapatkan di</h1>
            <h1 className="text-center jd-home3"><span className={styles.textYellow}>INGENIO</span> INDONESIA</h1>
          </div>
          <div className="row g-5 px-2" style={{marginBottom: '20px'}}>
            <div className="col-12 col-lg-5">
              <img src="/assets/landing/medical research.png" width="100%" alt="Medical Research" className="mx-auto"></img>
            </div>
            <div className="col-12 col-lg-7">
                <div className="benefit">PROGRAM BIMBINGAN dengan
                    <span className={styles.textYellow}> POLA PIKIR</span> yang disusun berdasarkan standar kompetensi terbaru dan guideline terlengkap.<br /><br />
                    Peserta dapat tanya jawab ke tim Tutor selama menjadi peserta bimbingan
                </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 my-4 px-2">
              <div className="content justify-content-start text-center text-md-start">
                <img src="/assets/landing/icons/book.png" className="mb-3 kelebihan-image"></img>
                <h5 className="kelebihan-text">
                  Sistem Pengajaran dengan Pola Pikir Runut, Sistematis khas ala INGENIO</h5>
              </div>
            </div>
            <div className="col-md-6 my-4 px-2">
              <div className="content justify-content-start text-center text-md-start">
                <img src="/assets/landing/icons/materi.png" className="mb-3 kelebihan-image"></img>
                <h5 className="kelebihan-text">
                Materi Ajar yang disusun Original, Lengkap, dan Sistematis oleh Tim Tutor INGENIO</h5>
              </div>
            </div>
            <div className="col-md-6 my-4 px-2">
              <div className="content justify-content-start text-center text-md-start">
                <img src="/assets/landing/icons/line.png" className="mb-3 kelebihan-image"></img>
                <h5 className="kelebihan-text">
                  Tanya Jawab dengan Tutor di Grup LINE Peserta</h5>
              </div>
            </div>
            <div class="col-md-6 my-4 px-2">
              <div class="content justify-content-start text-center text-md-start">
                <img src="/assets/landing/icons/class.png" className="mb-3 kelebihan-image"></img>
                <h5 className="kelebihan-text">
                  Pilihan Kelas Privat atau Kelas Intensif Berkelompok Pada Bimbingan Preklinik / Dokter Muda</h5>
              </div>
            </div>
          </div>
          
          <DaftarButton />
        </div>
      </section>
    </React.Fragment>
  )
}

