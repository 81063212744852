import React, {useEffect} from 'react';
import {
  SideBar
} from '../../components/layout/dashboard';
import {
  PengumumanCard,
  MyScheduleCard,
  NewActivityCard
} from '../../components/dashboard';
import { useSelector, useDispatch } from 'react-redux';

export default function HomePage () {
  document.title = "INGENIO Indonesia";
  var baseUrl = window.location.origin;
  
  return(
    <React.Fragment>
      <SideBar />
      <div className="md:ml-64">
        {/* <div className="bg-orange px-3 md:px-8 py-1 h-40">
          <h1 className="text-left tracking-wider" style={{marginLeft: '2.75rem', fontFamily: 'Quicksand', fontWeight: 700, fontSize: '1.25rem'}}>Selamat Datang, Peserta Didik INGENIO Indonesia</h1>
        </div> */}
        <div className="container md:pr-8 md:pl-10" style={{position: 'absolute', top: '80px'}}>
          <h1 className="text-left tracking-wider" style={{marginLeft: '2.75rem', fontFamily: 'Quicksand', fontWeight: 700, fontSize: '1.25rem'}}>Selamat Datang, Peserta Didik INGENIO Indonesia</h1>
        </div>

        <div className="px-3 md:px-8 -mt-12">
            <div className="container mx-auto max-w-full">
                <div className="row">
                    <div className="col-md-8 col-xs-4">
                      <PengumumanCard />
                    </div>
                    <div className="col-md-4 col-xs-4">
                      <div className="mb-5">
                        <MyScheduleCard />
                      </div>
                      <div className="mb-5">
                        <NewActivityCard />
                      </div>
                    </div>
                </div>
            </div>
        </div>

      </div>
    </React.Fragment>
    
  )
}