import React, { useState } from 'react';

import { FormGroup, FormControl, Form } from 'react-bootstrap';
import TutorSidebar from '../../../components/layout/dashboard/tutor/TutorSideBar';

export default function Akun() {
    document.title = "INGENIO Indonesia";
    const [program, setProgram] = useState('');
    var baseUrl = window.location.origin;
    const [openTab, setOpenTab] = React.useState(1);
    const [selectedFile, setSelectedFile] = useState();
    const [isFilePicked, setIsFilePicked] = useState(false);
    const [isSelected, setIsSelected] = useState(false);
    const changeHandler = (event) => {
        setSelectedFile(event.target.files[0]);
        setIsSelected(true);
    };

    return (
        <React.Fragment>
            <TutorSidebar />
            <div className="md:ml-64">
                <div className="bg-orange px-3 md:px-8 py-1 h-40">
                    <h3 className="text-left font-medium tracking-wider" style={{ marginLeft: '3rem' }}>Account Settings</h3>
                </div>

                <div className="py-4 px-3 md:px-8 -mt-12">
                    <div className="container mx-auto max-w-full">
                        <div class="shadow-md rounded-md overflow-hidden col-12 col-md-10 col-lg-8 mx-auto">
                            <div className="py-3 px-3 bg-white font-bold" style={{ fontFamily: 'Quicksand' }}>
                                Profile Settings
                            </div>
                            <div className="py-3 px-5 bg-gray-100 font-bold" style={{ fontFamily: 'Quicksand' }}>
                                <ul className="list-reset flex">
                                    <li className="-mb-px mr-1">
                                        <a
                                            className={openTab === 1 ? "inline-block rounded-t py-2 px-4 font-bold" : "inline-block rounded-t py-2 px-4 font-semibold"}
                                            style={{ fontFamily: 'Quicksand' }}
                                            onClick={e => { e.preventDefault(); setOpenTab(1); }}
                                            href="#">
                                            Profile Information
                                        </a>
                                    </li>
                                    <li className="mr-1">
                                        <a
                                            className={openTab === 2 ? "inline-block rounded-t py-2 px-4 font-bold" : "inline-block rounded-t py-2 px-4 font-semibold"}
                                            style={{ fontFamily: 'Quicksand' }}
                                            onClick={e => { e.preventDefault(); setOpenTab(2); }}
                                            href="#">
                                            Profile Image
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <div className={openTab === 1 ? "block" : "hidden"} id="profileInformation" >
                                <form action="" className="overflow-y-scroll" style={{ maxHeight: '55vh' }}>
                                    <div className="form-group p-5">
                                        <div className="row">
                                            <div className="col-12 lg:px-4">
                                                <label htmlFor="" className="label-tryout">Nama Lengkap</label> <br />
                                                <Form.Control type="text" name="" className="form-tryout mb-3" id="" />
                                            </div>
                                            <div className="col-12 lg:px-4">
                                                <label htmlFor="" className="label-tryout">Nama Panggilan</label> <br />
                                                <Form.Control type="text" name="" className="form-tryout mb-3" id="" />
                                            </div>
                                            <div className="col-12 col-md-6 lg:px-4">
                                                <label htmlFor="" className="label-tryout">No.Handphone <b>(dengan WA Aktif)</b></label> <br />
                                                <Form.Control type="text" name="" className="form-tryout mb-3" id="" />
                                            </div>
                                            <div className="col-12 col-md-6 lg:px-4">
                                                <label htmlFor="" className="label-tryout">ID Line</label> <br />
                                                <Form.Control type="text" name="" className="form-tryout mb-3" id="" placeholder="ID Line" />
                                            </div>
                                            <div className="col-12 lg:px-4">
                                                <div class="block mb-3">
                                                    <label htmlFor="" className="label-tryout">Jenis Kelamin</label> <br />
                                                    <div class="mt-2 flex">
                                                        <div className="mr-3">
                                                            <label class="inline-flex items-center">
                                                                <input type="radio" class="form-radio" name="radio" value="1" checked></input>
                                                                <span class="ml-2">Laki-laki</span>
                                                            </label>
                                                        </div>
                                                        <div className="mr-3">
                                                            <label class="inline-flex items-center">
                                                                <input type="radio" class="form-radio" name="radio" value="2"></input>
                                                                <span class="ml-2">Perempuan</span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 lg:px-4">
                                                <label htmlFor="" className="label-tryout">Email</label> <br />
                                                <Form.Control type="text" name="" className="form-tryout mb-3" id="" />
                                            </div>
                                            <div className="col-12 col-md-6 lg:px-4">
                                                <label htmlFor="" className="label-tryout">Agama</label> <br />
                                                <Form.Select
                                                    as="select"
                                                    value={program}
                                                    aria-label="Program"
                                                    aria-describedby="basic-addon1"
                                                    className="agama"
                                                >
                                                    <option value=""></option>
                                                    <option value="1">Islam</option>
                                                    <option value="2">Kristen</option>
                                                </Form.Select>
                                            </div>
                                            <div className="col-12 col-md-6 lg:px-4">
                                                <label htmlFor="" className="label-tryout">Tempat / Tanggal Lahir</label> <br />
                                                <div className="row" style={{ padding: '0.1rem' }}>

                                                    <div className="col-6 col-md-6 lg:px-4">
                                                        <Form.Control type="text" name="" className="form-tryout mb-3" id="" placeholder="Tempat" />
                                                    </div>

                                                    <div className="col-6 col-md-6 lg:px-4">
                                                        <Form.Control type="date" name="duedate" placeholder="Due date" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 lg:px-4">
                                                <label htmlFor="" className="label-tryout">Ukuran Size</label> <br />
                                                <Form.Select
                                                    as="select"
                                                    value={program}
                                                    aria-label="Program"
                                                    aria-describedby="basic-addon1"
                                                    className="size"
                                                >
                                                    <option value=""></option>
                                                    <option value="1">XL</option>
                                                    <option value="2">L</option>
                                                    <option value="3">M</option>
                                                </Form.Select>
                                            </div>
                                            <div className="col-12 col-md-6 lg:px-4">
                                                <label htmlFor="" className="label-tryout">Akun Instagram</label> <br />
                                                <Form.Control type="text" name="" className="w-full p-2" id="" />
                                            </div>
                                            <div className="col-12 lg:px-4">
                                                <label htmlFor="" className="label-tryout">Asal Fakultas</label> <br />
                                                <Form.Control type="text" name="" className="form-tryout mb-3" id="" />
                                            </div>
                                            <div className="col-12 lg:px-4">
                                                <label htmlFor="" className="label-tryout">Asal Universitas</label> <br />
                                                <Form.Control type="text" name="" className="form-tryout mb-3" id="" />
                                            </div>
                                            <div className="col-12 col-md-6 lg:px-4">
                                                <label htmlFor="" className="label-tryout">Angkatan (tahun masuk kuliah)</label> <br />
                                                <Form.Control type="text" name="" className="form-tryout mb-3" id="" />
                                            </div>
                                            <div className="col-12 col-md-6 lg:px-4">
                                                <label htmlFor="" className="label-tryout">Pendidikan Terakhir</label> <br />
                                                <Form.Control type="text" name="" className="form-tryout mb-3" id="" />
                                            </div>
                                            <div className="col-12 lg:px-4">
                                                <label htmlFor="" className="label-tryout">Alamat Lengkap KTP</label> <br />
                                                <Form.Control type="text" name="" className="form-tryout mb-3" id="" />
                                            </div>
                                            <div className="col-12 lg:px-4">
                                                <label htmlFor="" className="label-tryout">Alamat Lengkap Domisili</label> <br />
                                                <Form.Control type="text" name="" className="form-tryout mb-3" id="" />
                                            </div>
                                            <div className="col-12 col-md-6 lg:px-4">
                                                <label htmlFor="" className="label-tryout">Jabatan Staff</label> <br />
                                                <Form.Control type="text" name="" className="w-full p-2" id="" />
                                            </div>
                                            <div className="col-12 col-md-6 lg:px-4">
                                                <label htmlFor="" className="label-tryout">Status Pernikahan</label> <br />
                                                <Form.Select
                                                    as="select"
                                                    value={program}
                                                    aria-label="Program"
                                                    aria-describedby="basic-addon1"
                                                    className="status"
                                                >
                                                    <option value=""></option>
                                                    <option value="1">Sudah Menikah</option>
                                                    <option value="2">Belum Menikah</option>
                                                </Form.Select>
                                            </div>
                                            <div className="col-12 col-md-12 lg:px-4">
                                                <label htmlFor="" className="label-tryout">Bidang Program dan Materi</label> <br />
                                                <Form.Select
                                                    as="select"
                                                    value={program}
                                                    aria-label="Program"
                                                    aria-describedby="basic-addon1"
                                                    className="program-materi"
                                                >
                                                    <option value=""></option>
                                                    <option value="1">Comingsoon</option>
                                                    <option value="2">Comingsoon</option>
                                                </Form.Select>
                                            </div>
                                            <div className="col-12 lg:px-4">
                                                <label htmlFor="" className="label-tryout">No. KTP</label> <br />
                                                <Form.Control type="text" name="" className="form-tryout mb-3" id="" />
                                            </div>
                                            <div className="col-12 lg:px-4">
                                                <label htmlFor="" className="label-tryout">No. NPWP</label> <br />
                                                <Form.Control type="text" name="" className="form-tryout mb-3" id="" />
                                            </div>
                                            <div className="col-12 lg:px-4">
                                                <label htmlFor="" className="label-tryout">No. Rek Mandiri</label> <br />
                                                <Form.Control type="text" name="" className="form-tryout mb-3" id="" />
                                            </div>
                                            <div className="col-12 lg:px-4">
                                                <div class="block mb-3">
                                                    <label htmlFor="" className="label-tryout">Apa sudah ada Surat Kontrak?</label> <br />
                                                    <div class="mt-2 flex">
                                                        <div className="mr-3">
                                                            <label class="inline-flex items-center">
                                                                <input type="radio" class="form-radio" name="radio" value="1" checked></input>
                                                                <span class="ml-2">Sudah Ada</span>
                                                            </label>
                                                        </div>
                                                        <div className="mr-3">
                                                            <label class="inline-flex items-center">
                                                                <input type="radio" class="form-radio" name="radio" value="2"></input>
                                                                <span class="ml-2">Belum Ada</span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 lg:px-4">
                                                <label htmlFor="" className="label-tryout"><strong>Jika sudah ada SK</strong>, berlaku sejak tanggal, bulan dan tahun</label> <br />
                                                <Form.Control type="text" name="" className="form-tryout mb-3" id="" />
                                            </div>
                                            <div className="col-12 lg:px-4">
                                                <label htmlFor="" className="label-tryout"><strong>Jika tidak sudah ada SK</strong>, mengajar sejak bulan dan tahun</label> <br />
                                                <Form.Control type="text" name="" className="form-tryout mb-3" id="" />
                                            </div>

                                        </div>
                                    </div>
                                    <div className="py-3 px-5 bg-white text-center">
                                        <button
                                            class="col-12 bg-orange text-white active:bg-orange font-bold text-base px-8 py-3 rounded shadow-md hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                            type="button">
                                            Submit Data
                                        </button>
                                    </div>
                                </form>
                            </div>
                            <div className={openTab === 2 ? "block p-5" : "hidden p-5"} id="profileImage">
                                <form className="row">
                                    <div className="col-6 mx-auto text-center mb-3">
                                        <img src={baseUrl + "/assets/img/profile-image-default.png"} className="object-center mx-auto"></img>
                                    </div>
                                    <div className="col-12 text-center mb-3">
                                        <h4 className="font-bold">JPEG, GIF, and PNG images below 5MB can be uploaded.</h4>
                                    </div>
                                    <div className="col-12 col-md-5 mx-auto text-left mb-3">
                                        <input type="file" name="file" onChange={changeHandler} />
                                    </div>
                                    <div className="col-12 text-center mb-3">
                                        <button type="submit" class="bg-transparent hover:bg-blue-500 text-black font-semibold hover:text-black py-2 px-4 border border-gray-800 hover:border-transparent rounded">Upload Image</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

            </div >
        </React.Fragment >

    )
}