import axios from "axios";

const API_URL = `${process.env.REACT_APP_AUTHURL}`;
const USER_URL = `${process.env.REACT_APP_USERURL}`;
const ELEARNING_URL = `${process.env.REACT_APP_ELEARNINGURL}`;

const tokenMethod = () => {
  const valueToken = localStorage.getItem("token");
  if(valueToken && valueToken !== null)
    return JSON.parse(valueToken)
  return null
};

const axiosInstance = axios.create({
  baseURL: API_URL,
  headers: {
    "Authorization": "Bearer ",
  }
});

class AuthService {
  async resetSessionUser(email) {
    return axios.put(API_URL + `session/reset`, {email: email}, {
      headers: {
        "Authorization": "Bearer " + JSON.parse(localStorage.getItem("token")),
      }
    })
  }
  async getListElibParticipant(params = '') {
    return axios.get(USER_URL + `e-library/get?${params}`, {
      headers: {
        "Authorization": "Bearer " + JSON.parse(localStorage.getItem("token")),
      }
    })
  }
  async getDetailUserProfileTutor(id) {
    return axios.get(USER_URL + `private/user/tutor/detail/${id}`, {
      headers: {
        "Authorization": "Bearer " + JSON.parse(localStorage.getItem("token")),
      }
    })
  }
  async sendResetPasswod(id) {
    return axios.get(USER_URL + `user/admin/update/${id}/password`, {
      headers: {
        "Authorization": "Bearer " + JSON.parse(localStorage.getItem("token")),
      }
    })
  }
  async resendActivation(email) {
    return axios.post(API_URL + `register/resend`, {email: email}, {
      headers: {
        "Authorization": "Bearer " + JSON.parse(localStorage.getItem("token")),
      }
    })
  }
  async updateStatusUserAdmin(id, body) {
    return axios.post(USER_URL + `user/admin/update/${id}/status`, body, {
      headers: {
        "Authorization": "Bearer " + JSON.parse(localStorage.getItem("token")),
      }
    })
  }
  async updateUserAdmin(id, body) {
    return axios.put(USER_URL + `user/admin/update/${id}`, body, {
      headers: {
        "Authorization": "Bearer " + JSON.parse(localStorage.getItem("token")),
      }
    })
  }
  async createUserAdmin(body) {
    return axios.post(USER_URL + `user/admin/create`, body, {
      headers: {
        "Authorization": "Bearer " + JSON.parse(localStorage.getItem("token")),
      }
    })
  }
  async getListUserAdmin(params = '') {
    return axios.get(USER_URL + `user/admin/get?${params}`, {
      headers: {
        "Authorization": "Bearer " + JSON.parse(localStorage.getItem("token")),
      }
    })
  }

  async getListJadwalKelasByUserIdAndKelasAkademik(userId, kelasId, params = '') {
    return axios.get(ELEARNING_URL + `evaluasi/admin/get/${userId}/kelas/${kelasId}?${params}`, {
      headers: {
        "Authorization": "Bearer " + JSON.parse(localStorage.getItem("token")),
      }
    })
  }

  async getListJadwalKelasByUserIdAdmin(id, params = '') {
    return axios.get(ELEARNING_URL + `evaluasi/admin/get/${id}?${params}`, {
      headers: {
        "Authorization": "Bearer " + JSON.parse(localStorage.getItem("token")),
      }
    })
  }

  async getListElibCategoryDropdownAdmin() {
    return axios.get(USER_URL + `e-library/admin/category/dropdown`, {
      headers: {
        "Authorization": "Bearer " + JSON.parse(localStorage.getItem("token")),
      }
    })
  }
  async getListElibAdmin(params = '') {
    return axios.get(USER_URL + `e-library/admin/get?${params}`, {
      headers: {
        "Authorization": "Bearer " + JSON.parse(localStorage.getItem("token")),
      }
    })
  }
  async uploadElibAdmin(body, onUploadProgress) {
    return axios.post(USER_URL + `e-library/admin/create`, body, {
      headers: {
          "Authorization": "Bearer " + JSON.parse(localStorage.getItem("token")),
          'Content-Type': 'multipart/form-data'
      },
      onUploadProgress: onUploadProgress
    })
  }
  async updateElibAdmin(id, body, onUploadProgress) {
    return axios.put(USER_URL + `e-library/admin/update/${id}`, body, {
      headers: {
          "Authorization": "Bearer " + JSON.parse(localStorage.getItem("token")),
          'Content-Type': 'multipart/form-data'
      },
      onUploadProgress: onUploadProgress
    })
  }
  async deleteElibAdmin(id) {
    return axios.delete(USER_URL + `e-library/admin/delete/${id}`, {
      headers: {
        "Authorization": "Bearer " + JSON.parse(localStorage.getItem("token")),
      }
    })
  }


  async getListElibCategoryAdmin(params = '') {
    return axios.get(USER_URL + `e-library/admin/category/get?${params}`, {
      headers: {
        "Authorization": "Bearer " + JSON.parse(localStorage.getItem("token")),
      }
    })
  }
  async addElibCategoryAdmin(body) {
    return axios.post(USER_URL + `e-library/admin/category/create`, body, {
      headers: {
        "Authorization": "Bearer " + JSON.parse(localStorage.getItem("token")),
      }
    })
  }
  async updateElibCategoryAdmin(id, body) {
    return axios.put(USER_URL + `e-library/admin/category/update/${id}`, body, {
      headers: {
        "Authorization": "Bearer " + JSON.parse(localStorage.getItem("token")),
      }
    })
  }
  async deleteElibCategoryAdmin(id) {
    return axios.delete(USER_URL + `e-library/admin/category/delete/${id}`, {
      headers: {
        "Authorization": "Bearer " + JSON.parse(localStorage.getItem("token")),
      }
    })
  }

  async getListTutorAdmin(params = '') {
    return axios.get(USER_URL + `tutor/admin/get?${params}`, {
      headers: {
        "Authorization": "Bearer " + JSON.parse(localStorage.getItem("token")),
      }
    })
  }

  async addTutorAdmin(body) {
    return axios.post(USER_URL + `tutor/admin/create`, body, {
      headers: {
        "Authorization": "Bearer " + JSON.parse(localStorage.getItem("token")),
      }
    })
  }

  async updateTutorAdmin(id, body) {
    return axios.put(USER_URL + `tutor/admin/update/${id}`, body, {
      headers: {
        "Authorization": "Bearer " + JSON.parse(localStorage.getItem("token")),
      }
    })
  }

  async updateStatusTutorAdmin(id, body) {
    return axios.put(USER_URL + `user/admin/update/${id}/status`, body, {
      headers: {
        "Authorization": "Bearer " + JSON.parse(localStorage.getItem("token")),
      }
    })
  }

  async createTesti(body) {
    return axios.post(USER_URL + `testi/create`, body, {
      headers: {
        "Authorization": "Bearer " + JSON.parse(localStorage.getItem("token")),
      }
    })
  }
  async getListTestiAdmin(params = '') {
    return axios.get(USER_URL + `testi/admin/get?${params}`, {
      headers: {
        "Authorization": "Bearer " + JSON.parse(localStorage.getItem("token")),
      }
    })
  }
  async getListUploadDataAdmin(params = '') {
    return axios.get(USER_URL + `upload-data/admin?${params}`, {
      headers: {
        "Authorization": "Bearer " + JSON.parse(localStorage.getItem("token")),
      }
    })
  }
  async deleteUploadFileAdmin(id) {
    return axios.delete(USER_URL + `upload-data/admin/${id}`, {
      headers: {
        "Authorization": "Bearer " + JSON.parse(localStorage.getItem("token")),
      }
    })
  }
  async createRegioAdmin(body) {
    return axios.post(USER_URL + `regio` , body, {
      headers: {
        "Authorization": "Bearer " + JSON.parse(localStorage.getItem("token")),
      }
    })
  }
  async updateRegioAdmin(id, body) {
    return axios.put(USER_URL + `regio/${id}` , body, {
      headers: {
        "Authorization": "Bearer " + JSON.parse(localStorage.getItem("token")),
      }
    })
  }
  async deleteRegioAdmin(id) {
    return axios.delete(USER_URL + `regio/${id}`, {
      headers: {
        "Authorization": "Bearer " + JSON.parse(localStorage.getItem("token")),
      }
    })
  }
  getListRegioAdmin(params = '') {
    return axios.get(USER_URL + `regio?${params}`, {
      headers: {
        "Authorization": "Bearer " + JSON.parse(localStorage.getItem("token")),
      }
    })
  }
  getListRegioAll() {
    return axios.get(USER_URL + `regio/all`, {
      headers: {
        "Authorization": "Bearer " + JSON.parse(localStorage.getItem("token")),
      }
    })
  }

  getLisSubtRegioAdmin(params = '') {
    return axios.get(USER_URL + `regio/sub-regio?${params}`, {
      headers: {
        "Authorization": "Bearer " + JSON.parse(localStorage.getItem("token")),
      }
    })
  }

  addSubRegio(body) {
    return axios.post(USER_URL + `regio/sub-regio`, body, {
      headers: {
        "Authorization": "Bearer " + JSON.parse(localStorage.getItem("token")),
      }
    })
  }

  updateSubRegio(id, body) {
    return axios.put(USER_URL + `regio/sub-regio/update/${id}`, body, {
      headers: {
        "Authorization": "Bearer " + JSON.parse(localStorage.getItem("token")),
      }
    })
  }

  deleteSubRegio(id) {
    return axios.delete(USER_URL + `regio/sub-regio/delete/${id}`, {
      headers: {
        "Authorization": "Bearer " + JSON.parse(localStorage.getItem("token")),
      }
    })
  }

  accountVerify() {
    return axios.get(API_URL + `auth-verify`, {
      headers: {
          'Authorization': `Bearer ${tokenMethod()}`,
          'id-device': `${localStorage.getItem('m_f__master')}`
      }
    })
  }

  logoutUser() {
    return axios.get(API_URL + `logout`, {
      headers: {
          'Authorization': `Bearer ${tokenMethod()}`
      }
    })
  }

  akademikGetDetailPeserta(id) {
    return axios
      .get(USER_URL + 'akademik/participant/' + id, {
        headers: {
          "Authorization": "Bearer " + tokenMethod(),
        }
      })
      .then((response) => {
        return response.data
      })
  }

  // AUTHENTICATION
  login(payload) {
    return axiosInstance
      .post( "login", payload)
  }

  verify() {
    return axiosInstance
      .get("verify")
      .then((response) => {
        console.log(response);
        if(response.data.success === true) {
          return response.data;
        } else {
          localStorage.clear()
          return response.data;
        }
      })
  }

  logout() {
    localStorage.clear();
  }

  register(payload) {
    return axiosInstance
      .post("register", payload)
      .then((response) => {
        return response.data;
      })
  }

  forgotPassword(payload) {
    return axiosInstance
      .put('forgot-password', payload)
      .then((response) => {
        return response.data
      })
  }

  getCurrentUser() {
    return tokenMethod();
  }
  // END AUTHENTICATION

  // AUTHORIZATION PARTICIPANT
  getProfileStudent() {
    console.log({
      headers: {
        "Authorization": "Bearer " + tokenMethod(),
      }
    })
    return axios
      .get(API_URL + 'me/student', {
        headers: {
          "Authorization": "Bearer " + tokenMethod(),
        }
      })
      .then((response) => {
        return response.data
      })
  }

  updateProfileStudent(payload) {
    return axios
      .put(API_URL + 'update-profile/student', payload, {
        headers: {
          "Authorization": "Bearer " + tokenMethod(),
        }
      })
      .then((response) => {
        return response.data
      })
  }

  uploadAvatar(payload, id) {
    return axios
      .put(USER_URL + 'upload-avatar/' + id, payload, {
        headers: {
          "Authorization": "Bearer " + tokenMethod(),
          // 'accept': 'application/json',
          // 'Accept-Language': 'en-US,en;q=0.8',
          'Content-Type': "multipart/form-data",
          // 'Accept': 'application/json, application/xml, text/plain, text/html, image/png, image/jpg *.*',
        }
      })
      .then((response) => {
        return response.data
      })
  }
  // END AUTHORIZATION PARTICIPANT

  // AUTHORIZATION AKADEMIK
  getProfileAkademik() {
    return axios
      .get(API_URL + 'me/student')
      .then((response) => {
        return response.data
      })
  }

  updateProfileAkademik(payload) {
    return axios
      .put(API_URL + 'update-profile/akademik', payload)
      .then((response) => {
        return response.data
      })
  }
  // END AUTHORIZATION AKADEMIK

  getListUploadFiles(params = '') {
    return axios.get(USER_URL + `upload-data?${params}`, {
      headers: {
        "Authorization": "Bearer " + JSON.parse(localStorage.getItem("token")),
      }
    });
  }

  uploadFile(body) {
    return axios.post(USER_URL + `upload-data`, body, {
      headers: {
        "Authorization": "Bearer " + JSON.parse(localStorage.getItem("token")),
        'Content-Type': "multipart/form-data",
      }
    });
  }

  updateUploadFile(id, body) {
    return axios.put(USER_URL + `upload-data/${id}`, body, {
      headers: {
        "Authorization": "Bearer " + JSON.parse(localStorage.getItem("token")),
        'Content-Type': "multipart/form-data",
      }
    });
  }

  deleteUploadFile(id) {
    return axios.delete(USER_URL + `upload-data/${id}`, {
      headers: {
        "Authorization": "Bearer " + JSON.parse(localStorage.getItem("token")),
        'Content-Type': "multipart/form-data",
      }
    });
  }

  async downloadTestimoniParticipant() {
    return axios.get(process.env.REACT_APP_ELEARNINGURL + `kelas/admin/testimoni-participant/download`, {
        headers: {
            "Authorization": "Bearer " + JSON.parse(localStorage.getItem("token"))
        }
    })
}
}

export default new AuthService();