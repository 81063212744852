import React, { useEffect, useState } from 'react';
import { Form, FormGroup, Button } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import { BsFillGeoAltFill } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import { SideBar } from '../../../components/layout/dashboard';
import { contactLink } from '../../../helpers/utils'
import ElearningService from '../../../services/elearning';
import CustomDatatable from '../../../components/common/CustomDatatable';
import moment from 'moment-timezone';
import Swal from 'sweetalert2';

export default function Resi() {
    document.title = "INGENIO Indonesia";

    const [isLoading, setIsLoading] = useState(true);
    const [hasNext, setHasNext] = useState(false)
    const [hasPrev, setHasPrev] = useState(false)
    const [count, setCount] = useState(0);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [keyword, setKeyword] = useState('');
    const [userId, setUserId] = useState('');
    const [kelasSaya, setKelasSaya] = useState([])

    const [program, setProgram] = useState('');
    const dropdown = {
        borderRadius: "10px",
        height: 50,
        padding: "25px",
    };

    const columns = [
        {
            name: 'NO.',
            field: 'no',
            index: true,
            selector: row => row.no,
            sortable: true,
        },
        {
            name: 'NAMA KELAS',
            render: true,
            component: (row) => {
                return (
                    <td className='main-table__bodyRow'>{row.elearning_shipments[0].kelas.nama_kelas}</td>
                )
            },
            selector: row => row.namakelas,
            sortable: true,
            // width: '15vw',
        },
        {
            name: 'PROGRAM BIMBINGAN',
            render: true,
            component: (row) => {
                return (
                    <td className='main-table__bodyRow'>{row.elearning_shipments[0].kelas.elearning_program.nama_program}</td>
                )
            },
            selector: row => row.programbimbingan,
            sortable: true,
            // width: '15vw',
        },
        {
            name: 'KURIR PAKET',
            render: true,
            component: (row) => {
                return (
                    <td className='main-table__bodyRow'>{'Lion Parcel'}</td>
                )
            },
            selector: row => row.regio,
            sortable: true,
            // width: '15vw',
        },
        {
            name: 'TANGGAL PENGIRIMAN',
            selector: row => row.masaberlaku,
            render: true,
            component: (row) => {
                return (
                    <td className='main-table__bodyRow'>{`${moment(row.shipment_date).format('DD-MM-YYYY')}`}</td>
                )
            },
            sortable: true,
            // width: '15vw',
        },
        {
            name: 'NO. RESI PAKET',
            render: true,
            component: (row) => {
                return (
                    <td className='main-table__bodyRow'>
                        {
                            row.resi !== null ?
                            <Link className="flex gap-2" to={`/dashboard/e-learning/resi/cek-tracking/${row.resi}`} style={{ textDecoration: 'underline' }}>{row.resi}<BsFillGeoAltFill /></Link>
                            :
                            '-'
                        }
                    </td>
                )
            },
            selector: row => row.statuskelas,
            sortable: true,
        },
        {
            name: 'STATUS PAKET',
            render: true,
            component: (row) => {
                return (
                    <td className='main-table__bodyRow'>{row.last_status_tracking}</td>
                )
            },
            selector: row => row.statuskelas,
            sortable: true,
        },
    ];
    const data = [
        {
            namakelas: 'Lorem ipsum dolor.',
            programbimbingan: 'Lorem ipsum dolor.',
            tanggal: '00/00/0000',
            kurir: 'Lion Parcel',
            noresi: <Link className="flex gap-2" to="/dashboard/e-learning/resi/cek-tracking" style={{ textDecoration: 'underline' }}>000-0000-0000 <BsFillGeoAltFill /></Link>,
            statuspaket: 'Terkirim',
        },
        {
            namakelas: 'Lorem ipsum dolor.',
            programbimbingan: 'Lorem ipsum dolor.',
            tanggal: '00/00/0000',
            kurir: 'Tidak Ada',
            noresi: 'Tidak Ada',
            statuspaket: 'Tidak Ada',
        },
    ];

    const getListKelasSaya = (params = '') => {
        setIsLoading(true)
        ElearningService.getListShipmentParticipant(params).then(resp => {
            if(resp.data.success === true) {
                const response = resp.data;
                setKelasSaya(response.data);

                setPage(response.meta.page)
                setPerPage(response.meta.perPage)
                setCount(response.meta.count)

                setHasNext(response.meta.hasNext)
                setHasPrev(response.meta.hasPrev);

                if(response.meta.userId) {
                    setUserId(response.meta.userId)
                }
            }
            setIsLoading(false)
        }).catch(err => {
            setIsLoading(false)
            console.error(err)
        })
    }

    useEffect(() => {
        getListKelasSaya()
    }, [])

    return (
        <React.Fragment>
            <SideBar />
            <div className="md:ml-64 tutorDataKelas" style={{ backgroundColor: '#F3F4F6' }}>
                {/* <div className="bg-orange px-3 md:px-8 py-1 h-40">
                    <div className="container md:pr-8 md:pl-10">
                        <h3 className="text-left tracking-wider title-dashboard">Selamat datang di Kelas Bimbingan Intensif Ingenio Indonesia</h3>
                        <br />
                        <h3 className="text-left tracking-wider">Anda dapat menghubungi Admin terlebih dahulu untuk memastikan kelas dan program yang Anda daftarkan. <br /> <br />Kontak Admin <a href={contactLink()} target={'_blank'}> <i class="fa fa-whatsapp" aria-hidden="true"></i></a></h3>
                    </div>
                </div> */}

                <div className="container md:pr-8 md:pl-10"  style={{position: 'absolute', top: '80px'}}>
                    <h3 className="text-left tracking-wider title-dashboard">Selamat datang di Kelas Bimbingan Intensif Ingenio Indonesia</h3>
                    <br />
                    <h3 className="text-left tracking-wider">Anda dapat menghubungi Admin terlebih dahulu untuk memastikan kelas dan program yang Anda daftarkan. <br /> <br />Kontak Admin <a href={contactLink()} target={'_blank'}> <i class="fa fa-whatsapp" aria-hidden="true"></i></a></h3>
                </div> 

                <div className="px-3 md:px-8 mt-4">
                    <div className="container mx-auto max-w-full">
                        <div className="row">
                            <div className="col-12 mt-3 mb-4">
                                <FormGroup>
                                    <div className="SearchTable2 gap-3">
                                        <input value={keyword}
                                            onChange={(e) => setKeyword(e.target.value)} type="text" name="" className="inputSearchTable" placeholder="&#x1F50E;&#xFE0E; Search..." id="" />
                                        <Button onClick={() => {
                                            getListKelasSaya(`page=${page}&perPage=${perPage}&keyword=${keyword}`)
                                        }} className="btnSearchTable">Cari</Button>
                                    </div>
                                </FormGroup>
                            </div>
                        </div>
                        <div className="col-md-12 col-lg-12 col-xs-12 mt-3">
                            {/* <DataTable columns={columns} data={data} pagination /> */}
                            <CustomDatatable 
                                filteredItems={kelasSaya} 
                                columns={columns}
                                isLoading={isLoading}
                                page={page}
                                perPage={perPage}
                                hasPrev={hasPrev}
                                hasNext={hasNext}
                                count={count}
                                onTableChange={(e, data) => {
                                    switch (e) {
                                    case 'rowPerPageChange':
                                        setPerPage(data.perPage)
                                        getListKelasSaya(`page=${page}&perPage=${data.perPage}&keyword=${keyword}`)
                                        break;
                                    case 'rowPageChange':
                                        setPage(data.page)
                                        getListKelasSaya(`page=${data.page}&perPage=${perPage}&keyword=${keyword}`)
                                        break;
                                    
                                    default:
                                        break;
                                    }
                                }}
                            />
                        </div>
                    </div>
                </div>

            </div>
        </React.Fragment >

    )
}