import React from 'react'
import { useDropzone } from 'react-dropzone'
import { BsImage } from "react-icons/bs";
import { useFormContext, useForm, Controller } from "react-hook-form";
import { FaTrash } from "react-icons/fa"
import { UploadGambar } from '.';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { BsFillTrashFill } from 'react-icons/bs';

const thumbsContainer = {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: 16
};

const thumb = {
    display: "inline-flex",
    borderRadius: 2,
    border: "1px solid #eaeaea",
    marginBottom: 8,
    marginRight: 8,
    width: 100,
    height: 100,
    padding: 4,
    boxSizing: "border-box"
};

const thumbInner = {
    display: "flex",
    minWidth: 0,
    overflow: "hidden"
};

const img = {
    display: "block",
    width: "auto",
    height: "100%"
};

const InputJawaban = (props) => {

    let formData = new FormData()

    const { acceptedFiles: gambarJawaban, getRootProps: getRootPropsJawaban, getInputProps: getInputPropsJawaban } = useDropzone({
        accept: "image/*",
        onDrop: gambarJawaban => {
            console.log(gambarJawaban);
            setFiles(
                gambarJawaban.map(file =>
                    Object.assign(file, {
                        preview: URL.createObjectURL(file)
                    })
                )
            );
        }
    });

    // const fileObj = gambarJawaban.map(file => {
    //     formData.append('gambar', file, file.name)
    // })

    const { register } = useFormContext();

    const [files, setFiles] = React.useState([]);

    const thumbs = files.map(file => (
        <div style={thumb} key={file.name}>
            <div style={thumbInner}>
                <img src={file.preview} style={img} />
                <button />
            </div>
        </div>
    ));

    return (
        <React.Fragment>
            <div className="flex w-full mx-auto xl:mx-0 mb-3">
                <div className="w-9/12 flex px-4">
                    <div className="self-center round rounded-full p-2 mr-3">
                        <input type="radio" id={"checkbox-" + props.jawaban} value={props.jawaban} {...register('answer_key')} name="answer_key" />
                        <label htmlFor={"checkbox-" + props.jawaban}></label>
                        <input type="hidden" value={props.jawaban} name="key" {...register(`jawaban.${props.index}.${'key'}`)} />
                    </div>
                    <input
                        id="email1"
                        style={{ fontFamily: 'Quicksand' }}
                        className="text-gray-600 dark:text-gray-400 focus:outline-none focus:border focus:border-indigo-700 dark:focus:border-indigo-700 dark:border-gray-700 dark:bg-gray-800 bg-white font-normal w-100 h-10 flex items-center pl-3 text-sm border-gray-300 rounded border"
                        placeholder={"Jawaban " + props.jawaban}
                        {...register(`jawaban.${props.index}.${'jawaban'}`)}
                    />
                </div>
                <div className="w-3/12 flex items-center">
                    {
                        props.dataJawaban && props.dataJawaban !== null ?
                            props.dataJawaban.gambar && props.dataJawaban.gambar !== null ?
                            <div className="flex flex-row">
                                <LazyLoadImage
                                    src={props.dataJawaban.gambar}
                                    alt={'Gambar ' + `Soal`}
                                    effect="blur"
                                />
                                <Controller
                                    name={`jawaban.${props.index}.${'gambar'}`}
                                    control={props.control}
                                    render={({
                                        field: { onChange, onBlur, value, name, ref } }) => {
                                            return <button
                                                class="bg-white hover:bg-blue-light font-bold py-2 px-4 hover:border-blue rounded ml-2 flex items-center"
                                                style={{ border: '2px solid #9ca3af', fontFamily: 'Quicksand', color: "#000" }}
                                                onClick={() => {
                                                    onChange(null)
                                                    props.onUpdate(props.jawaban)
                                                }}
                                            // onClick={() => deleteSub(i.id)}
                                            >
                                                <BsFillTrashFill />
                                            </button>
                                    }}
                                />
                                
                            </div>
                            :
                            <Controller
                                name={`jawaban.${props.index}.${'gambar'}`}
                                control={props.control}
                                render={({
                                    field: { onChange, onBlur, value, name, ref } }) => {
                                    return <UploadGambar value={value} onChange={(e) => onChange(e.target.files[0])} />
                                }} />
                        :
                        <Controller
                            name={`jawaban.${props.index}.${'gambar'}`}
                            control={props.control}
                            render={({
                                field: { onChange, onBlur, value, name, ref } }) => {
                                return <UploadGambar value={value} onChange={(e) => onChange(e.target.files[0])} />
                            }} />
                    }
                    

                    {/* <select
                        placeholder="kompetensi"
                        className="py-1 px-3 mx-2"
                        {...register(`jawaban.${props.index}.${'grade'}`)}
                        style={{ fontFamily: 'Quicksand', fontWeight: 500, border: '0.5px solid #9CA3AF', borderRadius: '5px' }}>
                        <option value="">Grade</option>
                        <option value="1">1</option>
                        <option value="0">0</option>
                        <option value="-1">-1</option>
                    </select> */}
                </div>
            </div>
        </React.Fragment>
    )
}

export default InputJawaban