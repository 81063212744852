import React from 'react';
import { useState } from 'react';
import AdminSideBar from '../../../../components/layout/dashboard/admin/AdminSideBar';
import Button from '@material-tailwind/react/Button';
import { Form, FormGroup, Modal } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import { Link } from 'react-router-dom';
import { GrFormView } from 'react-icons/gr';
import { BsFillFileEarmarkArrowUpFill } from 'react-icons/bs';
import { MdOutlineNoteAdd, MdOutlineAddCircleOutline } from 'react-icons/md';
import { HiOutlinePencilAlt, HiOutlineTrash } from 'react-icons/hi';
import AuthService from '../../../../services/auth'
import CustomDatatable from '../../../../components/common/CustomDatatable';
import moment from 'moment-timezone';
import Swal from 'sweetalert2';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useEffect } from 'react';

export default function OlahLibraryAdmin (props) {
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);

  const [uploadProgress, setUploadProgress] = useState(0.0);

  const [categories, setCategories] = useState([]);

  // Filtering data
  const [isLoading, setIsLoading] = useState(true);
  const [hasNext, setHasNext] = useState(false)
  const [hasPrev, setHasPrev] = useState(false)
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [keyword, setKeyword] = useState('');
  const [kelasData, setKelasData] = useState([]);

  const [inIdDoc, setInIdDoc] = useState('');
  const [inCatId, setInCatId] = useState('');
  const [inTahun, setInTahun] = useState('');
  const [inAuthor, setInAuthor] = useState('');
  const [inFileName, setInFileName] = useState('');
  const [inFileData, setInFileData] = useState(null);


  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  document.title = "INGENIO Indonesia";
  const [showSidebar, setShowSidebar] = useState('-left-64');
  var baseUrl = window.location.origin;

  const columns = [
    {
        name: 'NO.',
        field: 'no',
        index: true,
        selector: row => row.no,
        sortable: true,
        minWidth: '1px'
    },
    {
        name: 'KATEGORI',
        render: true,
        component: (row) => {
            return (
                <td className='main-table__bodyRow'>{row.category.name}</td>
            )
        },
        selector: row => row.tanggal,
        sortable: true,
        // width: '15vw',
    },
    {
        name: 'NAMA DOKUMENT',
        render: true,
        component: (row) => {
            return (
                <td className='main-table__bodyRow'>{row.file_name}</td>
            )
        },
        selector: row => row.tanggal,
        sortable: true,
        // width: '15vw',
    },
    {
        name: 'TGL. UPLOAD',
        render: true,
        component: (row) => {
            return (
                <td className='main-table__bodyRow'>{moment(row.uploaded_at).format('yyyy-MM-DD')}</td>
            )
        },
        selector: row => row.tanggal,
        sortable: true,
        // width: '15vw',
    },
    {
      name: 'Aksi',
      render: true,
      component: (row) => {
          return (
              <td className='main-table__bodyRow'>
                  <div style={{display: 'flex', flexDirection: 'row'}}>
                      <OverlayTrigger placement="top" overlay={<Tooltip id="button-tooltip-2">Edit</Tooltip>}>
                        <Link to='#' className="btnCircleBlack mr-3" onClick={() => {
                          setInIdDoc(row.id)
                          setInCatId(row.id_category)
                          setInFileName(row.file_name)
                          setInTahun(row.tahun)
                          setInAuthor(row.author)
                          handleShow2()
                        }}><HiOutlinePencilAlt size="22"/></Link>
                      </OverlayTrigger>
                      <OverlayTrigger placement="top" overlay={<Tooltip id="button-tooltip-2">View</Tooltip>}>
                        <Link to='#' className="btnCircleBlack mr-3" onClick={() => {
                            window.open(row.url, '_blank')
                        }}><GrFormView size="22"/></Link>
                      </OverlayTrigger>
                      <OverlayTrigger placement="top" overlay={<Tooltip id="button-tooltip-2">Delete</Tooltip>}>
                        <Link to='#' className="btnCircleBlack mr-3" onClick={() => {
                          deleteDocument(row.id)
                        }}><HiOutlineTrash size="22"/></Link>
                      </OverlayTrigger>
                  </div>
              </td>
          )
      },
      selector: row => row.tanggal,
      sortable: true,
      // width: '15vw',
  },
];
  const data = [
      {
          no: 1,
          kategori: 'lorem ipsum dolor.',
          nama: 'lorem',
          tgl: '12/12/2021',
      },
      {
          no: 2,
          kategori: 'lorem ipsum dolor.',
          nama: 'lorem',
          tgl: '11/12/2021',
      },
  ];

  const customStyles = {
      table: {
        style: {
          borderRadius: '15px',
          border: '1px solid #BDC1C9',
        },
      },
      head: {
        style: {
          fontSize: '1.25rem',
          textTransform: "uppercase",
          color: '#6B7280',
          backgroundColor: '#f3f4f6',
          border: '1px solid #BDC1C9',
          borderTopLeftRadius: '15px',
          borderTopRightRadius: '15px',
        },
      },
      pagination: {
        style: {
          color: '#6B7280',
          fontSize: '1.1rem',
          minHeight: '56px',
          backgroundColor: '#f3f4f6',
          border: '1.25px solid #BDC1C9',
          borderBottomLeftRadius: '15px',
          borderBottomRightRadius: '15px',
        },
      },
  };

  const AreYouSure = () => {
      Swal.fire({  
          title: 'Sudah Yakin?',  
          text: 'Apakah Anda Yakin Ingin Menghapus Data Ini?',  
          icon: 'warning',  
          showCancelButton: true,  
          cancelButtonText: 'Tidak',  
          confirmButtonText: 'Ya'  
      })
  }

  const Succeed = () => {
      Swal.fire({  
          title: 'Data Berhasil Tersimpan !',  
          text: '',  
          icon: 'success',  
          showCancelButton: false,              
          confirmButtonText: 'OK'  
      }).then((result) => {
        if (result.isConfirmed) {
            getListKelas()
        }
      })
  }

  const EditSucceed = () => {
      Swal.fire({  
          title: 'Data Berhasil Tersimpan !',  
          text: '',  
          icon: 'success',  
          showCancelButton: false,              
          confirmButtonText: 'OK'  
      }).then((result) => {
        if (result.isConfirmed) {
          window.location.reload();
        }
      })
  }

  const updateDocument = () => {
    Swal.fire({  
        title: 'Sudah Yakin?',  
        text: 'Apakah Anda Yakin Ingin Menambah Data Ini?',  
        icon: 'warning',  
        showCancelButton: true,  
        cancelButtonText: 'Tidak',  
        confirmButtonText: 'Ya'  
    }).then((result) => {
      if (result.isConfirmed) {
    
        setIsLoading(true)
        let formData = new FormData();
        const id = inIdDoc;
        formData.append('file_name', inFileName);
        formData.append('id_category', inCatId);
        formData.append('tahun', inTahun);
        formData.append('author', inAuthor);
    
        if(inFileData && inFileData !== null)
        {
            const file = inFileData
            const size = file.size;
            const fileExt = file.name.split('.').pop();
            if(size > 50000000) {
                Fail('File harus kurang dari 50MB')
                return;
            }
            if(fileExt.toLowerCase() !== 'pdf') {
                Fail('File extension harus PDF')
                return;
            }
            formData.append('file', inFileData);
        }
    

        AuthService.updateElibAdmin(id, formData, (progressEvent) => {
            const progressValue = (progressEvent.loaded / progressEvent.total) * 100;
            setUploadProgress(progressValue)
    
        })
        .then(resp => {
            const response = resp.data;
            getListKelas();
    
            setIsLoading(false)
            handleClose()
            setUploadProgress(0.0)
            Succeed()
        }).catch(err => {
            console.error(err)
            setIsLoading(false)
            handleClose()
            setUploadProgress(0.0)
            let msg = 'Internal Server Error'
            if(err.response) {
                if(err.response.data.message) {
                    msg = err.response.data.message
                }
            }
    
            Fail(msg)
        })
      }
    });  
  }

  const uploadDocument = () => {
    if(!inFileData || inFileData === null) {
        Fail('File is required')
        return;
    }
    Swal.fire({  
        title: 'Sudah Yakin?',  
        text: 'Apakah Anda Yakin Ingin Update Data Ini?',  
        icon: 'warning',  
        showCancelButton: true,  
        cancelButtonText: 'Tidak',  
        confirmButtonText: 'Ya'  
    }).then((result) => {
      if (result.isConfirmed) {
    
        setIsLoading(true)
        let formData = new FormData();
        formData.append('file_name', inFileName);
        formData.append('id_category', inCatId);
        formData.append('tahun', inTahun);
        formData.append('author', inAuthor);
    
        if(inFileData && inFileData !== null) {
            const file = inFileData
            const size = file.size;
            const fileExt = file.name.split('.').pop();
            if(size > 50000000) {
                Fail('File harus kurang dari 50MB')
                return;
            }
            if(fileExt.toLowerCase() !== 'pdf') {
                Fail('File extension harus PDF')
                return;
            }
            formData.append('file', inFileData);
        }
            
    
        AuthService.uploadElibAdmin(formData, (progressEvent) => {
            const progressValue = (progressEvent.loaded / progressEvent.total) * 100;
            setUploadProgress(progressValue)
    
        })
        .then(resp => {
            const response = resp.data;
            getListKelas();
    
            setIsLoading(false)
            handleClose2()
            setUploadProgress(0.0)
            Succeed()
        }).catch(err => {
            console.error(err)
            setIsLoading(false)
            handleClose2()
            setUploadProgress(0.0)
            let msg = 'Internal Server Error'
            if(err.response) {
                if(err.response.data.message) {
                    msg = err.response.data.message
                }
            }
    
            Fail(msg)
        })
      }
    });  
    
  }

  const deleteDocument = (id) => {
    Swal.fire({  
        title: 'Sudah Yakin?',  
        text: 'Apakah Anda Yakin Ingin Hapus Data Ini?',  
        icon: 'warning',  
        showCancelButton: true,  
        cancelButtonText: 'Tidak',  
        confirmButtonText: 'Ya'  
    }).then((result) => {
      if (result.isConfirmed) {
        AuthService.deleteElibAdmin(id)
        .then(resp => {
            const response = resp.data;
            getListKelas();
    
            setIsLoading(false)
            Succeed()
        }).catch(err => {
            console.error(err)
            setIsLoading(false)
            let msg = 'Internal Server Error'
            if(err.response) {
                if(err.response.data.message) {
                    msg = err.response.data.message
                }
            }
    
            Fail(msg)
        })
      }
    });  
    
  }

  const getListKelas = (params = '') => {
    setIsLoading(true)
    AuthService.getListElibAdmin(params).then(resp => {
        const response = resp.data;
        setKelasData(response.data);

        setPage(response.meta.page)
        setPerPage(response.meta.perPage)
        setCount(response.meta.count)

        setHasNext(response.meta.hasNext)
        setHasPrev(response.meta.hasPrev);
        
        setIsLoading(false)
    }).catch(err => {
        let msg = 'Internal Server Error'
        setIsLoading(false)
        console.error(err)
        if(err.response) {
            if(err.response.data.message) {
                msg = err.response.data.message
            }
        }

        Fail(msg)
    })
  }

  const getListElibCategoryDropdown = () => {
    setIsLoading(true)
    AuthService.getListElibCategoryDropdownAdmin().then(resp => {
        const response = resp.data;
        setCategories(response.data);
        
        setIsLoading(false)
    }).catch(err => {
        let msg = 'Internal Server Error'
        setIsLoading(false)
        console.error(err)
        if(err.response) {
            if(err.response.data.message) {
                msg = err.response.data.message
            }
        }

        Fail(msg)
    })
  }

  const Fail = (message) => {
    Swal.fire({  
        title: 'Gagal!',  
        text: message,  
        icon: 'warning',  
        showCancelButton: true,  
        cancelButtonText: 'Kembali',           
        showConfirmButton: false,  
    });   
  }

  useEffect(() => {
    getListElibCategoryDropdown();
    getListKelas()
  }, [])

  return(
    <React.Fragment>
      <AdminSideBar />
      <div className="md:ml-64 styleHead" style={{ backgroundColor: '#F3F4F6' }}>
        <div className="bg-orange md:px-8 pb-5" style={{height: '20vh', position: 'relative', padding: '0 30px 30px 30px'}}>
            <h1 className="tracking-wider title-24-600">Olah Dokumen E-Library</h1>
            {/* <h2 className="tracking-wider title-20-700 mt-5">Selamat Datang Tutor INGENIO !</h2> */}
            <img src={baseUrl + "/assets/logo/evaluasikelas.png"} className="img-opacity"></img>
        </div>

        <div className="px-3 md:px-8 pb-5">
            <div className="container mx-auto max-w-full">
                <div className="row mt-5">
                  <div className="col-12 my-2">
                    <FormGroup>
                      <div className="justify-start">
                        <div className="SearchTable">
                            <input  onChange={(e) => {
                              setKeyword(e.target.value)
                            }}type="text" name="" className="inputSearchTable" placeholder="&#x1F50E;&#xFE0E; Search..." id="" />
                            <Button onClick={() => {
                                getListKelas(`page=${page}&perPage=${perPage}&keyword=${keyword}`)
                            }} className="btnSearchTable">Cari</Button>
                        </div>
                        <Button className="btnOrange2" onClick={() => {
                            setInIdDoc('')
                            setInCatId('')
                            setInFileData(null)
                            setInFileName('')
                            setInTahun('')
                            setInAuthor('')
                            handleShow()
                        }}>
                            <div className="titleBtnOrange">
                                <MdOutlineAddCircleOutline size="23px" className="mr-2"/>Tambah E-Library
                            </div> 
                        </Button>
                      </div>
                    </FormGroup>
                  </div>
                  <div className="col-12 mt-3">
                      {/* <DataTable columns={columns} customStyles={customStyles} data={data} pagination /> */}
                      <CustomDatatable 
                          filteredItems={kelasData} 
                          columns={columns}
                          isLoading={isLoading}
                          page={page}
                          perPage={perPage}
                          hasPrev={hasPrev}
                          hasNext={hasNext}
                          count={count}
                          onTableChange={(e, data) => {
                              switch (e) {
                              case 'rowPerPageChange':
                                  setPerPage(data.perPage)
                                  getListKelas(`page=${page}&perPage=${data.perPage}&keyword=${keyword}`)
                                  break;
                              case 'rowPageChange':
                                  setPage(data.page)
                                  getListKelas(`page=${data.page}&perPage=${perPage}&keyword=${keyword}`)
                                  break;
                              
                              default:
                                  break;
                              }
                          }}
                      />
                  </div>
                </div>
                <Modal show={show} onHide={handleClose}
                      {...props}
                      size="md"
                      aria-labelledby="contained-modal-title-vcenter"
                      centered>
                  <Modal.Header closeButton>
                      <Modal.Title id="contained-modal-title-vcenter">
                          <div className="title-20-700">Tambah E-Library</div>
                      </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                      <div className="row p-4">
                          <div className="col-12">
                              <div className="title-18-500 mb-1">Kategori</div>
                              {/* <input type="text" class="formInputJurnal" placeholder="kategori..." /> */}
                              <Form.Select
                                  as="select"
                                  aria-label="Program"
                                  aria-describedby="basic-addon1"
                                  className="formInputJurnal"
                                  onChange={(e) => {
                                    setInCatId(e.target.value);
                                  }}
                              >
                                  <option selected disabled >Kategori</option>
                                  {
                                    categories.map((item, i) => {
                                        return <option value={item.id}>{item.name}</option>
                                    })
                                  }
                              </Form.Select>
                              <div className="title-18-500 mb-1">Nama Dokumen</div>
                              <input onChange={(e) => {
                                    setInFileName(e.target.value);
                                }} type="text" class="formInputJurnal" placeholder="nama dokumen..." />
                                
                                <div className="title-18-500 mb-1">Tahun</div>
                                <input value={inTahun} onChange={(e) => {
                                    setInTahun(e.target.value)
                                }} type="text" class="formInputJurnal" placeholder="Tahun..." />

                                <div className="title-18-500 mb-1">Penulis</div>
                              <input value={inAuthor} onChange={(e) => {
                                    setInAuthor(e.target.value)
                                }} type="text" class="formInputJurnal" placeholder="Penulis..." />

                              <div className="title-16-500">Upload File<span className="txtpdf ml-2">(PDF Maks. 50MB)</span></div>
                              <div className="relative mt-2">
                                  <input onChange={(e) => {
                                        const file = e.target.files[0]
                                        const size = file.size;
                                        const fileExt = file.name.split('.').pop();
                                        alert(`${file.name}.${fileExt} Size ${size}`);
                                        setInFileData(file)
                                    }} type="file" id="file" className="custom-file-input" style={{ position: 'absolute', right: '-10px' }} />
                                  <label htmlFor="file" className="labelFile">Upload Here<BsFillFileEarmarkArrowUpFill size="18px"/></label>
                              </div>
                          </div>
                          <div className="col-12 justify-end" style={{ marginTop: '4rem' }}>
                              <Button type="submit" onClick={uploadDocument} className="btnOrange3">
                                  <span className="txt-btn">Submit</span>
                              </Button>
                          </div>
                      </div>
                  </Modal.Body>
                </Modal>
                <Modal show={show2} onHide={handleClose2}
                      {...props}
                      size="md"
                      aria-labelledby="contained-modal-title-vcenter"
                      centered>
                  <Modal.Header closeButton>
                      <Modal.Title id="contained-modal-title-vcenter">
                          <div className="title-20-700">Edit E-Library</div>
                      </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                      <div className="row p-4">
                          <div className="col-12">
                              <div className="title-18-500 mb-1">Kategori</div>
                              {/* <input type="text" class="formInputJurnal" placeholder="kategori..." /> */}
                              <Form.Select
                                  as="select"
                                  aria-label="Program"
                                  aria-describedby="basic-addon1"
                                  className="formInputJurnal"
                                  value={inCatId}
                              >
                                  <option selected disabled >Kategori</option>
                                  {
                                    categories.map((item, i) => {
                                        return <option value={item.id}>{item.name}</option>
                                    })
                                  }
                              </Form.Select>
                              <div className="title-18-500 mb-1">Tahun</div>
                              <input value={inTahun} onChange={(e) => {
                                    setInTahun(e.target.value)
                                }} type="text" class="formInputJurnal" placeholder="Tahun..." />

                                <div className="title-18-500 mb-1">Penulis</div>
                              <input value={inAuthor} onChange={(e) => {
                                    setInAuthor(e.target.value)
                                }} type="text" class="formInputJurnal" placeholder="Penulis..." />

                              <div className="title-18-500 mb-1">Nama Dokumen</div>
                              <input value={inFileName} onChange={(e) => {
                                    setInFileName(e.target.value)
                                }} type="text" class="formInputJurnal" placeholder="nama dokumen..." />

                              <div className="title-16-500">Upload File<span className="txtpdf ml-2">(PDF Maks. 50MB)</span></div>
                              <div className="relative mt-2">
                                  <input onChange={(e) => {
                                        setInFileData(e.target.files[0])
                                    }} type="file" id="file2" className="custom-file-input" style={{ position: 'absolute', right: '-10px' }} />
                                  <label htmlFor="file2" className="labelFile">Upload Here<BsFillFileEarmarkArrowUpFill size="18px"/></label>
                              </div>
                          </div>
                          <div className="col-12 justify-end" style={{ marginTop: '4rem' }}>
                              <Button type="submit" onClick={updateDocument} className="btnOrange3">
                                  <span className="txt-btn">Submit</span>
                              </Button>
                          </div>
                      </div>
                  </Modal.Body>
                </Modal>
            </div>
        </div>

      </div>
    </React.Fragment>
    
  )
}