import React, { useState, useEffect } from 'react';
import {
  SideBar
} from '../../../components/layout/dashboard';
import { FormGroup, FormControl, Form } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import Button from '@material-tailwind/react/Button';
import InputIcon from '@material-tailwind/react/InputIcon'
import { getListSummary, getAllProgram } from '../../../store/actions/tryout';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import { Link } from 'react-router-dom';

function HasilTryOut(props) {
  document.title = "INGENIO Indonesia";
  document.body.style.backgroundColor = "#F3F4F6";
  const [programs, setPrograms] = useState([]);
  const [myTryout, setMyTryout] = useState([]);
  const tx_black = {
    color: 'black !important'
  }

  // FILTERING DATA
  const [selectedProgram, setSelectedProgram] = React.useState(null)
  const [filterText, setFilterText] = React.useState('');
  const [filterObj, setFilterObj] = React.useState({
    text: '',
    selectedProgram: ''
  })
  const onFilter = (data) => {
    setFilterText(data)
  }
  const onFilterSubmit = (e) => {
    e.preventDefault();
    let obj = {
      text: filterText,
      program: selectedProgram
    }
    setFilterObj(obj)
  }

  const filteredItems = myTryout.filter(
    item => (item.tryout.nama_tryout && item.tryout.nama_tryout.toLowerCase().includes(filterObj.text.toLowerCase())) && (!filterObj.program || item.tryout.program.nama.includes(filterObj.program)),
  );
  // END FILTERING DATA

  const { dispatch } = props;

  const columns = [
    {
      name: 'No.',
      selector: (row, idx) => idx + 1,
      sortable: true,
      width: '6rem'
    },
    {
      name: 'Nama Paket TryOut',
      selector: row => row.tryout.nama_tryout,
      sortable: true,
    },
    {
      name: 'Kode TryOut',
      selector: row => row.tryout.kode_tryout,
      sortable: true,
    },
    {
      name: 'Nama Subtryout',
      selector: row => row.subtryout.nama_subtryout,
      sortable: true,
    },
    {
      name: 'Program',
      selector: row => row.tryout.program.nama,
      sortable: true,
    },
    {
      name: 'Tanggal Submit',
      selector: row => moment(row.submitted_at).tz("Asia/Jakarta").format("DD MMMM YYYY HH:mm"),
      sortable: true,
    },
    {
      cell: (row) => <Link to={{
        pathname: "/dashboard/hasil-tryout/" + row.subtryout.id,
        state: {
          user: {
            id_tryout: row.id_tryout
          }
        }
      }}>
        <button className="pilih-tryout mx-auto">Review</button>
      </Link>,
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      width: '10rem'
    },
  ];

  useEffect(() => {
    dispatch(getListSummary())
      .then((response) => {
        console.log(response)
        return setMyTryout(response.data)
      })
      .catch((err) => {
        console.log(err)
      })
  }, [])

  useEffect(() => {
    dispatch(getAllProgram())
      .then((response) => {
        let obj = { ...response.data };
        let count = 1;
        let programArr = [];
        for (const key in obj) {
          console.log(obj[key]);
          let payload = {
            key: count,
            id: obj[key].id,
            nama: obj[key].nama,
          };
          programArr.push(payload)
          count++
        }
        setPrograms(programArr);
      });
  }, []);

  const customStyles = {
    table: {
      style: {
        borderRadius: '15px',
        border: '1px solid #BDC1C9',
      },
    },
    head: {
      style: {
        fontSize: '1.25rem',
        textTransform: "uppercase",
        color: '#6B7280',
        backgroundColor: '#f3f4f6',
        border: '1px solid #BDC1C9',
        borderTopLeftRadius: '15px',
        borderTopRightRadius: '15px',
      },
    },
    pagination: {
      style: {
        color: '#6B7280',
        fontSize: '1.1rem',
        minHeight: '56px',
        backgroundColor: '#f3f4f6',
        border: '1.25px solid #BDC1C9',
        borderBottomLeftRadius: '15px',
        borderBottomRightRadius: '15px',
      },
    },
  };

  return (
    <React.Fragment>
      <SideBar judul={"Hasil TryOut"} />
      <div className="md:ml-64">
        {/* <div className="bg-orange px-3 md:px-8 py-1" style={{ height: '2.5rem' }}>
          <div className="container md:pr-8 md:pl-10">
          </div>
        </div> */}

        <div className="px-3 md:px-8 mt-5">
          <div className="container mx-auto max-w-full">
            <div className="flex-2 flex-col">
              <div className="flex-2 mb-4">
                <form onSubmit={(e) => onFilterSubmit(e)}>
                  <div className="flex flex-row">
                    <div className="bg-white px-4 py-3 mr-3" style={{ borderRadius: '20px' }}>
                      <input
                        type="text"
                        name=""
                        value={filterText}
                        onChange={(e) => onFilter(e.target.value)}
                        style={{ backgroundColor: '#F3F4F6', width: '25rem', borderRadius: '12px', padding: '0.75rem', border: 'none !important', paddingLeft: '10px', fontFamily: 'Quicksand' }}
                        className="mr-3"
                        placeholder="Nama Tryout atau keyword"
                        id=""
                      />
                      <select
                        as="select"
                        aria-label="Program"
                        aria-describedby="basic-addon1"
                        className="my-tryout px-3 py-2 mx-3"
                        style={{ fontFamily: 'Quicksand' }}
                        onChange={(e) => setSelectedProgram(e.target.value)}
                      >
                        <option value="">Pilih Program</option>
                        {programs.map(item =>
                          <option key={item.key} value={item.nama}>{item.nama}</option>
                        )};
                      </select>
                      <button type="submit" className="pilih-tryout mx-3" onClick={(e) => onFilterSubmit(e)}>Cari</button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="flex-2">
              <DataTable columns={columns} data={filteredItems} customStyles={customStyles} pagination />
            </div>
          </div>
        </div>

      </div>
    </React.Fragment>

  )
}

export default connect()(HasilTryOut)