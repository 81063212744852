import React from 'react';
import { useState, useEffect } from 'react';
import AdminSideBar from '../../../../components/layout/dashboard/admin/AdminSideBar';
import Button from '@material-tailwind/react/Button';
import { FormGroup, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import { Link } from 'react-router-dom';
import { GrFormView } from 'react-icons/gr';
import { MdOutlineCalendarViewDay } from 'react-icons/md';
import { HiOutlineDownload, HiOutlineTrash, HiOutlinePencilAlt } from 'react-icons/hi';
import CustomDatatable from '../../../../components/common/CustomDatatable';
import AuthService from '../../../../services/auth';
import moment from 'moment-timezone';
import Swal from 'sweetalert2';

export default function InformasiTestimoniAdmin (props) {
  document.title = "INGENIO Indonesia";
  const [showSidebar, setShowSidebar] = useState('-left-64');
  var baseUrl = window.location.origin;
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [detailTesti, setDetailTesti] = useState('');

  // Filtering data
  const [isLoading, setIsLoading] = useState(true);
  const [hasNext, setHasNext] = useState(false)
  const [hasPrev, setHasPrev] = useState(false)
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [keyword, setKeyword] = useState('');
  const [kelasData, setKelasData] = useState([]);

  const columns = [
    {
        name: 'NO.',
        field: 'no',
        index: true,
        selector: row => row.no,
        sortable: true,
        minWidth: '1px'
    },
    {
        name: 'TANGGAL TESTIMONI',
        render: true,
        component: (row) => {
            return (
              <td className='main-table__bodyRow'>{moment(row.created_at).format('yyyy-MM-DD')}</td>
            )
        },
        selector: row => row.tanggal,
        sortable: true,
        // width: '15vw',
    },
    {
      name: 'NAMA PESERTA',
      render: true,
      component: (row) => {
          return (
              <td className='main-table__bodyRow'>{row.user.full_name}</td>
          )
      },
      selector: row => row.tanggal,
      sortable: true,
      // width: '15vw',
  },
  {
    name: 'ISI TESTIMONI',
    render: true,
    component: (row) => {
        return (
            <td className='main-table__bodyRow'>{row.isi_testimoni}</td>
        )
    },
    selector: row => row.tanggal,
    sortable: true,
    // width: '15vw',
},
    {
        name: 'Aksi',
        render: true,
        component: (row) => {
            return (
                <td className='main-table__bodyRow'>
                    <div style={{display: 'flex', flexDirection: 'row'}}>
                    <OverlayTrigger placement="top" overlay={<Tooltip id="button-tooltip-2">Lihat Testimoni</Tooltip>}>
                        <Link to='#' className="btnCircleBlack mr-3" onClick={() => {
                          setDetailTesti(row.isi_testimoni)
                          handleShow()
                        }}><MdOutlineCalendarViewDay size="22"/></Link>
                    </OverlayTrigger>
                    </div>
                </td>
            )
        },
        selector: row => row.tanggal,
        sortable: true,
        // width: '15vw',
    },
];
  const data = [
      {
          no: 1,
          tgl: '12-12-2021',
          kode: '1122334',
          nama: 'lorem',
          isi: 'lorem ipsum dolor.',
      },
      {
          no: 2,
          tgl: '12-12-2021',
          kode: '1122334',
          nama: 'lorem',
          isi: 'lorem ipsum dolor.',
      },
  ];

  const customStyles = {
      table: {
        style: {
          borderRadius: '15px',
          border: '1px solid #BDC1C9',
        },
      },
      head: {
        style: {
          fontSize: '1.25rem',
          textTransform: "uppercase",
          color: '#6B7280',
          backgroundColor: '#f3f4f6',
          border: '1px solid #BDC1C9',
          borderTopLeftRadius: '15px',
          borderTopRightRadius: '15px',
        },
      },
      pagination: {
        style: {
          color: '#6B7280',
          fontSize: '1.1rem',
          minHeight: '56px',
          backgroundColor: '#f3f4f6',
          border: '1.25px solid #BDC1C9',
          borderBottomLeftRadius: '15px',
          borderBottomRightRadius: '15px',
        },
      },
  };

  const AreYouSure = () => {
      Swal.fire({  
          title: 'Sudah Yakin?',  
          text: 'Apakah Anda Yakin Ingin Menghapus Data Ini?',  
          icon: 'warning',  
          showCancelButton: true,  
          cancelButtonText: 'Tidak',  
          confirmButtonText: 'Ya'  
      })
  }

  const Succeed = () => {
      Swal.fire({  
          title: 'Data Berhasil Tersimpan !',  
          text: '',  
          icon: 'success',  
          showCancelButton: false,              
          confirmButtonText: 'OK'  
      }).then((result) => {
        if (result.isConfirmed) {
          window.location.reload();
        }
      })
  }

  const downloadListParticipant = () => {
    setIsLoading(true)
    AuthService.downloadTestimoniParticipant().then(resp => {
        const response = resp.data;
        const url = `https://ingenioindonesia.com/public/assets/${response.data}`

        window.open(url, '_blank')
        
        setIsLoading(false)
    }).catch(err => {
        let msg = 'Internal Server Error'
        setIsLoading(false)
        console.error(err)
        if(err.response) {
            if(err.response.data.message) {
                msg = err.response.data.message
            }
        }

        Fail(msg)
    })
  }

  const getListKelas = (params = '') => {
    setIsLoading(true)
    AuthService.getListTestiAdmin(params).then(resp => {
        const response = resp.data;
        setKelasData(response.data);

        setPage(response.meta.page)
        setPerPage(response.meta.perPage)
        setCount(response.meta.count)

        setHasNext(response.meta.hasNext)
        setHasPrev(response.meta.hasPrev);
        
        setIsLoading(false)
    }).catch(err => {
      let msg = 'Internal Server Error'
      setIsLoading(false)
      console.error(err)
      if(err.response) {
          if(err.response.data.message) {
              msg = err.response.data.message
          }
      }

      Fail(msg)
    })
  }

  const Fail = (message) => {
    Swal.fire({  
        title: 'Gagal!',  
        text: message,  
        icon: 'warning',  
        showCancelButton: true,  
        cancelButtonText: 'Kembali',           
        showConfirmButton: false,  
    });   
}

  useEffect(() => {
    getListKelas();
}, [])

  return(
    <React.Fragment>
      <AdminSideBar />
      <div className="md:ml-64 styleHead" style={{ backgroundColor: '#F3F4F6' }}>
        <div className="bg-orange md:px-8 pb-5" style={{height: '20vh', position: 'relative', padding: '0 30px 30px 30px'}}>
            <h1 className="tracking-wider title-24-600">Informasi Testimoni</h1>
            {/* <h2 className="tracking-wider title-20-700 mt-5">Selamat Datang Tutor INGENIO !</h2> */}
            <img src={baseUrl + "/assets/logo/evaluasikelas.png"} className="img-opacity"></img>
        </div>

        <div className="px-3 md:px-8 pb-5">
            <div className="container mx-auto max-w-full">
                <div className="row mt-5">
                  <div className="col-12 my-2">
                    <FormGroup>
                      <div className="justify-start">
                        <div className="SearchTable">
                            <input onChange={(e) => {
                              setKeyword(e.target.value)
                            }} type="text" name="" className="inputSearchTable" placeholder="&#x1F50E;&#xFE0E; Search..." id="" />
                            <Button onClick={() => {
                                getListKelas(`page=${page}&perPage=${perPage}&keyword=${keyword}`)
                            }}  className="btnSearchTable">Cari</Button>
                        </div>
                        <OverlayTrigger placement="top" overlay={<Tooltip id="button-tooltip-2">Download Testimoni</Tooltip>}>
                          <Button onClick={() => downloadListParticipant()} className="btnOrange2">
                              <div className="titleBtnOrange">
                                  <HiOutlineDownload size="23px" className="mr-2"/>Download
                              </div> 
                          </Button>
                        </OverlayTrigger>
                      </div>
                    </FormGroup>
                  </div>
                  <div className="col-12 mt-3">
                      {/* <DataTable columns={columns} customStyles={customStyles} data={data} pagination /> */}
                      <CustomDatatable 
                          filteredItems={kelasData} 
                          columns={columns}
                          isLoading={isLoading}
                          page={page}
                          perPage={perPage}
                          hasPrev={hasPrev}
                          hasNext={hasNext}
                          count={count}
                          onTableChange={(e, data) => {
                              switch (e) {
                              case 'rowPerPageChange':
                                  setPerPage(data.perPage)
                                  getListKelas(`page=${page}&perPage=${data.perPage}&keyword=${keyword}`)
                                  break;
                              case 'rowPageChange':
                                  setPage(data.page)
                                  getListKelas(`page=${data.page}&perPage=${perPage}&keyword=${keyword}`)
                                  break;
                              
                              default:
                                  break;
                              }
                          }}
                      />
                  </div>
                </div>
                <Modal show={show} onHide={handleClose}
                      {...props}
                      size="md"
                      aria-labelledby="contained-modal-title-vcenter"
                      centered>
                  <Modal.Header closeButton>
                      <Modal.Title id="contained-modal-title-vcenter">
                          <div className="title-20-700">Testimoni Peserta</div>
                      </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                      <div className="row p-4">
                          <div className="col-12">
                            <textarea name="" class="formInputJurnal" value={detailTesti} style={{padding: '10px 14px', minHeight: '100px'}}></textarea>
                          </div>
                      </div>
                  </Modal.Body>
                </Modal>
            </div>
        </div>

      </div>
    </React.Fragment>
    
  )
}