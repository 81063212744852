import React, { useEffect, useState } from 'react';
import { BsChevronLeft, BsImage, BsCheck } from "react-icons/bs";
import { InputJawaban, SoalWYSIWYG, UploadGambar } from '../../../../components/dashboard';
import { Link, useParams, useHistory, NavLink, useLocation } from 'react-router-dom';
import { BsFillTrashFill } from 'react-icons/bs';

import { connect } from "react-redux";
import { getAllTopik, getAllBidang, getAllKompetensi, createBankSoalSubTO } from "../../../../store/actions/tryout";

import * as Yup from 'yup';
import { useForm, FormProvider, useFieldArray, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Swal from 'sweetalert2';

import { getBankSoal, updateBankSoal, uploadGambarSoal, uploadGambarPembahasan, uploadGambarJawaban } from '../../../../store/actions/tryout';
import TryoutService from '../../../../services/tryout';
import { fileValidation } from '../../../../helpers/utils'

const thumbsContainer = {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: 16
};

const thumb = {
    display: "inline-flex",
    borderRadius: 2,
    border: "1px solid #eaeaea",
    marginBottom: 8,
    marginRight: 8,
    width: 100,
    height: 100,
    padding: 4,
    boxSizing: "border-box"
};

const thumbInner = {
    display: "flex",
    minWidth: 0,
    overflow: "hidden"
};

const img = {
    display: "block",
    width: "auto",
    height: "100%"
};

let stopSubmit = false;

// Delete status
let isDeleteGambarSoal = false;
let isDeleteGambarPembahasan = false;
let deleteGambarJawabans = [];

function SoalEditorEditSoal(props) {
    var baseUrl = window.location.origin;
    document.body.style.backgroundColor = "#F3F4F6";

    const validationSchema = Yup.object().shape({
        soal: Yup.string()
            .required('Soal is required'),
        id_topik: Yup.string()
            .required('Topik is required'),
        id_kompetensi: Yup.string()
            .required('Kompetensi is required'),
        id_bidang: Yup.string()
            .required('Bidang is required'),
        answer_key: Yup.string()
            .required('Correct answer is required'),
    });

    const [jumlahJawaban, setJumlahJawaban] = React.useState([65, 66, 67, 68]);
    const [dataJawaban, setDataJawaban] = useState([]);
    const [dataBidang, setDataBidang] = React.useState([]);
    const [dataKompetensi, setDataKompetensi] = React.useState([]);
    const [dataTopik, setDataTopik] = React.useState([]);
    const [dataBanksoal, setDataBanksoal] = React.useState({});

    const [dataTinjauan1, setDataTinjauan1] = React.useState([]);
    const [dataTinjauan2, setDataTinjauan2] = React.useState([]);
    const [dataTinjauan3, setDataTinjauan3] = React.useState([]);
    const [dataTinjauan4, setDataTinjauan4] = React.useState([]);
    const [dataTinjauan5, setDataTinjauan5] = React.useState([]);
    const [dataTinjauan6, setDataTinjauan6] = React.useState([]);
    const [dataTinjauan7, setDataTinjauan7] = React.useState([]);

    const [isLoading, setIsLoading] = useState(false);

    let { id, subtryoutId, soalId } = useParams();

    const location = useLocation();
    const history = useHistory();
    const { dispatch } = props;

    const methods = useForm({ resolver: yupResolver(validationSchema) });

    const onSubmit = async (data) => {
        if(stopSubmit) {
            return;
        }
        if(isLoading){
            return;
        }
        setIsLoading(true)
        let formData = new FormData();
        let soal_url, pembahasan_url, jawaban_url;

        if(isDeleteGambarSoal) {
            data.gambar_soal = null;
        }

        if(isDeleteGambarPembahasan) {
            data.gambar_pembahasan = null
        }

        


        if(data.gambar_soal){
            if(data.gambar_soal.path){ //Validation file gambar soal
                if(!fileValidation(data.gambar_soal.path.split('.')[data.gambar_soal.path.split('.').length -1])){
                    Swal.fire({  
                        title: 'Gagal!',  
                        text: 'File not allowed (jpg, png)',  
                        icon: 'warning',  
                        showCancelButton: true,  
                        cancelButtonText: 'Kembali',           
                        showConfirmButton: false,  
                    });  
                    setIsLoading(false)
                    return;
                }
            }
        }
        

        if(data.gambar_pembahasan){
            if(data.gambar_pembahasan.path){ //Validation file gambar pembahasan
                if(!fileValidation(data.gambar_pembahasan.path.split('.')[data.gambar_pembahasan.path.split('.').length -1])){
                    Swal.fire({  
                        title: 'Gagal!',  
                        text: 'File not allowed (jpg, png)',  
                        icon: 'warning',  
                        showCancelButton: true,  
                        cancelButtonText: 'Kembali',           
                        showConfirmButton: false,  
                    });  
                    setIsLoading(false)
                    return;
                }
            }
        }
        

        // Validation file gambar jawaban
        
        for (let l = 0; l < data.jawaban.length; l++) {
            const el = data.jawaban[l];
            if(el.gambar){
                if(el.gambar.path){
                    if(!fileValidation(el.gambar.path.split('.')[el.gambar.path.split('.').length -1])){
                        Swal.fire({  
                            title: 'Gagal!',  
                            text: 'File not allowed (jpg, png)',  
                            icon: 'warning',  
                            showCancelButton: true,  
                            cancelButtonText: 'Kembali',           
                            showConfirmButton: false,  
                        });  
                        setIsLoading(false)
                        return;
                    }
                }
            }
            
        }

        if (data.gambar_soal) {
            if(data.gambar_soal.path){
                formData = new FormData();
                formData.append('gambar_soal', data.gambar_soal);
                try {
                    const response = await dispatch(uploadGambarSoal(formData))
                    soal_url = response.data.photo_url.image_url;
                    data.gambar_soal = soal_url;
                }catch (err) {
                    Swal.fire({  
                        title: 'Gagal!',  
                        text: 'Something went wrong',  
                        icon: 'warning',  
                        showCancelButton: true,  
                        cancelButtonText: 'Kembali',           
                        showConfirmButton: false,  
                    });  
                    setIsLoading(false)
                    return;
                }
            }
            
        }

        if (data.gambar_pembahasan) {
            if(data.gambar_pembahasan.path){
                formData = new FormData();
                formData.append('gambar_pembahasan', data.gambar_pembahasan);
                await dispatch(uploadGambarPembahasan(formData))
                    .then((response) => {
                        pembahasan_url = response.data.photo_url.image_url;
                        data.gambar_pembahasan = pembahasan_url;
                    })
                    .catch(err => {
                        console.log(err)
                        Swal.fire({  
                            title: 'Gagal!',  
                            text: 'Something went wrong',  
                            icon: 'warning',  
                            showCancelButton: true,  
                            cancelButtonText: 'Kembali',           
                            showConfirmButton: false,  
                        });  
                        setIsLoading(false)
                        return;
                    })
            }
            
        }

        let arrJawaban = data.jawaban;

        for (let i = 0; i < arrJawaban.length; i++) {
            let deleteJawabanPerKey = false;
            for (let m = 0; m < deleteGambarJawabans.length; m++) {
                const jwbn = deleteGambarJawabans[m];
                if(jwbn.key === arrJawaban[i].key){
                    
                    deleteJawabanPerKey = true;
                }
            }
            if (arrJawaban[i].gambar) {
                if(arrJawaban[i].gambar.path){
                    if(!arrJawaban[i].gambar.size) {
                        arrJawaban[i].gambar = null;
                        continue;
                    }
                    formData = new FormData();
                    formData.set('gambar', arrJawaban[i].gambar);
                    try {
                        const response = await dispatch(uploadGambarJawaban(formData))
                        deleteJawabanPerKey = false;
                        jawaban_url = response.data.photo_url.image_url;
                        arrJawaban[i].gambar = jawaban_url;
                    }catch(err) {
                        console.log(err)
                            Swal.fire({  
                                title: 'Gagal!',  
                                text: 'Something went wrong',  
                                icon: 'warning',  
                                showCancelButton: true,  
                                cancelButtonText: 'Kembali',           
                                showConfirmButton: false,  
                            }); 
                            setIsLoading(false)
                            return;
                    }
                }
            }

            if(deleteJawabanPerKey) {
                arrJawaban[i].gambar = null;
            }
            
        }

        data.jawaban = arrJawaban;

        console.log(data)
        let id_banksoal;
        try {
            delete data.seq
            await dispatch(updateBankSoal(soalId, data))
            setIsLoading(false)
            deleteGambarJawabans = [];
            // id_banksoal = response.data.id;
            history.push('/dashboard/akademik/tryout/' + location.state.id_tryout + '/soal-editor/' + location.state.id_subtryout);
        }catch(err) {
            console.log(err, 'dari banksoal 1');
                Swal.fire({  
                    title: 'Gagal!',  
                    text: 'Something went wrong',  
                    icon: 'warning',  
                    showCancelButton: true,  
                    cancelButtonText: 'Kembali',           
                    showConfirmButton: false,  
                });  
                setIsLoading(false)
                return;
        }
    };


    useEffect(() => {
        dispatch(getAllTopik('perPage=5000'))
            .then((response) => {
                setDataTopik(response.data);
            })
        dispatch(getAllBidang('perPage=5000'))
            .then((response) => {
                setDataBidang(response.data);
            })
        dispatch(getAllKompetensi('perPage=5000'))
            .then((response) => {
                setDataKompetensi(response.data);
            })
        TryoutService.getFirstTinjauan('?perPage=100')
        .then(resp => {
            setDataTinjauan1(resp.data.data);
        }).catch(err => {
            console.error(err)
        })
        TryoutService.getSecondTinjauan('?perPage=100')
        .then(resp => {
            setDataTinjauan2(resp.data.data);
        }).catch(err => {
            console.error(err)
        })
        TryoutService.getThirdTinjauan('?perPage=100')
        .then(resp => {
            setDataTinjauan3(resp.data.data);
        }).catch(err => {
            console.error(err)
        })
        TryoutService.getFourthTinjauan('?perPage=100')
        .then(resp => {
            setDataTinjauan4(resp.data.data);
        }).catch(err => {
            console.error(err)
        })
        TryoutService.getFifthTinjauan('?perPage=100')
        .then(resp => {
            setDataTinjauan5(resp.data.data);
        }).catch(err => {
            console.error(err)
        })
        TryoutService.getSixthTinjauan('?perPage=100')
        .then(resp => {
            setDataTinjauan6(resp.data.data);
        }).catch(err => {
            console.error(err)
        })
        TryoutService.getSeventhTinjauan('?perPage=100')
        .then(resp => {
            setDataTinjauan7(resp.data.data);
        }).catch(err => {
            console.error(err)
        })
    }, [])

    useEffect(() => {
        
        dispatch(getBankSoal(soalId))
            .then(async (response) => {
                console.log(response)
                setDataJawaban(response.data.jawaban);
                return await setDataBanksoal(response.data);
            })
            .then(() => {
                
                let totalJawab = dataBanksoal.jawaban.length - 1;
                let lastAnswer = dataBanksoal.jawaban[totalJawab].key;
                let codeKey = lastAnswer.charCodeAt(0);
                let abjadAkhir = jumlahJawaban[jumlahJawaban.length - 1];
                if (abjadAkhir !== codeKey) {
                    console.log(abjadAkhir)
                    let abjadCount = codeKey - abjadAkhir;
                    console.log(abjadCount)
                    for (let i = 0; i < abjadCount; i++) {
                        let newAlpha = abjadAkhir + (i + 1);
                        setJumlahJawaban(oldArray => [...oldArray, newAlpha]);
                    }
                    
                };
                [
                    { name: 'soal', value: dataBanksoal.soal },
                    { name: 'gambar_soal', value: dataBanksoal.gambar_soal },
                    { name: 'answer_key', value: dataBanksoal.answer_key },
                    { name: 'jawaban', value: dataBanksoal.jawaban },
                    { name: 'id_topik', value: dataBanksoal.topik.id },
                    { name: 'id_kompetensi', value: dataBanksoal.kompetensi.id },
                    { name: 'id_bidang', value: dataBanksoal.bidang.id },
                    { name: 'pembahasan', value: dataBanksoal.pembahasan },
                    { name: 'gambar_pembahasan', value: dataBanksoal.gambar_pembahasan },
                ].forEach(({ name, value }) => methods.setValue(name, value))
            })
            .catch((err) => {
                console.log(err);
            })
    }, [dataBanksoal.id])

    const tambahOpsi = () => {
        let newAlpha = jumlahJawaban[jumlahJawaban.length - 1] + 1;
        setJumlahJawaban(oldArray => [...oldArray, newAlpha]);
        console.log(jumlahJawaban);
    }

    const deleteGambarJawaban = (key) => {
        stopSubmit = true;
        TryoutService.deleteGambarJawabanByBanksoalIdAkademik(soalId, key)
        .then(resp => {
            const response = resp.data;
            dispatch(getBankSoal(soalId))
                .then(async (response) => {
                    console.log(response)
                    setDataJawaban(response.data.jawaban);
                    return await setDataBanksoal(response.data);
                })
                .then(() => {
                    
                    let totalJawab = dataBanksoal.jawaban.length - 1;
                    let lastAnswer = dataBanksoal.jawaban[totalJawab].key;
                    let codeKey = lastAnswer.charCodeAt(0);
                    let abjadAkhir = jumlahJawaban[jumlahJawaban.length - 1];
                    if (abjadAkhir !== codeKey) {
                        console.log(abjadAkhir)
                        let abjadCount = codeKey - abjadAkhir;
                        console.log(abjadCount)
                        for (let i = 0; i < abjadCount; i++) {
                            let newAlpha = abjadAkhir + (i + 1);
                            setJumlahJawaban(oldArray => [...oldArray, newAlpha]);
                        }
                        
                    };
                    [
                        { name: 'soal', value: dataBanksoal.soal },
                        { name: 'gambar_soal', value: dataBanksoal.gambar_soal },
                        { name: 'answer_key', value: dataBanksoal.answer_key },
                        { name: 'jawaban', value: dataBanksoal.jawaban },
                        { name: 'id_topik', value: dataBanksoal.topik.id },
                        { name: 'id_kompetensi', value: dataBanksoal.kompetensi.id },
                        { name: 'id_bidang', value: dataBanksoal.bidang.id },
                        { name: 'pembahasan', value: dataBanksoal.pembahasan },
                        { name: 'gambar_pembahasan', value: dataBanksoal.gambar_pembahasan },
                    ].forEach(({ name, value }) => methods.setValue(name, value))
                    stopSubmit = false;
                })
                .catch((err) => {
                    console.log(err);
                    stopSubmit = false;
                })
        }).catch(err => {
            console.error(err)
            stopSubmit = false;
        })
    }

    const deleteGambarPembahasan = () => {
        isDeleteGambarPembahasan = true;
        stopSubmit = true;
        TryoutService.deleteGambarPembahasanByBanksoalIdAkademik(soalId)
        .then(resp => {
            const response = resp.data;
            dispatch(getBankSoal(soalId))
                .then(async (response) => {
                    console.log(response)
                    setDataJawaban(response.data.jawaban);
                    return await setDataBanksoal(response.data);
                })
                .then(() => {
                    
                    let totalJawab = dataBanksoal.jawaban.length - 1;
                    let lastAnswer = dataBanksoal.jawaban[totalJawab].key;
                    let codeKey = lastAnswer.charCodeAt(0);
                    let abjadAkhir = jumlahJawaban[jumlahJawaban.length - 1];
                    if (abjadAkhir !== codeKey) {
                        console.log(abjadAkhir)
                        let abjadCount = codeKey - abjadAkhir;
                        console.log(abjadCount)
                        for (let i = 0; i < abjadCount; i++) {
                            let newAlpha = abjadAkhir + (i + 1);
                            setJumlahJawaban(oldArray => [...oldArray, newAlpha]);
                        }
                        
                    };
                    [
                        { name: 'soal', value: dataBanksoal.soal },
                        { name: 'gambar_soal', value: dataBanksoal.gambar_soal },
                        { name: 'answer_key', value: dataBanksoal.answer_key },
                        { name: 'jawaban', value: dataBanksoal.jawaban },
                        { name: 'id_topik', value: dataBanksoal.topik.id },
                        { name: 'id_kompetensi', value: dataBanksoal.kompetensi.id },
                        { name: 'id_bidang', value: dataBanksoal.bidang.id },
                        { name: 'pembahasan', value: dataBanksoal.pembahasan },
                        { name: 'gambar_pembahasan', value: dataBanksoal.gambar_pembahasan },
                    ].forEach(({ name, value }) => methods.setValue(name, value))
                    stopSubmit = false;
                    isDeleteGambarPembahasan = false;
                })
                .catch((err) => {
                    console.log(err);
                    stopSubmit = false;
                    isDeleteGambarPembahasan = false;
                })
        }).catch(err => {
            console.error(err)
            stopSubmit = false;
            isDeleteGambarPembahasan = false;
        })
    }

    const deleteGambarSoal = () => {
        isDeleteGambarSoal = true;
        stopSubmit = true;
        TryoutService.deleteGambarSoalByBanksoalIdAkademik(soalId)
            .then(resp => {
                const response = resp.data;
                dispatch(getBankSoal(soalId))
                    .then(async (response) => {
                        console.log(response)
                        setDataJawaban(response.data.jawaban);
                        return await setDataBanksoal(response.data);
                    })
                    .then(() => {
                        
                        let totalJawab = dataBanksoal.jawaban.length - 1;
                        let lastAnswer = dataBanksoal.jawaban[totalJawab].key;
                        let codeKey = lastAnswer.charCodeAt(0);
                        let abjadAkhir = jumlahJawaban[jumlahJawaban.length - 1];
                        if (abjadAkhir !== codeKey) {
                            console.log(abjadAkhir)
                            let abjadCount = codeKey - abjadAkhir;
                            console.log(abjadCount)
                            for (let i = 0; i < abjadCount; i++) {
                                let newAlpha = abjadAkhir + (i + 1);
                                setJumlahJawaban(oldArray => [...oldArray, newAlpha]);
                            }
                            
                        };
                        [
                            { name: 'soal', value: dataBanksoal.soal },
                            { name: 'gambar_soal', value: dataBanksoal.gambar_soal },
                            { name: 'answer_key', value: dataBanksoal.answer_key },
                            { name: 'jawaban', value: dataBanksoal.jawaban },
                            { name: 'id_topik', value: dataBanksoal.topik.id },
                            { name: 'id_kompetensi', value: dataBanksoal.kompetensi.id },
                            { name: 'id_bidang', value: dataBanksoal.bidang.id },
                            { name: 'pembahasan', value: dataBanksoal.pembahasan },
                            { name: 'gambar_pembahasan', value: dataBanksoal.gambar_pembahasan },
                        ].forEach(({ name, value }) => methods.setValue(name, value))
                        stopSubmit = false;
                    })
                    .catch((err) => {
                        console.log(err);
                        stopSubmit = false;
                    })
            }).catch(err => {
                console.error(err)
                stopSubmit = false;
            })
    }

    return (
        <React.Fragment>
            <nav className="bg-orange py-2 px-3">
                <div className="container max-w-full mx-auto flex items-center justify-between md:pr-8 md:pl-10">
                    <div className="flex justify-between items-center w-full">
                        <NavLink
                            to={"/dashboard/akademik/tryout/" + id + "/soal-editor/" + subtryoutId}
                            className="flex items-center gap-4 text-md text-black font-bold px-2 py-2 rounded-lg"
                            style={{ fontFamily: 'Quicksand' }}
                        >
                            <BsChevronLeft style={{ stroke: "black", strokeWidth: "1" }} />
                            {location.state.nama_tryout} - {location.state.kode_tryout}
                        </NavLink>
                    </div>
                </div>
            </nav>
            <FormProvider id="form_tambah_soal" {...methods} encType="multipart/form-data">
                <form onSubmit={methods.handleSubmit(onSubmit)}>
                    <div className="container max-w-full mx-auto flex items-center justify-between py-5 px-5">
                        <div class="shadow-md rounded-lg border border-2 overflow-hidden col-12 col-lg-10 mx-auto">
                            <div className="py-3 px-3 bg-gray font-medium" style={{ fontFamily: 'Quicksand' }}>
                                Soal No. {location.state.nomor}
                            </div>
                            <input
                                {...methods.register("seq")}
                                id="seq"
                                value={location.state.nomor}
                                style={{display: "none"}}
                            />
                            <div className="block bg-white px-5 py-2">
                                <div className="flex w-full mx-auto xl:mx-0 mb-2">
                                    <div className="w-9/12 px-4">
                                        <Controller
                                            name="soal"
                                            control={methods.control}
                                            render={({
                                                field: { onChange, onBlur, value, name, ref } }) => {
                                                if (value) {
                                                    return <SoalWYSIWYG value={value} onChange={onChange} control={methods.control} />
                                                }
                                                return <div />
                                            }}
                                            defaultValue={dataBanksoal.soal}
                                        />
                                    </div>
                                    <div className="w-3/12 flex flex-col">
                                        {
                                            dataBanksoal.gambar_soal ?
                                                <div className="flex flex-row">
                                                    <LazyLoadImage
                                                        src={dataBanksoal.gambar_soal}
                                                        alt={'Gambar ' + `Soal`}
                                                        effect="blur"
                                                        width="150rem"
                                                    />
                                                    <button
                                                        class="bg-white hover:bg-blue-light font-bold py-2 px-4 hover:border-blue rounded ml-2 flex items-center"
                                                        style={{ border: '2px solid #9ca3af', fontFamily: 'Quicksand', color: "#000" }}
                                                        onClick={() => deleteGambarSoal()}
                                                    >
                                                        <BsFillTrashFill />
                                                    </button>
                                                </div>
                                                : <Controller
                                                    name="gambar_soal"
                                                    control={methods.control}
                                                    render={({
                                                        field: { onChange, onBlur, value, name, ref } }) => {
                                                        return <UploadGambar value={value} onChange={(e) => {
                                                            isDeleteGambarSoal = false;
                                                            onChange(e.target.files[0]);
                                                        }} />
                                                    }}
                                                />
                                        }

                                    </div>
                                </div>
                                {
                                    dataJawaban.length > 0 ?
                                    jumlahJawaban.map((i, index) => {
                                        return (
                                            <InputJawaban onUpdate={(key) => {
                                                deleteGambarJawaban(key)
                                                for (let k = 0; k < deleteGambarJawabans.length; k++) {
                                                    const el = deleteGambarJawabans[k];
                                                    if(el.key === key){
                                                        
                                                        return;
                                                    }
                                                }
                                                deleteGambarJawabans.push({key})
                                                
                                                
                                                
                                            }} key={index} index={index} dataJawaban={dataJawaban[index]} jawaban={String.fromCharCode(i)} name={'jawaban_' + String.fromCharCode(i)} />
                                        )
                                    })
                                    :
                                    null
                                }

                                <div className="flex w-full mx-auto xl:mx-0 my-3">
                                    <div className="w-9/12 flex" style={{ paddingLeft: '72px' }}>
                                        <button
                                            class="false flex items-center justify-center gap-1 outline-none tracking-wider focus:outline-none focus:shadow-none transition-all duration-300 rounded-md py-1.5 px-3 text-sm leading-normal bg-white border border-solid shadow-none border-black hover:bg-white hover:border-black hover:text-black hover:bg-white active:bg-white undefined"
                                            style={{ position: 'relative', overflow: 'hidden', fontFamily: 'Quicksand', color: '#9CA3AF' }}
                                            onClick={() => tambahOpsi()}
                                        >
                                            Tambah Opsi Jawaban
                                        </button>
                                    </div>
                                </div>
                                <h5 class="line-divider mb-4" style={{ fontSize: '1rem' }}><span class="span-line-divider ">Pembahasan (Opsional)</span></h5>
                                <div className="flex w-full mx-auto xl:mx-0 mb-2">
                                    <div className="w-9/12 flex px-4">
                                        <textarea
                                            {...methods.register("pembahasan")}
                                            id="pembahasan"
                                            rows={2}
                                            style={{ fontFamily: 'Quicksand' }}
                                            className="px-3 py-2 text-gray-600 dark:text-gray-400 focus:outline-none focus:border focus:border-indigo-700 dark:focus:border-indigo-700 dark:border-gray-700 dark:bg-gray-800 bg-white font-normal w-100 h-10 flex items-center pl-3 text-sm border-gray-300 rounded border mr-3"
                                            placeholder="Pembahasan"
                                        />
                                    </div>
                                    <div className="w-3/12 flex flex-col">
                                        {
                                            dataBanksoal.gambar_pembahasan ?
                                                <div className="flex flex-row">
                                                    <LazyLoadImage
                                                        src={dataBanksoal.gambar_pembahasan}
                                                        alt={'Gambar ' + `Pembahasan`}
                                                        effect="blur"
                                                        width="150rem"
                                                    />
                                                    <button
                                                        class="bg-white hover:bg-blue-light font-bold py-2 px-4 hover:border-blue rounded ml-2 flex items-center"
                                                        style={{ border: '2px solid #9ca3af', fontFamily: 'Quicksand', color: "#000" }}
                                                        // onClick={() => deleteGambarSoal(soalId)}
                                                        onClick={() => deleteGambarPembahasan()}
                                                    >
                                                        <BsFillTrashFill />
                                                    </button>
                                                </div> :
                                                <Controller
                                                    name="gambar_pembahasan"
                                                    control={methods.control}
                                                    render={({
                                                        field: { onChange, onBlur, value, name, ref } }) => {
                                                        return <UploadGambar value={value} onChange={(e) => {
                                                            onChange(e.target.files[0])
                                                        }} />
                                                    }}
                                                />
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="py-3 px-3 bg-gray font-medium" style={{ fontFamily: 'Quicksand', borderBottom: '1px solid #9CA3AF' }}>
                            <div class="flex mb-3">
                                <select
                                    {...methods.register("id_kompetensi")}
                                    placeholder="kompetensi"
                                    className="py-1 px-3 mx-2"
                                    style={{ fontFamily: 'Quicksand', fontWeight: 500, border: '0.5px solid #9CA3AF', borderRadius: '5px' }}>
                                    <option value="">pilih kompetensi</option>
                                    {dataKompetensi.map(item =>
                                    <option key={item.key} value={item.id}>{item.nama}</option>
                                    )};
                                </select>
                                <select
                                    {...methods.register("id_bidang")}
                                    placeholder="bidang"
                                    className="py-1 px-3 mx-2"
                                    style={{ fontFamily: 'Quicksand', fontWeight: 500, border: '0.5px solid #9CA3AF', borderRadius: '5px' }}>
                                    <option value="">pilih bidang</option>
                                    {dataBidang.map(item =>
                                    <option key={item.key} value={item.id}>{item.nama}</option>
                                    )};
                                </select>
                                <select
                                    {...methods.register("id_topik")}
                                    placeholder="topik"
                                    className="py-1 px-3 mx-2"
                                    style={{ fontFamily: 'Quicksand', fontWeight: 500, border: '0.5px solid #9CA3AF', borderRadius: '5px' }}>
                                    <option value="">pilih topik</option>
                                    {dataTopik.map(item =>
                                    <option key={item.key} value={item.id}>{item.nama}</option>
                                    )};
                                </select>
                            </div>    
                            </div>
                            <div className="py-3 px-3 bg-gray font-medium flex justify-between" style={{ fontFamily: 'Quicksand' }}>
                                <div className="flex-row">
                                    {/* <button
                                        class="false flex items-center justify-center gap-1 outline-none tracking-wider focus:outline-none focus:shadow-none transition-all duration-300 rounded-lg py-1.5 px-3 text-sm leading-normal bg-white border border-solid shadow-none text-black border-black hover:bg-white hover:border-black hover:text-black hover:bg-white active:bg-white undefined"
                                        style={{ position: 'relative', overflow: 'hidden', fontFamily: 'Quicksand' }}
                                    >
                                        total grade
                                    </button> */}
                                </div>
                                <div className="flex-row-reverse">
                                    <button type="submit" class="bg-green hover:bg-blue-light text-white font-bold py-2 px-4 hover:border-blue rounded ml-2" style={{ borderBottom: '4px solid #22C55E' }}>
                                        {isLoading ? 'Saving...' : 'SAVE'}
                                    </button>
                                    <button onClick={() => history.goBack()} class="bg-white hover:bg-blue-light font-bold py-2 px-4 hover:border-blue rounded ml-2" style={{ borderBottom: '4px solid #9ca3af', color: "#9ca3af" }}>
                                        CANCEL
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </FormProvider>

        </React.Fragment>
    );
}

export default connect()(SoalEditorEditSoal)