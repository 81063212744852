import Button from '@material-tailwind/react/Button';
import React, { useState, useEffect } from 'react';
import { DropdownButton, Dropdown, Form } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import { Link, useParams, useHistory } from 'react-router-dom';
import { BsPencilSquare, BsArrowLeftShort, BsCloudDownload } from 'react-icons/bs';
import TutorSidebar from '../../../components/layout/dashboard/tutor/TutorSideBar';
import { useLocation } from 'react-router';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Swal from 'sweetalert2';
import CustomDatatable from '../../../components/common/CustomDatatable';
import ElearningService from '../../../services/elearning';
import moment from 'moment-timezone';
import { timeValidation } from '../../../helpers/utils'

export default function Absensi() {
    var baseUrl = window.location.origin;

    document.title = "INGENIO Indonesia";
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    // Filtering data
    const [getColumns, setColumns] = useState([])
    const [isLoading, setIsLoading] = useState(true);
    const [hasNext, setHasNext] = useState(false)
    const [hasPrev, setHasPrev] = useState(false)
    const [count, setCount] = useState(0);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [keyword, setKeyword] = useState('');
    const [kelasData, setKelasData] = useState([]);
    const [pesertaAbsen, setPesertaAbsen] = useState([]);


    // for Input absensi
    const [hari, setHari] = useState('');
    const [tanggal, setTanggal] = useState(moment().format('yyyy-MM-DD'));
    const [jamMulai, setJamMulai] = useState('00:00');
    const [jamAkhir, setJamAkhir] = useState('00:00');
    const [pokokBahasan, setPokokBahasan] = useState('');
    const [pertemuanKe, setPertemuanKe] = useState(1);

    const history = useHistory();
    const location = useLocation();
    const { kelasid } = useParams();
    const { nama_kelas, nama_regio, elearning_program } = location.state

    const dropdown = {
        borderRadius: "10px",
        height: 50,
        padding: "25px",
    };

    const columns = [
        {
            name: 'NO.',
            field: 'no',
            index: true,
            selector: row => row.no,
            sortable: true,
            width: '6.2vw',
        },
        {
            name: 'Kode Peserta',
            render: true,
            component: (row) => {
              return (
                <td className='main-table__bodyRow'>
                    <span>{`EL${row.id_peserta.split('-')[0].toUpperCase()}`}</span>
                </td>
              )
            },
            selector: row => row.kode,
            sortable: true,
            width: '20vw',
        },
        {
            name: 'Nama Peserta',
            field: 'nama_peserta',
            selector: row => row.nama,
            sortable: true,
            width: '30vw',
        },
        {
            name: '',
            render: true,
            component: (row) => {
              return (
                <td className='main-table__bodyRow'>
                    <Form.Select onChange={(e) => {
                        let updatePesertaAbsen = pesertaAbsen;
                        for (let bb = 0; bb < pesertaAbsen.length; bb++) {
                            const pesertaAbs = pesertaAbsen[bb];
                            if(pesertaAbs.id_peserta === row.id_peserta) {
                                updatePesertaAbsen[bb].status_absen = e.target.value;
                                break;
                            }
                        }

                        setPesertaAbsen(updatePesertaAbsen);
                    }} aria-label="Default select example">
                        <option value=''>-- Pilih kehadiran --</option>
                        <option value="H">Hadir</option>
                        <option value="S">Sakit</option>
                        <option value="I">Izin</option>
                        <option value="A">Alpa</option>
                    </Form.Select>
                </td>
              )
            },
            selector: row => row.p1,
            sortable: true,
            minWidth: '150px'
            // width: '13vw',
        },
    ];
    const data = [
        {
            no: 1,
            kode: '223344',
            nama: 'lorem ipsum.',
        },
        {
            no: 2,
            kode: '223344',
            nama: 'lorem ipsum.',
        },

    ];

    const customStyles = {
        table: {
          style: {
            borderRadius: '15px',
            border: '1px solid #BDC1C9',
          },
        },
        head: {
          style: {
            fontSize: '1.25rem',
            textTransform: "uppercase",
            color: '#6B7280',
            backgroundColor: '#f3f4f6',
            border: '1px solid #BDC1C9',
            borderTopLeftRadius: '15px',
            borderTopRightRadius: '15px',
          },
        },
        pagination: {
          style: {
            color: '#6B7280',
            fontSize: '1.1rem',
            minHeight: '56px',
            backgroundColor: '#f3f4f6',
            border: '1.25px solid #BDC1C9',
            borderBottomLeftRadius: '15px',
            borderBottomRightRadius: '15px',
          },
        },
    };

    const Succeed = (message, onConfirm) => {
        Swal.fire({  
            title: 'Berhasil!',  
            text: message,  
            icon: 'success',  
            showCancelButton: false,              
            confirmButtonText: 'OK'  
        }).then((result) => {
          if (result.isConfirmed) {
            onConfirm()
          }
        })
    }

    const submitAbsen = (e) => {
        if(pokokBahasan == '' || pokokBahasan == null) {
            Fail('Pokok Bahasan harus diisi')
            return;
        }
        if(hari == '' || hari == null) {
            Fail('Hari harus diisi')
            return;
        }
        if(tanggal == '' || tanggal == null) {
            Fail('Tanggal harus diisi')
            return;
        }
        if(jamMulai == '' || jamMulai == null) {
            Fail('Jam mulai harus diisi')
            return;
        }
        if(!timeValidation(jamMulai)) {
            Fail('Jam mulai tidak valid')
            return;
        }
        if(jamAkhir == '' || jamAkhir == null) {
            Fail('Jam akhir harus diisi')
            return;
        }
        if(!timeValidation(jamAkhir)) {
            Fail('Jam akhir tidak valid')
            return;
        }
        if(isNaN(pertemuanKe)){
            Fail('Pertemuan ke tidak valid')
            return;
        }
        if(parseInt(pertemuanKe)<1){
            Fail('Pertemuan ke tidak valid')
            return;
        }
        const tanggalDate = moment(tanggal).format('yyyy-MM-DD');
        const jamMulaiDatetime = `${moment(`${moment(tanggal).format('yyyy-MM-DD')} ${jamMulai}`).utc().format('yyyy-MM-DD HH:mm')}`
        const jamAkhirDatetime = `${moment(`${moment(tanggal).format('yyyy-MM-DD')} ${jamAkhir}`).utc().format('yyyy-MM-DD HH:mm')}`

        if(moment(jamAkhirDatetime) < moment(jamMulaiDatetime)){
            Fail('Jam mulai tidak boleh lebih dari jam akhir')
            return;
        }

        let body = {
            hari,
            jam_mulai: jamMulaiDatetime,
            jam_akhir: jamAkhirDatetime,
            tanggal: tanggalDate,
            pokok_bahasan: pokokBahasan,
            nama_materi: pokokBahasan,
            pertemuan_ke: pertemuanKe,
            absen_pesertas: pesertaAbsen
        }

        Swal.fire({  
            title: 'Sudah Yakin?',  
            text: 'Pastikan semua data yang Anda isikan sudah sesuai',  
            icon: 'warning',  
            showCancelButton: true,  
            cancelButtonText: 'Tidak',  
            confirmButtonText: 'Ya'  
          })
          .then((result) => {
            if (result.isConfirmed) {
                ElearningService.submitAbsen(kelasid, body).then(resp => {
                    Succeed('Absen berhasil di submit', () => {
                        history.push(`/dashboard/tutor/data-kelas/absensi/${kelasid}`, {nama_kelas, nama_regio, elearning_program});
                    })
                }).catch(err => {
                    Fail('Gagal update data')
                })
            }
          });

    }

    const getListKelasParticipant = (params = '') => {
        setIsLoading(true)
        ElearningService.getListKelasParticipantTutor(kelasid, params).then(resp => {
            const response = resp.data;
            
            setKelasData(response.data);
            let listPesertaAbsens = []
            for (let m = 0; m < response.data.length; m++) {
                const peserta = response.data[m];
                listPesertaAbsens.push({id_peserta: peserta.id_peserta, status_absen: 'A'})
            }

            setPesertaAbsen(listPesertaAbsens)

            setPage(response.meta.page)
            setPerPage(response.meta.perPage)
            setCount(response.meta.count)

            setHasNext(response.meta.hasNext)
            setHasPrev(response.meta.hasPrev);
            
            setIsLoading(false)
        }).catch(err => {
            setIsLoading(false)
            console.error(err)
        })
    }

    useEffect(() => {
        getListKelasParticipant();
    }, [])

    const Fail = (message) => {
        Swal.fire({  
            title: 'Gagal!',  
            text: message,  
            icon: 'warning',  
            showCancelButton: true,  
            cancelButtonText: 'Kembali',           
            showConfirmButton: false,  
        });   
    }

    return (
        <React.Fragment>
            <TutorSidebar />
            <div className="md:ml-64 tutorDataKelas" style={{ backgroundColor: '#F3F4F6' }}>
                <div className="bg-orange md:px-8 pb-5" style={{height: '24vh', position: 'relative', padding: '0 30px 30px 30px'}}>
                    <h1 className="tracking-wider title-24-600">Input Absensi Kelas</h1>
                    <h2 className="tracking-wider title-20-700 mt-5">Selamat Datang Tutor INGENIO !</h2>
                    <img src={baseUrl + "/assets/logo/evaluasikelas.png"} className="img-opacity"></img>
                </div>

                <div className="px-3 md:px-8 pb-5">
                    <div className="container mx-auto max-w-full">
                        <div className="justify-start mt-4 mb-3">
                            <Link to="/dashboard/tutor/data-kelas">
                                <div className="navBack">
                                    <BsArrowLeftShort size="19px"/>Kembali
                                </div> 
                            </Link>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <div className="cards pd-4">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="flex lh-40">
                                                <div className="w-50 fw-600">Nama Kelas</div>
                                                <div className="w-50">{nama_kelas}</div>
                                            </div>
                                            <div className="flex lh-40">
                                                <div className="w-50 fw-600">Nama Regio</div>
                                                <div className="w-50">{nama_regio}</div>
                                            </div>
                                            <div className="flex lh-40">
                                                <div className="w-50 fw-600">Nama Program</div>
                                                <div className="w-50">{elearning_program.nama_program}</div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="flex lh-40" style={{marginBottom: '18px'}}>
                                                <div className="w-50 fw-600">Hari</div>
                                                <Form.Control value={hari} className='w-50' onChange={(e) => {
                                                    setHari(e.target.value)
                                                }} type="text" name="" placeholder="" />
                                            </div>
                                            <div className="flex lh-40" style={{marginBottom: '18px'}}>
                                                <div className="w-50 fw-600">Jam Mulai</div>
                                                <Form.Control value={jamMulai} className='w-50' onChange={(e) => {
                                                    setJamMulai(e.target.value)
                                                }} maxLength={5} type="text" name="" placeholder="" />
                                            </div>
                                            <div className="flex lh-40" style={{marginBottom: '18px'}}>
                                                <div className="w-50 fw-600">Jam Akhir</div>
                                                <Form.Control value={jamAkhir} className='w-50' onChange={(e) => {
                                                    setJamAkhir(e.target.value)
                                                }} maxLength={5} type="text" name="" placeholder="" />
                                            </div>
                                            <div className="flex lh-40" style={{marginBottom: '18px'}}>
                                                <div className="w-50 fw-600">Tanggal Pertemuan</div>
                                                <Form.Control value={tanggal} className='w-50' onChange={(e) => {
                                                    const tanggalDate = moment(e.target.value).format('yyyy-MM-DD')
                                                    setTanggal(tanggalDate)
                                                }} type="date" name="" placeholder="" />
                                            </div>
                                            <div className="flex lh-40" style={{marginBottom: '18px'}}>
                                                <div className="w-50 fw-600">Pertemuan Ke</div>
                                                <Form.Control value={pertemuanKe} className='w-50' onChange={(e) => {
                                                    setPertemuanKe(e.target.value)
                                                }} maxLength={5} type="number" name="" placeholder="" />
                                            </div>
                                            <div className="flex lh-40">
                                                <div className="w-50 fw-600">Pokok Bahasan</div>
                                                <div className="w-50 mt-2">
                                                    <textarea onChange={(e) => {
                                                        setPokokBahasan(e.target.value)
                                                    }} class="formInputAbsen" name="" id="">{pokokBahasan}</textarea> 
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 mt-4">
                                {/* <DataTable columns={columns} customStyles={customStyles} data={data} pagination /> */}
                                <CustomDatatable 
                                    filteredItems={kelasData} 
                                    columns={columns}
                                    isLoading={isLoading}
                                    page={page}
                                    perPage={perPage}
                                    hasPrev={hasPrev}
                                    hasNext={hasNext}
                                    count={count}
                                    onTableChange={(e, data) => {
                                        switch (e) {
                                        case 'rowPerPageChange':
                                            setPerPage(data.perPage)
                                            getListKelasParticipant(`page=${page}&perPage=${data.perPage}&keyword=${keyword}`)
                                            break;
                                        case 'rowPageChange':
                                            setPage(data.page)
                                            getListKelasParticipant(`page=${data.page}&perPage=${perPage}&keyword=${keyword}`)
                                            break;
                                        
                                        default:
                                            break;
                                        }
                                    }}
                                />
                            </div>
                            <div className="col-12 mt-4 justify-end">
                                <button onClick={submitAbsen} type="submit" className="btnOrange3">
                                    <div className="txt-btn">Submit</div> 
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment >

    )
}