import React, { useState, useEffect } from 'react';
import { FormGroup, FormControl, Form } from 'react-bootstrap';
import { Table, Pagination, PaginationItem, PaginationLink } from 'reactstrap'
import Skeleton from 'react-loading-skeleton';
import DataTable from 'react-data-table-component';
import { Link } from 'react-router-dom';
import Button from '@restart/ui/esm/Button';
import { FaRegEdit, FaRegTrashAlt } from 'react-icons/fa';
import moment from 'moment-timezone';

function CustomDatatable(props) {
    const { 
        columns, 
        filteredItems,
        isLoading,
        page,
        perPage,
        hasNext,
        hasPrev,
        count,
        onTableChange
    } = props

   const handlePageFromTo = () => {
       let from = 1;
       let to = parseInt(perPage);
       if(count < parseInt(perPage)) {
        to = parseInt(count);
       }

       if(page > 1) {
        for (let i = 1; i < parseInt(page); i++) {
            from = parseInt(from) + parseInt(perPage);
            to = parseInt(to) + parseInt(perPage);     
        }
       }
       

       return {
           from,
           to
       }
   }

  return (
    <React.Fragment>
        <Table responsive hover className="main-table">
            <thead className='main-table__head'>
                <tr>
                {
                    columns.map((col, i) => {
                        return (<th key={i} style={{minWidth: col.minWidth}} className="align-middle main-table__headRow">{col.name}</th>)
                    })
                }
                    
                </tr>
            </thead>
            {
                
                isLoading ? 
                (
                    <tbody style={{border: 'none'}}>
                        <tr className="border-side__tbodyRow" style={{backgroundColor: 'white', borderBottom: '1px solid rgba(0,0,0,.12)', borderTop: 'none'}}>
                            <td colSpan={columns.length} className='main-table__bodyRow'>
                                {'Loading Data...'}
                            </td>
                        </tr>
                    </tbody>
                )
                :
                (
                <tbody style={{border: 'none'}}>
                    {
                        filteredItems.map((item, i) => {
                            return (<tr key={i} className="border-side__tbodyRow" style={{backgroundColor: 'white', borderBottom: '1px solid rgba(0,0,0,.12)', borderTop: 'none'}}>
                                {
                                    columns.map((col, j) => {
                                        if(col.index) {
                                            return (<td key={j} className='main-table__bodyRow'>{(parseInt(page) - parseInt(1)) * parseInt(perPage) + i + 1}</td>)
                                        }
                                        if(col.render) {
                                            return col.component(item)
                                        }
                                        return (<td key={j} className='main-table__bodyRow'>{item[col.field]}</td>)
                                    })
                                }
                                
                                {/* <td className='main-table__bodyRow'>{item.paket}</td>
                                <td className='main-table__bodyRow'>{item.kode}</td>
                                <td className='main-table__bodyRow'>{item.program}</td>
                                <td className='main-table__bodyRow'>{moment(item.tanggal_awal).format("DD MMM YYYY HH:mm")}</td>
                                <td className='main-table__bodyRow'>{moment(item.tanggal_akhir).format("DD MMM YYYY HH:mm")}</td>
                                <td className='main-table__bodyRow'>{item.jumlah + ' Soal'}</td>
                                <td className='main-table__bodyRow'>
                                    <Link
                                    className="flex content-center items-center"
                                    style={{width: '35px', borderRadius: '50%', padding: '10px', color: 'black', background: '#F8CA2B' }}
                                    to={'/dashboard/akademik/tryout/' + item.id}>
                                    <FaRegEdit />
                                    </Link>
                                </td>
                                <td className='main-table__bodyRow'>
                                    <Link
                                    className="flex content-center items-center"
                                    style={{width: '35px', borderRadius: '50%', padding: '10px', color: 'black', background: '#F8CA2B' }}
                                    to={'/dashboard/akademik/tryout/' + item.id}>
                                    <FaRegTrashAlt />
                                    </Link>
                                </td> */}
                            </tr>)
                        })
                    }
                </tbody>
                )
            }
            
        </Table>
        <div className='d-flex justify-content-end paginationTools' style={{border: 'none'}}>
            <span style={{marginLeft: '24px'}}>Rows per Page: </span>
            <select style={{marginLeft: '24px'}} onChange={(e) => {
                onTableChange('rowPerPageChange', {perPage: e.target.value})
            }}>
                <option value={'3'} selected={perPage === 3}>3</option>
                <option value={'5'} selected={perPage === 5}>5</option>
                <option value={'10'} selected={perPage === 10}>10</option>
                <option value={'20'} selected={perPage === 20}>20</option>
                <option value={'50'} selected={perPage === 50}>50</option>
                <option value={'100'} selected={perPage === 100}>100</option>
            </select>
            <span style={{marginLeft: '24px'}}>{`${handlePageFromTo().from}-${handlePageFromTo().to} of ${count}`}</span>
            <button style={{marginLeft: '24px'}} onClick={() => {
                if(page > 1) {
                    const newPage = parseInt(page) - parseInt(1);
                    onTableChange('rowPageChange', {page: newPage})
                }
            }}>
                <i class="fa fa-chevron-left"></i>
            </button>
            <button style={{marginLeft: '34px'}} onClick={() => {
                if(hasNext) {
                    const newPage = parseInt(page) + parseInt(1);
                    onTableChange('rowPageChange', {page: newPage})
                }
                
            }}>
                <i class="fa fa-chevron-right"></i>
            </button>
        </div>
    </React.Fragment>

  )
}

export default CustomDatatable