import React from 'react'

export default function IngenioNav() {
    const token = localStorage.getItem("token");
    
    const textLogo = {
        position: "relative",
        fontSize: '0.6rem',
        fontWeight: "700"
    };
    const signup = {
        position: "relative",
        fontSize: "14px",
        top: "25px",
        marginLeft: "-50%",
        fontWeight: "700",
        color: "black",
        textDecoration: 'none',
        marginBottom: '5%'
    };
    return (
        <React.Fragment>
            <header className="site-header site-header--menu-left landing-11-menu site-header--absolute site-header--sticky mobile-sticky-enable" style={{ zIndex: 4000, background: '#ffcc00' }}>
                <div className="container">
                    <nav className="navbar site-navbar">
                        <div className="brand-logo" id='logo'>
                            <a href="/" className="align-items-center">
                                <img src="/assets/logo/logo-ingenio-nav.png" alt="" className="light-version-logo mx-auto" style={{ maxWidth: '155px' }} />
                                <img src="/assets/logo/logo-ingenio-nav.png" alt="" className="dark-version-logo mx-auto" style={{ maxWidth: '120px' }} />
                            </a>
                            <span className="d-none d-lg-block" style={textLogo}>Trusted Professional Medical E-Learning
                            </span>
                        </div>
                        <div className="menu-block-wrapper">
                            <div className="menu-overlay"></div>
                            <nav className="menu-block" id="append-menu-header">
                                <div className="mobile-menu-head">
                                    <img src="/assets/logo/logo-ingenio-nav.png" style={{ width: '120px' }} className="p-3 py-5" alt="Logo Ingenio" />
                                    <div className="go-back">
                                        <i className="fa fa-angle-left"></i>
                                    </div>
                                    <div className="current-menu-title"></div>
                                    <div className="mobile-menu-close">&times;</div>
                                </div>
                                <ul className="site-menu-main">
                                    <li className="nav-item">
                                        <a href="/" className="nav-link-item">Beranda</a>
                                    </li>
                                    <li className="nav-item nav-item-has-children">
                                        <a href="#" className="nav-link-item drop-trigger">Tentang <i className="fas fa-angle-down"></i>
                                        </a>
                                        <ul className="sub-menu" id="submenu-1">
                                            <li className="sub-menu--item">
                                                <a href="/opening">Opening</a>
                                            </li>
                                            <li className="sub-menu--item">
                                                <a href="/values">Values</a>
                                            </li>
                                            <li className="sub-menu--item">
                                                <a href="/history">History</a>
                                            </li>
                                            <li className="sub-menu--item">
                                                <a href="/fasilitas">Facility</a>
                                            </li>
                                            <li className="sub-menu--item">
                                                <a href="/faq">FAQ</a>
                                            </li>
                                            <li className="sub-menu--item nav-item-has-children">
                                                <a href="#">Galeri <i className="fas fa-angle-down" style={{ color: '#1f1f1f' }}></i></a>
                                                <ul className="sub-menu" id="submenu-5">
                                                    <li className="sub-menu--item">
                                                        <a href="/testimoni_peserta">Peserta</a>
                                                    </li>
                                                    <li className="sub-menu--item">
                                                        <a href="/ingenio-peduli">Ingenio Peduli</a>
                                                    </li>
                                                    <li className="sub-menu--item">
                                                        <a href="/tutor">Tutor</a>
                                                    </li>
                                                    <li className="sub-menu--item">
                                                        <a href="/event">Event</a>
                                                    </li>
                                                </ul>
                                            </li>
                                            
                                        </ul>
                                    </li>
                                    <li className="nav-item nav-item-has-children">
                                        <a href="#" className="nav-link-item drop-trigger has-megamenu">Program <i className="fas fa-angle-down"></i>
                                        </a>
                                        <ul className="sub-menu" id="submenu-5">
                                                    <li className="sub-menu--item">
                                                        <a href="/program-ukmppd">UKMPPD</a>
                                                    </li>
                                                    <li className="sub-menu--item">
                                                        <a href="/program-ukmp2dg">UKMP2DG</a>
                                                    </li>
                                                    <li className="sub-menu--item">
                                                        <a href="/program-ukom-kebidanan">UKOM Kebidanan</a>
                                                    </li>
                                                    <li className="sub-menu--item">
                                                        <a href="/program-preklinik">Preklinik FK/FKG/Kebidanan</a>
                                                    </li>
                                                    <li className="sub-menu--item">
                                                        <a href="/program-utb">UTB Tahap I dan II</a>
                                                    </li>
                                                    <li className="sub-menu--item">
                                                        <a href="/program-bimbingan-pre-coass">Pre-Coass</a>
                                                    </li>
                                                    <li className="sub-menu--item">
                                                        <a href="/program-dokter-muda">Dokter Muda/Dokter Gigi Muda</a>
                                                    </li>
                                                    <li className="sub-menu--item">
                                                        <a href="/program-rocket-plus">Rocket Plus</a>
                                                    </li>
                                                    <li className="sub-menu--item">
                                                        <a href="https://tryout.ingenioindonesia.id/">Try Out</a>
                                                    </li>
                                        </ul>
                                    </li>
                                    <li className="nav-item">
                                        <a href="/artikel" className="nav-link-item">Artikel</a>
                                    </li>
                                    <li className="nav-item nav-item-has-children">
                                        <a href="#" className="nav-link-item drop-trigger">Karir <i className="fas fa-angle-down"></i>
                                        </a>
                                        <ul className="sub-menu" id="submenu-4">
                                            <li className="sub-menu--item">
                                                <a href="https://api.whatsapp.com/send?phone=6281358889133&text=Hallo,%20Tim%20HRD%20Ingenio%0A%0ASaya%20(nama%20dengan%20gelar)%0ADomisili%20kota%20xxx%0Amau%20tahu%20tentang%20informasi%20pendaftaran%20dan%20seleksi%20tutor%20regio%20(Kota%20Regio).%0A%0ATerima%20kasih">Pendaftaran Tutor</a>
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                        {
                            !token ?
                                <div className="header-misc">
                                    <div className="header-btns header-btn-l11 ms-auto d-xs-inline-flex align-items-center">
                                        <a className="btn log-in-btn focus-reset d-none d-lg-block" href="/signin">Log in/Sign Up</a>
                                        {/* <a className="d-none d-lg-block py-1" href="/signup" style={signup}> Sign Up</a> */}
                                    </div>
                                    <div className="header-btns header-btn-l11 ms-auto d-xs-inline-flex align-items-center">
                                        <a className="btn log-in-btn focus-reset d-lg-none" href="/signin">Log in/Sign Up</a>
                                    </div>
                                </div>
                                :
                                <div className="header-misc">
                                    <div className="header-btns header-btn-l11 ms-auto d-xs-inline-flex align-items-center">
                                        <a className="btn log-in-btn focus-reset d-none d-lg-block" href="/" onClick={() => localStorage.clear()}>Log Out</a>
                                        {/* <a className="d-none d-lg-block py-1" href="/signup" style={signup}> Sign Up</a> */}
                                    </div>
                                    <div className="header-btns header-btn-l11 ms-auto d-xs-inline-flex align-items-center">
                                        <a className="btn log-in-btn focus-reset d-lg-none" href="/" onClick={() => localStorage.clear()}>Log Out</a>
                                    </div>
                                </div>
                        }
                        <div class="mobile-menu-trigger">
                            <span></span>
                        </div>
                    </nav>
                </div>
            </header>
        </React.Fragment>
    )
}