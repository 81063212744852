import React from 'react';
import {
    Login,
} from '../components/login';
import {
    IngenioNav,
    IngenioFooter
} from '../components/layout'

export default function LoginPage() {
    document.title = "INGENIO Indonesia | Login";
    return (
        <React.Fragment>
            <IngenioNav />
            <div style={{ backgroundColor: 'white' }}>
                <Login />
            </div>
            <IngenioFooter bgColor="transparent" />
        </React.Fragment>

    )
}