import Button from '@material-tailwind/react/Button';
import React, { useState } from 'react';
import { DropdownButton, Dropdown, Form } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import { Link } from 'react-router-dom';
import { BsPencilSquare, BsArrowLeftShort, BsCloudDownload } from 'react-icons/bs';
import {
    SideBar
  } from '../../components/layout/dashboard';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Swal from 'sweetalert2';
import AuthService from '../../services/auth'

export default function Testimoni() {
    var baseUrl = window.location.origin;

    document.title = "INGENIO Indonesia";
    const [show, setShow] = useState(false);

    const [inTesti, setInTesti] = useState('');
    const [isLoading, setIsLoading] = useState(true);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const dropdown = {
        borderRadius: "10px",
        height: 50,
        padding: "25px",
    };

    const AreYouSure = () => {
        Swal.fire({  
            title: 'Sudah Yakin?',  
            text: 'Apakah Anda Yakin?',  
            icon: 'warning',  
            showCancelButton: true,  
            cancelButtonText: 'Tidak',  
            confirmButtonText: 'Ya'  
        }).then((result) => {
          if (result.isConfirmed) {
            createTesti()
          }
        });   
    }

    const Succeed = () => {
        Swal.fire({  
            title: 'Data Berhasil Tersimpan !',  
            text: '',  
            icon: 'success',  
            showCancelButton: false,              
            confirmButtonText: 'OK'  
        })
    }

    const createTesti = () => {
        setIsLoading(true)
        AuthService.createTesti({isi_testimoni: inTesti}).then(resp => {
            Succeed()
            setIsLoading(false)
        }).catch(err => {
            setIsLoading(false)
            console.error(err)
        })
    }

    return (
        <React.Fragment>
            <SideBar />
            <div className="md:ml-64 tutorDataKelas" style={{ backgroundColor: '#F3F4F6' }}>
                {/* <div className="bg-orange md:px-8 pb-5" style={{height: '24vh', position: 'relative', padding: '0 30px 30px 30px'}}>
                    <h1 className="tracking-wider title-24-600">Testimoni</h1>
                    <h2 className="tracking-wider title-20-700 mt-5">Selamat Datang Peserta INGENIO !</h2>
                    <img src={baseUrl + "/assets/logo/evaluasikelas.png"} className="img-opacity"></img>
                </div> */}

                <div className="container md:pr-8 md:pl-10"  style={{position: 'absolute', top: '80px'}}>
                    <h1 className="tracking-wider title-24-600">Testimoni</h1>
                    <h2 className="tracking-wider title-20-700 mt-5">Selamat Datang Peserta INGENIO !</h2>
                </div> 

                <div className="px-3 md:px-8 mt-5 pb-5">
                    <div className="container mx-auto max-w-full">
                        <div className="row">
                            <div className="col-md-9 ml-auto mr-auto">
                                <div className="cards pd-InJurnal">
                                    <div className="row">
                                        <div className="col-12 mb-4">
                                            <div className="title-20-700">Isi Testimoni <span>(Misal : Kualitas tutor, bahan ajar, fasilitas, dll)</span></div>
                                            <div className="borderBottomGrey"></div>
                                        </div>
                                        <div className="col-12">
                                            <div className="title-18-500">Testimoni Peserta</div>
                                            <div className="col-12 mt-2">
                                                <textarea onChange={(e) => {
                                                    setInTesti(e.target.value)
                                                }} className="formInputJurnal" name="" id="" style={{height: '150px'}}></textarea>
                                            </div>
                                        </div>
                                        <div className="col-12 mt-2 justify-end">
                                            <button onClick={AreYouSure} type="submit" className="btnOrange3">
                                                <div className="txt-btn">Submit</div> 
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment >

    )
}