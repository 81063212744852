import React from 'react';
import styles from '../../index.module.css';

export default function Kurikulum () {
  return (
    <React.Fragment>
      <section className="container-fluid py-5" id="kurikulum-title-section-container">
        <div className="row align-items-center g-2" style={{paddingTop: '6rem'}}>
          <h1 className="text-center jd-home2">Kurikulum Bimbingan</h1>
          <h1 className="text-center jd-home2"><span className={styles.textYellow}>INGENIO</span> INDONESIA</h1>
        </div>
        <svg class="wave-kurikulum" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 274" fill="none">
          <path xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd" d="M80 157.143L0 135.143V274H1440V0L1360 44C1280 91.1429 1120 179.143 960 226.286C800 270.286 640 270.286 480 248.286C336.301 228.527 192.603 188.488 106.859 164.597C97.1319 161.887 88.1507 159.384 80 157.143Z" fill="url(#paint0_linear)"/>
          <defs>
            <linearGradient id="paint0_linear" x1="720" y1="0" x2="720" y2="814" gradientUnits="userSpaceOnUse">
              <stop stop-color="#FCA61F"/>
              <stop offset="1" stop-color="#F8CA2B"/>
            </linearGradient>
          </defs>
        </svg>
      </section>
      <section class="container-fluid bg-linear" style={{paddingTop: '0px', paddingBottom: 'auto'}}>
          <div class="container vertical-middle" >
              <img src="/assets/landing/kurikulum-desktop.png" alt="roadmap-1" className="d-none d-lg-block" style={{width: '75%', alignSelf: 'center', paddingTop: '10vh', paddingBottom: '10vh'}}></img>
              <img src="/assets/landing/kurikulum-mobile.png" alt="roadmap-1" className="d-block d-lg-none" style={{paddingTop: '10vh', paddingBottom: '10vh'}}></img>
          </div>
      </section>
    </React.Fragment>
  )
}