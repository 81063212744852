import React from 'react';

export default function Partnership() {

    return (
        <React.Fragment>
            <section className="container-fluid">
                <div className="site-header site-header--menu-left landing-11-menu site-header--absolute" style={{ backgroundImage: 'url(/assets/landing/Genio2.png)', backgroundPositionY: 'bottom', backgroundPositionX: 'right', backgroundRepeat: 'no-repeat', paddingTop: '6rem', backgroundColor: '#ffcc00' }}>
                    <h5 className="text-center tentang_title py-5" style={{ paddingBottom: '2rem !important' }}>PARTNERSHIP</h5>
                </div>
            </section>
            <section className="container-fluid bg-desktop">
                <div className="container px-4" style={{ paddingTop: '10rem' }}>
                    <div className="row g-2 pdd10r" style={{ textAlign: 'justify' }}>
                        <div className="col-8 pdct">
                            <h5 className="txt24px" style={{ textDecoration: 'none', textAlign: 'left', fontWeight: 'bold', lineHeight: '40px' }}>
                                INGENIO MEMBUKA KESEMPATAN UNTUK PROGRAM PARTNERSHIP
                            </h5>
                            <h5 className="txt18px" style={{ lineHeight: '40px' }}>DENGAN BERBAGAI INSTANSI ATAU SENAT MAHASISWA
                                FAKULTAS KEDOKTERAN, FAKULTAS KEDOKTERAN GIGI, ATAU KEBIDANAN DI SELURUH INDONESIA.
                            </h5>
                        </div>
                        <div className="col-4 ptl ptb">
                            <img src="/assets/landing/partnership.png" />
                        </div>
                    </div>
                </div>
            </section>
            <section className="container-fluid" style={{ backgroundColor: '#F6F6F6' }}>
                <div className="container px-4">
                    <div className="row g-2" style={{ textAlign: 'justify', paddingTop: '2rem', paddingBottom: '2rem' }}>
                        <h5 className="txt32px" style={{ textDecoration: 'none', fontWeight: '700', textAlign: 'center' }}>
                            KEGIATAN PARTNERSHIP YANG TELAH INGENIO LAKUKAN DIANTARANYA :
                        </h5>
                    </div>
                </div>
                <div className="row prpl10r" style={{ paddingBottom: '3rem' }}>
                    <div className="col6 prpl5r zoom mb-5" style={{ textAlign: 'justify' }}>
                        <img className="lc" src="/assets/icons/icon-1.png" />
                        <h5 className="py-4" style={{ fontSize: '20px', fontWeight: 'bold', lineHeight: '25px' }}>Ingenio Goes To Campus</h5>
                        <h5 className="" style={{ fontSize: '20px', fontWeight: '500', lineHeight: '36px' }}>Ingenio Goes To Campus adalah program pengabdian atau kerjasama
                            antara Ingenio dengan Dekanat/BEM/senat mahasiswa/ HMPD atau himpunan mahasiswa lainnya untuk
                            memperkenalkan bimbingan belajar Ingenio.</h5>
                    </div>
                    <div className="col6 prpl5r zoom mb-5" style={{ textAlign: 'justify' }}>
                        <img className="lc" src="/assets/icons/icon-2.png" />
                        <h5 className="py-4" style={{ fontSize: '20px', fontWeight: 'bold', lineHeight: '25px' }}>Bakti Sosial serta Penggalangan Dana dan Penyaluran Donasi Bencana Nasional (Ingenio Peduli Ingenio Berbagi )</h5>
                        <h5 className="" style={{ fontSize: '20px', fontWeight: '500', lineHeight: '36px' }}>Ingenio Peduli rutin dilakukan sebagai wujud syukur Ingenio terhadap rizki dan karunia yang dilimpahkanNya.
                            Ingenio Peduli merupakan program Corporate Social Responsibility yang dilakukan oleh staf,
                            peserta dan seluruh donatur yang berpartisipasi di dalamnya untuk menolong dan membantu saudara-saudara kita yang
                            membutuhkan atau terkena bencana.</h5>
                    </div>
                    <div className="col6 prpl5r zoom mb-5" style={{ textAlign: 'justify' }}>
                        <img className="lc" src="/assets/icons/icon-3.png" />
                        <h5 className="py-4" style={{ fontSize: '20px', fontWeight: 'bold', lineHeight: '25px' }}>Kerjasama Event Sharing Class dengan materi yang berkaitan dengan kesehatan atau upgrade ilmu tenaga medis</h5>
                        <h5 className="" style={{ fontSize: '20px', fontWeight: '500', lineHeight: '36px' }}>Event Sharing Class adalah program pendidikan berkelanjutan bagi tenaga medis dan calon tenaga medis dengan berbagi
                            ilmu yang bermanfaat saat peserta event menjalani studi atau profesinya serta sebagai sarana untuk memperkenalkan bimbingan belajar Ingenio. </h5>
                    </div>
                    <div className="col6 prpl5r zoom mb-5" style={{ textAlign: 'justify' }}>
                        <img className="lc" src="/assets/icons/icon-3.png" />
                        <h5 className="py-4" style={{ fontSize: '20px', fontWeight: 'bold', lineHeight: '25px' }}>Kerjasama Event Sharing Class dengan materi yang berkaitan dengan kesehatan atau upgrade ilmu tenaga medis</h5>
                        <h5 className="" style={{ fontSize: '20px', fontWeight: '500', lineHeight: '36px' }}>Event Sharing Class adalah program pendidikan berkelanjutan bagi tenaga medis dan calon tenaga medis dengan berbagi
                            ilmu yang bermanfaat saat peserta event menjalani studi atau profesinya serta sebagai sarana untuk memperkenalkan bimbingan belajar Ingenio. </h5>
                    </div>
                </div>
            </section>
            <section className="container-fluid" style={{ backgroundColor: '#000000', paddingBottom: '3rem' }}>
                <div className="container px-4">
                    <div className="row g-2 pdd10r">
                        <div className="col-8 pt3r">
                            <h5 className="t32p" style={{ textDecoration: 'none', color: '#FFFFFF', textAlign: 'left', paddingBottom: '2rem', fontWeight: 'bold' }}>
                                Tertarik?
                            </h5>
                            <h5 className="t32p" style={{ textDecoration: 'none', color: '#FFFFFF', textAlign: 'left', paddingBottom: '3rem', fontWeight: 'bold' }}>
                                Jika Anda memiliki program yang akan <br></br> diajukan untuk Partnership
                            </h5>
                            <h5 className="t24p" style={{ color: '#FCA826', paddingBottom: '0.5rem', fontWeight: '500' }}>Dapat mengajukan proposal atau menghubungi
                            </h5>
                            <h5 className="t24p" style={{ color: '#FFFFFF', paddingBottom: '0.5rem', fontWeight: '500' }}>dr. Ardella, (WA 08156138879)<a href="#" style={{ color: '#FCA826' }}> Klik disini.</a>
                            </h5>
                            <div className="row">
                                <div className="col-4">
                                    <h5 className="t24p" style={{ color: '#FFFFFF' }}>Email :
                                    </h5>
                                </div>
                                <div className="col-8">
                                    <div className="row pdm">
                                        <div className="col-3 pdeclip">
                                            <img src="/assets/icons/eclips.png" />
                                        </div>
                                        <div className="col-9 pdemail">
                                            <a className="t24p" href="" style={{ color: '#FFFFFF', fontWeight: '500' }}>ardellanatomy@gmail.com</a>
                                        </div>
                                    </div>
                                    <div className="row pdm">
                                        <div className="col-3 pdeclip">
                                            <img src="/assets/icons/eclips.png" />
                                        </div>
                                        <div className="col-9 pdemail">
                                            <a className="t24p" href="" style={{ color: '#FFFFFF', fontWeight: '500' }}>direkturingeniomalang@gmail.com</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-4 pt5r">
                            <img src="/assets/landing/partnership-go.png" />
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment >
    )
}

