import React from 'react';
import {
    Hubungikami,
} from '../components/hubungikami';
import {
    IngenioNav2,
    IngenioFooter2
} from '../components/layout'

export default function HubungikamiPage() {
    return (
        <React.Fragment>
            <IngenioNav2 />
            <div style={{ backgroundColor: 'white' }}>
                <Hubungikami />
            </div>
            <IngenioFooter2 />
        </React.Fragment>

    )
}