import { useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { getProfileStudent, verify } from "../../store/actions/auth";
import { LOGIN_SUCCESS } from "../../store/actions/types";

function Authorization (WrappedComponent, allowedRoles, profileCompleted) {
  function AuthHOC (props) {
    const { dispatch, isLoggedIn, isProfileComplete, role } = props;

    useEffect(() => {
      // dispatch(getProfileStudent())
      // .then((data) => {
      // })
      // dispatch({
      //   type: LOGIN_SUCCESS,
      //   payload: true,
      // });
      // alert(JSON.stringify(props))
    }, [])
    
    if (isLoggedIn && allowedRoles === role && profileCompleted === isProfileComplete) {
      return (
        <WrappedComponent {...props} />
      )
    } else {
      return (
        <Redirect to='/403'/>
      )
    }
  }

  return connect(mapStateToProps)(AuthHOC);
}

function mapStateToProps(state) {
  const { isLoggedIn, isProfileComplete, role } = state.auth;
  return {
      isLoggedIn,
      isProfileComplete,
      role
  };
}

export default Authorization;



// export default 