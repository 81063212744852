import React, { useState, useEffect } from 'react';
import {
  SideBar
} from '../../../components/layout/dashboard';
import { FormGroup, FormControl, Form } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import { Link } from 'react-router-dom';
import { connect } from "react-redux";
import { getAllProgram, getAllTryoutStudent } from '../../../store/actions/tryout';
import { addCommas } from '../../../helpers/utils';
import moment from 'moment-timezone';

function TryOut(props) {
  document.title = "INGENIO Indonesia";
  document.body.style.backgroundColor = "#F3F4F6";
  const [programs, setPrograms] = useState([]);
  const [dataTryout, setDataTryout] = useState([]);
  const dropdown = {
    borderRadius: "10px",
    height: 50,
    padding: "25px",
  };

  // FILTERING DATA
  const [selectedProgram, setSelectedProgram] = React.useState('')

  const filteredItems = dataTryout.filter(
		item => (!selectedProgram || item.program.includes(selectedProgram)),
	);
  // END FILTERING DATA

  const { message, dispatch } = props;

  const customStyles = {
    table: {
      style: {
        borderRadius: '15px',
        border: '1px solid #BDC1C9',
      },
    },
    head: {
      style: {
        fontSize: '1.25rem',
        textTransform: "uppercase",
        color: '#6B7280',
        backgroundColor: '#f3f4f6',
        border: '1px solid #BDC1C9',
        borderTopLeftRadius: '15px',
        borderTopRightRadius: '15px',
      },
    },
    pagination: {
      style: {
        color: '#6B7280',
        fontSize: '1.1rem',
        minHeight: '56px',
        backgroundColor: '#f3f4f6',
        border: '1.25px solid #BDC1C9',
        borderBottomLeftRadius: '15px',
        borderBottomRightRadius: '15px',
      },
    },
  };

  useEffect(() => {
    dispatch(getAllTryoutStudent())
      .then((response) => {
        let obj = { ...response.data };
        let tryoutArr = [];
        let count = 1;
        for (const key in obj) {
          let jumlahSoal = 0;
          if (obj[key].subtryout.length > 0) {
            for (let i = 0; i < obj[key].subtryout.length; i++) {
              jumlahSoal += obj[key].subtryout[i].jumlah_soal;
            }
          }
          let payload = {
            no: count,
            id: obj[key].id,
            paket: obj[key].nama_tryout,
            kode: obj[key].kode_tryout,
            harga: obj[key].harga,
            program: obj[key].program.nama,
            jumlah: jumlahSoal
          };
          tryoutArr.push(payload)
          count++
        }
        setDataTryout(tryoutArr);
      });
  }, []);

  useEffect(() => {
    dispatch(getAllProgram())
      .then((response) => {
        let obj = { ...response.data };
        let count = 1;
        let programArr = [];
        for (const key in obj) {
          let payload = {
            key: count,
            id: obj[key].id,
            nama: obj[key].nama,
          };
          programArr.push(payload)
          count++
        }
        setPrograms(programArr);
      });
  }, []);

  const columns = [
    {
      name: 'No.',
      selector: row => row.no,
      width: '6rem',
      wrap: 'yes',
      sortable: true,
    },
    {
      name: 'Nama Paket TryOut',
      selector: row => row.paket,
      wrap: 'yes',
      sortable: true,
      width: '16rem',
    },
    {
      name: 'Program',
      selector: row => row.program,
      wrap: 'yes',
      sortable: true,
      width: '12rem',
    },
    {
      name: 'Tanggal Mulai',
      selector: row => moment(row.tanggal_awal).tz("Asia/Jakarta").format("DD MMMM YYYY HH:mm"),
      sortable: true,
      width: '15rem',
    },
    {
      name: 'Harga',
      selector: row => 'Rp. ' + addCommas(row.harga),
      wrap: 'yes',
      sortable: true,
      width: '12rem',
    },
    {
      cell: (row) => <Link className="pilih-tryout p-2" to={"/dashboard/tryout-detail/" + row.id}>Pilih TryOut</Link>,
      ignoreRowClick: true,
      width: '10rem',
      allowOverflow: true,
      button: true,
    },
  ];

  return (
    <React.Fragment>
      <SideBar judul={'TryOut Tersedia'} />
      <div className="md:ml-64">
        {/* <div className="bg-orange px-3 md:px-8 py-1" style={{ height: '2.5rem' }}>
          <div className="container md:pr-8 md:pl-10">
          </div>
        </div> */}

        <div className="px-3 md:px-8">
          <div className="container mx-auto max-w-full">
            <p className="sub-judul" style={{ padding: '3rem 0' }}>Pilih Kelas TryOut yang Tersedia</p> <br />
            <div className="flex-2 flex-col">
              <div className="flex-2 mb-4">
                <FormGroup>
                  <div className="flex w-9/12 flex-row">
                    <Form.Select
                      as="select"
                      aria-label="Program"
                      aria-describedby="basic-addon1"
                      style={dropdown}
                      onChange={(e) => setSelectedProgram(e.target.value)}
                    >
                      <option value="">Semua Program</option>
                      {programs.map(item =>
                        <option key={item.key} value={item.nama}>{item.nama}</option>
                      )};
                    </Form.Select>
                  </div>
                </FormGroup>
              </div>
              <div className="flex-2 mb-4">
                <DataTable columns={columns} data={filteredItems} customStyles={customStyles} pagination />
              </div>
            </div>
          </div>
        </div>

      </div>
    </React.Fragment>

  )
}

function mapStateToProps(state) {
  const { message } = state.message;
  return {
    message
  };
}

export default connect(mapStateToProps)(TryOut);