import React, { useEffect, useState, useRef } from 'react';
import {useReactToPrint} from "react-to-print";
import Button from '@restart/ui/esm/Button';
import { FormGroup, FormControl, Form } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import AkademikSidebar from '../../../../components/layout/dashboard/akademik/AkademikSideBar';
import ElearningService from '../../../../services/elearning';
import moment from 'moment-timezone';
import Swal from 'sweetalert2';

const ComponentToPrint = React.forwardRef((props, ref) => {
    const {jurnal, handlePrint} = props;
    return(
        <div ref={ref} className="cards" style={{paddingRight: '.3rem'}}>
            <div className="row">
                <div className="col-12 flex gap-4" style={{padding: '2.4rem 4.3rem 0 4.3rem'}}>
                    <img src={window.location.origin + "/assets/img/jurnal-1.png"} className="imgDetail" />
                    <div className="title-24-700 mt-2">Detail Jurnal Mengajar</div>
                    <Button className="pilih-tryout text-black mb-4" onClick={handlePrint}>
                        <i class="fa fa-arrow-circle-down" aria-hidden="true"></i>
                        {' Unduh Jurnal Mengajar'}
                    </Button>
                </div>
                <div className="col-12 mb-4" style={{padding: '0 4.3rem'}}>
                    <div className="borderBottomGrey"></div>
                </div>
                <div className="col-12">
                    <div className="cardsScroll pd-detail scrollY" id="scrollBar">
                        <div className="detailText">
                            <div className="titleDetail">Nama Program</div>
                            {
                                jurnal.kelas ? 
                                <div className="descDetail">{jurnal.kelas.elearning_program.nama_program}</div>
                                :
                                <div className="descDetail">{'-'}</div>
                            }
                            
                        </div>
                        <div className="detailText">
                            <div className="titleDetail">Nama Regio</div>
                            {
                                jurnal.kelas ? 
                                <div className="descDetail">{jurnal.kelas.nama_regio}</div>
                                :
                                <div className="descDetail">{'-'}</div>
                            }
                        </div>
                        <div className="detailText">
                            <div className="titleDetail">Topik yang diajar</div>
                            <div className="descDetail">{jurnal.topik_pembelajaran}</div>
                        </div>
                        <div className="detailText">
                            <div className="titleDetail">Tanggal Mengajar</div>
                            <div className="descDetail">{moment(jurnal.tanggal_mengajar).format('DD/MM/YYYY')}</div>
                        </div>
                        <div className="detailText">
                            <div className="titleDetail">Jam Mulai</div>
                            <div className="descDetail">{moment(jurnal.jam_mulai_mengajar).format('HH:mm')}</div>
                        </div>
                        <div className="detailText">
                            <div className="titleDetail">Jam Berakhir</div>
                            <div className="descDetail">{moment(jurnal.jam_berakhir_mengajar).format('HH:mm')}</div>
                        </div>
                        <div className="detailText">
                            <div className="titleDetail">Jumlah Siswa</div>
                            <div className="descDetail">{jurnal.jumlah_siswa + ' Siswa'}</div>
                        </div>
                        <div className="detailText">
                            <div className="titleDetail">Jumlah First Taker</div>
                            <div className="descDetail">{jurnal.jumlah_first_taker}</div>
                        </div>
                        <div className="detailText">
                            <div className="titleDetail">Jumlah Retaker</div>
                            <div className="descDetail">{jurnal.jumlah_retaker}</div>
                        </div>
                        <div className="detailText">
                            <div className="titleDetail">Komitmen on-time Peserta</div>
                            {
                                jurnal.ontime_commit_student !== null ?
                                    jurnal.ontime_commit_student === 'Ya' ?
                                    <div className="descDetail">YA / <span className="txt-through">TIDAK</span></div>
                                    :
                                    <div className="descDetail"><span className="txt-through">YA</span> / TIDAK </div>
                                :
                                'N/A'
                            }
                            
                        </div>
                        <div className="detailText">
                            <div className="titleDetail">Afek Ketertarikan Siswa Terhadap Materi</div>
                            <div className="descDetail">
                                <div className="flex">
                                    <div className="desc1">Sangat Tertarik</div>
                                    <div className="desc2">{`: ${jurnal.sangat_tertarik}%`}</div>
                                </div>
                                <div className="flex">
                                    <div className="desc1">Cukup Tertarik</div>
                                    <div className="desc2">{`: ${jurnal.cukup_tertarik}%`}</div>
                                </div>
                                <div className="flex">
                                    <div className="desc1">Tidak Tertarik</div>
                                    <div className="desc2">{`: ${jurnal.tidak_tertarik}%`}</div>
                                </div>
                            </div>
                        </div>
                        <div className="detailText">
                            <div className="titleDetail">Keaktifan Siswa Memberi Feedback</div>
                            <div className="descDetail">
                                <div className="flex">
                                    <div className="desc1">Hiperaktif</div>
                                    <div className="desc2">{`: ${jurnal.hiperaktif}%`}</div>
                                </div>
                                <div className="flex">
                                    <div className="desc1">Cukup Aktif</div>
                                    <div className="desc2">{`: ${jurnal.cukup_aktif}%`}</div>
                                </div>
                                <div className="flex">
                                    <div className="desc1">Pasif</div>
                                    <div className="desc2">{`: ${jurnal.pasif}%`}</div>
                                </div>
                            </div>
                        </div>
                        <div className="detailText">
                            <div className="titleDetail">Level Pertanyaan</div>
                            <div className="descDetail">
                                <div className="flex">
                                    <div className="desc1">Advance</div>
                                    <div className="desc2">{`: ${jurnal.advance}%`}</div>
                                </div>
                                <div className="flex">
                                    <div className="desc1">Medium</div>
                                    <div className="desc2">{`: ${jurnal.medium}%`}</div>
                                </div>
                                <div className="flex">
                                    <div className="desc1">Basic</div>
                                    <div className="desc2">{`: ${jurnal.basic}%`}</div>
                                </div>
                            </div>
                        </div>
                        <div className="detailText">
                            <div className="titleDetail">Level Keilmuan Dasar</div>
                            <div className="descDetail">
                                <div className="flex">
                                    <div className="desc1">Istimewa</div>
                                    <div className="desc2">{`: ${jurnal.level_istimewa}%`}</div>
                                </div>
                                <div className="flex">
                                    <div className="desc1">Cukup</div>
                                    <div className="desc2">{`: ${jurnal.level_cukup}%`}</div>
                                </div>
                                <div className="flex">
                                    <div className="desc1">Lemah</div>
                                    <div className="desc2">{`: ${jurnal.level_lemah}%`}</div>
                                </div>
                            </div>
                        </div>
                        <div className="detailText">
                            <div className="titleDetail">Memori Immediate Terhadap Materi Fasin</div>
                            <div className="descDetail">
                                <div className="flex">
                                    <div className="desc1">Istimewa</div>
                                    <div className="desc2">{`: ${jurnal.materi_istimewa}%`}</div>
                                </div>
                                <div className="flex">
                                    <div className="desc1">Cukup</div>
                                    <div className="desc2">{`: ${jurnal.materi_cukup}%`}</div>
                                </div>
                                <div className="flex">
                                    <div className="desc1">Lemah</div>
                                    <div className="desc2">{`: ${jurnal.materi_lemah}%`}</div>
                                </div>
                            </div>
                        </div>
                        <div className="title-20-700">Penjabaran Deskriptif</div>
                        <div className="borderOrange"></div>
                        <div className="detailText">
                            <div className="titleDetail">Apakah Anda dalam keadaan optimal saat mengajar kelas ini?</div>
                            {
                                jurnal.optimize_teaching !== null ?
                                    jurnal.optimize_teaching === 'Ya' ?
                                    <div className="descDetail">YA / <span className="txt-through">TIDAK</span></div>
                                    :
                                    <div className="descDetail"><span className="txt-through">YA</span> / TIDAK </div>
                                :
                                'N/A'
                            }
                        </div>
                        <div className="detailText">
                            <div className="titleDetail">Jika tidak, ceritakan apa yang Anda rasa membuat Anda secara personal kurang optimal dalam mengajar kelas ini.</div>
                            <div className="descDetail">{jurnal.optimize_teaching_reason}</div>
                        </div>
                        <div className="detailText">
                            <div className="titleDetail">Adakah distraktor atensi siswa di kelas ini saat anda mengajar?</div>
                            {
                                jurnal.distract_teaching !== null ?
                                    jurnal.distract_teaching === 'Ya' ?
                                    <div className="descDetail">YA / <span className="txt-through">TIDAK</span></div>
                                    :
                                    <div className="descDetail"><span className="txt-through">YA</span> / TIDAK </div>
                                :
                                'N/A'
                            }
                        </div>
                        <div className="detailText">
                            <div className="titleDetail">Jika Ya, apa distraktor komunal yang menggangu atensi siswa saat Anda mengajar?</div>
                            <div className="descDetail">{jurnal.distract_teaching_reason}</div>
                        </div>
                        <div className="detailText">
                            <div className="titleDetail">Apakah ada siswa di kelas ini yang Anda rasa istimewa?</div>
                            {
                                jurnal.special_student !== null ?
                                    jurnal.special_student === 'Ya' ?
                                    <div className="descDetail">YA / <span className="txt-through">TIDAK</span></div>
                                    :
                                    <div className="descDetail"><span className="txt-through">YA</span> / TIDAK </div>
                                :
                                'N/A'
                            }
                        </div>
                        <div className="detailText">
                            <div className="titleDetail">Jika Ya, sebutkan nama dan jelaskan keistimewaannya!</div>
                            <div className="descDetail">{jurnal.special_student_reason}</div>
                        </div>
                        <div className="detailText">
                            <div className="titleDetail">Apa yang sudah anda lakukan untuk mengapresiasi keistimewaannya?</div>
                            <div className="descDetail">{jurnal.special_student_appraisal}</div>
                        </div>
                        <div className="detailText">
                            <div className="titleDetail">Apakah ada siswa dikelas ini yang Anda rasa memiliki kebutuhan khusus?</div>
                            {
                                jurnal.special_needed_student !== null ?
                                    jurnal.special_needed_student === 'Ya' ?
                                    <div className="descDetail">YA / <span className="txt-through">TIDAK</span></div>
                                    :
                                    <div className="descDetail"><span className="txt-through">YA</span> / TIDAK </div>
                                :
                                'N/A'
                            }
                        </div>
                        <div className="detailText">
                            <div className="titleDetail">Jika Ya, sebutkan nama dan jelaskan alasan/bentuk seperti apa kebutuhan khususnya!</div>
                            <div className="descDetail">{jurnal.special_needed_student_reason}</div>
                        </div>
                        <div className="detailText">
                            <div className="titleDetail">Apa yang sudah anda lakukan untuk membantu siswa tersebut?</div>
                            <div className="descDetail">{jurnal.special_needed_student_help}</div>
                        </div>
                        <div className="detailText">
                            <div className="titleDetail">Saran untuk tutor lain untuk menghadapi siswa ini</div>
                            <div className="descDetail">{jurnal.special_needed_student_advice}</div>
                        </div>
                        <div className="detailText">
                            <div className="titleDetail">Saran untuk tim akademik untuk menghadapi siswa ini</div>
                            <div className="descDetail">{jurnal.special_needed_student_advice_for_ak}</div>
                        </div>
                        <div className="detailText">
                            <div className="titleDetail">Hal deskriptif lain yang ingin Anda sampaikan diluar pertanyaan di atas</div>
                            <div className="descDetail">{jurnal.others}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
})

export default function DetailProfileJurnal() {
    document.title = "INGENIO Indonesia";
    document.body.style.backgroundColor = "#f3f4f6";

    const [jurnal, setJurnal] = useState({});
    const [isLoading, setIsLoading] = useState(false);

    const {id} = useParams();

    // const [user, setUser] = useState(null)

    // const location = useLocation();
    // const { id_user } = location.state.user;

    // useEffect(() => {
    //     // alert(JSON.stringify(location.state.user))
    //     authService.akademikGetDetailPeserta(id_user).then(resp => {
    //         //alert(JSON.stringify(resp.data))
    //         setUser(resp.data)
    //     }).catch(err => {
    //         console.error(err)
    //     })
    // }, [])

    

    // console.log(user);
    // if(user === null)
    // {
    //     return (
    //         <React.Fragment>
    //             <AkademikSidebar judul={'Detail Peserta - '} />
    //         </React.Fragment>
    //     )
    // }
    const getListKelas = () => {
        setIsLoading(true)
        ElearningService.getDetailJurnalMengajarAkademik(id).then(resp => {
            const response = resp.data;
            setJurnal(response.data);
            
            setIsLoading(false)
        }).catch(err => {
            setIsLoading(false)
            console.error(err)
        })
    }

    const Fail = (message) => {
        Swal.fire({  
            title: 'Gagal!',  
            text: message,  
            icon: 'warning',  
            showCancelButton: true,  
            cancelButtonText: 'Kembali',           
            showConfirmButton: false,  
        });   
    }

    useEffect(() => {
        getListKelas();
    }, [])

    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    if(isLoading) {
        return (<React.Fragment>
            <AkademikSidebar />
            <div className="md:ml-64">
                <div className="bg-orange px-3 md:px-8 py-1" style={{ height: '2.5rem' }}>
                    Loading...
                </div>
            </div>
        </React.Fragment >)
    }

    return (
        <React.Fragment>
            <AkademikSidebar />
            <div className="md:ml-64">
                <div className="bg-orange px-3 md:px-8 py-1" style={{ height: '2.5rem' }}>
                </div>
                <div className="py-4 px-3 md:px-8 -mt-12">
                    <div className="container mx-auto max-w-full">
                        <div className="row">
                            <div className="col-lg-10 mx-auto">
                                {/* Handling print */}
                                <ComponentToPrint ref={componentRef} jurnal={jurnal} handlePrint={handlePrint} />
                                {/* Handling print */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment >

    )
}