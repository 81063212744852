import React from 'react';
import styles from '../../index.module.css';

export default function Pengalaman (props) {
  return (
    <React.Fragment>
      <section className="container-fluid bg-white py-4" id="pengalaman-section-container">
        <div className="row align-items-center text-center px-5 g-2 py-4">
          <h1 className="jd-home4 my-4" style={{color: '#000'}}>Pengalaman <span className={styles.textYellow}>INGENIO INDONESIA</span> Sejak 2012</h1>
        </div>
        <div className="container">
          <div className="row py-4 my-4 px-0">
            <div class="col-12 p-0">
              <img src="/assets/landing/new-big-data-1.png" alt="bigdata" style={{width: '100%', paddingRight: '0px', paddingLeft:'0px'}}></img>
            </div>
          </div>
          <div className="row py-4 my-4 px-0">
            <div class="col-12 p-0">
              <img src="/assets/landing/new-peta-persebaran-1.png" style={{width: '100%', paddingRight: '0px', paddingLeft:'0px'}} alt="Peta Persebaran"></img>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  )
}