import React, {useState, useEffect} from 'react';
import { connect } from 'react-redux';

import Card from "@material-tailwind/react/Card";
import CardHeader from "@material-tailwind/react/CardHeader";
import CardBody from "@material-tailwind/react/CardBody";
import CardFooter from "@material-tailwind/react/CardFooter";
import H5 from "@material-tailwind/react/Heading5";
import { Image } from 'react-bootstrap';
import { GrClose } from 'react-icons/gr';
import { Modal } from 'react-bootstrap';

import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { getProfileStudent } from '../../../../store/actions/auth';

// import {
//   Chart as ChartJS,
//   CategoryScale,
//   LinearScale,
//   BarElement,
//   Title,
//   Tooltip as ChartTooltip,
//   Legend,
// } from 'chart.js';
// import { Bar } from 'react-chartjs-2';
// import faker from 'faker';
import { Link, useParams } from 'react-router-dom';
import AkademikSidebar from '../../../../components/layout/dashboard/akademik/AkademikSideBar';
import { getHasilTryout, getSubtryoutDetail } from '../../../../store/actions/tryout';
import tryoutService from '../../../../services/tryout'
import { useLocation } from 'react-router';

function DetailNilai (props) {
  document.title = "INGENIO Indonesia";
  document.body.style.backgroundColor = "#F3F4F6";
  const [progressPercentage, setProgressPercentage] = useState(0)
  const [resultPerSubtryout, setResultPerSubtryout] = useState([]);
  const [dataProfile, setDataProfile] = useState({})
  const [dataSub, setDataSub] = useState({})
  const [jawaban, setJawaban] = useState([])
  const [jawabanPerBidang, setJawabanPerBidang] = useState([])
  const [jawabanPerTopik, setJawabanPerTopik] = useState([])
  const [jawabanPerKompetensi, setJawabanPerKompetensi] = useState([])
  const [labels, setLabels] = useState([]);
  const [dataPerTopik, setDataPerTopik] = useState([])
  const [detailTO, setDetailTO] = useState({})
  const [selectedSoal, setSelectedSoal] = useState({})
  const [selectedNumber, setSelectedNumber] = useState(1)
  const [showModal, setShowModal] = useState(false)

  var baseUrl = window.location.origin;
  const [user, setUser] = useState(null)
  const [rankingPeserta, setRankingPeserta] = useState(null)
  const [subtryoutResult, setsubtryoutResult] = useState(null)
  const [subtryoutId, setSubtryoutId] = useState(null)
  const [durasi, setDurasi] = useState(0)
  const [dataPesertaTryout, setDataPesertaTryout] = useState(null)

  const location = useLocation();
  const { id_user, id_tryout, id_subtryout } = location.state.user;

  const showPembahasan = (nomor, id) => {
    setSelectedNumber(nomor)
    setSelectedSoal(jawaban.find((el) => el.id === id))
    return setShowModal(true)
  }

  const handleCloseModal = () => {
    return setShowModal(false)
  }

  const {dispatch} = props;

  const { id } = useParams();

  useEffect(() => {
    tryoutService.getUserHasilTryoutBySubtryoutAkademik(id_user, id_subtryout)
      .then(resp => {
        // alert(JSON.stringify(resp.data.data))
        setsubtryoutResult(resp.data.data)

        setJawabanPerBidang(resp.data.data.nilai_per_bidang);
        setJawabanPerKompetensi(resp.data.data.nilai_per_kompetensi);
        setJawabanPerTopik(resp.data.data.nilai_per_topik);
        setLabels(resp.data.data.nilai_per_topik.map((i) => i.nama));
        let arrPerTopik = resp.data.data.nilai_per_topik.map((i) => (
          {
            label: i.nama,
            data: i.nilai,
            backgroundColor: '#6366F1'
          }
        ))
        setJawaban(resp.data.data.userhasiltryout_detail);
        setDataPerTopik(arrPerTopik);

      }).catch(err => {
        console.error(err)
      })
  }, []);

  useEffect(() => {
    tryoutService.getHasilTryoutPesertaSummaryPerSubtryoutAkademik(id_user, id_tryout)
      .then(resp => {
        let duration = 0;
        for (let i = 0; i < resp.data.data.length; i++) {
          const data = resp.data.data[i];
          if(data.subtryout.id == id) {
            setDurasi(data.subtryout.duration)
          }
        }
        setResultPerSubtryout(resp.data.data)
      }).catch(err => {
        console.error(err)
      })
  }, [])

  useEffect(() => {
    tryoutService.getRankingPesertaSubtryoutAkademik(id_user, id_subtryout).then(resp => { // Tampilkan data ranking
      // alert(JSON.stringify(resp.data.data.nilai_per_bidang))
      setRankingPeserta(resp.data.data)
      

      const subtryoutId = resp.data.data.subtryouts[1].id
      if(resp.data.data.subtryouts[1].duration !== null) {
        const durasi = (resp.data.data.subtryouts[1].duration)
        alert(durasi)
        setDurasi(durasi)
      }
      setSubtryoutId(subtryoutId);
    }).catch(err => {
      console.error(err)
    })

    


  }, [])

  useEffect(() => {
    if(subtryoutId === null) {
      return;
    }
    // tryoutService.getHasilTryoutPesertaAkademikPersubtryout(id_user, subtryoutId).then(resp => { // Tampilkan data ranking
    //   // alert(JSON.stringify(resp.data.data))
    //   // setsubtryoutResult(resp.data.data)
    // }).catch(err => {
    //   console.error(err)
    // })
  }, [subtryoutId])

  useEffect(() => {
    tryoutService.getPesertaTryout(id_user, id_tryout).then(resp => {
      setDataPesertaTryout(resp.data.data)
    }).catch(err => {
      console.error(err);
    })
  }, [])

  return(
    <React.Fragment>
      <AkademikSidebar judul={'Detail Hasil TryOut'}/>
      <div className="md:ml-64">
        <div className="bg-orange px-3 md:px-8 py-1" style={{height: '5rem'}}>
          <div className="container md:pr-8 md:pl-10">
          </div>           
        </div>

        <div className="px-3 md:px-8">
            <div className="container max-w-full mx-auto flex items-center justify-between py-5 px-1">
              <Card
                className="-mt-24"
                style={{borderRadius: '5rem !important'}}
              >
                <CardHeader className="bg-orange" style={{backgroundColor: "#F8CA2B"}} size="lg">
                  {
                    dataPesertaTryout !== null ?
                    (<H5 style={{color: 'black', fontFamily: 'Quicksand'}}>Hasil Tryout - {dataPesertaTryout.user.full_name} - {detailTO.kode_tryout}</H5>)
                    :
                    null
                  }
                    
                </CardHeader>
                <CardBody>
                {/* DETAIL RANGKING */}
                  <div className="mb-4 rounded-lg overflow-hidden flex-2 mx-auto py-4"
                    style={{
                      borderRadius: '2.5rem',
                      paddingLeft: '2rem',
                      paddingRight: "2rem"
                    }}
                  >
                    <div className="py-5 px-3 font-medium flex justify-between" style={{ fontFamily: 'Quicksand' }}>
                      <div 
                        className="flex-row w-9/12"
                        style={{marginRight: '5rem'}}
                      >
                        <div className="py-3 font-medium flex-2 flex-col justify-between" style={{ fontFamily: 'Quicksand' }}>
                          <h1
                            style={{
                              fontSize: '1.5rem',
                              lineHeight: 1,
                              fontWeight: 700
                            }}
                            className="mb-2"
                          >Nilai</h1>
                          <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip id="button-tooltip-2">{subtryoutResult !== null ? `${subtryoutResult.userhasiltryout_summary.total_nilai}%` : '0%'}</Tooltip>}
                          >
                            {({ ref, ...triggerHandler }) => (
                              <div class="progress my-3" {...triggerHandler} style={{height: '2rem', backgroundColor: "#EF4444", borderRadius: '1rem', border: "3px solid black"}}>
                                <div 
                                  class="progress-bar progress-bar-success" 
                                  role="progressbar" 
                                  aria-valuenow={subtryoutResult !== null ? subtryoutResult.userhasiltryout_summary.total_nilai : 0} 
                                  aria-valuemin="0" 
                                  aria-valuemax="100"
                                  ref={ref}
                                  style={{width:`${subtryoutResult !== null ? subtryoutResult.userhasiltryout_summary.total_nilai : 0}` + '%', background: '#4ADE80', borderRight: "1.25rem solid white"}}>
                                </div>
                              </div>
                            )}
                          </OverlayTrigger>
                          <h1
                            style={{
                              fontSize: '1.3rem',
                              lineHeight: 1,
                              fontWeight: 500,
                              textAlign: 'right'
                            }}
                            className="mb-2 text-right"
                          ><b>{subtryoutResult !== null ? Math.round((subtryoutResult.userhasiltryout_summary.total_nilai  / 100) * subtryoutResult.userhasiltryout_detail.length) : 0}</b> dari <b>{subtryoutResult !== null ? subtryoutResult.userhasiltryout_detail.length : 0}</b> soal</h1>
                        </div>
                      </div>
                      <div 
                        className="flex-col w-3/12 mx-auto"
                        style={{textAlign: 'left'}}
                      >
                        <h1
                          style={{
                            fontSize: '1.5rem',
                            lineHeight: 1,
                            fontWeight: 700
                          }}
                          className="mb-3"
                        >Ranking</h1>
                        <h1
                          style={{
                            fontSize: '3.2rem',
                            lineHeight: 1,
                            fontWeight: 700
                          }}
                          className="my-4"
                        >{rankingPeserta !== null ? `#${rankingPeserta.ranking}` : null}</h1>
                        <h1
                          style={{
                            fontSize: '1.5rem',
                            lineHeight: 1,
                            fontWeight: 500
                          }}
                          className="mb-3"
                        >dari <b>{rankingPeserta !== null ? `#${rankingPeserta.jumlah_peserta}` : null}</b> peserta</h1>
                      </div>
                    </div>
                    <div className="py-3 px-3 font-medium flex-2 flex-col justify-between" style={{ fontFamily: 'Quicksand' }}>
                      {
                        subtryoutResult && subtryoutResult !== null ?
                        <table style={{marginBottom: '15px'}}>
                          <tr>
                              <td style={{padding: '0px 10px 0px 0px', fontSize: '1.5rem', fontWeight: 700}}>Nilai Anda</td>
                              <td style={{padding: '0px 10px 0px 0px', fontSize: '1.5rem', fontWeight: 700}}>:</td>
                              <td style={{padding: '0px', fontSize: '1.5rem', fontWeight: 700}}><b>{parseFloat(subtryoutResult.total_nilai).toFixed(2)}</b></td>
                          </tr>
                          <tr>
                              <td style={{padding: '0px', fontSize: '1.5rem', fontWeight: 700}}>Passing Score</td>
                              <td style={{padding: '0px', fontSize: '1.5rem', fontWeight: 700}}>:</td>
                              <td style={{padding: '0px', fontSize: '1.5rem', fontWeight: 700}}><b>{subtryoutResult.passing_grade}</b></td>
                          </tr>
                          <tr>
                              <td style={{padding: '0px', fontSize: '1.5rem', fontWeight: 700}}>Result</td>
                              <td style={{padding: '0px', fontSize: '1.5rem', fontWeight: 700}}>:</td>
                              <td style={{padding: '0px', fontSize: '1.5rem', fontWeight: 700}}><b>{subtryoutResult.total_nilai >= subtryoutResult.passing_grade ? 'Lulus' : 'Tidak Lulus'}</b></td>
                          </tr>
                          <tr>
                              <td style={{padding: '0px 10px 0px 0px', fontSize: '1.5rem', fontWeight: 700}}>Waktu pengerjaan</td>
                              <td style={{padding: '0px 10px 0px 0px', fontSize: '1.5rem', fontWeight: 700}}>:</td>
                              <td style={{padding: '0px', fontSize: '1.5rem', fontWeight: 700}}><b>{`${durasi - Math.floor((subtryoutResult.userhasiltryout_summary.waktu_pengerjaan) / 60)} Menit`}</b></td>
                          </tr>
                      </table>
                        :
                        null
                      }
                      
                      {/* {
                        subtryoutResult !== null ?
                        (<h1
                          style={{
                            fontSize: '1.5rem',
                            lineHeight: 1,
                            fontWeight: 700
                          }}
                          className="my-4 py-2"
                        >Nilai Anda : {parseFloat(subtryoutResult.total_nilai).toFixed(2)}</h1>)
                        :
                        null
                      }
                      
                      {
                        subtryoutResult !== null ? 
                        (<h1
                          style={{
                            fontSize: '1.5rem',
                            lineHeight: 1,
                            fontWeight: 700
                          }}
                          className="my-4 py-2"
                        >Passing Score : {subtryoutResult.passing_grade}</h1>)
                        :
                        null
                      }
                      
                      {
                        subtryoutResult !== null ?
                        (<h1
                          style={{
                            fontSize: '1.5rem',
                            lineHeight: 1,
                            fontWeight: 700
                          }}
                          className="my-4 py-2"
                        >Result : {subtryoutResult.total_nilai >= subtryoutResult.passing_grade ? 'Lulus' : 'Tidak Lulus'}</h1>)
                        :
                        null
                      }
                      
                      {
                        subtryoutResult !== null ? 
                        (
                          <h1
                            style={{
                              fontSize: '1.5rem',
                              lineHeight: 1,
                              fontWeight: 700
                            }}
                            className="my-4 py-2"
                          >{`Waktu Pengerjaan : ${durasi - Math.floor((subtryoutResult.userhasiltryout_summary.waktu_pengerjaan) / 60)} Menit`}</h1>
                        )
                        :
                        null
                      } */}
                      
                    </div>
                  </div>
                {/* DETAIL BERDASARKAN SUBTO */}
                  <div className="shadow-md rounded-lg overflow-hidden flex-2 mx-auto mb-4 py-4"
                    style={{
                      backgroundColor: "#FFF",
                      border: "6px solid black",
                      borderRadius: '2.5rem',
                      paddingLeft: '10rem',
                      paddingRight: "10rem"
                    }}
                  >
                    <div className="py-5 px-5 font-medium flex justify-center" style={{ fontFamily: 'Quicksand' }}>
                      <div 
                        className="flex flex-col"
                        style={{textAlign: 'center'}}
                      >
                          <h1
                            style={{
                              fontSize: '2rem',
                              lineHeight: 1,
                              fontWeight: 700,
                              textAlign: 'center'
                            }}
                            className="mb-2 text-center"
                          >Nilai Tryout - {detailTO.nama_tryout}</h1>
                      </div>
                    </div>
                    <div className="py-3 px-5 font-medium flex-2 flex-row-reverse justify-between" style={{ fontFamily: 'Quicksand' }}>
                      <div 
                        className="container flex flex-row"
                      >
                        {
                          resultPerSubtryout.map((item, i) => {
                            return (
                              <div
                                className="flex flex-col flex-1 mx-auto justify-center"
                              >
                                <OverlayTrigger
                                  placement="top"
                                  overlay={<Tooltip id="button-tooltip-2">{item.total_nilai + '%'}</Tooltip>}
                                >
                                  {({ ref, ...triggerHandler }) => (
                                    <div 
                                      className="progress vertical my-3 mx-auto" 
                                      {...triggerHandler}
                                      style={{
                                        display: 'inline-block',
                                        position: 'relative',
                                        width: '20px',
                                        height: '25rem',
                                        backgroundColor: "#F3F4F6", 
                                        borderRadius: '1rem',
                                        boxShadow: "0px 5px 6px rgba(0, 0, 0, 0.25)",
                                        transform: 'rotate(180deg)'
                                      }}>
                                      <div 
                                        className="progress-bar progress-bar-success" 
                                        role="progressbar" 
                                        aria-valuenow={item.total_nilai} 
                                        aria-valuemin="0" 
                                        aria-valuemax="100"
                                        ref={ref}
                                        style={{
                                          height:item.total_nilai + '%', 
                                          borderRadius: '1rem',
                                          background: '#F8CA2B', 
                                          boxShadow: "2px 0px 6px rgba(0, 0, 0, 0.25)",
                                        }}>
                                      </div>
                                    </div>
                                  )}
                                </OverlayTrigger>
                                <h1
                                  style={{
                                    fontSize: '1.25rem',
                                    lineHeight: 1,
                                    fontWeight: 500,
                                    textAlign: 'center'
                                  }}
                                  className="mb-2 text-center"
                                >{item.subtryout.nama_subtryout}</h1>
                              </div>
                            )
                          })
                        }
                        {/* <div
                          className="flex flex-col flex-1 mx-auto justify-center"
                        >
                          <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip id="button-tooltip-2">{progressPercentage + '%'}</Tooltip>}
                          >
                            {({ ref, ...triggerHandler }) => (
                              <div 
                                className="progress vertical my-3 mx-auto" 
                                {...triggerHandler}
                                style={{
                                  display: 'inline-block',
                                  position: 'relative',
                                  width: '20px',
                                  height: '25rem',
                                  backgroundColor: "#F3F4F6", 
                                  borderRadius: '1rem',
                                  boxShadow: "0px 5px 6px rgba(0, 0, 0, 0.25)",
                                  transform: 'rotate(180deg)'
                                }}>
                                <div 
                                  className="progress-bar progress-bar-success" 
                                  role="progressbar" 
                                  aria-valuenow={progressPercentage} 
                                  aria-valuemin="0" 
                                  aria-valuemax="100"
                                  ref={ref}
                                  style={{
                                    height:progressPercentage + '%', 
                                    borderRadius: '1rem',
                                    background: '#F8CA2B', 
                                    boxShadow: "2px 0px 6px rgba(0, 0, 0, 0.25)",
                                  }}>
                                </div>
                              </div>
                            )}
                          </OverlayTrigger>
                          <h1
                            style={{
                              fontSize: '1.25rem',
                              lineHeight: 1,
                              fontWeight: 500,
                              textAlign: 'center'
                            }}
                            className="mb-2 text-center"
                          >Tryout B</h1>
                        </div> */}

                        {/* Data 2 */}
                        {/* <div
                          className="flex flex-col flex-1 mx-auto justify-center"
                        >
                          <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip id="button-tooltip-2">{progressPercentage + '%'}</Tooltip>}
                          >
                            {({ ref, ...triggerHandler }) => (
                              <div 
                                className="progress vertical my-3 mx-auto" 
                                {...triggerHandler}
                                style={{
                                  display: 'inline-block',
                                  position: 'relative',
                                  width: '20px',
                                  height: '25rem',
                                  backgroundColor: "#F3F4F6", 
                                  borderRadius: '1rem',
                                  boxShadow: "0px 5px 6px rgba(0, 0, 0, 0.25)",
                                  transform: 'rotate(180deg)'
                                }}>
                                <div 
                                  className="progress-bar progress-bar-success" 
                                  role="progressbar" 
                                  aria-valuenow={progressPercentage} 
                                  aria-valuemin="0" 
                                  aria-valuemax="100"
                                  ref={ref}
                                  style={{
                                    height:progressPercentage + '%', 
                                    background: '#F8CA2B', 
                                    borderRadius: '1rem',
                                    boxShadow: "2px 0px 6px rgba(0, 0, 0, 0.25)",
                                  }}>
                                </div>
                              </div>
                            )}
                          </OverlayTrigger>
                          <h1
                            style={{
                              fontSize: '1.25rem',
                              lineHeight: 1,
                              fontWeight: 500,
                              textAlign: 'center'
                            }}
                            className="mb-2 text-center"
                          >Tryout B</h1>
                        </div> */}
                        {/* END Data 2 */}

                      </div>
                    </div>
                  </div>
                {/* DETAIL BERDASARKAN KOMPETENSI */}
                  <div className="shadow-md rounded-lg overflow-hidden flex-2 mx-auto mb-4 py-4"
                    style={{
                      backgroundColor: "#FFF",
                      border: "6px solid black",
                      borderRadius: '2.5rem',
                      paddingLeft: '10rem',
                      paddingRight: "10rem"
                    }}
                  >
                    <div className="py-5 px-5 font-medium flex justify-center" style={{ fontFamily: 'Quicksand' }}>
                      <div 
                        className="flex flex-col"
                        style={{textAlign: 'center'}}
                      >
                          <h1
                            style={{
                              fontSize: '2rem',
                              lineHeight: 1,
                              fontWeight: 700,
                              textAlign: 'center'
                            }}
                            className="mb-2 text-center"
                          >Jawaban benar sesuai standar kompetensi</h1>
                      </div>
                    </div>
                    {jawabanPerKompetensi.map((i) => 
                      <div className="py-3 px-5 font-medium flex-2 flex-col justify-between" style={{ fontFamily: 'Quicksand' }}>
                        <div className="flex-2 flex-col">
                          <h1
                            style={{
                              fontSize: '1.5rem',
                              lineHeight: 1,
                              fontWeight: 500
                            }}
                            className="mb-2"
                          >Poin Standar Kompetensi <b>{`${i.nama} (${i.total_benar ? i.total_benar : '0'} / ${i.total})`}</b></h1>
                          <OverlayTrigger
                              placement="top"
                              overlay={<Tooltip id="button-tooltip-2">{parseFloat(i.nilai).toFixed(2) + '%'}</Tooltip>}
                            >
                            {({ ref, ...triggerHandler }) => (
                              <div 
                                className="progress my-3" 
                                {...triggerHandler} 
                                style={{height: '1rem', backgroundColor: "#f3f4f6", borderRadius: '1rem', boxShadow: "0px 5px 6px rgba(0, 0, 0, 0.25)", }}
                              >
                                <div 
                                  class="progress-bar progress-bar-success" 
                                  role="progressbar" 
                                  aria-valuenow={i.nilai} 
                                  aria-valuemin="0" 
                                  aria-valuemax="100"
                                  ref={ref}
                                  style={{
                                    width:i.nilai + '%', 
                                    background: '#F8CA2B',
                                    borderRadius: '1rem',
                                  }}>
                                </div>
                              </div>
                            )}
                          </OverlayTrigger>
                        </div>
                      </div>
                    )}
                  </div>
                {/* DETAIL BERDASARKAN Bidang */}
                  <div className="shadow-md rounded-lg overflow-hidden flex-2 mx-auto mb-4 py-4"
                    style={{
                      backgroundColor: "#FFF",
                      border: "6px solid black",
                      borderRadius: '2.5rem',
                      paddingLeft: '10rem',
                      paddingRight: "10rem"
                    }}
                  >
                    <div className="py-5 px-5 font-medium flex justify-center" style={{ fontFamily: 'Quicksand' }}>
                      <div 
                        className="flex flex-col"
                        style={{textAlign: 'center'}}
                      >
                          <h1
                            style={{
                              fontSize: '2rem',
                              lineHeight: 1,
                              fontWeight: 700,
                              textAlign: 'center'
                            }}
                            className="mb-2 text-center"
                          >Jawaban benar sesuai standar bidang</h1>
                      </div>
                    </div>
                    {jawabanPerBidang.map((i) => 
                      <div className="py-3 px-5 font-medium flex-2 flex-col justify-between" style={{ fontFamily: 'Quicksand' }}>
                        <div className="flex-2 flex-col">
                          <h1
                            style={{
                              fontSize: '1.5rem',
                              lineHeight: 1,
                              fontWeight: 500
                            }}
                            className="mb-2"
                          >{`${i.nama} (${i.total_benar ? i.total_benar : '0'} / ${i.total})`}</h1>
                          <OverlayTrigger
                              placement="top"
                              overlay={<Tooltip id="button-tooltip-2">{parseFloat(i.nilai).toFixed(2) + '%'}</Tooltip>}
                            >
                            {({ ref, ...triggerHandler }) => (
                              <div 
                                className="progress my-3" 
                                {...triggerHandler} 
                                style={{height: '1rem', backgroundColor: "#f3f4f6", borderRadius: '1rem', boxShadow: "0px 5px 6px rgba(0, 0, 0, 0.25)", }}
                              >
                                <div 
                                  class="progress-bar progress-bar-success" 
                                  role="progressbar" 
                                  aria-valuenow={i.nilai} 
                                  aria-valuemin="0" 
                                  aria-valuemax="100"
                                  ref={ref}
                                  style={{
                                    width:i.nilai + '%', 
                                    background: '#F8CA2B',
                                    borderRadius: '1rem',
                                  }}>
                                </div>
                              </div>
                            )}
                          </OverlayTrigger>
                        </div>
                      </div>
                    )}
                  </div>
                {/* DETAIL BERDASARKAN TOPIK */}
                  <div className="shadow-md rounded-lg overflow-hidden flex-2 mx-auto mb-4 py-4"
                    style={{
                      backgroundColor: "#FFF",
                      border: "6px solid black",
                      borderRadius: '2.5rem',
                      paddingLeft: '10rem',
                      paddingRight: "10rem"
                    }}
                  > 
                    <div className="py-5 px-5 font-medium flex justify-center" style={{ fontFamily: 'Quicksand' }}>
                      <div 
                        className="flex flex-col"
                        style={{textAlign: 'center'}}
                      >
                          <h1
                            style={{
                              fontSize: '2rem',
                              lineHeight: 1,
                              fontWeight: 700,
                              textAlign: 'center'
                            }}
                            className="mb-2 text-center"
                          >Jawaban benar sesuai standar topik</h1>
                      </div>
                    </div>
                    <div className="py-4">
                      {/* <Bar options={options} data={data} style={{fontFamily: 'Quicksand'}}/> */}
                      {jawabanPerTopik.map((i) => 
                        <div className="py-3 px-5 font-medium flex-2 flex-col justify-between" style={{ fontFamily: 'Quicksand' }}>
                          <div className="flex-2 flex-col">
                            <h1
                              style={{
                                fontSize: '1.5rem',
                                lineHeight: 1,
                                fontWeight: 500
                              }}
                              className="mb-2"
                            >{`${i.nama} (${i.total_benar ? i.total_benar : '0'} / ${i.total})`}</h1>
                            <OverlayTrigger
                                placement="top"
                                overlay={<Tooltip id="button-tooltip-2">{parseFloat(i.nilai).toFixed(2) + '%'}</Tooltip>}
                              >
                              {({ ref, ...triggerHandler }) => (
                                <div 
                                  className="progress my-3" 
                                  {...triggerHandler} 
                                  style={{height: '1rem', backgroundColor: "#f3f4f6", borderRadius: '1rem', boxShadow: "0px 5px 6px rgba(0, 0, 0, 0.25)", }}
                                >
                                  <div 
                                    class="progress-bar progress-bar-success" 
                                    role="progressbar" 
                                    aria-valuenow={i.nilai} 
                                    aria-valuemin="0" 
                                    aria-valuemax="100"
                                    ref={ref}
                                    style={{
                                      width:i.nilai + '%', 
                                      background: '#F8CA2B',
                                      borderRadius: '1rem',
                                    }}>
                                  </div>
                                </div>
                              )}
                            </OverlayTrigger>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                {/* REVIEW SOAL */}
                  {/* <div className="shadow-md rounded-lg overflow-hidden flex-2 mx-auto mb-4 py-4"
                      style={{
                        backgroundColor: "#F3F4F6",
                        borderRadius: '2.5rem',
                        paddingLeft: '5rem',
                        paddingRight: "5rem"
                      }}
                  >
                    <div className="py-5 font-medium flex flex-col justify-between" style={{ fontFamily: 'Quicksand' }}>
                      <div className="flex flex-row mr-3">
                        <div 
                          className="flex flex-col mx-4 justify-center"
                          style={{textAlign: 'left'}}
                        >
                          <h1
                            style={{
                              fontSize: '1.75rem',
                              lineHeight: 1,
                              fontWeight: 700
                            }}
                            className="mb-3"
                          >Review Soal</h1>
                          <h1
                            style={{
                              fontSize: '1rem',
                              lineHeight: 1,
                              fontWeight: 500
                            }}
                            className="mb-3"
                          >Klik untuk melihat soal, jawaban dan pembahasan</h1>
                        </div>
                      </div>
                      <div className="container">
                        <div
                            className="grid grid-cols-10"
                            style={{gridTemplateColumns: "repeat(10, minmax(0, 1fr))"}}
                        >
                            {jawaban.map((i, idx) => (
                                i.is_answer_true ? 
                                <button 
                                    className='mx-auto'
                                    style={{
                                        backgroundColor: '#4ADE80',
                                        fontFamily: 'Quicksand',
                                        fontStyle: 'normal',
                                        fontWeight: 500,
                                        fontSize: '2rem',
                                        width: '4rem',
                                        height: '4rem',
                                        lineHeight: '2rem',
                                        letterSpacing: '0.06em',
                                        color: 'black',
                                        borderRadius: '8px',
                                        padding: '10px',
                                        marginTop: '0.7rem',
                                    }} 
                                    key={idx} 
                                    onClick={() => showPembahasan(idx + 1, i.id)}
                                >
                                  {idx + 1}
                                </button> : 
                                <button 
                                    className='mx-auto'
                                    style={{
                                        backgroundColor: '#F87171',
                                        fontFamily: 'Quicksand',
                                        fontStyle: 'normal',
                                        fontWeight: 500,
                                        fontSize: '2rem',
                                        width: '4rem',
                                        height: '4rem',
                                        lineHeight: '2rem',
                                        letterSpacing: '0.06em',
                                        color: 'black',
                                        borderRadius: '8px',
                                        padding: '10px',
                                        marginTop: '0.7rem',
                                    }} 
                                    key={idx} 
                                    onClick={() => showPembahasan(idx + 1, i.id)}
                                >
                                  {idx + 1}
                                </button>
                            ))}
                        </div>
                      </div>
                    </div>
                  </div> */}
                </CardBody>
              </Card>
            </div>
        </div>
        <Modal
            show={showModal}
            onHide={handleCloseModal}
            size="xl"
            centered
        >
            <Modal.Header
                closeButton
                style={{
                  boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.25)'
                }}
            >
                <Modal.Title
                    style={{ fontFamily: 'Quicksand' }}
                >
                    Soal No. {selectedNumber}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="container mx-auto max-w-full">
                  <div className="block px-5 py-2">
                      <div
                          className="flex w-full mx-auto xl:mx-0 mb-2 p-4"
                          style={{ fontFamily: 'Quicksand', borderRadius: '10px', backgroundColor: '#f3f4f6', fontSize: '1.25rem'}}
                          dangerouslySetInnerHTML={{ __html: selectedSoal.soal }}
                      >
                      </div>
                      <hr className="my-4" />
                      <div className="flex flex-col w-full mx-auto xl:mx-0 mb-2">
                          {
                            selectedSoal.is_answer_true ? 
                            <div className="flex flex-row p-4" style={{borderRadius: '10px', backgroundColor: '#f3f4f6',}}>
                              <div className="flex flex-col mx-auto justify-center">
                                <h1 style={{color: '#4ADE80', textAlign: 'center'}}>Jawaban Anda:</h1>
                                <button 
                                    className='mx-auto'
                                    style={{
                                        backgroundColor: '#4ADE80',
                                        fontFamily: 'Quicksand',
                                        fontStyle: 'normal',
                                        fontWeight: 500,
                                        fontSize: '2rem',
                                        width: '4rem',
                                        height: '4rem',
                                        lineHeight: '2rem',
                                        letterSpacing: '0.06em',
                                        color: 'black',
                                        borderRadius: '8px',
                                        padding: '10px',
                                        marginTop: '0.7rem',
                                    }} 
                                >
                                  {selectedSoal.jawaban_benar}
                                </button>
                                {selectedSoal.jawaban_benar_desc}
                                {
                                selectedSoal.jawaban_benar_image ?
                                  <div className="flex">
                                    <img
                                      src={selectedSoal.jawaban_benar_image}
                                      alt={'Gambar Soal No. ' + selectedNumber}
                                      effect="blur"
                                    />
                                  </div> : null
                                }
                              </div>
                            </div> :
                            <div className="flex flex-row p-4" style={{borderRadius: '10px', backgroundColor: '#f3f4f6',}}>
                              <div className="flex flex-col mx-auto justify-center">
                                <h1 style={{color: '#F87171',  textAlign: 'center'}}>Jawaban Anda:</h1>
                                <button 
                                    className='mx-auto'
                                    style={{
                                        backgroundColor: '#F87171',
                                        fontFamily: 'Quicksand',
                                        fontStyle: 'normal',
                                        fontWeight: 500,
                                        fontSize: '2rem',
                                        width: '4rem',
                                        height: '4rem',
                                        lineHeight: '2rem',
                                        letterSpacing: '0.06em',
                                        color: 'black',
                                        borderRadius: '8px',
                                        padding: '10px',
                                        marginTop: '0.7rem',
                                    }} 
                                >
                                  {selectedSoal.jawaban_peserta}
                                    
                                </button>
                                {selectedSoal.jawaban_peserta_desc}
                                {
                                selectedSoal.jawaban_peserta_image ?
                                  <div className="flex">
                                    <img
                                      src={selectedSoal.jawaban_peserta_image}
                                      alt={'Gambar Soal No. ' + selectedNumber}
                                      effect="blur"
                                    />
                                  </div> : null
                                }
                              </div>
                              <div className="flex flex-col mx-auto justify-center" >
                                <h1 style={{color: '#4ADE80',  textAlign: 'center'}}>Jawaban Benar:</h1>
                                <button 
                                    className='mx-auto'
                                    style={{
                                        backgroundColor: '#4ADE80',
                                        fontFamily: 'Quicksand',
                                        fontStyle: 'normal',
                                        fontWeight: 500,
                                        fontSize: '2rem',
                                        width: '4rem',
                                        height: '4rem',
                                        lineHeight: '2rem',
                                        letterSpacing: '0.06em',
                                        color: 'black',
                                        borderRadius: '8px',
                                        padding: '10px',
                                        marginTop: '0.7rem',
                                    }} 
                                >
                                  {selectedSoal.jawaban_benar}
                                </button>
                                {selectedSoal.jawaban_benar_desc}
                                {
                                selectedSoal.jawaban_benar_image ?
                                  <div className="flex">
                                    <img
                                      src={selectedSoal.jawaban_benar_image}
                                      alt={'Gambar Soal No. ' + selectedNumber}
                                      effect="blur"
                                    />
                                  </div> : null
                                }
                              </div>
                            </div>
                          }
                      </div>
                      {selectedSoal.pembahasan ? 
                        <div>
                        <hr className="my-4" />
                            <h5 class="mb-4" style={{ fontSize: '1.25rem', fontFamily: 'Quicksand', fontWeight: 'bold'}}>
                              Pembahasan
                            </h5>
                            <div className="flex w-full mx-auto xl:mx-0 mb-2 p-4" style={{borderRadius: '10px', backgroundColor: '#f3f4f6',}}>
                                <div className={selectedSoal.gambar_pembahasan ? "w-9/12 flex px-4" : "w-100 flex px-4"}>
                                    <h1 style={{fontFamily: 'Quicksand'}}>{selectedSoal.pembahasan}</h1>
                                </div>
                                {
                                  selectedSoal.gambar_pembahasan ? 
                                  <div className="w-3/12 flex">
                                    <img
                                        src={selectedSoal.gambar_pembahasan}
                                        alt={'Gambar Pembahasan Soal No. ' + selectedNumber}
                                        effect="blur"
                                        width="150rem"
                                    />
                                  </div> : null
                                }
                            </div>
                        </div> : null
                      }
                      
                  </div>
                </div>
            </Modal.Body>
        </Modal>
      </div>
    </React.Fragment>
    
  )
}

export default connect()(DetailNilai)