import React, { useState } from 'react';

export default function ProgramPreklinik() {

    return (
        <React.Fragment>
            <section className="container-fluid">
                <div className="site-header site-header--menu-left landing-11-menu site-header--absolute" style={{ backgroundPositionY: 'bottom', backgroundPositionX: 'right', backgroundRepeat: 'no-repeat', paddingTop: '6rem', backgroundColor: '#83D0CF' }}>
                    <div className="py-5" style={{ paddingBottom: '2rem !important' }}>
                        <h5 className="sub-title text-center">Kelas Bimbingan</h5>
                        <h5 className="tentang_title g-title">Preklinik FK, FKG dan Kebidanan </h5>

                    </div>
                </div>
            </section>
            <section className="container-fluid bg-desktop">
                <div className="container px-4" style={{ paddingTop: '10rem', width: '85%', paddingBottom: '5rem' }}>
                    <div className="row g-2" style={{ textAlign: 'left', paddingTop: '13.5rem' }}>
                        <div className="col-lg-6 col-md-12">
                            <img src="/assets/landing/preklinik-1.png" />
                        </div>
                        <div className="col-lg-6 col-md-12 mt5r">
                            <h5 className="tdt36px balooc2 prl5r" style={{ textDecoration: 'none' }}>Bimbingan Preklinik FK</h5>
                            <h5 className="txt20px mt1r prl5r" style={{ marginBottom: '1rem' }}>Merupakan program bimbingan di Ingenio yang mempersiapkan mahasiswa preklinik Kedokteran menghadapi ujian blok dan memahami materi kedokteran yang menyenangkan dan komprehensif
                            </h5>
                        </div>
                    </div>
                    <h5 className="txt24px mt1r" style={{ marginBottom: '0.2rem' }}>Dengan berbagai fasilitas penunjang yaitu:</h5>
                    <h5 className="txt20px mt1r" style={{ marginBottom: '1rem' }}>a.	Materi disesuaikan dengan blok di kampus masing-masing<br />
                        b.	Dapat diakses secara online dimana saja<br />
                        c.	Pengajaran berdasarkan pola pikir, bukan hanya mengajarkan teori<br />
                        d.	Latihan soal dan membahas soal<br />
                        e.	Private (1 orang) atau berkelompok (2-18 orang)<br />
                        f.	Jadwal bimbingan yang dapat disepakati bersama dengan tim akademik<br />
                        g.	Konsultasi tutor selama masa bimbingan

                    </h5>
                    <a href="https://drive.google.com/file/d/1EBJQGWskHQPaEV-UqSB0BiquEN7k0cQS/view?usp=sharing" className="txt20px" style={{ color: '#FCA61F', textDecoration: 'underline' }}>Download Brosur Preklinik FK
                    </a>
                    <div className="row g-2" style={{ textAlign: 'left', paddingTop: '2.5rem' }}>
                        <div className="col-lg-7 col-md-12 mt5r">
                            <h5 className="tdt36px w75p" style={{ textDecoration: 'none' }}>Bimbingan Preklinik FKG</h5>
                            <h5 className="txt20px mt1r w65p" style={{ marginBottom: '1rem' }}>Merupakan program bimbingan di Ingenio yang mempersiapkan mahasiswa preklinik Kedokteran Gigi menghadapi ujian blok dan memahami materi kedokteran yang menyenangkan dan komprehensif
                            </h5>
                            <h5 className="txt24px mt5r" style={{ marginBottom: '0.2rem' }}>Dengan berbagai fasilitas penunjang yaitu:</h5>
                            <h5 className="txt20px mt1r" style={{ marginBottom: '1rem' }}>a.	Materi disesuaikan dengan blok di kampus masing-masing<br />
                                b.	Dapat diakses secara online dimana saja<br />
                                c.	Pengajaran berdasarkan pola pikir, bukan hanya mengajarkan teori<br />
                                d.	Latihan soal dan membahas soal<br />
                                e.	Private (1 orang) atau berkelompok (2-18 orang)<br />
                                f.	Jadwal bimbingan yang dapat disepakati bersama dengan tim akademik<br />
                                g.	Konsultasi tutor selama masa bimbingan

                            </h5>
                            <a href="#" className="txt20px mt2r" style={{ color: '#FCA61F', textDecoration: 'underline' }}>Download Brosur Preklinik FKG
                            </a>
                        </div>
                        <div className="col-lg-5 col-md-12">
                            <img src="/assets/landing/preklinik-2.png" />
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment >
    )
}

