import React from 'react';
import styles from '../../index.module.css'

import { Card } from 'react-bootstrap';

export default function UKMPPDCard() {
  return (
    <React.Fragment>
      <Card style={{border: '0px'}}>
        <Card.Body className="container">
          <div className="row align-items-center text-center m-2">
            <div className="col-12">
              <h1 style={{fontSize: '108px', fontFamily: 'Quicksand', height: '75px'}} className={styles.textYellow}>“</h1>
              <h3 style={{ fontFamily: 'Quicksand'}} className="mb-3 text-3xl">Valeria Veldan</h3>
              <h5 style={{ fontFamily: 'Quicksand'}} className="mb-4 text-xl">Universitas FK Hasanudin</h5>
            </div>
          </div>
          <div className="row align-items-center text-center m-2">
            <div className="col-12 col-lg-8 mx-auto">
              <p style={{ fontFamily: 'Quicksand', color: 'black', fontSize: '21px', fontWeight: '500'}}>
                “ Lorem ipsum dolor sit amet, consectetur adipiscing elit. 
                Maecenas at scelerisque nunc. Duis a varius orci.  
                Nullam  sodales tincidunt elit et condimentum. Nullam  
                ut sapien  eget lacus pharetra fermentum id vitae quam.  
                Praesent id  augue ac nisl pharetra pretium. Pellentesque  
                elementum nibh egestas dolor suscipit dapibus. “
              </p>
            </div>
          </div>
        </Card.Body>
      </Card>
    </React.Fragment>
  )
}