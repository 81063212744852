import React from 'react';
import Card from '@material-tailwind/react/Card';
import CardHeader from '@material-tailwind/react/CardHeader';
import CardBody from '@material-tailwind/react/CardBody';
import { Col, Form, FormGroup, Row, Button, Modal, OverlayTrigger } from 'react-bootstrap';
import { HiOutlineDownload, HiStar } from 'react-icons/hi';
import LandingService from '../../../services/landing'
import moment from 'moment';
import Swal from 'sweetalert2';
import { useEffect, useState } from 'react';

export default function PengumumanCard () {
  
  const [startDate, setStartDate] = useState('');
  const [name, setName] = useState('');
  const [content, setContent] = useState('');
  const [urlImage, setUrlImage] = useState('-');
  const [urlFile, setUrlFile] = useState('-');

  const getPengumuman = async () => {
    try {
        const resp = await LandingService.getPengumuman()
        if(resp.data.data.length < 1) {
          return;
        }
        const dataPengumuman = resp.data.data[0]
        setStartDate(`${moment(dataPengumuman.start_date).format('dddd')}, ${moment(dataPengumuman.start_date).format('LL')}`)
        setName(dataPengumuman.name)
        setContent(dataPengumuman.content)
        setUrlImage(dataPengumuman.url_image)
        setUrlFile(dataPengumuman.url_file);
    }catch(err) {
        let msg = 'Internal Server Error'
        console.error(err)
        if(err.response) {
            if(err.response.data.message) {
                msg = err.response.data.message
            }
        }

        Fail(msg)
    }
  }

  const Fail = (message) => {
    Swal.fire({  
        title: 'Gagal!',  
        text: message,  
        icon: 'warning',  
        showCancelButton: true,  
        cancelButtonText: 'Kembali',           
        showConfirmButton: false,  
    });   
  }

  useEffect(async () => {
    await getPengumuman()
  }, [])

  return (
    <Card>
        <CardHeader contentPosition="left" className="bg-orange border-4 border-white-600">
            <h3 className="text-white text-2xl">Pengumuman</h3>
        </CardHeader>
        <CardBody>
            <div className="relative h-96">
            <div className='ml-4' style={{display: 'flex', justifyContent: 'space-between'}}>
                <i><b>{startDate}</b></i>
                {
                  urlFile !== null && urlFile !== '-' ?
                  <div>
                    <Button onClick={() => window.open(urlFile, '_blank')} className="btnOrange2">
                        <div className="titleBtnOrange">
                          <HiOutlineDownload size="23px" className="mr-2"/>Download File
                        </div> 
                    </Button>
                  </div>
                  :
                  null
                }
                
              </div>
              <div className='ml-4 mt-1' style={{display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center'}}>
                <h1 style={{fontSize: '35pt'}}>{name}</h1>
              </div>
              {
                urlImage !== '-' && urlImage !== null ?
                <div className='ml-4 mt-1' style={{display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center'}}>
                  <img src={urlImage} />
                </div>
                :
                null
              }
              
              <div className='ml-4 mt-1' style={{display: 'flex', width: '100%', flexWrap: 'wrap' }}>
                <p style={{fontSize: '14pt'}}>{content}</p>
              </div>
            </div>
        </CardBody>
    </Card>
  );
}