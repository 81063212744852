import React from 'react';
import { useState } from 'react';
import AdminSideBar from '../../../components/layout/dashboard/admin/AdminSideBar';
import { BsFillFileEarmarkArrowUpFill } from 'react-icons/bs';
import Swal from 'sweetalert2';

export default function UpdateLandingPage () {
  document.title = "INGENIO Indonesia";
  const [openTab, setOpenTab] = React.useState(1);

  const [selectedImage, setSelectedImage] = useState();
  const imageChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setSelectedImage(e.target.files[0]);
    }
  };

  const Succeed = () => {
    Swal.fire({  
        title: 'Data Berhasil Tersimpan !',  
        text: '',  
        icon: 'success',  
        showCancelButton: false,              
        confirmButtonText: 'OK'  
    }).then((result) => {
      if (result.isConfirmed) {
        window.location.reload();
      }
    })
  }

  const removeSelectedImage = () => {
    setSelectedImage();
  };
  // ===========

  const [selectedImage2, setSelectedImage2] = useState();
  const imageChange2 = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setSelectedImage2(e.target.files[0]);
    }
  };

  const removeSelectedImage2 = () => {
    setSelectedImage2();
  };
  // ===========

  const [selectedImage3, setSelectedImage3] = useState();
  const imageChange3 = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setSelectedImage3(e.target.files[0]);
    }
  };

  const removeSelectedImage3 = () => {
    setSelectedImage3();
  };
  // ===========

  const [selectedImage4, setSelectedImage4] = useState();
  const imageChange4 = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setSelectedImage4(e.target.files[0]);
    }
  };

  const removeSelectedImage4 = () => {
    setSelectedImage4();
  };
  // ===========

  const [selectedImage5, setSelectedImage5] = useState();
  const imageChange5 = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setSelectedImage5(e.target.files[0]);
    }
  };

  const removeSelectedImage5 = () => {
    setSelectedImage5();
  };
  // ===========

  const [selectedImage6, setSelectedImage6] = useState();
  const imageChange6 = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setSelectedImage6(e.target.files[0]);
    }
  };

  const removeSelectedImage6 = () => {
    setSelectedImage6();
  };
  // ===========

  const [selectedImage7, setSelectedImage7] = useState();
  const imageChange7 = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setSelectedImage7(e.target.files[0]);
    }
  };

  const removeSelectedImage7 = () => {
    setSelectedImage7();
  };
  // ===========

  const [selectedImage8, setSelectedImage8] = useState();
  const imageChange8 = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setSelectedImage8(e.target.files[0]);
    }
  };

  const removeSelectedImage8 = () => {
    setSelectedImage8();
  };
  // ===========

  const [selectedImage9, setSelectedImage9] = useState();
  const imageChange9 = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setSelectedImage9(e.target.files[0]);
    }
  };

  const removeSelectedImage9 = () => {
    setSelectedImage9();
  };
  // ===========

  const [selectedImage10, setSelectedImage10] = useState();
  const imageChange10 = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setSelectedImage10(e.target.files[0]);
    }
  };

  const removeSelectedImage10 = () => {
    setSelectedImage10();
  };
  // ===========

  const [selectedImage11, setSelectedImage11] = useState();
  const imageChange11 = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setSelectedImage11(e.target.files[0]);
    }
  };

  const removeSelectedImage11 = () => {
    setSelectedImage11();
  };
  // ===========

  const [selectedImage12, setSelectedImage12] = useState();
  const imageChange12 = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setSelectedImage12(e.target.files[0]);
    }
  };

  const removeSelectedImage12 = () => {
    setSelectedImage12();
  };
  // ===========

  const [selectedImage13, setSelectedImage13] = useState();
  const imageChange13 = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setSelectedImage13(e.target.files[0]);
    }
  };

  const removeSelectedImage13 = () => {
    setSelectedImage13();
  };
  // ===========

  const [selectedImage14, setSelectedImage14] = useState();
  const imageChange14 = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setSelectedImage14(e.target.files[0]);
    }
  };

  const removeSelectedImage14 = () => {
    setSelectedImage14();
  };

  return(
    <React.Fragment>
      <AdminSideBar judul={'Update Data Landing'} />
      <div className="md:ml-64 Landing" style={{ backgroundColor: '#F3F4F6' }}>
        <div className="bg-orange px-3 md:px-8 py-1" style={{height: '2.5rem'}}>
          <div className="container md:pr-8 md:pl-10">
          </div>
        </div>

        <div className="px-3 md:px-8 pb-5">
          <div className="container mx-auto max-w-full">
            <div className="row mt-5">
              <div className="col-12">
                <div className="cards pb-4">
                  <div className="row">
                    <div className="col-12 flex gap-4" style={{padding: '2.4rem 3.6rem 0 3.6rem'}}>
                      <img src={"/assets/img/update.jpg"} className="imgUpdate" />
                      <div className="title-24-700 mt-4">Update Data Landing</div>
                    </div>
                    <div className="col-12 mb-4" style={{padding: '0 3.6rem'}}>
                      <div className="borderBottomGrey2"></div>
                    </div>
                  </div>
                  <div className="row" style={{padding: '0 3.6rem 2rem 3.6rem'}}>
                    <div className="col-12">
                      <div className="py-3 bg-gray-100 font-bold">
                        <ul>
                          <li className="tabs1">
                            <a className={openTab === 1 ? "tabsActive" : "tabsNonActive"} 
                               onClick={e => {e.preventDefault();setOpenTab(1);}} href="#">
                               Carousel
                            </a>
                            {/* <div className="justify-center pb-2">
                              <div className="borderBottomTab"></div>
                            </div> */}
                          </li>
                          <li className="tabs2">
                            <a className={openTab === 2 ? "tabsActive" : "tabsNonActive"}
                               onClick={e => {e.preventDefault();setOpenTab(2);}} href="#">
                               History Ingenio
                            </a>
                          </li>
                          <li className="tabs3">
                            <a className={openTab === 3 ? "tabsActive" : "tabsNonActive"}
                               onClick={e => {e.preventDefault();setOpenTab(3);}} href="#">
                               Testimoni
                            </a>
                          </li>
                          <li className="tabs4">
                            <a className={openTab === 4 ? "tabsActive" : "tabsNonActive"}
                               onClick={e => {e.preventDefault();setOpenTab(4);}} href="#">
                               Prestasi Peserta
                            </a>
                          </li>
                          <li className="tabs5">
                            <a className={openTab === 5 ? "tabsActive" : "tabsNonActive"}
                               onClick={e => {e.preventDefault();setOpenTab(5);}} href="#">
                               Artikel
                            </a>
                          </li>
                          <li className="tabs6">
                            <a className={openTab === 6 ? "tabsActive" : "tabsNonActive"}
                               onClick={e => {e.preventDefault();setOpenTab(6);}} href="#">
                               Galery Tutor
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className={openTab === 1 ? "block" : "hidden"} >
                      <div className="row">
                        <div className="col-12">
                          <div className="title-20-600">Update Carousel</div>
                          <div className="borderOrange2"></div>
                        </div>
                        <div className="col-12">
                          <div className="title-18-500 mb-1">Upload Foto</div>
                        </div>
                        <div className="col-md-4">
                          <div className="imgContainer">
                            <input type="file" className="d-none" id="images" accept="image/*" onChange={imageChange} />
                            <label htmlFor="images" className="imgLanding">
                              <i class="imgIcon fas fa-upload"></i>
                              <div className="name">Click for upload</div> 
                            </label>
                            {selectedImage && (
                              <div className="selectedImg">
                                <img src={URL.createObjectURL(selectedImage)} alt="Thumb" className="preview" />
                                <button className="btnRemove" onClick={removeSelectedImage}>
                                  <i class="removeIcon fas fa-times"></i>
                                </button>
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="imgContainer">
                            <input type="file" className="d-none" id="images2" accept="image/*" onChange={imageChange2} />
                            <label htmlFor="images2" className="imgLanding">
                              <i class="imgIcon fas fa-upload"></i>
                              <div className="name">Click for upload</div> 
                            </label>
                            {selectedImage2 && (
                              <div className="selectedImg">
                                <img src={URL.createObjectURL(selectedImage2)} alt="Thumb" className="preview" />
                                <button className="btnRemove" onClick={removeSelectedImage2}>
                                  <i class="removeIcon fas fa-times"></i>
                                </button>
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="imgContainer">
                            <input type="file" className="d-none" id="images3" accept="image/*" onChange={imageChange3} />
                            <label htmlFor="images3" className="imgLanding">
                              <i class="imgIcon fas fa-upload"></i>
                              <div className="name">Click for upload</div> 
                            </label>
                            {selectedImage3 && (
                              <div className="selectedImg">
                                <img src={URL.createObjectURL(selectedImage3)} alt="Thumb" className="preview" />
                                <button className="btnRemove" onClick={removeSelectedImage3}>
                                  <i class="removeIcon fas fa-times"></i>
                                </button>
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="col-12 mt-4">
                          <div className="borderTopGrey"></div>
                          <div className="justify-end mt-4">
                            <button type='submit' onClick={Succeed}  className="btnOrange3">Update Data</button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={openTab === 2 ? "block" : "hidden"}>
                      <div className="row">
                        <div className="col-12">
                          <div className="title-20-600">Update History Ingenio</div>
                          <div className="borderOrange2"></div>
                        </div>
                        <div className="col-12">
                          <div className="title-18-500 mb-1">Upload Foto</div>
                        </div>
                        <div className="col-md-4">
                          <div className="imgContainer">
                            <input type="file" className="d-none" id="images4" accept="image/*" onChange={imageChange4} />
                            <label htmlFor="images4" className="imgLanding">
                              <i class="imgIcon fas fa-upload"></i>
                              <div className="name">Click for upload</div> 
                            </label>
                            {selectedImage4 && (
                              <div className="selectedImg">
                                <img src={URL.createObjectURL(selectedImage4)} alt="Thumb" className="preview" />
                                <button className="btnRemove" onClick={removeSelectedImage4}>
                                  <i class="removeIcon fas fa-times"></i>
                                </button>
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="imgContainer">
                            <input type="file" className="d-none" id="images5" accept="image/*" onChange={imageChange5} />
                            <label htmlFor="images5" className="imgLanding">
                              <i class="imgIcon fas fa-upload"></i>
                              <div className="name">Click for upload</div> 
                            </label>
                            {selectedImage5 && (
                              <div className="selectedImg">
                                <img src={URL.createObjectURL(selectedImage5)} alt="Thumb" className="preview" />
                                <button className="btnRemove" onClick={removeSelectedImage5}>
                                  <i class="removeIcon fas fa-times"></i>
                                </button>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-12 mt-4">
                        <div className="borderTopGrey"></div>
                        <div className="justify-end mt-4">
                          <button type='submit' onClick={Succeed}  className="btnOrange3">Update Data</button>
                        </div>
                      </div>
                    </div>
                    <div className={openTab === 3 ? "block" : "hidden"}>
                      <div className="row">
                        <div className="col-12">
                          <div className="title-20-600">Update Testimoni</div>
                          <div className="borderOrange2"></div>
                        </div>
                        <div className="col-lg-4">
                          <div className="title-18-500 mb-1">Nama</div>
                          <input type="text" class="formInput" placeholder="nama..." />
                        </div>
                        <div className="col-lg-4">
                          <div className="title-18-500 mb-1">Asal Kampus</div>
                          <input type="text" class="formInput" placeholder="asal kampus..." />
                        </div>
                        <div className="col-lg-4">
                          <div className="title-18-500 mb-1">Program Bimbingan</div>
                          <input type="text" class="formInput" placeholder="program bimbingan..." />
                        </div>
                        <div className="col-12">
                          <div className="title-18-500 mb-1">Isi Testimoni</div>
                          <textarea className="formInput" placeholder="isi testimoni..." name="" id="" style={{height: '100px', padding: '14px 18px'}}></textarea>
                        </div>
                        <div className="col-md-4">
                          <div className="title-18-500 mb-1">Upload Foto</div>
                          <div className="imgContainer">
                            <input type="file" className="d-none" id="images6" accept="image/*" onChange={imageChange6} />
                            <label htmlFor="images6" className="imgLanding">
                              <i class="imgIcon fas fa-upload"></i>
                              <div className="name">Click for upload</div> 
                            </label>
                            {selectedImage6 && (
                              <div className="selectedImg">
                                <img src={URL.createObjectURL(selectedImage6)} alt="Thumb" className="preview" />
                                <button className="btnRemove" onClick={removeSelectedImage6}>
                                  <i class="removeIcon fas fa-times"></i>
                                </button>
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="col-12 mt-4">
                          <div className="borderTopGrey"></div>
                          <div className="justify-end mt-4">
                            <button type='submit' onClick={Succeed}  className="btnOrange3">Update Data</button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={openTab === 4 ? "block" : "hidden"}>
                      <div className="row">
                        <div className="col-12">
                          <div className="title-20-600">Update Prestasi Peserta</div>
                          <div className="borderOrange2"></div>
                        </div>
                        <div className="col-12">
                          <div className="title-18-500 mb-1">Upload Foto</div>
                        </div>
                        <div className="col-md-4">
                          <div className="imgContainer">
                            <input type="file" className="d-none" id="images7" accept="image/*" onChange={imageChange7} />
                            <label htmlFor="images7" className="imgLanding">
                              <i class="imgIcon fas fa-upload"></i>
                              <div className="name">Click for upload</div> 
                            </label>
                            {selectedImage7 && (
                              <div className="selectedImg">
                                <img src={URL.createObjectURL(selectedImage7)} alt="Thumb" className="preview" />
                                <button className="btnRemove" onClick={removeSelectedImage7}>
                                  <i class="removeIcon fas fa-times"></i>
                                </button>
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="imgContainer">
                            <input type="file" className="d-none" id="images8" accept="image/*" onChange={imageChange8} />
                            <label htmlFor="images8" className="imgLanding">
                              <i class="imgIcon fas fa-upload"></i>
                              <div className="name">Click for upload</div> 
                            </label>
                            {selectedImage8 && (
                              <div className="selectedImg">
                                <img src={URL.createObjectURL(selectedImage8)} alt="Thumb" className="preview" />
                                <button className="btnRemove" onClick={removeSelectedImage8}>
                                  <i class="removeIcon fas fa-times"></i>
                                </button>
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="imgContainer">
                            <input type="file" className="d-none" id="images9" accept="image/*" onChange={imageChange9} />
                            <label htmlFor="images9" className="imgLanding">
                              <i class="imgIcon fas fa-upload"></i>
                              <div className="name">Click for upload</div> 
                            </label>
                            {selectedImage9 && (
                              <div className="selectedImg">
                                <img src={URL.createObjectURL(selectedImage9)} alt="Thumb" className="preview" />
                                <button className="btnRemove" onClick={removeSelectedImage9}>
                                  <i class="removeIcon fas fa-times"></i>
                                </button>
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="imgContainer">
                            <input type="file" className="d-none" id="images10" accept="image/*" onChange={imageChange10} />
                            <label htmlFor="images10" className="imgLanding">
                              <i class="imgIcon fas fa-upload"></i>
                              <div className="name">Click for upload</div> 
                            </label>
                            {selectedImage10 && (
                              <div className="selectedImg">
                                <img src={URL.createObjectURL(selectedImage10)} alt="Thumb" className="preview" />
                                <button className="btnRemove" onClick={removeSelectedImage10}>
                                  <i class="removeIcon fas fa-times"></i>
                                </button>
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="imgContainer">
                            <input type="file" className="d-none" id="images11" accept="image/*" onChange={imageChange11} />
                            <label htmlFor="images11" className="imgLanding">
                              <i class="imgIcon fas fa-upload"></i>
                              <div className="name">Click for upload</div> 
                            </label>
                            {selectedImage11 && (
                              <div className="selectedImg">
                                <img src={URL.createObjectURL(selectedImage11)} alt="Thumb" className="preview" />
                                <button className="btnRemove" onClick={removeSelectedImage11}>
                                  <i class="removeIcon fas fa-times"></i>
                                </button>
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="col-12 mt-4">
                          <div className="borderTopGrey"></div>
                          <div className="justify-end mt-4">
                            <button type='submit' onClick={Succeed}  className="btnOrange3">Update Data</button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={openTab === 5 ? "block" : "hidden"}>
                      <div className="row">
                        <div className="col-12">
                          <div className="title-20-600">Update Artikel</div>
                          <div className="borderOrange2"></div>
                        </div>
                        <div className="col-md-6">
                          <div className="title-18-500 mb-1">Nama Article</div>
                          <input type="text" class="formInput" placeholder="nama article..." />
                        </div>
                        <div className="col-md-6">
                          <div className="title-18-500 mb-1">Tanggal Article</div>
                          <input type="date" class="formInput" placeholder="tanggal..." />
                        </div>
                        <div className="col-md-6">
                          <div className="title-18-500 mb-1">Thumbnail</div>
                          <div className="imgContainer">
                            <input type="file" className="d-none" id="images12" accept="image/*" onChange={imageChange12} />
                            <label htmlFor="images12" className="imgLanding">
                              <i class="imgIcon fas fa-upload"></i>
                              <div className="name">Click for upload</div> 
                            </label>
                            {selectedImage12 && (
                              <div className="selectedImg">
                                <img src={URL.createObjectURL(selectedImage12)} alt="Thumb" className="preview" />
                                <button className="btnRemove" onClick={removeSelectedImage12}>
                                  <i class="removeIcon fas fa-times"></i>
                                </button>
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="title-18-500 mb-1">File Article<span className="txtpdf ml-2">(PDF)</span></div>
                          <div className="relative">
                            <input type="file" id="file" className="custom-file-input" style={{ position: 'absolute', right: '22px' }} />
                            <label htmlFor="file" className="labelFile">Upload File <BsFillFileEarmarkArrowUpFill size="18px"/></label>
                          </div>
                        </div>
                        <div className="col-12 mt-4">
                          <div className="borderTopGrey"></div>
                          <div className="justify-end mt-4">
                            <button type='submit' onClick={Succeed}  className="btnOrange3">Update Data</button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={openTab === 6 ? "block" : "hidden"}>
                      <div className="row">
                        <div className="col-12">
                          <div className="title-20-600">Update Galery Tutor</div>
                          <div className="borderOrange2"></div>
                        </div>
                        <div className="col-12 mb-3">
                          <div className="subTitle-18">Update Tutor Regio</div>
                          <div className="borderBottomGrey3"></div>
                        </div>
                        <div className="col-lg-4">
                          <div className="title-18-500 mb-1">Regio</div>
                          <input type="text" class="formInput" placeholder="regio..." />
                        </div>
                        <div className="col-lg-4">
                          <div className="title-18-500 mb-1">Nama Tutor</div>
                          <input type="text" class="formInput" placeholder="nama tutor..." />
                        </div>
                        <div className="col-lg-4">
                          <div className="title-18-500 mb-1">Alumni</div>
                          <input type="text" class="formInput" placeholder="alumni..." />
                        </div>
                        <div className="col-md-6">
                          <div className="title-18-500 mb-1">Materi Bidang</div>
                          <input type="text" class="formInput" placeholder="materi bidang..." />
                        </div>
                        <div className="col-md-6">
                          <div className="title-18-500 mb-1">Mengajar Sejak Tahun</div>
                          <input type="number" min={1945} max={2099} className="formInput" placeholder="0000" />
                        </div>
                        <div className="col-md-6">
                          <div className="title-18-500 mb-1">Upload Foto<span className="notice ml-3">*Format Foto PNG</span></div>
                          <div className="imgContainer">
                            <input type="file" className="d-none" id="images13" accept="image/*" onChange={imageChange13} />
                            <label htmlFor="images13" className="imgLanding">
                              <i class="imgIcon fas fa-upload"></i>
                              <div className="name">Click for upload</div> 
                            </label>
                            {selectedImage13 && (
                              <div className="selectedImg">
                                <img src={URL.createObjectURL(selectedImage13)} alt="Thumb" className="preview2" />
                                <button className="btnRemove" onClick={removeSelectedImage13}>
                                  <i class="removeIcon fas fa-times"></i>
                                </button>
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="title-18-500">Contoh Foto</div>
                          <div className="imgSample">
                            <img src="/assets/landing/tutor-1.png" className='sampleImages'></img>
                          </div>
                        </div>
                        <div className="col-12 mt-5 mb-3">
                          <div className="subTitle-18">Update Tutor</div>
                          <div className="borderBottomGrey3"></div>
                        </div>
                        <div className="col-md-6">
                          <div className="title-18-500 mb-1">Nama Tutor</div>
                          <input type="text" class="formInput" placeholder="nama tutor..." />
                        </div>
                        <div className="col-md-6">
                          <div className="title-18-500 mb-1">Alumni</div>
                          <input type="text" class="formInput" placeholder="alumni..." />
                        </div>
                        <div className="col-md-6">
                          <div className="title-18-500 mb-1">Materi Bidang</div>
                          <input type="text" class="formInput" placeholder="materi bidang..." />
                        </div>
                        <div className="col-md-6">
                          <div className="title-18-500 mb-1">Mengajar Sejak Tahun</div>
                          <input type="number" min={1945} max={2099} className="formInput" placeholder="0000" />
                        </div>
                        <div className="col-md-6">
                          <div className="title-18-500 mb-1">Upload Foto<span className="notice ml-3">*Format Foto PNG</span></div>
                          <div className="imgContainer">
                            <input type="file" className="d-none" id="images14" accept="image/*" onChange={imageChange14} />
                            <label htmlFor="images14" className="imgLanding">
                              <i class="imgIcon fas fa-upload"></i>
                              <div className="name">Click for upload</div> 
                            </label>
                            {selectedImage14 && (
                              <div className="selectedImg">
                                <img src={URL.createObjectURL(selectedImage14)} alt="Thumb" className="preview2" />
                                <button className="btnRemove" onClick={removeSelectedImage14}>
                                  <i class="removeIcon fas fa-times"></i>
                                </button>
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="title-18-500">Contoh Foto</div>
                          <div className="imgSample">
                            <img src="/assets/landing/tutor-2.png" className='sampleImages'></img>
                          </div>
                        </div>
                        <div className="col-12 mt-4">
                          <div className="borderTopGrey"></div>
                          <div className="justify-end mt-4">
                            <button type='submit' onClick={Succeed}  className="btnOrange3">Update Data</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
    
  )
}