import React from 'react';
import {
    ProgramDokterMuda,
} from '../components/programdoktermuda';
import {
    IngenioFooterBlue2,
    IngenioNavBlue2
} from '../components/layout'

export default function ProgramDokterMudaPage() {
    return (
        <React.Fragment>
            <IngenioNavBlue2 />
            <div style={{ backgroundColor: 'white' }}>
                <ProgramDokterMuda />
            </div>
            <IngenioFooterBlue2 />
        </React.Fragment>

    )
}