import Button from '@material-tailwind/react/Button';
import React, { useState } from 'react';
import { FormGroup, FormControl, Form, Modal } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import { Link } from 'react-router-dom';
import { BsPencilSquare, BsFillTrashFill, BsFillPlusCircleFill } from 'react-icons/bs';
import AkademikSidebar from '../../../../components/layout/dashboard/akademik/AkademikSideBar';

export default function AktivasiVideo() {
    document.title = "INGENIO Indonesia";
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const dropdown = {
        borderRadius: "10px",
        height: 50,
        padding: "25px",
    };
    const columns = [
        {
            name: 'NO.',
            selector: row => row.no,
            sortable: true,
            // width: '6vw',
        },
        {
            name: 'NAMA MATERI',
            selector: row => row.materi,
            sortable: true,
            // width: '10vw',
        },
        {
            name: 'NAMA KELAS',
            selector: row => row.kelas,
            sortable: true,
            // width: '10vw',   
        },
        {
            name: 'NAMA PROGRAM',
            selector: row => row.program,
            sortable: true,
            // width: '10vw',   
        },
        {
            name: 'NAMA REGIO',
            selector: row => row.regio,
            sortable: true,
            // width: '10vw',   
        },
        {
            name: 'NAMA TUTOR',
            selector: row => row.tutor,
            sortable: true,
            // width: '10vw',   
        },
        {
            cell: () => <Link className="pilih-tryout p-2" to="#">Aktivasi</Link>,
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
            width: '15vw',
        },
    ];
    const data = [
        {
            no: 1,
            materi: 'Lorem ipsum dolor.',
            kelas: 'Lorem ipsum dolor.',
            program: 'Lorem ipsum dolor.',
            regio: 'Lorem ipsum dolor.',
            tutor: 'Lorem ipsum dolor.',

        },
        {
            no: 2,
            materi: 'Lorem ipsum dolor.',
            kelas: 'Lorem ipsum dolor.',
            program: 'Lorem ipsum dolor.',
            regio: 'Lorem ipsum dolor.',
            tutor: 'Lorem ipsum dolor.',

        },
        {
            no: 3,
            materi: 'Lorem ipsum dolor.',
            kelas: 'Lorem ipsum dolor.',
            program: 'Lorem ipsum dolor.',
            regio: 'Lorem ipsum dolor.',
            tutor: 'Lorem ipsum dolor.',
        },
        {
            no: 4,
            materi: 'Lorem ipsum dolor.',
            kelas: 'Lorem ipsum dolor.',
            program: 'Lorem ipsum dolor.',
            regio: 'Lorem ipsum dolor.',
            tutor: 'Lorem ipsum dolor.',
        },
        {
            no: 5,
            materi: 'Lorem ipsum dolor.',
            kelas: 'Lorem ipsum dolor.',
            program: 'Lorem ipsum dolor.',
            regio: 'Lorem ipsum dolor.',
            tutor: 'Lorem ipsum dolor.',
        },
        {
            no: 6,
            materi: 'Lorem ipsum dolor.',
            kelas: 'Lorem ipsum dolor.',
            program: 'Lorem ipsum dolor.',
            regio: 'Lorem ipsum dolor.',
            tutor: 'Lorem ipsum dolor.',
        },
    ];

    return (
        <React.Fragment>
            <AkademikSidebar />
            <div className="md:ml-64" style={{ backgroundColor: '#F3F4F6' }}>
                <div className="bg-orange px-3 md:px-8 py-1 h-40">
                    <div className="container md:pr-8 md:pl-10">
                        <h3 className="text-left tracking-wider title-dashboard">Selamat Datang Staff Akademik INGENIO!</h3>
                    </div>
                </div>

                <div className="px-3 md:px-8 mt-5">
                    <div className="container mx-auto max-w-full">
                        <div className="row">
                            <div className="col-md-8 col-lg-4 col-xs-12 mb-4">
                                <FormGroup>
                                    <div className="row" style={{ backgroundColor: '#FFF', padding: '1rem', borderRadius: '1rem' }}>
                                        <div className="col-8">
                                            <input type="text" name="" style={{ backgroundColor: '#F3F4F6', width: '100%', borderRadius: '8px', border: 'none !important', height: '45px', paddingLeft: '10px' }} placeholder="&#x1F50E;&#xFE0E; Nama Materi." id="" />
                                        </div>
                                        <div className="col-3">
                                            <Button className="pilih-tryout">Cari</Button>
                                        </div>
                                    </div>
                                </FormGroup>
                            </div>
                            <div className="col-md-12 col-lg-12 col-xs-12">
                                <DataTable columns={columns} data={data} pagination />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment >

    )
}