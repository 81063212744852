import Button from '@material-tailwind/react/Button';
import React, { useState } from 'react';
import { FormGroup } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import { Link } from 'react-router-dom';
import TutorSidebar from '../../../components/layout/dashboard/tutor/TutorSideBar';
import { Modal } from 'react-bootstrap';

export default function UploadMateri() {
    document.title = "INGENIO Indonesia";
    const [program, setProgram] = useState('');
    const dropdown = {
        borderRadius: "10px",
        height: 50,
        padding: "25px",
    };
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const columns = [
        {
            name: 'NO.',
            selector: row => row.no,
            sortable: true,
            // width: '6vw',
        },
        {
            name: 'PROGRAM',
            selector: row => row.program,
            sortable: true,
            // width: '15vw',
        },
        {
            name: 'REGIO',
            selector: row => row.regio,
            sortable: true,
            // width: '15vw',
        },
        {
            name: 'KELAS',
            selector: row => row.kelas,
            sortable: true,
            // width: '15vw',
        },
        {
            name: 'MATERI',
            selector: row => row.materi,
            sortable: true,
            // width: '15vw',
        },
        {
            name: 'TANGGAL',
            selector: row => row.tanggal,
            sortable: true,
        },
        {
            cell: () => <Button className="pilih-tryout p-2 text-center text-dark m-1" onClick={handleShow}>Upload Materi</Button>,
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
    ];
    const data = [
        {
            no: 1,
            program: 'Lorem ipsum dolor.',
            regio: 'Regio A',
            kelas: <Link className="kelas" to="data-kelas/kelas-a" style={{ textDecoration: 'underline' }}>Kelas A</Link>,
            materi: 'Materi A',
            tanggal: '20 Jan 2022',
        },
        {
            no: 2,
            program: 'Lorem ipsum dolor.',
            regio: 'Regio B',
            kelas: <Link className="kelas" to="data-kelas/kelas-a" style={{ textDecoration: 'underline' }}>Kelas B</Link>,
            materi: 'Materi A',
            tanggal: '20 Jan 2022',
        },
        {
            no: 3,
            program: 'Lorem ipsum dolor.',
            regio: 'Regio C',
            kelas: <Link className="kelas" to="data-kelas/kelas-a" style={{ textDecoration: 'underline' }}>Kelas C</Link>,
            materi: 'Materi A',
            tanggal: '20 Jan 2022',
        },
        {
            no: 4,
            program: 'Lorem ipsum dolor.',
            regio: 'Regio D',
            kelas: <Link className="kelas" to="data-kelas/kelas-a" style={{ textDecoration: 'underline' }}>Kelas D</Link>,
            materi: 'Materi A',
            tanggal: '20 Jan 2022',
        },
        {
            no: 5,
            program: 'Lorem ipsum dolor.',
            regio: 'Regio E',
            kelas: <Link className="kelas" to="data-kelas/kelas-a" style={{ textDecoration: 'underline' }}>Kelas A</Link>,
            materi: 'Materi A',
            tanggal: '20 Jan 2022',
        },
        {
            no: 6,
            program: 'Lorem ipsum dolor.',
            regio: 'Regio F',
            kelas: <Link className="kelas" to="data-kelas/kelas-a" style={{ textDecoration: 'underline' }}>Kelas C</Link>,
            materi: 'Materi A',
            tanggal: '20 Jan 2022',
        },
        {
            no: 7,
            program: 'Lorem ipsum dolor.',
            regio: 'Regio G',
            kelas: <Link className="kelas" to="data-kelas/kelas-a" style={{ textDecoration: 'underline' }}>Kelas B</Link>,
            materi: 'Materi A',
            tanggal: '20 Jan 2022',
        },
        {
            no: 8,
            program: 'Lorem ipsum dolor.',
            regio: 'Regio H',
            kelas: <Link className="kelas" to="data-kelas/kelas-a" style={{ textDecoration: 'underline' }}>Kelas A</Link>,
            materi: 'Materi A',
            tanggal: '20 Jan 2022',
        },
        {
            no: 9,
            program: 'Lorem ipsum dolor.',
            regio: 'Regio I',
            kelas: <Link className="kelas" to="data-kelas/kelas-a" style={{ textDecoration: 'underline' }}>Kelas A</Link>,
            materi: 'Materi A',
            tanggal: '20 Jan 2022',
        },
        {
            no: 10,
            program: 'Lorem ipsum dolor.',
            regio: 'Regio J',
            kelas: <Link className="kelas" to="data-kelas/kelas-a" style={{ textDecoration: 'underline' }}>Kelas B</Link>,
            materi: 'Materi A',
            tanggal: '20 Jan 2022',
        },
    ];

    return (
        <React.Fragment>
            <TutorSidebar />
            <div className="md:ml-64" style={{ backgroundColor: '#F3F4F6' }}>
                <div className="bg-orange px-3 md:px-8 py-1 h-40">
                    <div className="container md:pr-8 md:pl-10">
                        <h3 className="text-left tracking-wider title-dashboard">Selamat Datang Tutor INGENIO !</h3>
                    </div>
                </div>

                <div className="px-3 md:px-8 mt-4">
                    <div className="container mx-auto max-w-full">
                        <div className="row">
                            <div className="col-md-4 col-lg-4 col-xs-12 mb-4">
                                <FormGroup>
                                    <div className="row" style={{ backgroundColor: '#FFF', padding: '1rem', borderRadius: '1rem' }}>
                                        <div className="col-md-9">
                                            <input type="text" name="" style={{ backgroundColor: '#F3F4F6', width: '100%', borderRadius: '8px', border: 'none !important', height: '45px', paddingLeft: '10px' }} placeholder="&#x1F50E;&#xFE0E; Search..." id="" />
                                        </div>
                                        <div className="col-3">
                                            <Button className="pilih-tryout">Cari</Button>
                                        </div>

                                    </div>

                                </FormGroup>
                            </div>
                        </div>
                        <div className="col-md-12 col-lg-12 col-xs-12 mt-3">
                            <DataTable columns={columns} data={data} pagination />
                        </div>
                    </div>
                </div>
                <Modal show={show} size="lg" onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Tambah Materi</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <form key={1}>
                        <input type="text" placeholder="Nama Materi" id="" className="mb-4" style={{ backgroundColor: '#F3F4F6', width: '100%', borderRadius: '8px', border: 'none !important', height: '45px', paddingLeft: '10px' }} /> <br />
                        <span class="text-xs text-danger"></span>

                        <input type="text" placeholder="Sub Materi" id="" className="mb-4" style={{ backgroundColor: '#F3F4F6', width: '100%', borderRadius: '8px', border: 'none !important', height: '45px', paddingLeft: '10px' }} /> <br />
                        <span class="text-xs text-danger"></span>

                        <label htmlFor="">Upload Materi</label>
                        <input type="file" placeholder="Sub Materi" id="" className="mb-4" style={{ backgroundColor: '#F3F4F6', width: '100%', borderRadius: '8px', border: 'none !important', height: '45px', paddingLeft: '10px' }} /> <br />
                        <span class="text-xs text-danger"></span>

                        <label htmlFor="">Upload Video</label>
                        <input type="file" placeholder="Sub Materi" id="" className="mb-4" style={{ backgroundColor: '#F3F4F6', width: '100%', borderRadius: '8px', border: 'none !important', height: '45px', paddingLeft: '10px' }} /> <br />
                        <span class="text-xs text-danger"></span>

                        <Button 
                            className="bg-success mx-2"
                            type="submit" 
                            style={{ borderRadius: '5px', color: 'white', padding: '5px', float: 'right' }}>
                            Submit
                        </Button>
                        <Button 
                            className="bg-primary mx-2"
                            onClick={handleClose} 
                            style={{ padding: '5px', float: 'right' }}>
                            Batal
                        </Button>
                        </form>
                    </Modal.Body>
                </Modal>
            </div>
        </React.Fragment >

    )
}