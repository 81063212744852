import React from 'react';
import {
    LoginAkademik,
} from '../components/loginakademik';
import {
    IngenioNav,
    IngenioFooter
} from '../components/layout'

export default function LoginAkademikPage() {
    document.title = "INGENIO Indonesia | Login";
    return (
        <React.Fragment>
            <IngenioNav />
            <div style={{ backgroundColor: 'white' }}>
                <LoginAkademik />
            </div>
            <IngenioFooter bgColor="transparent" />
        </React.Fragment>

    )
}