import React, { useState, useEffect } from 'react';
import {
  SideBar
} from '../../../components/layout/dashboard';
import { useParams, useHistory } from "react-router-dom";
import { Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Icon from '@material-tailwind/react/Icon';

import { connect } from "react-redux";
import { getTryoutDetail } from "../../../store/actions/tryout"

function DetailTryOut (props) {
    document.title = "INGENIO Indonesia | Detail Try Out";
    const [programs, setPrograms] = useState({});
    const [dataTryout, setDataTryout] = useState({})
    const [dataSubTryout, setDataSubTryout] = useState([]);
    const [total, setTotal] = useState(0); 

    const { dispatch } = props;
    let { id } = useParams();

    const history = useHistory();

    useEffect(() => {
        dispatch(getTryoutDetail(id))
        .then((response) =>{
            const { subtryout, program, ...tryout } = response.data
            setDataSubTryout(subtryout)
            setPrograms(program)
            setDataTryout({...tryout})
            
        }).then (() => {
            let totalSoal = 0;
            dataSubTryout.forEach((item, index) => {
                totalSoal += item.jumlah_soal; 
            })
            setTotal(totalSoal);
        });
    }, [dataTryout.id]);

    return(
        <React.Fragment>
        <SideBar />
        <div className="md:ml-64">
            {/* <div className="bg-orange px-3 md:px-8 py-1 h-40">
            <div className="container md:pr-8 md:pl-10">
                <h3 className="text-left tracking-wider title-dashboard">Selamat Datang, Peserta TryOut INGENIO Indonesia</h3>
            </div>           
            </div>

            <div className="px-3 md:px-8 -mt-24">
                <div className="container mx-auto max-w-full">
                    <div className="grid grid-cols-2 xl:grid-cols-5">
                        <div className="xl:col-start-1 xl:col-end-4 px-4 mb-14">
                        <a href="/dashboard/tryout" className="rounded-full btn-tryout">Mulai TryOut</a>
                        <a href="/dashboard/tata-cara" className="rounded-full btn-tryout">Tata Cara TryOut</a>
                        <a href="/dashboard/hasil-tryout" className="rounded-full btn-tryout">Hasil TryOut</a>
                        </div>
                    </div>
                </div>
            </div> */}

            <div className="container md:pr-8 md:pl-10"  style={{position: 'absolute', top: '80px'}}>
                <h3 className="text-left tracking-wider title-dashboard">Selamat Datang, Peserta TryOut INGENIO Indonesia</h3>
                <div className="grid grid-cols-2 xl:grid-cols-5" style={{marginTop: '21px'}}>
                    <div className="xl:col-start-1 xl:col-end-4 px-4 mb-14">
                    <a href="/dashboard/tryout" className="rounded-full btn-tryout">Mulai TryOut</a>
                    <a href="/dashboard/tata-cara" className="rounded-full btn-tryout">Tata Cara TryOut</a>
                    <a href="/dashboard/hasil-tryout" className="rounded-full btn-tryout">Hasil TryOut</a>
                    </div>
                </div>
            </div> 

            <div className="px-3 md:px-8">
                <div className="container mx-auto max-w-full">
                    <p className="breadcrumbss py-4">Tryout Tersedia / <span style={{fontWeight: 'bold'}}>Detail Tryout</span></p>
                    <div className="card-gede">
                        <div className="card-head px-4 py-4" >
                            Detail Tryout - {dataTryout.nama_tryout}
                        </div>
                        <div className="row">
                            <div className="col-lg-6 col-md-6 col-xs-12 py-4 px-5">
                                <p style={{fontSize:"18px !important", lineHeight: "22px", letterSpacing: "0.06em", color: "#000000", fontWeight: 'bold'}}>Daftar List Sub Tryout</p> <br />
                                <Table bordered>
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>NAMA TRYOUT</th>
                                            <th>JUMLAH SOAL</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {dataSubTryout.map((item, idx) => 
                                            <tr>
                                                <td>{idx + 1}</td>
                                                <td>{item.nama_subtryout}</td>
                                                <td>{item.jumlah_soal}</td>
                                            </tr>
                                        )}                           
                                    </tbody>
                                </Table>
                            </div>
                            <div className="col-lg-6 col-md-6 col-xs-12 py-4 px-5">
                                <p style={{fontSize:"18px !important", lineHeight: "22px", letterSpacing: "0.06em", color: "#000000", fontWeight: 'bold'}}>Detail</p> <br />
                                <table style={{marginBottom: '15px'}}>
                                    <tr>
                                        <td style={{padding: '0px 10px 0px 0px'}}>Nama Paket Tryout</td>
                                        <td style={{padding: '0px 10px 0px 0px'}}>:</td>
                                        <td style={{padding: '0px'}}><b>{dataTryout.nama_tryout}</b></td>
                                    </tr>
                                    <tr>
                                        <td style={{padding: '0px'}}>Total Tryout</td>
                                        <td style={{padding: '0px'}}>:</td>
                                        <td style={{padding: '0px'}}><b>{dataSubTryout.length}</b></td>
                                    </tr>
                                    <tr>
                                        <td style={{padding: '0px'}}>Total jumlah soal</td>
                                        <td style={{padding: '0px'}}>:</td>
                                        <td style={{padding: '0px'}}><b>{`${total} soal`}</b></td>
                                    </tr>
                                </table>
                                {/* <p className="text-black mb-3">Nama Paket Tryout : <b>{dataTryout.nama_tryout}</b></p>                     
                                <p className="text-black mb-3">Total Tryout : <b>{dataSubTryout.length}</b></p>
                                <p className="text-black mb-4">Total Jumlah Soal : <b>{total}</b></p> */}
                                <Link className="pilih-tryout p-2" to={"/dashboard/payment?id=" + dataTryout.id}>
                                <Icon color="black" size="base" name="shopping_cart" />
                                <span className="px-2">Beli TryOut</span>
                                </Link>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>      

        </div>
        </React.Fragment>
        
    )
}

export default connect()(DetailTryOut);