import React, { useState } from 'react';

import { FormGroup, FormControl, Form } from 'react-bootstrap';
import AkademikSidebar from '../../../../components/layout/dashboard/akademik/AkademikSideBar';

export default function DetailHasilPeserta() {
    document.title = "INGENIO Indonesia";
    document.body.style.backgroundColor = "#f3f4f6";
    var baseUrl = window.location.origin;

    return (
        <React.Fragment>
            <AkademikSidebar judul={'Detail Peserta'} />
            <div className="md:ml-64">
                <div className="bg-orange px-3 md:px-8 py-1" style={{ height: '2.5rem' }}>
                </div>
                <div className="py-4 px-3 md:px-8 -mt-12">
                    <div className="container mx-auto max-w-full">
                        <div class="shadow-md rounded-md overflow-hidden col-12 col-md-10 col-lg-12 mx-auto">
                            <div className="py-3 px-3 bg-white font-bold">
                            </div>
                            <div id="profileInformation" >
                                <form action="" className="overflow-y-scroll bg-white" style={{ maxHeight: '90vh' }}>
                                    <div className="form-group p-5" style={{ fontFamily: 'Quicksand' }}>
                                        <div className="flex flex-row">
                                            <div className="flex w-3/12 flex-initial flex-col">
                                                <img src={baseUrl + "/assets/img/profile-image-default.png"} className="object-center mx-auto"></img>
                                            </div>
                                            <div className="flex w-9/12 flex-initial flex-col">
                                                <div className="row px-4 pb-2 my-2">
                                                    <div className="col-4 lg:px-2">
                                                        <h4 className="font-bold">Nama Lengkap Peserta</h4>
                                                    </div>
                                                    <div className="col-8 lg:px-2">
                                                        <h4>Nama Lengkap Peserta</h4>
                                                    </div>
                                                </div>
                                                <div className="row px-4 pb-2 my-2">
                                                    <div className="col-4 lg:px-2">
                                                        <h4 className="font-bold">Nama Panggilan</h4>
                                                    </div>
                                                    <div className="col-8 lg:px-2">
                                                        <h4>Nama Panggilan</h4>
                                                    </div>
                                                </div>
                                                <div className="row px-4 pb-2 my-2">
                                                    <div className="col-4 lg:px-2">
                                                        <h4 className="font-bold">Nomor HP</h4>
                                                    </div>
                                                    <div className="col-8 lg:px-2">
                                                        <h4>Nomor Hp</h4>
                                                    </div>
                                                </div>
                                                <div className="row px-4 pb-2 my-2">
                                                    <div className="col-4 lg:px-2">
                                                        <h4 className="font-bold">Akun Instagram</h4>
                                                    </div>
                                                    <div className="col-8 lg:px-2">
                                                        <h4>@Akuninstagram</h4>
                                                    </div>
                                                </div>
                                                <div className="row px-4 pb-2 my-2">
                                                    <div className="col-4 lg:px-2">
                                                        <h4 className="font-bold">Jenis Kelamin</h4>
                                                    </div>
                                                    <div className="col-8 lg:px-2">
                                                        <h4>Jenis Kelamin</h4>
                                                    </div>
                                                </div>
                                                <div className="row px-4 pb-2 my-2">
                                                    <div className="col-4 lg:px-2">
                                                        <h4 className="font-bold">Email</h4>
                                                    </div>
                                                    <div className="col-8 lg:px-2">
                                                        <h4>Email@email.com</h4>
                                                    </div>
                                                </div>
                                                <div className="row px-4 pb-2 my-2">
                                                    <div className="col-4 lg:px-2">
                                                        <h4 className="font-bold">Agama</h4>
                                                    </div>
                                                    <div className="col-8 lg:px-2">
                                                        <h4>Agama</h4>
                                                    </div>
                                                </div>
                                                <div className="row px-4 pb-2 my-2">
                                                    <div className="col-4 lg:px-2">
                                                        <h4 className="font-bold">Asal Fakultas</h4>
                                                    </div>
                                                    <div className="col-4 lg:px-2">
                                                        <h4>Asal Fakultas</h4>
                                                    </div>
                                                </div>
                                                <div className="row px-4 pb-2 my-2">
                                                    <div className="col-4 lg:px-2">
                                                        <h4 className="font-bold">Asal Universitas</h4>
                                                    </div>
                                                    <div className="col-8 lg:px-2">
                                                        <h4>Asal Universitas</h4>
                                                    </div>
                                                </div>
                                                <div className="row px-4 pb-2 my-2">
                                                    <div className="col-4 lg:px-2">
                                                        <h4 className="font-bold">Angkatan</h4>
                                                    </div>
                                                    <div className="col-8 lg:px-2">
                                                        <h4>Angkatan</h4>
                                                    </div>
                                                </div>
                                                <div className="row px-4 pb-2 my-2">
                                                    <div className="col-4 lg:px-2">
                                                        <h4 className="font-bold">Pendidikan Terakhir</h4>
                                                    </div>
                                                    <div className="col-8 lg:px-2">
                                                        <h4>Pendidikan Terakhir</h4>
                                                    </div>
                                                </div>
                                                <div className="row px-4 pb-2 my-2">
                                                    <div className="col-4 lg:px-2">
                                                        <h4 className="font-bold">Alamat Lengkap KTP</h4>
                                                    </div>
                                                    <div className="col-8 lg:px-2">
                                                        <input
                                                            type="textarea"
                                                            name=""
                                                            className="form-tryout mb-3"
                                                            placeholder="Alamat Lengkap KTP"
                                                            disabled={true}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row px-4 pb-2 my-2">
                                                    <div className="col-4 lg:px-2">
                                                        <h4 className="font-bold">Alamat Lengkap Domisili</h4>
                                                    </div>
                                                    <div className="col-8 lg:px-2">
                                                        <input
                                                            type="textarea"
                                                            name=""
                                                            className="form-tryout mb-3"
                                                            placeholder="Alamat Lengkap Domisili"
                                                            disabled={true}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row px-4 pb-2 my-2">
                                                    <div className="col-4 lg:px-2">
                                                        <h4 className="font-bold">Negara</h4>
                                                    </div>
                                                    <div className="col-8 lg:px-2">
                                                        <h4>Negara</h4>
                                                    </div>
                                                </div>
                                                <div className="row px-4 pb-2 my-2">
                                                    <div className="col-4 lg:px-2">
                                                        <h4 className="font-bold">Status Hamil / Materi yang Sulit Dipahami</h4>
                                                    </div>
                                                    <div className="col-8 lg:px-2">
                                                        <h4>Status Hamil / Materi yang sulit dipahami</h4>
                                                    </div>
                                                </div>
                                                <div className="row px-4 pb-2 my-2">
                                                    <div className="col-4 lg:px-2">
                                                        <h4 className="font-bold">Mengetahui Ingenio Dari</h4>
                                                    </div>
                                                    <div className="col-8 lg:px-2">
                                                        <h4>Facebook, Instagram</h4>
                                                    </div>
                                                </div>
                                                <div className="row px-4 pb-2 my-2">
                                                    <div className="col-4 lg:px-2">
                                                        <h4 className="font-bold">Bimbingan yang Pernah di Ikuti</h4>
                                                    </div>
                                                    <div className="col-8 lg:px-2">
                                                        <h4>Bimbingan</h4>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

            </div >
        </React.Fragment >

    )
}