import React from 'react';
import {
  SideBar
} from '../../components/layout/dashboard';
import { Col, Form, FormGroup, Row, Button, Modal, OverlayTrigger } from 'react-bootstrap';
import { useLocation } from 'react-router';
import { useParams, useHistory, Link } from "react-router-dom";
import { Player } from 'video-react';
import DocViewer, { DocViewerRenderers, PDFRenderer } from "react-doc-viewer";
import VideoJS from '../../components/VideoJS'

import "video-react/dist/video-react.css";
import { useState, useEffect } from 'react';

export default function ViewMateriCourse() {
    document.title = "INGENIO Indonesia";
    var baseUrl = window.location.origin;
    const [isDisabled, setIsDisabled] = useState(false);

    const playerRef = React.useRef(null);

    const history = useHistory();
    const location = useLocation();
    const { kode_ecourse, ecourse_name, materi_name, url_content, content_type } = location.state;

    const handlePlayerReady = (player) => {
        playerRef.current = player;
    
        // You can handle player events here, for example:
        player.on('waiting', () => {
          console.log('player is waiting');
        });
    
        player.on('dispose', () => {
          console.log('player will dispose');
        });
    };

    useEffect(() => {
        
    }, [])

    return (
        <React.Fragment>
            <SideBar />
            <div tabIndex="0" className="md:ml-64 styleHead" style={{ backgroundColor: '#F3F4F6' }}>
                {/* <div className="bg-orange md:px-8" style={{height: '180px', position: 'relative', padding: '0 30px'}}>
                    <h1 className="tracking-wider title-24-600">Akses Video Materi Saya</h1><br />
                    <img src={baseUrl + "/assets/logo/evaluasikelas.png"} className="img-opacity"></img>
                </div> */}

                <div className="container md:pr-8 md:pl-10"  style={{position: 'absolute', top: '80px'}}>
                    <h1 className="tracking-wider title-24-600">Akses Video Materi Saya</h1><br />
                    {/* <h3 className="text-left tracking-wider">Anda dapat menghubungi Admin terlebih dahulu untuk memastikan kelas dan program yang Anda daftarkan. <br /> <br />Kontak Admin <a href={contactLink()} target={'_blank'}> <i class="fa fa-whatsapp" aria-hidden="true"></i></a></h3> */}
                    {/* <img src={baseUrl + "/assets/logo/evaluasikelas.png"} className="img-opacity"></img> */}
                </div> 

                <div className="px-3 md:px-8 mt-4 ">
                    <div className="container mx-auto max-w-full pb-4">
                        <div className="row">
                            <div className="col-12">
                                <table style={{marginBottom: '15px'}}>
                                    <tr>
                                        <td>Kode E-Course</td>
                                        <td>:</td>
                                        <td><b>{kode_ecourse}</b></td>
                                    </tr>
                                    <tr>
                                        <td>Nama E-Course</td>
                                        <td>:</td>
                                        <td><b>{ecourse_name}</b></td>
                                    </tr>
                                    <tr>
                                        <td>Nama Materi</td>
                                        <td>:</td>
                                        <td><b>{materi_name}</b></td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                        <div className="row">
                            {
                                isDisabled ?
                                <div className="col-12 mt-3">
                                    <Button onClick={() => document.location.reload()} className="btnOrange2">
                                        <div className="titleBtnOrange">
                                            <span>Refresh page</span>
                                        </div> 
                                    </Button>
                                </div>
                                :
                                <div className="col-12 mt-3">
                                    {
                                        content_type === 'pdf' ?
                                        <DocViewer pluginRenderers={[PDFRenderer]} documents={[{uri: url_content}]} />
                                        :
                                        null
                                    }
                                    {
                                        content_type === 'doc' ?
                                        <DocViewer style={{height: '1000px'}} pluginRenderers={DocViewerRenderers} documents={[{uri: url_content}]} />
                                        :
                                        null
                                    }
                                    {
                                        content_type === 'video' ?
                                        <VideoJS options={{
                                            autoplay: false,
                                            controls: true,
                                            responsive: true,
                                            fluid: true,
                                            sources: [{
                                                src: url_content,
                                                type: 'video/mp4'
                                            }]
                                        }} onReady={handlePlayerReady} />
                                        // <Player
                                        //     playsInline
                                        //     playing
                                        //     controls
                                        //     src={url_content}
                                        //     onContextMenu={e => e.preventDefault()}
                                        // />
                                        :
                                        null
                                    }
                                    {/* <iframe width="100%" height="550px" src="https://www.youtube.com/embed/ysz5S6PUM-U" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> */}
                                    {/* <Player
                                        playsInline
                                        playing
                                        controls
                                        src={url_content}
                                        onContextMenu={e => e.preventDefault()}
                                    /> */}
                                    
                                </div>
                            }
                            
                        </div>
                    </div>
                </div>

            </div>
        </React.Fragment >

    )
}