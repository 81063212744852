import React, { useEffect, useState } from 'react';
import {
  IngenioNav,
  IngenioFooter
} from '../components/layout'
import { Spinner } from 'react-bootstrap';
import { Redirect } from "react-router-dom";
import axios from 'axios';

export default function VerificationPage () {
  document.title = "INGENIO Indonesia | Verifikasi Email";
  let queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const token = urlParams.get('id')
  console.log(token);
  const [message, setMessage] = useState('');

  useEffect(() => {
    const data = {
      token
    }
    axios.put(process.env.REACT_APP_AUTHURL+'verify-email', data)
      .then((response) => {
          if(response.data.success === false) {
              setMessage('Verification Link has Expired')
              console.log(response);
          } else if (response.data.success === true) {
              setMessage('Account has been successfully verified, you can continue to login with your account')
              console.log(response);
          }
          // history.push('/dashboard');
      })
      .catch((error) => {
          console.log(error);
      });
  });

  if(message){
    return <Redirect to={{ pathname: '/signin', state: { emailVerified: message } }}/>
  } else {
    return (
      <React.Fragment>
        <section className="container-fluid bg-desktop" style={{ backgroundImage: 'url(/assets/landing/bg-racoon.jpg)' }}>
          <div className="container px-4" style={{ paddingTop: '10rem' }}>
            <div className="row align-items-center g-2 py-3">
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </div>
          </div>
        </section>
      </React.Fragment>
    ) 
  }
}