import React from 'react';

export default function Ingeniostore() {

    return (
        <React.Fragment>
            <section className="container-fluid">
                <div className="site-header site-header--menu-left landing-11-menu site-header--absolute" style={{ backgroundImage: 'url(/assets/landing/Genio2.png)', backgroundPositionY: 'bottom', backgroundPositionX: 'right', backgroundRepeat: 'no-repeat', paddingTop: '6rem', backgroundColor: '#ffcc00' }}>
                    <h5 className="text-center tentang_title py-5" style={{paddingBottom: '2rem !important'}}>INGENIO STORE</h5>
                </div>
            </section>
            <section className="container-fluid bg-desktop">
                <div className="container px-4" style={{ paddingTop: '12rem' }}>
                    <div className="row g-2 pdd7r">
                        <div className="col4">
                            <div className="row align-items-center justify-content-center text-left g-2 py-1 mt-4">
                                <a className="bt-m zoom" href="#" style={{ width: '300px', height: '350px', paddingLeft: '1rem', background: 'linear-gradient(248.1deg, #FFFFFF 27.1%, #FFFFFF 73.3%)', boxShadow: '0px 2px 10px #000000', borderRadius: '1rem' }}>
                                    {/* <span className="bt-link" style={{ color: '#000000' }}>dr Novel</span> */}
                                    <img src="/assets/store/merchant1.png" style={{ marginLeft: 'auto', marginRight: 'auto', width: '172px', height: '177px' }} /><br />
                                    <span className="txt24px" style={{ fontWeight: '700', color: '#000000' }}>Stock Ready/PO</span><br />
                                    <span className="txt18px" style={{ color: '#000000' }}>kode produk</span><br /><br />
                                    <span className="txt18px" style={{ fontWeight: '700', color: '#F8CA2B' }}>Rp.90.000</span>
                                </a>
                            </div>
                        </div>
                        <div className="col4">
                            <div className="row align-items-center justify-content-center text-left g-2 py-1 mt-4">
                                <a className="bt-m zoom" href="#" style={{ width: '300px', height: '350px', paddingLeft: '1rem', background: 'linear-gradient(248.1deg, #FFFFFF 27.1%, #FFFFFF 73.3%)', boxShadow: '0px 2px 10px #000000', borderRadius: '1rem' }}>
                                    {/* <span className="bt-link" style={{ color: '#000000' }}>dr Novel</span> */}
                                    <img src="/assets/store/merchant2.png" style={{ marginLeft: 'auto', marginRight: 'auto', width: '172', height: '177px' }} /><br />
                                    <span className="txt24px" style={{ fontWeight: '700', color: '#000000' }}>Stock <a style={{ color: '#22C55E' }}>Ready</a></span><br />
                                    <span className="txt18px" style={{ color: '#000000' }}>A0821F</span><br /><br />
                                    <span className="txt18px" style={{ fontWeight: '700', color: '#F8CA2B' }}>Rp. 120.000</span>
                                </a>
                            </div>
                        </div>
                        <div className="col4">
                            <div className="row align-items-center justify-content-center text-left g-2 py-1 mt-4 ">
                                <a className="bt-m zoom" href="#" style={{ width: '300px', height: '350px', paddingLeft: '1rem', background: 'linear-gradient(248.1deg, #FFFFFF 27.1%, #FFFFFF 73.3%)', boxShadow: '0px 2px 10px #000000', borderRadius: '1rem' }}>
                                    {/* <span className="bt-link" style={{ color: '#000000' }}>dr Novel</span> */}
                                    <img src="/assets/store/merchant3.png" style={{ marginLeft: 'auto', marginRight: 'auto', width: '162px', height: '177px' }} /><br />
                                    <span className="txt24px" style={{ fontWeight: '700', color: '#000000' }}>Stock <a style={{ color: '#FCA61F' }}>PO</a></span><br />
                                    <span className="txt18px" style={{ color: '#000000' }}>7GRFF1</span><br /><br />
                                    <span className="txt18px" style={{ fontWeight: '700', color: '#F8CA2B' }}>Rp. 150.000</span>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="row align-items-center justify-content-center text-center g-2 py-1 mb-4">
                        <a className="bt-m" href="#" style={{ width: '300px', background: 'linear-gradient(248.1deg, #F8CA2B 27.1%, #F8CA2B 73.3%)', boxShadow: '0px 2px 10px #F8CA2B', borderRadius: '50px' }}>
                            <span className="bt-link" style={{ color: '#000000' }}><i className="fas fa-shopping-cart"></i> Pesan Sekarang</span>
                        </a>
                    </div>
                </div>
            </section>
        </React.Fragment >
    )
}

