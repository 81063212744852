import React, {useEffect} from "react";
import { Redirect, Route } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { fetchParticipantProfile } from '../store/actions';
import { verify } from "../store/actions/auth";

function ProtectedRoute({ component: Component, ...restOfProps }) {
  const token = localStorage.getItem("token");

  return (
    <Route
      {...restOfProps}
      render={(props) =>
        token ? <Component {...props} /> : <Redirect to="/signin" />
      }
    />
  );
}

export default ProtectedRoute;