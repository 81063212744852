import React, { useEffect, useState } from 'react';
import {
  SideBar
} from '../../components/layout/dashboard';
import { Link } from 'react-router-dom';
import { addCommas } from '../../helpers/utils'
import { BsFillPlayCircleFill } from 'react-icons/bs';
import EcourseService from '../../services/ecourse'
import axios from 'axios';

export default function LandingPage () {
  document.title = "INGENIO Indonesia";

  // Filtering data
  const [isLoading, setIsLoading] = useState(true);
  const [hasNext, setHasNext] = useState(false)
  const [hasPrev, setHasPrev] = useState(false)
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [keyword, setKeyword] = useState('');
  const [datas, setDatas] = useState([]);
  const [userId, setUserId] = useState('');

  const getListKelasTersedia = (params = '') => {
    setIsLoading(true)
    EcourseService.getListEcourseAvailableParticipant(params).then(resp => {
        if(resp.data.success === true) {
            const response = resp.data;
            setDatas(response.data);

            setPage(response.meta.page)
            setPerPage(response.meta.perPage)
            setCount(response.meta.count)

            setHasNext(response.meta.hasNext)
            setHasPrev(response.meta.hasPrev);
        }
        setIsLoading(false)
    }).catch(err => {
        setIsLoading(false)
        console.error(err)
    })
  }

  useEffect(() => {
      axios.get(`${process.env.REACT_APP_AUTHURL}me/student`, {
        headers: {
          Authorization: `Bearer ${JSON.parse(localStorage.getItem('token'))}`
        }
      }).then(resp => {
        if(resp.data.success === true){
          const id_user = resp.data.data.profile.user.id
          setUserId(id_user)
          getListKelasTersedia();
        }
      }).catch(err => {
        console.error(err)
      })
      
  }, [])

  const renderRating = (val) => {
    let stars = [];
    let unlistCount = 5-val
    for (let i = 1; i <= val; i++) {
        
      stars.push(<span class="fas fa-star active"></span>)
    }

    for (let j = 1; j <= unlistCount; j++) {
      stars.push(<span class="fas fa-star"></span>)
    }

    return stars;
  }

  const checkValidationUserId = (currentUserId, ecourseParticipants = []) => {
    for (let i = 0; i < ecourseParticipants.length; i++) {
        const data = ecourseParticipants[i];
        if(data.id_participant === currentUserId) {
            if(data.is_active) {
                return true
            }
        }
    }
    return false;
  }

  return(
    <React.Fragment>
      <SideBar />
      <div className="md:ml-64" style={{ backgroundColor: '#F3F4F6', minWidth: '100vh' }}>
        {/* <div className="bg-orange px-3 md:px-8 py-1" style={{height: '14vh'}}>
          <div className="container md:pr-8 md:pl-10">
            <h3 className="text-left tracking-wider title-dashboard">Course Tersedia</h3>
          </div>           
        </div> */}
        <div className="container md:pr-8 md:pl-10" style={{position: 'absolute', top: '80px'}}>
          <h3 className="text-left tracking-wider title-dashboard">Course Tersedia</h3>
        </div> 

        <div className="px-3 md:px-8 pb-5">
          <div className="container mx-auto max-w-full">
            <p className="sub-judul py-4 px-4">Pilih Akses Course yang Tersedia</p> <br />
            <div className="row">

              {
                isLoading ? 
                <span>Loading Data...</span>
                :
                datas.map((item, i) => {
                  return (<div className="col-lg-3">
                            <div className="card-16 courseCard">
                              <div className="imgContainer">
                                <img src={item.url_thumbnail} alt="Card Image" className="img-card" />
                                {
                                  checkValidationUserId(userId, item.ecourse_participants) ?
                                  <Link className="btnbeliCourse" to={{
                                        pathname: `/dashboard/course-saya/materi/${item.id}`,
                                        state: {
                                            kode_ecourse: item.kode_ecourse,
                                            ecourse_name: item.name,
                                        }
                                    }}>Akses Kelas</Link>
                                  :
                                  <Link className="btnbeliCourse" to={{
                                      pathname: `/dashboard/course-tersedia/beli`,
                                      state: {
                                          id: item.id,
                                          kode_ecourse: item.kode_ecourse,
                                          name: item.name,
                                          rate: item.rate,
                                          desc: item.desc,
                                          price: item.price,
                                      }
                                  }}>Beli</Link>
                                }
                                
                              </div>
                              <div className="content">
                                <div className="nama text-truncate">{item.name}</div>
                                <div className="borderBottom"></div>
                                <div className="desc truncate-2">{item.desc}</div>
                                <div className="justify-sb">
                                  <div className="harga">{`Rp ${addCommas(item.price)}`}</div>
                                  <div className="rating">
                                    {renderRating(Math.round(item.rate))}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>)
                })
              }

            </div>
          </div>
        </div>

      </div>
    </React.Fragment>
    
  )
}