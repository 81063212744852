import React, { useState, useEffect } from 'react';
import AkademikSidebar from '../../../../components/layout/dashboard/akademik/AkademikSideBar';
import { FormGroup, Form } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import Button from '@restart/ui/esm/Button';
import Icon from '@material-tailwind/react/Icon';
import { Modal } from 'react-bootstrap';
import { FaRegEdit, FaRegTrashAlt, FaTrash } from 'react-icons/fa';
import Swal from 'sweetalert2';

import * as Yup from 'yup';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';

import { connect } from 'react-redux';
import { getAllKompetensi, getAllProgram, deleteKompetensi, createKompetensi, updateKompetensi } from '../../../../store/actions/tryout';
import CustomDatatable from '../../../../components/common/CustomDatatable';
import TryoutService from '../../../../services/tryout'

function Kompetensi(props) {
  document.title = "INGENIO Indonesia";
  document.body.style.backgroundColor = "#f3f4f6";
  const [dataKompetensi, setDataKompetensi] = useState([]);
  const [editKompetensi, setEditKompetensi] = useState({});
  const [show, setShow] = useState(false);
  const [showEdit, setShowEdit] = useState(false);

  const [isLoading, setIsLoading] = useState(true);
  const [hasNext, setHasNext] = useState(false)
  const [hasPrev, setHasPrev] = useState(false)
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [keyword, setKeyword] = useState('');
  const [kelasData, setKelasData] = useState([]);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleCloseEdit = () => {
    setShowEdit(false);
    setEditKompetensi({})
  }
  const handleShowEdit = (payload) => {
    setShowEdit(true);
    console.log(payload);
    setEditKompetensi(payload);
    [
      {name: 'nama', value: payload.nama},
    ].forEach(({ name, value }) => setValueEdit(name, value))
  }

  // FILTERING DATA
  const [filterText, setFilterText] = React.useState('');
  const [filterObj, setFilterObj] = React.useState({
    text: '',
  })
  const onFilter = (data) => {
    setFilterText(data)
  }
  const onFilterSubmit = (e) => {
    e.preventDefault();
    let obj = {
      text: filterText,
    }
    setFilterObj(obj)
  }

  const filteredItems = dataKompetensi.filter(
		item => (item.nama && item.nama.toLowerCase().includes(filterObj.text.toLowerCase()))
	);
  // END FILTERING DATA

  const validationSchema = Yup.object().shape({
    nama: Yup.string()
      .required('Nama Kompetensi is required'),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(validationSchema) });

  const {
    register: registerEdit,
    handleSubmit: handleSubmitEdit,
    formState: { errors: errorsEdit },
    setValue: setValueEdit
  } = useForm({ resolver: yupResolver(validationSchema) });


  const { dispatch } = props;

  // FETCH
  useEffect(() => {
    getListKelas()
  }, [])

  // CREATE
  const onSubmit = (data) => {
    dispatch(createKompetensi(data))
      .then((response) => {
        handleClose();
        getListKelas()
      })
  };

  // UPDATE
  const onSubmitEdit = (data) => {
    dispatch(updateKompetensi(editKompetensi.id, data))
      .then((response) => {
        handleCloseEdit();
        getListKelas()
      })
  };

  // DELETE
  const toggleDelete = (id) => {
    Swal.fire({
      title: 'Anda Yakin?',
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'Kembali',
      confirmButtonText: 'Ya, Hapus!'
    })
      .then(async (result) => {
        if (result.isConfirmed) {
          try {
            await dispatch(deleteKompetensi(id))
            Swal.fire({  
              title: 'Data Berhasil Dihapus !',  
              text: '',  
              icon: 'success',  
              showCancelButton: false,              
              confirmButtonText: 'OK'  
              }).then((result) => {
                if (result.isConfirmed) {
                  getListKelas()
                }
              })
            
          }catch (err) {
            let msg = 'Internal Server Error'
            console.error(err)
            if(err.response) {
                if(err.response.data.message) {
                    msg = err.response.data.message
                }
            }

            Fail(msg)
          }
        }
      })
  }

  const dropdown = {
    borderRadius: "10px",
    height: 50,
    padding: "25px",
  };

  const customStyles = {
    table: {
      style: {
        borderRadius: '15px',
        border: '1px solid #BDC1C9',
      },
    },
    head: {
      style: {
        fontSize: '1.25rem',
        textTransform: "uppercase",
        color: '#6B7280',
        backgroundColor: '#f3f4f6',
        border: '1px solid #BDC1C9',
        borderTopLeftRadius: '15px',
        borderTopRightRadius: '15px',
      },
    },
    pagination: {
      style: {
        color: '#6B7280',
        fontSize: '1.1rem',
        minHeight: '56px',
        backgroundColor: '#f3f4f6',
        border: '1.25px solid #BDC1C9',
        borderBottomLeftRadius: '15px',
        borderBottomRightRadius: '15px',
      },
    },
  };

  const Fail = (message) => {
    Swal.fire({  
        title: 'Gagal!',  
        text: message,  
        icon: 'warning',  
        showCancelButton: true,  
        cancelButtonText: 'Kembali',           
        showConfirmButton: false,  
    });   
  }

  const columns = [
    {
        name: 'NO.',
        field: 'no',
        index: true,
        selector: row => row.no,
        sortable: true,
        minWidth: '1px'
    },
    {
        name: 'NAMA KOMPETENSI',
        render: true,
        component: (row) => {
            return (
                <td className='main-table__bodyRow'>{row.nama}</td>
            )
        },
        selector: row => row.tanggal,
        sortable: true,
        // width: '15vw',
    },
  {
      name: 'Aksi',
      render: true,
      component: (row) => {
          return (
              <td className='main-table__bodyRow'>
                  <div style={{display: 'flex', flexDirection: 'row'}}>
                  <Button
                    className="flex content-center items-center"
                    style={{ borderRadius: '50%', padding: '10px', color: 'black', background: '#F8CA2B' }}
                    onClick={() => { handleShowEdit(row) }}>
                    <FaRegEdit />
                  </Button>
                  <Button
                    className="flex content-center items-center"
                    style={{ borderRadius: '50%', padding: '10px', color: 'black', background: '#F8CA2B' }}
                    onClick={() => { toggleDelete(row.id) }} >
                    <FaTrash />
                  </Button >
                  </div>
              </td>
          )
      },
      selector: row => row.tanggal,
      sortable: true,
      // width: '15vw',
  },
];

const getListKelas = (params = '') => {
  setIsLoading(true)
  TryoutService.getAllKompetensi(params).then(resp => {
      const response = resp.data;
      setKelasData(response.data);

      setPage(response.meta.page)
      setPerPage(response.meta.perPage)
      setCount(response.meta.count)

      setHasNext(response.meta.hasNext)
      setHasPrev(response.meta.hasPrev);
      
      setIsLoading(false)
  }).catch(err => {
      let msg = 'Internal Server Error'
      setIsLoading(false)
      console.error(err)
      if(err.response) {
          if(err.response.data.message) {
              msg = err.response.data.message
          }
      }

      Fail(msg)
  })
}

  return (
    <React.Fragment>
      <AkademikSidebar judul={'Kelola Kompetensi'} />
      <div className="md:ml-64">
        <div className="bg-orange px-3 md:px-8 py-1" style={{ height: '2.5rem' }}>
          <div className="container md:pr-8 md:pl-10">
          </div>
        </div>

        <div className="px-3 md:px-8 mt-5">
          <div className="container mx-auto max-w-full">
            <div className="flex-2 flex-col">
              <div className="flex-2 mb-4">
                <div>
                  <div className="flex flex-row">
                    <div className="bg-white px-4 py-3 mr-3" style={{ borderRadius: '20px' }}>
                      <input
                        type="text"
                        name=""
                        style={{ backgroundColor: '#F3F4F6', width: '25rem', borderRadius: '12px', padding: '0.75rem', border: 'none !important', paddingLeft: '10px', fontFamily: 'Quicksand' }}
                        className="mr-3"
                        placeholder="Search..."
                        id=""
                        value={keyword}
		                  	onChange={(e) => setKeyword(e.target.value)}
                      />
                      <Button className="pilih-tryout mx-3" onClick={(e) => getListKelas(`page=${page}&perPage=${perPage}&keyword=${keyword}`)}>Cari</Button>
                      <Button className="pilih-tryout mx-3" onClick={handleShow}>
                        <Icon name="add" size="sm" />
                        Tambah Kompetensi
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex-2 mb-4">
                {/* <DataTable columns={columns} data={filteredItems} customStyles={customStyles} pagination /> */}
                <CustomDatatable 
                          filteredItems={kelasData} 
                          columns={columns}
                          isLoading={isLoading}
                          page={page}
                          perPage={perPage}
                          hasPrev={hasPrev}
                          hasNext={hasNext}
                          count={count}
                          onTableChange={(e, data) => {
                              switch (e) {
                              case 'rowPerPageChange':
                                  setPerPage(data.perPage)
                                  getListKelas(`page=${page}&perPage=${data.perPage}&keyword=${keyword}`)
                                  break;
                              case 'rowPageChange':
                                  setPage(data.page)
                                  getListKelas(`page=${data.page}&perPage=${perPage}&keyword=${keyword}`)
                                  break;
                              
                              default:
                                  break;
                              }
                          }}
                      />
              </div>
            </div>
            <Modal show={show} onHide={handleClose}>
              <Modal.Header closeButton>
                <Modal.Title>Tambah Kompetensi</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <form key={1} onSubmit={handleSubmit(onSubmit)}>
                  <input type="text" {...register('nama')} placeholder="Nama Kompetensi" id="" className="mb-4" style={{ backgroundColor: '#F3F4F6', width: '100%', borderRadius: '8px', border: 'none !important', height: '45px', paddingLeft: '10px' }} /> <br />
                  <span class="text-xs text-danger">{errors.nama?.message}</span>

                  <Button
                    className="bg-success mx-2"
                    type="submit"
                    style={{ borderRadius: '5px', color: 'white', padding: '5px', float: 'right' }}>
                    Submit
                  </Button>
                  <Button
                    variant="secondary"
                    onClick={handleClose}
                    style={{ padding: '5px', float: 'right' }}>
                    Batal
                  </Button>
                </form>
              </Modal.Body>
            </Modal>
            <Modal show={showEdit} onHide={handleCloseEdit}>
              <Modal.Header closeButton>
                <Modal.Title>Edit Kompetensi</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <form key={1} onSubmit={handleSubmitEdit(onSubmitEdit)}>
                  <input type="text" {...registerEdit('nama')} id="" className="mb-4" style={{ backgroundColor: '#F3F4F6', width: '100%', borderRadius: '8px', border: 'none !important', height: '45px', paddingLeft: '10px' }} /> <br />
                  <span class="text-xs text-danger">{errorsEdit.nama?.message}</span>
                  <Button
                    className="bg-success mx-2"
                    type="submit"
                    style={{ borderRadius: '5px', color: 'white', padding: '5px', float: 'right' }}>
                    Submit
                  </Button>
                  <Button
                    variant="secondary"
                    onClick={handleCloseEdit}
                    style={{ padding: '5px', float: 'right' }}>
                    Batal
                  </Button>
                </form>
              </Modal.Body>
            </Modal>
          </div>
        </div>

      </div>
    </React.Fragment>

  )
}

export default connect()(Kompetensi)