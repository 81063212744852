import React, { useEffect } from 'react';
import { useState } from 'react';
import { useParams, useHistory, Link } from "react-router-dom";
import { FormGroup, FormControl, Form, Modal } from 'react-bootstrap';
import Button from '@material-tailwind/react/Button';
// import InputIcon from '@material-tailwind/react/InputIcon'
import { BsPlusCircle, BsList, BsPen, BsFillTrashFill } from 'react-icons/bs';
import { BiSave } from 'react-icons/bi';
import { FaPen, FaClock, FaUser, FaPlay } from 'react-icons/fa';
// import AkademikSidebar from '../../../components/layout/dashboard/akademik/AkademikSideBar';
import TutorSidebar from '../../../components/layout/dashboard/tutor/TutorSideBar';
import ElearningService from '../../../services/elearning'
import Swal from 'sweetalert2';
import moment from 'moment';
import { useLocation } from 'react-router';

import { connect } from "react-redux";
import { createSubtryout, getAllProgram, getSubtryoutByTO, getTryoutDetail, deleteSubtryout, aktivasi } from "../../../store/actions/tryout";

export default function MateriEditor(props) {
    document.title = "INGENIO Indonesia";
    document.body.style.backgroundColor = "#F3F4F6";
    const [showModalUpload, setShowModalUpload] = useState(false)
    
    const [isUpload, setIsUpload] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(0.0);
    

    const [programs, setPrograms] = useState({});
    const [dataTryout, setDataTryout] = useState({})
    const [dataSubTryout, setDataSubTryout] = useState([]);
    const [isTambah, setIsTambah] = useState(false);
    const [namaMateri, setNamaMateri] = useState('');

    const [materiId, setMateriId] = useState(null);
    const [slideFile, setSlideFile] = useState(null);
    const [videoFile, setVideoFile] = useState(null);

    const location = useLocation();
    const { nama_kelas, jadwal, tutor } = location.state;
    var baseUrl = window.location.origin;

    // FILTERING DATA
    const [isLoading, setIsLoading] = useState(false);
    const [materiJadwalKelas, setMateriJadwalKelas] = useState([])
    // END FILTERING DATA

    const { dispatch } = props;
    let { kelasjadwalid } = useParams();

    const history = useHistory();

    const uploadMateriContentJadwalKelas = () => {
        
        if((!videoFile === null ||slideFile === null) && (!videoFile || videoFile === null)){
            Fail('Document / Video harus diisi salah satu')
            return;
        }
        if(slideFile && slideFile !== null) {
            const slideFileType = slideFile.name.split('.').pop();
            const slideFilesize = slideFile.size;
            if(!'ppt,pptx'.split(',').includes(slideFileType.toLowerCase())){ // Slide file validation
                Fail('Materi Slide harus (ppt / pptx)');
                return;
            }

            if(slideFilesize > 150000000) {
                Fail('Materi Slide harus kurang dari 150 MB');
                return;
            }
        }

        if(videoFile && videoFile !== null) {
            const videoFileType = videoFile.name.split('.').pop();
            const videoFilesize = videoFile.size;
            if(!'mp4'.split(',').includes(videoFileType.toLowerCase())){ // Slide file validation
                Fail('Materi Video harus (mp4');
                return;
            }

            if(videoFilesize > 1000000000) {
                Fail('Materi Video harus kurang dari 1 GB');
                return;
            }
        }
        
        
        setIsLoading(true)
        let formData = new FormData();
        formData.append('nama_materi', 'sample material');

        if(slideFile && slideFile !== null)
            formData.append('docs', slideFile);
        if(videoFile && videoFile !== null)
            formData.append('video', videoFile);

        ElearningService.uploadMateriContentJadwalKelasTutor(formData, materiId, kelasjadwalid, (progressEvent) => {
            const progressValue = (progressEvent.loaded / progressEvent.total) * 100;
            setUploadProgress(progressValue)

        })
        .then(resp => {
            const response = resp.data;
            getListMateriJadwalKelas();

            setIsLoading(false)
            setShowModalUpload(false)
            setUploadProgress(0.0)

        }).catch(err => {
            setIsLoading(false)
            setShowModalUpload(false)
            setUploadProgress(0.0)
            console.error(err)
        })
    }

    const getListMateriJadwalKelas = (params = '') => {
        setIsLoading(true)
        ElearningService.getListMateriJadwalKelasTutor(params, kelasjadwalid).then(resp => {
            const response = resp.data;
            setMateriJadwalKelas(response.data)

            setIsLoading(false)

        }).catch(err => {
            setIsLoading(false)
            console.error(err)
        })
    }

    const deleteMateriJadwalKelas = (materiId) => {
        Swal.fire({
            title: 'Anda Yakin?',
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: 'Kembali',
            confirmButtonText: 'Ya, Hapus!'
        })
            .then((result) => {
                if (result.isConfirmed) {
                    setIsLoading(true)
                    ElearningService.deleteMateriJadwalKelasAkademik(materiId, kelasjadwalid).then(resp => {
                        getListMateriJadwalKelas();
                        setIsLoading(false)

                    }).catch(err => {
                        setIsLoading(false)
                        console.error(err)
                    })
                }
            });
        
    }

    const addMateriJadwalKelas = (body) => {
        setIsLoading(true)
        ElearningService.addMateriJadwalKelasAkademik(body, kelasjadwalid).then(resp => {
            getListMateriJadwalKelas();
            setIsLoading(false)
            setIsTambah(false)

        }).catch(err => {
            setIsLoading(false)
            console.error(err)
        })
    }

    const deleteSub = (idsub) => {
        Swal.fire({
            title: 'Anda Yakin?',
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: 'Kembali',
            confirmButtonText: 'Ya, Hapus!'
        })
            .then((result) => {
                if (result.isConfirmed) {
                    dispatch(deleteSubtryout(idsub))
                        .then((res) => {
                            window.location.reload();
                        })
                }
            });
    }


    function submitSubTO() {

    }

    const AreYouSure = (data) => {
        Swal.fire({
            title: 'Anda Yakin?',
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: 'Kembali',
            confirmButtonText: data ? 'Ya, Aktivasi!' : 'Ya, Deaktivasi!',
        }).then((result) => {
            if (result.IsConfirmed) {

            }
        });
    }

    const Succeed = (message, onConfirm) => {
        Swal.fire({  
            title: 'Berhasil!',  
            text: message,  
            icon: 'success',  
            showCancelButton: false,              
            confirmButtonText: 'OK'  
        }).then((result) => {
          if (result.isConfirmed) {
            onConfirm()
          }
        })
    }
    
    const Fail = (message) => {
        Swal.fire({  
            title: 'Gagal!',  
            text: message,  
            icon: 'warning',  
            showCancelButton: true,  
            cancelButtonText: 'Kembali',           
            showConfirmButton: false,  
        });   
    }

    useEffect(() => {
        getListMateriJadwalKelas();
    }, [])

    return (
        <React.Fragment>
            <TutorSidebar judul={"Detail Materi - " + nama_kelas} />
            <div className="md:ml-64" style={{ backgroundColor: '#F3F4F6' }}>
                <div className="bg-orange px-3 md:px-8 py-1 h-10">
                    <div className="container md:pr-8 md:pl-10">
                    </div>
                </div>
                <div className="px-3 md:px-8 mt-5">
                    <div className="container mx-auto flex sm:flex-col-reverse md:flex-row max-w-full">
                        <div className="flex-auto mb-4">
                            <div className="row">
                                <div className="col-xs-12 col-md-6 col-lg-6">
                                    <form onSubmit={(e) => {}}>
                                        <div className="row" style={{ backgroundColor: '#FFF', padding: '1rem', borderRadius: '1rem' }}>
                                            <div className="col-8">
                                                <input
                                                    type="text"
                                                    name=""
                                                    style={{
                                                        backgroundColor: '#F3F4F6',
                                                        width: '100%',
                                                        borderRadius: '8px',
                                                        border: 'none !important',
                                                        height: '45px',
                                                        paddingLeft: '10px'
                                                    }}
                                                    placeholder="&#x1F50E;&#xFE0E; Nama Materi"
                                                    id=""
                                                    value={''}
                                                    onChange={(e) => {}}
                                                />
                                            </div>
                                            <div className="col-4">
                                                <Button type="submit" className="pilih-tryout" onClick={(e) => {}}>Cari</Button>
                                            </div>

                                        </div>

                                    </form>
                                </div>
                                <div className="col-xs-12 col-md-5 sm:text-center " style={{ padding: '1rem' }}>
                                    {
                                        isLoading ? 
                                        null
                                        :
                                        <button
                                            className="text-md rounded-lg font-bold px-3 py-2 flex items-center focus:outline-none"
                                            style={{ color: '#F8CA2B', fontFamily: 'Quicksand', backgroundColor: '#Fff' }}
                                            onClick={() => setIsTambah(true)}
                                        >
                                            Tambah Materi
                                            <span className="pl-4" style={{ color: '#F8CA2B' }}>
                                                <BsPlusCircle style={{ stroke: "#F8CA2B", strokeWidth: "0.5" }} />
                                            </span>
                                        </button>
                                    }
                                    
                                </div>
                                {
                                    materiJadwalKelas.length < 1 && !isTambah ? <div className="col-12">
                                        <div className="container md:pr-0 md:pl-0 py-4 my-3">
                                            <h3 className="text-left tracking-wider title-dashboard" style={{ fontSize: '1rem', fontStyle: 'Quicksand', color: '#6B7280' }}>Data tidak ditemukan, silahkan tambah materi.</h3>
                                        </div>
                                    </div> : null
                                }
                                {materiJadwalKelas.map((item, i) => {
                                    return (
                                        <div key={i} className="mt-3" >
                                            <div className="max-w-full md:pr-0 md:pl-0" style={{ paddingRight: '3.5rem' }}>
                                                <FormGroup>
                                                    <div className="flex flex-row" style={{ backgroundColor: '#FFFF', padding: '1rem', border: '1px solid #BDC1C9', borderTopLeftRadius: '15px', borderTopRightRadius: '15px' }}>
                                                        <div className="flex flex-1" style={{ padding: '1rem' }}>
                                                            <h1
                                                                style={{ fontFamily: 'Quicksand' }}
                                                            >
                                                                {item.materi.nama_materi}
                                                            </h1>
                                                        </div>
                                                        <div className="flex flex-row">
                                                            <div className="flex mr-2">
                                                                {
                                                                    item.materi.materi_contents.length > 0 ?
                                                                        (item.materi.is_content_ready === 'processing_content') ? 
                                                                        <span>Konten sedang di proses, mohon menunggu beberapa saat...</span>
                                                                        :
                                                                        <button
                                                                            class="bg-white hover:bg-blue-light font-bold py-2 px-4 hover:border-blue rounded ml-2 flex flex-row items-center"
                                                                            style={{ border: '2px solid #9ca3af', fontFamily: 'Quicksand', color: "#000" }}
                                                                            onClick={() => {
                                                                                setMateriId(item.id_materi)
                                                                                setShowModalUpload(!showModalUpload)
                                                                            }}>
                                                                            <BsPen size="1rem" className="mr-2" /> Update Konten Materi
                                                                        </button>
                                                                    :
                                                                    (item.materi.is_content_ready === 'processing_content') ? 
                                                                        <span>Konten sedang di proses, mohon menunggu beberapa saat...</span>
                                                                        :
                                                                        <button
                                                                            class="bg-white hover:bg-blue-light font-bold py-2 px-4 hover:border-blue rounded ml-2 flex flex-row items-center"
                                                                            style={{ border: '2px solid #9ca3af', fontFamily: 'Quicksand', color: "#000" }}
                                                                            onClick={() => {
                                                                                setMateriId(item.id_materi)
                                                                                setShowModalUpload(!showModalUpload)
                                                                            }}>
                                                                            <BsPen size="1rem" className="mr-2" /> Upload Konten Materi Baru
                                                                        </button>

                                                                }
                                                                
                                                            </div>
                                                            <div className="flex mr-2">
                                                                {
                                                                    item.materi.is_content_ready === 'processing_content' ?
                                                                    null
                                                                    :
                                                                    <button
                                                                        title="Delete Materi"
                                                                        class="bg-white hover:bg-blue-light font-bold py-2 px-4 hover:border-blue rounded ml-2 flex flex-row items-center"
                                                                        style={{ border: '2px solid #9ca3af', fontFamily: 'Quicksand', color: "#000" }}
                                                                        onClick={() => deleteMateriJadwalKelas(item.id_materi)}>
                                                                        <BsFillTrashFill />
                                                                    </button>
                                                                }
                                                                
                                                            </div>
                                                            {
                                                                item.materi.materi_contents.length > 0 ?
                                                                (item.materi.is_content_ready === 'processing_content') ? 
                                                                    null
                                                                    :
                                                                    <div className="flex mr-2">
                                                                        <Link
                                                                            title="Preview"
                                                                            className="bg-white hover:bg-blue-light font-bold py-2 px-4 hover:border-blue rounded ml-2 flex flex-row items-center"
                                                                            style={{ border: '2px solid #9ca3af', fontFamily: 'Quicksand', color: "#000" }}
                                                                            to={{
                                                                                pathname: `/dashboard/tutor/upload-materi/materi-editor/kelas-jadwal/${kelasjadwalid}/materi/${item.id_materi}/preview`,
                                                                                state: {
                                                                                    nama_kelas, 
                                                                                    jadwal, 
                                                                                    tutor
                                                                                }
                                                                            }}
                                                                            // to={`/dashboard/akademik/e-learning/materi-preview/kelas-jadwal/${kelasjadwalid}/materi/${item.id_materi}`}
                                                                        >
                                                                            <FaPlay size="1rem" className="mr-2" />
                                                                        </Link>
                                                                    </div>
                                                                :
                                                                null
                                                            }
                                                        </div>
                                                    </div>
                                                </FormGroup>
                                                <div className="py-2 px-3 bg-gray font-medium" style={{ fontFamily: 'Quicksand', border: '1px solid #BDC1C9', borderBottomLeftRadius: '15px', borderBottomRightRadius: '15px' }}>
                                                    <div class="flex justify-between">
                                                        <div class="flex flex-row">
                                                            {
                                                                item.materi.materi_contents.length > 0 ?
                                                                <h5
                                                                    className="false flex items-center justify-center gap-1 outline-none tracking-wider focus:outline-none focus:shadow-none transition-all duration-300 rounded-lg py-1.5 px-3 leading-normal shadow-none hover:bg-white hover:border-black hover:text-black undefined mr-3"
                                                                    style={{ position: 'relative', overflow: 'hidden', fontFamily: 'Quicksand', color: "#6B7280" }}
                                                                >
                                                                    Konten Slide: <b>{item.materi.materi_contents.filter((content) => {
                                                                        return content.content_type === 'docs'
                                                                    }).length} Slide</b>
                                                                </h5>
                                                                :
                                                                <h5
                                                                    className="false flex items-center justify-center gap-1 outline-none tracking-wider focus:outline-none focus:shadow-none transition-all duration-300 rounded-lg py-1.5 px-3 leading-normal shadow-none hover:bg-white hover:border-black hover:text-black undefined mr-3"
                                                                    style={{ position: 'relative', overflow: 'hidden', fontFamily: 'Quicksand', color: "#6B7280" }}
                                                                >
                                                                    Konten Slide: <b>{'0 Slide'}</b>
                                                                </h5>
                                                            }
                                                            
                                                        </div>
                                                    </div>
                                                    <div class="flex justify-between">
                                                        <div class="flex flex-row">
                                                        {
                                                                item.materi.materi_contents.length > 0 ?
                                                                <h5
                                                                    className="false flex items-center justify-center gap-1 outline-none tracking-wider focus:outline-none focus:shadow-none transition-all duration-300 rounded-lg py-1.5 px-3 leading-normal shadow-none hover:bg-white hover:border-black hover:text-black undefined mr-3"
                                                                    style={{ position: 'relative', overflow: 'hidden', fontFamily: 'Quicksand', color: "#6B7280" }}
                                                                >
                                                                    Konten Video: <b>{item.materi.materi_contents.filter((content) => {
                                                                        return content.content_type === 'video'
                                                                    }).length}</b>
                                                                </h5>
                                                                :
                                                                <h5
                                                                    className="false flex items-center justify-center gap-1 outline-none tracking-wider focus:outline-none focus:shadow-none transition-all duration-300 rounded-lg py-1.5 px-3 leading-normal shadow-none hover:bg-white hover:border-black hover:text-black undefined mr-3"
                                                                    style={{ position: 'relative', overflow: 'hidden', fontFamily: 'Quicksand', color: "#6B7280" }}
                                                                >
                                                                    Konten Video: <b>{'0'}</b>
                                                                </h5>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                                {isTambah ? <div className="mt-3" style={{ paddingRight: '10rem' }}>
                                    <div className="max-w-full md:pr-0 md:pl-0">
                                        <div className="flex flex-row" style={{ backgroundColor: '#FFFF', padding: '1rem', borderRadius: '15px' }}>
                                            <div className="flex flex-1">
                                                <input
                                                    type="text"
                                                    onChange={(e) => { setNamaMateri(e.target.value); }}
                                                    value={namaMateri}
                                                    className="w-100"
                                                    style={{ backgroundColor: '#F3F4F6', borderRadius: '8px', border: 'none !important', paddingLeft: '10px' }}
                                                    placeholder="Masukkan Nama Materi"
                                                    id=""
                                                />
                                            </div>
                                            <div className="flex flex-row">
                                                <button
                                                    class="bg-green hover:bg-blue-light text-white font-bold py-2 px-4 hover:border-blue rounded ml-2 flex flex-row"
                                                    style={{ borderBottom: '4px solid #22C55E' }}
                                                    onClick={() => {
                                                        const body = {
                                                            nama_materi: namaMateri
                                                        }
                                                        addMateriJadwalKelas(body)
                                                    }}
                                                >
                                                    <BiSave size="1.5rem" className="mr-2" />Save
                                                </button>
                                                <button
                                                    class="hover:bg-blue-light font-bold py-2 px-4 hover:border-blue rounded ml-2"
                                                    style={{ borderBottom: '4px solid #9ca3af', color: "#9ca3af", backgroundColor: '#F7F6F6' }}
                                                    onClick={() => setIsTambah(false)}
                                                >
                                                    Cancel
                                                </button>
                                            </div>

                                        </div>
                                    </div>
                                </div> : null}
                            </div>
                        </div>
                        <div className="flex-auto mb-4">
                            <div className="max-w-full rounded-xl border-solid border-2 bg-transparent mb-4" style={{ borderColor: '#9CA3AF', minWidth: '270px' }}>
                                <div className="p-4 flex flex-col justify-between leading-normal">
                                    <div className="mb-3">
                                        <div className="flex justify-between text-xl mb-2" style={{ fontFamily: 'Quicksand', fontWeight: 800 }}>
                                        </div>
                                        <div
                                            className="text-base mb-2"
                                            style={{ color: '#F8CA2B', fontFamily: 'Quicksand', fontWeight: '600' }}
                                        >
                                            Jadwal Kelas
                                        </div>
                                        <button
                                            className="flex justify-between text-base mb-4"
                                            style={{ color: '#F8CA2B', fontFamily: 'Quicksand', fontWeight: '600', cursor: 'auto' }}
                                        >
                                            <span class="flex">
                                                <FaClock style={{ color: '#F8CA2B', width: '1.25em', height: '1.25em' }} className="mr-2" />
                                                {jadwal}
                                            </span>
                                        </button>
                                        <hr className="w-100 mb-4" />
                                        <div
                                            className="flex justify-between text-base mb-4"
                                            style={{ color: '#F8CA2B', fontFamily: 'Quicksand', fontWeight: '600' }}
                                        >
                                            <span class="flex">
                                                <FaUser style={{ color: '#F8CA2B', width: '1.25em', height: '1.25em' }} className="mr-2" />
                                                {tutor}
                                            </span>
                                        </div>
                                        <div className="w-100 px-3">
                                            {/* {
                                                dataTryout.is_active ? <Button
                                                    className="pilih-tryout p-2"
                                                    style={{ width: "100%" }}
                                                    onClick={() => AreYouSure(false)}
                                                >Deaktivasi</Button> :
                                                    <Button
                                                        className="pilih-tryout p-2"
                                                        style={{ width: "100%" }}
                                                        onClick={() => AreYouSure(true)}
                                                    >Aktivasi</Button>
                                            } */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Modal size="lg" show={showModalUpload} onHide={() => {
                    setShowModalUpload(!showModalUpload)
                }}>
                    <Modal.Header closeButton>
                        <Modal.Title><strong>Upload Materi</strong></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="form-group p-5">
                            <div className="row">
                                <div className="col-12 lg:px-4">
                                    <label htmlFor="" className="label-tryout">Slide Materi (ppt / pptx) max. 150 MB</label> <br />
                                    <Form.Control onChange={(e) => {
                                        setSlideFile(e.target.files[0]);
                                    }} type="file" name="" className="form-tryout mb-3" id="" />
                                </div>
                                <div className="col-12 lg:px-4">
                                    <label htmlFor="" className="label-tryout">Video Materi (mp4) max. 1 GB</label> <br />
                                    <Form.Control onChange={(e) => {
                                        setVideoFile(e.target.files[0])
                                    }} type="file" name="" className="form-tryout mb-3" id="" />
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button className="btn btn-dark" style={{ backgroundColor: '#00' }} onClick={() => {
                            setShowModalUpload(!showModalUpload)
                        }}>
                            Batal
                        </Button>
                        <Button disabled={isLoading} className="btn btn-success" onClick={() => {
                            // updateKelas()
                            uploadMateriContentJadwalKelas()
                        }}>
                            {isLoading ? `Uploading...(${Math.round(uploadProgress)} %)` : 'Upload'}
                        </Button>
                    </Modal.Footer>
                </Modal>


            </div >
        </React.Fragment >
    )
}