import React, {useState, useEffect} from 'react';
// import styles from '../../index.module.css';
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import { ArticleCard } from './index';

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import 'swiper/components/scrollbar/scrollbar.scss';

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y ]);

export default function ArticleSlider(props) {

  const [isMobile, setIsMobile] = useState(3)
 
  //choose the screen size 
  const handleResize = () => {
    if (window.innerWidth < 720) {
        setIsMobile(1)
    } else {
        setIsMobile(3)
    }
  }

  useEffect(() => {
    window.addEventListener("resize", handleResize)
  })

  return (
    <Swiper
      id="main"
      loop
      watchOverflow
      observer={'true'}
      spaceBetween={25}
      slidesPerView={isMobile}
      breakpoints={{
        768: {
          slidesPerView: 3,
        },
      }}
      navigation
      scrollbar={{ draggable: true }}
      onSlideChange={() => console.log('slide change')}
    >
      <SwiperSlide>
        <ArticleCard />
      </SwiperSlide>
      <SwiperSlide>
        <ArticleCard />
      </SwiperSlide>
      <SwiperSlide>
        <ArticleCard />
      </SwiperSlide>
    </Swiper>
  )
}