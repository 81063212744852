import React, { useEffect, useState } from 'react';
import {
  SideBar
} from '../../components/layout/dashboard';
import { FormGroup, FormControl, Form } from 'react-bootstrap';
import { useParams, useHistory } from "react-router-dom";
import { Link, useLocation } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import Button from '@restart/ui/esm/Button';
import InputIcon from '@material-tailwind/react/InputIcon';
import axios from 'axios';
import { addCommas } from '../../helpers/utils';
import moment from 'moment';

export default function MyTryOut() {
  document.title = "INGENIO Indonesia";
  const [program, setProgram] = useState('');
  const [transaction, setTransaction] = useState([])
  const [myTryout, setMyTryout] = useState([]);
  const tx_black = {
    color: 'black !important'
  }
  const jenisTransactionTypeRender = (transactionItems) => {
    if(transactionItems.tryout !== null) {
      return 'Tryout'
    }

    if(transactionItems.elearning !== null) {
      return 'E-learning'
    }
  }
  const columns = [
    {
        name: 'No.',
        selector: (row, idx) => (idx+1),
        sortable: true,
    },
    {
      name: 'Jenis Transaksi',
      selector: row => row.transaction_type,
      sortable: true,
    },
    {
      name: 'Kode Transaksi',
      selector: row => (row.transaction_items.length > 0) ? row.transaction_items[0].item_name : '',
      sortable: true,
    },
    {
      name: 'Tanggal Transaksi',
      selector: row => `${moment(row.transaction_at).format('LL')}`,
      sortable: true,
    },
    {
      name: 'Harga',
      selector: row => `Rp ${addCommas(row.subtotal)}`,
      sortable: true,
    },
    {
      name: 'Status',
      selector: (row) => row.transaction_status,
      conditionalCellStyles: [
        {
          when: row => row.transaction_status === 'pending',
          style: {
            backgroundColor: '#EAB308',
            borderRadius: '20px',
            color: 'white',
            '&:hover': {
              cursor: 'pointer',
            },
            textAlign: 'center'
          },
        },
        {
          when: row => row.transaction_status === 'success',
          style: {
            backgroundColor: '#22C55E',
            borderRadius: '20px',
            color: 'white',
            '&:hover': {
              cursor: 'pointer',
            },
            textAlign: 'center'
          },
        },
        {
          when: row => row.transaction_status === 'failed',
          style: {
            backgroundColor: '#EF4444',
            borderRadius: '20px',
            color: 'white',
            '&:hover': {
              cursor: 'not-allowed',
            },
            textAlign: 'center'
          },
        },
      ],
      sortable: true,
    },
    {
      cell: (e) => e.transaction_status === 'success' ? <Link className="pilih-tryout p-2" to={`/dashboard/payment-process/sukses?transaction_id=${e.id}`}>Detail</Link> : <Link className="pilih-tryout p-2" to={`/dashboard/payment-process?transaction_id=${e.id}`}>Detail</Link>,
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];
  const data = [
    {
      no: 1,
      paket: 'Tryout 1 Lorem ipsum dolor.',
      kode: 'AAA',
      program: 'Program 1',
      tryout: [
        {
          harga: '100.000'
        }
      ]
    },
  ];

  function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
  }

  const query = useQuery()

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_TRYOUTURL}transaction/participant/get`, {
      headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem('token'))}`
      }
    }).then(resp => {
      console.log(resp);
      setTransaction(resp.data.data)
    }).catch(err => {
      console.error(err)
    })
  }, [])

  return (
    <React.Fragment>
      <SideBar judul={'Daftar Transaksi'}/>
      <div className="md:ml-64">
        <div className="bg-orange px-3 md:px-8 py-1" style={{height: '2.5rem'}}>
          <div className="container md:pr-8 md:pl-10">
          </div>
        </div>

        <div className="py-5 px-3 md:px-8">
          <div className="container mx-auto max-w-full">
            <div className="row">
              <div className="col-md-8 col-lg-8 col-xs-12 mb-4">
                <FormGroup>
                  <div className="row">
                    <div className="col-3">
                      <Form.Select
                        as="select"
                        value={program}
                        aria-label="Pembayaran"
                        aria-describedby="basic-addon1"
                        className="my-tryout"
                      >
                        <option value="">Semua Pembayaran</option>
                        <option value="1">Pembayaran A</option>
                        <option value="2">Pembayaran B</option>
                        <option value="3">Pembayaran C</option>
                      </Form.Select>
                    </div>
                  </div>
                </FormGroup>
              </div>
              <div className="col-md-12 col-lg-12 col-xs-12 px-4">
                <DataTable columns={columns} data={transaction} pagination />
              </div>
            </div>
          </div>
        </div>

      </div>
    </React.Fragment>

  )
}