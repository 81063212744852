import React from 'react';
import {
    ProgramUkmp2dg,
} from '../components/programukmp2dg';
import {
    IngenioFooterPurple,
    IngenioNavPurple
} from '../components/layout'

export default function ProgramUkmp2dgPage() {
    return (
        <React.Fragment>
            <IngenioNavPurple />
            <div style={{ backgroundColor: 'white' }}>
                <ProgramUkmp2dg />
            </div>
            <IngenioFooterPurple />
        </React.Fragment>

    )
}