import React from "react";
import { connect, useSelector } from "react-redux";

const AccessControl = ({
  role = "",
  isProfileComplete,
  children,
  renderNoAccess = () => null
}) => {
  return children
};

export default AccessControl;