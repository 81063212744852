import React from 'react';
import {
  SideBar
} from '../../components/layout/dashboard';
import Button from '@material-tailwind/react/Button';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { BsCartFill } from 'react-icons/bs';
import { useLocation } from 'react-router';
import { Link, useParams } from 'react-router-dom';
import { addCommas } from '../../helpers/utils'


export default function BuyCourse () {
  document.title = "INGENIO Indonesia";

  const location = useLocation();
  const { id, kode_ecourse, name, price, rate, desc } = location.state;

  const renderRating = (val) => {
    let stars = [];
    let unlistCount = 5-val
    for (let i = 1; i <= val; i++) {
        
      stars.push(<span class="fas fa-star active"></span>)
    }

    for (let j = 1; j <= unlistCount; j++) {
      stars.push(<span class="fas fa-star"></span>)
    }

    return stars;
  }

  return(
    <React.Fragment>
      <SideBar />
      <div className="md:ml-64" style={{ backgroundColor: '#F3F4F6', minWidth: '100vh' }}>
        {/* <div className="bg-orange px-3 md:px-8 py-1" style={{height: '14vh'}}>
          <div className="container md:pr-8 md:pl-10">
            <h3 className="text-left tracking-wider title-dashboard">Pembelian Course</h3>
          </div>           
        </div> */}

        <div className="container md:pr-8 md:pl-10" style={{position: 'absolute', top: '80px'}}>
          <h3 className="text-left tracking-wider title-dashboard">Pembelian Course</h3>
        </div>

        <div className="px-3 md:px-8 pb-5">
          <div className="container mx-auto max-w-full">
            <div className="row">
              <div className="col-12 mb-4">
                <Breadcrumb className="breadcrumbss my-4">
                  <Breadcrumb.Item href="/dashboard/course-tersedia">E-Course Tersedia</Breadcrumb.Item>
                  <Breadcrumb.Item active><span style={{fontWeight: 'bold'}}>Pembelian E-Course</span></Breadcrumb.Item>
                </Breadcrumb>
              </div>
              <div className="col-12">
                <div className="cards pd-InJurnal">
                  <div className="row">
                    <div className="col-12 mb-4">
                      <div className="title-20-700">Detail E-Course</div>
                      <div className="borderBottomGrey"></div>
                    </div>
                    <div className="col-12 BuyCourse">
                      <div className="flex mt-2">
                        <div className="name">Nama E-Course</div>
                        <div className="space">:</div>
                        <div className="desc">{name}</div>
                      </div>
                      <div className="flex mt-2">
                        <div className="name">Harga E-Course</div>
                        <div className="space">:</div>
                        <div className="desc">{`Rp ${addCommas(price)}`}</div>
                      </div>
                      <div className="flex mt-2">
                        <div className="name">Rating</div>
                        <div className="space">:</div>
                        <div className="desc rating flex">
                          {renderRating(Math.round(rate))}
                        </div>
                      </div>
                      <div className="flex mt-2">
                        <div className="name">Deskripsi</div>
                        <div className="space">:</div>
                        <div className="desc">{desc}</div>
                      </div>
                      <div className="addDesc">Saya telah yakin dan setuju dengan ketentuan kelas e-course dan melakukan pembayaran sebelum e-course dimulai.</div>
                      
                      <div className="justify-start mt-3">
                        <Button type="submit" className="btnOrange3">
                          <Link to={{
                                      pathname: `/dashboard/course-tersedia/beli/pembayaran`,
                                      state: {
                                          id,
                                          kode_ecourse,
                                          name,
                                          rate,
                                          desc,
                                          price,
                                      }
                                  }}>
                            <span className="txt-btn flex gap-2"><BsCartFill size="20px" style={{marginTop: '2px'}} />Bayar E-Course</span>
                          </Link>
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </React.Fragment>
    
  )
}