export const addCommas = (nStr) => {
    if(!nStr || nStr === null)
        return ''
  
    if((nStr+'').trim() === ''){
        return ''
    }
    nStr += '';
    var x = nStr.split(',');
      var	x1 = x[0];
    var x2 = x.length > 1 ? ',' + x[1] : '';
    var rgx = /(\d+)(\d{3})/;
    while (rgx.test(x1)) {
      x1 = x1.replace(rgx, '$1' + '.' + '$2');
    }
    return x1 + x2;
}

export const timeValidation = (values) => {
  const parsedTime = values.split(':');
  if(parsedTime.length < 2 || parsedTime.length > 2)
    return false;

  const hours = parsedTime[0];
  const minutes = parsedTime[1];

  if(hours.length < 2 || hours.length > 2)
    return false;

  if(minutes.length < 2 || minutes.length > 2)
    return false;

  if(parseInt(hours) > 23)
    return false;
  
  return true;
}

export const fileValidation = (ext) => {
  const fileAllowed = ['jpg', 'jpeg', 'png'];
  if(fileAllowed.includes(ext.toLowerCase())){
    return true
  }

  return false;
}

export const filePdfValidation = (ext) => {
  const fileAllowed = ['pdf'];
  if(fileAllowed.includes(ext.toLowerCase())){
    return true
  }

  return false;
}

export const contactLink = () => {
  return 'https://web.whatsapp.com/send?phone=6281358889123&text=Halo, saya ingin bertanya'
}
  