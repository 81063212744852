import React from 'react';
import {
  Hero, 
  About, 
  Program, 
  Kurikulum, 
  Kelebihan, 
  Pengalaman, 
  Testimoni,
  Legalitas,
  Prestasi,
  Article,
} from '../components/landing';
import {
  IngenioNav,
  IngenioFooter
} from '../components/layout'

export default function LandingPage () {
  document.title = "INGENIO Indonesia";
  return(
    <React.Fragment>
      <IngenioNav />
        <div style={{backgroundColor: 'black', paddingTop: '7.5vh'}}>
          <Hero />
          <About />
          <Program />
          <Kurikulum />
          <Kelebihan />
          <Pengalaman />
          <Testimoni />
          <Legalitas />
          <Prestasi />
          <Article />
        </div>
      <IngenioFooter bgColor="black"/>
    </React.Fragment>
    
  )
}