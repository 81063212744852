import React from 'react';
import GallerySlider1 from './GallerySlider1';
import GallerySlider2 from './GallerySlider2';
import GalleryPlayer from './GalleryPlayer'

export default function Gallery(props) {

    return (
        <React.Fragment>
            <section className="container-fluid">
                <div className="site-header site-header--menu-left landing-11-menu site-header--absolute" style={{ backgroundImage: 'url(/assets/landing/Genio2.png)', backgroundPositionY: 'bottom', backgroundPositionX: 'right', backgroundRepeat: 'no-repeat', paddingTop: '6rem', backgroundColor: '#ffcc00' }}>
                    <h5 className="text-center tentang_title py-5" style={{ paddingBottom: '2rem !important' }}>{props.title}</h5>
                </div>
            </section>
            {
                props.type === 'baksos' ? 
                <section className="container-fluid bg-desktop" style={{ backgroundColor: '#FFF' }}>
                    <div className="container px-4" style={{ paddingTop: '18rem', width: '75%' }}>
                        <div className="row align-items-center">
                            <GalleryPlayer url="https://ingenioindonesia.com/public/assets/event/new-baksos-vid.mp4" />
                        </div>
                    </div>
                </section>
                :
                null
            }

            {
                props.type === 'event' ? 
                <section className="container-fluid bg-desktop" style={{ backgroundColor: '#FFF' }}>
                    <div className="container px-4" style={{ paddingTop: '18rem', width: '75%' }}>
                        <div className="row align-items-center">
                            <GalleryPlayer url="https://ingenioindonesia.com/public/assets/new-event/new-event-vid.mp4" />
                        </div>
                    </div>
                </section>
                :
                null
            }
            
            {
                props.type === 'baksos' ? 
                <section className="container-fluid bg-desktop" style={{ backgroundColor: '#FFF' }}>
                    <div className="container px-4" style={{ paddingTop: '18rem', width: '100%', paddingBottom: '2rem' }}>
                        <div className="row align-items-center">
                            <GallerySlider1
                                interval={5000}
                                images={[
                                    'https://ingenioindonesia.com/public/assets/event/new-baksos-1.jpg',
                                    'https://ingenioindonesia.com/public/assets/event/new-baksos-2.jpg',
                                    'https://ingenioindonesia.com/public/assets/event/new-baksos-3.jpg',
                                    'https://ingenioindonesia.com/public/assets/event/new-baksos-4.jpg',
                                    'https://ingenioindonesia.com/public/assets/event/new-baksos-5.jpg',
                                    'https://ingenioindonesia.com/public/assets/event/new-baksos-6.jpg',
                                    'https://ingenioindonesia.com/public/assets/event/new-baksos-7.jpg',
                                    'https://ingenioindonesia.com/public/assets/event/new-baksos-8.jpg',
                                    'https://ingenioindonesia.com/public/assets/event/new-baksos-9.jpg',
                                    'https://ingenioindonesia.com/public/assets/event/new-baksos-10.jpg',
                                    'https://ingenioindonesia.com/public/assets/event/new-baksos-11.jpg',
                                    'https://ingenioindonesia.com/public/assets/event/new-baksos-12.jpg',
                                    'https://ingenioindonesia.com/public/assets/event/new-baksos-13.jpg',
                                ]}
                            />
                        </div>
                    </div>
                </section>
                :
                null
            }
            {
                props.type === 'event' ? 
                <section className="container-fluid bg-desktop" style={{ backgroundColor: '#FFF' }}>
                    <div className="container px-4" style={{ paddingTop: '18rem', width: '100%', paddingBottom: '2rem' }}>
                        <div className="row align-items-center">
                            <GallerySlider1
                                interval={5000}
                                images={[
                                    // 'https://ingenioindonesia.com/public/assets/new-event/konas-1/new-konas-1-1.jpg',
                                    'https://ingenioindonesia.com/public/assets/new-event/konas-1/new-konas-1-2.jpg',
                                    'https://ingenioindonesia.com/public/assets/new-event/konas-1/new-konas-1-3.jpg',
                                    'https://ingenioindonesia.com/public/assets/new-event/konas-1/new-konas-1-4.jpg',

                                    'https://ingenioindonesia.com/public/assets/new-event/konas-2/new-konas-2-1.jpg',
                                    'https://ingenioindonesia.com/public/assets/new-event/konas-2/new-konas-2-2.jpg',
                                    'https://ingenioindonesia.com/public/assets/new-event/konas-2/new-konas-2-3.jpg',

                                    // 'https://ingenioindonesia.com/public/assets/new-event/konas-3/new-konas-3-1.jpg',
                                    'https://ingenioindonesia.com/public/assets/new-event/konas-3/new-konas-3-2.jpg',
                                    'https://ingenioindonesia.com/public/assets/new-event/konas-3/new-konas-3-3.jpg',
                                    'https://ingenioindonesia.com/public/assets/new-event/konas-3/new-konas-3-4.jpg',
                                    // 'https://ingenioindonesia.com/public/assets/new-event/konas-3/new-konas-3-5.jpg',
                                    // 'https://ingenioindonesia.com/public/assets/new-event/konas-3/new-konas-3-6.jpg',

                                    'https://ingenioindonesia.com/public/assets/new-event/konas-4/new-konas-4-1.jpg',
                                    'https://ingenioindonesia.com/public/assets/new-event/konas-4/new-konas-4-2.jpg',
                                    'https://ingenioindonesia.com/public/assets/new-event/konas-4/new-konas-4-3.jpg',
                                    'https://ingenioindonesia.com/public/assets/new-event/konas-4/new-konas-4-4.jpg',
                                    'https://ingenioindonesia.com/public/assets/new-event/konas-4/new-konas-4-5.jpg',
                                    // 'https://ingenioindonesia.com/public/assets/new-event/konas-4/new-konas-4-6.jpg',
                                ]}
                            />
                        </div>
                    </div>
                </section>
                :
                null
            }
        </React.Fragment >
    )
}

