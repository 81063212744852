import React from 'react';
import ArtikelSlider from './ArtikelSlider';

export default function Artikel() {

    return (
        <React.Fragment>
            <section className="container-fluid">
                <div className="site-header site-header--menu-left landing-11-menu site-header--absolute" style={{ backgroundImage: 'url(/assets/landing/Genio2.png)', backgroundPositionY: 'bottom', backgroundPositionX: 'right', backgroundRepeat: 'no-repeat', paddingTop: '6rem', backgroundColor: '#ffcc00' }}>
                    <h5 className="text-center tentang_title py-5" style={{ paddingBottom: '2rem !important' }}>ARTIKEL</h5>
                </div>
            </section>
            <section className="container-fluid bg-desktop" style={{ backgroundImage: 'url(/assets/landing/bg-value.png)' }}>
                <div className="container px-4" style={{ paddingTop: '19rem', width: '95%' }}>
                    <div className="row g-3 mb-5">

                        <ArtikelSlider />

                    </div>
                </div>
            </section>
        </React.Fragment >
    )
}

