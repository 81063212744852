import React from 'react';
import { useState, useEffect } from 'react';
import AdminSideBar from '../../../../components/layout/dashboard/admin/AdminSideBar';
import Button from '@material-tailwind/react/Button';
import { FormGroup, Modal } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import { Link } from 'react-router-dom';
import { GrFormView } from 'react-icons/gr';
import { MdOutlineNoteAdd, MdOutlineAddCircleOutline } from 'react-icons/md';
import { HiOutlineDownload, HiOutlineTrash } from 'react-icons/hi';
import { addCommas } from '../../../../helpers/utils'
import EcourseService from '../../../../services/ecourse';
import CustomDatatable from '../../../../components/common/CustomDatatable';
import moment from 'moment-timezone';
import Swal from 'sweetalert2';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

export default function InformasiEcourseAdmin () {
  document.title = "INGENIO Indonesia";
  const [showSidebar, setShowSidebar] = useState('-left-64');
  var baseUrl = window.location.origin;

  // Filtering data
  const [isLoading, setIsLoading] = useState(true);
  const [hasNext, setHasNext] = useState(false)
  const [hasPrev, setHasPrev] = useState(false)
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [keyword, setKeyword] = useState('');
  const [kelasData, setKelasData] = useState([]);

  const columns = [
    {
        name: 'NO.',
        field: 'no',
        index: true,
        selector: row => row.no,
        sortable: true,
        minWidth: '1px'
        
    },
    {
        name: 'KODE E-COURSE',
        field: 'kode_ecourse',
        selector: row => row.kelas,
        sortable: true,
        
    },
    {
        name: 'NAMA E-COURSE',
        field: 'name',
        selector: row => row.regio,
        sortable: true,
         
    },
    {
      name: 'HARGA',
      field: 'price',
      render: true,
        component: (row) => {
            return (
                <td className='main-table__bodyRow'>{`Rp ${addCommas(row.price)}`}</td>
            )
        },
      selector: row => row.regio,
      sortable: true,
       
  },
    {
        name: 'JUMLAH PESERTA',
        field: 'kapasitas',
        render: true,
        component: (row) => {
            return (
                <td className='main-table__bodyRow'>{`${row.ecourse_participants.filter(x => x.is_active === true).length} peserta`}</td>
            )
        },
        selector: row => row.kapasitas,
        sortable: true,
        
    },
    {
      name: 'RATING',
      field: 'kapasitas',
      render: true,
      component: (row) => {
          return (
              <td className='main-table__bodyRow'>
                <div className="rating">
                  {renderRating(Math.round(row.rate))}
                </div>
              </td>
          )
      },
      selector: row => row.kapasitas,
      sortable: true,
      
  },
];

  const renderRating = (val) => {
    let stars = [];
    let unlistCount = 5-val
    for (let i = 1; i <= val; i++) {
        
      stars.push(<span class="fas fa-star active"></span>)
    }

    for (let j = 1; j <= unlistCount; j++) {
      stars.push(<span class="fas fa-star"></span>)
    }

    return stars;
  }
  const data = [
    {
        no: 1,
        kode: '11223344',
        nama: 'lorem',
        harga: 'Rp. 100.000',
        jumlah: '150 Peserta',
        // rating: <span class="fas fa-star active"></span><span class="fas fa-star active"></span>,
        komentar: 'Lorem ipsum dolor.',
    },
  ];

  const customStyles = {
      table: {
        style: {
          borderRadius: '15px',
          border: '1px solid #BDC1C9',
        },
      },
      head: {
        style: {
          fontSize: '1.25rem',
          textTransform: "uppercase",
          color: '#6B7280',
          backgroundColor: '#f3f4f6',
          border: '1px solid #BDC1C9',
          borderTopLeftRadius: '15px',
          borderTopRightRadius: '15px',
        },
      },
      pagination: {
        style: {
          color: '#6B7280',
          fontSize: '1.1rem',
          minHeight: '56px',
          backgroundColor: '#f3f4f6',
          border: '1.25px solid #BDC1C9',
          borderBottomLeftRadius: '15px',
          borderBottomRightRadius: '15px',
        },
      },
  };

  const AreYouSure = () => {
      Swal.fire({  
          title: 'Sudah Yakin?',  
          text: 'Apakah Anda Yakin Ingin Menghapus Data Ini?',  
          icon: 'warning',  
          showCancelButton: true,  
          cancelButtonText: 'Tidak',  
          confirmButtonText: 'Ya'  
      })
  }

  const Succeed = () => {
      Swal.fire({  
          title: 'Data Berhasil Tersimpan !',  
          text: '',  
          icon: 'success',  
          showCancelButton: false,              
          confirmButtonText: 'OK'  
      }).then((result) => {
        if (result.isConfirmed) {
          window.location.reload();
        }
      })
  }

  const getListKelas = (params = '') => {
    setIsLoading(true)
    EcourseService.getListEcourseAkademik(params).then(resp => {
        const response = resp.data;
        setKelasData(response.data);

        setPage(response.meta.page)
        setPerPage(response.meta.perPage)
        setCount(response.meta.count)

        setHasNext(response.meta.hasNext)
        setHasPrev(response.meta.hasPrev);
        
        setIsLoading(false)
    }).catch(err => {
        let msg = 'Internal Server Error'
          setIsLoading(false)
          console.error(err)
          if(err.response) {
            if(err.response.data.message) {
              msg = err.response.data.message
            }
          }

          Fail(msg)
    })
  }

  const Fail = (message) => {
      Swal.fire({  
          title: 'Gagal!',  
          text: message,  
          icon: 'warning',  
          showCancelButton: true,  
          cancelButtonText: 'Kembali',           
          showConfirmButton: false,  
      });   
  }

  useEffect(() => {
      getListKelas();
  }, [])

  return(
    <React.Fragment>
      <AdminSideBar />
      <div className="md:ml-64 styleHead" style={{ backgroundColor: '#F3F4F6' }}>
        <div className="bg-orange md:px-8 pb-5" style={{height: '20vh', position: 'relative', padding: '0 30px 30px 30px'}}>
            <h1 className="tracking-wider title-24-600">Informasi E-Course</h1>
            {/* <h2 className="tracking-wider title-20-700 mt-5">Selamat Datang Tutor INGENIO !</h2> */}
            <img src={baseUrl + "/assets/logo/evaluasikelas.png"} className="img-opacity"></img>
        </div>

        <div className="px-3 md:px-8 pb-5">
            <div className="container mx-auto max-w-full">
                <div className="row mt-5">
                  <div className="col-12 my-2">
                    <FormGroup>
                      <div className="justify-start">
                        <div className="SearchTable">
                        <input value={keyword} onChange={(e) => setKeyword(e.target.value)} type="text" name="" className="inputSearchTable" placeholder="&#x1F50E;&#xFE0E; Search..." id="" />
                          <Button onClick={() => {
                                  getListKelas(`page=${page}&perPage=${perPage}&keyword=${keyword}`)
                              }} className="btnSearchTable">Cari</Button>
                        </div>
                      </div>
                    </FormGroup>
                  </div>
                  <div className="col-12 mt-3">
                      {/* <DataTable columns={columns} customStyles={customStyles} data={data} pagination /> */}
                      <CustomDatatable 
                          filteredItems={kelasData} 
                          columns={columns}
                          isLoading={isLoading}
                          page={page}
                          perPage={perPage}
                          hasPrev={hasPrev}
                          hasNext={hasNext}
                          count={count}
                          onTableChange={(e, data) => {
                              switch (e) {
                              case 'rowPerPageChange':
                                  setPerPage(data.perPage)
                                  getListKelas(`page=${page}&perPage=${data.perPage}&keyword=${keyword}`)
                                  break;
                              case 'rowPageChange':
                                  setPage(data.page)
                                  getListKelas(`page=${data.page}&perPage=${perPage}&keyword=${keyword}`)
                                  break;
                              
                              default:
                                  break;
                              }
                          }}
                      />
                  </div>
                </div>
            </div>
        </div>

      </div>
    </React.Fragment>
    
  )
}