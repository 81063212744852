import React, { useState, useEffect } from 'react';

export default function GallerySlider1({ images = [], interval = 3000 }) {
    const [thumbnails, setThumnails] = useState([]);
    const [previousSlideStyle, setPreviousSlideStyle] = useState({});
    const [currentSlide, setCurrentSlide] = useState(0);
    const [nextSlideStyle, setNextSlideStyle] = useState({});
    const [currentSlideStyle, setCurrentSlideStyle] = useState({});

    useEffect(() => {
        setThumnails(images);
        setCurrentSlideStyle({
            backgroundImage: "url('" + images[currentSlide] + "')"
        });

        if (currentSlide > 0) {
            setPreviousSlideStyle({
                backgroundImage: "url('" + images[currentSlide - 1] + "')"
            });
        } else {
            setPreviousSlideStyle({
                backgroundImage: "url('" + images[images.length - 1] + "')"
            });
        }

        if (currentSlide === images.length - 1) {
            setNextSlideStyle({
                backgroundImage: "url('" + images[0] + "')"
            });
        } else {
            setNextSlideStyle({
                backgroundImage: "url('" + images[currentSlide + 1] + "')"
            });
        }

        const loop = setInterval(() => {
            if (currentSlide === images.length - 1) {
                setCurrentSlide(0);
            } else {
                setCurrentSlide(currentSlide + 1);
            }
        }, interval);
        return () => clearInterval(loop);
    }, [images, currentSlide, interval]);

    function previous() {
        if (currentSlide > 0) {
            setCurrentSlide(currentSlide - 1);
        } else {
            setCurrentSlide(thumbnails.length - 1);
        }
    }

    function next() {
        if (currentSlide === thumbnails.length - 1) {
            setCurrentSlide(0);
        } else {
            setCurrentSlide(currentSlide + 1);
        }
    }
    return (
        <React.Fragment>
            <section className="slideshow-gal d-none d-lg-block">
                <div className="slide-holder-gal">
                    <section className="slide-gal previous-slide-gal">
                        <div style={previousSlideStyle} className="slide-thumbnail-gal"></div>
                    </section>
                    <section className="slide-gal current-slide-gal">
                        <div style={currentSlideStyle} className="slide-thumbnail-gal"></div>
                    </section>
                    <section className="slide-gal next-slide-gal">
                        <div style={nextSlideStyle} className="slide-thumbnail-gal"></div>
                    </section>
                </div>

                <div className="slideshow-controller-gal">
                    <span onClick={previous}><img src="/assets/landing/arrow-left.png"></img></span>
                    <span onClick={next}><img src="/assets/landing/arrow-right.png"></img></span>
                </div>
            </section>
            <section className="slideshow-gal d-lg-none d-lg-none">
                <div className="slide-holder-gal">
                    <section className="slide-gal previous-slide-gal">
                        <div style={previousSlideStyle} className="slide-thumbnail-gal"></div>
                    </section>
                    {/* <section className="slide-gal current-slide-gal">
                        <div style={currentSlideStyle} className="slide-thumbnail-gal"></div>
                    </section>
                    <section className="slide-gal next-slide-gal">
                        <div style={nextSlideStyle} className="slide-thumbnail-gal"></div>
                    </section> */}
                </div>

                <div className="slideshow-controller-gal" style={{ width: '40%' }}>
                    <span onClick={previous}><img src="/assets/landing/arrow-left.png"></img></span>
                    <span onClick={next}><img src="/assets/landing/arrow-right.png"></img></span>
                </div>
            </section>
        </React.Fragment >
    )
}

