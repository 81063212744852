import React, { useState, useEffect } from 'react';
import { Col, Form, FormGroup, Row, Button } from 'react-bootstrap';
import { BsArrowLeftShort, BsFillGeoAltFill, BsPlusCircle } from 'react-icons/bs';
import DataTable from 'react-data-table-component';
import { Link, useHistory, useParams } from 'react-router-dom';
import { SideBar } from '../../../components/layout/dashboard';
import { contactLink } from '../../../helpers/utils'
import axios from 'axios';
import ElearningService from '../../../services/elearning';
import CustomDatatable from '../../../components/common/CustomDatatable';
import moment from 'moment-timezone';
import Swal from 'sweetalert2';

export default function InfoKelas() {
    document.title = "INGENIO Indonesia";
    var baseUrl = window.location.origin;

    // Filtering data
    const [isLoading, setIsLoading] = useState(true);
    const [hasNext, setHasNext] = useState(false)
    const [hasPrev, setHasPrev] = useState(false)
    const [count, setCount] = useState(0);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [keyword, setKeyword] = useState('');
    const [userId, setUserId] = useState('');
    const [kelasJadwal, setKelasJadwal] = useState([])
    const [elearningPrograms, setElearningPrograms] = useState([])
    const [idElearningProgram, setIdElearningProgram] = useState('');

    const [program, setProgram] = useState('');

    const { id } = useParams();

    const dropdown = {
        borderRadius: "10px",
        height: 50,
        padding: "25px",
    };

    const checkingEvaluasiHasFilled = (evaluasiKelases, userId) => {
        for (let i = 0; i < evaluasiKelases.length; i++) {
            const evaluasiKelas = evaluasiKelases[i];
            for (let j = 0; j < evaluasiKelas.evaluasi_kelas_details.length; j++) {
                const evaluasiKelasDetail = evaluasiKelas.evaluasi_kelas_details[j];
                if(evaluasiKelasDetail.id_participant === userId) {
                    return true;
                }
            }
        }

        return false;
    }
    const columns = [
        {
            name: 'NO.',
            field: 'no',
            index: true,
            selector: row => row.no,
            sortable: true,
            width: '6.2vw',
        },
        {
            name: 'NAMA MATERI',
            field: 'nama_materi',
            selector: row => row.namamateri,
            sortable: true,
            // width: '15vw',
        },
        {
            name: 'NAMA TUTOR',
            field: 'nama_tutor',
            selector: row => row.namatutor,
            sortable: true,
        },
        {
            render: true,
            component: (row) => {
                return (
                    <td className='main-table__bodyRow'>
                        {
                            checkingEvaluasiHasFilled(row.evaluasi_kelases, userId) ?
                            <Link className="btnTable" style={{backgroundColor: 'grey'}} to="#">Isi Evaluasi</Link>
                            :
                            <Link className="btnTable"
                                to={{
                                    pathname: `/dashboard/e-learning/isi-evaluasi`,
                                    state: {
                                        id_kelas_jadwal: row.id,
                                        nama_kelas: row.kelas.nama_kelas,
                                        nama_program: row.kelas.elearning_program.nama_program,
                                        nama_regio: row.kelas.nama_regio,
                                        nama_tutor: row.nama_tutor,
                                        nama_materi: row.nama_materi,
                                    }
                                }}>Isi Evaluasi</Link>
                        }
                        
                        {/* {
                            row.kelas.is_active ?
                            <Link className="pilih-tryout p-2 text-center m-1"
                                to={{
                                    pathname: `/dashboard/e-learning/kelas-saya/details/${row.kelas.id}`,
                                    state: {
                                        nama_program: row.kelas.elearning_program.nama_program,
                                        nama_regio: row.kelas.nama_regio,
                                        nama_kelas: row.kelas.nama_kelas
                                    }
                                }}>
                                Akses Kelas
                            </Link>
                            :
                            <Button onClick={() => {
                                Swal.fire({
                                    title: 'Kelas tidak aktif',  
                                    text: 'Kelas tidak aktif, silahkan menghubungi administrator',  
                                    icon: 'warning',  
                                    showCancelButton: true,  
                                    cancelButtonText: 'Kembali',           
                                    showConfirmButton: false,  
                                  }); 
                            }} className="pilih-tryout-disable">Akses Kelas</Button>
                            
                        } */}
                        
                    </td>
                )
            },
            minWidth: '190px',
            cell: () => <Link className="pilih-tryout p-2 text-center m-1" to="/dashboard/e-learning/kelas-saya/details">Akses Kelas</Link>,
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
    ];
    const data = [
        {
            no: 1,
            nama: 'Lorem ipsum dolor.',
            materi: 'Materi A',
        },
    ];

    const customStyles = {
        table: {
          style: {
            borderRadius: '15px',
            border: '1px solid #BDC1C9',
          },
        },
        head: {
          style: {
            fontSize: '1.25rem',
            textTransform: "uppercase",
            color: '#6B7280',
            backgroundColor: '#f3f4f6',
            border: '1px solid #BDC1C9',
            borderTopLeftRadius: '15px',
            borderTopRightRadius: '15px',
          },
        },
        pagination: {
          style: {
            color: '#6B7280',
            fontSize: '1.1rem',
            minHeight: '56px',
            backgroundColor: '#f3f4f6',
            border: '1.25px solid #BDC1C9',
            borderBottomLeftRadius: '15px',
            borderBottomRightRadius: '15px',
          },
        },
    };

    const getListKelasJadwal = (params = '') => {
        setIsLoading(true)
        ElearningService.getListJadwalKelasForEvaluasiParticipant(id, params).then(resp => {
            if(resp.data.success === true) {
                const response = resp.data;
                setKelasJadwal(response.data);

                setPage(response.meta.page)
                setPerPage(response.meta.perPage)
                setCount(response.meta.count)

                setHasNext(response.meta.hasNext)
                setHasPrev(response.meta.hasPrev);

                if(response.meta.userId) {
                    setUserId(response.meta.userId)
                }
            }
            setIsLoading(false)
        }).catch(err => {
            setIsLoading(false)
            console.error(err)
        })
    }

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_AUTHURL}me/student`, {
            headers: {
              Authorization: `Bearer ${JSON.parse(localStorage.getItem('token'))}`
            }
          }).then(resp => {
            if(resp.data.success === true){
              const id_user = resp.data.data.profile.user.id
              setUserId(id_user)
              getListKelasJadwal();
            }
          }).catch(err => {
            console.error(err)
          })
        
    }, [])

    return (
        <React.Fragment>
            <SideBar />
            <div className="md:ml-64 tutorDataKelas" style={{ backgroundColor: '#F3F4F6' }}>
                {/* <div className="bg-orange md:px-8" style={{height: '220px', position: 'relative', padding: '0 30px'}}>
                    <h1 className="tracking-wider title-24-600" style={{marginTop: '-20px'}}>Informasi Kelas</h1><br />
                    <h3 className="text-left tracking-wider" style={{lineHeight: '25px'}}>Anda dapat menghubungi Admin terlebih dahulu untuk memastikan kelas dan program<br /> yang Anda daftarkan. <br /></h3>
                    <img src={baseUrl + "/assets/logo/evaluasikelas.png"} className="img-opacity"></img>
                </div> */}

                <div className="container md:pr-8 md:pl-10"  style={{position: 'absolute', top: '80px'}}>
                    <h1 className="tracking-wider title-24-600" style={{marginTop: '-20px'}}>Informasi Kelas</h1><br />
                    <h3 className="text-left tracking-wider" style={{lineHeight: '25px'}}>Anda dapat menghubungi Admin terlebih dahulu untuk memastikan kelas dan program<br /> yang Anda daftarkan. <br /></h3>
                </div> 

                <div className="px-3 md:px-8 mt-4">
                    <div className="container mx-auto max-w-full">
                        <div className="justify-start mt-4 mb-3">
                            <Link to="/dashboard/e-learning/evaluasi-kelas">
                                <div className="navBack">
                                    <BsArrowLeftShort size="19px"/>Kembali
                                </div> 
                            </Link>
                        </div>
                        <div className="row">
                            <div className="col-12 mt-3 mb-4">
                                <FormGroup>
                                    <div className="SearchTable gap-3">
                                        <input value={keyword}
                                            onChange={(e) => setKeyword(e.target.value)} type="text" name="" className="inputSearchTable" placeholder="&#x1F50E;&#xFE0E; Search..." id="" />
                                        <Button onClick={() => {
                                            getListKelasJadwal(`page=${page}&perPage=${perPage}&keyword=${keyword}`)
                                        }} className="btnSearchTable">Cari</Button>
                                    </div>
                                </FormGroup>
                            </div>
                        </div>
                        <div className="col-12 mt-3 mb-5">
                            {/* <DataTable columns={columns} customStyles={customStyles} data={data} pagination /> */}
                            <CustomDatatable 
                                filteredItems={kelasJadwal} 
                                columns={columns}
                                isLoading={isLoading}
                                page={page}
                                perPage={perPage}
                                hasPrev={hasPrev}
                                hasNext={hasNext}
                                count={count}
                                onTableChange={(e, data) => {
                                    switch (e) {
                                    case 'rowPerPageChange':
                                        setPerPage(data.perPage)
                                        getListKelasJadwal(`page=${page}&perPage=${data.perPage}&keyword=${keyword}&program=${program}`)
                                        break;
                                    case 'rowPageChange':
                                        setPage(data.page)
                                        getListKelasJadwal(`page=${data.page}&perPage=${perPage}&keyword=${keyword}&program=${program}`)
                                        break;
                                    
                                    default:
                                        break;
                                    }
                                }}
                            />
                        </div>
                    </div>
                </div>

            </div>
        </React.Fragment >

    )
}