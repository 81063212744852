import React from 'react'
import styles from '../../index.module.css';
import { FasilitasCard } from './index';

export default function Fasilitas(props) {
    let baseURL = window.location.origin;

    let dataPrograms = [{
        id: 1,
        img: '/assets/landing/fas1.png',
        color: 'linear-gradient(180deg, #F8CA2B 0%, #FCA826 100%)',
        colorName: 'orange',
        text: 'Tutor handal, berpengalaman dan terstandarisasi nasional',
    },
    {
        id: 2,
        img: '/assets/landing/fas2.png',
        color: 'linear-gradient(180deg, #F8CA2B 0%, #FCA826 100%)',
        colorName: 'orange',
        text: 'Fasilitas kelas online via Zoom untuk seluruh peserta se-Indonesia',
    },
    {
        id: 3,
        img: '/assets/landing/fas3.png',
        color: 'linear-gradient(180deg, #F8CA2B 0%, #FCA826 100%)',
        colorName: 'orange',
        text: 'Kelas offline dehngan protokol kesehatan yang sesuai SOP',
    },
    {
        id: 4,
        img: '/assets/landing/fas4.png',
        color: 'linear-gradient(180deg, #F8CA2B 0%, #FCA826 100%)',
        colorName: 'orange',
        text: 'Materi UKMPPD original ala Ingenio yang telah tercatat ISBN di perpusnas  dan sebagai Hak Kekayaan Intelektual di Kemenkumham Republik Indonesia',
    },
    {
        id: 5,
        img: '/assets/landing/fas5.png',
        color: 'linear-gradient(180deg, #F8CA2B 0%, #FCA826 100%)',
        colorName: 'orange',
        text: 'Fasilitas tambahan pada program bimbingan tertentu dengan dilengkapi slingbag, buku eksklusif materi yang colourfull dan kaos eksklusif Ingenio',
    },
    {
        id: 6,
        img: '/assets/landing/fas6.png',
        color: 'linear-gradient(180deg, #F8CA2B 0%, #FCA826 100%)',
        colorName: 'orange',
        text: 'Silabus preklinik dan bimbingan  dokter muda disesuaikan dengan kurikulum kampus  atau stase masing-masing',
    },
    {
        id: 7,
        img: '/assets/landing/fas7.png',
        color: 'linear-gradient(180deg, #F8CA2B 0%, #FCA826 100%)',
        colorName: 'orange',
        text: 'Suasana bimbingan yang fun, sistematis dan interaktif',
    },
    {
        id: 8,
        img: '/assets/landing/fas8.png',
        color: 'linear-gradient(180deg, #F8CA2B 0%, #FCA826 100%)',
        colorName: 'orange',
        text: 'Silabus materi uji kompetensi disesuaikan dengan standar uji kompetensi dan guideline terbaru',
    },
    {
        id: 9,
        img: '/assets/landing/fas9.png',
        color: 'linear-gradient(180deg, #F8CA2B 0%, #FCA826 100%)',
        colorName: 'orange',
        text: 'Kelas offline dengan infokus dan ruangan nyaman ber-AC',
    },
    {
        id: 10,
        img: '/assets/landing/fas10.png',
        color: 'linear-gradient(180deg, #F8CA2B 0%, #FCA826 100%)',
        colorName: 'orange',
        text: 'Alat dan manekin simulasi OSCE yang lengkap',
    },
    {
        id: 11,
        img: '/assets/landing/fas11.png',
        color: 'linear-gradient(180deg, #F8CA2B 0%, #FCA826 100%)',
        colorName: 'orange',
        text: 'Konsultasi tutor selama masa bimbingan',
    },
    {
        id: 12,
        img: '/assets/landing/fas12.png',
        color: 'linear-gradient(180deg, #F8CA2B 0%, #FCA826 100%)',
        colorName: 'orange',
        text: 'Harga bimbingan yang terjangkau',
    }
    ]

    return (
        <React.Fragment>
            <section className="container-fluid">
                    <div className="site-header site-header--menu-left landing-11-menu site-header--absolute" style={{ backgroundImage: 'url(/assets/landing/Genio2.png)', backgroundPositionY: 'bottom', backgroundPositionX: 'right', backgroundRepeat: 'no-repeat', paddingTop: '6rem', backgroundColor: '#ffcc00' }}>
                        <h5 className="tentang_title py-5" style={{paddingBottom: '2rem !important'}}>FASILITAS</h5>
                    </div>
            </section>
            <section className="container-fluid bg-desktop" style={{ backgroundImage: 'url(/assets/landing/bg-value.png)' }}>
                <div className="container px-4 pb-5" style={{ paddingTop: '10rem', width: '85%' }}>
                    <div className="row g-2" style={{ textAlign: 'justify', paddingTop: '5rem' }}>
                        <p className="ct pt-5 pb-4" style={{ color: '#000000' }}>
                            Program Bimbingan di Ingenio Indonesia didukung oleh berbagai macam fasilitas yang akan mempermudah kamu untuk belajar sesuai kebutuhanmu :
                        </p>
                    </div>
                    <div className="row align-items-center gy-5">
                        {dataPrograms.map(program => <div className="col-sm-6 col-lg-4"><FasilitasCard key={program.id} data={program} /></div>)}
                    </div>
                    <div className="row align-items-center gy-5 py-1 my-4">
                        <div className="col-sm-6 col-lg-6">
                            <img src="/assets/landing/fas-ukmppd.png" className="zoom" style={{ marginLeft: 'auto', marginRight: 'auto' }} />
                        </div>
                        <div className="col-sm-6 col-lg-6">
                            <img src="/assets/landing/fas-peserta.png" className="zoom" style={{ marginLeft: 'auto', marginRight: 'auto' }} />
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment>
    )
}