import { useLocation, Link } from 'react-router-dom';
import React, { useEffect } from 'react';

import Button from '@material-tailwind/react/Button';
import Icon from '@material-tailwind/react/Icon';
import NavbarInput from '@material-tailwind/react/NavbarInput';
import Image from '@material-tailwind/react/Image';
import Dropdown from '@material-tailwind/react/Dropdown';
import DropdownItem from '@material-tailwind/react/DropdownItem';
import AuthService from '../../../../services/auth'
// import ProfilePicture from '../../../assets/img/team-1-800x800.jpg';

export default function AdminNav2({ showSidebar, setShowSidebar, judul }) {
    const location = useLocation().pathname;

    var baseUrl = window.location.origin; 
    return (
        <nav className="bg-orange md:ml-64 py-6 px-3">
            <div className="container max-w-full mx-auto flex items-center justify-between md:pr-8 md:pl-10">
                <div className="md:hidden">
                    <Button
                        color="transparent"
                        buttonType="link"
                        size="lg"
                        iconOnly
                        rounded
                        ripple="light"
                        onClick={() => setShowSidebar('left-0')}
                    >
                        <Icon name="menu" size="2xl" color="white" />
                    </Button>
                    <div
                        className={`absolute top-2 md:hidden ${showSidebar === 'left-0' ? 'left-64' : '-left-64'
                            } z-50 transition-all duration-300`}
                    >
                        <Button
                            color="transparent"
                            buttonType="link"
                            size="lg"
                            iconOnly
                            rounded
                            ripple="light"
                            onClick={() => setShowSidebar('-left-64')}
                        >
                            <Icon name="close" size="2xl" color="white" />
                        </Button>
                    </div>
                </div>

                <div className="flex justify-between items-center w-full">
                    <h4 className="judul-page text-black tracking-wider mt-1" style={{fontWeight: 500}}>
                        {judul}
                    </h4>

                    <div className="flex">
                        {/* <NavbarInput placeholder="Search" /> */}

                        <div>
                            <Dropdown
                                color="transparent"
                                buttonText={
                                    <div className="w-12" style={{ display: 'inline-flex' }}>
                                        <Image src="https://thumbs.dreamstime.com/b/user-icon-flat-style-person-icon-user-icon-web-site-user-icon-vector-illustration-user-icon-flat-style-person-icon-user-131140097.jpg" rounded />
                                        {/* <p className="d-xs-none">
                                            Farah A'idina <br />
                                            farahaidina24@gmail.com
                                        </p> */}
                                    </div>
                                }
                                rounded
                                style={{
                                    padding: 0,
                                    color: 'transparent',
                                }}
                            >
                                <DropdownItem color="lightBlue">
                                    <Link to="/dashboard/admin/profile-saya">Profile</Link>
                                </DropdownItem>
                                <DropdownItem color="lightBlue" onClick={async () => {
                                    await AuthService.logoutUser()
                                    localStorage.clear()
                                    window.location.replace('/')
                                }}>
                                    Keluar
                                </DropdownItem>
                            </Dropdown>
                        </div>
                    </div>
                </div>
            </div>
        </nav>
    );
}
