import React, { useState, useRef } from 'react';
import { FormGroup, FormControl, Form } from 'react-bootstrap';
import { useHistory } from "react-router-dom";
import styles from '../../index.module.css';
import Swal from 'sweetalert2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { useForm, useFormState} from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

import { connect } from "react-redux";
import { registerUser } from "../../store/actions/auth";
import { clearMessage } from '../../store/actions/message';

import AuthService from '../../services/auth'


function Register(props) {
    const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
    const history = useHistory();
    const didMountRef = useRef(false);
    const validationSchema = Yup.object().shape({
        fullName: Yup.string().required('Fullname is required'),
        nickName: Yup.string()
          .required('Nickname is required')
          .min(2, 'Nickname must be at least 2 characters')
          .max(20, 'Nickname must not exceed 20 characters'),
        email: Yup.string()
          .required('Email is required')
          .email('Email is invalid'),
        telephone: Yup.string()
            .matches(phoneRegExp, 'Phone number is not valid'),
        universitas: Yup.string()
            .required('Universitas is required'),
        fakultas: Yup.string()
            .required('Fakultas is required'),
        password: Yup.string()
          .required('Password is required')
          .min(8, 'Password must be at least 6 characters')
          .max(40, 'Password must not exceed 40 characters')
          .test("isValidPass", "Password is not valid", (value, context) => {
            const hasUpperCase = /[A-Z]/.test(value);
            const hasNumber = /[0-9]/.test(value);
            let validConditions = 0;
            const numberOfMustBeValidConditions = 2;
            const conditions = [hasUpperCase, hasNumber];
            conditions.forEach((condition) =>
              condition ? validConditions++ : null
            );
            if (validConditions >= numberOfMustBeValidConditions) {
              return true;
            }
            return false;
          }),
        confirmPassword: Yup.string()
          .required('Confirm Password is required')
          .oneOf([Yup.ref('password')], 'Confirm Password does not match'),
    });
    const { register, handleSubmit, watch, reset, formState: { errors, isSubmitSuccessful, submitCount, isSubmitted } } = useForm({resolver: yupResolver(validationSchema)});

    const [validated, setValidated] = useState(false);
    const [ universitas, setUniversitas ] = useState('');
    const [ inIdRegio, setInIdRegio ] = useState('');
    const [ isLain, setIsLain ] = useState(false);
    const [listSubRegio, setListSubRegio] = useState([])
    const { isLoggedIn, message, dispatch } = props;

    React.useEffect(() => {
        if (didMountRef.current){
            if(!isSubmitSuccessful && submitCount > 0) {
                Fail();
            }
        } else {
            didMountRef.current = true;
        }
    }, [submitCount]);

    const Column = {
        borderRadius: "10px",
        height: 30,
        padding: "25px",
    };
    const dropdown = {
        borderRadius: "10px",
        height: 50,
        padding: "25px",
    };

    const onSubmit = (data) => {
        console.log(data);
        const payload = {
            email: data.email,
            password: data.password,
            full_name: data.fullName,
            nick_name: data.nickName,
            phone_number: data.telephone,
            university: data.universitas,
            faculty: data.fakultas,
            id_regio: inIdRegio
        }
        AreYouSure(payload);
    };

    const AreYouSure = (data) => {
        Swal.fire({  
            title: 'Sudah Yakin?',  
            text: 'Pastikan semua data yang Anda isikan sudah sesuai',  
            icon: 'warning',  
            showCancelButton: true,  
            cancelButtonText: 'Tidak',  
            confirmButtonText: 'Ya'  
          })
          .then((result) => {
            if (result.isConfirmed) {
                dispatch(registerUser(data))
                .then(() => {
                    Succeed();
                })
                .catch((err) => {
                    if(err) {
                        Swal.fire({  
                            title: 'Failed Register',  
                            text: err.message,  
                            icon: 'error',  
                            showCancelButton: true,  
                            cancelButtonText: 'Kembali',           
                            showConfirmButton: false,  
                          });   
                    }
                });
            }
          });   
    }

    const Succeed = () => {
        Swal.fire({  
            title: 'Pembuatan Akun Berhasil !',  
            text: 'Silahkan cek e-mail Anda untuk melakukan verifikasi akun',  
            icon: 'success',  
            showCancelButton: false,              
            confirmButtonText: 'Lanjut'  
        }).then((result) => {
            if (result.isConfirmed) {
                history.push("/signin");
            }
        });   
    }

    const Fail = () => {
        Swal.fire({  
            title: 'Data yang Anda isikan belum lengkap !',  
            text: 'Silahkan lengkapi data Anda',  
            icon: 'warning',  
            showCancelButton: true,  
            cancelButtonText: 'Kembali',           
            showConfirmButton: false,  
          });   
    }

    const ErrorSwal = (error) => {
        Swal.fire({  
            title: "Something's Wrong",  
            text: error,  
            icon: 'warning',  
            showCancelButton: true,  
            cancelButtonText: 'Kembali',           
            showConfirmButton: false,  
          });   
    }

    const getListKelas = (params = 'perPage=1000') => {
        AuthService.getLisSubtRegioAdmin(params).then(resp => {
            const response = resp.data;
            setListSubRegio(response.data);

        }).catch(err => {
            let msg = 'Internal Server Error'
            console.error(err)
            if(err.response) {
                if(err.response.data.message) {
                    msg = err.response.data.message
                }
            }
    
            Fail(msg)
        })
    }

    React.useEffect(() => {
        getListKelas()
    }, [])

    return (
        <React.Fragment>
            <Form noValidate validated={validated} onSubmit={handleSubmit(onSubmit)}>
                <section className="container-fluid bg-desktop" style={{ backgroundImage: 'url(/assets/landing/bg-racoon-2.jpg)' }}>
                    <div className="container px-4" style={{ paddingTop: '8.5rem' }}>
                        <div className="row g-2 py-1 mb-4" style={{ textAlign: 'left' }}>
                            <h4 className="title-big">Daftar Akun</h4>
                        </div>
                        <div className="row g-5 py-1 mb-4" style={{ textAlign: 'left' }}>
                            <div className="col-sm-12 col-md-4 ml-0 mr-3">
                                <FormGroup>
                                    <Form.Label htmlFor="namaLengkap" className="form-label"><b>Nama Lengkap</b></Form.Label>
                                    <FormControl
                                        name="fullName"
                                        {...register("fullName")}
                                        placeholder="Nama Lengkap"
                                        aria-label="namaLengkap"
                                        aria-describedby="basic-addon1"
                                        type="text"
                                        isInvalid={errors.fullName}
                                        style={Column}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.fullName?.message}
                                    </Form.Control.Feedback>
                                    {/* <div className="invalid-feedback">{errors.fullName?.message}</div> */}
                                </FormGroup>
                            </div>
                            <div className="col-sm-12 col-md-4 ml-0 mr-auto">
                                <FormGroup>
                                    <Form.Label htmlFor="namaPanggilan" className="form-label"><b>Nama Panggilan</b></Form.Label>
                                    <FormControl
                                        {...register("nickName")}
                                        placeholder="Nama Panggilan"
                                        aria-label="namaPanggilan"
                                        aria-describedby="basic-addon1"
                                        isInvalid={errors.nickName}
                                        type="text"
                                        style={Column}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.nickName?.message}
                                    </Form.Control.Feedback>
                                </FormGroup>
                            </div>
                        </div>

                        <div className="row g-5 py-1 mb-4" style={{ textAlign: 'left' }}>
                            <div className="col-sm-12 col-md-4 ml-0 mr-3">
                                <FormGroup>
                                    <Form.Label htmlFor="Email" className="form-label"><b>Email</b></Form.Label><br/>
                                    <FormControl
                                        {...register("email")}
                                        placeholder="Email"
                                        aria-label="Email"
                                        isInvalid={errors.email}
                                        aria-describedby="basic-addon1"
                                        type="email"
                                        style={Column}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.email?.message}
                                    </Form.Control.Feedback>
                                </FormGroup>
                            </div>
                            <div className="col-sm-12 col-md-4 ml-0 mr-auto">
                                <FormGroup>
                                    <Form.Label htmlFor="telephone" className="form-label"><b>No. HP</b></Form.Label><br/>
                                    <FormControl
                                        {...register("telephone")}
                                        placeholder="e.g 0812xxxxxxx"
                                        aria-label="telephone"
                                        aria-describedby="basic-addon1"
                                        isInvalid={errors.telephone}
                                        type="phone"
                                        style={Column}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.telephone?.message}
                                    </Form.Control.Feedback>
                                </FormGroup>
                            </div>
                        </div>


                        <div className="row g-5 py-1 mb-4" style={{ textAlign: 'left' }}>
                            <div className="col-sm-12 col-md-4 ml-0 mr-3">
                                <FormGroup>
                                    <Form.Label htmlFor="universitas" className="form-label"><b>Universitas</b></Form.Label><br/>
                                    <Form.Select
                                        as="select"
                                        {...register("universitas")}
                                        value={universitas}
                                        onChange={e => {
                                            e.preventDefault()
                                            setUniversitas(e.target.value)
                                            if(e.target.value === 'other') {
                                                setIsLain(true)
                                            } else {
                                                setIsLain(false)
                                            }

                                            const lists = listSubRegio.filter(x => x.name === e.target.value);
                                            if(lists.length > 0)
                                                setInIdRegio(lists[0].id_regio)
                                            

                                        }}
                                        isInvalid={errors.universitas}
                                        aria-label="Universitas"
                                        aria-describedby="basic-addon1"
                                        style={dropdown}
                                    >
                                        <option value="">-- Pilih Universitas --</option>
                                        {
                                            listSubRegio.map((item, index) => {
                                                return <option value={item.name}>{item.name}</option>
                                            })
                                        }
                                        
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">
                                        {errors.universitas?.message}
                                    </Form.Control.Feedback>
                                </FormGroup>
                            </div>
                            <div className="col-sm-12 col-md-4 ml-0 mr-auto">
                                <FormGroup>
                                    <Form.Label htmlFor="telephone" className="form-label"><b>Asal Fakultas</b></Form.Label><br/>
                                    <FormControl
                                        {...register("fakultas")}
                                        placeholder="Fakultas"
                                        aria-label="Fakultas"
                                        aria-describedby="basic-addon1"
                                        type="text"
                                        style={Column}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.fakultas?.message}
                                    </Form.Control.Feedback>
                                </FormGroup>
                            </div>
                        </div>

                        { isLain ? (<div className="row g-5 py-1 mb-4" style={{ textAlign: 'left' }}>
                            <div className="col-sm-12 col-8 ml-0 mr-3">
                                <FormGroup>
                                    <Form.Label htmlFor="regio" className="form-label"><b>Pilih Regio</b></Form.Label><br/>
                                    <Form.Check
                                        inline
                                        {...register("regio")}
                                        label="Regio A"
                                        name="regio"
                                        value="Regio A"
                                        type="radio"
                                    />
                                    <Form.Check
                                        inline
                                        {...register("regio")}
                                        label="Regio B"
                                        value="Regio B"
                                        name="regio"
                                        type="radio"
                                    />
                                    <Form.Check
                                        inline
                                        {...register("regio")}
                                        label="Regio C"
                                        value="Regio C"
                                        name="regio"
                                        type="radio"
                                    />
                                </FormGroup>
                            </div>
                        </div>) : null
                        }

                        <div className="row g-5 py-1 mb-4" style={{ textAlign: 'left' }}>
                            <div className="col-sm-12 col-md-4 ml-0 mr-3">
                                <FormGroup>
                                    <Form.Label htmlFor="Password" className="form-label"><b>Password</b></Form.Label><br/>
                                    <FormControl
                                        {...register("password")}
                                        placeholder="Password"
                                        aria-label="Password"
                                        aria-describedby="basic-addon1"
                                        type="password"
                                        isInvalid={errors.password}
                                        style={Column}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.password?.message}
                                    </Form.Control.Feedback>
                                </FormGroup>
                            </div>
                            <div className="col-sm-12 col-md-4 ml-0 mr-auto">
                                <FormGroup>
                                    <Form.Label htmlFor="PasswordConfirm" className="form-label"><b>Konfirmasi Password</b></Form.Label><br/>
                                    <FormControl
                                        {...register("confirmPassword")}
                                        placeholder="Konfirmasi Password"
                                        aria-label="PasswordConfirm"
                                        aria-describedby="basic-addon1"
                                        type="password"
                                        isInvalid={errors.confirmPassword}
                                        style={Column}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.confirmPassword?.message}
                                    </Form.Control.Feedback>
                                </FormGroup>
                            </div>
                            <div className="col-12 pb-3">
                                <p style={{fontFamily: 'Quicksand', fontWeight: '800'}}><FontAwesomeIcon icon={faInfoCircle} /> &nbsp; Password panjangnya minimal 8 karakter, dan mengandung min. 1 huruf besar dan 1 angka </p>
                            </div>
                        </div>


                        <div className="row g-2 py-1 mb-4 pt-5 mt-4" style={{ textAlign: 'left' }}>
                            <div className="col-sm-8 col-md-3 text-center text-md-start">
                                <button className="login-btn" style={{ fontWeight: '700', color: 'black' }}>Daftar</button>
                            </div>
                        </div>

                    </div>
                </section>
            </Form>
        </React.Fragment>
    )
}

function mapStateToProps(state) {
    const { isLoggedIn } = state.auth;
    const { message } = state.message;
    return {
        isLoggedIn,
        message
    };
}

export default connect(mapStateToProps)(Register);
