import React, { useState, useEffect } from 'react';
import {
  SideBar
} from '../../../components/layout/dashboard';
import { Link, useParams, useLocation } from 'react-router-dom';
import Icon from '@material-tailwind/react/Icon';
import { BsPlay, BsPlayFill } from 'react-icons/bs';
import Button from '@restart/ui/esm/Button';

import { connect } from 'react-redux';
import { getKerjakanDetail } from '../../../store/actions/tryout';

import moment from 'moment';

function Kerjakan (props) {
  document.title = "INGENIO Indonesia";
  const [myTryout, setMyTryout] = useState({});
  const [dataSubTryout, setDataSubTryout] = useState({});

  const { dispatch } = props;

  function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
  }
  const query = useQuery()

  useEffect(() => {
    const idTo = query.get("tryout");
    const idSub = query.get("subtryout");
    console.log(idTo, idSub)
    dispatch(getKerjakanDetail(idTo, idSub))
      .then(async (response) => {
        console.log(response)
        let datasub = response.data.subtryout[0];
        await setMyTryout(response.data);
        await setDataSubTryout(datasub);
      })
      .catch((err) => {
        console.log(err);
      })
  }, [dataSubTryout.id, myTryout.id])

  return(
    <React.Fragment>
      <SideBar />
      <div className="md:ml-64">
        {/* <div className="bg-orange px-3 md:px-8 py-1 h-40">
          <div className="container md:pr-8 md:pl-10">
            <h3 className="text-left tracking-wider title-dashboard">Selamat Datang, Peserta TryOut INGENIO Indonesia</h3>
          </div>           
        </div>

        <div className="px-3 md:px-8 -mt-24">
            <div className="container mx-auto max-w-full">
                <div className="grid grid-cols-2 xl:grid-cols-5">
                    <div className="xl:col-start-1 xl:col-end-4 px-4 mb-14">
                      <a href="/dashboard/tryout" className="rounded-full btn-tryout">Mulai TryOut</a>
                      <a href="/dashboard/tata-cara" className="rounded-full btn-tryout">Tata Cara TryOut</a>
                      <a href="/dashboard/hasil-tryout" className="rounded-full btn-tryout">Hasil TryOut</a>
                    </div>
                </div>
            </div>
        </div> */}

        <div className="container md:pr-8 md:pl-10"  style={{position: 'absolute', top: '80px'}}>
          <h1 className="tracking-wider title-24-600">Kerjakan Soal</h1><br />
          {/* <h3 className="text-left tracking-wider">Anda dapat menghubungi Admin terlebih dahulu untuk memastikan kelas dan program yang Anda daftarkan. <br /> <br />Kontak Admin <a href={contactLink()} target={'_blank'}> <i class="fa fa-whatsapp" aria-hidden="true"></i></a></h3> */}
          {/* <img src={baseUrl + "/assets/logo/evaluasikelas.png"} className="img-opacity"></img> */}
        </div> 

        <div className="px-3 md:px-8">
            <div className="container mx-auto max-w-full">
                <p className="breadcrumbss py-4">Tryout Saya / Detail Tryout / <span style={{fontWeight: 'bold'}}>{myTryout.nama_tryout}</span></p>
                <div className="card-gede">
                    <div className="card-head px-4 py-4" >
                        Detail Tryout - {myTryout.nama_tryout} - {dataSubTryout.nama_subtryout}
                    </div>
                    <div className="row">
                        <div className="col-lg-6 col-md-6 col-xs-12 py-4 px-5">
                            <p style={{fontSize:"18px !important", lineHeight: "22px", letterSpacing: "0.06em", color: "#000000", fontWeight: 'bold'}}>Detail Tryout - {myTryout.nama_tryout}</p> <br />
                            <p className="text-black mb-3">Mulai : <b>{(moment(myTryout.tanggal_awal, moment.ISO_8601).format("dddd, DD MMMM YYYY HH:mm")) + ' WIB'}</b></p>                     
                            <p className="text-black mb-3">Sifat : <b>Serentak</b></p>
                            <p className="text-black mb-4">Durasi : <b>{dataSubTryout.duration} Menit</b></p>
                            <p className="text-black mb-3">Batas : <b>{(moment(myTryout.tanggal_akhir, moment.ISO_8601).format("dddd, DD MMMM YYYY HH:mm")) + ' WIB'}</b></p>  
                            {
                              moment(myTryout.tanggal_akhir).toDate() > moment().toDate() ?
                              <Link className="flex items-center pilih-tryout p-2" style={{width:'fit-content'}} to={"/dashboard/soaltryout?sub=" + dataSubTryout.id}>
                                <BsPlayFill size="25px"/>
                                <span className="px-2">Kerjakan</span>
                              </Link>
                              :
                              <Button className="flex items-center p-2" style={{width:'fit-content', backgroundColor: 'grey'}} to={"#"}>
                                <BsPlayFill size="25px"/>
                                <span className="px-2">Sudah Lewat</span>
                              </Button>
                            }
                            
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>      

      </div>
    </React.Fragment>
    
  )
}

export default connect()(Kerjakan)