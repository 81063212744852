import Button from '@material-tailwind/react/Button';
import React, { useState } from 'react';
import { FormGroup } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import { BsArrowLeftShort, BsFileEarmark } from 'react-icons/bs';
import { Link, NavLink } from 'react-router-dom';
import TutorSidebar from '../../../components/layout/dashboard/tutor/TutorSideBar';

export default function KelasDetail() {
    var baseUrl = window.location.origin;

    document.title = "INGENIO Indonesia";
    const [program, setProgram] = useState('');
    const dropdown = {
        borderRadius: "10px",
        height: 50,
        padding: "25px",
    };
    const columns = [
        {
            name: 'NO.',
            selector: row => row.no,
            sortable: true,
            width: '6.2vw',
        },
        {
            name: 'NAMA LENGKAP',
            selector: row => row.namalengkap,
            sortable: true,
            width: '15vw',
        },
        {
            name: 'ASAL FK',
            selector: row => row.asalfk,
            sortable: true,
            width: '10vw',
        },
        {
            name: 'ANGKATAN',
            selector: row => row.angkatan,
            sortable: true,
            width: '12vw',
        },
        {
            name: 'IPK TERAKHIR',
            selector: row => row.ipkterakhir,
            sortable: true,
            width: '13vw',
        },
        {
            name: 'UJIAN KE-',
            selector: row => row.ujianke,
            sortable: true,
            width: '12vw',
        },
        {
            name: 'MATERI YANG SULIT',
            selector: row => row.materisulit,
            sortable: true,
        },
        {
            name: 'KESULITAN YANG DIRASAKAN',
            selector: row => row.kesulitan,
            sortable: true,
            width: '24vw',
        },
        {
            cell: () => <Link className="btnTable" to="/dashboard/tutor/data-kelas/data-peserta/detail-profile">Detail</Link>,
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
    ];
    const data = [
        {
            no: 1,
            namalengkap: 'Lorem ipsum dolor.',
            asalfk: 'FK A',
            // angkatan: <Link className="kelas" to="kelas-a" style={{ textDecoration: 'underline' }}>Kelas A</Link>,
            angkatan: '1990',
            ipkterakhir: '4',
            ujianke: '1',
            materisulit: 'Lorem Ipsum',
            kesulitan: 'Lorem Ipsum Dolor',
        },
        {
            no: 2,
            namalengkap: 'Lorem ipsum dolor.',
            asalfk: 'FK A',
            // angkatan: <Link className="kelas" to="kelas-a" style={{ textDecoration: 'underline' }}>Kelas A</Link>,
            angkatan: '1990',
            ipkterakhir: '3.5',
            ujianke: '2',
            materisulit: 'Lorem Ipsum',
            kesulitan: 'Lorem Ipsum Dolor',
        },
        {
            no: 3,
            namalengkap: 'Lorem ipsum dolor.',
            asalfk: 'FK A',
            // angkatan: <Link className="kelas" to="kelas-a" style={{ textDecoration: 'underline' }}>Kelas A</Link>,
            angkatan: '1990',
            ipkterakhir: '3.3',
            ujianke: '1',
            materisulit: 'Lorem Ipsum',
            kesulitan: 'Lorem Ipsum Dolor',
        },
        {
            no: 4,
            namalengkap: 'Lorem ipsum dolor.',
            asalfk: 'FK A',
            // angkatan: <Link className="kelas" to="kelas-a" style={{ textDecoration: 'underline' }}>Kelas A</Link>,
            angkatan: '1990',
            ipkterakhir: '3.6',
            ujianke: '1',
            materisulit: 'Lorem Ipsum',
            kesulitan: 'Lorem Ipsum Dolor',
        },

    ];

    const customStyles = {
        table: {
          style: {
            borderRadius: '15px',
            border: '1px solid #BDC1C9',
          },
        },
        head: {
          style: {
            fontSize: '1.25rem',
            textTransform: "uppercase",
            color: '#6B7280',
            backgroundColor: '#f3f4f6',
            border: '1px solid #BDC1C9',
            borderTopLeftRadius: '15px',
            borderTopRightRadius: '15px',
          },
        },
        pagination: {
          style: {
            color: '#6B7280',
            fontSize: '1.1rem',
            minHeight: '56px',
            backgroundColor: '#f3f4f6',
            border: '1.25px solid #BDC1C9',
            borderBottomLeftRadius: '15px',
            borderBottomRightRadius: '15px',
          },
        },
    };

    return (
        <React.Fragment>
            <TutorSidebar />
            <div className="md:ml-64 tutorDataKelas" style={{ backgroundColor: '#F3F4F6' }}>
                <div className="bg-orange md:px-8 py-5" style={{height: '250px', position: 'relative', padding: '30px'}}>
                    <h1 className="tracking-wider title-24-600">Data Kelas</h1>
                    <h2 className="tracking-wider title-20-700 mt-5">Selamat Datang Tutor INGENIO !</h2>
                    <img src={baseUrl + "/assets/logo/evaluasikelas.png"} className="img-opacity"></img>
                </div>

                <div className="px-3 md:px-8 mt-4">
                    <div className="container mx-auto max-w-full">
                        <div className="justify-start">
                            <Link to="/dashboard/tutor/data-kelas-tutor">
                                <div className="navBack">
                                    <BsArrowLeftShort size="19px"/>Kembali
                                </div> 
                            </Link>
                        </div>
                        <div className="row">
                            <div className="col-12 my-4 justify-start">
                                <FormGroup>
                                    <div className="SearchTable">
                                        <input type="text" name="" className="inputSearchTable" placeholder="&#x1F50E;&#xFE0E; Search..." id="" />
                                        <Button className="btnSearchTable">Cari</Button>
                                    </div>
                                </FormGroup>
                                <Button className="btnOrange2">
                                    <Link to="/dashboard/tutor/data-kelas/absensi">
                                        <div className="titleBtnOrange">
                                            <BsFileEarmark className="mr-2"/>Absensi Kelas
                                        </div> 
                                    </Link>
                                </Button>
                            </div>
                        </div>
                        <div className="col-md-12 col-lg-12 col-xs-12 mt-3 mb-5">
                            <DataTable columns={columns} customStyles={customStyles} data={data} pagination />
                        </div>
                    </div>
                </div>

            </div>
        </React.Fragment >

    )
}