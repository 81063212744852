import React from 'react';
import ArtikelSlider from './ArtikelSlider';

export default function ArtikelSelect() {

    return (
        <React.Fragment>
            {/* <section className="container-fluid">
                <div className="site-header site-header--menu-left landing-11-menu site-header--absolute" style={{ backgroundImage: 'url(/assets/landing/Genio2.png)', backgroundPositionY: 'bottom', backgroundPositionX: 'right', backgroundRepeat: 'no-repeat', paddingTop: '6rem', backgroundColor: '#ffcc00' }}>
                    <h5 className="text-center tentang_title py-5" style={{ paddingBottom: '2rem !important' }}>ARTIKEL</h5>
                </div>
            </section> */}
            <section className="container-fluid bg-desktop">
                <div className="container px-4" style={{ paddingTop: '10rem', width: '85%' }}>
                    <h5 className="tentang_title g-title" style={{ textAlign: 'left', fintWeight: '700' }}>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Tincidunt
                        habitasse odio dui placerat ipsum amet ac justo.</h5>
                </div>
                <div className="container px-4 d-none d-lg-block" style={{ paddingTop: '3rem', width: '85%' }}>
                    <div className="row g-2">
                        <div className="col-1">
                            <img src="/assets/icons/eclipse.png" />
                        </div>
                        <div className="col-9 tagselect" style={{ padding: '0.2rem' }}>
                            <div className="row g-2">
                                <div className="col-3">
                                    <h5 className="txt14px">Name</h5>
                                </div>
                            </div>
                            <div className="row g-2">
                                <div className="col-3">
                                    <h5 className="txt14px">9 Sep 2021</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container px-4" style={{ paddingTop: '1rem', width: '85%' }}>
                    <img src="/assets/landing/artikel-1.png" className="article-card-img" style={{ width: '100%', height: '20rem', paddingBottom: '2rem' }} />
                    <p className="txt24px" style={{ paddingBottom: '2rem', color: '#000000' }}>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Magnis suscipit iaculis faucibus eu. Vel at neque condimentum adipiscing consequat a dignissim. Dui adipiscing mauris, egestas purus ut eget. In justo, dictum elit non euismod eu. Quam cras lacus, quis varius. Dolor enim mi non, sagittis, tellus faucibus metus. Euismod feugiat auctor diam at hac fermentum. Euismod egestas netus ut eget penatibus bibendum ac feugiat. Pulvinar vestibulum, quam ultrices habitant quam sagittis volutpat. Sagittis, sociis habitasse tellus eget viverra leo nunc. Arcu, rutrum nunc massa dui a lacus. Enim massa pulvinar quisque tristique suspendisse duis ac eu sagittis. Eu commodo eu ac, et. Elit, mattis est pretium faucibus maecenas vivamus amet. Mauris vitae nibh malesuada quam.
                    </p>
                    <p className="txt24px" style={{ fontWeight: '800', color: '#000000', paddingBottom: '2rem' }}>
                        Sagittis, sociis habitasse tellus eget viverra leo nunc. Arcu, rutrum nunc massa dui a lacus. Enim massa pulvinar quisque tristique suspendisse duis ac eu sagittis.
                    </p>
                    <p className="txt24px" style={{ color: '#000000', paddingBottom: '1rem' }}>
                        Eu, amet, massa nisl eu. Viverra hac non consectetur facilisi ipsum. Orci egestas tincidunt mauris quam facilisi. Urna, lacinia nibh lorem aliquam id nulla ipsum odio sagittis. Euismod feugiat auctor diam at hac fermentum. Euismod egestas netus ut eget penatibus bibendum ac feugiat.
                    </p>
                </div>
            </section>
        </React.Fragment >
    )
}

