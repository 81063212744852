import Button from '@material-tailwind/react/Button';
import React, { useState } from 'react';
import { FormGroup, FormControl, Form, Modal } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import { Link } from 'react-router-dom';
import { BsPrinter } from 'react-icons/bs';
import { HiOutlinePencilAlt } from 'react-icons/hi';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import LogistikSideBar from '../../../components/layout/dashboard/logistik/LogistikSideBar';
import Swal from 'sweetalert2';

export default function OlahResiLogistik(props) {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    var baseUrl = window.location.origin;
    document.title = "INGENIO Indonesia";

    const columns = [
        {
            name: 'NO.',
            selector: row => row.no,
            sortable: true,
            width: '6.2vw',
        },
        {
            name: 'kode Peserta',
            selector: row => row.kode,
            sortable: true,
            width: '16vw',
        },
        {
            name: 'nama Peserta',
            selector: row => row.nama,
            sortable: true,
            width: '18vw',
        },
        {
            name: 'Program',
            selector: row => row.program,
            sortable: true,
            width: '15vw',
        },
        {
            name: 'Tgl Pengiriman',
            selector: row => row.tgl,
            sortable: true,
            width: '15vw',
        },
        {
            name: 'No. Resi',
            selector: row => row.resi,
            sortable: true,
            width: '18vw',
        },
        {
            name: 'Alamat',
            selector: row => row.alamat,
            sortable: true,
            width: '20vw',
        },
        {
            name: 'Status',
            selector: row => row.status,
            sortable: true,
            width: '10vw',
        },
        {
            name: 'Aksi',
            selector: row => row.aksi,
            sortable: true,
            width: '8vw',
        },
    ];
    const data = [
        {
            no: 1,
            kode: '112233',
            nama: 'Lorem.',
            program: 'Program A',
            tgl: '10-10-2022',
            resi: '11001-00111-1100',
            alamat: 'Bandung',
            status: <div className="btnTableDone">Terkirim</div>,
            aksi: <OverlayTrigger placement="top" overlay={<Tooltip id="button-tooltip-2">Print Resi</Tooltip>}><Link className="btnCircleBlack" to="/dashboard/logistik/olah-resi/print"><BsPrinter size="22"/></Link></OverlayTrigger>,
        },
        {
            no: 2,
            kode: '778899',
            nama: 'Lorem.',
            program: 'Program B',
            tgl: '10-10-2022',
            resi: '11001-00111-1100',
            alamat: 'Bandung',
            status: <div className="btnTablePending">Pending</div>,
            aksi: <OverlayTrigger placement="top" overlay={<Tooltip id="button-tooltip-2">Edit</Tooltip>}><Link className="btnCircleBlack" onClick={handleShow}><HiOutlinePencilAlt size="22"/></Link></OverlayTrigger>,
        },
    ];

    const customStyles = {
        table: {
          style: {
            borderRadius: '15px',
            border: '1px solid #BDC1C9',
          },
        },
        head: {
          style: {
            fontSize: '1.25rem',
            textTransform: "uppercase",
            color: '#6B7280',
            backgroundColor: '#f3f4f6',
            border: '1px solid #BDC1C9',
            borderTopLeftRadius: '15px',
            borderTopRightRadius: '15px',
          },
        },
        pagination: {
          style: {
            color: '#6B7280',
            fontSize: '1.1rem',
            minHeight: '56px',
            backgroundColor: '#f3f4f6',
            border: '1.25px solid #BDC1C9',
            borderBottomLeftRadius: '15px',
            borderBottomRightRadius: '15px',
          },
        },
    };

    const Succeed = () => {
        Swal.fire({  
            title: 'Data Berhasil Tersimpan !',  
            text: '',  
            icon: 'success',  
            showCancelButton: false,              
            confirmButtonText: 'OK'  
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.reload();
          }
        })
    }
    const Succeed2 = () => {
        Swal.fire({  
            title: 'Data Berhasil Tersimpan !',  
            text: '',  
            icon: 'success',  
            showCancelButton: false,              
            confirmButtonText: 'OK'  
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.reload();
          }
        })
    }

    return (
        <React.Fragment>
            <LogistikSideBar />
            <div className="md:ml-64 tutorDataKelas" style={{ backgroundColor: '#F3F4F6' }}>
                <div className="bg-orange md:px-8 pb-5" style={{height: '20vh', position: 'relative', padding: '0 30px 30px 30px'}}>
                    <h1 className="tracking-wider title-24-600">Olah Resi</h1>
                    <img src={baseUrl + "/assets/logo/evaluasikelas.png"} className="img-opacity"></img>
                </div>

                <div className="px-3 md:px-8 mt-4 pb-5">
                    <div className="container mx-auto max-w-full">
                        <div className="row">
                            <div className="col-12 mt-4 mb-5">
                                <div className="cards pd-InJurnal">
                                    <div className="row">
                                        <div className="col-12 mb-4">
                                            <div className="title-20-700">Input Resi</div>
                                            <div className="borderBottomGrey"></div>
                                        </div>
                                        <div className="col-12">
                                            <div className="title-18-500 mb-1">Nomor Resi</div>
                                            <input type="text" class="formInputJurnal" placeholder="masukkan resi..." />
                                        </div>
                                        <div className="col-12 justify-end mt-5">
                                            <Button type="submit" onClick={Succeed} className="btnOrange3">
                                                <span className="txt-btn">Submit</span>
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 my-2">
                                <FormGroup>
                                    <div className="SearchTable">
                                        <input type="text" name="" className="inputSearchTable" placeholder="&#x1F50E;&#xFE0E; Cari." id="" />
                                        <Button className="btnSearchTable">Cari</Button>
                                    </div>
                                </FormGroup>
                            </div>
                            <div className="col-12 mt-3">
                                <DataTable columns={columns} customStyles={customStyles} data={data} pagination />
                            </div>
                            <Modal show={show} onHide={handleClose}       
                                    {...props}
                                    size="md"
                                    aria-labelledby="contained-modal-title-vcenter"
                                    centered>
                                <Modal.Header closeButton>
                                    <Modal.Title id="contained-modal-title-vcenter">
                                        <div className="title-20-700">Edit No Resi</div>
                                    </Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <div className="row p-4">
                                        <div className="col-12">
                                            <div className="title-18-500 mb-1">No Resi</div>
                                            <input type="text" class="formInputJurnal" placeholder="masukkan resi..." />
                                        </div>
                                        <div className="col-12 justify-end mt-5">
                                            <Button type="submit" onClick={Succeed2} className="btnOrange3">
                                                <span className="txt-btn">Submit</span>
                                            </Button>
                                        </div>
                                    </div>
                                </Modal.Body>
                            </Modal>
                        </div>
                    </div>
                </div>

            </div>
        </React.Fragment >

    )
}