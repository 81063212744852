import React from 'react';

export default function Seleksitutor() {

    return (
        <React.Fragment>
            <section className="container-fluid">
                <div className="site-header site-header--menu-left landing-11-menu site-header--absolute" style={{ backgroundImage: 'url(/assets/landing/Genio2.png)', backgroundPositionY: 'bottom', backgroundPositionX: 'right', backgroundRepeat: 'no-repeat', paddingTop: '6rem', backgroundColor: '#ffcc00' }}>
                    <h5 className="text-center tentang_title py-5" style={{ paddingBottom: '2rem !important' }}>SELEKSI TUTOR</h5>
                </div>
            </section>
            <section className="container-fluid bg-desktop">
                <div className="container px-4" style={{ paddingTop: '10rem' }}>
                    <div className="row g-2 pdd10r mmb" style={{ textAlign: 'justify' }}>
                        <div className="col-8 pdcent">
                            <h5 className="txt32px" style={{ textDecoration: 'none', textAlign: 'left', paddingTop: '1rem' }}>
                                Anda ingin menjadi tutor INGENIO ?
                            </h5>
                            <h5 className="txt24px mt2r">Kontak bagian Akademik Ingenio untuk info detailnya!
                            </h5>
                        </div>
                        <div className="col-4 ptl">
                            <img src="/assets/landing/genhay.png" />
                        </div>
                    </div>
                    <h5 className="txt24px" style={{ textAlign: 'center' }}>Bisa menghubungi Tim Akademik kami:
                    </h5>
                    <div className="row prpl10r">
                        <div className="col6">
                            <div className="row align-items-center justify-content-center text-center g-2 py-1 mt-4 mb-4">
                                <a className="bt-m zoom" href="#" style={{ width: '350px', height: '200px', background: 'linear-gradient(248.1deg, #F8CA2B 27.1%, #FCA826 73.3%)', boxShadow: '0px 2px 10px #F8CA2B', borderRadius: '1rem' }}>
                                    <span className="bt-link" style={{ color: '#000000' }}>dr Novel</span>
                                    <img src="/assets/icons/wacolor.png" style={{ marginLeft: 'auto', marginRight: 'auto', paddingTop: '5rem' }} />
                                </a>
                            </div>
                        </div>
                        <div className="col6">
                            <div className="row align-items-center justify-content-center text-center g-2 py-1 mt-4 mb-4">
                                <a className="bt-m zoom" href="#" style={{ width: '350px', height: '200px', background: 'linear-gradient(248.1deg, #F8CA2B 27.1%, #FCA826 73.3%)', boxShadow: '0px 2px 10px #F8CA2B', borderRadius: '1rem' }}>
                                    <span className="bt-link" style={{ color: '#000000' }}>dr Aji Wibowo</span>
                                    <img src="/assets/icons/wacolor.png" style={{ marginLeft: 'auto', marginRight: 'auto', paddingTop: '5rem' }} />
                                </a>
                            </div>
                        </div>
                    </div>

                </div>
            </section>

        </React.Fragment >
    )
}

