import React, { useState } from 'react';

export default function ProgramUtb() {

    return (
        <React.Fragment>
            <section className="container-fluid">
                <div className="site-header site-header--menu-left landing-11-menu site-header--absolute" style={{ backgroundPositionY: 'bottom', backgroundPositionX: 'right', backgroundRepeat: 'no-repeat', paddingTop: '6rem', backgroundColor: '#1EA54A' }}>
                    <div className="py-5" style={{ paddingBottom: '2rem !important' }}>
                        <h5 className="sub-title text-center">Kelas Bimbingan</h5>
                        <h5 className="tentang_title g-title">Uji Tahap Bersama Tahap 1 dan 2</h5>

                    </div>
                </div>
            </section>
            <section className="container-fluid bg-desktop">
                <div className="container px-4" style={{ paddingTop: '10rem', width: '90%', paddingBottom: '5rem' }}>
                    <div className="row g-2" style={{ textAlign: 'left', paddingTop: '13.5rem' }}>
                        <div className="col-lg-6 col-md-12">
                            <img src="/assets/landing/utb-tahap-1.png" />
                        </div>
                        <div className="col-lg-6 col-md-12 mt5r">
                            <h5 className="tdt36px balooc2 prl5r" style={{ textDecoration: 'none', lineHeight: '50px' }}>Bimbingan Ujian Tahap Bersama 1</h5>
                            <h5 className="txt20px mt1r prl5r" style={{ marginBottom: '1rem', lineHeight: '30px' }}>Merupakan program bimbingan di Ingenio yang mempersiapkan mahasiswa tahap preklinik Kedokteran untuk menghadapi Ujian Tahap Bersama 1 secara komprehensif
                            </h5>
                        </div>
                    </div>
                    <h5 className="txt24px mt1r" style={{ marginBottom: '0.2rem', lineHeight: '30px' }}>Dengan berbagai fasilitas penunjang yaitu:</h5>
                    <h5 className="txt20px mt1r" style={{ marginBottom: '1rem', lineHeight: '30px' }}>a.	Konsultasi tutor 24/7 selama masa bimbingan<br />
                        b.	Kelas interaktif dan sistematis Online via Zoom<br />
                        c.	Materi dalam bentuk buku Eksklusif UTB Ingenio<br />
                        d.	23 pertemuan durasi 120-180 menit per pertemuan<br />
                        e.	Private atau grup (2-24 orang)<br />
                        f.	Tutor terstandarisasi sesuai bidang<br />
                        g.	Biaya terjangkau<br />
                        h.	Penanaman pola pikir dan latihan soal
                    </h5>
                    <a href="https://drive.google.com/file/d/1_RZrhWk58TVtKMmQhAWHE4b1ejLsKJuA/view?usp=sharing" className="txt20px mt1r" style={{ color: '#FCA61F', textDecoration: 'underline' }}>Download Brosur UTB Tahap 1
                    </a>
                    <div className="row g-2" style={{ textAlign: 'left', paddingTop: '2.5rem' }}>
                        <div className="col-lg-6 col-md-12 mt5r">
                            <h5 className="tdt36px balooc2 w75p" style={{ textDecoration: 'none', lineHeight: '50px' }}>Bimbingan Ujian Tahap Bersama 2</h5>
                            <h5 className="txt20px mt1r w70p" style={{ marginBottom: '1rem', lineHeight: '30px' }}>Merupakan program bimbingan di Ingenio yang mempersiapkan mahasiswa tahap preklinik Kedokteran untuk menghadapi Ujian Tahap Bersama 2 yang diselenggarakan setelah semester 7/8 secara komprehensif
                            </h5>
                            <h5 className="txt24px mt5r" style={{ marginBottom: '0.2rem', lineHeight: '30px' }}>Dengan berbagai fasilitas penunjang yaitu:</h5>
                            <h5 className="txt20px mt1r" style={{ marginBottom: '1rem', lineHeight: '30px' }}>a.	Konsultasi tutor 24/7 selama masa bimbingan<br />
                                b.	Kelas interaktif dan sistematis Online via Zoom<br />
                                c.	Materi dalam bentuk buku Eksklusif UTB Ingenio<br />
                                d.	23 pertemuan durasi 120-180 menit per pertemuan<br />
                                e.	Private atau grup (2-24 orang)<br />
                                f.	Tutor terstandarisasi sesuai bidang<br />
                                g.	Biaya terjangkau<br />
                                h.	Penanaman pola pikir dan latihan soal
                            </h5>
                            <a href="https://drive.google.com/file/d/1_RZrhWk58TVtKMmQhAWHE4b1ejLsKJuA/view?usp=sharing" className="txt20px" style={{ color: '#FCA61F', textDecoration: 'underline' }}>Download Brosur UTB Tahap 2
                            </a>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <img src="/assets/landing/utb-tahap-2.png" />
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment >
    )
}

