import React from 'react';
import {
    ProgramUkomKeb,
} from '../components/programukomkeb';
import {
    IngenioNavBlue,
    IngenioFooterBlue
} from '../components/layout'

export default function ProgramUkomKebPage() {
    return (
        <React.Fragment>
            <IngenioNavBlue />
            <div style={{ backgroundColor: 'white' }}>
                <ProgramUkomKeb />
            </div>
            <IngenioFooterBlue />
        </React.Fragment>

    )
}