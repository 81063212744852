import React from 'react';
import {
    ProgramUkmppd,
} from '../components/programukmppd';
import {
    IngenioFooter2,
    IngenioNav2
} from '../components/layout'

export default function ProgramUkmppdPage() {
    return (
        <React.Fragment>
            <IngenioNav2 />
            <div style={{ backgroundColor: 'white' }}>
                <ProgramUkmppd />
            </div>
            <IngenioFooter2 />
        </React.Fragment>

    )
}