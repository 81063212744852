import React, { useState } from 'react';

export default function ProgramUkmp2dg() {

    return (
        <React.Fragment>
            <section className="container-fluid">
                <div className="site-header site-header--menu-left landing-11-menu site-header--absolute" style={{ backgroundPositionY: 'bottom', backgroundPositionX: 'right', backgroundRepeat: 'no-repeat', paddingTop: '6rem', backgroundColor: '#B954A0' }}>
                    <div className="py-5" style={{ paddingBottom: '2rem !important' }}>
                        <h5 className="sub-title text-center">Kelas Bimbingan</h5>
                        <h5 className="tentang_title g-title">Ujian Kompetensi Mahasiswa<br />Program Pendidikan Dokter Gigi</h5>

                    </div>
                </div>
            </section>
            <section className="container-fluid bg-desktop">
                <div className="container px-4" style={{ paddingTop: '10rem', width: '85%', paddingBottom: '5rem' }}>
                    <div className="row g-2" style={{ textAlign: 'left', paddingTop: '17rem' }}>
                        <div className="col-lg-6 col-md-12 mt2r">
                            <img src="/assets/landing/ukmp2dg-1.png" />
                        </div>
                        <div className="col-lg-6 col-md-12 mt5r">
                            <h5 className="tdt36px balooc2 prl5r" style={{ textDecoration: 'none', lineHeight: '50px' }}>Bimbingan CBT UKMPPDG PLATINUM Complete Class (Online)</h5>
                            <h5 className="txt20px mt1r prl5r" style={{ marginBottom: '1rem', lineHeight: '30px' }}>Merupakan program bimbingan unggulan di Ingenio yang mempersiapkan mahasiswa klinik Kedokteran menghadapi CBT UKMPPDG secara daring (online)
                            </h5>
                        </div>
                    </div>
                    <h5 className="txt24px mt1r" style={{ marginBottom: '0.2rem', lineHeight: '30px' }}>Dengan berbagai fasilitas penunjang yaitu:</h5>
                    <h5 className="txt20px mt1r" style={{ marginBottom: '1rem', lineHeight: '30px' }}><strong>a</strong>. Fase Intensif (Materi dan Pola pikir)  14x pertemuan (@5-6 jam)<br />
                        <strong>b</strong>.	Fase Cepat (Pembahasan Soal) 9 x (@3-4 jam)<br />
                        <strong>c</strong>.	Kelas tatap muka, sistematis dan interaktif (via Zoom) <br />
                        <strong>d</strong>.	Tryout Online 3x<br />
                        <strong>e</strong>.	Tryout Suplementasi 1x<br />
                        <strong>f</strong>.	Fase Review 3x (2-3 jam)<br />
                        <strong>g</strong>.	Buku Modul UKMPPDG Eksklusif Full Colour Ingenio 3 buah<br />
                        <strong>h</strong>.	Kaos eksklusif Ingenio <br />
                        <strong>i</strong>.	Sling Bag Ingenio<br />
                        <strong>j</strong>.	Tutor pilihan Ingenio yang telah terstandarisasi nasional<br />
                        <strong>k</strong>.	Konsultasi tutor selama masa bimbingan


                    </h5>
                    <div className="row g-2" style={{ textAlign: 'left', paddingTop: '2.5rem' }}>
                        <div className="col-lg-7 col-md-12 mt5r">
                            <h5 className="tdt36px balooc2 w75p" style={{ textDecoration: 'none', lineHeight: '50px' }}>Bimbingan CBT UKMPPDG MEDIUM Focus Class (Online)</h5>
                            <h5 className="txt20px mt1r w65p" style={{ marginBottom: '1rem', lineHeight: '30px' }}>Merupakan program bimbingan unggulan di Ingenio yang mempersiapkan mahasiswa klinik Kedokteran menghadapi CBT UKMPPDG secara daring (online)
                            </h5>
                            <h5 className="txt24px mt5r" style={{ marginBottom: '0.2rem', lineHeight: '30px' }}>Dengan berbagai fasilitas penunjang yaitu:</h5>
                            <h5 className="txt20px" style={{ marginBottom: '1rem', lineHeight: '30px' }}><strong>a</strong>. Fase Cepat (Pembahasan Soal) 9 x (@3-4 jam)<br />
                                <strong>c</strong>.	Tryout Online 3x<br />
                                <strong>d</strong>.	Free Akses Video Pembahasan Soal Tryout Pilihan<br />
                                <strong>e</strong>.	Buku Modul Focus 1 buah<br />
                                <strong>f</strong>.	Kaos eksklusif Ingenio <br />
                                <strong>g</strong>.	Sling Bag Ingenio<br />
                                <strong>h</strong>.	Free Ongkos Kirim<br />
                                <strong>i</strong>.	Tutor pilihan Ingenio yang telah terstandarisasi nasional<br />
                                <strong>j</strong>.	Konsultasi tutor sampai H-1 UKMPPDG


                            </h5>
                            <a href="#" className="txt20px" style={{ color: '#FCA61F', textDecoration: 'underline' }}>Download Brosur UKMPPD
                            </a>
                        </div>
                        <div className="col-lg-5 col-md-12 mt2r">
                            <img src="/assets/landing/ukmp2dg-2.png" />
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment >
    )
}

