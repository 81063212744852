import React, { useState } from 'react';


export default function Opening() {
    return (
        <React.Fragment>
            <section className="container-fluid">
                <div className="site-header site-header--menu-left landing-11-menu site-header--absolute" style={{ backgroundImage: 'url(/assets/landing/Genio2.png)', backgroundPositionY: 'bottom', backgroundPositionX: 'right', backgroundRepeat: 'no-repeat', paddingTop: '6rem', backgroundColor: '#ffcc00' }}>
                    <h5 className="text-center tentang_title py-5" style={{ paddingBottom: '2rem !important' }}>OPENING</h5>
                </div>
            </section>
            <section className="container-fluid bg-desktop" style={{ backgroundImage: 'url(/assets/landing/bg-opening.png)' }}>
                {/* ver desk */}
                <div className="px-4 mx-auto" style={{ paddingTop: '12rem', width: '85%' }}>
                    <div className="row g-2" style={{ textAlign: 'justify', paddingTop: '5rem' }}>
                        <p className="ct pt-5 pb-4" style={{ color: '#000000' }}>Ingenio Indonesia adalah lembaga bimbingan belajar bidang pendidikan non formal yang berfokus pada layanan pendidikan kedokteran dan ilmu kesehatan. PT. Ingenio Medika Nusantara merupakan perusahaan yang bergerak dalam kegiatan usaha Penelitan dan Pengembangan Teknologi Bidang Kesehatan, dengan spesifikasi bimbingan belajar kedokteran dan ilmu kesehatan lainnya. Pengesahan pendirian Badan Hukum Perseroan Terbatas PT. Ingenio Medika Nusantara berdasarkan Keputusan Menteri Hukum dan Hak Asasi Manusia Republik Indonesia dengan Nomor AHU-0035252.AH.01.TAHUN 2018.</p>

                        <p className="ct pb-4" style={{ color: '#000000' }}>Layanan program bimbingan belajar di Ingenio Indonesia saat ini terdiri dari Program Kompetensi dan Non-Kompetensi. Program kompetensi terdiri dari bimbingan belajar CBT (Computer Based Test) dan OSCE (Objective Structured Clinical Examination) persiapan UKMPPD (Ujian Kompetensi Mahasiswa Pendidikan Profesi Dokter), UKMPPDG (Ujian Kompetensi Mahasiswa Pendidikan Profesi Dokter Gigi), dan UKOM (Ujian Kompetensi) Kebidanan. Program non kompetensi terdiri dari bimbingan preklinik FK, FKG, Kebidanan, bimbingan pre-coass dan bimbingan dokter muda.</p>

                        <p className="ct pb-4" style={{ color: '#000000' }}>INGENIO diambil dari kata INGENIOUS yang dalam bahasa Inggris memiliki arti cerdas dalam arti kreatif. Kata ingenious dalam seseorang berarti orang tersebut kreatif dan cerdas sehingga mampu membuat hal yang tampak begitu sulit pada awalnya menjadi lebih sederhana dan mudah. Hal ini menjadi filosofi kami, sehingga dalam proses pembelajaran semua peserta didik merasa bahwa pendidikan kedokteran tidaklah susah seperti dalam bayangan mereka sebelumnya.</p>

                        <p className="ct pb-4" style={{ color: '#000000' }}>Ingenio memiliki 5 nilai dalam Ingenio Ways yang diharapkan menjadi nilai yang dihayati oleh setiap staf, tutor, dan peserta bimbingannya dalam setiap aspek yaitu Integrity (integritas), Respect (saling menghargai), Perseverance (ketekunan), Excellence (mutu tinggi) dan Collaboration (kolaborasi/kerjasama). Ingenio mengajak dengan tangan terbuka kepada setiap pembaca buku ini untuk turut menjaga 5 nilai ini dan turut merasakan menjadi bagian dari bimbingan belajar kami yang terintensif, terbesar, sekaligus terhangat sebagai inkubator kebaikan dalam memberi warna pada dunia pendidikan kedokteran dan tenaga medis lainnya.</p>

                    </div>
                    <div className="img text-center d-none d-lg-block" style={{ marginRight: 'auto', marginLeft: 'auto', textAlign: 'center', padding: '5rem' }}>
                        <img src="assets/landing/new-big-data-1.png" style={{ width: '100%' }} />
                    </div>
                </div>
                {/* ver mob */}
                {/* <div className="container px-4 d-lg-none d-lg-none" style={{ paddingTop: '12rem' }}>
                    <div className="row g-2" style={{ padding: '0rem', textAlign: 'justify' }}>
                        <h5 className="text" style={{ fontSize: '18px', fontWeight: '500', paddingBottom: '2rem' }}>Puji syukur selayaknya diucapkan kepada Tuhan YME, karena hanya dengan rahmat dan pengasihanNya sajalah, Ingenio Learning Center bisa berdiri dan bertahan hingga saat ini. Ingenio berdiri untuk menjawab kebutuhan zaman terkait dengan kualitas dan profesionalitas dari pendidikan tenaga medis di nusantara. Urgensi dari kebutuhan akan bimbingan belajar ini dapat dilihat dari tingginya angka retaker ujian kompetensi tenaga medis di Indonesia yang terus tinggi dari tahun ke tahun.</h5>

                        <h5 className="text" style={{ fontSize: '18px', fontWeight: '500', paddingBottom: '2rem' }}>Apakah akar dari permasalahan ini? Patutkah kita menyalahkan fakultas kedokteran di nusantara ini, menyatakan bahwa mereka tidak mampu mendidik dengan baik? Tentu tidak. Ingenio memilih untuk berperan serta dalam pendidikan tenaga medis di Indonesia dengan cara kami sendiri. Ingenio tidak menggantikan fungsi kampus sebagai lembaga pendidikan formal, namun kami merupakan suplemen dan bantuan untuk meningkatkan kualitas tenaga medis secara keseluruhan.</h5>

                        <h5 className="text" style={{ fontSize: '18px', fontWeight: '500', paddingBottom: '2rem' }}>Di tengah maraknya pola pendidikan Kurikulum Berbasis Kompetensi (KBK) dan self-discussion, kami melihat bahwa tidak ada yang salah dengan pola pengajaran klasik. Ingenio memberikan pengajaran berbasis tutor, namun dengan kualitas tutor terbaik yang bisa didapat di seluruh Indonesia ini. Dengan begitu, peserta didik tidak lagi direpotkan dengan tuntutan belajar dan mencari materi sendiri, namun bisa fokus untuk menyerap ilmu yang benar dalam kurun waktu yang sesingkat-singkatnya dalam rangka persiapan ujian kompetensi.</h5>

                        <h5 className="text" style={{ fontSize: '18px', fontWeight: '500', paddingBottom: '2rem' }}>Berawal dari persiapan ujian kompetensi, sekarang Ingenio juga menyediakan bimbingan komprehensif di setiap jenjang pendidikan tenaga medis. Bimbingan preklinik dan pre-koas juga disediakan, untuk membantu memperkuat fondasi ilmu para tenaga medis. Sama halnya tidak ada rumah berkualitas bagus tanpa fondasi yang kuat, tidak ada tenaga medis profesional tanpa landasan ilmu preklinik yang kuat.</h5>

                        <h5 className="text" style={{ fontSize: '18px', fontWeight: '500' }}>Ke depannya, Ingenio akan terus mempertahankan kualitas sebagai bimbingan belajar kedokteran yang terbaik di Indonesia. Kami ucapkan terima kasih yang sebesar-besarnya untuk kepercayaan yang diberikan, dan kepercayaan itu akan terus kami jaga.</h5>
                    </div>
                    <div className="img text-center d-lg-none d-lg-none" style={{ marginRight: 'auto', marginLeft: 'auto', textAlign: 'center' }}>
                        <img src="assets/landing/big-data-1.png" />
                    </div>
                </div> */}
            </section>
        </React.Fragment >
    )
}

