import React from 'react';
import { useState } from 'react';
import AdminSideBar from '../../../../components/layout/dashboard/admin/AdminSideBar';
import Button from '@material-tailwind/react/Button';
import { FormGroup, Modal } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';


export default function InformasiKelasAdmin (props) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  document.title = "INGENIO Indonesia";
  const [showSidebar, setShowSidebar] = useState('-left-64');
  var baseUrl = window.location.origin;

  const columns = [
    {
        name: 'NO.',
        selector: row => row.no,
        sortable: true,
        width: '6.2vw',
    },
    {
        name: 'Program',
        selector: row => row.program,
        sortable: true,
        // width: '20vw',
    },
    {
        name: 'regio',
        selector: row => row.regio,
        sortable: true,
        // width: '20vw',
    },
    {
        name: 'kode kelas',
        selector: row => row.kode,
        sortable: true,
        // width: '20vw',
    },
    {
        name: 'nama kelas',
        selector: row => row.kelas,
        sortable: true,
        // width: '20vw',
    },
  ];
  const data = [
      {
          no: 1,
          program: 'Program A',
          regio: 'Jakarta',
          kode: '001198',
          kelas: 'Kelas A',
      },
      {
          no: 2,
          program: 'Program B',
          regio: 'Jakarta',
          kode: '001198',
          kelas: 'Kelas B',
      },
  ];

  const customStyles = {
      table: {
        style: {
          borderRadius: '15px',
          border: '1px solid #BDC1C9',
        },
      },
      head: {
        style: {
          fontSize: '1.25rem',
          textTransform: "uppercase",
          color: '#6B7280',
          backgroundColor: '#f3f4f6',
          border: '1px solid #BDC1C9',
          borderTopLeftRadius: '15px',
          borderTopRightRadius: '15px',
        },
      },
      pagination: {
        style: {
          color: '#6B7280',
          fontSize: '1.1rem',
          minHeight: '56px',
          backgroundColor: '#f3f4f6',
          border: '1.25px solid #BDC1C9',
          borderBottomLeftRadius: '15px',
          borderBottomRightRadius: '15px',
        },
      },
  };

  const AreYouSure = () => {
      Swal.fire({  
          title: 'Sudah Yakin?',  
          text: 'Apakah Anda Yakin Ingin Menghapus Data Ini?',  
          icon: 'warning',  
          showCancelButton: true,  
          cancelButtonText: 'Tidak',  
          confirmButtonText: 'Ya'  
      })
  }

  const Succeed = () => {
      Swal.fire({  
          title: 'Data Berhasil Tersimpan !',  
          text: '',  
          icon: 'success',  
          showCancelButton: false,              
          confirmButtonText: 'OK'  
      }).then((result) => {
        if (result.isConfirmed) {
          window.location.reload();
        }
      })
  }

  return(
    <React.Fragment>
      <AdminSideBar />
      <div className="md:ml-64 styleHead" style={{ backgroundColor: '#F3F4F6' }}>
        <div className="bg-orange md:px-8 pb-5" style={{height: '20vh', position: 'relative', padding: '0 30px 30px 30px'}}>
            <h1 className="tracking-wider title-24-600">Informasi Kelas</h1>
            {/* <h2 className="tracking-wider title-20-700 mt-5">Selamat Datang Tutor INGENIO !</h2> */}
            <img src={baseUrl + "/assets/logo/evaluasikelas.png"} className="img-opacity"></img>
        </div>

        <div className="px-3 md:px-8 pb-5">
            <div className="container mx-auto max-w-full">
                <div className="row mt-5">
                  <div className="col-12 my-2">
                    <FormGroup>
                      <div className="justify-start">
                        <div className="SearchTable">
                            <input type="text" name="" className="inputSearchTable" placeholder="&#x1F50E;&#xFE0E; Search..." id="" />
                            <Button className="btnSearchTable">Cari</Button>
                        </div>
                      </div>
                    </FormGroup>
                  </div>
                  <div className="col-12 mt-3">
                      <DataTable columns={columns} customStyles={customStyles} data={data} pagination />
                  </div>
                </div>
            </div>
        </div>

      </div>
    </React.Fragment>
    
  )
}