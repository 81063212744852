import React from 'react';
import styles from '../../index.module.css';
import { HeroPlayer } from './index'

export default function Hero() {
  return (
    <React.Fragment>
      <section className="container-fluid">
        <div className="container px-4 py-5 about-section">
          <div className="row align-items-center g-2 py-1">
            <HeroPlayer />
            <div className="col-12 col-lg-8 mx-auto text-center">
              <h4 className="about-text_testimoni text-xl" style={{color: 'black', fontFamily: 'Quicksand !important'}}>
              TESTIMONI PESERTA BIMBINGAN INGENIO <br />
              TOP SCORE 1 NASIONAL CBT UKMPPD BATCH 1 2022
              </h4>
            </div>
          </div>
        </div>
      </section> 
    </React.Fragment>
  )
}

